const moment = require("moment-timezone");

const items = JSON.parse(localStorage.getItem("AuthStore"));
const format = "YYYY-MM-DD HH:mm:ss.SSS";

const timeZoneDefault = items?.user?.company?.timeZone;

function timeFormatter(utcTime, targetTimezone) {
  var momentUtcTime = moment.utc(utcTime);

  if (targetTimezone) {
    var convertedTime = momentUtcTime.tz(targetTimezone);
  } else {
    var convertedTime = momentUtcTime.tz(timeZoneDefault);
  }

  if (convertedTime) {
    return {
      unix: convertedTime?.unix(),
      formatted: convertedTime?.format(format),
    };
  } else {
  }
}

export { timeFormatter };
