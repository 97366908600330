import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useForm, useWatch } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { processService } from "../../../services/process.service";
import {
  OperationIcon,
  ProcessInfo,
  ProcessResponsible,
  TabModal,
} from "../../process";
import { externalOperationService } from "../../../services/external-operation.service";
import { Toast } from "../../../utils/toastify/toast";
import ExternalOperationInfo from "../pages/ExternalOperationInfo";
import { CustomModal } from "../../../components";
import AddSupplierModal from "./AddSupplierModal";
import ExternalResponsible from "../pages/ExternalResponsible";

const AddExternalOperationModal = observer(
  ({ data, setIsOpen, selectedProcess, handleData }) => {
    const { t } = useTranslation();
    const [icons, setIcons] = useState([]);
    const [tempIcons, setTempIcons] = useState([]);
    const [selectedTab, setSelectedTab] = useState("process");
    const [selectedPersonal, setSelectedPersonal] = useState([]);
    const [selectedSupplier, setSelectedSuplier] = useState([]);
    const [addSuplierOpen, setAddSuplierOpen] = useState(false);
    const [supplier, setSupplier] = useState([]);
    const listSupplier = async () => {
      const response = await externalOperationService.listSupplier();
      setSupplier(response.data);
    };
    const [supplierData, setSupplierData] = useState({});
    const schema = yup.object({
      name: yup.string(),
    });

    const {
      handleSubmit,
      control,
      formState: { errors },
      setValue,
    } = useForm({
      resolver: yupResolver(schema),
    });

    console.log(selectedProcess, "SELECTED PROCESSSSS");

    const onSubmit = async (data) => {
      Toast(
        "warning",
        "Demo users are not authorized to access contact administration."
      );
    };

    const handleIcons = async () => {
      const { data } = await processService.getIcons();
      setIcons(data);
      setTempIcons(data);
    };

    const suppliers = useWatch({
      control,
      name: "supplier",
    });

    useEffect(() => {
      if (selectedProcess && selectedProcess?.id) {
        setValue("name", selectedProcess?.name);
        setValue("iconKey", selectedProcess?.iconKey);
        setValue(
          "supplier",
          selectedProcess?.supplier.map((d) => d.id)
        );
        setValue(
          "personals",
          selectedProcess?.responsibleUser.map((d) => d.id)
        );
        setSelectedSuplier(selectedProcess?.supplier.map((d) => d.id));
      } else {
        setValue("name", "");
        setValue("iconKey", "");
        setValue("supplier", "");
        setValue("personals", "");
      }
    }, [selectedProcess]);

    useEffect(() => {
      listSupplier();
    }, []);

    useEffect(() => {
      if (selectedProcess) {
        setSelectedPersonal(selectedProcess?.responsibleUser);
      }
    }, [selectedProcess]);

    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-screen h-[542px] xs:w-[335px] xs:h-[656px] xs:overflow-hidden sm:w-[500px] md:w-[700px] sm:max-h-[500px] md:max-h-[520px] max-w-[800px] max-h-[570px] min-h-[450px]  xs:flex-col flex gap-x-4 scrollbar-hide"
        >
          <TabModal
            tabData={[
              {
                name: t("addProcess:processInfo"),
                value: "process",
                component: (
                  <ExternalOperationInfo
                    setSelectedSuplier={setSelectedSuplier}
                    selectedSupplier={selectedSupplier}
                    setSupplierData={setSupplierData}
                    supplierData={supplierData}
                    control={control}
                    setValue={setValue}
                    closeModal={setIsOpen}
                    setAddSuplierOpen={setAddSuplierOpen}
                    errors={errors}
                    supplier={supplier}
                    setSelectedTab={setSelectedTab}
                  />
                ),
              },
              {
                name: t("routes:processResponsible"),
                value: "processResponsible",
                component: (
                  <ExternalResponsible
                    t={t}
                    setIsOpen={setIsOpen}
                    setSelectedTab={setSelectedTab}
                    selectedTab={selectedTab}
                    selectedPersonals={selectedPersonal}
                    setSelectedPersonals={setSelectedPersonal}
                    errors={errors}
                    handleData={handleData}
                    selectedProcess={selectedProcess}
                    onSubmit={onSubmit}
                  />
                ),
              },
            ]}
            width="1/2"
            data={data}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </form>
        <CustomModal
          isOpen={addSuplierOpen}
          setIsOpen={setAddSuplierOpen}
          children={
            <AddSupplierModal
              supplierData={supplierData}
              setSupplierData={setSupplierData}
              setIsOpen={setAddSuplierOpen}
              listSupplier={listSupplier}
            />
          }
          modalTitle={
            supplierData?.id
              ? t("step:updateSupplier")
              : t("product:createSupplier")
          }
        />
      </>
    );
  }
);

export default AddExternalOperationModal;
