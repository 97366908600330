import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import {
  ResetPreviousIcon,
  ResetRefresh,
} from "../../../assets/icons/machineIcons";
import DashboardSwiperItem from "../../machine/Slider/DashboardSwiperItem";
import GaugeChart from "../../machine/Twin/components/GaugeChart";
import DetailGaugeChart from "../../machine/Twin/chart/DetailGaugeChart";
import { useTranslation } from "react-i18next";
import GradeChart from "../../machine/Twin/chart/GradeChart";
import Selector from "../../../components/inputs/Selector";
import ResetGaugeChart from "../../machine/Twin/chart/ResetGaugeChart";
import { Loader } from "../../../assets/icons/stepsIcons";

const ResetKpiValueModal = ({
  selectedValue,
  setSelectedValue,
  gaugeData,
  loading,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [chartData, setChartData] = useState(null);

  const options = [
    { value: "performance", label: t("gauge:performance"), index: 0 },
    { value: "utilization", label: t("gauge:utilization"), index: 1 },
    { value: "availability", label: t("gauge:availability"), index: 2 },
  ];

  useEffect(() => {
    setValue(selectedValue?.name);
    setChartData(selectedValue);
  }, [selectedValue]);

  return (
    <div className="flex flex-col w-[720px] h-full">
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="flex flex-col mt-6 mb-6 gap-3  items-center">
          <div className="flex items-center justify-center w-[35%] ml-auto  mr-auto">
            <Selector
              items={options}
              onChange={(v) => {
                setChartData(
                  gaugeData?.find((a) => {
                    return (
                      String(a?.type)?.toLowerCase() === String(v).toLowerCase()
                    );
                  })
                );
                setValue(v);
              }}
              value={value}
            />
          </div>
          <div className="flex items-center justify-center gap-x-8  mr-auto ml-auto">
            <div className="flex flex-col items-center justify-end gap-4 w-[200px]">
              <div className="flex flex-col justify-end ml-auto gap-y-3">
                <div className="flex justify-end ">
                  <Button
                    colorType={"secondary-machine"}
                    size={"sm"}
                    iconLeft={<ResetPreviousIcon />}
                  />
                </div>
                <p className="font-medium text-sm text-primary-700">
                  {t("gauge:returnPreviousData")}
                </p>
              </div>
              <div className="flex flex-col justify-end ml-auto gap-y-3">
                <div className="flex justify-end ">
                  <Button
                    colorType={"secondary-error"}
                    size={"sm"}
                    iconLeft={<ResetRefresh color={"#B42318"} />}
                  />
                </div>
                <p className="font-medium text-sm text-error-700">
                  {t("gauge:resetAllData")}
                </p>
              </div>
            </div>

            <ResetGaugeChart
              width={247}
              height={110}
              radius="145%"
              // enabled={enabled}
              value={chartData?.actual}
              kpi1={chartData?.average ? chartData?.average?.toFixed(2) : 0}
              kpi2={chartData?.average == 0 ? 0 : chartData?.kpi}
              isIncreaseStatus={chartData?.isIncreaseStatus}
              timeMass={(chartData?.timeMass / 3600).toFixed(2) || 25000}
            />

            <div className="flex flex-col items-center justify-end gap-4 w-[200px]">
              <div className="flex flex-col justify-start gap-y-3 mr-auto">
                <div className="flex justify-start mr-auto">
                  <Button
                    colorType={"secondary-machine"}
                    size={"sm"}
                    iconLeft={<ResetPreviousIcon />}
                  />
                </div>
                <p className="font-medium text-sm text-primary-700">
                  {t("gauge:returnNextData")}
                </p>
              </div>
              <div className="flex flex-col justify-start mr-auto gap-y-3">
                <div className="flex justify-start ">
                  <Button
                    colorType={"secondary-error"}
                    size={"sm"}
                    iconLeft={<ResetRefresh color={"#B42318"} />}
                  />
                </div>
                <p className="font-medium text-sm text-error-700">
                  {t("gauge:resetKpi")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetKpiValueModal;
