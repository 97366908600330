import React, { useState, useEffect } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { Button } from "../../../components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../../../assets/icons/machineIcons";
import { useNavigate } from "react-router-dom";

const PersonelDetailHeader = ({ user,dateChangeCount }) => {
  const navigate = useNavigate();

  const data = {
    userImage: user?.avatar,
    name: user?.name,
    lastName: user?.lastName,
    role: user?.role,
  };

  return (
    <div className="flex flex-row items-center h-14 w-full pb-2 border-b border-secondary-200 mt-3 mb-3  ">
      <div className="flex flex-row gap-3 items-center justify-between ">
        <Button
          size={"md"}
          colorType={"tertiary-gray"}
          iconLeft={<ArrowLeftIcon />}
          onClick={() => navigate(-dateChangeCount)}
        />

        {data?.userImage ? (
          <img
            src={generateFileUrl(data?.userImage)}
            className="w-12 h-12 min-w-[48px] min-h-[48px] rounded-full object-cover blur-sm"
          />
        ) : (
          <div className="w-12 h-12">
            <NoAvatar size={48} name={data?.name} lastName={data?.lastName} />
          </div>
        )}

        <p className="text-displayXs font-semibold text-secondary-700">
          {data?.name} {data?.lastName}
        </p>
        <p className="text-[20px] font-regular text-secondary-500">
          {data?.role}
        </p>
      </div>
    </div>
  );
};

export default PersonelDetailHeader;
