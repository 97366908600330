import React, { useEffect, useState } from "react";
import { DangerIcon } from "../../../assets/icons/machineIcons";
import { Controller, useForm } from "react-hook-form";
import Selector from "../../../components/inputs/Selector";
import { Button, Input, TimeInput, UploadProfile } from "../../../components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { InfoIcon } from "../../../assets/icons/PersonIcons";
import HourTimeInput from "../../../components/inputs/HourTimeInput";
import { lossesService } from "../../../services/losses.service";
import { Toast } from "../../../utils/toastify/toast";

export default function SendErrorMessageModal({
  t,
  setIsOpen,
  selectedLossId,
  refetch,
}) {
  const [selectedLoss, setSelectedLoss] = useState();

  const handleProcess = async () => {
    await lossesService.getLossesTypeList().then((res) => {
      setSelectedLoss(
        res.data.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        })
      );
    });
  };

  const schema = yup.object({
    lossId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    lossDescription: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
   };
  useEffect(() => {
    handleProcess();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full h-full"
    >
      <div className="flex flex-col gap-y-4 items-center">
        <span className="min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px] p-3 bg-[#FEE4E2] rounded-md">
          <DangerIcon />
        </span>
        <div className="flex flex-col items-center text-center gap-y-1">
          <span className="text-lg font-semibold text-[#101828]">
            {t("equipments:sendErrorMessage")}
          </span>
          <span className="text-sm font-normal text-[#667085]">
            {t("equipments:areYouSure")}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 mt-6">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Selector
              items={selectedLoss}
              label={t("loss:lostType")}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              validate={errors?.entityId ? "error" : ""}
              errorMessage={errors?.entityId ? errors?.entityId?.message : ""}
            />
          )}
          name="lossId"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("Loss Description")}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              placeholder={"Write loss description"}
              validate={errors.text ? "error" : ""}
              errorMessage={errors.text ? errors.text.message : ""}
            />
          )}
          name="lossDescription"
        />
      </div>

      <div className="flex w-full gap-x-3 mt-8 min-h-[44px] max-h-[44px]">
        <Button
          colorType={"secondary-gray"}
          label={t("buttons:stop")}
          onClick={() => setIsOpen(false)}
        />
        <Button
          colorType={"primary-error"}
          label={t("buttons:send")}
          type="submit"
        />
      </div>
    </form>
  );
}
