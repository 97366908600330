import React from "react";

const MapInfoButton = ({ buttons, selectedButton }) => {
  return (
    <div className="flex flex-row items-center  border w-fit border-secondary-300 rounded-lg h-fit">
      <div className="flex flex-row items-center w-fit">
        {buttons?.map((item, index) => {
          return (
            <div
              onClick={item?.onClick}
              className={`flex items-center justify-center ${
                index === 0
                  ? "rounded-l-lg"
                  : index === 3
                  ? "rounded-r-lg"
                  : "rounded-none"
              } ${
                selectedButton == item?.name ? "bg-secondary-50" : "bg-white"
              } cursor-pointer w-11 h-fit py-2.5 px-3 ${
                index === 3 ? "border-r-0" : "border-r"
              }  border-r-secondary-300`}
            >
              <item.icon />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MapInfoButton;
