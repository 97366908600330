import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { personalService } from "../../../services/personal.service";
import DepartmentItem from "./DepartmentItem";
import AddDepartment from "../../../steps/department/modals/Add-Department";
import { chatService } from "../../../services/chat.service";
import { Button, CustomModal } from "../../../components";
import useOuterClick from "../../../hooks/useOuterClick";
import { Toast } from "../../../utils/toastify/toast";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import { ChevronRightIcon } from "../../../assets/icons/PersonIcons";

const DepartmentSlider = ({
  equipmentPage,
  enabled = false,
  selectedDepartment,
  setSelectedDepartment,
  theme,
  setCurrentPage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const ref = useOuterClick(() => setSelectedDepartment(""));
  const [departments, setDepartments] = useState([]);
  const [isCompanyModal, setIsCompanyModal] = useState(false);
  const [visibleButton, setVisibleButton] = useState(false);
  const [visibleRightButton, setVisibleRightButton] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const newArr = [{ name: "All", id: "all" }, ...departments];

  const [selectedSwiperValue, setSelectedSwiperValue] = useState();
  const { t } = useTranslation();
  const contentWrapper = useRef(null);
  const onDoubleClickProcess = (item) => {
    setSelectedSwiperValue(item);
    setIsOpen(true);
  };
  const handleData = async () => {
    await personalService
      .getDepartments()

      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {});

    setLoading(false);
  };

  const onDelete = async () => {
    await personalService
      .deleteDepartment(selectedSwiperValue.id)
      .then((res) => {
        if (res.data.status == 1) {
          handleData();
          setIsOpen(false);
          Toast(t("addDepartment:deletedDepartment"));
        } else {
          Toast(t("product:errors"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await chatService.convertDepartmentToNull(selectedSwiperValue.id);
  };

  useEffect(() => {
    handleData();
  }, []);

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const slide = (shift) => {
    contentWrapper.current.scrollLeft += shift;
  };

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        const container = document.getElementById("container");
        if (container?.scrollWidth > container?.clientWidth) {
          setVisibleRightButton(true);
        } else {
          setVisibleRightButton(false);
        }
      }, 300);
    }
  }, [loading]);

  return (
    <div className="flex w-full items-center justify-center flex-col min-h-[40px] my-4">
      <div
        ref={equipmentPage == true ? null : ref}
        className={`w-full flex flex-row items-center`}
      >
        <span className={`${visibleButton ? "hidden" : "flex"} mr-6 `}>
          <Button
            colorType={"secondary-gray"}
            size={"md"}
            iconLeft={<PlusIcon />}
            // onClick={() => {
            //   setSelectedDepartment(null);
            //   setSelectedSwiperValue("");
            //   setIsOpen(true);
            // }}
            onClick={() => Toast("warning", t("alert:warning"))}
          />
        </span>
        <span
          className={`${
            visibleButton ? "flex " : "hidden"
          } pl-6 border-l rotate-180`}
        >
          <Button
            iconLeft={<ChevronRightIcon />}
            colorType={"secondary-gray"}
            size={"md"}
            onClick={() => slide(-90)}
          />
        </span>

        <div
          onScroll={(e) => {
            if (!isIntersecting) {
              setVisibleButton(true);
            }
          }}
          id="container"
          className={`flex flex-row min-h-[40px] items-center gap-5 overflow-x-auto scrollbar-hide h-full `}
          ref={contentWrapper}
        >
          {newArr.map((department, i) => {
            return (
              <DepartmentItem
                department={department}
                i={i}
                selectedDepartment={selectedDepartment}
                setCurrentPage={setCurrentPage}
                setSelectedDepartment={setSelectedDepartment}
                onDoubleClickProcess={onDoubleClickProcess}
                setIsIntersecting={setIsIntersecting}
                setVisibleButton={setVisibleButton}
                setVisibleRightButton={setVisibleRightButton}
                setDepartments={setDepartments}
                dataLength={newArr?.length}
                departments={newArr}
              />
            );
          })}
        </div>
        <span
          className={`pl-6 border-l ${visibleRightButton ? "flex" : "hidden"}`}
        >
          <Button
            iconLeft={<ChevronRightIcon />}
            colorType={"secondary-gray"}
            size={"md"}
            onClick={() => {
              sideScroll(contentWrapper.current, 25, 100, 10);
            }}
          />
        </span>
      </div>
      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        children={
          <AddDepartment
            theme={theme}
            setSelectedDepartmant={setSelectedDepartment}
            // setOpenModal={setIsOpen}
            setIsOpen={setIsOpen}
            selectedDepartmant={selectedSwiperValue}
            setSelectedSwiperValue={setSelectedSwiperValue}
            GetDepartment={handleData}
            onDelete={onDelete}
          />
        }
        modalTitle={
          selectedSwiperValue
            ? t("department:updateDepartment")
            : t("department:modalTitle")
        }
      />
      {/* <ModalTemplate
        onRequestClose={() => setIsCompanyModal(false)}
        isOpen={isCompanyModal}
        setIsOpen={() => {
          setTimeout(() => {
            setIsCompanyModal(false);
          }, 100);
          setIsOpen(false);
        }}
        modalTitle={t("routes:company")}
        children={
          <div className="w-[1024px] h-full">
            {" "}
            <Company
              isOpen={isOpen}
              type="editModal"
              setIsOpen={() => {
                setTimeout(() => {
                  setIsCompanyModal(false);
                }, 100);
                setIsOpen(false);
              }}
            />
          </div>
        }
      /> */}
    </div>
  );
};

export default DepartmentSlider;
