import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import AlertModal from "../alert-Modal/Alert-Modal";
import { DeleteIcon, DotsVerticalIcon } from "./ActiveAndPlannedCard";
import { EditIcon } from "../../assets/icons/departmentIcon";
import JunctionUpdate from "../modal/JunctionUpdate";
import CustomModal from "../modal/CustomModal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import QualityControlForm from "../modal/QualityControlForm";
import { useNavigate } from "react-router-dom";
import {
  EquipmentDeleteIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  NoEquIcon,
  ProductDetailIcon,
  QualityDetailIcon,
} from "../../assets/icons/machineIcons";
import moment from "moment";
import Badgets from "../buttons/Badgets";

const NewApprovedCard = ({
  id,
  onClick,
  status,
  equipmentImage,
  equipmentName,
  type,
  totalJunctionTime,
  estimatedTime,
  image,
  piece,
  oneOrderTime,
  qualityAcceptedPiece,
  qualityRejectedPiece,
  qualityPercent,
  estimatedOperationTime,
  totalEndTime,
  pages,
  point,
  oeeData,
  refetch,
  name,
  user,
  step,
  estimatedTrue,
  targetMin,
  startDate,
  allData,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const navigate = useNavigate();

  const [deleteId, setDeleteId] = useState("");

  const menuOptions = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsEquipment = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsPersonnel = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "productDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const onDeleted = async () => {
    await junctionService.deleteJunction(id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  // convertSeconds = (seconds: number): string => {
  //   if (isNaN(seconds)) return "---";

  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = Number(seconds % 60).toFixed(0);

  //   let formattedTime = "";

  //   if (minutes > 0) {
  //     formattedTime += minutes + " dk ";
  //   }

  //   if (remainingSeconds > 0) {
  //     formattedTime += remainingSeconds + " sn";
  //   }

  //   return formattedTime.trim();
  // }

  // onPressProduct: (e) => {
  //   navigate(
  //     `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
  //   );
  // },

  return (
    <>
      <div
        className={`flex w-full h-[92px] flex-col  gap-x-2 border-[#D0D5DD] items-start  border  rounded-t-[4px] rounded-b-lg  shadow-xs max-h-[92px] min-h-[92px] bg-white group relative`}
      >
        <div
          className={`w-full border-t-4 rounded-t-[3px] ${
            type == "work"
              ? "border-t-success-500 "
              : type === "setup"
              ? "border-t-[#EAAA08]"
              : "border-t-[#98A2B3]"
          }`}
        ></div>
        <div className="flex flex-row items-start gap-x-[6px] p-[6px] w-full">
          <div className="w-[50%] flex flex-row items-center gap-x-[6px]">
            {equipmentImage ? (
              <img
                data-tooltip-id={`${id}`}
                data-tooltip-place={"right"}
                
                onClick={onClick}
                src={generateFileUrl(equipmentImage || "")}
                className="w-[64px] min-w-[64px] h-[48px] blur-sm cursor-pointer rounded-[2px] border border-secondary-300 "
              />
            ) : (
              <div
                onClick={onClick}
                className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 shadow-sm bg-secondar-50 flex items-center justify-center"
              >
                <NoEquIcon />
              </div>
            )}
            {type === "manual_finished_work" ? (
              <div className="flex flex-col items-start gap-y-[6px]">
                <p className="truncate text-sm text-secondary-700 font-semibold">
                  {t("product:finishedWork")}
                </p>
                <div className="flex flex-row items-center w-full">
                  <div className="flex flex-row items-center w-[140px]">
                    <ClockIcon />
                    <p className="text-xs text-secondary-600 ml-1 font-normal">
                      {totalJunctionTime || "---"}-{totalEndTime || "---"}
                    </p>
                    <p className="text-xxs font-normal ml-0.5 text-secondary-500">
                      {t("product:startFinishTwo")}
                    </p>
                  </div>
                  <div className="flex flex-row items-center">
                    <PieceIcon />
                    <p className="text-xs text-secondary-600 ml-1 font-normal">
                      {piece || "-"}
                    </p>
                    <p className="text-xxs font-normal ml-0.5 text-secondary-600">
                      {t("product:pieces")}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-start gap-y-[6px]">
                <div className="flex flex-row items-center gap-x-3 w-full">
                  <div className="flex flex-row items-center w-[170px]">
                    <ClockIcon />
                    <p className="text-xs text-secondary-600 ml-1 font-normal">
                      {totalJunctionTime || "---"}-{totalEndTime || "---"}
                    </p>
                    <p className="text-xxs font-normal ml-0.5 text-secondary-500">
                      {t("product:start-end")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <PieceIcon />
                  <p className="text-xs text-secondary-600 ml-1 font-normal">
                    {piece || "---"}
                  </p>
                  <p className="text-xxs font-normal ml-0.5 text-secondary-600">
                    {t("product:pieces")}
                  </p>
                </div>
              </div>
            )}
          </div>
          {user?.avatar ? (
            <div
              onClick={() => {
                navigate(
                  `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                    startDate
                  ).format("YYYY-MM-DD")}`
                );
              }}
              data-tooltip-id={`${id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${user?.name + " " + user?.lastName} `}
              className="relative ml-auto z-50 mr-[90px]"
            >
              <img
                className="w-[48px] min-w-[48px] blur-sm h-[48px] cursor-pointer rounded-full border border-secondary-300"
                src={generateFileUrl(user?.avatar)}
              />
            </div>
          ) : (
            <div
              onClick={() => {
                navigate(
                  `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                    startDate
                  ).format("YYYY-MM-DD")}`
                );
              }}
              data-tooltip-id={`${id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${user?.name + " " + user?.lastName} `}
              className="relative ml-auto z-50 mr-[90px]"
            >
              <NoAvatar
                size={48}
                fontSize={16}
                name={user?.name}
                lastName={user?.lastName}
                color={"#475467"}
              />
            </div>
          )}
          <div className="flex flex-col z-[20] items-start rounded border-[0.5px] absolute border-[#D0D5DD] w-[82px] min-w-[82px] bg-white right-[6px]">
            <div className="w-full flex  items-center justify-center rounded-t-lg pr-1 py-[2px] pl-1 bg-[#F9FAFB] border-b">
              <p className="text-xxs font-semibold text-secondary-700">
                {t("reports:quality")}
              </p>
              <div className="px-1 py-[2px] rounded-[2px]  bg-[#079455] w-[35px] h-[14px] flex items-center justify-center ml-auto">
                <p className="text-xxs text-white font-semibold">
                  %{qualityPercent || "0"}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                <AcceptedIcon />
              </div>
              <p className="text-xs font-medium ml-1 text-success-600">
                {qualityAcceptedPiece || "0"}
              </p>
              <p className="text-[10px] font-normal ml-0.5 text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-[6px] py-1">
              <div className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]">
                <RejectedIcon />
              </div>
              <p className="text-xs font-medium ml-1 text-error-600">
                {qualityRejectedPiece || "0"}
              </p>
              <p className="text-xxs font-normal ml-0.5 text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center w-full h-[26px] bg-[#F9FAFB] gap-x-12 py-1 px-[6px] rounded-b-[8px]  border-t-[0.5px] border-t-[#D0D5DD]">
          <div className="flex flex-row gap-1 items-center ">
            <TargetIcon />
            <p className="text-xs font-medium text-secondary-600">
              {estimatedOperationTime || "---"}
            </p>
            <p className="text-xxs font-normal text-secondary-600">
              {t("product:targetMinActual")}
              {/* {type === "setup" ? "hdf sn-ort" : "hdf dk-ort"} */}
              {/* {t("product:targetMin")} */}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            {estimatedTime > estimatedOperationTime ? (
              <SinglePiece2 />
            ) : (
              <SinglePiece />
            )}

            <div className="flex flex-row items-center">
              {type === "setup" ? (
                <>
                  <p
                    className={`text-xs font-normal  ${
                      estimatedTime > estimatedOperationTime
                        ? "text-red-500"
                        : "text-success-600"
                    }`}
                  >
                    {oneOrderTime || "---"}
                  </p>
                  {/* <p className="text-xxs font-normal text-secondary-600">dk</p> */}
                </>
              ) : (
                <>
                  <p
                    className={`text-xs font-normal  ${
                      estimatedTime > estimatedOperationTime
                        ? "text-red-500"
                        : "text-success-600"
                    }`}
                  >
                    {oneOrderTime || "---"}
                  </p>
                  <p className="text-xxs font-normal text-secondary-600">
                    {t("product:pcss")}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[8px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[2px] right-[32px] z-[150] rounded-lg px-[6px] py-1 group-hover:flex">
            {pages === "equipmentDetail" ? (
              <>
                {menuOptionsEquipment?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        if (item.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              user?.id
                            }/${moment(startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item.value === "quality") {
                          setIsOpenForm(true);
                        } else setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : pages === "personnelDetail" ? (
              <>
                {menuOptionsPersonnel?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        if (item.value === "productDetail") {
                          navigate(
                            `/app/product/order/detail/${allData?.header?.id}+${allData?.job?.id}`
                          );
                        }
                        if (item.value === "details") {
                          onClick();
                        } else setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {menuOptions?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                         Toast("warning" , "Demo users can't delete data")
                        }
                        if (item.value === "edit") {
                          Toast("warning" , "Demo users can't edit data")
                        }
                        if (item.value === "details") {
                          onClick();
                        }
                        if (item.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              user?.id
                            }/${moment(startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item?.value === "quality") {
                          setIsOpenForm(true);
                        } else setShowDropDown(false);
                      }}
                   
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            )}
          </div>
        )}
        {step === undefined ? null : (
          <span className="absolute left-1 top-2">
            <Badgets colorType={"fill-gray"} label={step + ".op"} size={"sm"} />
          </span>
        )}
      </div>
      <Tooltip id={`${id}`} />
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        modalTitle={t("product:qualityControlForm")}
        onClose={() => {
          setIsOpenForm(false);
        }}
        children={
          <QualityControlForm
            onClose={() => {
              setIsOpenForm(false);
            }}
            junctionId={id}
            refetch={refetch}
          />
        }
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={id}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default NewApprovedCard;

export const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00008 3.49996V6.99996L9.33342 8.16663M12.8334 6.99996C12.8334 10.2216 10.2217 12.8333 7.00008 12.8333C3.77842 12.8333 1.16675 10.2216 1.16675 6.99996C1.16675 3.7783 3.77842 1.16663 7.00008 1.16663C10.2217 1.16663 12.8334 3.7783 12.8334 6.99996Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PieceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.07812H3.25C2.42157 2.07812 1.75 2.7497 1.75 3.57813V10.4219C1.75 11.2503 2.42157 11.9219 3.25 11.9219H10.75C11.5784 11.9219 12.25 11.2503 12.25 10.4219V3.57813C12.25 2.7497 11.5784 2.07812 10.75 2.07812H8.96875M5.03125 2.07812V5.89427C5.03125 5.95003 5.08992 5.98629 5.13979 5.96135L7 5.03125L8.86021 5.96135C8.91008 5.98629 8.96875 5.95003 8.96875 5.89427V2.07812M5.03125 2.07812H8.96875"
        stroke="#475467"
      />
    </svg>
  );
};

export const AcceptedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04163H3.25C2.42157 2.04163 1.75 2.7132 1.75 3.54163V10.3854C1.75 11.2138 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2138 12.25 10.3854V3.54163C12.25 2.7132 11.5784 2.04163 10.75 2.04163H8.96875M5.03125 2.04163V4.69111C5.03125 4.74686 5.08992 4.78312 5.13979 4.75819L7 3.82808L8.86021 4.75819C8.91008 4.78312 8.96875 4.74686 8.96875 4.69111V2.04163M5.03125 2.04163H8.96875"
        stroke="#079455"
      />
      <path
        d="M5.25 8.27889L6.50641 9.5353L9.33333 6.70837"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const RejectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04163H3.25C2.42157 2.04163 1.75 2.7132 1.75 3.54163V10.3854C1.75 11.2138 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2138 12.25 10.3854V3.54163C12.25 2.7132 11.5784 2.04163 10.75 2.04163H8.96875M5.03125 2.04163V4.69111C5.03125 4.74686 5.08992 4.78312 5.13979 4.75819L7 3.82808L8.86021 4.75819C8.91008 4.78312 8.96875 4.74686 8.96875 4.69111V2.04163M5.03125 2.04163H8.96875"
        stroke="#D92D20"
      />
      <path
        d="M8.45841 6.70837L5.54175 9.62504M5.54175 6.70837L8.45841 9.62504"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const TargetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8334 7.00002H10.5001M3.50008 7.00002H1.16675M7.00008 3.50002V1.16669M7.00008 12.8334V10.5M11.6667 7.00002C11.6667 9.57735 9.57741 11.6667 7.00008 11.6667C4.42275 11.6667 2.33341 9.57735 2.33341 7.00002C2.33341 4.42269 4.42275 2.33335 7.00008 2.33335C9.57741 2.33335 11.6667 4.42269 11.6667 7.00002Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SinglePiece = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.66667 7.58333V9.91667M9.33333 6.41667V9.91667M7 4.08333V9.91667M4.55 12.25H9.45C10.4301 12.25 10.9201 12.25 11.2945 12.0593C11.6238 11.8915 11.8915 11.6238 12.0593 11.2945C12.25 10.9201 12.25 10.4301 12.25 9.45V4.55C12.25 3.56991 12.25 3.07986 12.0593 2.70552C11.8915 2.37623 11.6238 2.10852 11.2945 1.94074C10.9201 1.75 10.4301 1.75 9.45 1.75H4.55C3.56991 1.75 3.07986 1.75 2.70552 1.94074C2.37623 2.10852 2.10852 2.37623 1.94074 2.70552C1.75 3.07986 1.75 3.56991 1.75 4.55V9.45C1.75 10.4301 1.75 10.9201 1.94074 11.2945C2.10852 11.6238 2.37623 11.8915 2.70552 12.0593C3.07986 12.25 3.56991 12.25 4.55 12.25Z"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SinglePiece2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.66667 7.58333V9.91667M9.33333 6.41667V9.91667M7 4.08333V9.91667M4.55 12.25H9.45C10.4301 12.25 10.9201 12.25 11.2945 12.0593C11.6238 11.8915 11.8915 11.6238 12.0593 11.2945C12.25 10.9201 12.25 10.4301 12.25 9.45V4.55C12.25 3.56991 12.25 3.07986 12.0593 2.70552C11.8915 2.37623 11.6238 2.10852 11.2945 1.94074C10.9201 1.75 10.4301 1.75 9.45 1.75H4.55C3.56991 1.75 3.07986 1.75 2.70552 1.94074C2.37623 2.10852 2.10852 2.37623 1.94074 2.70552C1.75 3.07986 1.75 3.56991 1.75 4.55V9.45C1.75 10.4301 1.75 10.9201 1.94074 11.2945C2.10852 11.6238 2.37623 11.8915 2.70552 12.0593C3.07986 12.25 3.56991 12.25 4.55 12.25Z"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
