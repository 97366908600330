import React, { useEffect, useState } from "react";
import StepsMainSide from "../../components/steps/StepsMainSide";
import { useTranslation } from "react-i18next";
import StepNoData from "../step-navigation/components/StepNoData";
import { CustomModal } from "../../components";
import UndefinedCard from "./components/UndefinedCard";
import DefinedCard from "./components/DefinedCard";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import AddEquipmentModal from "./modals/AddEquipmentModal";
import { equipmentService } from "../../services/equipment.service";
import { Toast } from "../../utils/toastify/toast";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../assets/icons/stepsIcons";
import NoData from "../step-navigation/components/NoData";
import Skeleton from "react-loading-skeleton";
import { useStore } from "../../hooks/useStores";

const Equipments = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isDelete, setIsDelete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});
  const [deleteNode, setDeleteNode] = useState("");

  const { data, isLoading, refetch, isPending, status, isFetching } = useQuery({
    queryKey: ["getAllNodes"],
    queryFn: async () => await equipmentService.getAllNodes(),
    retry: 0,
  });
  const onDeleted = async (id) => {
    await equipmentService.updateEquipmentDefinedToUndefined(id).then((res) => {
      if (res.data.code == 0) {
        refetch();
        setIsDelete(false);
        Toast("success", t("addEquipment:deletedEqu"));
      } else {
        Toast("error", t("product:errors"));
      }
    });
  };

  const generateSkeleton = (count, height = 64, width = 280) => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <div key={i} className="flex items-center justify-center w-full h-full">
          <Skeleton height={height} width={width} />
        </div>
      );
    }
    return skeletons;
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          {data?.defined?.length || data?.undefined?.length > 0 ? (
            <StepsMainSide
              t={t}
              setIsOpen={setIsOpen}
              title={t("routes:equipments")}
              placeholder={t("addEquipment:searchNode")}
              undefinedDataLength={data?.undefined?.length}
              setSearchVal={setSearchQuery}
              children={
                <div className="flex w-full gap-5">
                  <div className="w-full flex flex-col items-center gap-y-3 h-[280px] max-h-[280px] overflow-scroll scrollbar-hide min-w-[280px]">
                    {isFetching ? (
                      <div className="flex flex-col gap-0 items-center justify-center w-full h-full">
                        {generateSkeleton(4, 64, 300)}
                      </div>
                    ) : (
                      <>
                        {data?.undefined?.filter(
                          (node) =>
                            node?.name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase()) ||
                            node?.electronicCardSerialNo
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                        ).length > 0 ? (
                          <>
                            {data?.undefined?.map((node, index) => {
                              if (
                                node?.name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase()) ||
                                node?.electronicCardSerialNo
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                                return (
                                  <UndefinedCard
                                    isEquipment={true}
                                    key={index}
                                    name={node?.name}
                                    description={node?.electronicCardSerialNo}
                                    isUndefined={true}
                                    avatar={""}
                                    editOnClick={() => {
                                      setIsOpen(true);
                                      setSelectedNode(node);
                                    }}
                                  />
                                );
                            })}
                          </>
                        ) : (
                          <div className="w-full h-full flex items-center justify-center ">
                            <NoData
                              theme={"machine"}
                              header={t("step:noDataFound")}
                              button={false}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              }
              secondChildren={
                <div className="flex w-full justify-center gap-5">
                  <div className="w-full items-center flex flex-col gap-y-3 max-h-[280px] overflow-scroll scrollbar-hide">
                    {isFetching ? (
                      <div className="flex flex-col gap-0 items-center justify-center w-full h-full">
                        {generateSkeleton(4, 64, 300)}
                      </div>
                    ) : (
                      <>
                        {data?.defined?.filter((node) =>
                          (node.metadata.brand + " " + node.metadata.model)
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length > 0 ? (
                          <>
                            {data.defined?.map((node, index) => {
                              if (
                                (
                                  node?.metadata?.brand +
                                  " " +
                                  node?.metadata?.model
                                )
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                                return (
                                  <DefinedCard
                                    t={t}
                                    isEquipment={true}
                                    key={index}
                                    name={
                                      node?.metadata?.brand +
                                      " " +
                                      node?.metadata?.model
                                    }
                                    process={node?.process?.name}
                                    avatar={node?.metadata?.image}
                                    editOnClick={() => {
                                      setSelectedNode(node);
                                      setIsOpen(true);
                                    }}
                                    deleteOnclick={() => {
                                      setIsDelete(true);
                                      setDeleteNode(node?.id);
                                    }}
                                    isAlert={isAlert}
                                    setIsAlert={setIsAlert}
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    openAlert={openAlert}
                                    setOpenAlert={setOpenAlert}
                                    isMobile={isMobile}
                                  />
                                );
                            })}
                          </>
                        ) : (
                          <div className="w-full h-full flex items-center justify-center ">
                            <NoData
                              theme={"machine"}
                              header={t("step:noDataFound")}
                              button={false}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              }
            />
          ) : (
            <StepNoData
              header={t("addEquipment:title")}
              text={t("addProcess:equipmentStep")}
              // onClick={() => setIsOpen(true)}
              onClick={() => Toast("warning", t("alert:warning"))}
            />
          )}
        </>
      ) : isLoading ? (
        <div className="flex items-center mt-64 justify-center w-full  h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <>
          {data?.defined?.length || data?.undefined?.length > 0 ? (
            <StepsMainSide
              t={t}
              setIsOpen={setIsOpen}
              definedDataLength={
                data?.defined?.filter(
                  (node) =>
                    node?.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    (node.metadata.brand + " " + node.metadata.model)
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                ).length
              }
              title={t("routes:equipments")}
              placeholder={t("addEquipment:searchNode")}
              undefinedDataLength={
                data?.undefined?.filter(
                  (node) =>
                    node?.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    node?.electronicCardSerialNo
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                )?.length
              }
              setSearchVal={setSearchQuery}
              length={
                data?.defined?.length +
                "/" +
                auth?.user?.company?.limits.user +
                " " +
                t("addPersonal:personCredit")
              }
              isLimitReached={
                data?.defined?.length >= auth?.user?.company?.limits.user
              }
              limitlabel={t("addPersonal:buyStaff")}
              children={
                <div className="flex w-full gap-5">
                  <div className="w-full flex flex-col items-center gap-y-3 h-[280px] max-h-[280px] overflow-scroll scrollbar-hide min-w-[280px]">
                    {isFetching ? (
                      <div className="flex flex-col gap-0 items-center justify-center w-full h-full">
                        {generateSkeleton(4, 64, 280)}
                      </div>
                    ) : (
                      <>
                        {data?.undefined?.filter(
                          (node) =>
                            node?.name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase()) ||
                            node?.electronicCardSerialNo
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                        ).length > 0 ? (
                          <>
                            {data?.undefined?.map((node, index) => {
                              if (
                                node?.name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase()) ||
                                node?.electronicCardSerialNo
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                                return (
                                  <UndefinedCard
                                    isEquipment={true}
                                    key={index}
                                    name={node?.name}
                                    description={node?.electronicCardSerialNo}
                                    isUndefined={true}
                                    avatar={""}
                                    editOnClick={() => {
                                      setIsOpen(true);
                                      setSelectedNode(node);
                                    }}
                                  />
                                );
                            })}
                          </>
                        ) : (
                          <div className="w-full h-full flex items-center justify-center ">
                            <NoData
                              theme={"machine"}
                              header={t("step:noDataFound")}
                              button={false}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="w-full items-center flex flex-col gap-y-3 max-h-[280px] min-w-[385px] sm:w-[280px] overflow-scroll scrollbar-hide">
                    {isFetching ? (
                      <div className="flex flex-col gap-0 items-center justify-center w-full h-full">
                        {generateSkeleton(4, 64, 385)}
                      </div>
                    ) : (
                      <>
                        {data?.defined?.filter(
                          (node) =>
                            (
                              node?.metadata?.brand +
                              " " +
                              node?.metadata?.model
                            )
                              ?.toLowerCase()
                              .includes(searchQuery?.toLowerCase()) ||
                            node?.name
                              ?.toLowerCase()
                              .includes(searchQuery?.toLowerCase()) ||
                            node?.process?.name
                              ?.toLowerCase()
                              .includes(searchQuery?.toLowerCase())
                        ).length > 0 ? (
                          <>
                            {data?.defined?.map((node, index) => {
                              if (
                                (
                                  node?.metadata?.brand +
                                  " " +
                                  node?.metadata?.model
                                )
                                  ?.toLowerCase()
                                  .includes(searchQuery?.toLowerCase()) ||
                                node?.name
                                  ?.toLowerCase()
                                  .includes(searchQuery?.toLowerCase()) ||
                                node?.process?.name
                                  ?.toLowerCase()
                                  .includes(searchQuery?.toLowerCase())
                              )
                                return (
                                  <DefinedCard
                                    isEquipment={true}
                                    key={index}
                                    name={
                                      node?.metadata?.brand +
                                      " " +
                                      node?.metadata?.model
                                    }
                                    process={node?.process?.name}
                                    avatar={node?.metadata?.image}
                                    editOnClick={() => {
                                      setSelectedNode(node);
                                      setIsOpen(true);
                                    }}
                                    deleteOnclick={() => {
                                      setIsDelete(true);
                                      setDeleteNode(node?.id);
                                    }}
                                    isAlert={isAlert}
                                    setIsAlert={setIsAlert}
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    openAlert={openAlert}
                                    setOpenAlert={setOpenAlert}
                                  />
                                );
                            })}
                          </>
                        ) : (
                          <div className="w-full h-full flex items-center justify-center ">
                            <NoData
                              theme={"machine"}
                              header={t("step:noDataFound")}
                              button={false}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              }
            />
          ) : (
            <StepNoData
              header={t("addEquipment:title")}
              text={t("addProcess:equipmentStep")}
              // onClick={() => setIsOpen(true)}
              onClick={() => Toast("warning", t("alert:warning"))}
            />
          )}
        </>
      )}

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={
          selectedNode ? t("addEquipment:updateEq") : t("addEquipment:title")
        }
        width="fit-content"
        children={
          <AddEquipmentModal
            t={t}
            setIsOpen={setIsOpen}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            refetch={refetch}
          />
        }
      />

      <AlertModal
        title={t("equipments:deleteEquipment")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isDelete}
        setIsOpen={setIsDelete}
        applyButton={() => {
          // onDeleted(deleteNode);
          // setSelectedNode(selectedNode?.id);
        }}
      />
    </>
  );
};

export default Equipments;
