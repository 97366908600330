import React, { useEffect, useState } from "react";
import { rawMaterialService } from "../../../services/raw-material.service";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import QtyInput from "../../../components/inputs/QtyInput";
import { Button, Input } from "../../../components";
import EndDatePicker from "../../person/components/EndDatePicker";
import moment from "moment";
import { CalendarIcon } from "../../../assets/icons/PersonIcons";
import { orderService } from "../../../services/order.service";
import Selector from "../../../components/inputs/Selector";
import PersonnelAndEquipmentSelector from "../../../components/inputs/PersonnelAndEquipmentSelector";
import TextArea from "../../../components/inputs/TextArea";
import { instance } from "../../../libs/client";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";
import NoAvatar from "../../../components/avatar/NoAvatar";
const NewPurchaseStart = ({ image, name, code, requestId, refetch, close }) => {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState([]);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visible, setVisible] = useState(false);
  const handleSupplier = async () => {
    await instance.get("purchase").then((res) => {
      setSuppliers(
        res.data?.map((item) => {
          return { label: item?.name, value: item?.id, image: item?.image };
        })
      );
    });
  };
  //   {supplier, orderedUnit, unitCost, requestId, desc, estimatedDeliveryDate}
  const schema = yup.object({
    // unitType: yup
    //   .string()
    //   .required(`${t("validation:empty")}`)
    //   .typeError(`${t("validation:string")}`),
    unitCost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    supplier: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    estimatedDeliveryDate: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    orderedUnit: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const priceOptions = [
    { label: "USD", value: "usd" },
    { label: "EUR", value: "eur" },
    { label: "GBP", value: "gbp" },
    { label: "RUB", value: "rub" },
    { label: "UAH", value: "uah" },
    { label: "TRY", value: "try" },
  ];

  const onSubmit = async (value) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  useEffect(() => {
    handleSupplier();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[500px] flex flex-col "
    >
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:addTheSupplier")}
      </p>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />

      <div className="flex flex-row gap-x-2 items-center w-[30%] h-full mt-4">
        {image ? (
          <img
            src={generateFileUrl(image)}
            className="min-w-[60px] w-[60px] h-[45px] rounded border border-secondary-300 object-cover"
          />
        ) : (
          <div className="w-[60px] min-w-[60px] h-[45px] max-h-[45px] flex rounded  overflow-hidden">
            <NoAvatar
              name={name || "---"}
              rounded={4}
              theme={"product"}
              minHeight="45px"
              minWidth="60px"
              fontSize={24}
            />
          </div>
        )}
        <div className="flex flex-col items-start">
          <p className="text-lg text-secondary-900 w-[180px] truncate font-medium">
            {name || "---"}
          </p>
          <p className="text-sm text-secondary-600">
            {t("product:stockId")}: {code || "---"}
          </p>
        </div>
      </div>
      <div className="flex flex-col mt-5 w-full gap-y-4">
        {/* <div className="flex flex-col gap-y-[6px] w-full">
          <p className="text-sm font-medium text-secondary-700">Tahmini Teslimat Tarihi</p>
          <div className="flex flex-row w-full">
            <EndDatePicker
              date={date}
              visible={visible}
              setDate={setDate}
              setVisible={setVisible}
              buttonClassName={"flex flex-col items-center"}
              calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
              visibleFooter
            >
              <Button
                onClick={() => {
                  setVisible(!visible);
                }}
                iconLeft={<CalendarIcon />}
                size={"md"}
                colorType={"secondary-gray"}
                label={moment(date).format("DD.MM.YYYY")}
              />
            </EndDatePicker>
          </div>
        </div> */}
        <hr className="bg-secondary-200 w-full h-px" />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <PersonnelAndEquipmentSelector
              value={value}
              items={suppliers}
              icon={"machine"}
              image={""}
              theme={"product"}
              label={t("product:supplier")}
              onChange={onChange}
              onBlur={onBlur}
              validate={errors?.supplier ? "error" : ""}
              errorMessage={errors?.supplier ? errors?.supplier?.message : ""}
            />
          )}
          name="supplier"
        />

        <div className="flex flex-row items-center gap-x-3 w-full">
          {/* <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                theme={"product"}
                items={priceOptions}
                label={"Birim"}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitType ? "error" : ""}
                errorMessage={errors?.unitType ? errors?.unitType?.message : ""}
              />
            )}
            name="unitType"
          /> */}
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:price")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitCost ? "error" : ""}
                errorMessage={errors?.unitCost ? errors?.unitCost?.message : ""}
              />
            )}
            name="unitCost"
          />
        </div>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              value={value}
              theme={"product"}
              label={t("product:quantitys")}
              onChange={onChange}
              setValue={setValue}
              onBlur={onBlur}
              validate={errors?.orderedUnit ? "error" : ""}
              errorMessage={
                errors?.orderedUnit ? errors?.orderedUnit?.message : ""
              }
            />
          )}
          name="orderedUnit"
        />
        <div className="flex flex-col gap-2">
          <p className="text-sm font-medium text-secondary-700">
            {t("product:estimatedDeliveryDate")}
          </p>
          <EndDatePicker
            date={date}
            visible={visible}
            setDate={(e) => {
              setValue("estimatedDeliveryDate", moment(e).format("YYYY-MM-DD"));
              setDate(e);
            }}
            setVisible={setVisible}
            buttonClassName={"flex flex-col items-center"}
            calendarClassName={
              "absolute bottom-12 rounded-lg shadow-xl z-[600]"
            }
          >
            <Button
              onClick={() => {
                setVisible(!visible);
              }}
              iconLeft={<CalendarIcon />}
              size={"md"}
              colorType={"secondary-gray"}
              label={moment(date).format("DD.MM.YYYY")}
            />
          </EndDatePicker>
        </div>

        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                value={value}
                theme={"product"}
                label={t("product:description")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.description ? "error" : ""}
                errorMessage={
                  errors?.description ? errors?.description?.message : ""
                }
              />
            )}
            name="description"
          />
        </div>
      </div>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />

      <div className="w-[100%] flex items-center  gap-3 mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          // onClick={onClose}
        />
        <Button
          type={"submit"}
          //   onClick={onSubmit}
          label={t("buttons:starts")}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};

export default NewPurchaseStart;
