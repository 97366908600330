import React from "react";
import { Controller } from "react-hook-form";
import { Button, Input, KpiInput } from "../../../components";
import Selector from "../../../components/inputs/Selector";

const SettingProcessInfo = ({
  control,
  t,
  setIsOpen,
  setValue,
  setSelectedTab,
  setEquipmentType,
}) => {
  const options = [
    { value: "automatic_equipment", label: t("equipmentTypes:automatic") },
    { value: "manual_equipment", label: t("equipmentTypes:manuel") },
    { value: "passive_equipment", label: t("equipmentTypes:passive") },
    { value: "assembly_equipment", label: t("equipmentTypes:assembly") },
    { value: "hand_tools_equipment", label: t("equipmentTypes:handTools") },
  ];

  return (
    <>
      <div className="flex flex-col h-full w-full gap-6 pt-6">
        <div className="w-full h-full flex-row flex justify-between items-start gap-6 xs:flex-col xs:h-[415px] overflow-auto scrollbar-hide">
          {/*first container*/}
          <div className="flex flex-col items-start gap-4 w-full">
            <Controller
              control={control}
              render={({ field: { onBlur, value, onChange } }) => (
                <Input
                  label={t("addProcess:processName")}
                  placeholder={t("addProcess:processName")}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  height="200px"
                  disabled={false}
                />
              )}
              name="name"
            />

            <Controller
              control={control}
              render={({ field: { onBlur, value } }) => (
                <Selector
                  items={options}
                  label={t("addProcess:type")}
                  lable={t("addProcess:type")}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    setValue("type", e);
                    setEquipmentType(e);
                  }}
                  height="200px"
                />
              )}
              name="type"
            />
          </div>

          {/*act as divider*/}
          <div className=" h-full border border-solid border-[#EAECF0] xs:hidden dark:border-[#292929]" />

          {/*second container*/}
          <div className={`w-full h-full flex flex-col gap-y-4 `}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <KpiInput
                  label={t("addProcess:utilization")}
                  // placeholder="10"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
              name="utilization"
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <KpiInput
                  label={t("addProcess:measurable")}
                  // placeholder="10"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
              name="measurable"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <KpiInput
                  label={t("addProcess:performance")}
                  // placeholder="10"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
              name="performance"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <KpiInput
                  label={t("addProcess:avaibility")}
                  // placeholder="10"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
              name="availability"
            />
          </div>
        </div>

        <div className="w-full flex flex-row gap-3 items-center">
          <Button
            label={t("buttons:stop")}
            colorType={"secondary-gray"}
            onClick={() => setIsOpen(false)}
          />
          <Button
            label={t("buttons:next")}
            colorType={"primary-machine"}
            onClick={() => {
              setSelectedTab("processResponsible");
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SettingProcessInfo;
