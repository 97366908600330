import React from "react";
import {
  DetailCcountIcon,
  DetailCompanyIcon,
  DetailConsumeIcon,
  DetailEnergyIcon,
  DetailOperationIcon,
} from "../../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";

const EquipmentDetailTable = ({ energyData, isLoading }) => {
  const { t } = useTranslation();
  return isLoading ? (
    <div className="w-full h-[142px] rounded-lg bg-gray-200 animate-pulse" />
  ) : (
    <div className="flex flex-col w-full border-secondary-200 border rounded-lg shadow">
      <div className="flex w-full">
        <div className="flex flex-row w-1/2 border-r border-secondary-200 items-center gap-3 px-6 py-1">
          <DetailCcountIcon color="#B54708" width={24} height={24} />
          <div className="flex flex-col whitespace-nowrap">
            <p className="text-[#475467] text-xs font-normal"> C-Count </p>
            <p className="text-[#B54708] text-sm font-semibold">
              %{energyData?.cCount?.toFixed(2) || "-- "}
              <span className="text-[#475467] font-normal"> kgs</span>
            </p>
          </div>
        </div>
        <div className="flex flex-row w-1/2 items-center gap-3 px-6 py-1">
          <DetailConsumeIcon color="#6941C6" />
          <div className="flex flex-col whitespace-nowrap">
            <p className="text-[#475467] text-xs font-normal">
              {t("settingTab:consume")}
            </p>
            <p className="text-[#6941C6] text-sm font-semibold">
              {/* %{energyData?.cCount?.toFixed(3) || "-- "} */}--
              <span className="text-[#475467] font-normal"> kgs</span>
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between h-full bg- w-full border-y border-secondary-200 bg-secondary-50">
        <div className="flex flex-row w-1/2 items-center gap-3 px-6 py-1">
          <DetailEnergyIcon color="#3E4784" width={24} height={24} />
          <div className="flex flex-col">
            <p className="text-[#475467] text-xs font-normal">
              {t("settingTab:energy")}
            </p>
            <p className="text-[#3E4784] text-sm font-semibold">
              {energyData?.kwh?.toFixed(3) || "-- "}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center w-1/2 px-6 py-1">
          <div className="flex gap-x-8 ">
            <div className="flex flex-col">
              <p className="text-[#475467] text-xs font-normal">
                {t("loss:costt")}
              </p>
              <p className="text-[#3E4784] text-sm font-semibold">
                {energyData?.kwhCostT1?.toFixed(2) || "-- "} ₺
              </p>
            </div>
            <div className="flex flex-col ">
              <p className="text-[#475467] text-xs font-normal whitespace-nowrap">
                {t("loss:costThree")}
              </p>
              <p className="text-[#3E4784] text-sm font-semibold">
                {energyData?.kwhCostForT3?.toFixed(2) || "-- "} ₺
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full">
        <div className="flex flex-row w-1/2 border-r border-secondary-200 items-center gap-3 px-6 py-1">
          <DetailOperationIcon color="#475467" width={24} height={24} />
          <div className="flex flex-col">
            <p className="text-[#475467] text-xs font-normal">
              {" "}
              {t("loss:operation")}
            </p>
            <p className="text-[#344054] text-sm font-semibold">
              %{energyData?.kwhRatioByOperation?.toFixed(0) || "-- "}
            </p>
          </div>
        </div>
        <div className="flex flex-row w-1/2 items-center gap-3 px-6 py-1">
          <DetailCompanyIcon color="#475467" width={24} height={24} />
          <div className="flex flex-col">
            <p className="text-[#475467] text-xs font-normal">
              {t("settingTab:company")}
            </p>
            <p className="text-[#344054] text-sm font-semibold">
              %{energyData?.kwhRatioByCompany?.toFixed(0) || "-- "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EquipmentDetailTable);
