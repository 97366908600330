import React from "react";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { formatSeconds } from "../../../../utils/secondToHoursAndMinute";
import moment from "moment";

function TimeLineTooltip({showTooltip,}) {
  return (
    <>
      <div className="flex items-center mb-1 ">
        <div
          style={{
            width: "12px",
            height: "12px",
            backgroundColor: showTooltip?.index?.color,
            borderRadius: 2,
          }}
        ></div>

        <p
          className="text-md font-semibold"
          style={{
            color: showTooltip?.index?.color,
            marginLeft: "6px",
          }}
        >
          {showTooltip?.index?.name}
        </p>
      </div>
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex w-full border-b border-[#EAECF0]">
          <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
            Start-End Time
          </div>
          <div className="flex min-w-[142px] max-w-[142px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
            <span>
              {moment(
                timeFormatter(showTooltip?.index?.startDate).formatted
              ).format("HH:mm:ss")}
            </span>
            -
            <span>
              {moment(
                timeFormatter(showTooltip?.index?.endDate).formatted
              ).format("HH:mm:ss")}
            </span>
          </div>
        </div>
        <div className="flex w-full">
          <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
            Total Time
          </div>
          <div className="flex min-w-[142px] max-w-[142px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
            <span>
              {formatSeconds(
                moment(
                  timeFormatter(showTooltip?.index?.endDate).formatted
                ).diff(
                  moment(
                    timeFormatter(showTooltip?.index?.startDate).formatted
                  ),
                  "seconds"
                )
              )}
            </span>
            -
            <span>
              {moment(
                timeFormatter(showTooltip?.index?.endDate).formatted
              ).diff(
                moment(timeFormatter(showTooltip?.index?.startDate).formatted),
                "seconds"
              ) + " sn"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeLineTooltip;
