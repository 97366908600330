
import React, { useEffect, useState } from "react";
import MultipleEventRectMulti from "../../../components/upuTimeline/components/MultipleEventRectMulti";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import moment from "moment";
import { authStore } from "../../../stores/auth.store";

const OperationNodeTimelines = ({
  data,
  setShowTooltip,
  showTooltip,
  endTime,
  startTime,
  setStartTime,
  sendEndTime,
  selectedShift,
  setSelectedShift,
  svgWidth,
  handleMouseMove,
  handleMouseOut,
  handleShiftClick,
  junctionNumber,
  selectedJunction,
  setSelectedJunction,
  yOffset,
}) => {
  const handleMouseOver = (index, xPos, yPos, e) => {
    if (index.index == 0) {
      values.filter((e) => {
        if (e.junctionId == index.junctionId) {
          setShowTooltip({ index, xPos, yPos, junction: e });
        }
      });
    } else {
      setShowTooltip({ index, xPos, yPos });
    }
  };

  const _ = require("lodash");
  const [values, setValues] = useState([]);

  const calculateData = async (data) => {
    const datas = [];

    data?.map((value, no) => {
      value?.value.map((da, i) => {
        if (da?.type === "losses") {
        }
        datas.push({
          name: value?.name,
          type:
            da?.index == 1
              ? "status"
              : da.index == 2
              ? "shift"
              : da.index == 0
              ? "junction"
              : "",
          name: value?.name,
          status: value?.status,
          node: value.node,
          junctionId: da?.junctionId,
          lossesId: da?.lossesId,
          lossesName: da?.lossesName,
          avatar: da?.avatar,
          productImage: da?.productImage,
          actionId: da?.actionId,
          productData: da?.productName,
          operationNo: da?.operationNo,
          startDate: da?.start,
          endDate: da?.end,
          timeDiff: da?.timeDiff,
          index: da?.index,
          acceptedWorkCount: da?.acceptedWorkCount,
          rejectedWorkCount: da?.rejectedWorkCount,
          junctionType: da?.type,
          value: [
            da?.index,

            /// moment(da.start).unix(),
            // moment(da.end).unix(),
            moment(
              timeFormatter(da.start, authStore?.user?.company?.timeZone)
                .formatted
            )?.unix(),
            moment(
              timeFormatter(da.end, authStore?.user?.company?.timeZone)
                .formatted
            )?.unix(),
            da.timeDiff,
          ],
          shiftId: da?.shiftId,
          color:
            da?.index == 1
              ? value?.itemStyle?.normal?.color
              : da?.index == 2
              ? value?.itemStyle?.normal?.color
              : da?.index == 0
              ? da?.type == "setup"
                ? "#EAAA08"
                : da.type == "losses"
                ? "#D92D20"
                : "#12B76A"
              : value?.itemStyle?.normal?.color,
        });
      });
    });

    const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);
    const lastValues = _.uniqWith(Valuess, _.isEqual);

    await setValues(Valuess);
    //   await  setTimelineValues(timelineDatas)
  };

  useEffect(() => {
    calculateData(data);
  }, []);

  const filteredValues = values?.filter((shift) => shift?.type !== "shift");

  return filteredValues?.map((shift, index) => {
    const { startDate, endDate, color, timeDiff } = shift;
    const shiftStartTime = new Date(
      timeFormatter(startDate)?.formatted
    ).getTime();
    const shiftEndTime = new Date(timeFormatter(endDate)?.formatted).getTime();

    const timeRange = endTime - startTime;

    if (selectedShift !== null && selectedShift !== index) {
      // Seçili event dışındaki diğer eventleri gösterme
      return null;
    }

    const shiftX =
      selectedShift === index
        ? 0
        : ((shiftStartTime - startTime) / timeRange) * svgWidth;

    const statusWidth =
      ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

    return (
      <>
        <MultipleEventRectMulti
          key={index}
          yOffset={yOffset}
          startPos={shiftX}
          width={statusWidth}
          widthS={statusWidth}
          event={shift}
          onClick={() => {
           
              handleShiftClick(shift);
              setSelectedJunction(shift);

          }}
          handleMouseOver={handleMouseOver}
          handleMouseOut={handleMouseOut}
          handleMouseMove={handleMouseMove}
          junctionNumber={junctionNumber}
          selectedJunction={selectedJunction}
        />
      </>
    );
  });
};

export default OperationNodeTimelines;

