import React, { memo, useMemo, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { CustomModal } from "../../../components";
import OperationDetailModal from "../modals/OperationDetailModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ClockIcon,
  ClockIconStart,
  PlaySquareIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../../assets/icons/productIcons";
import {
  formatSeconds,
  legacyFormatSeconds,
} from "../../../utils/secondToHoursAndMinute";
import { manipulate } from "../../../utils/manipulate";

const InternalOperationCard = memo(
  ({
    status,
    iconKey,
    name,
    operations,
    filledOperations,
    orderData,
    totalEstimatedTime,
    listOrder,
    totalJunctionTime,
    showOperationDetail = true,
  }) => {
    const { t } = useTranslation();
    const [operationDetailModal, setIsOperationDetailModal] = useState(false);
    const hours = useMemo(() =>
      Math.floor(operations?.totalEstimatedTime / 3600)
    );
    const hoursJunctionTime = useMemo(() =>
      Math.floor(operations?.totalJunctionTime / 3600)
    );

    const navigate = useNavigate();
    const statusColor = () => {
      switch (status) {
        case "finished":
          return "#12B76A";
        case "started":
          return "#FDE172";
        case "rejected":
          return "#D92D20";
        default:
          return "#D0D5DD";
      }
    };

    const qualityStatus = () => {
      if (operations?.hasQualityControl) {
        return "#17B26A";
      } else {
        return "#98A2B3";
      }
    };

    // const qualityStatus = useMemo(() => {
    //   return operations?.hasQualityControl ? "#17B26A" : "#98A2B3";
    // }, [operations?.hasQualityControl]);

    return (
      <>
        <div
          onClick={() => {
            showOperationDetail &&
              navigate(
                `/app/product/order/detail/${operations?.id}+${orderData?.id}`
              );
          }}
          className="bg-white  flex w-full flex-col h-full cursor-pointer"
        >
          <div
            style={{
              backgroundColor: statusColor(),
            }}
            className={`h-1 max-h-1 min-h-[4px]  rounded-t`}
          ></div>
          <div className="flex w-full h-full border-x pl-3 pr-1 py-1 justify-between">
            <div className="flex flex-col w-[184px] h-full">
              <div className="w-full flex items-center overflow-hidden">
                <div className="w-4 h-4 flex items-center justify-center">
                  <Process />
                </div>
                <p className="text-sm text-secondary-700 font-semibold truncate ml-1.5">
                  {/* {name?.slice(0, 2)+ " ***"} */}
                  {manipulate(name)}
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex border-b">
                  <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">
                    {t("product:previousIncoming")}
                  </div>
                  <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                    <span className="font-medium text-xs text-[#475467]">
                      {hours}
                    </span>
                    <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                      {t("product:hours")}
                    </span>
                  </div>
                  <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                    <span className="font-medium text-xs text-[#475467]">
                      {operations?.previousPiece}
                    </span>
                    <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                      {t("product:pcs")}
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">
                    {t("product:applied")}
                  </div>
                  <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                    <span className="font-medium text-xs text-[#475467]">
                      {hoursJunctionTime}
                    </span>
                    <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                      {t("product:hours")}
                    </span>
                  </div>
                  <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                    <span className="font-medium text-xs text-[#475467]">
                      {operations?.qualityAcceptedPiece}
                    </span>
                    <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                      {t("product:pcs")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col border-[0.5px] w-[82px] h-[68px] px-[6px] py-[2px] gap-y-2 rounded">
              <div className="flex gap-x-1 items-center justify-center">
                <span
                  className={`w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[${qualityStatus()}] rounded-[2px]`}
                ></span>
                <p className="text-[#475467] font-normal text-xs">
                  {t("reports:quality")}
                </p>
              </div>
              <div className="flex flex-col gap-y-[2px]">
                <div className="flex gap-x-[2px] items-center">
                  <QuantityAcceptedIcon />
                  <span className="text-xs text-[#079455]">
                    {operations?.hasQualityControl
                      ? operations?.qualityAcceptedPiece
                      : "--"}
                  </span>
                  <span className="text-[10px] leading-[18px] text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
                <div className="flex gap-x-[2px] items-center">
                  <QuantityDeclineIcon />
                  <span className="text-xs text-[#D92D20]">
                    {operations?.hasQualityControl
                      ? operations?.qualityRejectedPiece
                      : "--"}
                  </span>
                  <span className="text-[10px] leading-[18px] text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="border min-h-[26px] max-h-[26px] rounded-b-lg bg-[#F9FAFB] pl-3 pr-2 py-1 flex justify-between items-center">
            <div className="flex h-full items-center gap-x-[2px]">
              <ClockIcon />
              <span className="text-xs text-[#344054] font-medium">
                {legacyFormatSeconds(
                  operations?.internalOperation?.estimatedTime
                )}
              </span>
              <span className="text-[10px] leading-[18px] text-[#475467]">
                {t("product:targetMin")}
              </span>
            </div>
            <div
              className={`flex h-full items-center gap-x-[2px] ${
                operations?.newAvgActualCalculate == null ||
                operations?.newAvgActualCalculate === 0
                  ? "text-[#7F7F7F]"
                  : operations?.internalOperation?.estimatedTime >
                    operations?.newAvgActualCalculate
                  ? "text-[#079455]"
                  : "text-[#D92D20]"
              }`}
            >
              <ClockIconStart />
              <span className="text-xs font-medium">
                {legacyFormatSeconds(operations?.newAvgActualCalculate)}
              </span>
              <span className="text-[10px] leading-[18px] text-[#475467]">
                {t("product:minPieceAvg")}
              </span>
            </div>
            <div className="flex h-full items-center gap-x-[2px]">
              {/* <PlaySquareIcon /> */}
              <span className="text-[10px] leading-[18px] text-[#475467]">
                {t("product:events")}:
              </span>
              <span className={`text-[10px] text-success-500 font-medium`}>
                {operations?.acceptedJunctionLength || "--"}
              </span>

              <span className="text-[10px] text-[#344054] font-medium">
                /{operations?.totalJunctionLength || "--"}
              </span>
            </div>
          </div>
        </div>

        <CustomModal
          isOpen={operationDetailModal}
          setIsOpen={setIsOperationDetailModal}
          modalTitle={t("product:operationDetail")}
          children={
            <OperationDetailModal
              t={t}
              listOrder={listOrder}
              operations={operations}
              filledOperations={filledOperations}
              orderData={orderData}
            />
          }
        />
      </>
    );
  }
);

export default memo(InternalOperationCard);

const Process = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.3842 0.667969H3.61452C1.98611 0.667969 0.666016 1.98806 0.666016 3.61648V14.3861C0.666016 16.0145 1.98611 17.3346 3.61452 17.3346H14.3842C16.0126 17.3346 17.3327 16.0145 17.3327 14.3861V3.61648C17.3327 1.98806 16.0126 0.667969 14.3842 0.667969Z"
        stroke="#475467"
        stroke-width="1.33"
        stroke-miterlimit="10"
      />
      <path
        d="M14.61 9H3.38672"
        stroke="#475467"
        stroke-width="1.33"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M11.9473 6.05122C11.9473 6.05122 11.9092 5.78294 10.8053 4.6726C9.70143 3.56226 7.91172 3.56226 6.80785 4.6726C6.41675 5.066 6.16422 5.54599 6.05025 6.05122M11.9473 6.05122V4.15576M11.9473 6.05122H10.0629"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.05078 11.9488C6.05078 11.9488 6.08888 12.2171 7.19275 13.3274C8.29661 14.4377 10.0863 14.4377 11.1902 13.3274C11.5813 12.934 11.8338 12.454 11.9478 11.9488M6.05078 11.9488V13.8442M6.05078 11.9488H7.9352"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QualityAccepted = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M4.8125 1.75H3.28571C2.57563 1.75 2 2.32563 2 3.03571V8.90179C2 9.61187 2.57563 10.1875 3.28571 10.1875H9.71429C10.4244 10.1875 11 9.61187 11 8.90179V3.03571C11 2.32563 10.4244 1.75 9.71429 1.75H8.1875M4.8125 1.75V4.02098C4.8125 4.06877 4.86279 4.09985 4.90554 4.07848L6.5 3.28125L8.09446 4.07848C8.13721 4.09985 8.1875 4.06877 8.1875 4.02098V1.75M4.8125 1.75H8.1875"
        stroke={color || "#079455"}
        strokeWidth="0.75"
      />
      <path
        d="M5 7.09615L6.07692 8.17308L8.5 5.75"
        stroke={color || "#079455"}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const QualityRejected = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M4.8125 1.75H3.28571C2.57563 1.75 2 2.32563 2 3.03571V8.90179C2 9.61187 2.57563 10.1875 3.28571 10.1875H9.71429C10.4244 10.1875 11 9.61187 11 8.90179V3.03571C11 2.32563 10.4244 1.75 9.71429 1.75H8.1875M4.8125 1.75V4.02098C4.8125 4.06877 4.86279 4.09985 4.90554 4.07848L6.5 3.28125L8.09446 4.07848C8.13721 4.09985 8.1875 4.06877 8.1875 4.02098V1.75M4.8125 1.75H8.1875"
        stroke={color || "#D92D20"}
        strokeWidth="0.75"
      />
      <path
        d="M7.75 5.75L5.25 8.25M5.25 5.75L7.75 8.25"
        stroke={color || "#D92D20"}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
