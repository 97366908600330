import React, { useState } from "react";
import { Button, Input, TimeInput } from "../../../components";
import { Controller, useForm } from "react-hook-form";
import Selector from "../../../components/inputs/Selector";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { orderService } from "../../../services/order.service";
import { Toast } from "../../../utils/toastify/toast";
import { NoEquIcon } from "../../../assets/icons/machineIcons";

const ExternalInternalRequestForm = ({
  purchaseData,
  product,
  closeModal,
  deliveryDate,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visible, setVisible] = useState(false);

  const schema = yup.object({
    unitType: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    unitPrice: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    date: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    cost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    productCost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const units = [
    { label: "Kilogram", shortLabel: "kg", value: "kg" },
    { label: t("product:metricTon"), shortLabel: "ton", value: "ton" },
    { label: "Gram", shortLabel: "gr", value: "gr" },
    { label: "Miligram", shortLabel: "mg", value: "mg" },
    { label: "Microgram", shortLabel: "mcg", value: "mcg" },
    { label: "Pound", shortLabel: "lb", value: "lb" },
    { label: t("product:ounce"), shortLabel: "oz", value: "oz" },
    { label: "Metre", shortLabel: "m", value: "m" },
    { label: "Millilitre", shortLabel: "mm", value: "mm" },
    { label: t("product:gallon"), shortLabel: "gal", value: "gal" },
    { label: t("product:squareMetre"), shortLabel: "m2", value: "m2" },
    { label: t("product:squareMilimetre"), shortLabel: "mm2", value: "mm2" },
    { label: t("product:squareInch"), shortLabel: "in2", value: "mm2" },
    { label: t("product:pieceModal"), shortLabel: "ad", value: "ad" },
    { label: t("product:package"), shortLabel: "pkg", value: "pkg" },
    { label: t("product:box"), shortLabel: "box", value: "box" },
  ];

  const priceOptions = [
    {
      label: "USD",
      value: "usd",
    },
    {
      label: "EUR",
      value: "eur",
    },
    {
      label: "GBP",
      value: "gbp",
    },
    {
      label: "RUB",
      value: "rub",
    },
    {
      label: "UAH",
      value: "uah",
    },
    {
      label: "TRY",
      value: "try",
    },
  ];

  const onSubmit = async (value) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[500px] flex flex-col "
    >
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:productAndRawmaterial")}
      </p>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />

      <div className="flex flex-row w-full  mt-4 border border-secondary-300 rounded-lg pl-2 h-full ">
        <div className="w-full flex flex-col py-2">
          <div className="flex flex-row gap-x-2 items-center  ">
            {purchaseData?.process?.iconKey ? (
              <img
                src={generateFileUrl(purchaseData?.process?.iconKey)}
                className="min-w-[64px] w-[64px] h-[48px] rounded border border-secondary-300 object-cover"
              />
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <p className="text-lg text-secondary-900 w-[360px] truncate font-medium">
                {purchaseData?.process?.name || "---"}
              </p>
              <p className="text-sm text-secondary-600">
                Stock ID: {product?.stockCode || "---"}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <QtyIcon />
              <p className="text-xs font-medium text-secondary-600">
                {purchaseData?.qualityQuantity === null
                  ? 0
                  : purchaseData?.qualityQuantity}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:totalStock")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <DateIcon />
              <p className="text-xs font-medium text-secondary-600">
                {deliveryDate || "---"}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:deliveryDate")}
              </p>
            </div>
          </div>
        </div>
        <div className="h-full flex items-start border-l rounded-bl-lg border-r-0 rounded-tr-lg  border-t-0 border-b bg-[#EAECF0] border border-[#D0D5DD] p-[3px]">
          <div className=" w-6 h-[70px] flex items-center justify-center">
            <p className="-rotate-90 text-[10px] font-normal text-secondary-600 tracking-widest">
              {t("product:hammadde")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-5 w-full gap-y-4">
        {/* <div className="flex flex-col gap-y-[6px] w-full">
          <p className="text-sm font-medium text-secondary-700">
            Tahmini Teslimat Tarihi
          </p>
          <div className="flex flex-row w-[300px] h-full">
            <EndDatePicker
              date={date}
              visible={visible}
              setDate={setDate}
              setVisible={setVisible}
              buttonClassName={"flex flex-col items-center"}
              calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
              visibleFooter
            >
              <div className="w-[498px]">
                <Button
                  onClick={() => {
                    setVisible(!visible);
                  }}
                  iconLeft={<CalendarIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  label={moment(date).format("DD.MM.YYYY")}
                />
              </div>
            </EndDatePicker>
          </div>
        </div> */}
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TimeInput
              label={t("product:estimatedDeliveryDate")}
              placeholder={t("buttons:selectDate")}
              theme={"product"}
              value={moment(value).format("YYYY-MM-DD")}
              disabled={false}
              onChange={(v) => {
                setValue("date", v);
              }}
            />
          )}
          name="date"
        />

        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                theme={"product"}
                items={priceOptions}
                label={t("product:unit")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitPrice ? "error" : ""}
                errorMessage={
                  errors?.unitPrice ? errors?.unitPrice?.message : ""
                }
              />
            )}
            name="unitPrice"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:cost")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.cost ? "error" : ""}
                errorMessage={errors?.cost ? errors?.cost?.message : ""}
              />
            )}
            name="cost"
          />
        </div>

        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                theme={"product"}
                items={units}
                label={t("product:productUnit")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitType ? "error" : ""}
                errorMessage={errors?.unitType ? errors?.unitType?.message : ""}
              />
            )}
            name="unitType"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:orderQuantity")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.productCost ? "error" : ""}
                errorMessage={
                  errors?.productCost ? errors?.productCost?.message : ""
                }
              />
            )}
            name="productCost"
          />
        </div>
      </div>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />

      <div className="w-[100%] flex items-center  gap-3 mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={closeModal}
        />
        <Button
          type={"submit"}
          //   onClick={onSubmit}
          label={"Başlat"}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};

export default ExternalInternalRequestForm;

export const QtyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

export const DateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
