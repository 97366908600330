import React, { useState } from "react";
import { rawMaterialService } from "../../../services/raw-material.service";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import UnitPriceInput from "../../../components/inputs/UnitPriceInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import QtyInput from "../../../components/inputs/QtyInput";
import { Button, Input, TimeInput } from "../../../components";
import EndDatePicker from "../../person/components/EndDatePicker";
import moment from "moment";
import { CalendarIcon } from "../../../assets/icons/PersonIcons";
import { orderService } from "../../../services/order.service";
import Selector from "../../../components/inputs/Selector";
import { Toast } from "../../../utils/toastify/toast";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import {
  DateIcon,
  DateIcon2,
  QtyIcon,
} from "../../../assets/icons/productIcons";

//TYPE MAIN PRODUCT BUYUK MODALDAN KUCUK KARTLI MODALA GECIS

const NewPurchasedRequestForm = ({
  purchaseData,
  cardStockId,
  closeModal,
  stockPiece,
  deliveryDate,
}) => {
  const { t } = useTranslation();
  const [priceValue, setPriceValue] = useState("");
  const [priceCost, setPriceCost] = useState("");

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visible, setVisible] = useState(false);

  // const { isLoading, data, refetch } = useQuery({
  //   queryKey: ["getPurchaseListNew"],
  //   queryFn: async () =>
  //     await rawMaterialService.getPurchaseList(purchaseData?.id),
  //   retry: 0,
  // });

  const schema = yup.object({
    unitType: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    unitPrice: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    date: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    cost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    productCost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const units = [
    { label: "Kilogram", shortLabel: "kg", value: "kg" },
    { label: t("product:metricTon"), shortLabel: "ton", value: "ton" },
    { label: "Gram", shortLabel: "gr", value: "gr" },
    { label: "Miligram", shortLabel: "mg", value: "mg" },
    { label: "Microgram", shortLabel: "mcg", value: "mcg" },
    { label: "Pound", shortLabel: "lb", value: "lb" },
    { label: t("product:ounce"), shortLabel: "oz", value: "oz" },
    { label: "Metre", shortLabel: "m", value: "m" },
    { label: "Millilitre", shortLabel: "mm", value: "mm" },
    { label: t("product:gallon"), shortLabel: "gal", value: "gal" },
    { label: t("product:squareMetre"), shortLabel: "m2", value: "m2" },
    { label: t("product:squareMilimetre"), shortLabel: "mm2", value: "mm2" },
    { label: t("product:squareInch"), shortLabel: "in2", value: "mm2" },
    { label: t("product:pieceModal"), shortLabel: "ad", value: "ad" },
    { label: t("product:package"), shortLabel: "pkg", value: "pkg" },
    { label: t("product:box"), shortLabel: "box", value: "box" },
  ];

  const priceOptions = [
    {
      label: "USD",
      value: "usd",
    },
    {
      label: "EUR",
      value: "eur",
    },
    {
      label: "GBP",
      value: "gbp",
    },
    {
      label: "RUB",
      value: "rub",
    },
    {
      label: "UAH",
      value: "uah",
    },
    {
      label: "TRY",
      value: "try",
    },
  ];

  const onSubmit = async (value) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[500px] flex flex-col "
    >
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:productAndRawmaterial")}
      </p>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />
      <div className="flex flex-row w-full  mt-4 border border-secondary-300 rounded-lg pl-2 h-full ">
        <div className="w-full flex flex-col py-2">
          <div className="flex flex-row gap-x-2 items-center  ">
            {purchaseData?.image ? (
              <img
                src={generateFileUrl(purchaseData?.image)}
                className="min-w-[64px] w-[64px] h-[48px] rounded border border-secondary-300 object-cover"
              />
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <p className="text-lg text-secondary-900 w-[360px] truncate font-medium">
                {purchaseData?.name || "---"}
              </p>
              <p className="text-sm text-secondary-600">
                Stock ID: {purchaseData?.code || "---"}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <QtyIcon />
              <p className="text-xs font-medium text-secondary-600">
                {purchaseData?.qualityQuantity === null
                  ? 0
                  : purchaseData?.qualityQuantity}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:totalStock")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <DateIcon2 />
              <p className="text-xs font-medium text-secondary-600">
                {deliveryDate || "---"}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:deliveryDate")}
              </p>
            </div>
          </div>
        </div>
        <div className="h-full flex items-start border-l rounded-bl-lg rounded-tr-lg border-r-0 border-t-0 border-b bg-[#EAECF0] border border-[#D0D5DD] p-[3px]">
          <div className=" w-6 h-[70px] flex items-center justify-center">
            <p className="-rotate-90 text-[10px] font-normal text-secondary-600 tracking-widest">
              {t("product:hammadde")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-5 w-full gap-y-4">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TimeInput
              label={t("product:expectedDelivery")}
              placeholder={t("buttons:selectDate")}
              theme={"product"}
              value={moment(value).format("YYYY-MM-DD")}
              disabled={false}
              onChange={(v) => {
                setValue("date", v);
              }}
            />
          )}
          name="date"
        />

        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                theme={"product"}
                items={priceOptions}
                label={t("product:unit")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitPrice ? "error" : ""}
                errorMessage={
                  errors?.unitPrice ? errors?.unitPrice?.message : ""
                }
              />
            )}
            name="unitPrice"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:cost")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.cost ? "error" : ""}
                errorMessage={errors?.cost ? errors?.cost?.message : ""}
              />
            )}
            name="cost"
          />
        </div>

        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                theme={"product"}
                items={units}
                label={t("product:productUnit")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitType ? "error" : ""}
                errorMessage={errors?.unitType ? errors?.unitType?.message : ""}
              />
            )}
            name="unitType"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:orderQuantity")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.productCost ? "error" : ""}
                errorMessage={
                  errors?.productCost ? errors?.productCost?.message : ""
                }
              />
            )}
            name="productCost"
          />
        </div>
      </div>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />

      <div className="w-[100%] flex items-center  gap-3 mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={closeModal}
        />
        <Button
          type={"submit"}
          //   onClick={onSubmit}
          label={t("buttons:starts")}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};

export default NewPurchasedRequestForm;
