import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../../components";
import { Toast } from "../../utils/toastify/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { companyService } from "../../services/company.service";
import { Loader } from "../../assets/icons/stepsIcons";

const SettingKPI = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const getCompanyData = async () => {
    await companyService
      .getCompany()
      .then((res) => {
        setValue("firmPlanned", res.data?.kpiPoints?.firm?.planned);
        setValue("firmTeorical", res.data?.kpiPoints?.firm?.teorical);
        setValue("firmPerformance", res.data?.kpiPoints?.firm?.performance);
        setValue("userPerformance", res.data?.kpiPoints?.person?.performance);
      })
      .catch((err) => console.log(err));
  };

  const schema = yup.object({
    firmPlanned: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    firmTeorical: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    firmPerformance: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    userPerformance: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    
  };
  useEffect(() => {
    getCompanyData();
  }, [getCompanyData]);
  return (
    <>
      {loading ? (
        <div className="flex items-center mt-64 justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full max-h-[730px] flex flex-col gap-5">
          <div className="flex items-center justify-between h-[72px] border-b w-full border-secondary-200">
            <div className="flex flex-col w-1/2">
              <p className="text-gray-900 text-lg font-medium dark:text-[#FAFAFA]">
                KPI
              </p>
              <p className="text-gray-500 text-sm dark:text-[#D6D6D6]">
                {t("settingTab:updateYourKpi")}
              </p>
            </div>
            <div className="w-1/2 flex flex-row items-center gap-3 justify-end ">
              <div className="w-[30%] xs:w-full sm:w-[65%] md:w-1/2 lg:w-1/2 xs:hidden">
                <Button
                  colorType={"primary-machine"}
                  size={"lg"}
                  label={t("buttons:saveChanges")}
                  // type={"submit"}
                  // onClick={handleSubmit(onSubmit)}
                  onClick={()=> Toast("warning", "Demo users are not authorized.")}
                />
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-start gap-6 w-full h-[75%]"
          >
            <div className="flex flex-col items-start w-full gap-5">
              <div className="flex flex-row xs:flex-col items-start w-full  gap-3 dark:border-b-[#292929]">
                <p className="w-[28%] dark:text-[#E5E5E5]">
                  {" "}
                  {t("settingsKpi:firmWide")}
                </p>
                <div className="w-[28%] sm:w-[40%] xs:w-full">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        onChange={onChange}
                        blur="blur-sm"
                        value={value}
                        validate={errors.name ? "error" : ""}
                        errorMessage={errors.name ? errors.name.message : ""}
                        label={t("settingsKpi:plannedProduction")}
                      />
                    )}
                    name="firmPlanned"
                  />
                </div>
              </div>

              <div className="flex flex-row xs:flex-col items-start w-full  gap-3 dark:border-b-[#292929]">
                <p className="w-[28%] dark:text-[#E5E5E5]"></p>
                <div className="w-[28%] sm:w-[40%] xs:w-full">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors.name ? "error" : ""}
                        errorMessage={errors.name ? errors.name.message : ""}
                        label={t("settingsKpi:theoreticalProduction")}
                      />
                    )}
                    name="firmTeorical"
                  />
                </div>
              </div>

              <div className="flex flex-row  xs:flex-col items-start w-full  gap-3 dark:border-b-[#292929]">
                <p className="w-[28%] dark:text-[#E5E5E5]"></p>
                <div className="w-[28%] sm:w-[40%] xs:w-full">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors.name ? "error" : ""}
                        errorMessage={errors.name ? errors.name.message : ""}
                        label={t("settingsKpi:performance")}
                      />
                    )}
                    name="firmPerformance"
                  />
                </div>
              </div>
              <hr className="w-full h-[1px] bg-secondary-200 " />
              <div className="flex flex-row xs:flex-col items-start w-full  gap-3 dark:border-b-[#292929]">
                <p className="w-[28%] dark:text-[#E5E5E5]">
                  {t("settingsKpi:personels")}
                </p>
                <div className="w-[28%] sm:w-[40%] xs:w-full">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors.name ? "error" : ""}
                        errorMessage={errors.name ? errors.name.message : ""}
                        label={t("settingsKpi:performance")}
                      />
                    )}
                    name="userPerformance"
                  />
                </div>
              </div>
              <hr className="w-full h-[1px] bg-secondary-200 mt-1" />
            </div>

            <div className="hidden xs:flex xs:w-full">
              <div className="w-[20%] sm:w-[30%] xs:w-full">
                <Button
                  colorType={"primary-machine"}
                  size={"lg"}
                  label={t("buttons:saveChanges")}
                  onClick={handleSubmit(onSubmit)}
                  // onClick={()=> Toast("warning", "Demo users are not authorized.")}
                  // type={"submit"}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default SettingKPI;
