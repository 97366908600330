import React, { memo, useEffect, useRef, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import {
  DeleteIcon,
  InfoSquareIcon,
  LeaveGroupIcon,
  MenuIcon,
  MuteIcon,
  MuteIconNew,
  PollDeleteIcon,
  UnmuteIcon,
} from "../../../assets/icons/PersonIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { chatStore } from "../../../stores/chat.store";
import { chatService } from "../../../services/chat.service";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import {
  DangerIcon,
  EditIconMachine,
} from "../../../assets/icons/machineIcons";
import { authStore } from "../../../stores/auth.store";
import { useTranslation } from "react-i18next";
import { CameraIcon, FileIcon } from "../../../assets/icons/buttonIcons";

const PersonnelCard = observer(
  ({
    name,
    chat,
    type,
    image,
    onClick,
    refetch,
    lastMessage,
    conversationId,
    item,
    unreadCount = 0,
  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const menuRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [alertLeaveGroup, setAlertLeaveGroup] = useState(false);
    const [leaveGroupData, setLeaveGroupData] = useState({});
    const [alertTaskGroup, setAlertTaskGroup] = useState(false);
    const [unmuteAlert, setUnmuteAlert] = useState(false);
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    const deleteConversation = async () => {
     
    };

    const lastMessages = () => {
      if (lastMessage?.type === "image") {
        return (
          <p className="flex items-center truncate text-secondary-500 text-sm gap-1">
            <CameraIcon size={18} />
            Fotoğraf
          </p>
        );
      } else if (lastMessage?.type === "file") {
        return (
          <p className="flex items-center truncate text-secondary-500 text-sm gap-1">
            <FileIcon size={18} />
            Dosya
          </p>
        );
      } else {
        return (
          <p className="truncate text-secondary-500 text-sm">
            {String(lastMessage.slice(0, 3))}...
          </p>
        );
      }
    };

    const groupChatMenuItems = [
      {
        name: t("person:groupInfo"),
        icon: <InfoSquareIcon />,
        onClick: () => Toast("warning", "Demo users cannot access messages"),
      },
      {
        name:
          item?.isQuite === true
            ? t("person:unmuteGroup")
            : t("person:muteGroup"),
        icon: item?.isQuite === true ? <UnmuteIcon /> : <MuteIconNew />,
        onClick: () => Toast("warning", t("alert:warning")),
      },
      {
        name: t("person:leaveGroup"),
        icon: <LeaveGroupIcon />,
        onClick: () => Toast("warning", t("alert:warning")),
      },
      {
        name: t("buttons:delete"),
        icon: <PollDeleteIcon />,
        onClick: () => Toast("warning", t("alert:warning")),
      },
    ];
    const chatMenuItems = [
      {
        name:
          item?.isQuite === true
            ? t("person:unmutePerson")
            : t("person:muteThePerson"),
        icon: item?.isQuite === true ? <UnmuteIcon /> : <MuteIconNew />,
        onClick: () => setUnmuteAlert(true),
      },
      {
        name: t("buttons:delete"),
        icon: <PollDeleteIcon />,
        onClick: function () {
          deleteConversation();
        },
      },
    ];
    const menuItems =
      type === "group-task" ? groupChatMenuItems : chatMenuItems;
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <>
        <div ref={menuRef} className="relative">
          <NavLink
            onClick={() => {
              chat.setCurrentGroupChatId(null);
              onClick();
            }}
            to={`${conversationId}`}
            className={({ isActive }) =>
              `w-full py-3 px-2 flex group items-center cursor-pointer relative ${
                isActive ? "bg-gray-50 rounded-lg" : ""
              }`
            }
          >
            {image ? (
              <img
                className="w-[48px] min-w-[48px] h-[48px] blur-sm rounded-full border border-secondary-200"
                src={generateFileUrl(image)}
              />
            ) : (
              <NoAvatar
                size={48}
                color={"rgba(20, 20, 20, 0.70)"}
                name={name}
                fontSize={20}
              />
            )}
            <div className="h-full flex grow flex-col ml-3 overflow-hidden">
              <p className="font-semibold text-secondary-700 truncate">
                {name}
              </p>
              {lastMessages()}
            </div>
            <div
              className={`min-w-[50px]  flex flex-col items-center justify-end relative ${
                item?.isQuite === true ? "pt-0" : "pt-2"
              }`}
            >
              {/* <p className="absolute -top-4 text-xs text-secondary-500">
              1 hours
            </p> */}
              {unreadCount > 0 && (
                <span className="py-0.5 px-1 w-fit h-fit rounded border-teal-200 border bg-teal-50 text-teal-700 text-xs">
                  {unreadCount}
                </span>
              )}
              <button
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
                className={`w-[20px] h-[20px] items-center justify-center ml-1 ${
                  isOpen ? "flex" : "group-hover:flex hidden"
                }`}
              >
                <MenuIcon />
              </button>
              <div className="group-hover:hidden">
                {item?.isQuite === true && <MuteIcon />}
              </div>
            </div>
          </NavLink>
          {isOpen && (
            <div
              className="w-[190px] p-1 bg-white border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-[99999]"
              style={{ position: "absolute", top: 52, right: 0 }}
              onBlur={() => setIsOpen(false)}
            >
              {menuItems.map((item, index) => {
                return (
                  <button
                    onClick={() => {
                      // item.onClick();
                      Toast("warning", t("alert:warning"));
                      setIsOpen(false);
                    }}
                    key={index}
                    type="button"
                    className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                  >
                    <div className="w-6">{item.icon}</div>
                    {type === "group-task" ? (
                      <>
                        <p
                          className={`font-medium ${
                            index === 3 ? "text-red-500" : "text-secondary-700"
                          } text-sm `}
                        >
                          {item.name}
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          className={`font-medium ${
                            index === 1 ? "text-red-500" : "text-secondary-700"
                          } text-sm `}
                        >
                          {item.name}
                        </p>
                      </>
                    )}
                  </button>
                );
              })}
            </div>
          )}
        </div>
        <AlertModal
          icon={<DangerIcon />}
          title={"upu.io"}
          subTitle={t("person:areYouLeave")}
          isOpen={alertLeaveGroup}
          label={t("buttons:leave")}
          setIsOpen={setAlertLeaveGroup}
          applyButton={async () => {
            await chatService
              .leaveGroupConversations(leaveGroupData)
              .then((res) => {
                Toast("success", res?.data?.message);
                if (pathname?.includes("message")) {
                  navigate("/app/person/message");
                }
                if (pathname?.includes("task")) {
                  navigate("/app/person/task");
                }
                // navigate("/app/person/message");
                refetch();
                setAlertLeaveGroup(false);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        />
        <AlertModal
          icon={<DangerIcon />}
          title={"upu.io"}
          subTitle={t("person:areYouDelete")}
          isOpen={alertTaskGroup}
          setIsOpen={setAlertTaskGroup}
          applyButton={async () => {
            await chatService
              .deleteTaskGroup(conversationId)
              .then((res) => {
                if (res.data.code === 1) {
                  Toast("success", res?.data?.message);
                  if (pathname?.includes("message")) {
                    navigate("/app/person/message");
                  }
                  if (pathname?.includes("task")) {
                    navigate("/app/person/task");
                  }
                  // navigate("/app/person/message");
                  refetch();
                  setAlertTaskGroup(false);
                } else {
                  Toast("error", t("chat:notTransaction"));
                }
              })
              .catch((err) => console.log("err", err));
          }}
        />
        <AlertModal
          icon={item?.isQuite === false ? <MuteIconNew /> : <UnmuteIcon />}
          title={"upu.io"}
          subTitle={
            type === "group-task"
              ? item?.isQuite === false
                ? t("person:areYouMute")
                : t("person:areYouUnmuteGroup")
              : item?.isQuite === false
              ? t("person:areYouMutePerson")
              : t("person:areYouUnmutePerson")
          }
          label={
            item?.isQuite === false ? t("person:mute") : t("person:unmute")
          }
          isOpen={unmuteAlert}
          setIsOpen={setUnmuteAlert}
          applyButton={async () => {
            await chatService
              .handleQuiteConversation(item?.conversationId, {
                status: item?.isQuite ? false : true,
              })
              .then((res) => {
                if (res?.data?.code === 0) {
                  Toast("success", res?.data?.message);
                  refetch();
                  setUnmuteAlert(false);
                }
                // if (res.data.code === 0) {
                //   handleConversations();
                // }
              });
          }}
        />
      </>
    );
  }
);

export default memo(PersonnelCard);
