import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import UploadProfile from "../../ImageUploader/ImageUploader";
import { positions } from "react-alert";
import { Input, UploadProfile } from "../../../components";
import { externalOperationService } from "../../../services/external-operation.service";
import { Toast } from "../../../utils/toastify/toast";

const AddSupplierModal = ({
  setIsOpen,
  editSupplier,
  deleteFunction,
  supplierData,
  listSupplier,
}) => {
  const { t } = useTranslation();

  const closeModal = () => {
    setIsOpen(false);
  };

  const schema = yup.object({
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    address: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactPhone: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    contactEmail: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    image: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {}, [errors]);

  useEffect(() => {
    if (supplierData && supplierData?.id) {
      setValue("name", supplierData?.name);
      setValue("address", supplierData?.address);
      setValue("contactPhone", supplierData?.contactPhone);
      setValue("contactEmail", supplierData?.contactEmail);
      setValue("contactName", supplierData?.contactName);
      setValue("image", supplierData?.image);
    } else {
      setValue("name", "");
      setValue("address", "");
      setValue("contactPhone", "");
      setValue("contactEmail", "");
      setValue("contactName", "");
      setValue("image", "");
    }
  }, [supplierData]);

  const onSubmit = async (data) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[368px] flex flex-col mt-4 gap-y-3"
    >
      <Controller
        className="xs:pl-4  bg-blue-600"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <UploadProfile
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            title={t("fileInput:uploadAvatar")}
            subTitle={t("fileInput:removePicture")}
          />
        )}
        name="image"
      />
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            label={t("product:supplierName")}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            validate={errors.name ? "error" : ""}
            errorMessage={errors.name ? errors.name.message : ""}
          />
        )}
        name="name"
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            label={t("routes:adress")}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            validate={errors.address ? "error" : ""}
            errorMessage={errors.address ? errors.address.message : ""}
          />
        )}
        name="address"
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            label={t("phone:contactNumber")}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            validate={errors.contactPhone ? "error" : ""}
            errorMessage={
              errors.contactPhone ? errors.contactPhone.message : ""
            }
          />
        )}
        name="contactPhone"
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            label={t("table:contactName")}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            validate={errors.contactName ? "error" : ""}
            errorMessage={errors.contactName ? errors.contactName.message : ""}
          />
        )}
        name="contactName"
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            label={"E-mail"}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            validate={errors.contactEmail ? "error" : ""}
            errorMessage={
              errors.contactEmail ? errors.contactEmail.message : ""
            }
          />
        )}
        name="contactEmail"
      />

      <div className="flex w-full items-center justify-between mt-2">
        {editSupplier === true ? (
          <button
            type="button"
            onClick={deleteFunction}
            className="h-9 w-[48%] border rounded-lg shadow bg-red-600 flex items-center justify-center border-gray-300 text-sm font-medium text-white"
          >
            {t("product:deleteSupplier")}
          </button>
        ) : (
          <button
            type="button"
            onClick={() => closeModal()}
            className="h-9 w-[48%] border rounded-lg shadow bg-white flex items-center justify-center border-gray-300 text-sm font-medium text-gray-700  dark:bg-[#292929] dark:border-[#525252] dark:text-[#FFFFFF]"
          >
            {t("buttons:stop")}
          </button>
        )}

        <button
          type="submit"
          className="h-9 w-[48%] rounded-lg shadow bg-primary-700 flex items-center justify-center text-sm font-medium text-white"
        >
          {t("buttons:save")}
        </button>
      </div>
    </form>
  );
};

export default AddSupplierModal;
