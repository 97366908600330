import React from "react";
import { Button } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import { DeleteIcon, EditIcon } from "../../../assets/icons/departmentIcon";
import { Toast } from "../../../utils/toastify/toast";

const OperationCard = ({
  t,
  name,
  editOnClick,
  deleteOnclick,
  icon,
  responsibleUser,
  page,
}) => {
  return (
    <div className="w-full bg-white min-h-[64px] max-h-[64px] pl-5 border border-l-[8px] dark:bg-[#141414] dark:border-[#424242] border-gray-300 shadow-xs rounded-lg flex items-center justify-between px-3">
      <div className="flex items-center gap-3">
        {icon ? (
          <img
            className={`min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-lg flex items-center justify-center blur-sm`}
            src={generateFileUrl(icon)}
          />
        ) : (
          <div
            className={
              "min-w-[40px] max-w-[40px] min-h-[40px] rounded-lg bg-[#7F56D9] dark:bg-[#B692F6] flex items-center justify-center font-medium text-white text-2xl blur-sm"
            }
          >
            {name?.charAt(0).toUpperCase()}
          </div>
        )}
        <div className="w-full truncate">
          <p className="text-sm font-semibold text-[#101828] dark:text-[#D6D6D6]">
            {`${name.slice(0, 2)} ***` || t("routes:operationName")}
          </p>
          <p className="text-sm font-normal text-[#667085] dark:text-[#D6D6D6] truncate xs:w-[120px] sm:w-[300px] md:w-[450px] lg:w-[450px] xl:w-[500px]">
            {responsibleUser
              ? responsibleUser.join(", ")
              : t("addProcess:noUser")}
          </p>
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center justify-center ">
        <Button
          iconLeft={<EditIcon />}
          colorType={"tertiary-gray"}
          size={"sm"}
          // onClick={editOnClick}
          onClick={() => Toast("warning", "Demo user not authorized")}
        />
        {page === "external" ? (
          <></>
        ) : (
          <Button
            iconLeft={<DeleteIcon />}
            colorType={"tertiary-error"}
            size={"sm"}
            // onClick={deleteOnclick}
            onClick={() => Toast("warning", "Demo user not authorized")}
          />
        )}
      </div>
    </div>
  );
};

export default OperationCard;
