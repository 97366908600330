import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  EquipmentDeleteIcon,
  EquipmentDetailIcon,
  OperationDetailIcon,
  ProductDetailIcon,
} from "../../assets/icons/machineIcons";
import { PersonnelDetailIcon } from "../junction/ActiveAndPlannedCard";

const JunctionInfoModal = ({
  onPressNode,
  onPressFinish,
  onPressProduct,
  onPressOperation,
  showDropDown,
  props,
  setShowDropDown,
  setIsAlert,
  setIsAlertPlanned,
  status,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const menuOptions = [
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },

    {
      label: t("product:productDetail"),
      value: "productDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:operationDetail"),
      value: "operationDetail",
      icon: <OperationDetailIcon />,
    },
    {
      label: t("product:finishJuction"),
      value: "finish",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsPlanned = [
    // {
    //   label: t("product:finishJuction"),
    //   value: "finish",
    //   icon: <ProductDetailIcon />,
    // },
    {
      label: t("product:productDetail"),
      value: "productDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:operationDetail"),
      value: "operationDetail",
      icon: <OperationDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  return (
    <div className="min-w-[200px] max-w-[200px] flex flex-col">
      {showDropDown && (
        <div className="flex flex-col  h-fit">
          <>
            {status === "active" ? (
              <>
                {menuOptions?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          // setDeleteId(id);
                        }
                        if (item?.value === "details") {
                          onPressNode();
                        }

                        if (item?.value === "finish") {
                          onPressFinish();
                        }

                        if (item?.value === "operationDetail") {
                          onPressOperation();
                        }

                        if (item.value === "productDetail") {
                          onPressProduct();
                        }
                        if (item?.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              props?.user?.id
                            }/${moment(props?.startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : status === "planned" ? (
              <>
                {menuOptionsPlanned?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlertPlanned(true);
                          // setDeleteId(id);
                        }
                        if (item?.value === "details") {
                          onPressNode();
                        }
                        if (item?.value === "operationDetail") {
                          onPressOperation();
                        }

                        if (item.value === "productDetail") {
                          onPressProduct();
                        }
                        // if (item?.value === "finish") {
                        //   onPressFinish();
                        // }
                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default JunctionInfoModal;
