import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../lang/en";
import tr from "../lang/tr";

const localStorage = window.localStorage;

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: (cb) => {
    const storedLanguage = localStorage.getItem("language");
    const detectedLanguage = storedLanguage || "en";
    cb(detectedLanguage);
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    localStorage.setItem("language", lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        ...en,
      },
      tr: {
        ...tr,
      },
    },
  });

export default i18n;
