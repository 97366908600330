import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";

import moment from "moment";
import { timeFormatter } from "../../utils/timezoneFormatter";
import MultipleEventRect from "./components/MultipleEventRect";
import generateFileUrl from "../../utils/generateFileUrl";
import { SingleEventRect } from "./components/SingleEventRect";

import { statusToStringTR } from "../../utils/statusToString";
import {
  formatSeconds,
  secondToHoursAndMinute2,
} from "../../utils/secondToHoursAndMinute";

import ApprovedCard from "../junction/ApprovedCard";
import MultipleEventRectMulti from "./components/MultipleEventRectMulti";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import NoData from "../../steps/step-navigation/components/NoData";

const UpuTimelinePersonelPagesSaved = ({
  data,
  startTime,
  endTime,

  //kontrols
  setStartTime,
  setEndTime,
  setSelectedJunction,
  selectedJunction,
  defaultStartTime,
  defaultEndTime,

  junctionData,

  //grouped data
  groupedDatas,

  //svg

  svgHeight,
}) => {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();

  const [selectedDayType, setSelectedDayType] = useState(1);

  const [selectedShift, setSelectedShift] = useState(null);

  const [showTooltip, setShowTooltip] = useState(null);
  const [mouseX, setMouseX] = useState(null);

  const totalWidth = width;

  const totalWidthh = totalWidth - 390;
  let svgWidth = width - 360;

  // let svgHeight = data?.timeline?.timelineData?.length * 100;

  const handleMouseOver = (index, xPos, yPos, e) => {
    if (index.index == 0) {
      data?.junctionData?.filter((e) => {
        if (e.id == index.junctionId) {
          setShowTooltip({ index, xPos, yPos, junction: e });
        }
      });
    } else {
      setShowTooltip({ index, xPos, yPos });
    }
  };

  const handleMouseMove = (e) => {
    setMouseX(e.clientX);
  };

  const handleMouseOut = () => {
    if (showTooltip) {
      setShowTooltip(null);
    }
  };

  const handleShiftClick = (index) => {
    if (selectedJunction) {
      setStartTime(
        new Date(timeFormatter(selectedJunction.startDate).formatted).valueOf()
      );
      setEndTime(
        new Date(timeFormatter(selectedJunction.endDate).formatted).valueOf()
      );
    } else {
      setStartTime(
        new Date(timeFormatter(index.startDate).formatted).valueOf()
      );
      setEndTime(new Date(timeFormatter(index.endDate).formatted).valueOf());
    }
  };

  const junctionNumber = (item) => {
    const junctions = junctionData?.filter((a) => a.status != "planned");

    let sortedJunctions = junctions?.sort(
      (a, b) => moment(a?.endDate)?.unix() - moment(b?.endDate)?.unix()
    );

    const datas = sortedJunctions?.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });

    const lastData = datas?.filter((a) => a != "");

    if (lastData?.length > 0) {
      return lastData[0];
    } else {
      return 1;
    }
  };

  useEffect(() => {
    if (selectedJunction) {
      handleShiftClick(selectedJunction);
    }
  }, [selectedJunction]);

  return (
    <div
      className=" w-full flex flex-col  border-[2px] border-gray-300 rounded-xl  bg-secondary-100  "
      style={{
        width: totalWidthh - 150,
      }}
    >
      <div className="w-full  rounded-t-lg  flex items-center ml-3 mt-2   ">
        <span className="isolate inline-flex rounded-lg">
          <button
            onClick={() => {
              setStartTime(defaultStartTime);
              setEndTime(defaultEndTime);
              setSelectedDayType(1);
              setSelectedJunction(null);
            }}
            type="button"
            className="relative inline-flex items-center rounded-l-lg  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            style={{
              backgroundColor: selectedDayType == 1 ? "#F9FAFB" : "white",
            }}
          >
            {t("gauge:allDay")}
          </button>
          {Object?.values(groupedDatas)?.map((shift, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setStartTime(
                    new Date(
                      timeFormatter(shift[0]?.startDate)?.formatted
                    ).valueOf()
                  );
                  setEndTime(
                    new Date(
                      timeFormatter(
                        shift[shift?.length - 1]?.endDate
                      )?.formatted
                    ).valueOf()
                  );
                  setSelectedDayType(shift[0]?.shiftId);
                }}
                type="button"
                className="relative -ml-px inline-flex items-center px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300  focus:z-10 last:rounded-r-md "
                style={{
                  backgroundColor:
                    selectedDayType == shift[0]?.shiftId ? "#F9FAFB" : "white",
                }}
              >
                <span
                  className={`w-3 h-3 rounded-sm mr-2`}
                  style={{
                    backgroundColor: shift[0]?.color,
                  }}
                ></span>
                {shift[0]?.name}
              </button>
            );
          })}
        </span>
      </div>

      <div className="w-full flex mt-2 ">
        <div className="w-[110px] min-w-[110px] max-w-[110px] flex flex-col items-start z-[30]">
          <h3 className="text-base  text-secondary-900 font-semibold mt-0.5 pl-3">
            {t("equipments:equipments")}
          </h3>
          {data?.timelineData?.map((e, i) => {
            return (
              <>
                <img
                  data-tooltip-id={`${e?.image}`}
                  data-tooltip-place="right"
                  data-tooltip-content={`${e?.name.slice(0,3)+"***"} `}
                  src={generateFileUrl(e?.image)}
                  className="w-[96px] h-[72px] border-[1px] border-secondary-300  rounded-md mx-3 my-2 blur-sm"
                />
                <Tooltip id={e?.image} />
              </>
            );
          })}
        </div>
        <div className="w-full flex flex-col  relative pr-3 ml-3  ">
          {data?.timelineData?.length == 0 ? (
            <div className="flex w-full items-center justify-center mt-5">
              <span className="mr-[126px]">
                <NoData theme={"machine"} header={t("label:noData")} button={false} />
              </span>
            </div>
          ) : (
            <svg width={svgWidth} height={svgHeight}>
              {data?.shiftlyTimelineData?.map((shift, index) => {
                const { startDate, endDate, color } = shift;
                const shiftStartTime = new Date(
                  timeFormatter(startDate).formatted
                ).getTime();
                const shiftEndTime = new Date(
                  timeFormatter(endDate).formatted
                ).getTime();

                if (selectedShift !== null && selectedShift !== index) {
                  return null;
                }

                const timeRange = endTime - startTime;

                const shiftX =
                  selectedShift === index
                    ? 0
                    : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                const shiftWidth =
                  selectedShift === index
                    ? svgWidth
                    : ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                const lastElement =
                  data?.timeline?.shiftlyTimelineData[
                    data?.timeline?.shiftlyTimelineData?.length - 1
                  ];

                let lastShift = {
                  startDate: lastElement?.endDate,
                  endDate: defaultEndTime,
                  color: "red",
                  endPosition:
                    ((new Date(
                      timeFormatter(defaultEndTime).formatted
                    ).getTime() -
                      startTime) /
                      timeRange) *
                      svgWidth || 0,
                  startPostion:
                    ((new Date(
                      timeFormatter(lastElement?.endDate).formatted
                    ).getTime() -
                      startTime) /
                      timeRange) *
                      svgWidth || 0,
                };

                return (
                  <>
                    <SingleEventRect
                      key={index}
                      startPos={shiftX}
                      width={shiftWidth}
                      fill={color}
                      onClick={() => handleShiftClick(shift)}
                      style={{ cursor: "pointer" }}
                      event={shift}
                      svgHeight={svgHeight}
                      handleMouseOver={handleMouseOver}
                      handleMouseOut={handleMouseOut}
                    />

                    {shiftWidth > 15 && (
                      <>
                        <rect
                          key={index}
                          x={shiftX}
                          y={30}
                          width={shiftWidth}
                          height={svgHeight}
                          fill={
                            shift?.color == "#FFFFFF" ? "#F2F4F7" : shift?.color
                          }
                          opacity={0.2}
                          onClick={() => handleShiftClick(shift)}
                          // onMouseOver={() => handleMouseOver(event, startPos)}
                          // onMouseOut={handleMouseOut}
                          stroke={shift?.color}
                          strokeWidth={1}
                          style={{ cursor: "pointer" }}
                          ry={"4"}
                        ></rect>

                        <text
                          style={{
                            cursor: "pointer",
                          }}
                          x={lastShift?.endPosition}
                          y={22}
                          font-family="Inter"
                          font-size="12"
                          fill="black"
                          onClick={() => handleShiftClick(shift)}
                          // onMouseOver={() => handleMouseOver(event, startPos)}
                          // onMouseOut={handleMouseOut}
                        >
                          {moment(
                            timeFormatter(defaultEndTime).formatted
                          ).format("HH:mm")}
                        </text>
                      </>
                    )}
                  </>
                );
              })}

              {/* Status and junctions */}
              {data?.timelineData?.map((x, y) => {
                return x?.timelineData.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(
                    timeFormatter(startDate).formatted
                  ).getTime();
                  const shiftEndTime = new Date(
                    timeFormatter(endDate).formatted
                  ).getTime();

                  const timeRange = endTime - startTime;

                  if (selectedShift !== null && selectedShift !== index) {
                    // Seçili event dışındaki diğer eventleri gösterme
                    return null;
                  }

                  const shiftX =
                    selectedShift === index
                      ? 0
                      : ((shiftStartTime - startTime) / timeRange) * svgWidth;

                  const statusWidth =
                    ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                  let yOffset = (y + 1) * 90;

                  return (
                    <>
                      <MultipleEventRectMulti
                        key={index}
                        yOffset={yOffset}
                        startPos={shiftX}
                        width={statusWidth}
                        widthS={statusWidth}
                        event={shift}
                        onClick={() => handleShiftClick(shift)}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleMouseMove={handleMouseMove}
                        junctionNumber={junctionNumber}
                        selectedJunction={selectedJunction}
                      />
                    </>
                  );
                });
              })}
            </svg>
          )}

          {showTooltip && showTooltip?.index?.index == 0 ? (
            <div
              key={showTooltip?.index?.startDate}
              style={{
                right: totalWidthh - mouseX < 400 ? "62%" : "5%",
                top: showTooltip.yPos,
              }}
              className={`absolute z-50 bg-white p-2  rounded-lg shadow-md top-5 `}
            >
              <ApprovedCard
              step={showTooltip.junction?.header?.step}
                key={showTooltip.junction?.id}
                id={showTooltip.junction?.id}
                equipmentName={showTooltip.junction?.job?.product?.name}
                equipmentImage={showTooltip.junction?.job?.product?.image}
                qualityAcceptedPiece={
                  showTooltip.junction?.metadata?.madeWorkCount
                }
                qualityRejectedPiece={
                  showTooltip.junction?.metadata?.rejectedWorkCount
                }
                oneOrderTime={formatSeconds(
                  (
                    moment(showTooltip.junction?.endDate).diff(
                      showTooltip.junction?.startDate,
                      "seconds"
                    ) / showTooltip.junction?.metadata?.madeWorkCount || 0
                  ).toFixed(0)
                )}
                user={showTooltip.junction?.user}
                status={showTooltip.junction?.status}
                totalJunctionTime={secondToHoursAndMinute2(
                  showTooltip.junction?.startDate,
                  showTooltip.junction?.endDate
                )}
                qualityPercent={showTooltip.junction?.metadata?.qualityPercent}
                estimatedTrue={
                  (
                    moment(showTooltip.junction?.endDate).diff(
                      showTooltip.junction?.startDate,
                      "seconds"
                    ) / showTooltip.junction?.metadata?.madeWorkCount
                  ).toFixed(0) <
                  showTooltip.junction?.operations?.internalOperation
                    ?.estimatedTime
                    ? true
                    : false
                }
              />
            </div>
          ) : showTooltip && showTooltip?.index?.index == 1 ? (
            <div
              key={showTooltip?.index?.startDate}
              className={`absolute z-50 bg-white p-2  rounded-lg shadow-md`}
              style={{
                left:
                  (showTooltip?.xPos + 30) * 2 > totalWidthh
                    ? showTooltip?.xPos - 280
                    : showTooltip?.xPos + 40,
                top: showTooltip?.yPos,
                borderTop: "5px solid " + showTooltip?.index?.color,
              }}
            >
              <div className="flex items-center mb-1 ">
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: showTooltip?.index?.color,
                    borderRadius: 2,
                  }}
                ></div>

                <p
                  className="text-md font-semibold"
                  style={{
                    color: showTooltip?.index?.color,
                    marginLeft: "6px",
                  }}
                >
                  {showTooltip?.index?.name}
                </p>
              </div>

              <div className="flex flex-col w-full items-center justify-center">
                <div className="flex w-full border-b border-[#EAECF0]">
                  <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
                    Start-End Time
                  </div>
                  <div className="flex min-w-[142px] max-w-[142px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                    <span>
                      {moment(
                        timeFormatter(showTooltip?.index?.startDate).formatted
                      ).format("HH:mm:ss")}
                    </span>
                    -
                    <span>
                      {moment(
                        timeFormatter(showTooltip?.index?.endDate).formatted
                      ).format("HH:mm:ss")}
                    </span>
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
                    Total Time
                  </div>
                  <div className="flex min-w-[142px] max-w-[142px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                    <span>
                      {formatSeconds(
                        moment(
                          timeFormatter(showTooltip?.index?.endDate).formatted
                        ).diff(
                          moment(
                            timeFormatter(showTooltip?.index?.startDate)
                              .formatted
                          ),
                          "seconds"
                        )
                      )}
                    </span>
                    -
                    <span>
                      {moment(
                        timeFormatter(showTooltip?.index?.endDate).formatted
                      ).diff(
                        moment(
                          timeFormatter(showTooltip?.index?.startDate).formatted
                        ),
                        "seconds"
                      ) + " sn"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UpuTimelinePersonelPagesSaved;

const ClockIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_11043_56614)">
        <path
          d="M7.99967 3.99992V7.99992L10.6663 9.33325M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11043_56614">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ClockIcons2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.43698 5.42993V8.43848L5.55664 9.56668"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4801 8.69752C14.5024 8.46749 14.5138 8.23427 14.5138 7.99837C14.5138 4.0544 11.3166 0.857178 7.37264 0.857178C3.42867 0.857178 0.231445 4.0544 0.231445 7.99837C0.231445 11.9423 3.42867 15.1396 7.37264 15.1396C8.04655 15.1396 8.69865 15.0462 9.31673 14.8718V13.8255C8.70577 14.0292 8.05209 14.1396 7.37264 14.1396C3.98095 14.1396 1.23145 11.3901 1.23145 7.99837C1.23145 4.60669 3.98095 1.85718 7.37264 1.85718C10.7643 1.85718 13.5138 4.60669 13.5138 7.99837C13.5138 8.00998 13.5138 8.02159 13.5137 8.03318L14.4801 8.69752Z"
        fill="#475467"
      />
      <path
        d="M10.8213 9.11247C10.8213 8.80377 10.8213 8.64942 10.8857 8.56434C10.9417 8.49021 11.0274 8.44435 11.1202 8.43881C11.2267 8.43245 11.3551 8.51807 11.612 8.6893L14.9545 10.9177C15.1668 11.0591 15.2729 11.1299 15.3099 11.2191C15.3422 11.297 15.3422 11.3846 15.3099 11.4626C15.2729 11.5518 15.1668 11.6225 14.9545 11.764L11.612 13.9923C11.3551 14.1636 11.2267 14.2492 11.1202 14.2428C11.0274 14.2373 10.9417 14.1914 10.8857 14.1173C10.8213 14.0322 10.8213 13.8779 10.8213 13.5692V9.11247Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
