import React, { useCallback, useMemo, useState, memo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Badgets from "../../../components/buttons/Badgets";
import {
  ArrowUpGreen,
  ArrownDownRed,
  LeaderBoardIcon,
  PointHeaderText,
  PointIcon,
  PointIconLogo,
} from "../../../assets/icons/PersonIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { SearchIcon } from "../../../assets/icons/stepsIcons";
import Button from "../../../components/buttons/Button";
import MultipleBarChart from "../../machine/Tabs/MultipleBarChart";
import { HumburgerIcon } from "../../../assets/icons/leftMenuIcons";
import moment from "moment";
import { Arrow, Calender } from "../../../assets/icons/machineIcons";
import { useStore } from "../../../hooks/useStores";
import LossesPieChart from "../../machine/Tabs/LossesPieChart";
import { lossesService } from "../../../services/losses.service";
import { useTranslation } from "react-i18next";
import MockNodeOperation from "./mockComp/MockNodeOperation";
import MockOperationTypes from "./mockComp/MockOperationTypes";
import MockCompanyOperation from "./mockComp/MockCompanyOperation";
import { lossData, users } from "../../machine/loss/mock";
import { NoDataPerson } from "../../../assets/illustrations";
import { personalService } from "../../../services/personal.service";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Tab } from "../../../components";

const UpuPoint = () => {
  const { t } = useTranslation();
  const { settings } = useStore();
  const navigate = useNavigate();
  const { auth } = useStore();
  const companyId = auth?.user?.company?.id;
  const [month, setMonth] = useState(new Date());
  const [monthRes, setMonthRes] = useState(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedTab, setSelectedTab] = useState("operator");

  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["leaderboardPoint"],
    queryFn: async () =>
      personalService.leaderboardPoint(
        selectedTab === "operator" ? "operator" : "operation_responsible",
        {
          date:
            selectedTab === "operator"
              ? moment(month).format("YYYY-MM-01")
              : moment(monthRes).format("YYYY-MM-01"),
        }
      ),
  });

  const currentMonthFormat = moment().startOf("month");
  const isDisabled = currentMonthFormat.isSame(
    moment(month).startOf("month"),
    "month"
  );

  const currentMonthFormatRes = moment().startOf("month");
  const isDisabledRes = currentMonthFormatRes.isSame(
    moment(monthRes).startOf("month"),
    "month"
  );

  const handlePreviousMonth = useCallback(() => {
    setMonth(moment(month).subtract(1, "day").startOf("month"));
  }, [month]);

  const handleCalender = () => {
    return moment(month).format("MMMM YYYY") || "DD MM YYYY";
  };

  const handleNextMonth = useCallback(() => {
    setMonth(moment(month).add(1, "month").startOf("month"));
  }, [month]);

  const handlePreviousMonthRes = useCallback(() => {
    setMonthRes(moment(monthRes).subtract(1, "day").startOf("month"));
  }, [monthRes]);

  const handleCalenderRes = () => {
    return moment(monthRes).format("MMMM YYYY") || "DD MM YYYY";
  };

  const handleNextMonthRes = useCallback(() => {
    setMonthRes(moment(monthRes).add(1, "month").startOf("month"));
  }, [monthRes]);

  const sortedData = data?.sort((a, b) => b?.upuPoint - a?.upuPoint);
  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K";
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num < 999) {
      return num;
    }
  };

  useEffect(() => {
    refetch();
  }, [monthRes, selectedTab, month]);

  return (
    <>
      {companyId === "e5c06127-3c5d-44b9-8403-74c160fc7f59" ? (
        <div className="w-full h-full flex-col flex">
          <div className="w-full  min-h-[56px] border-b border-[#EAECF0] h-[56px] flex items-center dark:text-[#FAFAFA]">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button
                colorType={"tertiary-gray"}
                iconLeft={<HumburgerIcon />}
                size={"md"}
                onClick={() => settings.setIsActive(true)}
              />
            </span>
            {isLoading ? (
              <Skeleton width={133} height={52} borderRadius={8} />
            ) : (
              <span className="flex min-w-[133px] min-h-[28px]">
                <PointHeaderText />
              </span>
            )}
          </div>
          {isLoading ? (
            <Skeleton width={"30%"} height={52} borderRadius={8} />
          ) : (
            <div className="w-[30%]">
              <Tab
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                options={[
                  { value: "operator", label: "Operatörler" },
                  {
                    value: "operationResponsible",
                    label: "Operasyon Sorumluları",
                  },
                ]}
              />
            </div>
          )}

          {selectedTab === "operator" ? (
            <>
              <div className="flex w-full h-full relative">
                <div className="absolute right-0  -top-16">
                  <div className={`w-fit ml-auto h-9 flex items-center gap-3`}>
                    {/*left arrow*/}
                    {isLoading ? (
                      <div className="absolute right-48  top-4">
                        <Skeleton width={36} height={36} borderRadius={8} />
                      </div>
                    ) : (
                      <button
                        role="button"
                        onClick={() => {
                          handlePreviousMonth();
                        }}
                        className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs `}
                      >
                        <Arrow color="#344054" />
                      </button>
                    )}

                    {/*calender arrow*/}
                    {isLoading ? (
                      <div className="absolute right-16  top-4">
                        <Skeleton width={120} height={36} borderRadius={8} />
                      </div>
                    ) : (
                      <div
                        role="button"
                        className={`min-w-[119px] cursor-pointer h-full flex px-2 justify-center items-center gap-1 rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs`}
                      >
                        <Calender color="#344054" />
                        <div
                          className={`w-fit h-5 flex justify-center items-center`}
                        >
                          <span
                            className={`text-[#344054] text-sm font-semibold flex`}
                          >
                            {moment(month).format("MMMM") || "DD MM YYYY"}
                          </span>
                        </div>
                        {openCalendar && (
                          <>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenCalendar(true);
                              }}
                              style={{
                                marginTop: 40,
                              }}
                              className={`absolute top-10 -translate-x-1 bg-white z-[9999999] p-1 border rounded-lg border-gray-300 dark:bg-[#141414] dark:border-[#525252] shadow-lg`}
                            >
                              <Calender
                                value={month}
                                onChange={(e) => {
                                  setMonth(e);
                                  handleCalender();
                                }}
                              />
                            </div>
                            <div
                              className="fixed inset-0 z-[99999]"
                              onClick={(e) => {
                                setOpenCalendar(false);
                              }}
                              style={{ zIndex: 999 }}
                            />
                          </>
                        )}
                      </div>
                    )}

                    {/*right arrow*/}
                    {isLoading ? (
                      <div className="absolute right-4  top-4">
                        <Skeleton width={36} height={36} borderRadius={8} />
                      </div>
                    ) : (
                      <div
                        role="button"
                        onClick={() => {
                          if (!isDisabled) {
                            handleNextMonth();
                          }
                        }}
                        className={` ${
                          isDisabled ? "disabled-button" : ""
                        } disabled disabled-button  w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs rotate-180 `}
                      >
                        <Arrow color="#344054" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="flex px-5 pb-8 flex-col items-center">
                    {isLoading ? (
                      <Skeleton width={720} height={200} borderRadius={8} />
                    ) : (
                      <div className="flex flex-row  items-center gap-x-8 h-[284px] justify-center">
                        <SecondPerson
                          name={sortedData?.[1]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[1]?.avatar}
                          point={sortedData?.[1]?.upuPoint}
                          lastname={sortedData?.[1]?.lastName}
                          department={sortedData?.[1]?.department}
                          id={sortedData?.[1]?.id || sortedData?.[1]?._id}
                          navigate={navigate}
                        />

                        <FirstPerson
                          name={sortedData?.[0]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[0]?.avatar}
                          point={sortedData?.[0]?.upuPoint}
                          lastname={sortedData?.[0]?.lastName}
                          department={sortedData?.[0]?.department}
                          id={sortedData?.[0]?.id || sortedData?.[0]?._id}
                          navigate={navigate}
                        />

                        <ThirdPerson
                          name={sortedData?.[2]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[2]?.avatar}
                          point={sortedData?.[2]?.upuPoint}
                          lastname={sortedData?.[2]?.lastName}
                          department={sortedData?.[2]?.department}
                          id={sortedData?.[2]?.id || sortedData?.[2]?._id}
                          navigate={navigate}
                        />
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <Skeleton width={"100%"} height={500} borderRadius={8} />
                  ) : (
                    <div className="flex flex-col h-[45%] bg-white w-full border shadow-sm border-gray-300 rounded-xl overflow-y-auto scrollbar-hide">
                      {sortedData?.map((item, index) => {
                        if (index > 2)
                          return (
                            <TablePersonComp
                              id={item?.id || item?._id}
                              status="up"
                              name={item?.name}
                              lastname={item?.lastName}
                              department={item?.department || "---"}
                              numFormatter={numFormatter}
                              point={item?.upuPoint}
                              avatar={item?.avatar}
                              difNumber={index + 1}
                            />
                          );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex w-full h-full relative">
                <div className="absolute right-0  -top-16">
                  <div className={`w-fit ml-auto h-9 flex items-center gap-3`}>
                    {/*left arrow*/}
                    {isLoading ? (
                      <Skeleton width={36} height={36} borderRadius={8} />
                    ) : (
                      <button
                        role="button"
                        onClick={() => {
                          handlePreviousMonthRes();
                        }}
                        className={`w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs `}
                      >
                        <Arrow color="#344054" />
                      </button>
                    )}

                    {/*calender arrow*/}
                    {isLoading ? (
                      <Skeleton width={120} height={36} borderRadius={8} />
                    ) : (
                      <div
                        role="button"
                        className={`min-w-[119px] cursor-pointer h-full flex px-2 justify-center items-center gap-1 rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs`}
                      >
                        <Calender color="#344054" />
                        <div
                          className={`w-fit h-5 flex justify-center items-center`}
                        >
                          <span
                            className={`text-[#344054] text-sm font-semibold flex`}
                          >
                            {moment(monthRes).format("MMMM") || "DD MM YYYY"}
                          </span>
                        </div>
                        {openCalendar && (
                          <>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenCalendar(true);
                              }}
                              style={{
                                marginTop: 40,
                              }}
                              className={`absolute top-10 -translate-x-1 bg-white z-[9999999] p-1 border rounded-lg border-gray-300 dark:bg-[#141414] dark:border-[#525252] shadow-lg`}
                            >
                              <Calender
                                value={monthRes}
                                onChange={(e) => {
                                  setMonthRes(e);
                                  handleCalenderRes();
                                }}
                              />
                            </div>
                            <div
                              className="fixed inset-0 z-[99999]"
                              onClick={(e) => {
                                setOpenCalendar(false);
                              }}
                              style={{ zIndex: 999 }}
                            />
                          </>
                        )}
                      </div>
                    )}

                    {/*right arrow*/}
                    {isLoading ? (
                      <Skeleton width={36} height={36} borderRadius={8} />
                    ) : (
                      <div
                        role="button"
                        onClick={() => {
                          if (!isDisabledRes) {
                            handleNextMonthRes();
                          }
                        }}
                        className={` ${
                          isDisabledRes ? "disabled-button" : ""
                        } disabled disabled-button  w-9 h-full cursor-pointer flex justify-center items-center rounded-lg border border-solid border-[#D0D5DD] bg-[#fff] shadow-xs rotate-180 `}
                      >
                        <Arrow color="#344054" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="flex px-5 pb-8 flex-col items-center">
                    {isLoading ? (
                      <Skeleton width={720} height={200} borderRadius={8} />
                    ) : (
                      <div className="flex flex-row  items-center gap-x-8 h-[284px] justify-center">
                        <SecondPerson
                          name={sortedData?.[1]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[1]?.avatar}
                          point={sortedData?.[1]?.upuPoint}
                          lastname={sortedData?.[1]?.lastName}
                          department={sortedData?.[1]?.processName.join(", ")}
                          id={sortedData?.[1]?.id || sortedData?.[1]?._id}
                          navigate={navigate}
                        />

                        <FirstPerson
                          name={sortedData?.[0]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[0]?.avatar}
                          point={sortedData?.[0]?.upuPoint}
                          lastname={sortedData?.[0]?.lastName}
                          department={sortedData?.[0]?.processName.join(", ")}
                          id={sortedData?.[0]?.id || sortedData?.[0]?._id}
                          navigate={navigate}
                        />

                        <ThirdPerson
                          name={sortedData?.[2]?.name}
                          numFormatter={numFormatter}
                          avatar={sortedData?.[2]?.avatar}
                          point={sortedData?.[2]?.upuPoint}
                          lastname={sortedData?.[2]?.lastName}
                          department={sortedData?.[2]?.processName.join(", ")}
                          id={sortedData?.[2]?.id || sortedData?.[2]?._id}
                          navigate={navigate}
                        />
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <Skeleton width={"100%"} height={500} borderRadius={8} />
                  ) : (
                    <div className="flex flex-col h-[45%]  bg-white w-full border shadow-sm border-gray-300 rounded-xl overflow-y-auto scrollbar-hide">
                      {sortedData?.map((item, index) => {
                        if (index > 2)
                          return (
                            <TablePersonComp
                              id={item?.id || item?._id}
                              status="up"
                              name={item?.name}
                              lastname={item?.lastName}
                              department={item?.processName.join(", ") || "---"}
                              numFormatter={numFormatter}
                              point={item?.upuPoint}
                              avatar={item?.avatar}
                              difNumber={index + 1}
                            />
                          );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center flex-col">
          <NoDataPerson />
          <p className="text-gray-700 font-medium text-xl mt-5">
            {t("chat:comingSoon")}
          </p>
        </div>
      )}
    </>
  );
};

const FirstPerson = ({
  navigate,
  id,
  name,
  lastname,
  point,
  department,
  avatar,
  numFormatter,
}) => {
  return (
    <div className="flex  gap-y-4 flex-col h-[284px] w-[232px] min-w-[232px] border rounded-[16px] p-6 ring-4 ring-[#F6D03924] border-[#F6D03948]  justify-center items-center">
      <div
        onClick={() => {
          navigate(
            `/app/machine/digital-twin/user-detail/${id}/${moment().format(
              "YYYY-MM-DD"
            )}`
          );
        }}
        className="rounded-full cursor-pointer bg-[#F6D0393D] min-w-[112px] max-w-[112px] min-h-[112px] max-h-[112px] items-center flex justify-center relative"
      >
        {avatar ? (
          <img
            className="max-w-[56px] min-w-[96px] max-h-[96px] min-h-[96px] rounded-full border-2 outline-2 border-[#0000001A] outline-[#0000001A] blur-sm"
            src={generateFileUrl(avatar)}
          />
        ) : (
          <NoAvatar name={name} lastName={lastname} size={40} />
        )}
        <div className="flex px-[6px] min-h-[24px] max-h-[24px] absolute border border-[#F8DA64] rounded-lg items-center justify-center text-[#C7A109] font-semibold text-md bg-white bottom-[-6px]">
          1st
        </div>
      </div>
      <div className="flex gap-y-2 flex-col w-full h-full items-center">
        <div className="flex flex-col w-full items-center justify-center">
          <span className="text-lg w-[184px] truncate text-center font-semibold text-secondary-700">
            {name + " " + lastname}
          </span>
          <span className="text-sm text-center font-normal text-secondary-500">
            {department || "---"}
          </span>
        </div>
        <div className="min-h-[28px] max-h-[28px] min-w-[85px] max-w-[85px] bg-gradient-to-r from-[#BB9600] to-[#ECBE05] rounded-[36px] flex gap-x-1 items-center justify-center">
          <PointIconLogo />
          <span className="text-white font-semibold text-sm">
            {String(numFormatter(point))}
          </span>
        </div>
      </div>
    </div>
  );
};

const SecondPerson = ({
  id,
  navigate,
  name,
  lastname,
  point,
  department,
  avatar,
  numFormatter,
}) => {
  return (
    <div className="flex gap-y-4 flex-col border ring-4 ring-[#C3C7C732]  h-[252px] w-[232px] min-w-[232px] rounded-[16px] border-[#C3C7C748] border-opacity-45 p-6  justify-center items-center mt-auto">
      <div
        onClick={() => {
          navigate(
            `/app/machine/digital-twin/user-detail/${id}/${moment().format(
              "YYYY-MM-DD"
            )}`
          );
        }}
        className="rounded-full cursor-pointer bg-[#EBEDED] min-w-[92px] max-w-[92px] min-h-[92px] max-h-[92px] items-center flex justify-center relative"
      >
        {avatar ? (
          <img
            className="max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px] rounded-full border-2 outline-2 border-[#0000001A] outline-[#0000001A] blur-sm "
            src={generateFileUrl(avatar)}
          />
        ) : (
          <NoAvatar name={name} lastName={lastname} size={40} />
        )}
        <div className="flex px-[6px] min-h-[24px] max-h-[24px] absolute border border-[#C3C7C7] rounded-lg items-center justify-center text-[#656C6C] font-semibold text-sm bg-[#F3F4F4] bottom-[-6px]">
          2nd
        </div>
      </div>
      <div className="flex gap-y-2 flex-col w-full h-full items-center">
        <div className="flex flex-col w-full items-center justify-center">
          <span className="text-lg text-center w-[184px] truncate font-semibold text-secondary-700">
            {name + " " + lastname}
          </span>
          <span className="text-sm font-normal text-center text-secondary-500">
            {department || "---"}
          </span>
        </div>
        <div className="min-h-[28px] max-h-[28px] min-w-[85px] max-w-[85px] bg-gradient-to-r from-[#656C6C] to-[#AEB4B4] rounded-[36px] flex gap-x-1 items-center justify-center">
          <PointIconLogo />
          <span className="text-white font-semibold text-sm">
            {String(numFormatter(point))}
          </span>
        </div>
      </div>
    </div>
  );
};

const ThirdPerson = ({
  navigate,
  id,
  name,
  lastname,
  point,
  department,
  avatar,
  numFormatter,
}) => {
  return (
    <div className="flex  gap-y-4  flex-col border p-6 h-[240px] w-[232px] min-w-[232px] rounded-lg ring-4 ring-[#C5832B24] border-[#C5832B48]  justify-center items-center mt-auto">
      <div
        onClick={() => {
          navigate(
            `/app/machine/digital-twin/user-detail/${id}/${moment().format(
              "YYYY-MM-DD"
            )}`
          );
        }}
        className="rounded-full cursor-pointer bg-[#F1E1CC] min-w-[92px] max-w-[92px] min-h-[92px] max-h-[92px] items-center flex justify-center relative"
      >
        {avatar ? (
          <img
            className="max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px] rounded-full border-2 outline-2 border-[#0000001A] outline-[#0000001A] blur-sm"
            src={generateFileUrl(avatar)}
          />
        ) : (
          <NoAvatar name={name} lastName={lastname} size={40} />
        )}
        <div className="flex px-[6px] min-h-[24px] max-h-[24px] absolute border border-[#E2B57A] rounded-lg items-center justify-center text-[#AB7225] font-semibold text-sm bg-[#FBF4EC] bottom-[-6px]">
          3rd
        </div>
      </div>
      <div className="flex gap-y-2 w-full flex-col h-full items-center">
        <div className="flex flex-col w-full items-center justify-center">
          <span className="text-lg w-[184px] truncate text-center  font-semibold text-secondary-700">
            {name + " " + lastname}
          </span>
          <span className="text-sm font-normal text-center text-secondary-500">
            {department || "---"}
          </span>
        </div>
        <div className="min-h-[28px] max-h-[28px] min-w-[85px] max-w-[85px] bg-gradient-to-r from-[#AB7225] to-[#F4AD4D] rounded-[36px] flex gap-x-1 items-center justify-center">
          <PointIconLogo />
          <span className="text-white font-semibold text-sm">
            {String(numFormatter(point))}
          </span>
        </div>
      </div>
    </div>
  );
};

const TablePersonComp = ({
  status,
  name,
  lastname,
  department,
  point,
  numFormatter,
  avatar,
  difNumber,
  id,
}) => {
  const navigate = useNavigate();

  return (
    <div
      key={id}
      className="flex w-full min-h-[72px] max-h-[72px] px-5 py-2 border-b items-center justify-center"
    >
      <span>
        <Badgets colorType={"fill-gray"} label={difNumber} size={"sm"} />
      </span>
      <div className="flex w-full ml-3">
        {avatar ? (
          <img
            className="max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px] rounded-full border border-[#0000001A] blur-sm"
            src={generateFileUrl(avatar)}
          />
        ) : (
          <NoAvatar name={name} lastName={lastname} size={40} />
        )}
        <div className="flex flex-col w-full ml-3">
          <span className="text-sm font-semibold text-[#344054]">
            {name} {lastname}
          </span>
          <span className="text-xs font-normal text-[#667085]">
            {department}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <PointIcon />
        <span className="ml-1 text-[#344054] text-sm font-medium">
          {String(numFormatter(point))}
        </span>
      </div>
      <div className="ml-8">
        <Button
          colorType={"tertiary-gray"}
          iconLeft={<DetailIcon />}
          size={"sm"}
          onClick={() => {
            navigate(
              `/app/machine/digital-twin/user-detail/${id}/${moment().format(
                "YYYY-MM-DD"
              )}`
            );
          }}
        />
      </div>
    </div>
  );
};

export default UpuPoint;

export const DetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_12738_55687)">
        <path
          d="M10.0003 13.3334V10.0001M10.0003 6.66675H10.0087M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
          stroke="#475467"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12738_55687">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
