import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TabModal } from "../../../steps/process";
import GroupInfo from "../pages/GroupInfo";
import GroupMembers from "../pages/GroupMembers";
import { useStore } from "../../../hooks/useStores";
import { personalService } from "../../../services/personal.service";
import { chatService } from "../../../services/chat.service";
import { Toast } from "../../../utils/toastify/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { authStore } from "../../../stores/auth.store";
import moment from "moment";

const NewGroup = ({
  setIsNewGroupModalOpen,
  groupId,
  setIsOpen,
  selectedGroup,
  refetch = () => {},
  newRefetch = () => {},
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [selectedTab, setSelectedTab] = useState("info");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [adminUser, setAdminUser] = useState([]);
  const { chat, auth } = useStore();
  const [departments, setDepartments] = useState([]);
  const [departmans, setDepartmans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState();
  const schema = yup.object({
    image: yup.string(),
    title: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    endDate: yup.date().required(`${t("validation:empty")}`),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const selectDepartmentUsers = (selected) => {
  //   const findToDepartment = departments?.filter(
  //     (item) => item.departmentId === selected
  //   );
  //   if (selectedGroup) {
  //     setSelectedUsers([...selectedUsers, ...findToDepartment[0]?.users]);
  //   } else {
  //     setSelectedUsers(findToDepartment[0]?.users);
  //   }
  // };

  const selectDepartmentUsers = (selected) => {
    const findToDepartment = departmans?.filter(
      (item) => item.departmentId === selected
    );
    if (selectedGroup) {
      setSelectedUsers([...selectedUsers, ...findToDepartment[0]?.users]);
    } else {
      setSelectedUsers(findToDepartment[0]?.users);
    }
  };

  const getDepartmentToUser = async () => {
    try {
      const { data } = await personalService.getDepartmentToUser();
      setLoadings(true)
      setData(data);
      setLoadings(false)
      setDepartmans(data);
      setDepartments(
        data.map((e) => {
          return {
            label: e.departmentName,
            value: e.departmentId,
          };
        })
      );
      
    } catch (err) {
      console.log("err", err);
    }
  };

  const triggerUser = (user) => {
    const filter = selectedUsers?.filter((u) => {
      return u.id == user.id;
    });
    if (filter?.length) {
      setSelectedUsers(selectedUsers?.filter((item) => item?.id !== user?.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    selectedUsers.includes(user);
  };

  const triggerAdmin = (user) => {
    const filter = adminUser?.filter((a) => {
      return a?.id == user?.id;
    });
    if (filter?.length > 0) {
      setAdminUser(adminUser?.filter((item) => item?.id != user?.id));
    } else {
      setAdminUser([...adminUser, user]);
    }
  };

  // const selectAllPress = (users) => {
  //   const diff = users.filter((x) => !selectedUsers.includes(x));
  //   if (diff.length > 0) {
  //     setSelectedUsers([...selectedUsers, ...diff]);
  //   } else {
  //     setSelectedUsers(selectedUsers.filter((item) => !users.includes(item)));
  //   }
  // };
  // const selectAllPress = (users) => {
  //   const diff = users.filter((x) => !selectedUsers.includes(x));
  //   if (diff.length > 0) {
  //     // Eğer farklı kullanıcılar varsa, onları seçili olarak ekleyin
  //     setSelectedUsers([...selectedUsers, ...diff]);
  //   } else {
  //     // Eğer tüm kullanıcılar zaten seçiliyse, tüm seçimleri kaldır
  //     setSelectedUsers(selectedUsers.filter((item) => !users.includes(item)));
  //   }
  // };
  const selectAllPress = (users) => {
    const updatedSelectedUsers = [...selectedUsers];

    // Her kullanıcıyı kontrol edin
    users.forEach((user) => {
      // Kullanıcı zaten seçiliyse, seçimi kaldırın; değilse ekleyin
      const index = updatedSelectedUsers.findIndex(
        (selectedUser) => selectedUser.id === user.id
      );
      if (index !== -1) {
        updatedSelectedUsers.splice(index, 1);
      } else {
        updatedSelectedUsers.push(user);
      }
    });

    // Güncellenmiş kullanıcıları set edin
    setSelectedUsers(updatedSelectedUsers);
  };

  const labelsArray = chat?.labelsArray || [];
  const currentGroupLabels = chat?.currentGroupChat?.labels || [];
  const onSubmit = async (formData) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  useEffect(() => {
    if (selectedGroup) {
      setSelectedDepartments(String(selectedGroup?.departmentId));
      setValue("image", selectedGroup?.image);
      setValue("title", selectedGroup?.title);
      setValue("endDate", new Date(moment(selectedGroup?.endDate)));
      setSelectedUsers(selectedGroup?.participants);
      setAdminUser(selectedGroup?.authorizedParticipants);
    } else {
      setValue("image", "");
      setValue("title", "");
      setValue("endDate", new Date());
      setSelectedUsers([]);
      setAdminUser([]);
    }
  }, []);

  useEffect(() => {
    getDepartmentToUser();
  }, []);

  return (
    <form className="w-full scrollbar-hide " onSubmit={handleSubmit(onSubmit)}>
      <TabModal
        width={"1/2"}
        theme={"person"}
        tabData={[
          {
            name: t("person:groupInfo"),
            component: (
              <GroupInfo
                departments={departments}
                t={t}
                errors={errors}
                departmans={departmans}
                control={control}
                setValue={setValue}
                setSelectedTab={setSelectedTab}
                setSelectedDepartments={setSelectedDepartments}
                selectedDepartments={selectedDepartments}
                selectDepartmentUsers={selectDepartmentUsers}
              />
            ),
            value: "info",
          },
          {
            name: t("person:groupMembers"),
            component: (
              <GroupMembers
                triggerAdmin={triggerAdmin}
                triggerUser={triggerUser}
                chat={chat}
                data={data}
                t={t}
                selectedUsers={selectedUsers}
                adminUser={adminUser}
                selectAllPress={selectAllPress}
                auth={auth}
                setSelectedTab={setSelectedTab}
                loadings={loadings}
                onSubmit={onSubmit}
                loading={loading}
                selectedDepartments={selectedDepartments}
              />
            ),
            value: "member",
          },
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </form>
  );
};

export default NewGroup;
