import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PollHeader from "../components/PollHeader";
import { useQuery } from "@tanstack/react-query";
import { pollService } from "../../../services/poll.service";
import PollText from "../components/PollText";
import PollResult from "../components/PollResult";
import { useStore } from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";

const PollViewer = observer(() => {
  const { id } = useParams();
  const { chat } = useStore();
  const { data, refetch, isLoading } = useQuery({
    retry: 0,
    queryKey: ["findOnePoll"],
    queryFn: async () => await pollService.getPollById(id),
  });
  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div className="flex-1">
      <PollHeader
        title={data?.title}
        setSelectedGroup={chat?.setCurrentPoll}
        data={data}
      />
      {data?.isCompleted === true ? (
        <PollResult data={data} />
      ) : (
        <PollText data={data} refetch={refetch} />
      )}
    </div>
  );
});

export default PollViewer;
