import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import "../../Calendar.css";
import { UpuShift } from "../../assets/icons";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { shiftService } from "../../services/shift.service";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { timeFormatter } from "../../utils/timezoneFormatter";
import { Button } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";

// moment.locale(localStorage.getItem("language"));
const localizer = momentLocalizer(moment);

const Shift = () => {
  const { settings } = useStore();
  const [loading, setLoading] = useState(false);
  const [shifts, setShifts] = useState([]);

  const activityGenerator = (data, start, end) => {
    const startDate = moment(start).format("DD MMMM YYYY");
    const activityStartTime = moment(data?.start).format("HH:mm");
    const activityTime = moment(
      startDate + " " + activityStartTime,
      "DD MMMM YYYY HH:mm"
    ).toDate();
    const endDate = moment(end).format("DD MMMM YYYY");
    const activityEndTime = moment(data?.end).format("HH:mm");
    const activityEndTime2 = moment(
      endDate + " " + activityEndTime,
      "DD MMMM YYYY HH:mm"
    ).toDate();
    const title = data?.name;
    const id = data?.id;
    const ActivityDatas = {
      id: id,
      start: activityTime,
      end: activityEndTime2,
      title: title,
      isBreak: true,
      color: "#02C851",
      activity: [],
    };
    return ActivityDatas;
  };

  const TimeBroker = (data) => {
    const weekStart = moment().startOf("isoWeek");
    const endDate = moment(data.end).format("DD MMMM YYYY");
    const newEndDate = moment(endDate).add(-1, "day").format("DD MMMM YYYY");
    const endTime = moment(data?.end).format("23.59");

    const lastEndTime = moment(
      newEndDate + " " + endTime,
      "DD MMMM YYYY HH:mm"
    ).toDate();

    const startDate = moment(data.start).format("DD MMMM YYYY");
    const newStartDate = moment(startDate).add(1, "day").format("DD MMMM YYYY");
    const startTime = moment(data?.start).format("00.01");
    const lastStartTime = moment(
      newStartDate + " " + startTime,
      "DD MMMM YYYY HH:mm"
    ).toDate();
    return {
      day: {
        start: moment(data?.start).toDate(),
        end: lastEndTime,
        title: data?.name,
        color: data?.color,
        isBreak: false,
      },
      night: {
        start: lastStartTime,
        end: moment(data?.end).toDate(),
        title: data?.name,
        color: data?.color,
        isBreak: false,
      },
    };
  };

  const handleData = async () => {
    setLoading(true);
    const nightShifts = [];
    const activities = [];
    const { data } = await shiftService.getDatelist();
    data?.map((item, index) => {
      if (
        !moment(moment(item.start).set("date", moment().format("DD"))).isBefore(
          moment(item.end).set("date", moment().format("DD"))
        )
      ) {
        const partialShift = TimeBroker(item);

        item.start = moment(
          timeFormatter(partialShift.day?.start)?.formatted
        ).toDate();
        item.end = partialShift.day?.end;
        item.title = partialShift.day?.title;
        item.color = partialShift.day?.color;
        item.isBreak = partialShift.day?.isBreak;
        nightShifts.push(partialShift.night);
      } else {
        // item.start = moment(item?.start).toDate();
        // item.end = moment(item.end).toDate();

        item.start = moment(timeFormatter(item?.start)?.formatted).toDate();
        item.end = moment(timeFormatter(item?.end)?.formatted).toDate();
        item.title = item?.name;
        item.color = item?.color;
        item.isBreak = false;
      }
      const act = item.activity.map((data, i) => {
        return activityGenerator(data, item.start, item.end);
      });
      activities.push(...act);
    });
    setShifts([...data, ...nightShifts, ...activities]);
    setLoading(false);
  };
  useEffect(() => {
    handleData();
  }, []);

  const newFormat = moment()
    .startOf("day")
    .subtract(moment().isoWeekday() - 1, "days");
  console.log("newFormat", newFormat);

  const oldFormat = moment().startOf("isoweek");
  // console.log("oldFormat", oldFormat);

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="w-full flex justify-between items-center border-b border-secondary-100 py-3 mb-3 min-h-[56px]">
        <div className="w-1/2 h-full">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button
              colorType={"tertiary-gray"}
              iconLeft={<HumburgerIcon />}
              size={"md"}
              onClick={() => settings.setIsActive(true)}
            />
          </span>
          <UpuShift width={130} height={30} />
        </div>
      </div>
      <div className="flex flex-1 overflow-y-auto p-1">
        {loading ? (
          <div className="w-full h-full bg-gray-50 p-5 flex flex-col rounded-xl">
            <div className="w-full h-20 rounded-lg bg-gray-200 animate-pulse mb-4" />
            <div className="flex w-full items-center justify-between flex-1">
              {[...new Array(7)].map(() => {
                return (
                  <div className="w-[13.4%] h-full animate-pulse bg-gray-200 rounded-lg" />
                );
              })}
            </div>
          </div>
        ) : (
          // <Calendar
          //   // step={30}
          //   view="week"
          //   // date={moment().startOf(1, "week")}
          //   // date={moment().startOf("day").subtract(moment().isoWeekday() - 1, "days")}
          //   date={moment().startOf("isoweek")}
          //   toolbar={false}
          //   selectable={false}
          //   popup={false}
          //   localizer={localizer}
          //   onDoubleClickEvent={(event) => {}}
          //   className="w-full h-full max-h-full"
          //   events={shifts}
          //   eventPropGetter={(event, s, e, isSelected) => {
          //     return {
          //       className: "font-semibold text-sm min-w-full ml-1",
          //       style: {
          //         color: "#000",
          //         backgroundColor: `${event.color}`,
          //       },
          //     };
          //   }}
          // />
          <Calendar
            view="week"
            date={moment().startOf("isoWeek")}
            toolbar={false}
            selectable={false}
            popup={false}
            localizer={localizer}
            onDoubleClickEvent={(event) => {}}
            className="w-full h-full max-h-full"
            events={shifts}
            eventPropGetter={(event, s, e, isSelected) => {
              return {
                className: "font-semibold text-sm min-w-full ml-1",
                style: {
                  color: "#000",
                  backgroundColor: `${event.color}`,
                },
              };
            }}
            views={["week"]}
            step={60}
            timeslots={1}
            showMultiDayTimes
            components={{
              week: {
                header: ({ date, localizer }) => (
                  <div className="rbc-header justify-center items-center">
                    {localizer.format(date, "DD ddd")}
                  </div>
                ),
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Shift;
