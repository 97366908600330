import { toast } from "react-toastify";

export const Toast = (type, text) => {
  switch (type) {
    case "error":
      toast.error(text, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;
    case "success":
      toast.success(text, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;
    case "info":
      toast.info(text, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      break;
    case "warning":
      toast.warning(text, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      break;

    case "update":
      toast.update(text, {
        position: "top-right",
        type: toast.TYP4.INFO,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    default:
      break;
  }
};

// const getData = () => {
//     const id = toast.loading("Please wait...")
//     axios.get(`some-url`)
//       .then(res => {
//          toast.update(id, {render: "All is good", type: "success", isLoading: false});
//     }).catch(err => {
//            toast.update(id, {render: "Something went wrong", type: "error", isLoading: false });
//       });
//    }
