import React, { useEffect, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Toast } from "../../../utils/toastify/toast";
import { pollService } from "../../../services/poll.service";
import { useForm } from "react-hook-form";
import { Button } from "../../../components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PollText = ({ data, refetch }) => {
  const { t } = useTranslation();
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setAnswers(
      (prevAnswers) =>
        data?.question?.map((ques) => ({
          questionId: ques._id,
          answerId: "",
          image: ques?.options?.image,
        })) || []
    );
  }, [data]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
   };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col mt-5 w-full  "
    >
      <div className="flex flex-col gap-4 h-[67vh] overflow-y-scroll scrollbar-hide border-b w-full border-secondary-200">
        {data?.question?.map((question, i) => (
          <div name={question?._id} className="w-full flex flex-col gap-2">
            <span className="text-md font-semibold text-secondary-700 dark:text-[#FAFAFA]">
              {i +
                1 +
                ". " +
                (question?.name ? question?.name?.slice(0, 3) + " ***" : "---")}
            </span>
            {question?.options?.map((d, j) => {
              return (
                <div key={j} className="w-full flex flex-col gap-2">
                  <label
                    for={question._id}
                    className="text-secondary-500 text-md font-normal flex items-center gap-2 dark:text-[#FAFAFA] "
                  >
                    <input
                      onChange={() => {
                        const newAnswers = [...answers];
                        newAnswers[i] = {
                          questionId: question?._id,
                          answerId: d?._id,
                        };
                        setAnswers(newAnswers);
                      }}
                      type="radio"
                      name={question._id}
                      className="w-5 h-5 rounded-full  checked:bg-teal-500 dark:checked:bg-[#2ED3B7] bg-gray-200 dark:bg-[#292929] border-[0.5px] focus:border-transparent border-none checked:ring-0 checked:outline-none outline-none dark:border-[#424242] focus:outline-0 focus:ring-0"
                      required={true}
                    />
                    {d?.option}
                  </label>
                  {d?.image ? (
                    <>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center justify-center w-[144px] min-w-[144px] min-h-[144px] border-secondary-300 h-[144px] border rounded-lg ">
                          <img
                            src={generateFileUrl(d?.image)}
                            style={{
                              minHeight: 144,
                              minWidth: 144,
                              width: 144,
                              height: 144,
                              borderRadius: 8,
                              filter: "blur(4px)",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex-none"></div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className="w-full mt-6">
        <Button
          size={"md"}
          colorType={"primary-person"}
          label={t("person:completePoll")}
          // type={"submit"}
          onClick={() => Toast("warning", t("alert:warning"))}
        />
      </div>
    </form>
  );
};

export default PollText;
