import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { junctionService } from "../../services/junction.service";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import NoAvatar from "../avatar/NoAvatar";
import Input from "../inputs/Input";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import UploadProfile from "../ImageUploader/UploadProfile";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../buttons/Button";
import { Toast } from "../../utils/toastify/toast";
import { orderService } from "../../services/order.service";
import { useTranslation } from "react-i18next";
import TextArea from "../inputs/TextArea";
import HourTimeInput from "../inputs/HourTimeInput";
import { Tooltip } from "react-tooltip";
import Loading from "../loading/Loading";
import { authStore } from "../../stores/auth.store";
import DateTimeInput from "../inputs/DateTimeInput";
import { useStore } from "../../hooks/useStores";
import Selector from "../inputs/Selector";

const JunctionUpdate = ({ junctionId, onClose, refetch, pages }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { auth } = useStore();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss")
  );

  const [endDate, setEndDate] = useState(
    moment(data?.endDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  const handleData = async () => {
    setLoading(true);
    const data = await junctionService
      .getQualityJunctionDetail(junctionId)
      .catch((err) => Toast("error", String(err)));
    setData(data);
    setLoading(false);
  };

  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];

  const dayStartHour = moment(
    auth?.user?.company?.dayStartHour,
    "HH:mm:ss"
  ).format("HH:mm:ss");

  const schema = yup.object({
    workPiece: yup.number(),
    startTime: yup.date(),
    endTime: yup.date(),
    type: yup.string().required(`${t("validation:empty")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });

  const onSubmit = async (value) => {
    Toast("warning", "Demo user cannot update junction.Only view.");
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    setValue("workPiece", data?.metadata?.madeWorkCount);
    setValue("rejectedPiece", data?.metadata?.rejectedWorkCount);
    setValue("acceptedPiece", data?.metadata?.acceptedWorkCount);
    setValue("type", data?.type);
    setEndDate(data?.endDate);
    setStartDate(data?.startDate);
  }, [data]);

  return (
    <>
      {loading ? (
        <div className="flex items-center w-[440px] justify-center  h-[500px]">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[440px] flex flex-col mt-6"
        >
          <div className="w-full flex gap-6 h-fit">
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex items-center h-[72px] gap-3">
                <img
                  src={generateFileUrl(data?.job?.product?.image)}
                  className="w-[96px] h-[72px] rounded border border-gray-300 blur-sm"
                />
                <div className="flex flex-col justify-between h-[72px]">
                  <p className="font-semibold text-gray-900">
                    {data?.job?.product?.name?.slice(0,3)+"***"}
                  </p>
                  <p className="text-sm text-gray-600">
                    {t("product:orderNo")}: {data?.job?.orderNo?.slice(0,3)+"***"}
                  </p>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                      <QtyIcon />
                      <p className="text-sm text-gray-600">
                        {data?.metadata?.madeWorkCount} / {data?.job?.piece}
                      </p>
                    </div>
                    {pages === "activeAndPlanned" ? (
                      <></>
                    ) : (
                      <>
                        <div className="flex items-center gap-1">
                          <ClockIcon />
                          <p className="text-sm text-gray-600">
                            {moment(endDate)
                              .diff(startDate, "minutes")
                              .toFixed(1) + "dk"}
                          </p>
                        </div>
                        <div
                          data-tooltip-id={`${data?.id}`}
                          data-tooltip-place={"top"}
                          data-tooltip-content={"Bitiş tarihi ve saati"}
                          className="flex items-center gap-1"
                        >
                          <CalendarIcon />
                          <p className="text-sm text-gray-600">
                            {moment(data?.endDate).format("DD-MM-YYYY HH:mm")}
                          </p>
                        </div>
                      </>
                    )}

                    <Tooltip id={`${data?.id}`} />
                  </div>
                </div>
              </div>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Selector
                    value={value}
                    items={junctionTypes}
                    theme={"product"}
                    label={t("product:junctionType")}
                    onChange={onChange}
                    disabled={pages === "activeAndPlanned" ? true : false}
                    onBlur={onBlur}
                    validate={errors?.source ? "error" : ""}
                    errorMessage={errors?.source ? errors?.source?.message : ""}
                  />
                )}
                name="type"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    disabled={pages === "activeAndPlanned" ? true : false}
                    label={t("product:numberOfProducts")}
                    errorMessage={errors?.workPiece?.message}
                    validate={errors?.workPiece ? "error" : ""}
                  />
                )}
                name="workPiece"
              />

              <div className="flex flex-row items-center gap-x-3 w-full">
                {/* <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <HourTimeInput
                      label={t("product:jobStartTime")}
                      validate={errors.startTime ? "error" : ""}
                      onChange={(v) => {
                        setValue(
                          `startTime`,
                          moment()
                            .set("hour", v.split(":")[0])
                            .set("minute", v.split(":")[1])
                            .toDate()
                        );
                      }}
                      theme={"product"}
                      value={moment(value).format("HH:mm")}
                      errorMessage={
                        errors.startTime ? errors.startTime.message : ""
                      }
                    />
                  )}
                  name="startTime"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <HourTimeInput
                      theme={"product"}
                      label={t("product:jobEndTime")}
                      onChange={(v) => {
                        setValue(
                          `endTime`,
                          moment()
                            .set("hour", v.split(":")[0])
                            .set("minute", v.split(":")[1])
                            .toDate()
                        );
                      }}
                      value={moment(value).format("HH:mm")}
                      validate={errors.endTime ? "error" : ""}
                      errorMessage={
                        errors.endTime ? errors.endTime.message : ""
                      }
                    />
                  )}
                  name="endTime"
                /> */}
                <DateTimeInput
                  minDate={
                    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") <
                    moment(data?.startDate).format("YYYY-MM-DD") +
                      "T" +
                      dayStartHour
                      ? moment(data?.startDate)
                          .add(-1, "day")
                          .format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                      : moment(data?.startDate).format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                  }
                  maxDate={
                    moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") +
                    "T" +
                    dayStartHour
                  }
                  label={t("product:jobStartDate")}
                  theme={"product"}
                  initialValue={moment(data?.startDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )}
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                />
                <DateTimeInput
                  label={t("product:jobEndDate")}
                  theme={"product"}
                  disabled={pages === "activeAndPlanned" ? true : false}
                  minDate={
                    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") <
                    moment(data?.startDate).format("YYYY-MM-DD") +
                      "T" +
                      dayStartHour
                      ? moment(data?.startDate)
                          .add(-1, "day")
                          .format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                      : moment(data?.startDate).format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                  }
                  maxDate={
                    moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") +
                    "T" +
                    dayStartHour
                  }
                  initialValue={moment(data?.endDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )}
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex items-center ml-auto justify-end gap-3 mt-8">
            <Button
              label={t("buttons:stop")}
              colorType={"secondary-gray"}
              size={"md"}
              onClick={onClose}
            />
            <Button
              type={"submit"}
              label={"Update Junction"}
              colorType={"primary-product"}
              size={"md"}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default JunctionUpdate;

const QtyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#475467"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_9923_13289)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9923_13289">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.5 5H1.5M8 1V3M4 1V3M3.9 11H8.1C8.94008 11 9.36012 11 9.68099 10.8365C9.96323 10.6927 10.1927 10.4632 10.3365 10.181C10.5 9.86012 10.5 9.44008 10.5 8.6V4.4C10.5 3.55992 10.5 3.13988 10.3365 2.81901C10.1927 2.53677 9.96323 2.3073 9.68099 2.16349C9.36012 2 8.94008 2 8.1 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V8.6C1.5 9.44008 1.5 9.86012 1.66349 10.181C1.8073 10.4632 2.03677 10.6927 2.31901 10.8365C2.63988 11 3.05992 11 3.9 11Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
