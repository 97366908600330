import moment from "moment";
import React from "react";
import Modal from "react-modal";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button } from "../../../components";
import { junctionService } from "../../../services/junction.service";
import { authStore } from "../../../stores/auth.store";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";

const DroppedJunctionPlan = ({
  isOpen,
  setIsOpen,
  nodeData,
  orderData,
  refetch,
  processId,
}) => {
  const { t } = useTranslation();
  const startNewPlannedJunction = async () => {
    const data = {
      headerId: orderData?.id,
      companyId: authStore.user.company.id,
      process: {
        id: processId,
      },
      stationId: nodeData?.id,
      type: "internal",
      orderId: orderData?.orderData?.id,
    };
    await junctionService.equipmentBasedJunctionPlanning(data).then((res) => {
      if (res.data?.code === 0) {
        Toast("success", res.data?.message);
        setIsOpen(false);
        refetch();
      } else if (res.data.code === 1) {
        Toast("error", res.data?.message);
        setIsOpen(false);
      } else {
        Toast("error", "Error");
      }
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      overlayClassName="fixed inset-0 flex items-center justify-center bg-[#0F0F0F] bg-opacity-50 backdrop-blur-[4px] z-[999]"
    >
      <div className="w-full h-full relative flex flex-col">
        <div className="w-full h-full">
          <div className="w-[372px] bg-white flex flex-col items-center">
            <h2 className="text-lg font-semibold text-secondary-900">
              {t("product:planWork")}
            </h2>
            <p className="text-sm text-gray-500 text-center px-4 mt-3">
              {t("product:plannedEquipment")}
            </p>

            <div className="w-[284px] min-w-[284px] mr-3 h-[72px] min-h-[72px] rounded-lg  border border-gray-300 shadow-sm p-2 flex items-center bg-white mt-5">
              <img
                className="w-[72px] h-[54px] rounded border border-gray-300"
                src={generateFileUrl(
                  orderData?.internalOperation?.product?.image
                )}
              />
              <div className="flex flex-col ml-2.5 flex-1 overflow-hidden">
                <p
                  title={orderData?.internalOperation?.product?.name}
                  className="text-sm font-semibold text-secondary-900 truncate"
                >
                  {orderData?.internalOperation?.product?.name ||
                    "Product Name"}
                </p>
                <p
                  title={orderData?.orderData?.orderNo}
                  className="text-xs text-gray-600 truncate"
                >
                  Order No: {orderData?.orderData?.orderNo}
                </p>
                <div className="w-full overflow-hidden flex items-center justify-between">
                  <div
                    title={moment(orderData?.orderData?.deliveryDate).format(
                      "DD.MM.YY"
                    )}
                    className="flex items-center gap-0.5 w-1/2 overflow-hidden"
                  >
                    <CalendarIcon />
                    <p className="text-xs text-gray-600 truncate">
                      {moment(orderData?.orderData?.deliveryDate).format(
                        "DD.MM.YY"
                      )}
                    </p>
                  </div>
                  <div
                    title={orderData?.orderData?.piece}
                    className="flex items-center gap-0.5 w-1/2 overflow-hidden"
                  >
                    <QtyLight />
                    <p className="text-xs text-gray-600 truncate">
                      {orderData?.orderData?.piece}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4 w-12 h-12 rounded-md bg-orange-100 flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 5V19M12 19L19 12M12 19L5 12"
                  stroke="#DC6803"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <div className="w-[284px] min-w-[284px] mr-3 h-[72px] min-h-[72px] rounded-lg  border border-gray-300 shadow-sm p-2 flex items-center bg-white">
              <img
                className="w-[72px] h-[54px] rounded border border-gray-300"
                src={generateFileUrl(nodeData?.metadata?.image)}
              />
              <div className="flex flex-col ml-2.5 flex-1 overflow-hidden">
                <p className="text-sm font-semibold text-secondary-900 truncate">
                  {nodeData?.metadata?.brand + " " + nodeData?.metadata?.model}
                </p>
                <p className="text-xs text-gray-600 truncate mt-1">
                  {nodeData?.id}
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-between gap-3 mt-8">
              <Button
                colorType={"secondary-gray"}
                label={t("buttons:stop")}
                size={"md"}
                onClick={() => setIsOpen(false)}
              />
              <Button
                onClick={() => {
                  setIsOpen(false);
                  Toast("warning", t("alert:warning"))}}
                colorType={"primary-product"}
                label={t("product:planWork")}
                size={"md"}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 24,
    zIndex: 99999,
    borderRadius: "12px",
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "#FFF",
    borderColor: "#FFF",
  },
};
export default DroppedJunctionPlan;

const QtyLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.3335H3.71429C2.76751 2.3335 2 3.10101 2 4.04778V11.8692C2 12.816 2.76751 13.5835 3.71429 13.5835H12.2857C13.2325 13.5835 14 12.816 14 11.8692V4.04778C14 3.10101 13.2325 2.3335 12.2857 2.3335H10.25M5.75 2.3335V6.69481C5.75 6.75853 5.81706 6.79997 5.87405 6.77147L8 5.7085L10.126 6.77147C10.1829 6.79997 10.25 6.75853 10.25 6.69481V2.3335M5.75 2.3335H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const Process = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.0289 9.69452C3.96498 9.69452 4.72381 8.93568 4.72381 7.9996C4.72381 7.06353 3.96498 6.30469 3.0289 6.30469C2.09282 6.30469 1.33398 7.06353 1.33398 7.9996C1.33398 8.93568 2.09282 9.69452 3.0289 9.69452Z"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9723 9.69452C13.9083 9.69452 14.6672 8.93568 14.6672 7.9996C14.6672 7.06353 13.9083 6.30469 12.9723 6.30469C12.0362 6.30469 11.2773 7.06353 11.2773 7.9996C11.2773 8.93568 12.0362 9.69452 12.9723 9.69452Z"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00058 4.72284C8.93666 4.72284 9.69549 3.964 9.69549 3.02792C9.69549 2.09185 8.93666 1.33301 8.00058 1.33301C7.0645 1.33301 6.30566 2.09185 6.30566 3.02792C6.30566 3.964 7.0645 4.72284 8.00058 4.72284Z"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00058 14.6662C8.93666 14.6662 9.69549 13.9074 9.69549 12.9713C9.69549 12.0352 8.93666 11.2764 8.00058 11.2764C7.0645 11.2764 6.30566 12.0352 6.30566 12.9713C6.30566 13.9074 7.0645 14.6662 8.00058 14.6662Z"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69531 3.02783H12.4072C13.0312 3.02783 13.5371 3.53374 13.5371 4.15778V6.40111"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2773 7.99951H4.72363"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.46387 9.59814V11.8415C2.46387 12.4655 2.96977 12.9714 3.59381 12.9714H6.30568"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69531 12.9712H14.6671"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.48088 3.02783H1.33398"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.48145 4.15784L4.61139 3.02789L3.48145 1.89795"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5371 14.1012L14.6671 12.9713L13.5371 11.8413"
        stroke="#475467"
        strokeWidth="0.903955"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
