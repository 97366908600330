import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { ArrowDownIcon } from "../../../assets/icons/stepsIcons";
import Badgets from "../../../components/buttons/Badgets";
import { Button } from "../../../components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../../../assets/icons/PersonIcons";
import SearchInput from "../../../components/inputs/SearchInput";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { personalService } from "../../../services/personal.service";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const UserTable = ({
  setSearchVal,
  userValue = "55",
  onEdit,
  tableBodyItems,
  setTableBodyItems,
  handlePageChange,
  getPersonals,
  setCurrentPage,
  currentPage,
  selectedDepartment,
}) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [deletePerson, setDeletePerson] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const navigate = useNavigate();

  const [tableHeadItems, setTableHeadItems] = useState([
    { name: t("table:image"), value: "image", type: "" },
    {
      name: t("table:name") + " " + t("table:surname"),
      value: "name",
      type: "string",
    },
    // {
    //   name: t("table:surname"),
    //   value: "lastName",
    //   type: "string",
    // },
    {
      name: t("table:authorization"),
      value: "rolesKey",
      type: "string",
    },
    { name: t("label:talent"), value: "talents", type: "number" },
    { name: t("table:phoneNo"), value: "phoneNumber", type: "number" },
    {
      name: t("addPersonal:upuPoint"),
      value: "upuPoint",
      type: "number",
    },
    { name: t("tasks:edit"), value: "edit" },
    { name: t("buttons:delete"), value: "delete" },
    { name: t("buttons:detail"), value: "detail" },
  ]);

  // 👇️ sort by Numeric property ASCENDING (1 - 100)
  const numAscending = (key) => {
    setTableBodyItems([...tableBodyItems].sort((a, b) => a[key] - b[key]));
  };
  // 👇️ sort by Numeric property DESCENDING (100 - 1)
  const numDescending = (key) => {
    setTableBodyItems([...tableBodyItems].sort((a, b) => b[key] - a[key]));
  };

  // 👇️ sort by String property ASCENDING (A - Z)
  const strAscending = (key) => {
    setTableBodyItems(
      [...tableBodyItems].sort((a, b) => a[key]?.localeCompare(b[key]))
    );
  };
  // 👇️ sort by String property DESCENDING (Z - A)
  const strDescending = (key) => {
    setTableBodyItems(
      [...tableBodyItems].sort((a, b) => b[key]?.localeCompare(a[key]))
    );
  };

  const ITEMS_PER_PAGE = height > 950 ? 8 : 7;
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = tableBodyItems?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(tableBodyItems.length / ITEMS_PER_PAGE);

  const toggleSort = (item, index) => {
    const updatedItems = [...tableHeadItems];
    updatedItems.forEach((headItem, i) => {
      if (i === index) {
        if (!headItem.active) {
          headItem.ascending = true;
        } else {
          headItem.ascending = !headItem.ascending;
        }
        headItem.active = true;
      } else {
        headItem.active = false;
        headItem.ascending = true;
      }
    });
    setTableHeadItems(updatedItems);

    if (item.type === "number") {
      if (item.ascending) {
        numAscending(item.value);
      } else {
        numDescending(item.value);
      }
    } else {
      if (item.ascending) {
        strAscending(item.value);
      } else {
        strDescending(item.value);
      }
    }
  };

  const onDeleted = async (id) => {
    await personalService.removePersonal(id).then(() => {
      Toast("success", t("addPersonal:deletedPersonalMessage"));
    });
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    const displayRange = 3;

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPageButton(i));
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 6; i++) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(renderEllipsis());
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(renderEllipsis());
        for (let i = totalPages - 5; i <= totalPages; i++) {
          pageNumbers.push(renderPageButton(i));
        }
      } else {
        pageNumbers.push(renderEllipsis());
        for (
          let i = currentPage - displayRange;
          i <= currentPage + displayRange;
          i++
        ) {
          pageNumbers.push(renderPageButton(i));
        }
        pageNumbers.push(renderEllipsis());
      }
    }

    return pageNumbers;
  };

  const renderPageButton = (pageNumber) => (
    <span>
      <Button
        key={pageNumber}
        onClick={() => setCurrentPage(pageNumber)}
        colorType={
          currentPage === pageNumber ? "secondary-gray" : "tertiary-gray"
        }
        size={"sm"}
        label={pageNumber.toString()}
      />
    </span>
  );

  const renderEllipsis = () => <span className="mx-1">...</span>;

  return (
    <>
      <div className="w-full flex  flex-1  flex-col h-full">
        <div className="h-[80px] border border-[#EAECF0] border-b-0 flex items-center justify-between px-6 py-5 rounded-t-xl gap-x-2">
          {selectedDepartment ? (
            <span className="text-[#101828] font-semibold text-lg">
              {selectedDepartment?.name}
            </span>
          ) : (
            <span className="text-[#101828] font-semibold text-lg">
              {t("settingsKpi:personels")}
            </span>
          )}
          <Badgets colorType={"fill-success"} label={userValue} size={"sm"} />
          <div className="flex items-center flex-row ml-auto w-[320px]">
            <SearchInput
              setSearchVal={setSearchVal}
              placeholder={t("chat:search")}
              theme={"person"}
            />
          </div>
        </div>
        <div className="w-full flex-1 h-full">
          <div className="min-w-full divide-y border border-gray-200 h-full rounded-b-xl">
            <div
              className={`w-full  ${
                height > 950
                  ? "min-h-[615px] h-[615px]"
                  : "min-h-[547px] h-[547px]"
              } `}
            >
              <div className="flex bg-secondary-50 max-h-[44px] border-b border-b-secondary-200 min-h-[44px]">
                {tableHeadItems.map((item, i) => {
                  return (
                    <div
                      key={i}
                      scope="col"
                      className={` px-4 py-3 ${i === 8 ? "" : "border-r"} w-[${
                        i === 0 || i === 6 || i === 7 || i === 8
                          ? "6%"
                          : i === 3
                          ? "13%"
                          : i === 5
                          ? "13%"
                          : i === 1
                          ? "16%"
                          : i === 2
                          ? "20%"
                          : i === 4
                          ? "14%"
                          : ""
                      }] ${
                        !item.active
                          ? "text-secondary-500"
                          : "text-secondary-900"
                      } items-center text-center`}
                    >
                      <span
                        className={`text-center rounded  ${
                          !item.active ? "text-gray-500" : "text-gray-500"
                        } flex text-xs font-medium ${
                          i === 0 || i === 4 || i === 5 || i === 6 || i === 7
                            ? "items-center justify-center"
                            : " items-center"
                        } cursor-pointer whitespace-nowrap`}
                        onClick={() => toggleSort(item, i)}
                      >
                        {item?.name}
                        <span
                          className={`${
                            item?.value === "edit" ? "hidden" : "flex"
                          }`}
                        >
                          {item?.active ? (
                            item?.value === "image" ||
                            item?.value === "detail" ||
                            item?.value === "delete" ? null : item.ascending ? (
                              <ArrowDownIcon
                                className=" ml-1 bg-b h-4 w-4 text-[#667085]"
                                aria-hidden="true"
                                style={{
                                  display: item.name ? "block" : "none",
                                }}
                              />
                            ) : (
                              <ArrowDownIcon
                                className=" ml-1 h-4 w-4 rotate-180 text-[#667085]"
                                aria-hidden="true"
                              />
                            )
                          ) : item.value === "image" ||
                            item?.value === "detail" ||
                            item?.value === "delete" ? null : (
                            <ArrowDownIcon
                              className=" ml-1 h-4 w-4 text-[#667085]"
                              aria-hidden="true"
                              style={{
                                display: item.name ? "block" : "none",
                              }}
                            />
                          )}
                        </span>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="w-full bg-[#FFF]">
                {currentItems?.map((person, key) => (
                  <div
                    key={key}
                    className={`flex items-center border-b ${
                      height > 950 ? "h-[71.5px]" : "h-[72px]"
                    } `}
                  >
                    <div className="whitespace-nowrap text-center border-r h-full  items-center text-sm font-medium text-gray-900 flex justify-center w-[6%]">
                      {person?.image === "Empty" ? (
                        <div
                          className={
                            "w-10 h-10 rounded-full bg-primary-700 flex border-4 border-primary-400 items-center justify-center font-medium text-white text-md"
                          }
                        >
                          {person?.name?.charAt(0).toUpperCase() +
                            person?.lastName?.charAt(0)}
                        </div>
                      ) : person?.avatar ? (
                        <img
                          className="h-10 w-10 blur-sm rounded-full"
                          src={generateFileUrl(person?.avatar)}
                          alt=":)"
                        />
                      ) : (
                        <NoAvatar
                          name={person?.name}
                          lastName={person?.lastName}
                          size={40}
                          theme={"person"}
                        />
                      )}
                    </div>
                    <div className=" border-r h-full p-4 flex items-center w-[16%]">
                      {/* {person?.name} */}
                      <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {person?.name + " " + person?.lastName}
                      </p>
                    </div>
                    {/* <div className="border-r h-full p-4 flex items-center w-[20%]">
                      <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {person?.lastName}
                      </p>
                    </div> */}
                    <div className="border-r h-full p-4 flex items-center w-[20%]">
                      <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-[#F5F5F5] font-normal">
                        {person?.rolesKey
                          ? person?.rolesKey?.slice(0, 2) + " ***"
                          : "---"}
                      </p>
                    </div>
                    <div className="whitespace-nowrap h-full flex p-4 border-r text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal w-[13%]">
                      {person?.talent
                        ? person?.talent?.slice(0, 2) + " ***"
                        : "---"}
                    </div>
                    <div className="whitespace-nowrap h-full border-r flex text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal w-[14%]">
                      {person?.phoneNumber
                        ? person?.phoneNumber?.slice(0, 4) + " ***"
                        : "---"}
                    </div>
                    <div className="whitespace-nowrap h-full border-r flex text-center items-center justify-center text-sm text-gray-500 dark:text-[#F5F5F5] font-normal w-[13%]">
                      {person?.upuPoint
                        ? person?.upuPoint?.slice(0, 2) + " ***"
                        : "0"}
                    </div>
                    <div className="h-full items-center flex justify-center cursor-pointer whitespace-nowrap  border-r text-center text-sm w-[6%]">
                      <Button
                        // onClick={() => onEdit(person)}
                        onClick={() => Toast("warning", t("alert:warning"))}
                        colorType={"tertiary-gray"}
                        iconLeft={<EditIcon />}
                        size={"sm"}
                      />
                    </div>
                    <div className="h-full items-center flex justify-center cursor-pointer whitespace-nowrap  border-r text-center text-sm w-[6%]">
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<DeleteIcon />}
                        size={"sm"}
                        // onClick={() => {
                        //   setDeletePerson(person?.id);
                        //   setIsAlert(true);
                        // }}
                        onClick={() => Toast("warning", t("alert:warning"))}
                      />
                    </div>
                    <div className="h-full items-center flex justify-center cursor-pointer whitespace-nowrap   text-center text-sm w-[6%]">
                      <Button
                        colorType={"tertiary-gray"}
                        iconLeft={<DetailIcon />}
                        size={"sm"}
                        // onClick={() => {
                        //   navigate(
                        //     `/app/machine/digital-twin/user-detail/${
                        //       person?.id
                        //     }/${moment().format("YYYY-MM-DD")}`
                        //   );
                        // }}
                        onClick={() => Toast("warning", t("alert:warning"))}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`flex w-full items-center ${
                height > 950 ? "h-[72px]" : "h-[64px]"
              }  `}
            >
              <div
                className={`flex w-full min-h-[40px] ${
                  height < 950 ? "mt-4" : "mt-0"
                } max-h-[40px] px-6`}
              >
                <span className="flex mr-auto">
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    colorType={"secondary-gray"}
                    size={"sm"}
                    label={t("buttons:previous")}
                    iconLeft={<ArrowLeftIcon />}
                  />
                </span>
                <span className="flex gap-x-1">{renderPageNumbers()}</span>
                <span className="flex ml-auto">
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    colorType={"secondary-gray"}
                    size={"sm"}
                    label={t("buttons:next")}
                    iconRight={<ArrowRightIcon />}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        title={t("addPersonal:deletePersonal")}
        subTitle={t("addPersonal:deletePersonalWarning")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={async () => {
          // await onDeleted(deletePerson);
          // await getPersonals();
          setIsAlert(false);
        }}
      />
    </>
  );
};

UserTable.propTypes = {
  tableBodyItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      machineModal: PropTypes.string,
      machineName: PropTypes.string,
      puantaj: PropTypes.number,
      user: PropTypes.arrayOf(
        PropTypes.shape({
          user: PropTypes.string,
        })
      ),
      upuPoint: PropTypes.number,
    })
  ),
  onSort: PropTypes.func,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
};

UserTable.defaultProps = {
  users: [],
  isOpen: false,
  setIsOpen: () => {},
};

export default UserTable;

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        d="M12.3333 5.00008V4.33341C12.3333 3.39999 12.3333 2.93328 12.1517 2.57676C11.9919 2.26316 11.7369 2.00819 11.4233 1.8484C11.0668 1.66675 10.6001 1.66675 9.66667 1.66675H8.33333C7.39991 1.66675 6.9332 1.66675 6.57668 1.8484C6.26308 2.00819 6.00811 2.26316 5.84832 2.57676C5.66667 2.93328 5.66667 3.39999 5.66667 4.33341V5.00008M7.33333 9.58342V13.7501M10.6667 9.58342V13.7501M1.5 5.00008H16.5M14.8333 5.00008V14.3334C14.8333 15.7335 14.8333 16.4336 14.5608 16.9684C14.3212 17.4388 13.9387 17.8212 13.4683 18.0609C12.9335 18.3334 12.2335 18.3334 10.8333 18.3334H7.16667C5.76654 18.3334 5.06647 18.3334 4.53169 18.0609C4.06129 17.8212 3.67883 17.4388 3.43915 16.9684C3.16667 16.4336 3.16667 15.7335 3.16667 14.3334V5.00008"
        stroke="#B42318"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const DetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_12738_55687)">
        <path
          d="M10.0003 13.3334V10.0001M10.0003 6.66675H10.0087M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
          stroke="#475467"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12738_55687">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
