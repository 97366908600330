import EChartsReact from "echarts-for-react";
import React from "react";
import {
  AverageLeftIcon,
  AverageRightIcon,
} from "../../../../assets/icons/machineIcons";
import { manipulate } from "../../../../utils/manipulate";
import { useTranslation } from "react-i18next";

const QualityOeeCard = ({
  value,
  kpi1,
  kpi2,
  timeMass,
  gaugeWidth,
  gaugeHeight,
  gaugeRadius,
  activeLength,
  waitingLength,
  name,
}) => {
  const { t } = useTranslation();
  const xAxis = gaugeWidth / 2;
  const yAxis = gaugeHeight / 1.2;
  const getOption = {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        center: [xAxis, yAxis],
        radius: gaugeRadius, //used to adjust arch size
        min: 0,
        max: 100,
        splitNumber: !value ? 4 : 4,
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 6,
            fontWeight: "bold",
            color:
              !value && !kpi1 && !kpi2
                ? [
                    [
                      1,
                      localStorage.color_theme === "dark"
                        ? "#525252"
                        : "#D0D5DD",
                    ],
                  ]
                : [
                    [kpi1, "#F22032"],
                    [kpi2, "#F79919"],
                    [1, "#02C851"],
                  ],
          },
        },

        pointer: {
          // icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          // length: "13%",
          // width: 3,
          // offsetCenter: [0, "-62%"],
          // itemStyle: {
          //   color: "auto",
          // },
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z'",
          length: "16%",
          width: 6,
          offsetCenter: [0, "-60%"],
          itemStyle: {
            color: "auto",
          },
        },
        axisTick: {
          length: 3,
          distance: 2,
          lineStyle: {
            color: "auto",
            width: 0.5,
          },
        },
        splitLine: {
          length: 5,
          distance: 3,
          lineStyle: {
            color: "auto",
            width: 1.5,
          },
        },
        axisLabel: {
          color: "#464646",
          fontSize: 20,
          distance: -60,
          rotate: "tangential",
          formatter: function (value) {
            if (value === 0.875) {
              return "Grade A";
            } else if (value === 0.625) {
              return "Grade B";
            } else if (value === 0.375) {
              return "Grade C";
            } else if (value === 0.125) {
              return "Grade D";
            }
            return "";
          },
        },
        title: {
          offsetCenter: [0, "-30%"],
          fontSize: 15,
          fontWeight: 400,
          color: "#455467",
        },
        detail: {
          fontSize: gaugeWidth <= 111 ? 8 : 25,
          fontWeight: 600,
          offsetCenter: [0, "7%"],
          valueAnimation: true,
          formatter: function (value) {
            return "%" + Math.round(value);
          },
          color: "auto",
        },
        data: [
          {
            value: value * 100 || 0,
            name: timeMass + " h",
            fontSize: 5,
            fontWeight: "bold",
          },
        ],
      },
    ],
  };

  return (
    <div className="flex flex-col">
      <div className="flex p-3 pb-[6px] w-full items-center justify-center">
        <p className="text-[#101828] text-lg font-semibold truncate">
          {/* {name?.slice(0, 3) + " ***"} */}
          {manipulate(name)}
        </p>
      </div>
      <div className="flex px-3 py-2 gap-x-3 w-full">
        <div className="flex flex-col w-[50%]">
          <div className="w-full rounded-t-sm bg-[#079455] min-h-[2px] max-h-[2px]"></div>
          <div className="flex flex-col items-center border border-t-[0px] px-3 py-[2px] rounded-b border-[#079455]">
            <p className="text-[#475467] text-xs font-normal">
              {t("product:actives")}{" "}
            </p>
            <p className="text-[#067647] text-xs font-semibold">
              {activeLength}
            </p>
          </div>
        </div>
        <div className="flex flex-col w-[50%]">
          <div className="w-full rounded-t-sm bg-[#DC6803] min-h-[2px] max-h-[2px]"></div>
          <div className="flex flex-col items-center border border-t-[0px] px-3 py-[2px] rounded-b border-[#DC6803]">
            <p className="text-[#475467] text-xs font-normal">
              {t("product:waiting")}{" "}
            </p>
            <p className="text-[#B54708] text-xs font-semibold">
              {waitingLength}
            </p>
          </div>
        </div>
      </div>
      <div className="flex px-3 pt-1 pb-2 w-full justify-center">
        <EChartsReact
          style={{
            width: gaugeWidth,
            height: gaugeHeight,
          }}
          option={getOption}
        />
      </div>
      <div className="flex px-3 pb-4 pt-1 w-full justify-between">
        <div className="flex items-center gap-x-1">
          <AverageLeftIcon size={16} />
          <div className="flex items-center gap-x-[2px]">
            <p className="text-[#475467] font-medium text-sm">
              %{(kpi1 * 100).toFixed(0)}
            </p>
            <p className="text-[#667085] font-normal text-sm">avg</p>
          </div>
        </div>
        <div className="flex items-center gap-x-1">
          <div className="flex items-center gap-x-[2px]">
            <p className="text-[#667085] font-normal text-sm">max</p>
            <p className="text-[#475467] font-medium text-sm">
              %{parseInt(kpi2 * 100) > 100 ? 100 : parseInt(kpi2 * 100)}
            </p>
          </div>
          <AverageRightIcon size={16} />
        </div>
      </div>
    </div>
  );
};

export default QualityOeeCard;
