import React, { useEffect, useRef, useState } from "react";
import {
  CcountIcon,
  ConsumeIcon,
} from "../../../../assets/icons/leftMenuIcons";
import { KwhIcon } from "../../../../assets/icons/lossIcon";
import { InternalOperationIcon } from "../../../../assets/icons/stepHeaderIcons";
import Skeleton from "react-loading-skeleton";
import GaugeChart from "./GaugeChart";
import DetailGaugeChart from "../chart/DetailGaugeChart";
import { useTranslation } from "react-i18next";

const OperationDetailInfo = ({ data, isLoading, isFetching }) => {
  const { t } = useTranslation();
  const [gaugeData, setGaugeData] = useState([]);

  useEffect(() => {
    setGaugeData(
      [
        { ...data?.availability, type: "Availability" },
        { ...data?.performance, type: "Performance" },
        { ...data?.utilization, type: "Utilization" },
      ] || [{}, {}, {}]
    );
  }, [data]);
  return (
    <div className="flex flex-col w-full max-w-[500px] gap-2">
      {isLoading || isFetching ? (
        <Skeleton width={472} height={144} borderRadius={8} />
      ) : (
        <>
          <div className="flex flex-col items-start rounded-lg border-secondary-200 shadow-xs w-[472px] border max-w-[472px] h-[138px] max-h-[138px]">
            <div className="flex flex-col h-[46px] w-full">
              <div className="flex flex-row items-center w-full h-[46px] border-b border-secondary-200">
                <div className="w-[50%] border-r border-secondary-200 h-full px-6 py-1">
                  <div className="flex flex-row items-center gap-3 h-full">
                    <div className="text-warning-700">
                      <CcountIcon width={24} height={24} />
                    </div>
                    <div className="flex flex-col h-full items-start">
                      <p className="text-xs font-normal text-secondary-600">
                        C-Count
                      </p>
                      <div className="flex items-center gap-x-1">
                        <p className="text-sm font-semibold text-warning-700 ">
                          {/* {data?.cCount || "--"} */}
                          {data?.cCount != null
                            ? String(data.cCount).slice(0, 2) + " **"
                            : "***"}
                        </p>
                        <p className="text-sm font-normal text-secondary-600">
                          kgs
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[50%]  border-secondary-200 h-full px-6 py-1">
                  <div className="flex flex-row items-center gap-3 h-full">
                    <div className="text-primary-700">
                      <ConsumeIcon width={24} height={24} />
                    </div>
                    <div className="flex flex-col h-full items-start">
                      <p className="text-xs font-normal text-secondary-600">
                        {t("settingTab:consume")}
                      </p>
                      <div className="flex items-center gap-x-1">
                        <p className="text-sm font-semibold text-seconary-600 ">
                          {"***"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row h-[46px] bg-secondary-50 items-center w-full">
                <div className="w-[50%]  border-b border-secondary-200 h-full px-6 py-1">
                  <div className="flex flex-row items-center gap-3 h-full">
                    <KwhIcon width={24} height={24} />

                    <div className="flex flex-col h-full items-start">
                      <p className="text-xs font-normal text-secondary-600">
                        {t("settingTab:energy")}
                      </p>
                      <div className="flex items-center gap-x-1">
                        <p className="text-sm font-semibold text-grayBlue-600 ">
                          {/* {data?.kwh?.toFixed(2) || "-- "} */}
                          {data?.kwh != null
                            ? data.kwh.toFixed(2).slice(0, 2) + " **"
                            : "***"}
                        </p>
                        <p className="text-sm font-normal text-secondary-600">
                          kVa/h
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[25%]  border-b border-secondary-200 h-full px-6 py-1">
                  <div className="flex flex-col h-full items-start">
                    <p className="text-xs font-normal text-secondary-600">
                      {t("loss:costt")}
                    </p>
                    <div className="flex items-center gap-x-1">
                      <p className="text-sm font-semibold text-grayBlue-600  ">
                        {data?.kwhCostForStandard != null
                          ? data?.kwhCostForStandard?.toFixed(2).slice(0, 2) +
                            " **"
                          : "***"}
                      </p>
                      <p className="text-sm font-normal text-secondary-600">
                        ₺
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[25%]  border-b border-secondary-200 h-full px-6 py-1">
                  <div className="flex flex-col h-full items-start">
                    <p className="text-xs font-normal text-secondary-600">
                      {t("loss:costThree")}
                    </p>
                    <div className="flex items-center gap-x-1">
                      <p className="text-sm font-semibold text-grayBlue-600 ">
                        {data?.kwhCostForThreeTime != null
                          ? data?.kwhCostForThreeTime?.toFixed(2).slice(0, 2) +
                            " **"
                          : "***"}
                      </p>
                      <p className="text-sm font-normal text-secondary-600">
                        ₺
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row h-[46px] items-center w-full">
                <div className="w-[50%] border-r border-secondary-200 h-full px-6 py-1">
                  <div className="flex flex-row items-center gap-3 h-full">
                    <div className="text-secondary-600">
                      <InternalOperationIcon width={24} height={24} />
                    </div>
                    <div className="flex flex-col h-full items-start">
                      <p className="text-xs font-normal text-secondary-600">
                        {t("loss:operation")}
                      </p>
                      <div className="flex items-center gap-x-1">
                        <p className="text-sm font-semibold text-secondary-700 ">
                          {"*** "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[50%]   border-secondary-200 h-full px-6 py-1">
                  <div className="flex flex-col h-full items-start">
                    <p className="text-xs font-normal text-secondary-600">
                      {t("settingTab:company")}
                    </p>
                    <div className="flex items-center gap-x-1">
                      <p className="text-sm font-semibold text-secondary-700 ">
                        %
                        {data?.kwhRatioByCompany != null
                          ? data?.kwhRatioByCompany?.toFixed(0).slice(0, 2) +
                            " **"
                          : "-- "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex items-start flex-row  mt-3 max-w-[472px] w-[472px]">
        {isLoading || isFetching ? (
          <Skeleton width={472} height={144} borderRadius={8} />
        ) : (
          <>
            <div className="flex w-full items-center  relative justify-between border rounded-lg border-secondary-200 shadow">
              {gaugeData?.map((item, index) => {
                return (
                  <DetailGaugeChart
                    gaugeData={gaugeData}
                    key={index}
                    index={index}
                    name={t(`addProcess:${item?.type}`)}
                    value={item?.actual}
                    timeMass={item?.timeMass}
                    kpi1={item?.average?.toFixed(2)}
                    kpi2={item?.kpi == 0 ? 0 : item?.kpi}
                    selectedProcess={data?.operations[0]?.id}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OperationDetailInfo;
