import React, { useEffect, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button, CustomModal } from "../../../components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../../../assets/icons/PersonIcons";
import { useQuery } from "@tanstack/react-query";
import { junctionService } from "../../../services/junction.service";
import Badgets from "../../../components/buttons/Badgets";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import SingleJunctionCard from "../../../components/junction/SingleJunctionCard";
import moment from "moment";
import FinishJunctionModal from "../../../components/modal/FinishJunctionModal";
import TransactionDetailModal from "../../../components/modal/TransactionDetailModal";
import NewPlannedJunctionModal from "./NewPlannedJunctionModal";
import NewPlannedJunctionExternalModal from "./NewPlannedJunctionExternalModal";
import SelectUserModal from "../../../components/modal/SelectUserModal";
import SelectEquModal from "../../../components/modal/SelectEquModal";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { orderService } from "../../../services/order.service";
import { Toast } from "../../../utils/toastify/toast";
import SelectSupplierModal from "./SelectSupplierModal";
import FinishOperationSelectNode from "../../../components/modal/FinishOperationSelectNode";

import { Tooltip } from "react-tooltip";
import {
  formatSeconds,
  secondToDay,
  secondToHoursAndMinute,
} from "../../../utils/secondToHoursAndMinute";

const OperationDetailModal = ({
  t,
  operations,
  filledOperations,
  orderData,
  listOrder,
  name,
}) => {
  const [finishOperationAlert, setFinishOperationAlert] = useState("");
  const [newPlannedJunctionModal, setNewPlannedJunctionModal] = useState(false);
  const [newPlannedJunctionExternalModal, setNewPlannedJunctionExternalModal] =
    useState(false);
  const [isSelectSupplierModal, setIsSelectSupplierModal] = useState();
  const [isOpenFinishOperation, setIsOpenFinishOperation] = useState(false);
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [isSelectNodeModal, setIsSelectNodeModal] = useState();
  const [currentStep, setCurrentStep] = useState(operations?.step);
  const [isSelectUserModal, setIsSelectUserModal] = useState();
  const [junctionType, setJunctionType] = useState("internal");
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [junctionDetailOpen, setJunctionDetailOpen] = useState(false);
  const [junctionId, setJunctionId] = useState("");
  const currentOperation = filledOperations.find(
    (operation) => operation.step === currentStep
  );
  const { data, isLoading, refetch } = useQuery({
    retry: 0,
    queryKey: ["operation-detail-product"],
    queryFn: async () =>
      await junctionService.getOperationJunctionList(currentOperation?.id),
  });

  const tooltips = [
    {
      id: "qty",
      description: "qty",
    },
    {
      id: "quality",
      description: "qualiryyy",
    },
    { id: "time", description: "time" },
    { id: "qualityControl", description: "qualityControl" },
  ];

  const activeJunctions = data?.filter(
    (obj) => obj?.status === "active" || obj?.status === "planned"
  );

  const completedWorks = data?.filter(
    (obj) =>
      obj?.status === "completed" && obj?.metadata?.qualityStatus == "waiting"
  );

  const completedAndApprovedWorks = data?.filter(
    (obj) =>
      obj?.status === "completed" && obj?.metadata?.qualityStatus !== "waiting"
  );

  const nextStep = currentStep + 1;
  const handleNextStep = () => {
    if (currentStep < filledOperations.length) {
      setCurrentStep(nextStep);
    }
  };
  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const finishOperation = async () => {
    const data = orderData.operations.filter(
      (item) => item.step == currentStep
    );
    const internalOrExternal =
      data[0]?.internalOperation?.length > 0 ? "internal" : "external";
    const operationId = data[0]?.id;
    if (internalOrExternal === "internal") {
      await orderService.finishOperation(operationId).then((res) => {
        if (res?.data?.code === 0) {
          Toast("success", res?.data?.message);
          listOrder();
        } else {
          Toast("error", res?.data?.message);
        }
      });
    } else {
      await orderService.finishOperation(operationId).then((res) => {
        if (res?.data?.code === 0) {
          Toast("success", res?.data?.message);
          listOrder();
        } else {
          Toast("error", res?.data?.message);
        }
      });
    }
  };

  useEffect(() => {
    refetch();
  }, [currentOperation]);

  return (
    <>
      <div className="w-[1054px] h-[80vh] flex flex-col full overflow-hidden">
        <div className="w-full h-[72px] min-h-[72px] flex items-center mt-6 relative">
          <img
            src={generateFileUrl(orderData?.product?.image)}
            className="w-[96px] h-[72px] rounded border border-gray-300 object-cover"
          />
          <div className="flex flex-col ml-6 h-full justify-between">
            <p className="font-semibold text-gray-900">
              {orderData?.product?.name}
            </p>
            <p className="text-sm text-gray-600">
              Order No: {orderData?.orderNo}
            </p>
            <p className="text-sm text-gray-600">
              Stock Code: {orderData?.product?.stockCode}
            </p>
          </div>
          <div className="flex flex-col ml-8 mt-[27px]">
            <p
              data-tooltip-id={"qty"}
              data-tooltip-content={
                "Önceki operasyondan tamamlanmış olarak gelen ürün sayısı"
              }
              className="text-sm text-gray-600 flex items-center gap-1"
            >
              <ArrowRight />
              Önceki Operasyondan Gelen :{" "}
              <p className="font-semibold">{operations?.previousPiece}</p>
            </p>
            <p
              data-tooltip-id={"quality"}
              data-tooltip-content={
                "Bu operasyonda tamamlanmış ürün sayısı  / Tamamlanması gereken ürün sayısı"
              }
              className="text-sm text-gray-600 flex items-center gap-1 mt-1"
            >
              <QtyIcon />
              <p className="font-semibold">
                {operations?.orderData?.finishedPiece}
              </p>
              yapılması gereken adet /{" "}
              <p className="font-semibold">{operations?.piece}</p>
              yapılmış adet
            </p>
          </div>
          <div className="flex flex-col ml-8 mt-[27px]">
            <p
              data-tooltip-id={"time"}
              data-tooltip-content={
                "Bu operasyonda ki bir ürünün tamamlanma süresi (Tahmini)"
              }
              className="text-sm text-gray-600 flex items-center gap-1"
            >
              <ClockIcon />
              Tahmini süre
              <p className="font-semibold">
                {formatSeconds(operations?.internalOperation?.estimatedTime)}
              </p>
            </p>

            <p
              data-tooltip-id={"qualityControl"}
              data-tooltip-content={
                "Kalite kontrolünden geçen ürün sayısı / Kalite kontrolünden geçemeyen ürün sayısı"
              }
              className="text-sm text-gray-600 flex items-center gap-1 mt-1"
            >
              <Quality />
              Kalite Onay / Red :
              <p className="text-[#079455] font-semibold">
                {operations?.qualityAcceptedPiece}
              </p>
              ad
              <p className="text-[#D92D20] font-semibold">
                {operations?.qualityRejectedPiece}
              </p>
              ad
            </p>
          </div>
          <div className="w-[196px] absolute gap-x-3 -mt-20  flex items-center right-[130px]">
            <Button
              size={"sm"}
              colorType={"secondary-gray"}
              label={"Create a Work"}
              iconLeft={<PlusIcon />}
              onClick={() => {
                if (currentOperation?.internalOperation == null) {
                  setNewPlannedJunctionExternalModal(true);
                } else {
                  setNewPlannedJunctionModal(true);
                }
              }}
            />
            {currentOperation?.status !== "finished" && (
              <Button
                size={"sm"}
                label={"Finish this Operation"}
                colorType={"secondary-error"}
                onClick={() => setIsOpenFinishOperation(true)}
              />
            )}
          </div>
        </div>
        <div className="w-full h-px bg-[#EAECF0] my-6" />
        <div className="flex flex-col gap-y-7 w-full">
          <div className="flex justify-between h-9 w-full items-center">
            <div className="flex">
              <Button
                colorType={"secondary-gray"}
                iconLeft={<ArrowLeftIcon />}
                label={t("buttons:previous")}
                size={"sm"}
                onClick={handlePrevStep}
                disabled={currentOperation?.step === 1}
              />
            </div>
            <div className="flex gap-x-2">
              <span className="text-[#475467] text-md font-normal">
                {(currentOperation.step || 0) + 1}.Operation
              </span>
              <span className="text-[#101828] text-md font-semibold">
                {currentOperation.internalOperation
                  ? currentOperation?.internalOperation?.process?.name
                  : currentOperation?.externalOperation?.process?.name}
              </span>
            </div>
            <div className="flex">
              <Button
                colorType={"secondary-gray"}
                iconLeft={<ArrowRightIcon />}
                label={t("buttons:next")}
                size={"sm"}
                onClick={handleNextStep}
                disabled={
                  currentOperation?.step + 1 === filledOperations?.length ||
                  filledOperations[nextStep] === null
                }
              />
            </div>
          </div>
        </div>

        <div className="flex-1 w-full flex mt-6 overflow-hidden gap-x-3">
          <div className="w-1/3 h-full flex flex-col">
            <div className="w-full border-b bg-white border-gray-200 pb-1 flex items-center gap-3">
              <p className="text-sm text-secondary-900 font-semibold">
                {t("product:activeAndPlanned")}
              </p>
              <Badgets
                colorType={"fill-warning"}
                size={"sm"}
                label={activeJunctions?.length}
              />
            </div>

            <div className="w-full flex-1 overflow-y-auto flex flex-col pt-3 scrollbar-hide ">
              {activeJunctions
                ?.sort(
                  (b, a) =>
                    moment(a?.startDate ? a.startDate : 0).unix() -
                    moment(b?.startDate ? b?.startDate : 0).unix()
                )

                .map((item, index) => {
                  const startDate = moment(item?.startDate);
                  const endDate = new Date();

                  if (item?.status === "active") {
                    if (item?.supplier) {
                      return (
                        <div className="w-full mb-3" key={index}>
                          <SingleJunctionCard
                            id={item?.id}
                            props={{
                              ring: "#17B26A",
                              status: item?.status,
                              leftImage: item?.supplier?.image,
                              texts: {
                                first: item?.job?.orderNo,
                                second: item?.supplier?.name,

                                // time:
                                //   moment(endDate).diff(startDate, "minutes") +
                                //   "dk",

                                piece: String(item?.metadata?.madeWorkCount),
                              },
                              user: {
                                name: item?.user?.name,
                                lastName: item?.user?.lastName,
                                avatar: item?.user?.avatar,
                              },
                              onClick: () => {
                                setJunctionType(
                                  item?.externalOperation
                                    ? "external"
                                    : "internal"
                                );
                                setFinishJunctionId(item?.id);
                                setIsOpenJunctionPiece(true);
                              },
                            }}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="w-full mb-3" key={index}>
                          <SingleJunctionCard
                            props={{
                              ring: "#17B26A",
                              status: item?.status,
                              leftImage: item?.station?.metadata?.image,
                              texts: {
                                first: item?.job?.orderNo,
                                second:
                                  item?.station?.metadata?.brand +
                                  " " +
                                  item?.station?.metadata?.model,

                                time:
                                  moment(endDate).diff(startDate, "minutes") +
                                  "dk",

                                piece: String(item?.metadata?.madeWorkCount),
                              },
                              user: {
                                name: item?.user?.name,
                                lastName: item?.user?.lastName,
                                avatar: item?.user?.avatar,
                              },
                              onClick: () => {
                                setJunctionType(
                                  item?.externalOperation
                                    ? "external"
                                    : "internal"
                                );
                                setFinishJunctionId(item?.id);
                                setIsOpenJunctionPiece(true);
                              },
                            }}
                          />
                        </div>
                      );
                    }
                  } else {
                    if (item?.station) {
                      return (
                        <div
                          className="w-full mb-3 relative group "
                          key={index}
                        >
                          <SingleJunctionCard
                            props={{
                              status: item?.status,
                              leftImage: item?.station?.metadata?.image,
                              texts: {
                                second: item?.station?.metadata?.model,
                                first: item?.station?.metadata?.brand,

                                piece: item?.job?.piece,
                                time: secondToDay(
                                  item?.header?.totalEstimatedTime
                                ),
                              },
                              rightButton: () => {
                                setIsSelectUserModal(true);
                                setSelectedJunction(item);
                              },
                            }}
                          />
                          <button
                            onClick={async () => {
                              await junctionService
                                .deleteStationForJunction(item?.id)
                                .then((res) => {
                                  if (res.data.code == 0) {
                                    Toast("success", "Junction deleted");
                                    refetch();
                                  } else {
                                    Toast("error", "Junction not deleted");
                                  }
                                });
                            }}
                            type="button"
                            className="w-8 h-8 rounded-lg bg-white border border-[#FDA29B] hidden group-hover:flex items-center justify-center absolute -top-2 right-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
                                stroke="#B42318"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    } else {
                      if (item?.externalOperation) {
                        if (item?.supplier) {
                          return (
                            <div className="w-full mb-3" key={index}>
                              <SingleJunctionCard
                                props={{
                                  status: item?.status,
                                  leftImage: item?.supplier?.image,
                                  texts: {
                                    first: item?.job?.orderNo,
                                    second: item?.supplier?.name,
                                    piece: item?.job?.piece,
                                    time:
                                      moment
                                        .duration(
                                          item?.header?.totalEstimatedTime,
                                          "seconds"
                                        )
                                        .asMinutes()
                                        .toFixed(2) + "dk",
                                  },
                                  rightButton: () => {
                                    setIsSelectUserModal(true);
                                    setSelectedJunction(item);
                                  },
                                }}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div className="w-full mb-3 " key={index}>
                              <SingleJunctionCard
                                props={{
                                  status: item?.status,
                                  leftImage: item?.job?.product?.image,
                                  texts: {
                                    first: item?.job?.orderNo,
                                    second: item?.job?.product?.name,
                                    piece: item?.job?.piece,
                                    time:
                                      moment
                                        .duration(
                                          item?.header?.totalEstimatedTime,
                                          "seconds"
                                        )
                                        .asMinutes()
                                        .toFixed(2) + "dk",
                                  },
                                  rightButton: () => {
                                    setIsSelectSupplierModal(true);
                                    setSelectedJunction(item);
                                  },
                                }}
                              />
                            </div>
                          );
                        }
                      } else {
                        return (
                          <div className="w-full mb-3" key={index}>
                            <SingleJunctionCard
                              props={{
                                status: item?.status,
                                leftImage: item?.job?.product?.image,
                                texts: {
                                  first: item?.job?.orderNo,
                                  second: item?.job?.product?.name,
                                  piece: item?.job?.piece,

                                  time: secondToDay(
                                    item?.header?.totalEstimatedTime
                                  ),
                                },
                                rightButton: () => {
                                  setIsSelectNodeModal(true);
                                  setSelectedJunction(item);
                                },
                              }}
                            />
                          </div>
                        );
                      }
                    }
                  }
                })}
            </div>
          </div>
          <div className="h-full w-px bg-gray-200 " />
          <div className="w-1/3 h-full flex flex-col">
            <div className="w-full border-b bg-white border-gray-200 pb-1 flex items-center gap-3">
              <p className="text-sm text-secondary-900 font-semibold">
                {t("product:completedWorks")}
              </p>
              <Badgets
                colorType={"fill-warning"}
                size={"sm"}
                label={completedWorks?.length}
              />
            </div>
            <div className="w-full flex-1 overflow-y-auto flex flex-col pt-3 scrollbar-hide ">
              {completedWorks?.map((item, index) => {
                const startDate = moment(item?.startDate);
                const endDate = moment(item?.endDate);
                if (item?.externalOperation) {
                  return (
                    <div className="w-full mb-3 " key={index}>
                      <SingleJunctionCard
                        id={item?.id}
                        quality={item?.metadata?.qualityStatus}
                        props={{
                          status: "active",
                          leftImage: item?.supplier?.image,
                          texts: {
                            first: item?.supplier?.name,
                            second: item?.externalOperation?.name,
                            time: formatSeconds(
                              moment(endDate).diff(startDate, "seconds")
                            ),

                            piece: String(item?.metadata?.madeWorkCount),
                          },
                          user: {
                            name: item?.user?.name,
                            lastName: item?.user?.lastName,
                            avatar: item?.user?.avatar,
                          },
                          onClick: () => {
                            setJunctionType(
                              item?.externalOperation ? "external" : "internal"
                            );
                          },
                        }}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="w-full mb-3 " key={index}>
                      <SingleJunctionCard
                        quality={item?.metadata?.qualityStatus}
                        id={item?.id}
                        props={{
                          status: "active",
                          leftImage: item?.station?.metadata?.image,
                          texts: {
                            first:
                              item?.station?.metadata?.brand +
                              " " +
                              item?.station?.metadata?.model,
                            second: item?.internalOperation?.name,
                            time: secondToHoursAndMinute(
                              moment(endDate).diff(startDate, "seconds")
                            ),

                            piece: String(item?.metadata?.madeWorkCount),
                          },
                          user: {
                            name: item?.user?.name,
                            lastName: item?.user?.lastName,
                            avatar: item?.user?.avatar,
                          },
                          onClick: () => {
                            setJunctionType(
                              item?.externalOperation ? "external" : "internal"
                            );
                          },
                        }}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="h-full w-px bg-gray-200 " />
          <div className="w-1/3 h-full flex flex-col">
            <div className="w-full border-b bg-white border-gray-200 pb-1 flex items-center gap-3">
              <p className="text-sm text-secondary-900 font-semibold">
                {t("product:completedAndApproved")}
              </p>
              <Badgets
                colorType={"fill-warning"}
                size={"sm"}
                label={completedAndApprovedWorks?.length}
              />
            </div>
            <div className="w-full flex-1 overflow-y-auto flex flex-col pt-3 scrollbar-hide ">
              {completedAndApprovedWorks?.map((item, index) => {
                const startDate = moment(item?.startDate);
                const endDate = moment(item?.endDate);
                if (item?.externalOperation) {
                  return (
                    <div className="w-full mb-3" key={index}>
                      <SingleJunctionCard
                        quality={item?.metadata?.qualityStatus}
                        props={{
                          status: item?.status,
                          leftImage: item?.supplier?.image,
                          texts: {
                            first: item?.job?.orderNo,
                            second: item?.supplier?.name,
                            qualityAcceptedPiece:
                              item?.metadata?.acceptedWorkCount,
                            qualityRejectedPiece:
                              item?.metadata?.rejectedWorkCount,

                            estimatedTime: secondToHoursAndMinute(
                              moment(endDate)?.diff(startDate, "seconds")
                            ),
                          },
                          user: {
                            name: item?.user?.name,
                            lastName: item?.user?.lastName,
                            avatar: item?.user?.avatar,
                          },
                          onClick: () => {
                            setJunctionDetailOpen(true);
                            setJunctionId(item?.id);
                          },
                        }}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="w-full mb-3" key={index}>
                      <SingleJunctionCard
                        quality={item?.metadata?.qualityStatus}
                        props={{
                          status: item?.status,
                          leftImage: item?.station?.metadata?.image,
                          texts: {
                            first:
                              item?.station?.metadata?.brand +
                              " " +
                              item?.station?.metadata?.model,

                            qualityAcceptedPiece:
                              item?.metadata?.acceptedWorkCount,
                            qualityRejectedPiece:
                              item?.metadata?.rejectedWorkCount,
                            point: item?.metadata?.upuPoint,
                            estimatedTime: formatSeconds(
                              (
                                moment(endDate).diff(startDate, "seconds") /
                                item.metadata.madeWorkCount
                              ).toFixed(0)
                            ),
                            totalTime: secondToHoursAndMinute(
                              moment(endDate).diff(startDate, "seconds")
                            ),
                            estimatedTrue:
                              (
                                moment(endDate).diff(startDate, "seconds") /
                                item.metadata.madeWorkCount
                              ).toFixed(0) <
                              operations?.internalOperation?.estimatedTime
                                ? true
                                : false,
                          },
                          user: {
                            name: item?.user?.name,
                            lastName: item?.user?.lastName,
                            avatar: item?.user?.avatar,
                          },
                          onClick: () => {
                            setJunctionDetailOpen(true);
                            setJunctionId(item?.id);
                          },
                        }}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={junctionDetailOpen}
        setIsOpen={setJunctionDetailOpen}
        onClose={() => {
          setJunctionDetailOpen(false);
        }}
        modalTitle={t("product:transactionDetail")}
        width="fit-content"
        children={
          <TransactionDetailModal
            t={t}
            setIsOpen={setJunctionDetailOpen}
            isOpen={junctionDetailOpen}
            id={junctionId}
          />
        }
      />

      <CustomModal
        isOpen={isOpenFinishOperation}
        setIsOpen={setIsOpenFinishOperation}
        onClose={() => {
          setIsOpenFinishOperation(false);
        }}
        modalTitle={t("product:finishThisOperation")}
        width="fit-content"
        children={
          <FinishOperationSelectNode
            refetch={refetch}
            orderData={orderData}
            currentOperation={currentOperation}
            onClose={() => {
              setIsOpenFinishOperation(false);
            }}
            processId={
              currentOperation?.internalOperation
                ? currentOperation?.internalOperation?.process?.id
                : currentOperation?.externalOperation?.process?.id
            }
          />
        }
      />
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />

      <CustomModal
        isOpen={isSelectSupplierModal}
        setIsOpen={setIsSelectSupplierModal}
        onClose={() => {
          setIsSelectSupplierModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectSupplierModal
            t={t}
            setIsOpen={setIsSelectSupplierModal}
            isOpen={isSelectSupplierModal}
            selectedJunction={selectedJunction}
            currentOperation={currentOperation}
            handleData={refetch}
            processId={
              currentOperation?.internalOperation
                ? currentOperation?.internalOperation?.process?.id
                : currentOperation?.externalOperation?.process?.id
            }
          />
        }
      />

      <CustomModal
        modalTitle={t("product:createNewWork")}
        setIsOpen={setNewPlannedJunctionModal}
        isOpen={newPlannedJunctionModal}
        children={
          <NewPlannedJunctionModal
            operationData={currentOperation}
            getJunctions={refetch}
            closeModal={() => setNewPlannedJunctionModal(false)}
            processId={currentOperation?.internalOperation?.process?.id}
          />
        }
      />

      <CustomModal
        modalTitle={t("product:createNewWork")}
        setIsOpen={setNewPlannedJunctionExternalModal}
        isOpen={newPlannedJunctionExternalModal}
        children={
          <NewPlannedJunctionExternalModal
            operationData={currentOperation}
            getJunctions={refetch}
            closeModal={() => setNewPlannedJunctionExternalModal(false)}
            processId={currentOperation?.externalOperation?.process?.id}
          />
        }
      />

      <CustomModal
        isOpen={isSelectUserModal}
        setIsOpen={setIsSelectUserModal}
        onClose={() => {
          setIsSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsSelectUserModal}
            isOpen={isSelectUserModal}
            selectedJunction={selectedJunction}
            currentOperation={currentOperation}
            handleData={refetch}
          />
        }
      />

      <CustomModal
        isOpen={isSelectNodeModal}
        setIsOpen={setIsSelectNodeModal}
        onClose={() => {
          setIsSelectNodeModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectEquModal
            t={t}
            setIsOpen={setIsSelectNodeModal}
            isOpen={isSelectNodeModal}
            selectedJunction={selectedJunction}
            currentOperation={currentOperation}
            handleData={refetch}
            processId={
              currentOperation?.internalOperation
                ? currentOperation?.internalOperation?.process?.id
                : currentOperation?.externalOperation?.process?.id
            }
          />
        }
      />

      <AlertModal
        isOpen={finishOperationAlert}
        setIsOpen={setFinishOperationAlert}
        title={t("product:finishOperation")}
        subTitle={t("product:finishOperationTitle")}
        label={t("buttons:finish")}
        applyButton={() => {
          finishOperation();
          setFinishOperationAlert(false);
        }}
      />

      {tooltips.map((tooltip) => (
        <Tooltip id={tooltip.id} />
      ))}
    </>
  );
};

export default OperationDetailModal;

const Quality = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.5 5.5L6 7L11 2M10.5 6V9.5C10.5 9.76522 10.3946 10.0196 10.2071 10.2071C10.0196 10.3946 9.76522 10.5 9.5 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H8"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_9713_16623)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9713_16623">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QtyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#475467"
      />
    </svg>
  );
};

const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 8L8 6M8 6L6 4M8 6H4M3.9 10.5H8.1C8.94008 10.5 9.36012 10.5 9.68099 10.3365C9.96323 10.1927 10.1927 9.96323 10.3365 9.68099C10.5 9.36012 10.5 8.94008 10.5 8.1V3.9C10.5 3.05992 10.5 2.63988 10.3365 2.31901C10.1927 2.03677 9.96323 1.8073 9.68099 1.66349C9.36012 1.5 8.94008 1.5 8.1 1.5H3.9C3.05992 1.5 2.63988 1.5 2.31901 1.66349C2.03677 1.8073 1.8073 2.03677 1.66349 2.31901C1.5 2.63988 1.5 3.05992 1.5 3.9V8.1C1.5 8.94008 1.5 9.36012 1.66349 9.68099C1.8073 9.96323 2.03677 10.1927 2.31901 10.3365C2.63988 10.5 3.05992 10.5 3.9 10.5Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
