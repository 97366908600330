import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { junctionService } from "../../services/junction.service";
import generateFileUrl from "../../utils/generateFileUrl";
import moment from "moment";
import NoAvatar from "../avatar/NoAvatar";
import Input from "../inputs/Input";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import UploadProfile from "../ImageUploader/UploadProfile";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../buttons/Button";
import { Toast } from "../../utils/toastify/toast";
import { orderService } from "../../services/order.service";
import { useTranslation } from "react-i18next";
import TextArea from "../inputs/TextArea";
import HourTimeInput from "../inputs/HourTimeInput";
import { Tooltip } from "react-tooltip";
import Loading from "../loading/Loading";
import CustomModal from "./CustomModal";
import JunctionUpdate from "./JunctionUpdate";
import SplitOrderModal from "../../pages/product/modals/SplitOrderModal";
import { useStore } from "../../hooks/useStores";
import Selector from "../inputs/Selector";
import DateTimeInput from "../inputs/DateTimeInput";

const QualityControlForm = ({ junctionId, onClose, refetch, junctionData }) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [loading, setLoading] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(
    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss")
  );

  const [endDate, setEndDate] = useState(
    moment(data?.endDate).format("YYYY-MM-DDTHH:mm:ss")
  );
  useState(false);
  const handleData = async () => {
    setLoading(true);
    const data = await junctionService
      .getQualityJunctionDetail(junctionId)
      .catch((err) => Toast("error", String(err)));
    setData(data);
    setLoading(false);
  };
  const dayStartHour = moment(
    auth?.user?.company?.dayStartHour,
    "HH:mm:ss"
  ).format("HH:mm:ss");
  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];
  const schema = yup.object({
    qualityImage: yup.string().notRequired(),
    acceptedImage: yup.string().notRequired(),
    rejectedImage: yup.string().notRequired(),
    qualityDescription: yup.string().notRequired(),
    rejectedPiece: yup.number(),
    workPiece: yup.number(),
    acceptedPiece: yup.number(),
    type: yup.string().required(`${t("validation:empty")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rejectedPiece: 0,
    },
  });

  const onSubmit = async (value) => {
    Toast("warning", "Demo user cannot update data");
    onClose();
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    setValue("qualityImage", data?.metadata?.qualityImage);
    setValue("rejectedImage", data?.metadata?.rejectedImage);
    setValue("acceptedImage", data?.metadata?.acceptedImage);
    setValue("qualityDescription", data?.metadata?.qualityDescription);
    setValue("rejectedPiece", data?.metadata?.rejectedWorkCount);
    setValue("acceptedPiece", data?.metadata?.acceptedWorkCount);
    setValue("workPiece", data?.madeWorkCount);
    setValue("type", data?.type);
    setEndDate(data?.endDate);
    setStartDate(data?.startDate);
  }, [data]);
  return (
    <>
      {loading ? (
        <div className="flex items-center w-[770px] justify-center  h-[500px]">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[786px] flex flex-col mt-6"
        >
          <div className="w-full flex gap-6 h-fit">
            <div className="w-[60%] flex flex-col gap-4">
              <div className="w-full flex items-center h-[72px] gap-3">
                <img
                  src={generateFileUrl(data?.job?.product?.image)}
                  className="w-[96px] h-[72px] rounded border border-gray-300 blur-sm"
                />
                <div
                  data-tooltip-id={`${data?.id}`}
                  data-tooltip-place={"top"}
                  data-tooltip-content={`${t("product:productName")}: ${
                    data?.job?.product?.name.slice(0, 3)+"***"}
                  } `}
                  className="flex flex-col justify-between"
                >
                  <p className="font-semibold text-gray-900 w-[250px] truncate">
                    {data?.job?.product?.name.slice(0, 3)+"***"}
                  </p>
                  <p className="text-sm text-gray-600">
                    {t("product:orderNo")}: {data?.job?.orderNo.slice(0, 3)+"***"}
                  </p>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                      <QtyIcon />
                      <p className="text-sm text-gray-600">
                        {data?.metadata?.madeWorkCount} / {data?.job?.piece}
                      </p>
                    </div>
                    <div className="flex items-center gap-1">
                      <ClockIcon />
                      <p className="text-sm text-gray-600">
                        {moment(data?.endDate)
                          .diff(data?.startDate, "minutes")
                          .toFixed(1) + "dk"}
                      </p>
                    </div>

                    <Tooltip id={`${data?.id}`} />
                  </div>
                  <div
                    data-tooltip-id={`${data?.id}`}
                    data-tooltip-place={"top"}
                    data-tooltip-content={"Bitiş tarihi ve saati"}
                    className="flex items-center gap-1"
                  >
                    <CalendarIcon />
                    <p className="text-sm text-gray-600">
                      {moment(data?.endDate).format("DD-MM-YYYY HH:mm")}
                    </p>
                  </div>
                </div>
              </div>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Selector
                    value={value}
                    items={junctionTypes}
                    theme={"product"}
                    label={t("product:junctionType")}
                    onChange={onChange}
                    onBlur={onBlur}
                    validate={errors?.source ? "error" : ""}
                    errorMessage={errors?.source ? errors?.source?.message : ""}
                  />
                )}
                name="type"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    label={t("product:numberOfProducts")}
                    errorMessage={errors?.workPiece?.message}
                    validate={errors?.workPiece ? "error" : ""}
                  />
                )}
                name="workPiece"
              />
              <div className="flex flex-row items-center gap-x-3 w-full">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      theme={"product"}
                      label={t("product:approvedProductCount")}
                      errorMessage={errors?.acceptedPiece?.message}
                      validate={errors?.acceptedPiece ? "error" : ""}
                    />
                  )}
                  name="acceptedPiece"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      theme={"product"}
                      label={t("product:numberOfRejected")}
                      errorMessage={errors?.rejectedPiece?.message}
                      validate={errors?.rejectedPiece ? "error" : ""}
                    />
                  )}
                  name="rejectedPiece"
                />
              </div>
              <div className="flex flex-row items-center gap-x-3 w-full">
                {/* <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <HourTimeInput
                      label={t("product:jobStartTime")}
                      validate={errors.startDate ? "error" : ""}
                      onChange={(v) => {
                        setValue(
                          `startDate`,
                          moment(data?.startDate)
                            .set("hour", v.split(":")[0])
                            .set("minute", v.split(":")[1])
                            .format("YYYY-MM-DDTHH:mm:ss")
                        );
                      }}
                      // minHour={dayStartHour}
                      theme={"product"}
                      value={moment(value).format("HH:mm")}
                      errorMessage={
                        errors.startDate ? errors.startDate.message : ""
                      }
                    />
                  )}
                  name="startDate"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <HourTimeInput
                      theme={"product"}
                      label={t("product:jobEndTime")}
                      onChange={(v) => {
                        setValue(
                          `endDate`,
                          moment(data?.endDate)
                            .set("hour", v.split(":")[0])
                            .set("minute", v.split(":")[1])
                            .format("YYYY-MM-DDTHH:mm:ss")
                        );
                      }}
                      value={moment(value).format("HH:mm")}
                      validate={errors.endDate ? "error" : ""}
                      errorMessage={
                        errors.endDate ? errors.endDate.message : ""
                      }
                    />
                  )}
                  name="endDate"
                /> */}
                <DateTimeInput
                  minDate={
                    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") <
                    moment(data?.startDate).format("YYYY-MM-DD") +
                      "T" +
                      dayStartHour
                      ? moment(data?.startDate)
                          .add(-1, "day")
                          .format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                      : moment(data?.startDate).format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                  }
                  maxDate={
                    moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") +
                    "T" +
                    dayStartHour
                  }
                  label={t("product:jobStartDate")}
                  theme={"product"}
                  initialValue={moment(data?.startDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )}
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                />
                <DateTimeInput
                  label={t("product:jobEndDate")}
                  theme={"product"}
                  minDate={
                    moment(data?.startDate).format("YYYY-MM-DDTHH:mm:ss") <
                    moment(data?.startDate).format("YYYY-MM-DD") +
                      "T" +
                      dayStartHour
                      ? moment(data?.startDate)
                          .add(-1, "day")
                          .format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                      : moment(data?.startDate).format("YYYY-MM-DD") +
                        "T" +
                        dayStartHour
                  }
                  maxDate={
                    moment(data?.startDate).add(1, "day").format("YYYY-MM-DD") +
                    "T" +
                    dayStartHour
                  }
                  initialValue={moment(data?.endDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )}
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                />
              </div>
            </div>
            <div className="w-px border-l flex-1 bg-gray-300" />
            <div className="w-[40%] flex flex-col gap-3">
              <div className="w-full h-[48px] flex items-center">
                {data?.user?.avatar ? (
                  <img
                    src={generateFileUrl(data?.user?.avatar)}
                    className="w-12 min-w-[48px] h-12 rounded-full border border-gray-300"
                  />
                ) : (
                  <NoAvatar
                    name={data?.user?.name}
                    lastName={data?.user?.lastName}
                    size={48}
                  />
                )}
                <p className="text-secondary-700 font-semibold w-[80%] truncate ml-3">
                  {data?.user?.name + " " + data?.user?.lastName}
                </p>
              </div>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile
                    title={t("product:controlForm")}
                    subTitle={"Remove Picture"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
                name="qualityImage"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile
                    title={t("product:approvalForm")}
                    subTitle={"Remove Picture"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
                name="acceptedImage"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile
                    title={t("product:rejectionForm")}
                    subTitle={"Remove Picture"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
                name="rejectedImage"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextArea
                    label={t("product:descriptionOptionel")}
                    width={"100%"}
                    onBlur={onBlur}
                    onChange={onChange}
                    theme={"product"}
                    rows={3}
                    value={value}
                  />
                )}
                name="qualityDescription"
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-center">
            {/* {junctionData?.metadata?.qualityStatus === "waiting" && ( */}
            <div
              onClick={(e) => {
               Toast("warning", "Demo user cannot update data");
              }}
              className="mt-10 font-semibold cursor-pointer text-[#B54708] text-md"
            >
              {t("product:splitJob")}
            </div>
            {/* )} */}

            <div className="w-[50%] flex items-center ml-auto justify-end gap-3 mt-8">
              <Button
                label={t("buttons:stop")}
                colorType={"secondary-gray"}
                size={"md"}
                onClick={onClose}
              />
              <Button
                type={"submit"}
                label={t("product:sendQualityForm")}
                colorType={"primary-product"}
                size={"md"}
              />
            </div>
          </div>
        </form>
      )}
      <CustomModal
        isOpen={isSplit}
        setIsOpen={setIsSplit}
        onClose={() => {
          setIsSplit(false);
        }}
        modalTitle={t("product:splitJob")}
        children={
          <SplitOrderModal
            handleData={refetch}
            setIsOpen={setIsSplit}
            closeModal={() => {
              setIsSplit(false);
            }}
            id={junctionId}
            dataJunction={junctionData}
          />
        }
      />
    </>
  );
};

export default QualityControlForm;

const QtyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#475467"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_9923_13289)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#475467"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9923_13289">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.5 5H1.5M8 1V3M4 1V3M3.9 11H8.1C8.94008 11 9.36012 11 9.68099 10.8365C9.96323 10.6927 10.1927 10.4632 10.3365 10.181C10.5 9.86012 10.5 9.44008 10.5 8.6V4.4C10.5 3.55992 10.5 3.13988 10.3365 2.81901C10.1927 2.53677 9.96323 2.3073 9.68099 2.16349C9.36012 2 8.94008 2 8.1 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V8.6C1.5 9.44008 1.5 9.86012 1.66349 10.181C1.8073 10.4632 2.03677 10.6927 2.31901 10.8365C2.63988 11 3.05992 11 3.9 11Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
