import React, { useEffect, useRef, useState } from "react";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import Junction from "../../../components/junction/Junction";
import { useTranslation } from "react-i18next";
import FinishJunctionModal from "../../../components/modal/FinishJunctionModal";
import { CustomModal } from "../../../components";

const CompanyDetailsWorks = ({ isToday, junctions, refetch }) => {
  const menuRef = useRef();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);

  const [value, setValue] = useState("internal");
  const options = [
    { label: t("routes:addProcess"), value: "internal" },
    { label: t("routes:external"), value: "external" },
    { label: t("gauge:plannedJunction"), value: "planned" },
  ];

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="w-1/3 rounded-xl border border-gray-200 shadow flex flex-col">
        <div className="w-full h-[72px] min-h-[72px] flex items-center px-4 pl-6 border-b border-secondary-200">
          <Icon />
          <h1 className="text-lg font-semibold text-secondary-900 ml-2">
            {!isToday ? t("embedded:completedWorks") : t("embedded:works")}
          </h1>
          {isToday && (
            <div className="relative flex flex-col ml-auto" ref={menuRef}>
              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className={`pl-3 pr-2 py-2 flex items-center border border-gray-300 rounded-lg relative gap-2 hover:bg-gray-50 active:bg-white active:ring-2 ring-gray-200 ${
                  isOpen && "bg-gray-50"
                }`}
                type="button"
              >
                <p className="text-sm font-semibold text-secondary-700">
                  {options.find((a) => a.value === value).label}
                </p>
                <ChevronDown />
              </button>

              {isOpen && (
                <div className="absolute w-[190px] p-1 bg-white right-0 top-10 border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-[999]">
                  {options.map((item, index) => {
                    return (
                      <button
                        onClick={() => {
                          setValue(item.value);
                          setIsOpen(false);
                        }}
                        key={index}
                        type="button"
                        className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                      >
                        <p className="font-medium text-sm text-secondary-700">
                          {item.label}
                        </p>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex-1 flex flex-col w-full px-5 overflow-y-auto scrollbar-hide pt-5 gap-y-4">
          {value === "internal"
            ? junctions
                ?.filter((item) => item?.internalOperation || item?.station)
                ?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setFinishJunctionId(item?.id);
                        setIsOpenJunctionPiece(true);
                      }}
                      className="min-h-[80px] mb-2 cursor-pointer"
                    >
                      <Junction
                        step={item?.header?.step}
                        key={index}
                        props={{
                          isSetting: item?.type === "setup" ? true : false,
                          node: {
                            image: item?.station?.metadata?.image,
                            name:
                              item?.station?.metadata?.brand +
                              " " +
                              item?.station?.metadata?.model,
                            process: item?.internalOperation
                              ? item?.internalOperation?.name
                              : item?.externalOperation?.name,
                          },
                          product: {
                            name: item.job.product.name,
                            image: item.job.product.image,
                            code: item.job.product.code,
                            piece: item?.metadata?.madeWorkCount,
                            id: item.job.product.id,
                          },
                          user: {
                            image: item?.user.avatar,
                            id: item?.user.id,
                            name: item?.user.name,
                            lastName: item?.user.lastName,
                          },
                        }}
                      />
                    </div>
                  );
                })
            : value === "external"
            ? junctions
                ?.filter((item) => item?.supplier || item?.externalOperation)
                ?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setFinishJunctionId(item?.id);
                        setIsOpenJunctionPiece(true);
                      }}
                      className="min-h-[80px] mb-2 cursor-pointer"
                    >
                      <Junction
                        step={item?.header?.step}
                        key={index}
                        props={{
                          isSetting: item?.type === "setup" ? true : false,
                          node: {
                            image: item?.supplier?.image,
                            name: item?.supplier?.name,
                            process: item?.internalOperation
                              ? item?.internalOperation?.name
                              : item?.externalOperation?.name,
                          },
                          product: {
                            name: item.job.product.name,
                            image: item.job.product.image,
                            code: item.job.product.code,
                            piece: item?.metadata?.madeWorkCount,
                            id: item.job.product.id,
                          },
                          user: {
                            image: item?.user.avatar,
                            id: item?.user.id,
                            name: item?.user.name,
                            lastName: item?.user.lastName,
                          },
                        }}
                      />
                    </div>
                  );
                })
            : ""}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            modalTheme={"machine"}
            junctionId={finishJunctionId}
            type={junctions?.internalOperation ? "internal" : "external"}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </>
  );
};

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.5 8.96533C9.5 8.48805 9.5 8.24941 9.59974 8.11618C9.68666 8.00007 9.81971 7.92744 9.96438 7.9171C10.1304 7.90525 10.3311 8.03429 10.7326 8.29239L15.4532 11.3271C15.8016 11.551 15.9758 11.663 16.0359 11.8054C16.0885 11.9298 16.0885 12.0702 16.0359 12.1946C15.9758 12.337 15.8016 12.449 15.4532 12.6729L10.7326 15.7076C10.3311 15.9657 10.1304 16.0948 9.96438 16.0829C9.81971 16.0726 9.68666 15.9999 9.59974 15.8838C9.5 15.7506 9.5 15.512 9.5 15.0347V8.96533Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyDetailsWorks;
