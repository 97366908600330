import { useQuery } from "@tanstack/react-query";
import React, { memo, useState } from "react";
import { chatService } from "../../../services/chat.service";
import { authStore } from "../../../stores/auth.store";
import TaskFilterBar from "./TaskFilterBar";
import TaskCard from "./TaskCard";
import Badgets from "../../../components/buttons/Badgets";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import { Button } from "../../../components";
import { Loader } from "../../../assets/icons/stepsIcons";

const MyTaskList = () => {
  const [value, setValue] = useState("all");
  const { data, isLoading, refetch } = useQuery({
    retry: 0,
    queryKey: ["getAllMyTask"],
    queryFn: async () => await chatService.getAllMyTAsks(authStore.user.id),
  });

  const sectionListDataRender = (data) => {
    const lengths = (filter, item) => {
      switch (filter) {
        case "all":
          return item.totalLength;
        case "todo":
          return item.todoLength;
        case "done":
          return item.doneLength;
        case "approved":
          return item.approvedLength;
        case "expired":
          return item.expiredLength;
      }
    };
    const newData = data?.map((r) => {
      return {
        ...r,
        taskGroups: r?.taskGroups?.filter((i) => {
          if (lengths(value, i) > 0) {
            return {
              ...i,
            };
          }
        }),
      };
    });
    return newData;
  };

  return (
    <div className="w-full h-full flex flex-col">
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="#107569" currentFill="currentFill" />
        </div>
      ) : (
        <>
          <div className="mb-5 w-full">
            <TaskFilterBar
              value={value}
              setValue={setValue}
              pages={"myTask"}
              done={data?.doneLength}
              todo={data?.todoLength}
              total={data?.totalLength}
              expired={data?.expiredLength}
              approved={data?.approvedLength}
            />
          </div>

          <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide gap-y-3 pb-5">
            {sectionListDataRender(data.data).map((item, index) => {
              return (
                <ExpandedButton
                  key={index}
                  item={item}
                  value={value}
                  refetch={refetch}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(MyTaskList);

const ExpandedButton = memo(({ item, value, refetch }) => {
  const [expanded, setExpanded] = useState(false);
  if (item.taskGroups.length > 0)
    return (
      <div className="w-full flex flex-col mt-2">
        <div
          onClick={() => {
            setExpanded(!expanded);
          }}
          className="flex w-full items-center justify-between px-1 border-b border-secondary-200 pb-1"
        >
          <div className="flex items-center gap-2">
            <p className="text-base font-semibold text-secondary-700">
              {item.name}
            </p>
            <Badgets
              label={item.taskGroups.length}
              colorType={"fill-success"}
              size={"sm"}
            />
          </div>
          <div className={`w-9 ${expanded && "rotate-180"}`}>
            <Button
              colorType={"tertiary-gray"}
              size={"sm"}
              iconLeft={<ChevronDown />}
            />
          </div>
        </div>
        {expanded && (
          <div className="flex flex-col mt-2">
            {item?.taskGroups?.map((a, b) => {
              const lengths = () => {
                switch (value) {
                  case "all":
                    return a.totalLength;
                  case "todo":
                    return a.todoLength;
                  case "done":
                    return a.doneLength;
                  case "approved":
                    return a.approvedLength;
                  case "expired":
                    return a.expiredLength;
                  default:
                    return 1;
                }
              };
              if (lengths() > 0)
                return (
                  <MemorizedExpandedGroupMenu
                    key={b}
                    title={a?.title}
                    length={lengths()}
                    children={a.tasks?.map((task, index) => {
                      if (value === task.status) {
                        return (
                          <TaskCard
                            key={index}
                            index={index}
                            status={task?.status}
                            point={task?.upuPoint}
                            taskId={task?._id}
                            value={value}
                            date={task?.endDate}
                            creator={task?.creator}
                            isStar={task?.isStar}
                            task={task?.name}
                            number={task?.rowNumber}
                            groupDataId={a?.taskGroupId}
                            attachment={task?.attachment}
                            participants={task?.participants}
                            comments={task?.comments}
                            departmentLabels={item?.labels}
                            labels={task?.label}
                            groupConversationId={a?.taskGroupId}
                            refetch={refetch}
                          />
                        );
                      } else if (value === "all") {
                        return (
                          <TaskCard
                            key={index}
                            index={index}
                            status={task?.status}
                            point={task?.upuPoint}
                            taskId={task?._id}
                            date={task?.endDate}
                            creator={task?.creator}
                            isStar={task?.isStar}
                            groupDataId={a?.taskGroupId}
                            value={value}
                            task={task?.name}
                            number={task?.rowNumber}
                            attachment={task?.attachment}
                            participants={task?.participants}
                            comments={task?.comments}
                            departmentLabels={item?.labels}
                            labels={task?.label}
                            groupConversationId={a?.taskGroupId}
                            refetch={refetch}
                          />
                        );
                      }
                    })}
                  />
                );
            })}
          </div>
        )}
      </div>
    );
});

const MemorizedExpandedGroupMenu = memo(
  ({ title, image, children, length }) => {
    const [expanded, setExpanded] = useState(false);
    if (length > 0)
      return (
        <div className="flex flex-col">
          <div
            onClick={() => setExpanded(!expanded)}
            className="h-[52px] min-h-[52px] flex items-center pr-1"
          >
            {image ? (
              <img
                src={generateFileUrl(image)}
                className="w-8 h-8 rounded-full border border-secondary-200 object-cover"
              />
            ) : (
              <NoAvatar size={32} name={title} fontSize={16} />
            )}

            <p className="font-semibold text-secondary-700 ml-3">{title}</p>

            <div className={`w-9 ml-auto ${expanded && "rotate-180"}`}>
              <Button
                colorType={"tertiary-gray"}
                size={"sm"}
                iconLeft={<ChevronDown />}
              />
            </div>
          </div>
          {expanded && (
            <div className="flex flex-col mt-2 gap-3 mb-3">{children}</div>
          )}
        </div>
      );
  }
);
