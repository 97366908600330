import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DetailHeader from "./components/DetailHeader";
import { useQuery } from "@tanstack/react-query";
import { reportService } from "../../services/report.service";
import moment from "moment";
import DetailGaugeChart from "./Twin/chart/DetailGaugeChart";
import EquipmentDetailTable from "./Twin/components/EquipmentDetailTable";
import MonthlyChart from "./Twin/chart/MonthlyChart";
import DetailPageDateSelector from "./Twin/components/DetailPageDateSelector";
import WorkCountCard from "./components/WorkCountCard";
import { useTranslation } from "react-i18next";
import { Loading } from "./embeddedServer/svg/EmbeddedServerIcons";
import EquipmentTimeline from "./Twin/components/EquipmentTimeline";
import EquipmentJunctions from "./Twin/components/EquipmentJunctions";
import ExternalLoading from "../../components/loading/ExternalLoading";
import { timeFormatter } from "../../utils/timezoneFormatter";
import { authStore } from "../../stores/auth.store";
import EquipmentLosses from "./Twin/components/EquipmentLosses";
import { EquipmentDetailTourSteps } from "../../assets/TourSteps";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Button } from "../../components";
import EquipmentUpuTimeline from "../../components/upuTimeline/EquipmentUpuTimeline";
import { useStore } from "../../hooks/useStores";
import { useChannel, useEvent } from "@harelpls/use-pusher";
const EquipmentDetail = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { deviceId } = useParams();

  const urlData = useParams();
  let { dates } = useParams();
  // const newUrl = `/equipment-detail/${urlData.deviceId}/${date}`;

  const pattern = /^(.*?)-(.+)$/;
  const match = urlData.deviceId?.match(pattern);
  const [workCount, setWorkCount] = useState();
  const [gaugeData, setGaugeData] = useState([]);
  const [lossesData, setLossesData] = useState();
  const [barChartData, setBarChartData] = useState();
  const [junctionData, setJunctionData] = useState();
  const [isOpenQr, setIsOpenQr] = useState(false);
  const [energyUsageData, setEnergyUsageData] = useState();
  const [customLoading, setCustomLoading] = useState(false);
  const [dateChangeCount, setDateChangeCount] = useState(0);

  //New Timeline

  const [startTime, setStartTime] = useState(null);
  const [defaultStartTime, setDefaultStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultEndTime, setDefaultEndTime] = useState(null);
  const [selectedJunction, setSelectedJunction] = useState("");
  const [groupedDatas, setGroupedDatas] = useState([]);

  //values

  const [shiftValues, setShiftValues] = useState([]);
  const [timelineValues, setTimelineValues] = useState([]);

  const [legendSelected, setLegendSelected] = useState({
    Availability: true,
    Performance: true,
    Quality: true,
    kWh: true,
    kw: true,
    "kWh Cost": true,
  });

  // const urlDataDetect = async () => {
  //   if (urlData?.["*"]) {
  //     await setDate(urlData?.["*"]);
  //   } else {
  //     setDate(
  //       timeFormatter(moment(urlData?.date), authStore?.user?.company?.timeZone)
  //         .formatted
  //     );
  //   }
  // };

  // useEffect(() => {
  //   urlDataDetect();
  // }, [urlData]);

  const [date, setDate] = useState(
    timeFormatter(moment(), authStore?.user?.company?.timeZone).formatted
  );
  const [warning, setWarning] = useState(false);
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["daily"],
    queryFn: async () =>
      reportService.getDailyNodeData({
        nodeId: match[2],
        date: moment(
          timeFormatter(dates, authStore?.user?.company?.timeZone).formatted
        ).format("YYYY-MM-DD"),
      }),
  });

  const handleDateChange = (newDate) => {
    navigate(
      `/app/machine/digital-twin/equipment-details/${urlData.deviceId}/${newDate}`
    );
  };

  const PastJunctionData = async (event, onClickDate) => {
    setCustomLoading(true);
    setSelectedJunction(event?.id);
    let endDateToSend;
    if (event?.endDate) {
      endDateToSend = moment(event?.endDate).format("YYYY-MM-DD HH:mm:ss");
    } else {
      endDateToSend = moment(onClickDate).format("YYYY-MM-DD HH:mm:ss");
    }
    await reportService
      .getCustomDateReport({
        nodeId: event?.station?.id,
        id: data?._id,
        deviceId: data?.equipmentData?.deviceId,
        startDate: moment(event?.startDate).format("YYYY-MM-DD HH:mm:ss"),
        endDate: endDateToSend,
      })
      .then((res) => {
        setBarChartData(res?.data?.barChartData || []);
        setWorkCount(res?.data?.workCountData || []);
        setEnergyUsageData(
          {
            kwh: res?.data?.kwh,
            kwhCostT1: res?.data?.kwhCostForStandard,
            kwhCostForT3: res?.data?.kwhCostForThreeTime,
            cCount: res?.data?.cCount,
            kw: res?.data?.kw,
            kwhRatioByOperation: res?.data?.kwhRatioByOperation,
            kwhRatioByCompany: res?.data?.kwhRatioByCompany,
          } || []
        );
        setGaugeData(
          [
            { ...res.data.availability, type: "Availability" },
            { ...res.data.performance, type: "Performance" },
            { ...res.data.utilization, type: "Utilization" },
          ] || [{}, {}, {}]
        );
        setCustomLoading(false);
      });
    // setDataZoom({
    //   start: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(event?.startDate).unix()
    //   ),
    //   end: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(endDateToSend).unix()
    //   ),
    // });
    // setDataZoom({
    //   start: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(event?.startDate).unix()
    //   ),
    //   end: normalizeAndScaleTimestamp(
    //     defaultStart,
    //     defaultEnd,
    //     moment(endDateToSend).unix()
    //   ),
    // });
  };

  useEffect(() => {
    setWarning(data?.equipmentData?.warning);
    setWorkCount(data?.workCountData || []);
    setBarChartData(data?.barChartData || []);
    setJunctionData(data?.junctionData);
    setLossesData(data?.lossesData);
    setGaugeData(
      [
        { ...data?.availability, type: "Availability" },
        { ...data?.performance, type: "Performance" },
        { ...data?.utilization, type: "Utilization" },
      ] || [{}, {}, {}]
    );
    setEnergyUsageData(
      {
        kw: data?.kw,
        kwh: data?.kwh,
        cCount: data?.cCount,
        kwhCostT1: data?.kwhCostForStandard,
        kwhCostForT3: data?.kwhCostForThreeTime,
        kwhRatioByCompany: data?.kwhRatioByCompany,
        kwhRatioByOperation: data?.kwhRatioByOperation,
      } || []
    );
  }, [data]);

  useEffect(() => {
    setDate(dates);
  }, []);

  useEffect(() => {
    refetch();
  }, [dates]);

  const [stepIndexState, setStepIndexState] = useState(0);
  const [runState, setRunState] = useState(true);
  function handleJoyrideCallback(data) {
    // This callback is fired with every state change of the Joyride component.
    // The data parameter provides lots of useful information, which we can use in conditional
    const { action, index, type, status } = data;
    // states updated for illustrative purposes only:
    // Handle what to do when the tour is finished or skipped:
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunState(false);
      setStepIndexState(0);
      // Handle what to do when a Joyride button is clicked (eg 'Next', close, 'Back', etc), or if a Joyride button is clicked and the target for the next step doesn't exist.
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // if the current action is 'previous', set the stepIndex back one, otherwise progress +1:
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndexState(stepIndex);
    }
  }

  useEffect(() => {
    if (isOpenQr) {
      const timer = setTimeout(() => {
        setStepIndexState(1);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [isOpenQr, setStepIndexState]);

  useEffect(() => {
    if (stepIndexState === 4) {
      setIsOpenQr(false);
    } else if (stepIndexState === 1) {
      setIsOpenQr(true);
    }
  }, [stepIndexState, setIsOpenQr]);

  const getAllShiftDatas = async () => {
    await setGroupedDatas(
      data?.shiftlyData
        ? data?.shiftlyData
        : data?.shiftData
        ? data?.shiftData
        : []
    );
  };
  const companyID = auth?.user?.company?.id;
  const channel = useChannel(companyID);
  useEvent(channel, "update-node", (socketEvent) => {
    if (socketEvent?.node?.deviceId == match[1]) {
      setWarning(socketEvent?.status == 11 ? true : false);
    }
  });
  useEffect(() => {
    data &&
      setStartTime(
        moment(timeFormatter(data?.startDate)?.formatted)?.valueOf()
      );
    setDefaultStartTime(
      moment(timeFormatter(data?.startDate)?.formatted)?.valueOf()
    );
    setEndTime(
      moment(timeFormatter(data?.startDate)?.formatted)?.valueOf() + 86400000
    );
    setDefaultEndTime(
      moment(timeFormatter(data?.startDate)?.formatted)?.valueOf() + 86400000
    );

    getAllShiftDatas();
  }, [date, data]);

  useEffect(() => {
    // if (
    //   moment(date).format("YYYY-MM-DD") ===
    //   moment(new Date()).format("YYYY-MM-DD")
    // ) {
    // } else
    if (
      moment(date).format("YYYY-MM-DD") === moment(dates).format("YYYY-MM-DD")
    ) {
    } else {
      handleDateChange(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  useEffect(() => {
    setDateChangeCount((prevCount) => prevCount + 1);
  }, [dates]);

  console.log('data', data)

  return (
    <div className="w-full h-full flex flex-col mt-2 relative">
      <DetailHeader
        dateChangeCount={dateChangeCount}
        t={t}
        match={match}
        warning={warning}
        refetch={refetch}
        isLoading={isLoading || isFetching}
        setWarning={setWarning}
        junctionData={junctionData}
        equipmentData={data?.equipmentData}
        isWarning={data?.equipmentData?.warning}
        // name={data?.equipmentData?.equipmentName}
        name={data?.processId}
        process={data?.equipmentData?.processName}
        image={data?.equipmentData?.equipmentImage}
        setRunState={setRunState}
        setIsOpenQr={setIsOpenQr}
        isOpenQr={isOpenQr}
        setStepIndexState={setStepIndexState}
        runState={runState}
      />
      <div className="flex-1 mt-3 w-full flex flex-col overflow-y-auto scrollbar-hide pb-8 pt-2 overflow-x-hidden">
        <div className="flex w-full gap-3">
          {isLoading || isFetching ? (
            <div className="flex-1 bg-gray-200 animate-pulse rounded-lg" />
          ) : (
            <div className="flex-1 border rounded-lg shadow border-secondary-200 step-7">
              <MonthlyChart
                setDate={setDate}
                data={data?.monthlyChart}
                end={data?.monthlyChart?.endDate}
                start={data?.monthlyChart?.startDate}
                legendSelected={legendSelected}
                setLegendSelected={setLegendSelected}
              />
            </div>
          )}
          <div className="w-[472px] flex flex-col">
            {isLoading || isFetching ? (
              <div className="w-full h-[140px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
            ) : (
              <div className="flex items-center justify-between border rounded-lg border-secondary-200 shadow mb-3 ">
                {gaugeData?.map((item, index) => {
                  return (
                    <DetailGaugeChart
                      step={index + 1}
                      gaugeData={gaugeData}
                      key={index}
                      index={index}
                      name={t(`addProcess:${item?.type}`)}
                      value={item?.actual ? item?.actual : 0}
                      timeMass={item?.timeMass || 0}
                      kpi1={item?.average?.toFixed(2) || 0}
                      kpi2={item?.kpi || 0}
                      selectedProcess={data?.id}
                    />
                  );
                })}
              </div>
            )}
            <span className="step-11">
              <EquipmentDetailTable
                isLoading={isLoading || isFetching}
                energyData={energyUsageData}
              />
            </span>
          </div>
        </div>
        <DetailPageDateSelector
          dates={dates}
          date={date}
          setDate={setDate}
          isLoading={isLoading || isFetching}
          shiftSelecter={(r) => setDate(r)}
        />
        {isLoading || isFetching ? (
          <div className="w-full min-h-[270px] rounded-lg shadow border border-secondary-300 bg-gray-200 animate-pulse flex items-center justify-center">
            <Loading size={38} color={"#475467"} secondary={"#EAECF0"} />
          </div>
        ) : (
          <span className="step-12">
            <EquipmentUpuTimeline
              refetch={refetch}
              timelineChart={data?.timelineData}
              junctionData={junctionData}
              defaultStartTime={defaultStartTime}
              defaultEndTime={defaultEndTime}
              startTime={startTime}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              endTime={endTime}
              selectedJunction={selectedJunction}
              setSelectedJunction={setSelectedJunction}
              shiftValues={shiftValues}
              setShiftValues={setShiftValues}
              timelineValues={timelineValues}
              setTimelineValues={setTimelineValues}
              groupedDatas={groupedDatas}
              date={date}
            />
          </span>
        )}

        {isLoading || isFetching ? (
          <div className="w-full h-[52.81px] rounded-lg bg-gray-200 animate-pulse mt-4" />
        ) : (
          <span className="step-15">
            {workCount?.length > 0 && <WorkCountCard workCount={workCount} />}
          </span>
        )}
        <div className="flex flex-col w-full">
          {isLoading || isFetching ? (
            <div className="w-full h-[72px] rounded-lg bg-gray-200 animate-pulse " />
          ) : (
            <span className="step-16">
              <EquipmentJunctions
                data={data}
                junctionData={junctionData}
                date={date}
                refetch={refetch}
                defaultEndTime={defaultEndTime}
                defaultStartTime={defaultStartTime}
                PastJunctionData={PastJunctionData}
                selectedJunction={selectedJunction}
                setSelectedJunction={setSelectedJunction}
                activeLength={data?.activeJunctionLength}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
              />
            </span>
          )}
          <div className="w-full h-px bg-gray-300 mt-4" />
          {isLoading || isFetching ? (
            <div className="w-full h-[72px] rounded-lg bg-gray-200 animate-pulse " />
          ) : (
            <span className="step-17">
              <EquipmentLosses
                date={date}
                lossesData={lossesData}
                refetch={refetch}
              />
            </span>
          )}
        </div>
      </div>
      {!isLoading ||
        (isFetching && (
          <ExternalLoading first={isFetching} second={customLoading} />
        ))}
      {/* <ReactJoyride
        steps={EquipmentDetailTourSteps}
        continuous={true}
        disableOverlayClose={true}
        scrollToFirstStep={true}
        spotlightClicks={true}
        showProgress={true}
        stepIndex={stepIndexState}
        showSkipButton={true}
        hideCloseButton={true}
        run={runState}
        callback={handleJoyrideCallback}
        locale={{
          next: t("buttons:next"),
          back: t("buttons:back"),
          skip: t("buttons:skip"),
          last: t("buttons:last"),
          close: t("buttons:close"),
        }}
        styles={{
          buttonNext: {
            backgroundColor: "#7F56D9",
            borderRadius: 8,
            paddingRight: 14,
            paddingLeft: 14,
            paddingBottom: 10,
            paddingTop: 10,
          },
          tooltipTitle: {
            color: "#101828",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 600,
            fontFamily: "Inter",
          },
          tooltipContent: {
            color: "#667085",
          },
          options: {
            beaconSize: 36,
            overlayColor: "#000",
            primaryColor: "#6941C6",
            textColor: "#000",
            width: 410,
            zIndex: 1000,
          },
          buttonSkip: {
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: 600,
            fontStyle: "normal",
            color: "#475467",
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 10,
            paddingTop: 10,
          },
          buttonBack: {
            color: "#6941C6",
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Inter",
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 10,
            paddingTop: 10,
            marginRight: 12,
          },
          overlay: {
            // Arka plan stilleri
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Arka plan rengini burada değiştirebilirsiniz
          },
          spotlight: {
            // Işık halkası stilleri
          },
          tooltip: {
            // Tooltip stilleri
            backgroundColor: "white", // Tooltip arka plan rengini burada değiştirebilirsiniz
            borderRadius: 12,
            color: "#667085",
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: 400,
            fontStyle: "normal",
            padding: 24,
          },
          tooltipContent: {
            // Tooltip içeriği stilleri
            marginBottom: 32,
            padding: 0,
            marginTop: 4,
          },
          beacon: {
            // Rehber ışığı stilleri
          },
        }}
      /> */}
    </div>
  );
};

export default EquipmentDetail;
