import React from "react";
import Button from "../../../components/buttons/Button";
import {
  AlertIconNotify,
  AttendIconNotify,
  CalendarIcon,
  ClockIcon,
  EquipmentIconNotify,
  LossIconNotify,
  MessageIcon,
  NotifyIcons,
  NotifyPollIcon,
  SettingsIconNotify,
  TaskStatusUpdatedIcon,
  ViewIcon,
} from "../../../assets/icons/PersonIcons";
import moment from "moment";
import generateFileUrl from "../../../utils/generateFileUrl";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import { Link, NavLink } from "react-router-dom";
import { notificationService } from "../../../services/notification.service";

export default function NotificationContent({ t, data }) {
  let iconComponent;
  let view;
  let showButton = true;
  switch (data?.type) {
    case "new-task-message":
    case "task":
    case "new-task-group":
    case "new-task":
    case "delete-task":
    case "update-task-status":
    case "reject-task-status":
    case "add-comment":
    case "delete-comment":
    case "update-upu-point":
    case "add-system-comment":
      iconComponent = <TaskStatusUpdatedIcon />;
      view = `/app/person/task/${data?.forwardIds.groupId}`;
      break;
    case "poll":
    case "create-poll":
    case "result-poll":
      iconComponent = <NotifyPollIcon />;
      view = `/app/person/poll/${data?.forwardIds?.id}`;
      break;
    case "attend_permission":
    case "attend_permission_reply":
      iconComponent = <AttendIconNotify />;
      view = "/app/person/attend";
      showButton = false;
      break;
    case "send-message":
    case "delete-conversation":
    case "new-conversation":
      iconComponent = <MessageIcon />;
      view = "";
      showButton = false;
      break;
    case "order":
    case "junction":
    case "sensor_warning":
      iconComponent = <EquipmentIconNotify />;
      view = "";
      showButton = false;
      break;
    case "system":
    case "Connection":
      iconComponent = <SettingsIconNotify />;
      view = "";
      showButton = false;
      break;
    case "package_limit":
      iconComponent = <AlertIconNotify />;
      view = "";
      showButton = false;
      break;
    case "Announcement":
      iconComponent = <NotifyIcons />;
      view = "";
      showButton = false;
      break;
    case "losses":
      iconComponent = <LossIconNotify />;
      view = "";
      showButton = false;
      break;
    default:
      iconComponent = <NotifyIcons />;
      view = "/app/person/notify";
      showButton = false;
      break;
  }

  const readNotification = async (not) => {
    await notificationService
      .isReadNotification(not?._id)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="h-full flex flex-col gap-6 max-w-[375px] min-w-[375px] px-5 py-4">
        <div className="flex pl-1 pb-2 border-b border-secondary-200 gap-x-3 items-center min-h-[47px]">
          <div className="min-w-[32px] max-w-[32px] min-h-[32px] max-h-[32px] flex items-center justify-center">
            {iconComponent}
          </div>
          <span className="flex text-lg font-semibold text-secondary-900">
            {data?.title ? data?.title?.slice(0, 3) + " ***" : "---"}
          </span>
          {showButton ? (
            <Link to={view} className="flex ml-auto min-w-[124px]">
              <Button
                colorType={"primary-person"}
                size={"sm"}
                label={t("buttons:view")}
                iconLeft={<ViewIcon />}
                onClick={() => {
                  readNotification(data);
                }}
              />
            </Link>
          ) : null}
        </div>
        {data?.image ? (
          <img
            src={generateFileUrl(data?.image)}
            className="max-w-[144px] blur-sm min-w-[144px] max-h-[144px] min-h-[144px] rounded-lg border border-[#D0D5DD]"
          />
        ) : null}
        <div className="flex flex-col gap-2 text-md font-normal text-[#475467]">
          <span className="">
            {data?.message ? data?.message?.slice(0, 3) + " ***" : "---"}
          </span>
          <div className="flex w-full gap-x-3 items-center">
            <span className="flex gap-x-2 items-center">
              <CalendarIcon />
              {moment(data?.createdAt).format("DD.MM.YYYY")}
            </span>
            <span className="flex gap-x-2 items-center">
              <ClockIcon />
              {moment(data?.createdAt).format("HH:mm")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
