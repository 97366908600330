import React from "react";

const NoAvatar = ({
  size,
  rounded,
  theme,
  fontSize,
  name,
  lastName,
  color,
  minWidth = "64px",
  minHeight = "48px",
}) => {
  if (theme === "primary")
    return (
      <div
        style={{
          minWidth: size,
          minHeight: size,
          borderRadius: rounded ? rounded : "9999px",
          fontSize: fontSize ? fontSize : "17px",
        }}
        className={`border-2 border-primary-600 bg-primary-600 flex items-center justify-center font-medium text-white select-none`}
      >
        {lastName
          ? name?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()
          : name?.charAt(0).toUpperCase()}
      </div>
    );
  else if (theme === "product") {
    return (
      <div
        style={{
          minWidth: minWidth,
          minHeight: minHeight,
          borderRadius: rounded ? rounded : "9999px",
          fontSize: fontSize ? fontSize : "20px",
          backgroundColor: color ? color : "#B54708",
        }}
        title={name + " " + lastName}
        className={`flex items-center blur-sm justify-center font-medium text-white select-none shadow`}
      >
        {lastName
          ? name?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()
          : name?.charAt(0).toUpperCase()}
      </div>
    );
  } else
    return (
      <div
        style={{
          minWidth: size,
          minHeight: size,
          maxWidth: size,
          maxHeight: size,
          borderRadius: rounded ? rounded : "9999px",
          fontSize: fontSize ? fontSize : "17px",
          backgroundColor: color ? color : "#15B79E",
        }}
        title={name + " " + lastName}
        className={`flex items-center blur-sm justify-center font-medium text-white select-none shadow`}
      >
        {lastName
          ? name?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()
          : name?.charAt(0).toUpperCase()}
      </div>
    );
};

export default NoAvatar;
