import React from "react";
import { Button } from "../../../components";
import { DeleteIcon, EditIcon } from "../../../assets/icons/departmentIcon";
import { Toast } from "../../../utils/toastify/toast";

export default function ShiftCard({
  name,
  labels,
  id,
  responsibleUser,
  editOnClick,
  deleteOnclick,
  description,
}) {
  return (
    <div className="w-full bg-white min-h-[64px] max-h-[64px]  border border-l-[8px] dark:bg-[#141414] dark:border-[#424242] border-gray-300 shadow-xs rounded-lg flex items-center justify-between px-3">
      <div className="flex flex-col">
        <div className=" text-sm font-semibold whitespace-nowrap text-[#344054] dark:text-[#D6D6D6]">
          {name ? name?.slice(0, 3)+" ***" : "No Name"}
        </div>
        <div className=" text-[#667085] w-full whitespace-nowrap text-sm font-normal dark:text-[#D6D6D6]">
          {description || "No Shift"}
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center justify-center ">
        <Button
          iconLeft={<EditIcon />}
          colorType={"tertiary-gray"}
          size={"sm"}
          // onClick={editOnClick}
          onClick={() => Toast("warning", "Demo users are not authorized")}
        />
        <Button
          iconLeft={<DeleteIcon />}
          colorType={"tertiary-error"}
          size={"sm"}
          // onClick={deleteOnclick}
          onClick={() => Toast("warning", "Demo users are not authorized")}
        />
      </div>
    </div>
  );
}
