import React, { useEffect, useState } from "react";
import moment from "moment";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { reportService } from "../../../../services/report.service";
import BarChart from "./BarChart";
import useWindowDimensions from "../../../../hooks/useWindowDimension";

import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { authStore } from "../../../../stores/auth.store";

import { LockedIcon, UnlockedIcon } from "../../../../assets/icons/buttonIcons";
import { useTranslation } from "react-i18next";

import CustomModal from "../../../../components/modal/CustomModal";
import SendErrorMessageModal from "../../components/SendErrorMessageModal";

import ReactDOM from "react-dom";
import SingleJunctionCard from "../../../../components/junction/SingleJunctionCard";
import SecondToHoursAndMinute from "../../../../utils/secondToHoursAndMinute";

import { SVGRenderer, CanvasRenderer } from "echarts/renderers";

echarts.use([SVGRenderer, CanvasRenderer]);

const EquipmentTimeline = ({
  nodeId, //
  dayEnd, //
  lossesData,
  refetch, //
  dayStart, //
  deviceId, //
  shiftlyData, //
  setWorkCount, //
  setGaugeData, //
  barChartData, //
  setLossesData, //
  timelineChart, //
  equipmentRefId, //
  setJunctionData, //

  setBarChartData, //
  selectedJunction, //
  setCustomLoading, //
  equipmentDeviceId, //
  setEnergyUsageData, //
  setSelectedJunction, //
  date,

  // 213123213213
  defaultEnd,
  defaultStart,
  dataZoom,
  setDataZoom,
  junctionData,
}) => {
  const { t } = useTranslation();
  const _ = require("lodash");
  const [selectedShiftValues, setSelectedShiftValues] = useState([]);
  const [isLockScroll, setIsLockScroll] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState();
  const { width, height } = useWindowDimensions();
  const isNotToday = moment(date).isSame(moment(), "day");
  const [barValues, setBarValues] = useState([]);
  const [values, setValues] = useState([]);
  const [showJunctionComponent, setShowJunctionComponent] = useState("");
  const [selectedLoss, setSelectedLoss] = useState();
  const normalizeAndScaleTimestamp = (start, end, target) => {
    const reference = start;
    const adjustedStart = start - reference;
    const adjustedEnd = end - reference;
    const adjustedTarget = target - reference;
    const scaleFactor = 100 / (adjustedEnd - adjustedStart);
    const scaledValue = (adjustedTarget - adjustedStart) * scaleFactor;
    return scaledValue;
  };
  const normalizeAndScaleTimestampTwo = (start, end, target) => {
    const reference = start;
    const adjustedStart = start - reference;
    const adjustedEnd = end - reference;
    const adjustedTarget = target - reference;
    const scaleFactor = 100 / (adjustedEnd - adjustedStart);
    const scaledValue = (adjustedTarget - adjustedStart) * scaleFactor;
    return scaledValue;
  };
  const calculateData = (data) => {
    const datas = [];
    categories?.forEach(function (category, index) {
      data?.map((value, no) => {
        value?.value.map((da, i) => {

          
          datas.push({
            name: value.name,
            type:
              da?.index == 1
                ? "status"
                : da.index == 2
                ? "shift"
                : da.index == 0
                ? "junction"
                : "",
            node: value.node,
            junctionId: da?.junctionId,

            actionId: da?.actionId,
            productData: da?.productName,
            operationNo: da?.operationNo,

            startDate : da?.start,
            endDate : da?.end,
            timeDiff : da?.timeDiff,
            index  : da?.index,

            value: [
              da?.index,

              /// moment(da.start).unix(),
              // moment(da.end).unix(),
              moment(
                timeFormatter(da.start, authStore?.user?.company?.timeZone)
                  .formatted
              )?.unix(),
              moment(
                timeFormatter(da.end, authStore?.user?.company?.timeZone)
                  .formatted
              )?.unix(),
              da.timeDiff,
            ],
            shiftId: da?.shiftId,
            itemStyle: {
              color:
                da?.index == 1
                  ? value?.itemStyle?.normal?.color
                  : da?.index == 2
                  ? value?.itemStyle?.normal?.color
                  : da?.index == 0
                  ? da?.type == "setup"
                    ? "#EAAA08"
                    : "#12B76A"
                  : value?.itemStyle?.normal?.color,
            },
          });
        });
      });
    });
    const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);
    const lastValues = _.uniqWith(Valuess, _.isEqual);

    let noJunctionDatas = [];

    const NoIdDatas = lastValues?.map((value, index) => {
      if (value?.value[0] == 0) {
        junctionData?.map((item) => {
          if (item?.id == value?.junctionId) {
            noJunctionDatas.push(value);
          } else {
            return null;
          }
        });
      } else {
        noJunctionDatas.push(value);
      }
    });

    setValues(noJunctionDatas);
  };

  const calculateDataBar = (data) => {
    const barDatas = [];
    data?.data?.map((value, i) => {
      barDatas?.push({
        teoricalTime: data?.teoricalTime,
        name: value?.name,
        type: "bar",
        stack: "total",
        label: {
          color: "black",
          show: true,
          formatter: function (d) {
            return d?.name + (d?.data / 3600).toFixed(1) + " " + "h";
          },
        },
        itemStyle: value?.itemStyle,
        emphasis: {
          focus: "series",
        },
        data: [value?.value[0]],
      });
    });
    setBarValues(barDatas);
  };

  const handleDataZoomChange = async (event) => {
    if (event?.data?.type == "shift") {
      if (selectedShift == event?.data?.shiftId) {
      } else {
        setCustomLoading(true);
        setSelectedShift(event?.data?.shiftId);
        setSelectedShiftValues([
          0,
          event?.data?.value[1],
          event?.data?.value[2],
        ]);
        setDataZoom({
          start: normalizeAndScaleTimestamp(
            defaultStart,
            defaultEnd,
            event?.data?.value[1]
          ),
          end: normalizeAndScaleTimestamp(
            defaultStart,
            defaultEnd,
            event?.data?.value[2]
          ),
        });

        await reportService
          .getCustomDateReport({
            id: equipmentRefId,
            nodeId: nodeId,
            deviceId: equipmentDeviceId,
            nodeId: nodeId,
            startDate: moment
              .unix(event?.data?.value[1])
              .format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment
              .unix(event?.data?.value[2])
              .format("YYYY-MM-DD HH:mm:ss"),
          })
          .then((res) => {
            setWorkCount(res?.data?.workCountData || []);
            setEnergyUsageData(
              {
                kwh: res?.data?.kwh,
                kwhCostT1: res?.data?.kwhCostForStandard,
                kwhCostForT3: res?.data?.kwhCostForThreeTime,
                cCount: res?.data?.cCount,
                kw: res?.data?.kw,
                kwhRatioByOperation: res?.data?.kwhRatioByOperation,
                kwhRatioByCompany: res?.data?.kwhRatioByCompany,
              } || []
            );
            setGaugeData(
              [
                { ...res.data.availability, type: "Availability" },
                { ...res.data.performance, type: "Performance" },
                { ...res.data.utilization, type: "Utilization" },
              ] || [{}, {}, {}]
            );
            setJunctionData(res?.data?.junctionData || []);
            setLossesData(res?.data?.lossesData || []);
            setBarChartData(res?.data?.barChartData || []);
            calculateDataBar(res?.data?.barChartData || []);
            setCustomLoading(false);
          });
      }
    } else if (event?.data?.type == "junction") {
      setCustomLoading(true);

      setSelectedJunction(event?.data?.junctionId);
      await reportService
        .getCustomDateReport({
          nodeId: nodeId,
          id: equipmentRefId,
          deviceId: deviceId,
          startDate: moment
            .unix(event?.data?.value[1])
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment
            .unix(event?.data?.value[2])
            .format("YYYY-MM-DD HH:mm:ss"),
        })
        .then((res) => {
          setBarChartData(res?.data?.barChartData || []);
          setWorkCount(res?.data?.workCountData || []);
          setEnergyUsageData(
            {
              kwh: res?.data?.kwh,
              kwhCostT1: res?.data?.kwhCostForStandard,
              kwhCostForT3: res?.data?.kwhCostForThreeTime,
              cCount: res?.data?.cCount,
              kw: res?.data?.kw,
              kwhRatioByOperation: res?.data?.kwhRatioByOperation,
              kwhRatioByCompany: res?.data?.kwhRatioByCompany,
            } || []
          );
          setGaugeData(
            [
              { ...res.data.availability, type: "Availability" },
              { ...res.data.performance, type: "Performance" },
              { ...res.data.utilization, type: "Utilization" },
            ] || [{}, {}, {}]
          );
          setCustomLoading(false);
        });

      setDataZoom({
        start: normalizeAndScaleTimestamp(
          defaultStart,
          defaultEnd,
          event?.data?.value[1]
        ),
        end: normalizeAndScaleTimestamp(
          defaultStart,
          defaultEnd,
          event?.data?.value[2]
        ),
      });
    }
  };

  const renderItem = (params, api) => {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    var height =
      categoryIndex == 2
        ? api.size([0, 1])[1] * 0.5
        : categoryIndex != 1
        ? api.size([0, 1])[1] * 0.8
        : api.size([0, 1])[1] * 1.5;
    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: categoryIndex == 1 ? start[0] - 2 : start[0],
        y:
          categoryIndex == 1
            ? start[1] - 35
            : categoryIndex == 2
            ? start[1] - height / 2
            : start[1] - height / 2 + 8,

        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

   

    

    return (
      rectShape && {
        type: "rect",
        transition: ["shape"],
        shape: rectShape,
        style: api.style(),
      }
    );
  };

  const shiftSelecter = (start, end, id) => {
    const findShift = shiftlyData?.findIndex((a) => a.id === id);
    setDataZoom({
      start:
        findShift === 0
          ? 0
          : normalizeAndScaleTimestampTwo(defaultStart, defaultEnd, start),
      end: normalizeAndScaleTimestampTwo(defaultStart, defaultEnd, end),
    });
  };

  const chartTimeDiff = (params) => {
    const first = moment.unix(params.value[1]);
    const second = moment.unix(params.value[2]);

    const value = moment(second).diff(first, "seconds");
    let h = Math.floor(value / 3600);
    let m = Math.floor(value / 60);
    let s = Math.floor((value % 3600) % 60);
    return `${m + ":" + s}dk. - ${moment(second).diff(first, "second")}sn.`;
  };

  const customTooltip = (params) => {
    if (params?.data?.type === "junction") {
      return `${params.marker} ${params.name} : ${moment
        .unix(params.value[1])
        .format("HH:mm:ss")} - ${moment
        .unix(params.value[2])
        .format("HH:mm:ss")} - ${chartTimeDiff(params)} - ${
        params?.data?.productData
      } - ${params?.data?.operationNo}. Operation`;
    } else {
      return `${params.marker} ${params.name} : ${moment
        .unix(params.value[1])
        .format("HH:mm:ss")} - ${moment
        .unix(params.value[2])
        .format("HH:mm:ss")} - ${chartTimeDiff(params)} 
      `;
    }
  };

  // const handleMouseMove = (event) => {
  //   const chartInstance = event?.echarts;
  //   if (chartInstance) {
  //     const pointInPixel = [event.offsetX, event.offsetY];
  //     const params = chartInstance.containPixel("grid", pointInPixel);
  //     if (params) {
  //       // setShowJunctionComponent(event?.data?.junctionId);
  //       const customTooltipElement = document.getElementById("custom-tooltip");
  //       if (customTooltipElement) {
  //         customTooltipElement.style.left = `${event.pageX}px`;
  //         customTooltipElement.style.top = `${event.pageY}px`;
  //       }
  //     }
  //   }
  // };

  const handleMouseMove = (event) => {
    const chartInstance = event?.echarts;
    if (chartInstance) {
      const pointInPixel = [event.offsetX, event.offsetY];
      const params = chartInstance.containPixel("grid", pointInPixel);
      if (params) {
        const junctionId = params?.data?.junctionId;
        const junctionElement = document.getElementById(
          `junction-card-${junctionId}`
        );

        if (junctionElement) {
          junctionElement.style.display = "block";
          junctionElement.style.left = `${event.pageX}px`;
          junctionElement.style.top = `${event.pageY}px`;
        }
      }
    }
  };

  // const handleMouseOut = () => {
  //   // setShowJunctionComponent("");
  // };

  const handleMouseOut = () => {
    const junctionElements = document.querySelectorAll(".junction-card");
    junctionElements.forEach((element) => {
      element.style.display = "none";
    });
  };

  useEffect(() => {
    if (showJunctionComponent) {
      const junctionElements = junctionData?.map(
        (item) =>
          item?.id == showJunctionComponent && (
            <SingleJunctionCard
              props={{
                status: item?.status,
                leftImage: item?.job?.product?.image,
                texts: {
                  greenTime: SecondToHoursAndMinute(
                    moment(item.endDate).unix() - moment(item.startDate).unix()
                  ),

                  second: item?.job?.product?.code,
                  first: item?.job?.product?.name,
                  qualityAcceptedPiece: item?.metadata?.madeWorkCount,
                  qualityRejectedPiece: item?.metadata?.rejectedWorkCount,
                },
                user: {
                  name: item?.user?.name,
                  lastName: item?.user?.lastName,
                  avatar: item?.user?.avatar || "empty",
                },
                ring: "#079455",
              }}
            />
          )
      );

      // ReactDOM.render(
      //   <div>{junctionElements}</div>,
      //   document.getElementById("custom-tooltip")
      // );
      ReactDOM.render(
        <div>
          {junctionData?.map((item) => (
            <div
              key={item?.id}
              id={`junction-card-${item?.id}`}
              className="junction-card"
              style={{
                display: "none",
                position: "absolute",
                top: 80,
                right: 50,
                pointerEvents: "none",
              }}
            >
              <div>{junctionElements}</div>,
              {/* document.getElementById("custom-tooltip") */}
            </div>
          ))}
        </div>,
        document.getElementById("custom-tooltip")
      );
    }
  }, [showJunctionComponent, junctionData]);

  const categories = ["Junction", t("embedded:status"), t("routes:shift")];
  const options = {
    tooltip: {
      formatter: customTooltip,
    },
    label: {
      color: "black",
      fontSize: 14,
      fontWeight: "bold",
      show: true,
      formatter: function (d) {
        if (d?.data?.type == "shift") {
          if (
            selectedShiftValues[1] == d?.data?.value[1] &&
            selectedShiftValues[2] == d?.data?.value[2]
          ) {
            return `${d?.data?.name}  ${moment
              .unix(d.value[1])

              .format("HH:mm:ss")} -  ${moment
              .unix(d.value[2])

              .format("HH:mm:ss")} `;
          } else {
            return "";
          }
        } else if (d?.data?.type == "junction") {
          if (selectedJunction == d?.data?.junctionId) {
            return `${d?.data?.name}  ${moment
              .unix(d.value[1])
              .format("HH:mm:ss")} -  ${moment
              .unix(d.value[2])
              .format("HH:mm:ss")} - ${d?.data?.productData}. - ${
              d?.data?.operationNo
            } Operation`;
          } else {
            return junctionNumber(d?.data?.junctionId);
          }
        } else {
          return "";
        }
      },
    },

    title: {
      text: values.length > 0 ? "" : "",
    },
    dataZoom: [
      {
        start: dataZoom?.start,
        end: dataZoom?.end,
        type: "slider",
        filterMode: "weakFilter",
        showDataShadow: false,
        top: "73%",
        height: 20,
        labelFormatter: "",
        show: false,
        disabled: isLockScroll ? false : true,
      },
      {
        type: "inside",
        filterMode: "weakFilter",
        zoomLock: isLockScroll ? false : true,
      },
    ],
    grid: {
      height: 140,
      width: "93%",
      left: "5%",
      top: "15%",
    },
    xAxis: [
      {
        onZero: true,
        splitLine: {
          show: true,
        },
        min: defaultStart || "0",
        max: defaultEnd || "1",
        scale: true,
        axisLabel: {
          formatter: function (val) {
            // let data = timeFormatter(val, authStore.user?.company?.timeZone).formatted;
            let datas = moment.unix(val).format("HH:mm:ss");

            return datas;
          },
        },
      },
    ],

    yAxis: { data: categories },

    series: [
      {
        type: "custom",
        renderItem: renderItem,
     

        itemStyle: {
          opacity: 1,
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        data: values,
        width: 400,
        height: 2000,
      },
    ],
  };

  console.log('valuess' , values)

  useEffect(() => {
    calculateData(timelineChart);
    calculateDataBar(barChartData);
  }, [barChartData]);

  const junctionNumber = (item) => {
    const junctions = junctionData?.filter((a) => a.status != "planned");

    let sortedJunctions = junctions?.sort((a, b) => a.endDate - b.endDate);

    const data = sortedJunctions?.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });

    const lastData = data?.filter((a) => a != "");

    return lastData[0];
  };

  return (
    <div className="bg-gray-100 border border-gray-300 shadow rounded-lg w-full min-h-[270px] relative">
      {values?.length > 0 ? (
        <>
          <ReactECharts
            onEvents={{
              click: (event) => {
                if (isNotToday === true) {
                  if (event.name === "Warning") {
                    // setIsWarningModal(true);
                    const filterLoss = lossesData.filter((e) => {
                      return moment(e?.startDate).unix() == event.value[1];
                    });

                    if (filterLoss?.length > 0) {
                      setIsWarningModal(true);
                      setSelectedLoss(filterLoss[0]);
                    } else {
                    }
                  }
                }
                handleDataZoomChange(event);
              },
              dataZoom: (e) => {
                if (selectedShift || selectedJunction) {
                  setDataZoom({
                    start: dataZoom.start,
                    end: dataZoom.end,
                    zoomLock: true,
                  });
                }
              },
              mousemove: (event) => handleMouseMove(event),
              mouseout: () => handleMouseOut(),
            }}
            className="!w-full !h-[95%] !min-h-[260px] !min-w-full ml-0 mt-2.5"
            option={options}
            SVGRenderer
            id="main"
            style={{
              height: height,
              width: width,
              minWidth: width,
            }}
          />
          <div
            className="flex absolute right-0 top-14 cursor-pointer z-50 p-1 step-14"
            onClick={() => {
              setIsLockScroll(!isLockScroll);
            }}
          >
            {isLockScroll ? <UnlockedIcon /> : <LockedIcon />}
          </div>
          <div className="flex w-full px-2 items-center absolute top-2 gap-x-2">
            <div
              onClick={() => {
                setDataZoom({
                  start: 0,
                  end: 100,
                });
                setSelectedShift();
                setSelectedShiftValues([]);
                setSelectedJunction("");
                refetch();
              }}
              className={`px-2 py-1 rounded text-white font-semibold text-center text-sm cursor-pointer w-full max-w-[60px] bg-secondary-500 step-13 ${
                selectedShift || (selectedJunction && "animate-pulse")
              } `}
            >
              {t("buttons:reset")}
            </div>

            {shiftlyData?.map((e, index) => {
              if (selectedShift == undefined) {
                return (
                  <div
                    key={index}
                    onClick={async () => {
                      setCustomLoading(true);
                      setSelectedShift(e?.shift?.id);
                      setSelectedShiftValues([]);
                      shiftSelecter(
                        moment(e?.start).format("X"),
                        moment(e?.end).format("X"),
                        e.id
                      );
                      await reportService
                        .getCustomDateReport({
                          id: equipmentRefId,
                          nodeId: nodeId,
                          deviceId: deviceId,
                          startDate: e?.start,
                          endDate: e?.end,
                        })
                        .then((res) => {
                          setWorkCount(res?.data?.workCountData || []);
                          setEnergyUsageData(
                            {
                              kwh: res?.data?.kwh,
                              kwhCostT1: res.data.kwhCostForStandard,
                              kwhCostForT3: res.data.kwhCostForThreeTime,
                              cCount: res.data.cCount,
                              kw: res.data.kw,
                              kwhRatioByOperation: res.data.kwhRatioByOperation,
                              kwhRatioByCompany: res.data.kwhRatioByCompany,
                            } || []
                          );
                          setGaugeData(
                            [
                              {
                                ...res.data.availability,
                                type: "Availability",
                              },
                              { ...res.data.performance, type: "Performance" },
                              { ...res.data.utilization, type: "Utilization" },
                            ] || [{}, {}, {}]
                          );
                          setJunctionData(res?.data?.junctionData || []);
                          setLossesData(res?.data?.lossesData || []);
                          setBarChartData(res?.data?.barChartData || []);
                          calculateDataBar(res?.data?.barChartData || []);
                          setCustomLoading(false);
                        });
                    }}
                    style={{ backgroundColor: e?.shift?.color }}
                    className={`px-4 py-1 flex items-center justify-center rounded text-white font-semibold  text-sm cursor-pointer w-full   `}
                  >
                    {e?.shift?.name}
                  </div>
                );
              } else if (selectedShift == e?.shift?.id) {
                return (
                  <div
                    style={{ backgroundColor: e?.shift?.color }}
                    className={`px-4 py-1 flex items-center justify-center rounded text-white font-semibold  text-sm cursor-pointer w-full   `}
                  >
                    {e?.shift?.name}
                  </div>
                );
              } else {
              }
            })}
            {showJunctionComponent && (
              <div
                id="custom-tooltip"
                style={{
                  display: "flex",
                  position: "absolute",
                  top: 80,
                  right: 50,
                  pointerEvents: "none",
                }}
              >
                {" "}
              </div>
            )}
          </div>
          <div className="w-full h-full max-h-[90px] pl-5 pt-1 -mt-[53px] min-w-full">
            <BarChart
              data={barValues}
              teoricalTime={barValues[0]?.teoricalTime}
              height={"120px"}
            />
          </div>
        </>
      ) : (
        <div className="h-[280px] flex items-center justify-center w-full flex-col">
          <p className="text-[18px] text-secondary-500 font-semibold mt-2">
            {t("settingTab:graphDataNotFound")}
          </p>
        </div>
      )}
      <CustomModal
        width={400}
        isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        children={
          <SendErrorMessageModal
            setIsOpen={setIsWarningModal}
            t={t}
            refetch={refetch}
            selectedLossId={selectedLoss?.id}
          />
        }
      />
    </div>
  );
};

export default EquipmentTimeline;
