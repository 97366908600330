import React, { useState } from "react";
import { useStore } from "../../hooks/useStores";
import { Toast } from "../../utils/toastify/toast";
import { personalService } from "../../services/personal.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KeyIcons, TableArrowLeft } from "../../assets/icons/machineIcons";
import { Button, Input } from "../../components";
import { CloseIcon } from "../../assets/icons/departmentIcon";
import { LoginBackGround } from "../../assets/icons";

const ForgotPassword = ({ goBack, color }) => {
  const { t } = useTranslation();
  //   const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const { auth } = useStore();

  const forgotPassword = async (email) => {
    const data = {
      email: email,
    };
    await personalService.forgotPassword(data).then((res) => {
      if (res?.data?.code == 0) {
        Toast(
          "success",
          "Successful, check your e-mail address. You are redirected to the login page"
        );
        setTimeout(() => {
          window.location.href = "/auth/login";
        }, 3000);
      } else {
        Toast("error", "Warning.! not found user");
      }
    });
  };

  const sendMail = () => {
    forgotPassword(email);
  };

  return (
    <div className="w-full h-full flex items-center justify-center relative xs:justify-start xs:flex-col">
      {/* <LoginBacgroundDetail className="absolute top-0 right-0 xs:hidden" /> */}
      <img
        src={LoginBackGround}
        className="absolute left-1/2 bottom-0 rotate-180"
      />
      {/* <div
        onClick={goBack}
        className="absolute top-4 xs:-top-2 left-4 xs:right-3 xs:left-auto w-8 h-8 flex items-center justify-center rounded-full hover:bg-secondary-100 cursor-pointer"
      >
        <TableArrowLeft size={22} className="xs:hidden" />
      </div> */}

      <div className="w-[360px] flex flex-col relative z-20 items-center">
        <div className="w-14 h-14 rounded-xl border border-secondary-200 shadow-xs bg-white flex items-center justify-center">
          <KeyIcons />
        </div>
        <h1 className="text-displaySm font-semibold text-secondary-900 mt-4">
          Forgot password?
        </h1>
        <p className="text-secondary-600 font-normal text-md mt-3">
          No worries, we’ll send you reset instructions.
        </p>
        <div className="mt-8 mb-6 w-full">
          <Input
            onChange={(e) => setEmail(e?.target?.value)}
            label={t("login:email")}
          />
        </div>
        <Button
          colorType={
            color === "#0E9384"
              ? "primary-person"
              : color === "#7F57D9"
              ? "primary-machine"
              : "primary-product"
          }
          label={"Reset Password"}
          size={"sm"}
          onClick={(e) => {
            sendMail();
          }}
        />
        <div
          onClick={goBack}
          className={`w-full mt-5 flex items-center justify-center gap-1 text-sm font-semibold text-secondary-600 select-none cursor-pointer`}
        >
          <TableArrowLeft />
          Back to log in
        </div>
      </div>
      <div className="absolute bottom-9 right-9 text-sm text-gray-600">
        © upu.io 2024
      </div>
    </div>
  );
};

export default ForgotPassword;
