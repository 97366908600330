import React, { useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, TimeInput, UploadProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { settingsStore } from "../../../stores/settings.store";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import { CloseIcon } from "../../../assets/icons/departmentIcon";
import Selector from "../../../components/inputs/Selector";
import { Toast } from "../../../utils/toastify/toast";

export default function GroupInfo({
  theme,
  departments,
  t,
  errors,
  control,
  departmans,
  setValue,
  setSelectedTab,
  setSelectedDepartments,
  selectDepartmentUsers,
  selectedDepartments,
}) {
  const selectedPackage = settingsStore.selectedPackage;

  return (
    <>
      <div className="min-w-[700px] flex flex-col gap-6 mt-6">
        <div className="w-full flex gap-6 flex-row h-[312px]">
          <div className="w-1/2 flex flex-col gap-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UploadProfile
                  theme={theme}
                  title={t("group:groupPhoto")}
                  subTitle={t("fileInput:removePicture")}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  borderRadius={4}
                />
              )}
              name="image"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("group:taskGroupName")}
                  placeholder={t("group:groupName")}
                  type="text"
                  height="30px"
                  theme={"person"}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  validate={errors.title ? "error" : ""}
                  errorMessage={errors.title ? errors.title.message : ""}
                />
              )}
              name="title"
            />
          </div>
          <span className="min-h-full w-px bg-[#EAECF0]" />
          <div className="w-1/2 flex flex-col gap-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  label={t("routes:department")}
                  theme={"person"}
                  // items={departments}
                  items={departmans?.map((d) => {
                    if (d?.departmentId == "BLANK_DEPARTMENT") {
                      return {
                        label: "Diger",
                        value: d?.departmentId,
                      };
                    } else {
                      return {
                        label: d?.departmentName,
                        value: d?.departmentId,
                      };
                    }
                  })}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={(d) => {
                    setSelectedDepartments(d);
                    selectDepartmentUsers(d);
                  }}
                  value={selectedDepartments}
                  validate={errors.userId ? "error" : ""}
                  errorMessage={errors.userId ? errors.userId.message : ""}
                />
              )}
              name="departmentId"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TimeInput
                  label={t("report:setDeadLine")}
                  placeholder={t("buttons:selectDate")}
                  theme={"person"}
                  value={moment(value).format("YYYY-MM-DD")}
                  disabled={false}
                  onChange={(v) => {
                    setValue("endDate", v);
                  }}
                />
              )}
              name="endDate"
            />
          </div>
        </div>
        <div className="flex gap-3">
          <Button colorType={"secondary-gray"} label={t("buttons:stop")} />
          <Button
            colorType={"primary-person"}
            label={t("buttons:next")}
            type={"button"}
            onClick={() => {
              setSelectedTab("member");
            }}
          />
        </div>
      </div>
    </>
  );
}
