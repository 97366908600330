import React from "react";
import CustomText from "../CustomText";
import CustomImage from "../CustomImage";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { Toast } from "../../utils/toastify/toast";
import { useTranslation } from "react-i18next";
const styles = {
  container: {
    height: 80,
    width: "100%",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
  base: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
  },
  box: {
    width: "49%",
    height: 44,
    borderWidth: 1,
    borderRadius: 4,
    borderTopWidth: 4,
    alignItems: "center",
    position: "relative",
    paddingLeft: "12px",
    paddingRight: "12px",
    flexDirection: "column",
    display: "flex",
  },
  image: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  squareButton: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    display: "flex",
  },
  userContent: {
    position: "absolute",
    width: 50,
    height: 50,
    zIndex: 100,
    bottom: 32,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
  },
  avatar: {
    width: 50,
    height: 50,
    flexDirection: "column",
    borderRadius: 100,
    display: "flex",
  },
  circleButton: {
    width: 50,
    height: 50,
    borderRadius: 100,
    flexDirection: "column",
    borderWidth: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    display: "flex",
  },
  boldText: {
    fontSize: "11px",
    flexDirection: "column",
    fontWeight: "500",
    display: "flex",
    marginTop: "-2px",
    textAlign: "center",
  },
  lightText: {
    marginTop: 9,
    flexDirection: "column",
    fontSize: "11px",
    fontWeight: "400",
    display: "flex",
    textAlign: "center",
  },
  timeSection: {
    position: "absolute",
    display: "flex",
    top: 18,
    left: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  pieceSection: {
    position: "absolute",
    top: 0,
    right: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
};

const FinishedPurchaseJunction = ({
  id,
  materialImage,
  materialName = "materialName",
  materialCode = "materialCode",
  onClickMaterial = () => {
    console.log("material");
  },
  onClickUser = () => {
    console.log("user");
  },
  onClickSupplier = () => {
    console.log("supplier");
  },
  userImage,
  userName = "userName",
  userLastName = "userLastName",
  supplierImage,
  supplierName = "supplierName",
  supplierGroupName = "supplierGroupName",
  acceptedQuantity = "--",
  rejectedQuantity = "--",
  onClickQuality = () => {
    console.log("quality");
  },
  qualityPercent,
  startDate = "--",
  endDate = "--",
}) => {
  const { t } = useTranslation();
  return (
    <div style={styles.container}>
      <div className="flex  flex-row items-center w-full">
        <div className="flex flex-col items-start">
          <div className="flex flex-row items-center gap-1 mr-auto">
            <div className="mr-auto">
              <QualityGreenIcon />
            </div>
            <p className={`text-xs font-normal text-secondary-600 `}>
              {acceptedQuantity || "--"}
            </p>
          </div>
          <div className="flex flex-row items-center gap-1 mr-auto">
            <div className="mr-auto">
              <QualityRedIcon />
            </div>
            <p className={`text-xs font-normal text-secondary-600 `}>
              {rejectedQuantity || 0}
            </p>
          </div>
        </div>
      </div>
      <div style={{ ...styles.userContent }}>
        <UserContoller
          id={id}
          onClickUser={onClickUser}
          image={userImage}
          name={userName}
          lastName={userLastName}
        />
        <div
          className={`z-[100] absolute flex items-center justify-center w-fit px-[6px] py-1 gap-[2px] -bottom-[26px] h-[24px] blur-sm rounded border cursor-pointer bg-[#DC6803] border-[#DC6803]`}
          // onClick={onClickQuality}
          onClick={() => Toast("warning", t("alert:warning"))}
        >
          <ProductCompleted />
          <span className="text-white font-semibold text-[10px] leading-4">
            {qualityPercent}
          </span>
        </div>
      </div>
      <div style={styles.base}>
        <div
          style={{
            ...styles.box,
            borderColor: "#D0D5DD",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
            <PressedContoller image={materialImage} onClick={onClickMaterial} />
          </div>
          <CustomText
            text={materialCode?.slice(0, 2) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.lightText,
              color: "#667085",
            }}
          />
          <CustomText
            text={materialName?.slice(0, 2) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.boldText,
              color: "#344054",
            }}
          />
        </div>
        <div
          style={{
            ...styles.box,
            borderColor: "#D0D5DD",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
            <PressedContoller image={supplierImage} onClick={onClickSupplier} />
          </div>
          <CustomText
            text={supplierGroupName?.slice(0, 2) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.lightText,
              color: "#667085",
            }}
          />
          <CustomText
            text={supplierName?.slice(0, 2) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.boldText,
              color: "#344054",
            }}
          />
        </div>
      </div>
      <div style={styles.pieceSection}>
        <div className="flex flex-col items-start">
          <div className="flex flex-row items-center gap-1 ml-auto">
            <p className={`text-xs font-normal text-secondary-600 `}>
              {startDate}
            </p>
            <div className="ml-auto">
              <GreenClockIcon />
            </div>
          </div>
          <div className="flex flex-row items-center gap-1 ml-auto">
            <p className={`text-xs font-normal text-secondary-600 `}>
              {endDate}
            </p>
            <div className="ml-auto">
              <RedClockIcon />
            </div>
          </div>
        </div>
      </div>
      <Tooltip className="z-[100]" id={`${id}`} />
    </div>
  );
};

const PressedContoller = ({ image, onClick }) => {
  return (
    <div>
      {image ? (
        <CustomImage
          borderRadius={4}
          onClick={onClick}
          src={image}
          style={{
            ...styles.image,
            borderColor: "#0000001A",
            backgroundColor: "#fff",
            filter: "blur(4px)",
          }}
        />
      ) : (
        <div
          className={
            "min-w-[60px] min-h-[45px] max-w-[60px] blur-sm rounded max-h-[45px] bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
          }
        >
          A
        </div>
      )}
    </div>
  );
};
const UserContoller = ({ image, onClickUser, name, lastName, id }) => {
  return (
    <div
      data-tooltip-id={`${id}`}
      data-tooltip-place={"top"}
      data-tooltip-content={`
         ${name + " " + lastName}
    `}
      className={onClickUser && "cursor-pointer"}
      onClick={() => {
        if (onClickUser) {
          onClickUser();
        }
      }}
    >
      {image ? (
        <CustomImage
          src={image}
          style={{
            ...styles.avatar,
            borderColor: "#0000001A",
            backgroundColor: "#fff",
            filter: "blur(4px)",
          }}
        />
      ) : (
        <NoAvatar size={50} name={name} lastName={lastName} />
      )}
    </div>
  );
};

const ArchiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#A3A3A3"
        strokeWidth="0.75"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_1100_1592)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#A3A3A3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1100_1592">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(FinishedPurchaseJunction);
const QualityRedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04169H3.25C2.42157 2.04169 1.75 2.71326 1.75 3.54169V10.3854C1.75 11.2139 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2139 12.25 10.3854V3.54169C12.25 2.71326 11.5784 2.04169 10.75 2.04169H8.96875M5.03125 2.04169V4.69117C5.03125 4.74692 5.08992 4.78318 5.13979 4.75825L7 3.82815L8.86021 4.75825C8.91008 4.78318 8.96875 4.74692 8.96875 4.69117V2.04169M5.03125 2.04169H8.96875"
        stroke="#D92D20"
      />
      <path
        d="M8.45841 6.70831L5.54175 9.62498M5.54175 6.70831L8.45841 9.62498"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QualityGreenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04169H3.25C2.42157 2.04169 1.75 2.71326 1.75 3.54169V10.3854C1.75 11.2139 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2139 12.25 10.3854V3.54169C12.25 2.71326 11.5784 2.04169 10.75 2.04169H8.96875M5.03125 2.04169V4.69117C5.03125 4.74692 5.08992 4.78318 5.13979 4.75825L7 3.82815L8.86021 4.75825C8.91008 4.78318 8.96875 4.74692 8.96875 4.69117V2.04169M5.03125 2.04169H8.96875"
        stroke="#079455"
      />
      <path
        d="M5.25 8.27883L6.50641 9.53524L9.33333 6.70831"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const GreenClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.50638 4.75128V7.38376L4.86108 8.37094"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7868 7.69119C12.8114 7.46376 12.824 7.23275 12.824 6.99879C12.824 3.47878 9.97046 0.625244 6.45045 0.625244C2.93044 0.625244 0.0769043 3.47878 0.0769043 6.99879C0.0769043 10.5188 2.93044 13.3723 6.45045 13.3723C7.03961 13.3723 7.61011 13.2924 8.15167 13.1428V12.0975C7.61704 12.2758 7.04502 12.3723 6.45045 12.3723C3.48272 12.3723 1.0769 9.96652 1.0769 6.99879C1.0769 4.03106 3.48272 1.62524 6.45045 1.62524C9.41818 1.62524 11.824 4.03106 11.824 6.99879C11.824 7.00893 11.824 7.01906 11.8239 7.02918L12.7868 7.69119Z"
        fill="#079455"
      />
      <path
        d="M9.46777 7.97353C9.46777 7.70342 9.46777 7.56836 9.52409 7.49392C9.57316 7.42906 9.64815 7.38892 9.72933 7.38408C9.82251 7.37851 9.93489 7.45343 10.1596 7.60326L13.0844 9.55307C13.2701 9.67687 13.3629 9.73878 13.3953 9.8168C13.4236 9.88501 13.4236 9.96167 13.3953 10.0299C13.3629 10.1079 13.2701 10.1698 13.0844 10.2936L10.1596 12.2434C9.93489 12.3933 9.82251 12.4682 9.72933 12.4626C9.64815 12.4578 9.57316 12.4176 9.52409 12.3528C9.46777 12.2783 9.46777 12.1433 9.46777 11.8732V7.97353Z"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const RedClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.66247 4.68896V7.39072L4.97388 8.40388"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.59009 6.99561C1.59009 4.22592 3.83536 1.98065 6.60504 1.98065C9.37473 1.98065 11.62 4.22592 11.62 6.99561C11.62 7.01478 11.6199 7.03392 11.6197 7.05305H12.6064C12.6108 7.05305 12.6153 7.05305 12.6197 7.05305C12.6199 7.03392 12.62 7.01478 12.62 6.99561C12.62 3.67364 9.92701 0.980652 6.60504 0.980652C3.28308 0.980652 0.590088 3.67364 0.590088 6.99561C0.590088 10.3176 3.28308 13.0106 6.60504 13.0106C7.21835 13.0106 7.81022 12.9188 8.36766 12.7482C8.32609 12.6957 8.28961 12.6391 8.25891 12.5788C8.16661 12.3977 8.15145 12.1697 8.14897 11.7684C7.66261 11.9256 7.14374 12.0106 6.60504 12.0106C3.83536 12.0106 1.59009 9.76529 1.59009 6.99561Z"
        fill="#D92D20"
      />
      <path
        d="M9.36426 9.48463C9.36426 9.10635 9.36426 8.91721 9.43788 8.77273C9.50263 8.64563 9.60596 8.54231 9.73305 8.47755C9.87754 8.40393 10.0667 8.40393 10.445 8.40393H12.3362C12.7145 8.40393 12.9036 8.40393 13.0481 8.47755C13.1752 8.54231 13.2785 8.64563 13.3433 8.77273C13.4169 8.91721 13.4169 9.10635 13.4169 9.48463V11.3759C13.4169 11.7541 13.4169 11.9433 13.3433 12.0878C13.2785 12.2149 13.1752 12.3182 13.0481 12.3829C12.9036 12.4566 12.7145 12.4566 12.3362 12.4566H10.445C10.0667 12.4566 9.87754 12.4566 9.73305 12.3829C9.60596 12.3182 9.50263 12.2149 9.43788 12.0878C9.36426 11.9433 9.36426 11.7541 9.36426 11.3759V9.48463Z"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const ProductCompleted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.6615 4.71372L14.028 3.73641L13.66 2.21297C13.4966 1.53732 12.8708 1.08688 12.1846 1.14088L10.3156 1.28554L9.24489 0.337535C8.71755 -0.112434 7.95033 -0.112434 7.42117 0.337066L6.35045 1.2855L4.48139 1.14085C3.80299 1.09325 3.16898 1.53772 3.02295 2.15754C2.99273 2.25147 2.44689 3.94819 2.48233 3.83797L1.00477 4.71322C0.412891 5.07669 0.175766 5.80635 0.440797 6.44816L1.07845 7.99357L0.441266 9.53854C0.175766 10.1808 0.413329 10.91 1.00477 11.273L2.63658 12.2435L3.00599 13.7738C3.16849 14.449 3.79152 14.8971 4.48136 14.8459L6.33027 14.7031L7.42111 15.6622C7.68527 15.8874 8.00892 16 8.33302 16C8.65664 16 8.98077 15.8874 9.24489 15.6622L10.3357 14.703L12.1846 14.8458C12.8832 14.9012 13.4975 14.4494 13.66 13.7738L14.028 12.2503L15.6615 11.273C16.2525 10.91 16.4896 10.1808 16.225 9.53854L15.5874 7.9936L16.2255 6.44772C16.4901 5.80641 16.253 5.07672 15.6615 4.71372ZM8.33302 13.1563C5.48986 13.1563 3.17677 10.8432 3.17677 8C3.17677 5.15685 5.48986 2.84375 8.33302 2.84375C11.1762 2.84375 13.4893 5.15685 13.4893 8C13.4893 10.8432 11.1762 13.1563 8.33302 13.1563Z"
        fill="white"
      />
      <path
        d="M8.33301 3.78125C6.00666 3.78125 4.11426 5.67366 4.11426 8C4.11426 10.3263 6.00666 12.2188 8.33301 12.2188C10.6594 12.2188 12.5518 10.3263 12.5518 8C12.5518 5.67366 10.6594 3.78125 8.33301 3.78125ZM11.0082 6.92516L8.19566 9.73766C8.1041 9.82922 7.98416 9.875 7.86426 9.875C7.74435 9.875 7.62438 9.82922 7.53285 9.73766L5.65785 7.86266C5.47476 7.67956 5.47476 7.38291 5.65785 7.19981C5.84095 7.01672 6.1376 7.01672 6.3207 7.19981L7.86426 8.74341L10.3454 6.26231C10.5284 6.07922 10.8251 6.07922 11.0082 6.26231C11.1913 6.44541 11.1913 6.74206 11.0082 6.92516Z"
        fill="white"
      />
    </svg>
  );
};
