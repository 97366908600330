import React, { memo, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import {
  AverageLeftIcon,
  AverageRightIcon,
} from "../../../../assets/icons/machineIcons";
import { GaugeNoData } from "../../../../assets/illustrations";

const CompanyGaugeChart = ({
  textColorPercent = localStorage.color_theme === "dark"
    ? "#D6D6D6"
    : "#595959",
  value,
  kpi1,
  timeMass = 0,
  kpi2,
  name,
  index,
}) => {
  let chartRef = useRef(null);
  const getOption = () => ({
    series: [
      {
        type: "gauge",
        center: ["50%", "33%"],
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: !value ? 5 : 5,
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 5,
            fontWeight: "bold",
            color:
              value === 0
                ? [
                    [
                      1,
                      localStorage.color_theme === "dark"
                        ? "#424242"
                        : "#63666A",
                    ],
                  ]
                : [
                    [
                      kpi1,
                      localStorage.color_theme === "dark"
                        ? "#F04438"
                        : "#F22032",
                    ],
                    [
                      kpi2,
                      localStorage.color_theme === "dark"
                        ? "#FEC84B"
                        : "#F79919",
                    ],
                    [
                      1,
                      localStorage.color_theme === "dark"
                        ? "#12B76A"
                        : "#02C851",
                    ],
                  ],
            [[1, localStorage.color_theme === "dark" ? "#424242" : "#63666A"]]:
              [
                [
                  kpi1,
                  localStorage.color_theme === "dark" ? "#F04438" : "#F22032",
                ],
                [
                  kpi2,
                  localStorage.color_theme === "dark" ? "#FEC84B" : "#F79919",
                ],
                [
                  1,
                  localStorage.color_theme === "dark" ? "#12B76A" : "#02C851",
                ],
              ],
          },
        },
        pointer: {
          icon: "path://M5.23456 5.10815C5.29174 3.81731 2.99219 0.712524 2.99219 0.712524C2.99219 0.712524 0.601627 3.33333 0.505023 4.66659C0.447842 5.95743 1.42419 7.10176 2.68102 7.24179C4.06047 7.33765 5.17738 6.39899 5.23456 5.10815ZM2.83885 6.28163C2.08563 6.21424 1.46391 5.4785 1.50785 4.72875C1.55179 3.97901 2.27011 3.38149 3.02333 3.44889C3.77656 3.51628 4.39827 4.25208 4.35433 5.00182C4.27097 5.794 3.59208 6.34903 2.83885 6.28163Z",
          length: "18%",
          width: 8,
          offsetCenter: [0, "-55%"],
          itemStyle: {
            color: "auto",
          },
        },
        title: {
          offsetCenter: [0, "-35%"],
          fontSize: 12,
          color: textColorPercent,
          fontWeight: "bold",
        },
        axisTick: {
          length: 3,
          distance: 3,
          lineStyle: {
            color: "auto",
            width: 1,
          },
        },
        splitLine: {
          length: -8,
          lineStyle: {
            width: 0,
            color: "auto",
          },
        },
        axisLabel: {
          color: textColorPercent,
          show: false,
          fontSize: 30,
          fontWeight: "bold",
          formatter: function (value) {
            return "%" + value;
          },
          padding: [-20, -50, -20, -50],
        },
        detail: {
          fontSize: 20,
          offsetCenter: ["-2%", "5%"],
          valueAnimation: true,
          formatter: function (value) {
            return "%" + Math.round(value);
          },
          color: "auto",
        },
        data: [
          {
            value: value * 100 || 0,
            name: timeMass,
          },
        ],
      },
    ],
  });
  return (
    <>
      <div
        style={{ borderRightWidth: index === 2 ? 0 : 1 }}
        className="w-full h-[140px]  flex flex-col group items-center relative border-secondary-200"
        id="main"
      >
        {timeMass > 0 ? (
          <>
            <div className="w-[180px] h-[140px] overflow-hidden absolute -translate-x-1">
              <ReactEcharts
                ref={(e) => {
                  chartRef = e;
                }}
                option={getOption()}
                style={{
                  width: 180,
                  marginLeft: 2,
                }}
              />
            </div>
            <p className="text-[#344054] items-center text-sm font-medium text-center mt-1">
              {name || "KPI Adi Bulunamadi"}
            </p>

            <div className="absolute text-black text-[8px] left-[34px] top-[103px] font-semibold dark:text-[#D6D6D6]">
              %0
            </div>
            <div className="absolute text-black text-[8px] right-[34px] top-[103px] font-semibold dark:text-[#D6D6D6]">
              %100
            </div>

            <div className="flex w-full items-center justify-between px-2 mt-auto mb-1">
              <div className="flex items-center gap-x-1">
                <AverageLeftIcon width={10} height={10} />
                <p className="text-[#344054] font-medium text-[10px] leading-4">
                  %{(kpi1 * 100).toFixed(0)}
                </p>
                <p className="text-[#475467] text-[10px] font-normal leading-4">
                  avg
                </p>
              </div>
              <div className="flex items-center gap-x-1">
                <p className="text-[#475467] text-[10px] font-normal leading-4">
                  max
                </p>
                <p className="text-[#344054] font-medium text-[10px] leading-4">
                  %{parseInt(kpi2 * 100) > 100 ? 100 : parseInt(kpi2 * 100)}
                </p>
                <AverageRightIcon width={10} height={10} />
              </div>
            </div>
          </>
        ) : (
          <img
            src={GaugeNoData}
            className="w-[124px] h-[66px] mx-auto mt-auto mb-auto"
          />
        )}
      </div>
    </>
  );
};

export default memo(CompanyGaugeChart);
