import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Toggle = ({ enabled, setEnabled }) => {
  return (
    <div className="  w-fit rounded-[32px]  flex items-center  ">
      <div
        checked={enabled}
        onClick={() => setEnabled(!enabled)}
        className={classNames(
          enabled ? "bg-primary-700 " : "bg-secondary-500",
          "relative inline-flex flex-shrink-0 h-6 w-14 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none  "
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-8" : "translate-x-1",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 my-auto"
          )}
        />
        <span
          className={classNames(
            enabled ? "-translate-x-5 " : " translate-x-0 ",
            "mx-auto z-10 text-[10px] transition duration-200 text-primary-100 mt-auto mb-auto leading-5"
          )}
        >
          OEE
        </span>
      </div>
    </div>
  );
};

export default Toggle;
