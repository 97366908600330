import React from "react";
import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import Junction from "../../../components/junction/Junction";
import { useTranslation } from "react-i18next";

const CompanyDetailsLosses = ({ isToday, todayData, losses }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-1/3 rounded-xl border border-gray-200 shadow flex flex-col">
      <div className="w-full h-[72px] min-h-[72px] flex items-center px-4 pl-6 border-b border-secondary-200">
        <Icon />
        <h1 className="text-lg font-semibold text-secondary-900 ml-2">
          {t("routes:losses")}
        </h1>

        <div className="ml-auto">
          <Button
            onClick={() => navigate("/app/machine/loss/list-view")}
            colorType={"secondary-machine"}
            iconLeft={<ArrowUp />}
            label={t("losses:viewAll")}
            size={"sm"}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col w-full px-5 overflow-y-auto scrollbar-hide pt-5 gap-y-4">
        {todayData?.map((item, index) => {
          return (
            <div className="min-h-[80px] mb-2">
              <Junction
                key={index}
                props={{
                  ring: "red",
                  node: {
                    image: item?.node?.metadata?.image,
                    name:
                      item?.node?.metadata?.brand +
                      " " +
                      item?.node?.metadata?.model,
                    process: item?.process?.name,
                  },
                  product: {
                    name: item?.type ? "" : "Undefined Loss",
                    image: item?.type
                      ? ""
                      : "e318b614-0896-4f57-a8f4-276c96bde67f.png",
                    code: "",
                    piece: 0,
                    id: "",
                  },
                  user: {
                    image: item?.startedPerson?.avatar,
                    id: item?.startedPerson?.id,
                    name: item?.startedPerson?.name,
                    lastName: item?.startedPerson?.lastName,
                  },
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M21 15L15.5657 9.56569C15.3677 9.36768 15.2687 9.26867 15.1545 9.23158C15.0541 9.19895 14.9459 9.19895 14.8455 9.23158C14.7313 9.26867 14.6323 9.36768 14.4343 9.56569L12.5657 11.4343C12.3677 11.6323 12.2687 11.7313 12.1545 11.7684C12.0541 11.8011 11.9459 11.8011 11.8455 11.7684C11.7313 11.7313 11.6323 11.6323 11.4343 11.4343L7 7M21 15H17M21 15V11"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.8335 14.1667L14.1668 5.83337M14.1668 5.83337H5.8335M14.1668 5.83337V14.1667"
        stroke="#6941C6"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyDetailsLosses;
