import React from "react";
import {
  MachineActiveIcon,
  MachineIcon,
  PersonActiveIcon,
  PersonIcon,
  ProductActiveIcon,
  ProductIcon,
} from "../assets/icons/leftMenuIcons";
import { useNavigate } from "react-router-dom";
import { authStore } from "../stores/auth.store";

export default function PackageTab({
  selected,
  setSelected,
  currentColor,
  closed,
  isAdmin,
}) {
  const navigate = useNavigate();
  function navigation(value) {
    switch (value) {
      case "machine":
        return `machine/digital-twin`;
      case "product":
        return `product/order`;
      case "person":
        return `person/message`;
    }
  }
  const packages =
    authStore.user.rolesKey != "OPERATOR"
      ? [
          {
            name: "machine",
            value: "machine",
            icon: <MachineIcon />,
            activeIcon: <MachineActiveIcon />,
            borderColor: "#7F56D9",
          },
          {
            name: "product",
            value: "product",
            icon: <ProductIcon />,
            activeIcon: <ProductActiveIcon />,
            borderColor: "#DC6803",
          },
          {
            name: "person",
            value: "person",
            icon: <PersonIcon />,
            activeIcon: <PersonActiveIcon />,
            borderColor: "#0E9384",
          },
        ]
      : [
          {
            name: "person",
            value: "person",
            icon: <PersonIcon />,
            activeIcon: <PersonActiveIcon />,
            borderColor: "#0E9384",
          },
        ];
  if (closed) {
    const item = packages.find((item) => item?.value === selected);
    return (
      <div className="w-[56px] h-[58px] flex items-center justify-center">
        <button
          onClick={() => {
            navigate(navigation(item?.value));
            setSelected(item?.value);
          }}
          type="button"
          style={{
            borderColor:
              selected === item?.value ? "#fff" : currentColor.borderColor,
          }}
          className={`w-full flex items-center justify-center h-full border-b-[2px] flex-col pt-2`}
        >
          <span
            style={{ color: currentColor.textColor }}
            className="w-fit h-fit"
          >
            {selected === item?.value ? item?.activeIcon : item?.icon}
          </span>

          <p
            className="font-codec text-xs mt-1"
            style={{
              color: selected === item?.value ? "#fff" : currentColor.textColor,
            }}
          >
            {item?.name}
          </p>
        </button>
      </div>
    );
  } else {
    return (
      <div className="w-full h-[58px] flex items-center justify-between mt-3">
        {packages.map((item, index) => {
          return (
            <button
              onClick={() => {
                navigate(navigation(item?.value));
                setSelected(item?.value);
              }}
              key={index}
              type="button"
              style={{
                borderColor:
                  selected === item?.value ? "#fff" : currentColor.borderColor,
              }}
              className={`${
                isAdmin ? "w-1/3" : "w-full"
              } flex items-center justify-center h-full border-b flex-col pt-2`}
            >
              <span
                style={{ color: currentColor.textColor }}
                className="w-fit h-fit"
              >
                {selected === item?.value ? item?.activeIcon : item?.icon}
              </span>

              <p
                className="font-codec text-xs mt-1"
                style={{
                  color:
                    selected === item?.value ? "#fff" : currentColor.textColor,
                }}
              >
                {item?.name}
              </p>
            </button>
          );
        })}
      </div>
    );
  }
}
