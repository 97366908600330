import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { instance } from "../../libs/client";
import generateFileUrl from "../../utils/generateFileUrl";
import {
  ImageUploader,
  Loader,
  RoundedImageUploader,
} from "../../assets/icons/stepsIcons";
import { settingsStore } from "../../stores/settings.store";
import { toast } from "react-toastify";

const UploadProfile = ({
  onChange,
  value,
  type,
  title,
  subTitle,
  isHidden,
  borderRadius,
  theme,
  rounded,
  errorMessage,
  validate,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true);

    const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const file = acceptedFiles[0];

    if (file && acceptedImageTypes.includes(file.type)) {
      var formData = new FormData();
      formData.append("file", file);
      instance({
        method: "post",
        url: "file/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(({ data }) => {
        if (onChange) {
          onChange(data.file);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
      toast.error("error", t("fileInput:uploadImageError"));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: value,
  });

  return (
    <div
      className={`flex flex-row w-full h-[66px] justify-center items-center focus:outline-none cursor-pointer ${
        type === "profile" ? "w-full h-12" : ""
      }`}
      {...getRootProps()}
    >
      <div className="relative flex w-full items-center gap-x-3 xs:mt-2 sm:gap-x-3 sm:mt-3">
        <div
          className={`
          relative flex items-center justify-center text-main ${
            rounded ? " w-12 h-12" : " w-[64px] h-[48px]"
          }  ring-[#424242] dark:bg-[#0F0F0F] dark:text-[#FAFAFA]`}
        >
          {loading ? (
            <Loader currentColor="currentColor" currentFill="currentFill" />
          ) : value ? (
            <img
              style={{
                borderRadius: borderRadius ? borderRadius : "4px",
              }}
              src={
                value.includes("https://cdn.upu.io")
                  ? value
                  : generateFileUrl(value)
              }
              className={`opacity-100 absolute left-0 w-full h-full min-w-full min-h-full  object-cover overflow-hidden blur-sm ${
                rounded ? "rounded-full" : "rounded"
              } `}
            />
          ) : (
            <span
              className={` ${
                theme == "person" ? "text-teal-600" : "text-main"
              }  flex items-center justify-center`}
            >
              {rounded ? (
                <RoundedImageUploader
                  bgColor={settingsStore.theme === "light" ? "#FFF" : "#0F0F0F"}
                  color={
                    settingsStore.theme === "light" ? "#D0D5DD" : "#737373"
                  }
                  width="48"
                  height="48"
                />
              ) : (
                <ImageUploader
                  color={
                    settingsStore.theme === "light" ? "#D0D5DD" : "#424242"
                  }
                  width="64"
                  height="48"
                />
              )}
            </span>
          )}
        </div>
        {isHidden === true ? null : (
          <div className="flex flex-col  sm:mb-0 sm:gap-y-1 sm:ml-2">
            <p className="text-[#344054] dark:text-[#D6D6D6] text-base font-semibold ">
              {value ? title : title}
            </p>
            <p
              className="underline text-sm dark:text-[#D6D6D6] text-[#667085] sm:mt-0 "
              onClick={() => onChange("")}
            >
              {value ? subTitle : t("fileInput:uploadPicture")}
            </p>
            {validate ? (
              <p
                className={` ${
                  validate === "success" ? (
                    "text-validate-success"
                  ) : validate === "warning" ? (
                    "text-validate-warning"
                  ) : validate === "error" ? (
                    "text-validate-error"
                  ) : validate === "tooltip" ? (
                    "text-secondary-500 text-sm leading-5"
                  ) : (
                    <></>
                  )
                } text-xs font-normal `}
              >
                {errorMessage || validate.toLocaleUpperCase()}
              </p>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      <input {...getInputProps()} type={"file"} className={"sr-only"} />
    </div>
  );
};

export default UploadProfile;
