import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useStore } from "../hooks/useStores";


const AuthNavigation = observer(() => {
  let navigate = useNavigate();
  const { auth } = useStore();

  useEffect(() => {
    if (auth.isLogged && auth.accessToken) {
      navigate("/app/machine/digital-twin");
    }
  }, []);

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Outlet />
    </div>
  );
});

export default AuthNavigation;
