import React, { useState } from "react";
import NewOrderCard from "./components/NewOrderCard";
import AssemblyCard from "../../components/junction/AssemblyCard";
import InternalOperationCard from "./components/InternalOperationCard";
import ExternalOperationCard from "./components/ExternalOperationCard";
import OrderPurchaseCard from "./components/PurchaseAvgCard";
import { useTranslation } from "react-i18next";

const Montaj2 = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);

  const data = [
    {
      datas: [
        {
          type: "Product",
          line: "left",
          inData : {
            
          }
        },
        {
          type: "Assembly",
        },
        {
          type: "External",
        },
        {
          type: "External",
        },
        {},
        {},
        {},
      ],
    },
    {
      datas: [
        { type: "partLine", line: "left" },
        {
          type: "Product",
        },
        {
          type: "Purchase",
        },
        {},
        {},
        {},
        {},
      ],
    },
    {
      datas: [
        { type: "partLine", line: "left" },
        {
          type: "Product",
        },
        {
          type: "Purchase",
        },
        {
          type: "Assembly",
        },
        { type: "Internal" },
        {},
        {},
      ],
    },
    {
      datas: [
        { type: "partLine", line: "up" },
        { type: "partLine", line: "left" },
        { type: "partLine" },
        {
          type: "Product",
        },
        { type: "Purchase" },
        {
          type: "Internal",
        },
        {},
      ],
    },
    {
      datas: [
        { type: "partLine", line: "up" },
        { type: "partLine", line: "left" },
        { type: "partLine" },
        {
          type: "Product",
        },
        { type: "Purchase" },
        {
          type: "Internal",
        },
        {},
      ],
    },
    {
      datas: [
        { type: "partLine", line: "up" },
        { type: "partLine", line: "left" },
        { type: "partLine" },
        {
          type: "Product",
        },
        { type: "Purchase" },
        {},
        {},
      ],
    },
    {
      datas: [
        { type: "partLine", line: "left" },
        {
          type: "Product",
          line: "duz",
        },
        { type: "Purchase" },
        { type: "Internal" },
        { type: "Internal" },

        { type: "Internal" },
        {},
      ],
    },
    {
      datas: [
        { type: "partLine", line: "left" },
        {
          type: "Product",
          line: "duz",
        },
        { type: "Purchase" },
        { type: "Internal" },

        {},
      ],
    },
   
   
  ];

  return (
    <div className="w-full h-full overflow-auto flex flex-col">
      {data?.map((e, z) => {
        return (
          <div className="h-[130px] min-h-[130px] max-h-[130px] w-full flex ">
            {e?.datas?.map((e, i) => {
              return (
                <div className="w-[350px] min-w-[350px] max-w-[350px]  py-2 mr-5 ">
                  <CardSelector e={e} t={t} i={i} setSelected={setSelected} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const CardSelector = ({ e, t, i, setSelected }) => {
  const [visible, setVisible] = useState([]);

  const toggleVisibility = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  switch (e?.type) {
    case "Product":
      return (
        <div
          className={`w-full flex h-full relative justify-end`}
          onClick={() => {
            setSelected(i);
          }}
        >
          <div className="absolute -left-5 top-14">
            <div>
              <div className="w-[45px] h-[2px] bg-secondary-300"></div>
              <div
                // onClick={() => {
                //   if (visible?.includes(order?.id)) {
                //     setVisible(visible?.filter((id) => id !== order?.id));
                //   } else {
                //     setVisible([...visible, order?.id]);
                //   }
                // }}
                onClick={toggleVisibility}
                className="bg-white"
                style={{
                  position: "relative",
                  bottom: "17px",
                  left: "32px",
                  color: "#727272",
                  fontSize: "20px",
                }}
              >
                {visible ? "<" : ">"}
              </div>
            </div>
          </div>

          {/* <NewOrderCard t={t} /> */}
          {e?.type === "Product" && (
            <div>
              {visible && (
                <div>
                  <NewOrderCard t={t} />
                </div>
              )}
            </div>
          )}
        </div>
      );

    case "Assembly":
      return (
        <div
          className="w-full flex h-full justify-center"
          onClick={() => {
            setSelected(i);
          }}
        >
          <AssemblyCard />
        </div>
      );

    case "Internal":
      return <InternalOperationCard />;
    case "External":
      return <ExternalOperationCard />;
    case "Purchase":
      return <OrderPurchaseCard />;

    case "partLine":
      return (
        <div className="w-full h-full flex items-center relative">
          {e.line == "left" ? (
            <div className=" h-[130px] -top-[75px] absolute  w-[2px] bg-secondary-300"></div>
          ) : e?.line == "up" ? (
            <div className=" h-[130px] -top-[75px] left-0 absolute  w-[2px] bg-secondary-300"></div>
          ) : null}

          {e?.line != "up" ? (
            <div className="w-full h-[2px] bg-secondary-300"></div>
          ) : null}
        </div>
      );

    default:
      return <div className="min-h-[116px] max-h-[116px] h-[116px]"></div>;
  }
};

export default Montaj2;
