import moment from "moment";
import React, { memo, useCallback, useState } from "react";
import {
  AddAttachmentIcon,
  AddLabelIcon,
  AddUserIcon,
  CloseIcon,
  PlusIcon,
  PointIcon,
  ThreeDots,
  UsersIcon,
} from "../../../assets/icons/taskCardIcons";
import { authStore } from "../../../stores/auth.store";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import EndDatePicker from "./EndDatePicker";
import UserLine from "./UserLine";
import AttachmentLine from "./AttachmentLine";
import { useDropzone } from "react-dropzone";
import { instance } from "../../../libs/client";
import { Button, CustomModal } from "../../../components";
import SelectTaskUsers from "./SelectTaskUsers";
import { Toast } from "../../../utils/toastify/toast";
import { chatService } from "../../../services/chat.service";
import { useTranslation } from "react-i18next";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { Loader } from "../../../assets/icons/stepsIcons";
import { useQuery } from "@tanstack/react-query";
import { CalendarIcon, CloseIconGray } from "../../../assets/icons/PersonIcons";

const AddTaskCard = ({
  departmentLabels,
  setAddTask,
  groupParticipants,
  groupId,
  refetch,
  departmentId,
}) => {
  const { t } = useTranslation();
  const [upuPoint, setUpuPoint] = useState(100);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [taskName, setTaskName] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedTab, setSelectedTab] = useState("label");
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [participants, setParticipants] = useState([]);
  const handleUser = (user) => {
    if (participants.find((item) => item.id === user.id)) {
      setParticipants(participants.filter((item) => item.id !== user.id));
    } else {
      setParticipants([...participants, user]);
    }
  };

  const { data } = useQuery({
    retry: 0,
    queryKey: ["getDepartmentLabelList"],
    queryFn: async () => await chatService.getLabelList(departmentId),
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      var formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      await instance({
        method: "post",
        url: "file/message/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        handleAttachment({
          name: acceptedFiles[0].path,
          attachmentId: res?.data?.file,
          type: acceptedFiles[0].type,
        });
      });
    },
    [attachments, setAttachments]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const handleLabel = (item) => {
    const filter = labels.filter((u) => {
      return u?.id == item?.id;
    });
    if (filter.length) {
      setLabels(labels.filter((f) => f?.id !== item?.id));
    } else {
      setLabels([...labels, item]);
    }
  };
  const handleAttachment = (item, isDelete) => {
    if (isDelete) {
      const newAttachments = attachments.filter(
        (f) => f.attachmentId !== item.attachmentId
      );
      setAttachments(newAttachments);
    } else {
      setAttachments([...attachments, item]);
    }
  };
  const createTask = async () => {
   Toast("warning", "Demo users cannot create tasks");
  };

  const tabItems = [
    {
      component: (
        <div className="relative flex items-center justify-center gap-x-2">
          <span className="min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px] items-center justify-center">
            <AddLabelIcon />
          </span>

          <span className="text-sm font-semibold">{t("chat:addLabel")}</span>
        </div>
      ),
      value: "label",
      collapsedComponent: (
        <div className="w-full flex flex-wrap content-start gap-2">
          {data?.data?.map((item, index) => {
            const isSelected = labels?.find((f) => f?.id === item.id);
            return (
              <span
                onClick={() => handleLabel(item)}
                // onClick={() => Toast("warning", t("warning", t("alert:warning")))}
                key={index}
                style={{
                  backgroundColor: !isSelected ? "#fff" : item?.color,
                  color: !isSelected ? item?.color : "#fff",
                  border: !isSelected && `1px solid ${item?.color}`,
                }}
                className="pl-1.5 pr-1 py-0.5 flex rounded-md items-center text-xs font-medium cursor-pointer select-none gap-1 max-h-[22px]"
              >
                {item?.name}
                {isSelected ? (
                  <CloseIcon size={16} color={"#fff"} />
                ) : (
                  <PlusIcon size={14} color={item?.color} />
                )}
              </span>
            );
          })}
        </div>
      ),
    },
    {
      component: (
        <div className="relative flex items-center justify-center gap-x-2">
          <AddAttachmentIcon size={24} />

          <span className="text-sm font-semibold">
            {t("personals:addAttachments")}
          </span>
        </div>
      ),
      value: "attachment",
      collapsedComponent: (
        <div className="flex flex-col w-full">
          {attachments?.map((item, index) => {
            return (
              <AttachmentLine
                key={index}
                index={index}
                name={item?.name}
                attachmentId={item?.attachmentId}
                editMode={true}
                onClick={() => {
                  // handleAttachment(item, true);
                }}
              />
            );
          })}

          <div
            {...getRootProps()}
            className="w-full flex items-center mb-0.5 mt-1 px-3 min-h-[28px] py-[10px] border border-dashed h-10 rounded-md cursor-pointer"
          >
            <span className="text-sm text-[#667085] font-normal">
              {t("personals:addAttachments")}
            </span>
            <div className="min-w-[20px] h-5 flex items-center justify-center ml-auto">
              <PlusIcon color={"#98A2B3"} />
            </div>
            <input type="file" {...getInputProps()} />
          </div>
        </div>
      ),
    },
    {
      component: (
        <div className="flex items-center justify-center w-full h-full select-none">
          {participants?.length > 0 ? (
            participants?.length < 4 ? (
              participants?.map((item, index) => {
                return item?.avatar ? (
                  <img
                    src={generateFileUrl(item?.avatar)}
                    className="w-6 h-6 blur-sm rounded-full"
                    style={{ marginLeft: index !== 0 ? -8 : 0 }}
                  />
                ) : (
                  <div style={{ marginLeft: index !== 0 ? -8 : 0 }}>
                    <NoAvatar
                      name={item?.name}
                      lastName={item?.lastName}
                      size={24}
                      fontSize={10}
                    />
                  </div>
                );
              })
            ) : (
              <>
                <div className="w-6 h-6 rounded-full flex items-center justify-center border-[0.5px] border-secondary-300 bg-white">
                  <ThreeDots />
                </div>
                {participants?.map((item, index) => {
                  if (index < 3) {
                    return item?.avatar ? (
                      <img
                        src={generateFileUrl(item?.avatar)}
                        style={{ marginLeft: -8 }}
                        className="w-6 h-6 blur-sm rounded-full"
                      />
                    ) : (
                      <div style={{ marginLeft: -8 }}>
                        <NoAvatar
                          name={item?.name}
                          lastName={item?.lastName}
                          size={24}
                          fontSize={10}
                        />
                      </div>
                    );
                  }
                })}
              </>
            )
          ) : (
            <div className="relative flex items-center justify-center gap-x-2">
              <AddUserIcon />
              <span className="text-sm font-semibold">
                {t("person:addUser")}
              </span>
            </div>
          )}
        </div>
      ),
      value: "user",
      collapsedComponent: (
        <div className="flex flex-col w-full">
          {participants?.map((item, index) => {
            return (
              <UserLine
                key={index}
                name={item?.name}
                index={index}
                lastName={item?.lastName}
                avatar={item?.avatar}
                editMode={true}
                onClick={() => {
                  handleUser(item);
                }}
              />
            );
          })}

          <button
            onClick={() => Toast("warning", t("alert:warning"))}
            className="w-full flex items-center mb-0.5 mt-1 px-3 min-h-[28px] py-[10px] border border-dashed h-10 rounded-md cursor-pointer"
          >
            <p className="text-sm text-[#667085] font-normal">
              {t("person:addUser")}
            </p>
            <div className="min-w-[20px] h-5 flex items-center justify-center ml-auto">
              <PlusIcon color={"#98A2B3"} />
            </div>
          </button>
        </div>
      ),
    },
  ];
  const selectedComponent = tabItems.find((item) => item.value === selectedTab);

  return (
    <>
      <div className="w-full h-fit rounded-lg bg-white shadow border-x border-b border-secondary-200 relative flex flex-col px-3 pt-4 pb-2 gap-y-3">
        <div className="w-full h-2 absolute top-0 left-0 right-0 rounded-t-lg bg-teal-600" />
        <div className="flex w-full justify-between">
          <div className={`flex items-center h-8 w-[84%]`}>
            {/* <div
              onClick={() => setAddTask(false)}
              className={`w-8 h-8 border border-teal-500 hover:bg-teal-50 rounded flex items-center justify-center cursor-pointer group`}
            >
              <CloseIcon />
            </div> */}
            <Button
              colorType={"secondary-gray"}
              iconLeft={<CloseIconGray />}
              size={"sm"}
              onClick={() => setAddTask(false)}
            />

            <div
              className={`w-[78px] ml-3 overflow-hidden h-9 flex items-center border border-[#D0D5DD] dark:border-[#525252] dark:bg-[#292929] rounded-lg bg-white px-3`}
            >
              <span className="pb-1">
                <PointIcon />
              </span>
              <input
                className="h-full w-[60%] p-0 grow ml-1 border-transparent focus:border-transparent focus:outline-0 focus:ring-0 border-none text-[#344054] font-semibold text-sm dark:bg-[#292929] dark:text-[#D6D6D6]"
                type="number"
                value={upuPoint}
                onChange={({ target }) => {
                  const inputValue = target.value;
                  if (inputValue.length > 3) {
                    target.value = inputValue.slice(0, 3);
                  }
                  setUpuPoint(target.value);
                }}
              />
            </div>

            <div className="h-9 ml-3 relative flex flex-col items-center">
              <EndDatePicker
                date={endDate}
                setDate={setEndDate}
                visible={showDatePicker}
                setVisible={setShowDatePicker}
              >
                <div
                  onClick={() => setShowDatePicker(!showDatePicker)}
                  className="w-fit gap-x-1 h-9 border flex items-center justify-between px-2 border-[#D0D5DD] dark:border-[#525252] cursor-pointer rounded-lg"
                >
                  <CalendarIcon />
                  <p className="text-sm font-semibold text-[#344054] dark:text-[#D6D6D6]">
                    {moment(endDate).format("DD.MM.YYYY")}
                  </p>
                </div>
              </EndDatePicker>
            </div>
          </div>

          <div className={`h-8 flex items-center justify-end gap-2`}>
            <div className="text-sm font-semibold text-[#475467] whitespace-nowrap flex items-end truncate">
              {authStore.user?.name} {authStore.user?.lastName}
            </div>
            {authStore.user?.avatar ? (
              <img
                src={generateFileUrl(authStore.user?.avatar)}
                className={`w-8 min-w-[32px] h-8 blur-sm rounded-full border border-secondary-300 dark:border-[#525252]`}
              />
            ) : (
              <NoAvatar
                size={32}
                fontSize={13}
                name={authStore.user?.name}
                lastName={authStore.user?.lastName}
              />
            )}
          </div>
        </div>

        <textarea
          placeholder={t("person:writeTask")}
          type="text"
          rows={taskName?.length / 45 + 1}
          onChange={({ target }) => {
            setTaskName(target.value);
          }}
          value={taskName}
          className="w-full h-10 rounded-lg border shadow-sm focus:outline-0 border-gray-300 px-3 py-2 resize-none text-sm text-secondary-600 focus:ring-0 dark:bg-[#292929] dark:border-[#525252] dark:text-[#FAFAFA]"
        />

        <div className="flex flex-col w-full">
          <div className="w-full items-center flex justify-between border-b border-secondary-200">
            {tabItems.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (item.value === selectedTab) {
                      setSelectedTab(null);
                    } else {
                      setSelectedTab(item.value);
                    }
                  }}
                  className={`w-1/3 ${
                    selectedTab === item.value
                      ? "border-b-[1.5px] border-[#0E9384] text-[#107569]"
                      : "text-[#667085]"
                  } items-center pb-[10px] pt-[2px] w-1/3 flex justify-center h-9 cursor-pointer hover:bg-gray-50 active:bg-gray-100`}
                >
                  {item?.component}
                </div>
              );
            })}
          </div>
          <div className="flex w-full flex-col pt-2.5">
            {selectedComponent && selectedComponent.collapsedComponent}
          </div>
        </div>

        {loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader currentColor="#107569" currentFill="currentFill" />
          </div>
        ) : (
          <div className="w-full flex gap-x-3">
            <Button
              onClick={() => setAddTask(false)}
              size={"md"}
              colorType={"secondary-gray"}
              label={t("buttons:stop")}
            />
            <Button
              // onClick={createTask}
              onClick={() => Toast("warning", t("alert:warning"))}
              size={"md"}
              colorType={"primary-person"}
              label={t("person:createNewTask")}
            />
          </div>
        )}
      </div>

      <CustomModal
        modalTitle={t("chat:selectUser")}
        isOpen={isOpenUserModal}
        setIsOpen={setIsOpenUserModal}
        children={
          <SelectTaskUsers
            handleUser={handleUser}
            selectedUser={participants}
            setIsOpen={setIsOpenUserModal}
            setSelectedUser={setParticipants}
            groupParticipants={groupParticipants}
          />
        }
      />
    </>
  );
};

export default memo(AddTaskCard);
