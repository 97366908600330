import React, { memo, useState } from "react";
import {
  AcceptedIcon,
  ClockAccepted,
  ClockIcons,
  InternalOperationIcon,
  RejectedIcon,
} from "../../../assets/icons/productIcons";
import { useTranslation } from "react-i18next";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";
import { MenuIcon } from "../../../assets/icons/PersonIcons";
import { CustomModal } from "../../../components";
import NewPurchasedRequestForm from "../modals/NewPurchasedRequestForm";
import ExternalInternalRequestForm from "../modals/ExternalInternalRequestForm";
import { DotsVerticalIcon } from "../../../components/junction/ActiveAndPlannedCard";
import { Toast } from "../../../utils/toastify/toast";
import { manipulate } from "../../../utils/manipulate";

const StockInternalOperationCard = ({
  acceptedPiece,
  rejectedPiece,
  targetMinute,
  averageMinute,
  name,
  onClick,
  operations,
  showMenuOptions = true,
  product,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const menuOptions = [
    // { label: t("product:details"), value: "details" },
    {
      label: t("product:purchaseRequestCreation"),
      value: "form",
      icon: <PurchaseIcon />,
    },
  ];

  return (
    <>
      <div
        // onClick={onClick}
        className="bg-white w-[294px] border pl-3 pr-2 py-2 border-secondary-300 rounded-lg  shadow-sm flex flex-col items-start group relative"
      >
        <div className="flex flex-row gap-x-3 w-full items-start">
          <div className="flex flex-col item-start gap-y-2">
            <div className="flex flex-row items-center ">
              <InternalOperationIcon />
              <p className="text-sm font-semibold text-secondary-900 ml-2 w-[140px] truncate">
                {name ? manipulate(name) : "---"}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1 ">
              <ClockIcons />
              <div className="flex flex-row gap-1 items-center">
                <p className="text-xs font-medium text-secondary-700">
                  {legacyFormatSeconds(targetMinute)}
                </p>
                <p className="text-xxs font-normal text-secondary-600">
                  {t("product:targetMin")}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-1">
              <ClockAccepted />
              <div className="flex flex-row gap-1 items-center">
                <p className="text-xs font-medium text-success-600">
                  {legacyFormatSeconds(averageMinute)}
                </p>
                <p className="text-xxs font-normal text-secondary-600">
                  {t("product:minPieceAvg")}
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              // setIsOpen(!isOpen);
              Toast("warning", t("alert:warning"));
            }}
            className={`absolute bottom-1 w-[24px] min-h-[24px] max-h-[24px] min-w-[24px] border rounded bg-white right-1 h-[24px] cursor-pointer hidden ${
              showMenuOptions
                ? "group-hover:flex items-center justify-center"
                : "hidden"
            }`}
          >
            <DotsVerticalIcon />
          </div>
          {isOpen && (
            <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[52px] right-[32px] z-[110] rounded-lg px-[6px] py-1 ">
              {menuOptions?.map((item, index) => {
                return (
                  <button
                    onClick={() => {
                      if (item.value === "details") {
                        onClick();
                      }
                      if (item.value === "form") {
                        setIsPurchased(true);
                      }
                      setIsOpen(false);
                    }}
                    key={index}
                    type="button"
                    className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                      item?.value === "delete"
                        ? "hover:bg-[#FECDCA]"
                        : "hover:bg-secondary-100"
                    }`}
                  >
                    {item?.icon}
                    <p
                      className={`font-medium text-sm  ${
                        item?.value === "delete"
                          ? "text-[#D92D20]"
                          : "text-secondary-700"
                      }`}
                    >
                      {item.label}
                    </p>
                  </button>
                );
              })}
            </div>
          )}
          {/* yeni kalite ui */}

          {/* <div className="flex flex-col border border-secondary-200 bg-white  w-[84px] min-w-[84px]  ml-auto rounded">
            <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
              <QualityIcon />
              <p className="text-secondary-700 font-semibold text-[10px]">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col  items-center justify-center">
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <AcceptedIcon />
                </div>
                <span className="text-xs font-medium text-[#079455]">
                  {acceptedPiece}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <RejectedIcon />
                </div>
                <span className="text-xs font-medium text-[#D92D20]">
                  {rejectedPiece}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div> */}
          <div className="w-[84px] min-w-[84px] border border-secondary-200 px-[6px] pt-[2px] pb-1 flex flex-col rounded-md gap-y-2 ml-auto">
            <div className="flex flex-row justify-center items-center w-full gap-x-1">
              <span className="w-2 h-2 rounded-[2px] bg-[#17B26A]"></span>
              <p className="text-xs font-normal text-secondary-500">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                <AcceptedIcon />
              </div>

              <p className="text-xs font-normal text-success-600">
                {operations?.activePiece || 0}
              </p>
              <p className="text-[10px] font-normal text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                <RejectedIcon />
              </div>
              <p className="text-xs font-normal text-error-600">
                {operations?.rejectedPiece || 0}
              </p>
              <p className="text-[10px] font-normal text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isPurchased}
        setIsOpen={setIsPurchased}
        // onClose={() => {
        //   setIsPurchased(false);
        // }}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <ExternalInternalRequestForm
            setIsOpen={isPurchased}
            closeModal={() => {
              setIsPurchased(false);
            }}
            purchaseData={operations}
            product={product}
          />
        }
      />
    </>
  );
};

export default memo(StockInternalOperationCard);

const QualityIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g mask="url(#mask0_13331_70317)">
        <path
          d="M11.1016 7C11.1016 9.26524 9.26524 11.1016 7 11.1016C4.73476 11.1016 2.89844 9.26524 2.89844 7C2.89844 4.73476 4.73476 2.89844 7 2.89844C9.26524 2.89844 11.1016 4.73476 11.1016 7Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.05078 5.76953L6.58984 8.23047L4.94922 6.58984"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.46805 0.606006C6.77463 0.344873 7.22537 0.344873 7.53194 0.606006L8.59789 1.54677L10.4017 1.40718C10.8032 1.37612 11.1679 1.64105 11.2624 2.0325L11.6241 3.53028L13.2001 4.4737C13.5432 4.68457 13.6825 5.11327 13.5289 5.4855L12.9062 6.99424L13.5289 8.50299C13.6825 8.87525 13.5432 9.30394 13.2001 9.51482L11.6241 10.4582L11.2624 11.956C11.1679 12.3474 10.8032 12.6124 10.4017 12.5813L8.61552 12.4431L7.53194 13.394C7.22537 13.6551 6.77463 13.6551 6.46805 13.394L5.38448 12.4431L3.59827 12.5813C3.19676 12.6124 2.83207 12.3474 2.73757 11.956L2.37448 10.4523L0.801062 9.51482C0.457953 9.30394 0.318664 8.87525 0.472308 8.50299L1.09493 6.99424L0.472308 5.4855C0.318664 5.11327 0.457953 4.68457 0.801062 4.4737L2.23962 3.61907L2.73757 2.0325C2.83207 1.64105 3.19676 1.37612 3.59827 1.40718L5.40214 1.54677L6.46805 0.606006Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export const PurchaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_12446_70067)">
        <path
          d="M1.3335 1.33325H2.20427C2.36828 1.33325 2.45029 1.33325 2.51628 1.36341C2.57444 1.38999 2.62373 1.43274 2.65826 1.48655C2.69745 1.54761 2.70905 1.6288 2.73225 1.79116L3.04778 3.99992M3.04778 3.99992L3.74904 9.15419C3.83803 9.80827 3.88253 10.1353 4.0389 10.3815C4.17668 10.5984 4.37422 10.7709 4.60773 10.8782C4.87274 10.9999 5.20279 10.9999 5.8629 10.9999H11.5682C12.1965 10.9999 12.5107 10.9999 12.7675 10.8869C12.9939 10.7872 13.1881 10.6265 13.3283 10.4227C13.4875 10.1917 13.5462 9.88303 13.6638 9.26576L14.5462 4.63305C14.5876 4.41579 14.6083 4.30716 14.5783 4.22225C14.552 4.14777 14.5001 4.08504 14.4319 4.04526C14.3541 3.99992 14.2435 3.99992 14.0223 3.99992H3.04778ZM6.66683 13.9999C6.66683 14.3681 6.36835 14.6666 6.00016 14.6666C5.63197 14.6666 5.3335 14.3681 5.3335 13.9999C5.3335 13.6317 5.63197 13.3333 6.00016 13.3333C6.36835 13.3333 6.66683 13.6317 6.66683 13.9999ZM12.0002 13.9999C12.0002 14.3681 11.7017 14.6666 11.3335 14.6666C10.9653 14.6666 10.6668 14.3681 10.6668 13.9999C10.6668 13.6317 10.9653 13.3333 11.3335 13.3333C11.7017 13.3333 12.0002 13.6317 12.0002 13.9999Z"
          stroke="#667085"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12446_70067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
