import React, { useEffect, useState } from "react";
import Badgets from "../../../../components/buttons/Badgets";
import CustomModal from "../../../../components/modal/CustomModal";
import SelectUserModal from "../../../../components/modal/SelectUserModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStore } from "../../../../hooks/useStores";
import SingleJunctionCard from "../../../../components/junction/SingleJunctionCard";
import {
  secondToHoursAndMinute,
  formatSeconds,
  secondToDay,
  secondToHoursAndMinute2,
} from "../../../../utils/secondToHoursAndMinute";
import { junctionService } from "../../../../services/junction.service";
import { Toast } from "../../../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";
import ApprovedCard from "../../../../components/junction/ApprovedCard";
import ActiveAndPlannedCard from "../../../../components/junction/ActiveAndPlannedCard";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { CloseIcon } from "../../../../assets/icons/departmentIcon";
import NewApprovedCard from "../../../../components/junction/NewApprovedCard";
import NewActiveAndPlannedCard from "../../../../components/junction/NewActiveAndPlannedCard";
const EquipmentJunctions = ({
  junctionData = [],
  date,
  data,
  PastJunctionData,
  selectedJunction,
  setSelectedJunction,
  activeLength,
  defaultStartTime,
  defaultEndTime,
  //new timeline prop
  refetch,
  setStartTime,
  setEndTime,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useStore();
  const [isOpenSelectProduct, setIsOpenSelectProduct] = useState(false);
  const [selectedJunctionForPlanned, setSelectedJunctionForPlanned] =
    useState(null);

  const _data = junctionData
    ?.sort((f, s) => f?.planningOrder - s?.planningOrder)
    ?.filter((el) => {
      return el?.status === "planned";
    })[0];

  const sortedJunctions = junctionData?.sort(
    (f, s) => moment(f?.startDate).unix() - moment(s?.startDate).unix()
  );

  const activeJunction = junctionData?.find(
    (item) => item?.status === "active"
  );

  const junctionNumber = (item) => {
    const junctions = sortedJunctions?.filter((a) => a.status != "planned");

    const sorted = junctions?.sort(
      (a, b) => moment(a?.startDate).unix() - moment(b?.startDate).unix()
    );

    const data = sorted.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });
    const lastData = data.filter((a) => a != "");
    return lastData[0];
  };

  const activeJunctionLength = junctionData?.filter(
    (item) => item.status != "planned"
  );
  const oneOrderTime = (start, end, count, type) => {
    const diff = moment(end).diff(moment(start), "seconds");
    const oneOrder = diff / count;
    return type === "setup" ? diff : `${oneOrder?.toFixed(0)}`;
  };
  const activeJunctionLengths = activeJunctionLength?.length;

  return (
    <>
      <div className="w-full  px-4 flex items-center my-4 max-h-[110px]">
        <div className="flex flex-col gap-1 w-[70px] min-w-[70px] max-w-[70px]">
          <div className="max-w-[90px] flex h-[22px]">
            <Badgets
              label={activeJunctionLengths + ` ${t("table:piece")}`}
              size={"sm"}
              colorType={"fill-success"}
            />
          </div>

          <p
            className={`text-base font-semibold text-[#079455] dark:text-[#16B364]`}
          >
            {t("embedded:works")}
          </p>
        </div>

        <div className="flex-1 flex items-center ml-4 pb-2 overflow-x-auto ">
          {activeJunction && (
            <div
              onClick={() => {
                if (selectedJunction === activeJunction?.id) {
                } else {
                  const dateToSend = activeJunction?.endData || new Date();
                  PastJunctionData(activeJunction, dateToSend);

                  setStartTime(
                    new Date(
                      timeFormatter(activeJunction?.startDate)?.formatted
                    ).valueOf()
                  );
                  setEndTime(
                    new Date(timeFormatter(moment())?.formatted).valueOf()
                  );
                }
              }}
              className="min-w-[400px] max-w-[400px] mr-4 relative mt-10 cursor-pointer "
            >
              {selectedJunction === activeJunction?.id ? (
                <div
                  onClick={() => {
                    refetch();
                    setSelectedJunction(null);
                  }}
                  className="w-[100px] max-w-[100px] absolute -top-7   z-20 animate-pulse "
                >
                  <Badgets
                    label={t("global:selected")}
                    size={"sm"}
                    colorType={"outline-machine"}
                    iconLeft={<CloseIcon />}
                  />
                </div>
              ) : (
                <div className="w-[100px] max-w-[100px] absolute -top-7   z-20 ">
                  <Badgets
                    label={junctionNumber(activeJunction?.id) + ".Junction"}
                    size={"sm"}
                    colorType={"outline-gray"}
                  />
                </div>
              )}
              <NewActiveAndPlannedCard
                step={activeJunction?.header?.step}
                process={activeJunction?.internalOperation?.process}
                junctionData={activeJunction}
                id={activeJunction?.id}
                junctionNumber={junctionNumber(activeJunction?.id)}
                type={activeJunction?.type}
                status={activeJunction?.status}
                pages={"equipmentDetail"}
                name={activeJunction?.job?.product?.name}
                image={activeJunction?.job?.product?.image}
                user={activeJunction?.user}
                piece={"---"}
                estimatedTime={secondToHoursAndMinute2(
                  activeJunction?.startDate,
                  new Date()
                )}
                refetch={refetch}
              />
              {/* <ActiveAndPlannedCard
                step={activeJunction?.header?.step}
                process={activeJunction?.internalOperation?.process}
                junctionData={activeJunction}
                id={activeJunction?.id}
                junctionNumber={junctionNumber(activeJunction?.id)}
                type={activeJunction?.type}
                status={activeJunction?.status}
                pages={"equipmentDetails"}
                name={activeJunction?.job?.product?.name}
                image={activeJunction?.job?.product?.image}
                user={activeJunction?.user}
                piece={"---"}
                estimatedTime={secondToHoursAndMinute2(
                  activeJunction?.startDate,
                  new Date()
                )}
                refetch={refetch}
              /> */}
            </div>
          )}
          {[...junctionData?.filter((obj) => obj?.status != "planned"), _data]
            ?.sort((a, b) => {
              if (a?.status === "planned" && b?.status !== "planned") {
                return 1;
              } else if (a?.status !== "planned" && b?.status === "planned") {
                return -1;
              } else {
                return 0;
              }
            })

            ?.map((item, index) => {
              if (
                moment(date).format("DD-MM-YYYY") ==
                moment(new Date()).format("DD-MM-YYYY")
              ) {
                if (item?.status === "completed") {
                  if (item?.station) {
                    return (
                      <div
                        onClick={() => {
                          if (selectedJunction === item?.id) {
                            setSelectedJunction(!selectedJunction);
                            setStartTime(defaultStartTime);
                            setEndTime(defaultEndTime);
                            // refetch();
                          } else {
                            PastJunctionData(item);
                            setStartTime(
                              new Date(
                                timeFormatter(item?.startDate).formatted
                              ).valueOf()
                            );
                            setEndTime(
                              new Date(
                                timeFormatter(item?.endDate).formatted
                              ).valueOf()
                            );
                          }
                        }}
                        className="min-w-[400px] max-w-[400px] mr-4 flex flex-col gap-y-4 relative mt-10 cursor-pointer "
                      >
                        {selectedJunction === item?.id ? (
                          <div className="w-[100px] max-w-[100px] absolute -top-7   z-20 animate-pulse  ">
                            <Badgets
                              label={t("global:selected")}
                              size={"sm"}
                              colorType={"outline-machine"}
                              iconLeft={<CloseIcon />}
                            />
                          </div>
                        ) : (
                          <div className="w-[100px] max-w-[100px] absolute -top-7   z-20 ">
                            <Badgets
                              label={junctionNumber(item?.id) + ".Junction"}
                              size={"sm"}
                              colorType={"outline-gray"}
                            />
                          </div>
                        )}
                        <NewApprovedCard
                          step={item?.header?.step}
                          allData={item}
                          key={index}
                          type={item?.type}
                          piece={item?.metadata?.madeWorkCount}
                          pages={"equipmentDetail"}
                          refetch={refetch}
                          id={item?.id}
                          // estimatedOperationTime={operation?.estimatedTime}
                          totalJunctionTime={moment(item?.startDate).format(
                            "HH:mm"
                          )}
                          totalEndTime={moment(item?.endDate).format("HH:mm")}
                          equipmentName={item?.job?.product?.name}
                          equipmentImage={item?.job?.product?.image}
                          qualityAcceptedPiece={item?.metadata?.madeWorkCount}
                          startDate={item?.startDate}
                          // estimatedOperationTime={item?.metadata?.estimatedTime}
                          estimatedOperationTime={
                            item.type === "setup"
                              ? item.metadata.estimatedTime
                              : (item?.metadata?.estimatedTime / 60).toFixed(2)
                          }
                          estimatedTime={oneOrderTime(
                            item?.startDate,
                            item?.endDate,
                            item?.metadata?.acceptedWorkCount,
                            item.type
                          )}
                          qualityRejectedPiece={
                            item?.metadata?.rejectedWorkCount
                          }
                          oneOrderTime={formatSeconds(
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount || 0
                            ).toFixed(0)
                          )}
                          user={item?.user}
                          status={item?.status}
                          // totalJunctionTime={secondToHoursAndMinute2(
                          //   item?.startDate,
                          //   item?.endDate
                          // )}
                          qualityPercent={item?.metadata?.qualityPercent}
                          estimatedTrue={
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount
                            ).toFixed(0) <
                            item?.operations?.internalOperation?.estimatedTime
                              ? true
                              : false
                          }
                        />
                        {/* <ApprovedCard
                          step={item?.header?.step}
                          allData={item}
                          type={item?.type}
                          key={index}
                          pages={"equipmentDetail"}
                          id={item?.id}
                          refetch={refetch}
                          equipmentName={item?.job?.product?.name}
                          equipmentImage={item?.job?.product?.image}
                          qualityAcceptedPiece={item?.metadata?.madeWorkCount}
                          qualityRejectedPiece={
                            item?.metadata?.rejectedWorkCount
                          }
                          startDate={item?.startDate}
                          endDate={moment(item?.endDate).format("YYYY-MM-DD")}
                          oneOrderTime={formatSeconds(
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount || 0
                            ).toFixed(0)
                          )}
                          user={item?.user}
                          status={item?.status}
                          totalJunctionTime={secondToHoursAndMinute2(
                            item?.startDate,
                            item?.endDate
                          )}
                          qualityPercent={item?.metadata?.qualityPercent}
                          estimatedTrue={
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount
                            ).toFixed(0) <
                            item?.operations?.internalOperation?.estimatedTime
                              ? true
                              : false
                          }
                        /> */}
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                } else if (item?.status === "planned") {
                  if (item?.station) {
                    return (
                      <div
                        className="group min-w-[300px] max-w-[300px] mr-4 relative pb-4  mt-10"
                        key={index}
                      >
                        <div
                          onClick={() => {
                            if (item?.internalOperation) {
                              navigate(
                                `/app/product/internal-operation/detail/${item?.internalOperation?.id}`
                              );
                            }
                          }}
                          className="absolute -top-8 z-20 right-0 cursor-pointer"
                        >
                          <Badgets
                            label={t("embedded:viewAll")}
                            size={"sm"}
                            colorType={"outline-warning"}
                          />
                        </div>
                        <SingleJunctionCard
                          step={item?.header?.step}
                          id={item?.id}
                          t={t}
                          junctionData={item}
                          props={{
                            status: item?.status,
                            leftImage: item?.job?.product?.image,
                            texts: {
                              second: item?.job?.product?.code,
                              first: item?.job?.product?.name,
                              piece: item?.job?.piece,
                              time: secondToDay(
                                item?.header?.totalEstimatedTime
                              ),
                            },
                            rightButton: () => {
                              setSelectedJunctionForPlanned(item);
                              setIsOpenSelectProduct(true);
                            },
                          }}
                        />
                        <button
                          // onClick={async () => {
                          //   await junctionService
                          //     .deleteStationForJunction(item?.id)
                          //     .then((res) => {
                          //       if (res.data.code == 0) {
                          //         Toast("success", "Junction deleted");
                          //         refetch();
                          //       } else {
                          //         Toast("error", "Junction not deleted");
                          //       }
                          //     });
                          // }}
                          onClick={() =>
                            Toast(
                              "warning",
                              "Demo users are not authorized, contact administration."
                            )
                          }
                          type="button"
                          className="w-8 h-8 rounded-lg bg-white border border-[#FDA29B] hidden group-hover:flex items-center justify-center absolute -top-2 right-0"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
                              stroke="#B42318"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  }
                }
              } else {
                // const test = item?.endDate || item?.startDate ? SecondToHoursAndMinute(
                //   moment(item?.endDate ? item?.endDate : new Date())?.unix() - moment(item?.startDate ? item?.startDate : new Date())?.unix()
                // ) : 0;

                return (
                  item?.status === "completed" && (
                    <div
                      onClick={() => {
                        if (selectedJunction === item?.id) {
                          setSelectedJunction(!selectedJunction);
                          setStartTime(defaultStartTime);
                          setEndTime(defaultEndTime);
                        } else {
                          PastJunctionData(item);
                        }
                      }}
                      className="min-w-[400px] max-w-[400px] mr-4 relative  mt-10 pb-4 cursor-pointer "
                    >
                      {selectedJunction === item?.id ? (
                        <div className="w-[100px] max-w-[100px] absolute -top-7   z-20 animate-pulse ">
                          <Badgets
                            label={t("global:selected")}
                            size={"sm"}
                            colorType={"outline-machine"}
                            iconLeft={<CloseIcon />}
                          />
                        </div>
                      ) : (
                        <div className="w-[100px] max-w-[100px] absolute -top-7   z-20 ">
                          <Badgets
                            label={junctionNumber(item?.id) + ".Junction"}
                            size={"sm"}
                            colorType={"outline-gray"}
                          />
                        </div>
                      )}

                      <div
                        className="flex flex-col gap-y-8 mt-4"
                        onClick={() => {
                          {
                            setStartTime(
                              new Date(
                                timeFormatter(item?.startDate).formatted
                              ).valueOf()
                            );
                            setEndTime(
                              new Date(
                                timeFormatter(item?.endDate).formatted
                              ).valueOf()
                            );
                          }
                        }}
                      >
                        <NewApprovedCard
                          step={item?.header?.step}
                          allData={item}
                          key={index}
                          type={item?.type}
                          piece={item?.metadata?.madeWorkCount}
                          pages={"equipmentDetail"}
                          refetch={refetch}
                          id={item?.id}
                          totalJunctionTime={moment(item?.startDate).format(
                            "HH:mm"
                          )}
                          totalEndTime={moment(item?.endDate).format("HH:mm")}
                          equipmentName={item?.job?.product?.name}
                          equipmentImage={item?.job?.product?.image}
                          qualityAcceptedPiece={item?.metadata?.madeWorkCount}
                          startDate={item?.startDate}
                          qualityRejectedPiece={
                            item?.metadata?.rejectedWorkCount
                          }
                          estimatedTime={oneOrderTime(
                            item?.startDate,
                            item?.endDate,
                            item?.metadata?.acceptedWorkCount,
                            item.type
                          )}
                          oneOrderTime={formatSeconds(
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount || 0
                            ).toFixed(0)
                          )}
                          user={item?.user}
                          status={item?.status}
                          // totalJunctionTime={secondToHoursAndMinute2(
                          //   item?.startDate,
                          //   item?.endDate
                          // )}
                          qualityPercent={item?.metadata?.qualityPercent}
                          estimatedTrue={
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount
                            ).toFixed(0) <
                            item?.operations?.internalOperation?.estimatedTime
                              ? true
                              : false
                          }
                        />
                        {/* <ApprovedCard
                          step={item?.header?.step}
                          allData={item}
                          key={index}
                          type={item?.type}
                          pages={"equipmentDetail"}
                          refetch={refetch}
                          id={item?.id}
                          equipmentName={item?.job?.product?.name}
                          equipmentImage={item?.job?.product?.image}
                          qualityAcceptedPiece={item?.metadata?.madeWorkCount}
                          startDate={item?.startDate}
                          qualityRejectedPiece={
                            item?.metadata?.rejectedWorkCount
                          }
                          oneOrderTime={formatSeconds(
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount || 0
                            ).toFixed(0)
                          )}
                          user={item?.user}
                          status={item?.status}
                          totalJunctionTime={secondToHoursAndMinute2(
                            item?.startDate,
                            item?.endDate
                          )}
                          qualityPercent={item?.metadata?.qualityPercent}
                          estimatedTrue={
                            (
                              moment(item?.endDate).diff(
                                item?.startDate,
                                "seconds"
                              ) / item?.metadata?.madeWorkCount
                            ).toFixed(0) <
                            item?.operations?.internalOperation?.estimatedTime
                              ? true
                              : false
                          }
                        /> */}
                      </div>
                    </div>
                  )
                );
              }
            })}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenSelectProduct}
        setIsOpen={setIsOpenSelectProduct}
        modalTitle={t("chat:startJob")}
        subTitle={t("product:junctionSubtitile")}
        isBadges={false}
        // badgesValue={activeLength + "/" + auth?.user?.company?.limits?.junction}
        badgesColor={"fill-primary"}
        badgesSize={"md"}
        children={
          <SelectUserModal
            selectedJunction={selectedJunctionForPlanned}
            setIsOpen={setIsOpenSelectProduct}
            handleData={refetch}
          />
        }
      />
    </>
  );
};

export default EquipmentJunctions;
