import React from "react";
import generateFileUrl from "../utils/generateFileUrl";

const CustomImage = ({ style, containerStyle, src, onClick, borderRadius, ...rest }) => {
  return (
    <div className={`${onClick && "cursor-pointer"}`} onClick={onClick} style={containerStyle}>
      <img
        className="object-cover"
        style={{ borderRadius: borderRadius || 30, ...style }}
        src={generateFileUrl(src)}
        {...rest}
      />
    </div>
  );
};

export default CustomImage;
