import React, { useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../../components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
} from "../../../assets/icons/PersonIcons";
import EndDatePicker from "../../person/components/EndDatePicker";
import { useTranslation } from "react-i18next";

const PersonelDateSelector = ({ date, setDate, loading, dates }) => {
  return (
    <div className="w-full h-full ">
      <DateSelect
        date={date}
        setDate={setDate}
        loading={loading}
        dates={dates}
      />
    </div>
  );
};

export default PersonelDateSelector;

const DateSelect = React.memo(({ date, setDate, loading, dates }) => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <div className="w-full justify-between flex items-center gap-3 ">
      {loading ? (
        <Skeleton width={120} height={36} borderRadius={8} />
      ) : (
        <Button
          iconLeft={<ArrowLeftIcon />}
          width={140}
          label={t("buttons:previous")}
          size={"sm"}
          colorType={"secondary-gray"}
          onClick={() => {
            setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"));
          }}
        />
      )}
      {loading ? (
        <Skeleton width={140} height={36} borderRadius={8} />
      ) : (
        <EndDatePicker
          date={date}
          visible={visible}
          setDate={setDate}
          setVisible={setVisible}
          buttonClassName={"flex flex-col items-center"}
          calendarClassName={
            "absolute top-12 right-0 rounded-lg shadow-xl z-[600]"
          }
          visibleFooter
        >
          <Button
            onClick={() => {
              setVisible(!visible);
            }}
            iconLeft={<CalendarIcon />}
            size={"sm"}
            colorType={"secondary-gray"}
            label={moment(dates ? dates : date).format("DD.MM.YYYY")}
          />
        </EndDatePicker>
      )}
      {loading ? (
        <Skeleton width={140} height={36} borderRadius={8} />
      ) : (
        <Button
          width={120}
          iconLeft={<ArrowRightIcon />}
          size={"sm"}
          label={t("buttons:next")}
          colorType={"secondary-gray"}
          onClick={() => {
            setDate(moment(date).subtract(-1, "days").format("YYYY-MM-DD"));
          }}
        />
      )}
    </div>
  );
});
