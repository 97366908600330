import React, { memo } from "react";
import Modal from "react-modal";
import CloseButton from "../buttons/CloseButton";
import { settingsStore } from "../../stores/settings.store";
import Badgets from "../buttons/Badgets";
import Button from "../buttons/Button";
import { useNavigate } from "react-router-dom";

const CustomModal = ({
  children,
  isOpen,
  setIsOpen,
  modalTitle,
  width,
  onClose,
  height,
  isPreventShutdown = false,
  isBadges = false,
  badgesValue = "",
  badgesColor = "fill-warning",
  badgesSize = "md",
  subTitle,
  navigateButton,
  navigateOnClick,
}) => {
  // function openModal() {
  //   setIsOpen(true);
  // }

  const navigate = useNavigate();

  function closeModal() {
    if (!isPreventShutdown) {
      setIsOpen(false);
    }
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 24,
      zIndex: 99999,
      borderRadius: "12px",
      width: width || "fit-content",
      height: height || "auto",
      backgroundColor: settingsStore.theme === "light" ? "#FFF" : "#0F0F0F",
      borderColor: settingsStore.theme === "light" ? "#FFF" : "#424242",
      overflow: "hidden",
    },
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose ? onClose : closeModal}
        style={customStyles}
        overlayClassName="fixed inset-0 flex items-center justify-center bg-[#0F0F0F] bg-opacity-50 backdrop-blur-[4px] z-[999]"
      >
        <div className="w-full h-full relative flex flex-col ">
          <div
            className={`flex flex-row relative items-center w-full ${
              isBadges && "gap-x-4"
            }`}
          >
            {subTitle ? (
              <div className="flex flex-col gap-y-1">
                <p className="text-xl font-semibold text-secondary-900 dark:text-[#FAFAFA]">
                  {modalTitle}
                </p>
                <p className="text-[#475467] text-sm font-normal">{subTitle}</p>
              </div>
            ) : (
              <>
                {navigateButton === true ? (
                  <div className="flex flex-row items-center gap-x-1">
                    <Button
                      iconLeft={<ArrowLeft />}
                      colorType={"tertiary-gray"}
                      onClick={navigateOnClick}
                    />
                    <p className="text-xl font-semibold text-secondary-900 dark:text-[#FAFAFA]">
                      {modalTitle}
                    </p>
                  </div>
                ) : (
                  <p className="text-xl font-semibold text-secondary-900 dark:text-[#FAFAFA]">
                    {modalTitle}
                  </p>
                )}
              </>
            )}

            {isBadges && (
              <Badgets
                size={badgesSize}
                label={badgesValue}
                colorType={badgesColor}
              />
            )}
          </div>

          <div className="w-full h-full"> {children}</div>
          {!isPreventShutdown && (
            <div className="absolute -right-4 -top-4">
              <CloseButton
                onClick={onClose ? onClose : closeModal}
                size={"lg"}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const ArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.8334 10H4.16675M4.16675 10L10.0001 15.8333M4.16675 10L10.0001 4.16667"
        stroke="#475467"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default memo(CustomModal);
