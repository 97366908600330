import React, { useEffect, useState } from "react";
import SearchInput from "../inputs/SearchInput";
import generateFileUrl from "../../utils/generateFileUrl";
import { instance } from "../../libs/client";
import diacritics from "diacritics";
import Junction from "../junction/Junction";
import { useStore } from "../../hooks/useStores";
import Input from "../inputs/Input";
import Button from "../buttons/Button";
import { orderService } from "../../services/order.service";
import { Toast } from "../../utils/toastify/toast";
import { observer } from "mobx-react-lite";
import { Loader } from "../../assets/icons/stepsIcons";

const FinishOperationSelectNode = ({
  onClose,
  processId,
  currentOperation,
  orderData,
  refetch,
  isLoading,
  isFetching,
}) => {
  const { auth } = useStore();
  const [selected, setSelected] = useState(null);
  const [number, setNumber] = useState(0);
  const [piece, setPiece] = useState(0);

  const finishThisOperation = async () => {
    const data = {
      piece,
      nodeId: currentOperation?.internalOperation
        ? currentOperation?.internalOperation?.id
        : null,
      supplierId: currentOperation?.externalOperation
        ? currentOperation?.externalOperation?.id
        : null,
    };
    if (data.piece < number) {
      Toast("info", `Lütfen ${number} veya daha fazla adet giriniz.`);
    } else {
      await orderService
        .finishOperation(currentOperation?.id, data)
        .then((res) => {
          if (res?.data?.code === 0) {
            Toast("success", res?.data?.message);
            onClose();
            refetch();
          } else {
            Toast("error", res?.data?.message);
            onClose();
            refetch();
          }
        });
    }
  };

  const handleRemainderPiece = async () => {
    const { data } = await orderService.getRemainderPiece(currentOperation?.id);
    setPiece(data);
    setNumber(data);
  };

  useEffect(() => {
    handleRemainderPiece();
  }, []);
  return (
    <div className="w-[400px] flex flex-col mt-3">
      <div className="w-full flex flex-col">
        <Junction
          props={{
            user: {
              avatar: auth.user.avatar,
              name: auth?.user?.name,
              lastName: auth?.user?.lastName,
            },
            node: {
              name: "No Equipment",
              image: selected?.metadata?.image,
              process:
                currentOperation?.internalOperation?.process?.name ||
                currentOperation?.externalOperation?.process?.name,
            },
            product: {
              image: orderData?.product?.image,
              name: orderData?.product?.name,
              code: orderData?.orderNo,
              piece: piece,
            },
          }}
        />
        <div className="mt-4 w-full">
          <Input
            min={String(piece)}
            theme={"product"}
            label={"Quantity"}
            type={"number"}
            disabled={piece === number ? true : false}
            placeholder={String(piece)}
            value={piece}
            onChange={(event) => {
              setPiece(event.target.value);
            }}
          />
        </div>
        <div className="w-full flex items-center gap-4 mt-5">
          <Button
            label={"Cancel"}
            colorType={"secondary-gray"}
            size={"md"}
            onClick={onClose}
          />
          {isLoading || isFetching ? (
            <div className="flex items-center justify-center bg-[#DC6803]  rounded-lg w-full h-[46px] max-h-[46px]">
              <Loader currentColor="#DC6803" currentFill="currentFill" />
            </div>
          ) : (
            <Button
              label={"Finish"}
              colorType={"primary-error"}
              size={"md"}
              onClick={finishThisOperation}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default FinishOperationSelectNode;
