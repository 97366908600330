import React from "react";

const TaskFilterBar = ({
  value,
  setValue,
  done,
  todo,
  approved,
  expired,
  total,
  pages,
}) => {
  const segments = [
    { name: "Total", number: total, color: "#6941C6", key: "all" },
    { name: "Todo", number: todo, color: "#475467", key: "todo" },
    { name: "Done", number: done, color: "#CA8504", key: "done" },
    {
      name: pages === "myTask" ? "Appro.." : "Approved",
      number: approved,
      color: "#039855",
      key: "approved",
    },
    { name: "Expired", number: expired, color: "#D92D20", key: "expired" },
  ];
  return (
    <div className="w-full flex items-center justify-between gap-x-3.5">
      {segments.map((item, index) => {
        return (
          <div
            onClick={() => {
              setValue(item?.key);
            }}
            key={index}
            style={{
              background: value !== item?.key ? "#fff" : item?.color,
              borderTopColor: item?.color,
              borderColor: value === item?.key ? item?.color : "#EAECF0",
            }}
            className="flex-1 h-[48px] rounded border-x border-b flex flex-col items-center p-1 overflow-hidden relative cursor-pointer select-none shadow-sm"
          >
            <div
              style={{ backgroundColor: item?.color }}
              className="h-1 absolute top-0 -left-px -right-px"
            />
            <p
              style={{ color: value === item?.key ? "#fff" : item?.color }}
              className="text-sm font-semibold mt-0.5"
            >
              {item?.number || "0"}
            </p>

            <span
              style={{ color: value === item?.key ? "#fff" : item?.color }}
              className="text-xs font-semibold"
            >
              {item?.name}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default TaskFilterBar;
