import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { Button, Loading } from "../../../components";
import { personalService } from "../../../services/personal.service";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import {
  ChevronDown,
  CheckIcon,
  TaskAdminGray,
  TaskAdminGreen,
} from "../../../assets/icons/modalIcons";
import { useStore } from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";
import Badgets from "../../../components/buttons/Badgets";
import { Loader } from "../../../assets/icons/stepsIcons";
import diacritics from "diacritics";

const GroupMembers = ({
  triggerAdmin,
  triggerUser,
  auth,
  chat,
  t,
  adminUser,
  selectAllPress,
  selectedUsers,
  data,
  setSelectedTab,
  loading,
  loadings,
  onSubmit,
  selectedDepartments,
}) => {
  const PersonalItem = observer(({ item, isAdmin, isSelected, chat }) => {
    return (
      <div className="flex flex-col items-start gap-4 select-none">
        <div
          onClick={() => {
            isSelected ? triggerAdmin(item) : triggerUser(item);
            chat?.setCurrentChatUser(
              `${item?.name} ${item?.lastName}`,
              item?.avatar
            );
          }}
          className="flex flex-row items-center justify-center gap-3 p-0 w-full h-16"
        >
          <div className="w-full flex flex-row items-center gap-3 p-0 h-full">
            {isSelected ? (
              <div className="flex flex-row items-center gap-3 w-full justify-between">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(
                      `${item?.name} ${item?.lastName}`,
                      item?.avatar
                    );
                  }}
                  className={`cursor-pointer flex w-4 h-4 rounded items-center justify-center bg-[#0E9384] text-[#FFF]`}
                >
                  <CheckIcon />
                </div>
                <div className="flex flex-row items-center gap-3">
                  <div>
                    {item.avatar ? (
                      <img
                        src={generateFileUrl(item.avatar)}
                        className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]"
                      />
                    ) : (
                      <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                        {item?.name?.charAt(0).toUpperCase() +
                          "" +
                          item?.lastName?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                    <p className="text-xs font-normal text-[#344054]">
                      {item?.metadata?.department?.name}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    triggerAdmin(item);
                  }}
                  className="cursor-pointer flex items-center justify-center ml-auto w-6 h-6"
                >
                  {isAdmin ? <TaskAdminGreen /> : <TaskAdminGray />}
                </div>
              </div>
            ) : (
              <div className="w-full flex items-center gap-3">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(
                      `${item?.name} ${item?.lastName}`,
                      item?.avatar
                    );
                  }}
                  className="cursor-pointer w-4 h-4 border border-[#D0D5DD] rounded items-center justify-center bg-[#fff]"
                ></div>
                <div className="flex flex-row items-center gap-3">
                  <div>
                    {item?.avatar ? (
                      <img
                        src={generateFileUrl(item?.avatar)}
                        className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]"
                      />
                    ) : (
                      <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                        {item?.name?.charAt(0).toUpperCase() +
                          "" +
                          item?.lastName?.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                    <p className="text-xs font-normal text-[#344054]">
                      {item?.metadata?.department?.name}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="min-w-[700px] max-w-[700px] mt-6">
      {loadings ? (
        <div className="min-h-[382px] max-h-[382px] w-full h-full flex items-center justify-center">
          <Loading primary={"#15B79E"} secondary={"#fff"} size={32} />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <SearchInput
            setSearchVal={setSearchValue}
            placeholder={t("chat:search")}
          />
          <div>
            <div className="FORM w-full flex flex-col gap-6">
              <div className="w-full flex">
                <div className="max-w-full h-[244px] overflow-y-scroll min-w-full scrollbar-hide flex flex-col">
                  {data?.map((item, index) => {
                    if (item?.users?.length > 0) {
                      return (
                        <ExpandedCard
                          searchValue={searchValue}
                          t={t}
                          item={item}
                          index={index}
                          key={index}
                          chat={chat}
                          auth={auth}
                          PersonalItem={PersonalItem}
                          selectedUsers={selectedUsers}
                          triggerUser={triggerUser}
                          adminUser={adminUser}
                          selectAllPress={selectAllPress}
                          triggerAdmin={triggerAdmin}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <div className="flex gap-3">
                <Button
                  colorType="secondary-gray"
                  label={t("buttons:prev")}
                  onClick={() => {
                    setSelectedTab("info");
                  }}
                />
                {loading ? (
                  <div className="flex items-center justify-center bg-[#0E9384]  rounded-lg w-full h-[46px] max-h-[46px]">
                    <Loader currentColor="#0E9384" currentFill="currentFill" />
                  </div>
                ) : (
                  <Button
                    colorType="primary-person"
                    type="submit"
                    label={t("buttons:save")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ExpandedCard = ({
  t,
  item,
  index,
  auth,
  chat,
  selectedUsers,
  selectedDepartment,
  PersonalItem,
  selectAllPress,
  adminUser,
  triggerUser,
  triggerAdmin,
  user,
  searchValue,
}) => {
  const [expanded, setExpanded] = useState("");

  useEffect(() => {
    if (selectedDepartment) {
      setExpanded(selectedDepartment);
    }
  }, [selectedDepartment]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    if (selectedDepartment) {
      setSelectAllChecked(item.departmentId === selectedDepartment);
    }
  }, [selectedDepartment]);

  // const handleSelectAllClick = (e) => {
  //   e.stopPropagation();
  //   // setSelectAllChecked(!allUsersSelected);
  //   selectAllPress(item?.users);
  // };
  // const allUsersSelected =
  //   selectedUsers &&
  //   item?.users &&
  //   selectedUsers.length === item.users.length &&
  //   selectedUsers.every((selectedUser) =>
  //     item.users.some((departmentUser) => departmentUser.id === selectedUser.id)
  //   );

  // const allUsersSelected =
  //   // selectedUsers &&
  //   // item?.users &&
  //   selectedUsers.length > 0 && // Ek bir kontrol eklendi
  //   selectedUsers.every((selectedUser) =>
  //     item.users.some((departmentUser) => departmentUser.id === selectedUser.id)
  //   );

  const filteredUsers = item?.users?.filter((user) =>
    diacritics.remove(`${user?.name} ${user?.lastName}`)
      .toLowerCase()
      .includes(diacritics.remove(searchValue).toLowerCase())
  );

  return (
    <>
      <div
        key={index}
        onClick={() => {
          if (item?.departmentId === expanded) {
            setExpanded("");
          } else {
            setExpanded(item.departmentId);
          }
        }}
        className="flex items-center w-full justify-between cursor-pointer border-b pb-2 mt-3"
      >
        <div className="flex items-center gap-2">
          <p className="text-base font-semibold text-[#101828]">
            {item.departmentId === "BLANK_DEPARTMENT"
              ? "Diğer"
              : item?.departmentName}
          </p>
          <Badgets
            colorType={"fill-success"}
            label={filteredUsers?.length || 0}
          />
        </div>
        <div className="ml-auto gap-4 flex items-center justify-center z-20 cursor-pointer">
          <div
            className="ml-auto flex gap-3 items-center justify-center text-sm font-normal cursor-pointer select-none text-[#667085] py-2"
            onClick={(e) => {
              e.stopPropagation();
              selectAllPress(item?.users);
            }}
          >
            {/* {allUsersSelected ? (
              <>
                <div
                  className={`cursor-pointer w-4 h-4 flex rounded items-center justify-center bg-[#0E9384] text-[#FFFFFF]`}
                >
                  <CheckIcon />
                </div>
              </>
            ) : (
              <>
                <div
                  className={`cursor-pointer w-4 h-4 border border-solid border-[#D0D5DD] rounded items-center justify-center `}
                ></div>
              </>
            )} */}

            <p className="whitespace-nowrap">{t("buttons:selectAll")}</p>
          </div>
          <Button
            colorType={"tertiary-gray"}
            size={"md"}
            iconLeft={
              expanded === item?.departmentId ? (
                <ChevronDown className="rotate-180" />
              ) : (
                <ChevronDown />
              )
            }
          />
        </div>
      </div>
      {expanded === item?.departmentId && (
        <div className="flex flex-wrap">
          {filteredUsers?.map((user, index) => {
            return (
              user?.id !== auth?.user?.id && (
                <div
                  key={index}
                  className={`w-${
                    filteredUsers.length === 1 ? "full" : "1/2"
                  } p-2`}
                >
                  <PersonalItem
                    chat={chat}
                    item={user}
                    index={index}
                    isSelected={
                      selectedUsers?.find((a) => a?.id == user?.id)
                        ? true
                        : false
                    }
                    triggerUser={triggerUser}
                    isAdmin={
                      adminUser?.find((a) => a?.id == user?.id) ? true : false
                    }
                    triggerAdmin={triggerAdmin}
                  />
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default GroupMembers;
