import React, { useEffect, useState } from "react";
import { authStore } from "../../../stores/auth.store";
import { useStore } from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";
import { chatService } from "../../../services/chat.service";
import { Loader } from "../../../assets/icons/stepsIcons";
import SearchInput from "../../../components/inputs/SearchInput";
import { personalService } from "../../../services/personal.service";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { TableArrowLeft } from "../../../assets/icons/machineIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { ChevronDown } from "../../../assets/icons/modalIcons";
import { Button } from "../../../components";
import Badgets from "../../../components/buttons/Badgets";
import diacritics from "diacritics";

const SelectTaskUserModal = observer(
  ({ closeModal, selectedUser, setSelectedUser }) => {
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState("");
    const [departmans, setDepartmans] = useState([]);
    const [tempDepartmans, setTempDepartmans] = useState([]);
    const [loading, setloading] = useState(false);
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const getDepartments = async () => {
      setloading(true);
      await personalService
        .getDepartmentToUser()
        .then((res) => {
          setDepartmans(res.data);
          setTempDepartmans(res.data);
          setloading(false);
        })
        .catch((err) => {
          setDepartmans([]);
        });
      setloading(false);
    };

    const queries = useQuery({
      retry: 0,
      queryKey: ["getAllMyTask"],
      queryFn: async () => await chatService.getAllMyTAsks(selectedUser.id),
    });

    useEffect(() => {
      queries.refetch();
    }, [selectedUser, setSelectedUser]);

    useEffect(() => {
      getDepartments();
    }, []);

    useEffect(() => {
      if (searchVal) {
        const mapper = tempDepartmans.map((departmans) => {
          return {
            ...departmans,
            users: departmans?.users?.filter((user) => {
              let name = `${user?.name} ${user?.lastName}`;
              return diacritics
                .remove(name)
                .replace(/^\./g, "")
                .toLowerCase()
                .includes(diacritics.remove(searchVal).toLowerCase());
            }),
          };
        });
        setDepartmans(mapper);
      } else {
        setDepartmans(tempDepartmans);
      }
    }, [searchVal, setSearchVal]);

    return (
      <div className="w-[400px] h-[600px] overflow-y-scroll scrollbar-hide flex flex-col pb-5 ">
        {loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader currentColor="#0E9384" currentFill="currentFill" />
          </div>
        ) : (
          <>
            <div className="w-full pt-5 pb-2">
              <SearchInput
                placeholder={t("chat:search")}
                setSearchVal={setSearchVal}
              />
            </div>
            <div className="max-w-full h-full overflow-y-scroll min-w-full scrollbar-hide flex flex-col">
              {departmans?.map((item, index) => {
                if (item?.users?.length > 0)
                  return (
                    <ExpandedCard
                      t={t}
                      item={item}
                      index={index}
                      key={index}
                      selectedDepartment={selectedDepartments}
                      setSelectedUser={setSelectedUser}
                      PersonalItem={PersonalItem}
                      searchVal={searchVal}
                      setSearchVal={setSearchVal}
                      closeModal={closeModal}
                    />
                  );
              })}
            </div>
          </>
        )}
      </div>
    );
  }
);

const ExpandedCard = ({
  item,
  index,
  selectedDepartment,
  auth,
  triggerUser,
  selectedUsers,
  chat,
  adminUser,
  PersonalItem,
  setSelectedUser,
  searchVal,
  setSearchVal,
  closeModal,
}) => {
  const [expanded, setExpanded] = useState("");
  useEffect(() => {
    if (selectedDepartment) {
      setExpanded(selectedDepartment);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (searchVal) {
      setExpanded(item?.departmentId);
    }
  }, [searchVal, setSearchVal]);
  return (
    <>
      <div
        key={index}
        onClick={() => {
          if (item?.departmentId === expanded) {
            setExpanded("");
          } else {
            setExpanded(item?.departmentId);
          }
        }}
        className="flex items-center w-full justify-between cursor-pointer border-b pb-2 mt-3"
      >
        <div className="flex items-center">
          <p className="text-base font-semibold text-[#101828]">
            {item.departmentId === "BLANK_DEPARTMENT"
              ? "Diger"
              : item?.departmentName}
          </p>

          <div className="ml-2">
            <Badgets
              colorType={"fill-success"}
              label={item?.users?.length || 0}
            />
          </div>
        </div>

        <Button
          colorType={"tertiary-gray"}
          size={"md"}
          iconLeft={
            expanded === item?.departmentId ? (
              <ChevronDown className="rotate-180" />
            ) : (
              <ChevronDown />
            )
          }
        />
      </div>
      {expanded === item.departmentId && (
        <div className="flex flex-col w-full">
          {item?.users?.map((user, index) => {
            return (
              user?.id != auth?.user?.id && (
                <PersonalItem
                  key={index}
                  chat={chat}
                  item={user}
                  triggerUser={triggerUser}
                  index={index}
                  isSelected={
                    selectedUsers?.find((a) => a?.id == user?.id) ? true : false
                  }
                  isAdmin={
                    adminUser?.find((a) => a?.id == user?.id) ? true : false
                  }
                  setSelectedUser={setSelectedUser}
                  closeModal={closeModal}
                />
              )
            );
          })}
        </div>
      )}
    </>
  );
};

const PersonalItem = observer(
  ({ item, isSelected, setSelectedUser, closeModal }) => {
    return (
      <div className="flex flex-col items-start gap-4 select-none">
        <div
          onClick={() => {
            setSelectedUser(item);
            closeModal();
          }}
          className="flex flex-row items-center justify-center gap-3 p-0 w-full h-16 border-b dark:border-b-[#292929]"
        >
          <div className="w-full flex flex-row items-center gap-3 p-0 h-full">
            <div className="flex flex-row items-center gap-3">
              {item?.avatar ? (
                <img
                  src={generateFileUrl(item?.avatar)}
                  className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0] "
                />
              ) : (
                <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                  {item?.name?.charAt(0).toUpperCase() +
                    "" +
                    item?.lastName?.charAt(0).toUpperCase()}
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
              <p className="text-sm font-semibold text-[#344054]">
                {item?.name} {item?.lastName}
              </p>
              {/* <p className="text-xs font-normal text-[#344054]">
                {item?.metadata?.roles}
              </p> */}
            </div>
          </div>

          {isSelected ? (
            <div className="flex flex-row items-center gap-3 justify-center ml-auto">
              <div
                className="cursor-pointer"
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: 8,
                  gap: 12,
                  width: 24,
                  height: 24,
                }}
              ></div>
              <div
                onClick={() => {
                  setSelectedUser(item);
                  closeModal();
                }}
              ></div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
);

export default SelectTaskUserModal;
