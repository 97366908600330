import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import Badgets from "../../components/buttons/Badgets";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  EyeIconPoll,
} from "../../assets/icons/PersonIcons";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import EndDatePicker from "../person/components/EndDatePicker";
import { timeFormatter } from "../../utils/timezoneFormatter";
import useWindowDimensions from "../../hooks/useWindowDimension";
import SearchInput from "../../components/inputs/SearchInput";
import { FilterIcon } from "../machine/embeddedServer/svg";
import { junctionService } from "../../services/junction.service";
import AllOperationsCard from "./components/AllOperationsCard";
import NewFinishedJunctionCard from "../../components/junction/NewFinishedJunctionCard";
import { CloseIcon } from "../../assets/icons/departmentIcon";
import generateFileUrl from "../../utils/generateFileUrl";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import diacritics from "diacritics";
import { manipulate } from "../../utils/manipulate";

function QualityExternal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { settings, auth } = useStore();
  const [visible, setVisible] = useState(false);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [junctionType, setJunctionType] = useState("external");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [operations, setOperations] = useState([]);
  const [isPastDay, setIsPastDay] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [hiddenPreviousPending, setHiddenPreviousPending] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState("all");
  const [selectOperationData, setSelectOperationData] = useState(null);
  const handleCardClick = (cardInfo) => {
    setSelected(cardInfo);
  };
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 1,
    retryDelay: 3200,
    queryKey: ["quality-list"],
    queryFn: async () =>
      await junctionService.legacyQualityExternalData({
        date: moment(
          timeFormatter(date, auth?.user?.company?.timeZone)?.formatted
        ).format("YYYY-MM-DD"),
      }),
  });

  const cleanAndMatchSearch = (item, searchTerm) => {
    const fullName = `${item?.user?.name} ${item?.user?.lastName}`;
    const cleanedFullName = diacritics.remove(fullName).toLowerCase();
    const cleanedSearchTerm = diacritics.remove(searchTerm.toLowerCase());
    return cleanedFullName.includes(cleanedSearchTerm);
  };

  const filteredActiveJunction = data?.activeJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationActiveJunction =
    selectOperationData?.activeJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPendingJunction = data?.pendingJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationPendingJunction =
    selectOperationData?.pendingJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPreviousPendingJunction = data?.previousPending?.filter(
    (item) => {
      return cleanAndMatchSearch(item, searchTerm);
    }
  );

  const filteredOperationPreviousPendingJunction =
    selectOperationData?.previousPending?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPastJunction = data?.pastJunction?.filter((item) => {
    return cleanAndMatchSearch(item, searchTerm);
  });

  const filteredOperationPastJunction =
    selectOperationData?.pastJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  useEffect(() => {
    refetch();
    setSelected("all");
  }, [date]);

  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (
      auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325"
    ) {
    } else if (
      auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4"
    ) {
    } else if (
      auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
    ) {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  useEffect(() => {
    setOperations(data?.operations);
  }, [data, isLoading]);
  const today = moment().format("YYYY-MM-DD");
  useEffect(() => {
    if (moment(date).format("YYYY-MM-DD") !== today) {
      setIsPastDay(true);
    } else {
      setIsPastDay(false);
    }
  }, [date]);

  return (
    <>
      <div className="flex w-full h-full flex-col">
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 sm:justify-between items-center ">
          {isLoading || isFetching ? (
            <div className="w-full h-[48px] bg-gray-200 rounded-xl animate-pulse" />
          ) : (
            <>
              <div className=" sm:w-1/2 sm:h-full sm:flex md:flex sm:items-center">
                <span className="mr-3 md:flex sm:flex xs:flex hidden">
                  <Button
                    colorType={"tertiary-gray"}
                    iconLeft={<HumburgerIcon />}
                    size={"md"}
                    onClick={() => settings.setIsActive(true)}
                  />
                </span>
                <div className="flex items-center">
                  <span className="font-codecMedium text-2xl text-black">
                    upu.
                  </span>
                  <p className="text-2xl font-codecMedium text-[#B54708]">
                    quality
                  </p>
                </div>
              </div>
              <div className="ml-auto flex items-center gap-3 sm:hidden xs:hidden">
                <Button
                  iconLeft={<ArrowLeftIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  onClick={() =>
                    setDate(
                      moment(date).subtract(1, "days").format("YYYY-MM-DD")
                    )
                  }
                />
                <EndDatePicker
                  date={date}
                  visible={visible}
                  setDate={setDate}
                  setVisible={setVisible}
                  buttonClassName={"flex flex-col items-center"}
                  calendarClassName={
                    "absolute top-12 rounded-lg shadow-xl z-[600]"
                  }
                  visibleFooter
                >
                  <Button
                    onClick={() => setVisible(!visible)}
                    iconLeft={<CalendarIcon />}
                    size={"md"}
                    colorType={"secondary-gray"}
                    label={moment(date).format("DD.MM.YYYY")}
                  />
                </EndDatePicker>
                <Button
                  disabled={
                    moment(new Date()).format("YYYY-MM-DD") ===
                    moment(new Date(date)).format("YYYY-MM-DD")
                  }
                  iconLeft={<ArrowRightIcon />}
                  size={"md"}
                  colorType={"secondary-gray"}
                  onClick={() =>
                    setDate(
                      moment(date).subtract(-1, "days").format("YYYY-MM-DD")
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
        {isLoading || isFetching ? (
          <div className="w-full h-[134px] bg-gray-200  rounded-xl animate-pulse" />
        ) : (
          <div
            className={`flex w-full gap-x-5 overflow-x-auto pb-1 ${
              !isPastDay
                ? "min-h-[152px] max-h-[152px]"
                : "min-h-[132px] max-h-[132px]"
            }`}
          >
            <AllOperationsCard
              isPastDay={isPastDay}
              pendingJunctionlength={data?.pendingJunction?.length}
              activeJunctionlength={data?.activeJunction?.length}
              pastJunctionlength={data?.pastJunction?.length}
              allCount={data?.allCount}
              onClick={handleCardClick}
              selected={selected}
            />
            {operations
              ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
              ?.map((item, index) => {
                return (
                  <div
                    onClick={async () => {
                      handleCardClick(item);
                      await junctionService
                        .getQualityExternalJunctionsByOperationId(item?.id, {
                          date: moment(
                            timeFormatter(date, auth?.user?.company?.timeZone)
                              ?.formatted
                          ).format("YYYY-MM-DD"),
                        })
                        .then((r) => setSelectOperationData(r?.data));
                    }}
                    key={index}
                    className={`flex flex-col min-w-[172px] max-w-[172px] border h-[96%] shadow-sm ${
                      selected?.id === item?.id
                        ? "border-[#DC6803]"
                        : "border-[#EAECF0]"
                    }  px-3 py-2 rounded-lg cursor-pointer`}
                  >
                    <div className="flex gap-x-2 pb-[6px] items-center">
                      <span className="min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]">
                        {item?.iconKey ? (
                          <img src={generateFileUrl(item?.iconKey)} />
                        ) : (
                          <TransformIcon />
                        )}
                      </span>
                      <span className="text-[#344054] text-sm font-semibold truncate">
                        {/* {item?.name?.slice(0, 3) + " ***"} */}
                        {manipulate(item?.name)}
                      </span>
                    </div>
                    <div className="flex flex-col w-full h-full justify-center">
                      {!isPastDay && (
                        <div className="flex min-h-[30px] max-h-[30px] border-b border-[#EAECF0] py-1 items-center">
                          <span className="text-[#667085] font-normal text-xs">
                            {t("product:actives")}
                          </span>
                          <span className="ml-auto">
                            <Badgets
                              colorType={"fill-success"}
                              size={"sm"}
                              label={item?.activeJunction || 0}
                            />
                          </span>
                        </div>
                      )}
                      <div className="flex min-h-[30px] max-h-[30px] border-b border-[#EAECF0] py-1 items-center">
                        <span className="text-[#667085] font-normal text-xs">
                          {t("product:waiting")}
                        </span>
                        <span className="ml-auto">
                          <Badgets
                            colorType={"fill-warning"}
                            size={"sm"}
                            label={item?.pendingJunction || 0}
                          />
                        </span>
                      </div>
                      <div className="flex min-h-[26px] max-h-[26px] py-1 items-center">
                        <span className="text-[#667085] font-normal text-xs">
                          {t("product:completedNew")}
                        </span>
                        <span className="ml-auto pt-1">
                          <Badgets
                            colorType={"fill-gray"}
                            size={"sm"}
                            label={item?.pastJunction || 0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        {isLoading || isFetching ? (
          <div className="w-full flex-1 bg-gray-200 mt-4 rounded-xl animate-pulse" />
        ) : (
          <div className="flex w-full flex-1 border border-[#D0D5DD] rounded-xl mt-4 flex-col overflow-hidden">
            <div className="flex min-h-[72px] max-h-[72px] p-4 justify-between w-full items-center">
              <span className="text-[#101828] text-xl font-semibold">
                {selected?.name
                  ? manipulate(selected?.name) // ? selected?.name?.slice(0, 3) + " ***"
                  : t("product:allNew")}
                {/* {manipulate(selected?.id) || t("product:allNew")} */}
              </span>
              <div className="flex gap-x-3">
                <span className="flex min-w-[220px] max-w-[220px] h-full items-center justify-center">
                  <SearchInput
                    theme={"product"}
                    setSearchVal={setSearchTerm}
                    placeholder={t("chat:search")}
                  />
                </span>
                <Button
                  colorType={"secondary-gray"}
                  size={"md"}
                  label={t("product:sorted")}
                  iconLeft={<SwitchIcon />}
                />
                <Button
                  colorType={"secondary-gray"}
                  size={"md"}
                  label={t("product:filters")}
                  iconLeft={<FilterIcon />}
                />
              </div>
            </div>
            <div className="flex w-full flex-1 overflow-hidden">
              {!isPastDay && (
                <div className="flex flex-col w-1/3 border-t border-r h-full overflow-hidden">
                  <div className="flex min-h-[38px] max-h-[38px] px-4 py-2 bg-[#F9FAFB] border-b border-[#EAECF0] w-full gap-x-2 items-center">
                    <span className="text-[#344054] text-sm font-semibold">
                      {t("product:activeTasks")}
                    </span>
                    <Badgets
                      colorType={"fill-success"}
                      label={
                        selected === "all"
                          ? filteredActiveJunction?.length
                          : filteredOperationActiveJunction?.length || 0
                      }
                      size={"sm"}
                    />
                  </div>
                  <div className="flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide flex-1 w-full px-4 pt-2 gap-8">
                    {selected === "all" ? (
                      <>
                        {filteredActiveJunction?.map((item, index) => {
                          const startDate = moment(item?.startDate);
                          return (
                            <div className="min-h-[80px] cursor-pointer">
                              <NewFinishedJunctionCard
                                qualityPercent={item?.metadata?.qualityPercent}
                                isPastDay={isPastDay}
                                allData={item}
                                externalOperation={item?.externalOperation}
                                supplier={item?.supplier}
                                pages={"activeAndPlanned"}
                                refetch={refetch}
                                statusType={item?.status}
                                junctionId={item?.id}
                                step={item?.header?.step}
                                key={index}
                                onClick={() => {
                                  setJunctionType(
                                    item?.externalOperation
                                      ? "external"
                                      : "internal"
                                  );
                                  setFinishJunctionId(item?.id);
                                  setIsOpenJunctionPiece(true);
                                }}
                                props={{
                                  startDate: startDate,
                                  ring:
                                    item.type === "work"
                                      ? "#17B26A"
                                      : item.type === "setup"
                                      ? "#EAAA08"
                                      : "#98A2B3",
                                  user: {
                                    image: item?.user?.avatar,
                                    name: item?.user?.name,
                                    lastName: item?.user?.lastName,
                                    id: item?.user?.id,
                                  },
                                  status: item?.metadata?.qualityStatus,
                                  product: {
                                    name: item?.job?.product?.name,
                                    code: item?.job?.orderNo,
                                    image: item?.job?.product?.image,
                                    pieceGreen: item?.estimatedWorkCount,
                                    pieceRed: item?.rejectedWorkCount,
                                  },
                                  node: {
                                    name:
                                      item?.station?.metadata?.brand +
                                      " " +
                                      item?.station?.metadata?.model,
                                    image: item?.station?.metadata?.image,
                                    process: item?.internalOperation?.name,
                                  },
                                  time: {
                                    timeRed: "--",
                                    timeGreen:
                                      moment(startDate)?.format("HH:mm"),
                                  },
                                  onPressNode: (e) => {
                                    // e.stopPropagation();
                                    navigate(
                                      `/app/machine/digital-twin/equipment-details/${
                                        item?.station?.deviceId
                                      }-${item?.station?.id}/${moment(
                                        item?.startDate
                                      ).format("YYYY-MM-DD")}`
                                    );
                                  },
                                  onPressProduct: (e) => {
                                    // e.stopPropagation();
                                    navigate(
                                      `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                    );
                                  },
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {filteredOperationActiveJunction?.map((item, index) => {
                          const startDate = moment(item?.startDate);
                          return (
                            <div className="min-h-[80px] cursor-pointer">
                              <NewFinishedJunctionCard
                                qualityPercent={item?.metadata?.qualityPercent}
                                isPastDay={isPastDay}
                                allData={item}
                                externalOperation={item?.externalOperation}
                                supplier={item?.supplier}
                                pages={"activeAndPlanned"}
                                refetch={refetch}
                                statusType={item?.status}
                                junctionId={item?.id}
                                step={item?.header?.step}
                                key={index}
                                onClick={() => {
                                  setJunctionType(
                                    item?.externalOperation
                                      ? "external"
                                      : "internal"
                                  );
                                  setFinishJunctionId(item?.id);
                                  setIsOpenJunctionPiece(true);
                                }}
                                props={{
                                  startDate: startDate,
                                  ring:
                                    item.type === "work"
                                      ? "#17B26A"
                                      : item.type === "setup"
                                      ? "#EAAA08"
                                      : "#98A2B3",
                                  user: {
                                    image: item?.user?.avatar,
                                    name: item?.user?.name,
                                    lastName: item?.user?.lastName,
                                    id: item?.user?.id,
                                  },
                                  status: item?.metadata?.qualityStatus,
                                  product: {
                                    name: item?.job?.product?.name,
                                    code: item?.job?.orderNo,
                                    image: item?.job?.product?.image,
                                    pieceGreen: item?.madeWorkCount,
                                    pieceRed: item?.rejectedWorkCount,
                                  },
                                  node: {
                                    name:
                                      item?.station?.metadata?.brand +
                                      " " +
                                      item?.station?.metadata?.model,
                                    image: item?.station?.metadata?.image,
                                    process: item?.internalOperation?.name,
                                  },
                                  time: {
                                    timeRed: "--",
                                    timeGreen:
                                      moment(startDate)?.format("HH:mm"),
                                  },
                                  onPressNode: (e) => {
                                    // e.stopPropagation();
                                    navigate(
                                      `/app/machine/digital-twin/equipment-details/${
                                        item?.station?.deviceId
                                      }-${item?.station?.id}/${moment(
                                        item?.startDate
                                      ).format("YYYY-MM-DD")}`
                                    );
                                  },
                                  onPressProduct: (e) => {
                                    // e.stopPropagation();
                                    navigate(
                                      `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                    );
                                  },
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              )}
              <div
                className={`flex flex-col h-full overflow-hidden ${
                  !isPastDay ? "w-1/3" : "w-1/2"
                } border-t border-r`}
              >
                <div className="flex min-h-[38px] max-h-[38px] px-4 py-2 bg-[#F9FAFB] border-b border-[#EAECF0] w-full gap-x-2 items-center">
                  <span className="text-[#344054] text-sm font-semibold">
                    {t("product:completedPendingJobs")}
                  </span>
                  <Badgets
                    colorType={"fill-warning"}
                    label={
                      selected === "all"
                        ? filteredPendingJunction?.length
                        : filteredOperationPendingJunction?.length || 0
                    }
                    size={"sm"}
                  />
                </div>
                <div className="flex flex-col w-full px-4 flex-1 overflow-hidden gap-y-4">
                  <div className="flex flex-col overflow-y-auto overflow-x-hidden gap-y-8 scrollbar-hide h-full w-full pt-2">
                    <JunctionListController
                      items={
                        selected === "all"
                          ? filteredPendingJunction
                          : filteredOperationPendingJunction
                      }
                      refetch={refetch}
                      navigate={navigate}
                      completed={true}
                    />
                  </div>
                  {!isPastDay && (
                    <>
                      <div className="flex w-full h-[1px] bg-[#EAECF0]"></div>
                      <div
                        className={`flex flex-col gap-y-5 w-full max-h-[700px] border border-b-0 border-[#EAECF0] rounded-t-xl shadow-sm bg-[#FCFCFD] py-4 px-5 ${
                          hiddenPreviousPending && "min-h-[430px]"
                        }`}
                      >
                        <div className="flex w-full gap-x-2 items-center">
                          <span className="text-[#344054] font-semibold text-sm items-center">
                            {t("product:remainingFromOtherDays")}
                          </span>
                          <Badgets
                            label={
                              selected === "all"
                                ? filteredPreviousPendingJunction?.length
                                : filteredOperationPreviousPendingJunction?.length ||
                                  0
                            }
                            colorType={"outline-warning"}
                            size={"sm"}
                          />
                          <span className="ml-auto">
                            <Button
                              colorType={"tertiary-gray"}
                              iconLeft={
                                hiddenPreviousPending ? (
                                  <CloseIcon />
                                ) : (
                                  <EyeIconPoll />
                                )
                              }
                              size={"sm"}
                              onClick={() =>
                                setHiddenPreviousPending(!hiddenPreviousPending)
                              }
                            />
                          </span>
                        </div>
                        {hiddenPreviousPending && (
                          <div className="flex flex-col overflow-y-auto overflow-x-hidden gap-y-8 pt-2 scrollbar-hide h-full w-full">
                            <JunctionListController
                              items={
                                selected === "all"
                                  ? filteredPreviousPendingJunction
                                  : filteredOperationPreviousPendingJunction
                              }
                              refetch={refetch}
                              navigate={navigate}
                              completed={true}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                className={`flex flex-col flex-1 overflow-hidden ${
                  !isPastDay ? "w-1/3" : "w-1/2"
                } border-t`}
              >
                <div className="flex min-h-[38px] max-h-[38px] px-4 py-2 bg-[#F9FAFB] border-b border-[#EAECF0] w-full gap-x-2 items-center">
                  <span className="text-[#344054] text-sm font-semibold">
                    {t("product:completedJobs")}
                  </span>
                  <Badgets
                    colorType={"outline-gray"}
                    label={
                      selected === "all"
                        ? filteredPastJunction?.length
                        : filteredOperationPastJunction?.length || 0
                    }
                    size={"sm"}
                  />
                </div>
                <div className="flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide flex-1 gap-y-8 w-full px-4 pt-2">
                  <JunctionListController
                    items={
                      selected === "all"
                        ? filteredPastJunction
                        : filteredOperationPastJunction
                    }
                    refetch={refetch}
                    navigate={navigate}
                    completed={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </>
  );
}

export default QualityExternal;

const JunctionListController = ({ items, refetch, navigate, completed }) => {
  return items?.map((item, index) => {
    const startDate = moment(item?.startDate);
    const endDate = moment(item?.endDate);
    return (
      <div className="min-h-[80px]">
        <NewFinishedJunctionCard
          completed={completed || false}
          qualityPercent={item?.metadata?.qualityPercent}
          allData={item}
          refetch={refetch}
          externalOperation={item?.externalOperation}
          supplier={item?.supplier}
          junctionId={item?.id}
          step={item?.header?.step}
          key={index}
          props={{
            startDate: startDate,
            ring:
              item.type === "work"
                ? "#17B26A"
                : item.type === "setup"
                ? "#EAAA08"
                : "#98A2B3",
            user: {
              image: item?.user?.avatar,
              name: item?.user?.name,
              lastName: item?.user?.lastName,
              id: item?.user?.id,
            },
            status: item?.metadata?.qualityStatus,
            product: {
              name: item?.job?.product?.name,
              code: item?.job?.orderNo,
              image: item?.job?.product?.image,
              pieceGreen: item?.madeWorkCount,
              pieceRed: item?.rejectedWorkCount,
            },
            node: {
              name:
                item?.station?.metadata?.brand +
                " " +
                item?.station?.metadata?.model,
              image: item?.station?.metadata?.image,
              process: item?.internalOperation?.name,
            },
            time: {
              timeRed: moment(endDate)?.format("HH:mm"),
              timeGreen: moment(startDate)?.format("HH:mm"),
            },
            onPressNode: (e) => {
              // e.stopPropagation();
              navigate(
                `/app/machine/digital-twin/equipment-details/${
                  item?.station?.deviceId
                }-${item?.station?.id}/${moment(item?.startDate).format(
                  "YYYY-MM-DD"
                )}`
              );
            },
            onPressProduct: (e) => {
              // e.stopPropagation();
              navigate(
                `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
              );
            },
          }}
        />
      </div>
    );
  });
};

const SwitchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83333 3.33337V16.6667M5.83333 16.6667L2.5 13.3334M5.83333 16.6667L9.16667 13.3334M14.1667 16.6667V3.33337M14.1667 3.33337L10.8333 6.66671M14.1667 3.33337L17.5 6.66671"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const TransformIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.3847 0.666504H3.61501C1.9866 0.666504 0.666504 1.9866 0.666504 3.61501V14.3847C0.666504 16.0131 1.9866 17.3332 3.61501 17.3332H14.3847C16.0131 17.3332 17.3332 16.0131 17.3332 14.3847V3.61501C17.3332 1.9866 16.0131 0.666504 14.3847 0.666504Z"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
      />
      <path
        d="M14.6114 9H3.38818"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M11.9482 6.05122C11.9482 6.05122 11.9101 5.78294 10.8063 4.6726C9.70241 3.56226 7.91269 3.56226 6.80883 4.6726C6.41773 5.066 6.16519 5.54599 6.05123 6.05122M11.9482 6.05122V4.15576M11.9482 6.05122H10.0638"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.05176 11.9488C6.05176 11.9488 6.08986 12.2171 7.19372 13.3274C8.29759 14.4377 10.0873 14.4377 11.1912 13.3274C11.5823 12.934 11.8348 12.454 11.9488 11.9488M6.05176 11.9488V13.8442M6.05176 11.9488H7.93617"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const InternalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_9935_35414)">
        <path
          d="M14.9609 3.4375H17.7033C18.6525 3.4375 19.4221 4.20703 19.4221 5.15625V7.14135"
          stroke="white"
          strokeWidth="1.375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6015 11H7.39551"
          stroke="white"
          strokeWidth="1.375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.57812 14.8643V16.8439C2.57812 17.7931 3.34765 18.5626 4.29687 18.5626H7.04035"
          stroke="white"
          strokeWidth="1.375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g filter="url(#filter0_d_9935_35414)">
          <path
            d="M14.9609 18.5625H21.1408"
            stroke="white"
            strokeWidth="1.375"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M4.125 3.4375H0.859375"
          stroke="white"
          strokeWidth="1.375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.125 5.15625L5.84375 3.4375L4.125 1.71875"
          stroke="white"
          strokeWidth="1.375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.4219 20.2812L21.1406 18.5625L19.4219 16.8438"
          stroke="white"
          strokeWidth="1.375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.0117" cy="3.49463" r="2.57812" fill="white" />
        <circle cx="18.6201" cy="11.103" r="2.57812" fill="white" />
        <circle cx="3.40332" cy="11.103" r="2.57812" fill="white" />
        <circle cx="11.0117" cy="18.6196" r="2.57812" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_9935_35414"
          x="10.6068"
          y="17.875"
          width="14.888"
          height="8.70833"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.66667" />
          <feGaussianBlur stdDeviation="1.83333" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9935_35414"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9935_35414"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_9935_35414">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ExternalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M12.6846 9.06842C12.5856 8.96941 12.5361 8.91991 12.5197 8.86069C12.5052 8.80859 12.5082 8.7515 12.5283 8.6976C12.551 8.63631 12.606 8.58131 12.716 8.47131L16.7229 4.46437L15.3087 3.05015L20.5335 2.77517L20.2585 7.9999L18.8443 6.58569L14.8373 10.5926C14.7273 10.7026 14.6723 10.7576 14.611 10.7804C14.5571 10.8004 14.5 10.8034 14.4479 10.789C14.3887 10.7726 14.3392 10.7231 14.2402 10.6241L12.6846 9.06842Z"
        stroke="#98A2B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.71054 9.53604C7.3905 10.8561 7.39053 12.9963 8.71057 14.3164C10.0306 15.6364 12.1709 15.6364 13.4909 14.3164M11.7173 3.2166L11.2264 2.13762C11.0524 1.75522 10.6275 1.55466 10.2217 1.66337L6.73057 2.59882C6.3248 2.70756 6.05709 3.0937 6.09762 3.51184L6.21205 4.69175C6.34251 6.03762 5.21215 7.16798 3.86629 7.03751L2.68638 6.92309C2.26824 6.88255 1.88209 7.15026 1.77335 7.55604L0.837904 11.0472C0.729192 11.453 0.929753 11.8779 1.31216 12.0518L2.39114 12.5427C3.62194 13.1027 4.03571 14.6468 3.24975 15.7471L2.56074 16.7117C2.31658 17.0535 2.35532 17.5218 2.65235 17.8188L5.20808 20.3746C5.50511 20.6716 5.97341 20.7104 6.31523 20.4662L7.27984 19.7772C8.38018 18.9912 9.92427 19.405 10.4842 20.6358L10.9751 21.7148C11.1491 22.0972 11.574 22.2977 11.9798 22.189L15.4709 21.2536C15.8767 21.1448 16.1444 20.7587 16.1038 20.3406L15.9895 19.1607C15.859 17.8148 16.9893 16.6844 18.3352 16.8149L19.5151 16.9293C19.9332 16.9698 20.3194 16.7021 20.4281 16.2964L21.3636 12.8052C21.4723 12.3994 21.2717 11.9745 20.8893 11.8006"
        stroke="#98A2B3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
