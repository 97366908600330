import React, { useEffect, useRef, useState } from "react";
import Badgets from "../../../components/buttons/Badgets";
import { Button, CustomModal } from "../../../components";
import { useTranslation } from "react-i18next";
import { PlusIcon, SearchIcon } from "../../../assets/icons/stepsIcons";
import { DeleteIcon, EditIcon } from "../../../assets/icons/departmentIcon";
import NewBreak from "../modals/NewBreak";
import moment from "moment";

import { shiftService } from "../../../services/shift.service";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import NoData from "../../step-navigation/components/NoData";
import { timeFormatter } from "../../../utils/timezoneFormatter";

export default function Breaks({ setSelectedTab, activities, setActivities }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [isAlert, setIsAlert] = useState(false);

  var label;
  if (activities && activities.length !== undefined) {
    label = activities.length + " " + t("table:piece");
  } else {
    label = "0" + " " + t("table:piece");
  }
  const deleteActivity = async (act) => {
    if (act?.id && act?.id) {
      setActivities(
        activities.filter((a) => {
          return a?.id != act?.id;
        })
      );
      await shiftService.deleteActivity(act?.id);
    } else {
      setActivities(
        activities.filter((a) => {
          return a?.unique != act?.unique;
        })
      );
    }
  };
  return (
    <div className="flex flex-col gap-5 mt-6 w-[736px] xs:h-[500px] md:w-[670px] sm:w-[600px]">
      <div className="flex flex-col gap-5 min-h-[334px] ">
        <div className="flex justify-between">
          <div className="flex gap-3 items-center dark:text-[#D6D6D6]">
            <p>{t("addShift:breaks")}</p>
            <Badgets colorType={"fill-primary"} label={label} />
          </div>
          <div className="max-w-full items-center">
            <Button
              iconLeft={<PlusIcon />}
              size={"sm"}
              label={t("addShift:addBreak")}
              colorType={"tertiary-machine"}
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
        </div>
        <div className="w-full h-full flex flex-col gap-6 md:h-[282px] sm:h-[262px]">
          <div className="flex gap-6 border-b h-[282px] sm:h-full xs:h-[380px] dark:border-[#292929]">
            {activities && activities.length > 0 ? (
              <>
                <div className="w-1/2 xs:w-full h-full xs:h-[380px] flex flex-col gap-3 overflow-y-auto scrollbar-hide">
                  {activities?.map((e, i) => {
                    return (
                      <div className="flex w-full gap-y-4">
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold text-[#344054] dark:text-[#D6D6D6]">
                            {e?.name}
                          </p>
                          <p className="text-sm font-normal text-[#667085] dark:text-[#D6D6D6]">
                            {/* {moment(timeFormatter(e.start).formatted).format(
                              "HH:mm"
                            )}{" "}
                            -{" "}
                            {moment(timeFormatter(e.end).formatted).format(
                              "HH:mm"
                            )} */}
                            {moment(e.start).format("HH:mm")} -{" "}
                            {moment(e.end).format("HH:mm")}
                          </p>
                        </div>
                        <div className="flex ml-auto items-center gap-3">
                          <Button
                            iconLeft={<EditIcon />}
                            colorType={"tertiary-gray"}
                            size={"sm"}
                            onClick={() => {
                              setSelectedActivity(e);
                              setIsOpen(true);
                            }}
                          />
                          <Button
                            onClick={() => {
                              setIsAlert(true);
                              setSelectedActivity(e);
                            }}
                            iconLeft={<DeleteIcon />}
                            colorType={"tertiary-error"}
                            size={"sm"}
                            // onClick={deleteOnclick}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <span className="min-h-full mb-2 w-px bg-[#EAECF0] xs:hidden dark:bg-[#292929]" />
                <div className="w-1/2 xs:w-full h-full flex flex-col gap-3 overflow-y-auto scrollbar-hide xs:hidden">
                  <div className="w-full h-full flex flex-col items-center"></div>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center w-full h-full ">
                <NoData
                  theme={"machine"}
                  header={t("settingTab:noBreak")}
                  button={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full xs:w-full flex items-center justify-center gap-x-3">
        <Button
          label={t("buttons:prev")}
          colorType="secondary-gray"
          onClick={() => setSelectedTab("shiftInfo")}
        />
        <Button
          type="submit"
          label={t("buttons:save")}
          colorType="primary-machine"
        />
      </div>
      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedActivity(null);
        }}
        children={
          <NewBreak
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
            setIsOpen={setIsOpen}
            t={t}
            activities={activities}
            setActivities={setActivities}
          />
        }
        modalTitle={t("addShift:addBreak")}
        width={"400px"}
      />
      <AlertModal
        title={t("settingTab:deleteBreak")}
        subTitle={t("settingTab:areYouSureDelete")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={async () => {
          await deleteActivity(selectedActivity);
          setIsAlert(false);
        }}
      />
    </div>
  );
}
