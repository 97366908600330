import React, { useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import QtyInput from "../../../components/inputs/QtyInput";
import Selector from "../../../components/inputs/Selector";
import { Button, TimeInput } from "../../../components";
import { useTranslation } from "react-i18next";
import { rawMaterialService } from "../../../services/raw-material.service";
import { useQuery } from "@tanstack/react-query";
import HourTimeInput from "../../../components/inputs/HourTimeInput";
import moment from "moment";
import EndDatePicker from "../../person/components/EndDatePicker";
import { CalendarIcon } from "../../../assets/icons/PersonIcons";
import { orderService } from "../../../services/order.service";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { productService } from "../../../services/product.service";
import { Toast } from "../../../utils/toastify/toast";

//IS EMRINE BAGLI SATIN ALIM TALEP FORMU

const PurchaseRequestForm = ({
  selectedProduct,
  setPurchaseForm,
  purchaseSelected,
  orderData,
  setSupplyForm,
  refresh,
}) => {
  const { t } = useTranslation();
  const [priceValue, setPriceValue] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visible, setVisible] = useState(false);

  const tableHeadItems = [
    {
      name: t("product:productsAndMaterials"),
    },
    {
      name: t("product:orderQuantityss"),
    },
    {
      name: t("product:perUnitCost"),
    },
    {
      name: t("product:totalCost"),
    },
    {
      name: t("product:delivery"),
    },
  ];

  const [priceValues, setPriceValues] = useState({});
  const [dateValues, setDateValues] = useState({});

  const handleChangePrice = (index, value) => {
    setPriceValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleChangeDate = (index, value) => {
    setDateValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const updatedPurchaseSelected = purchaseSelected.map((item, index) => {
    return {
      ...item,
      orderQuantity: priceValues[index] || "",
      deliveryDate:
        dateValues[index] || moment(new Date()).format("YYYY-MM-DD"),
    };
  });

  // rawMaterial id olcak
  // type = order
  // estimatedDeliveryDate
  // orderedUnit orderData?.deliveryDate olcak
  // unitType
  // unitCost birim maliyet
  // currency tl dolar

  const onSubmit = async () => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  return (
    <div className="w-[862px] flex flex-col">
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:sentRawMaterials")}
      </p>
      <div className="h-12 flex flex-row items-center gap-x-4 w-full mt-5 bg-[#F9FAFB] border border-t border-r-0 border-l-0 border-b border-[#EAECF0]">
        <div className="flex flex-row items-center gap-x-2 ml-2">
          {selectedProduct?.image ? (
            <img
              src={generateFileUrl(selectedProduct?.image)}
              className="min-w-[32px] w-[32px] h-[24px] rounded border border-secondary-300 object-cover"
            />
          ) : (
            <div className="w-[32px] min-w-[32px] h-[24px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
              <NoEquIcon />
            </div>
          )}
          <p className="text-sm font-medium text-secondary-900">
            {selectedProduct?.name || "---"}
          </p>
        </div>

        <p className="text-xs font-normal text-secondary-700">
          {t("product:stockCode")}: {selectedProduct?.stockCode || "---"}
        </p>
        <p className="text-xs font-normal text-secondary-700">
          {t("product:workOrderCode")}: {selectedProduct?.code || "---"}
        </p>
        <div className="flex items-center gap-x-1 flex-row">
          <DateIcon />
          <p className="text-xs font-normal text-secondary-700">
            {moment(orderData?.deliveryDate).format("DD-MM-YYYY") || "---"}
          </p>
          <p className="text-xs font-normal text-secondary-700">
            {t("product:deliveryDate")}
          </p>
        </div>
      </div>
      <div className="w-full flex-1 h-full">
        <div
          className={`w-full min-h-[380px] h-[380px] overflow-y-scroll scrollbar-hide`}
        >
          <div className="flex mt-5 max-h-[44px] min-h-[44px]">
            {tableHeadItems.map((item, i) => {
              return (
                <div
                  key={i}
                  scope="col"
                  className={`border-b px-4 py-3 ${
                    i === 4 ? "" : "border-r"
                  } w-[${
                    i === 0
                      ? "30%"
                      : i === 4
                      ? "22%"
                      : i === 3 || i === 2
                      ? "13%"
                      : i === 1
                      ? "22%"
                      : ""
                  }] ${
                    !item.active ? "text-secondary-500" : "text-secondary-900"
                  } items-center text-center`}
                >
                  <span
                    className={`text-center rounded  ${
                      !item.active ? "text-gray-500" : "text-gray-500"
                    } flex text-xs font-medium ${
                      i === 1 || i === 2 || i === 3 || i === 4 || i === 5
                        ? "items-center justify-center"
                        : "items-center"
                    } cursor-pointer whitespace-nowrap`}
                  >
                    {item?.name}
                  </span>
                </div>
              );
            })}
          </div>
          {purchaseSelected?.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex w-full items-center border-b h-[80px] min-h-[80px]`}
              >
                <div className="py-2 pr-3 flex items-center border-r h-[80px] w-[30%]">
                  <div className="flex items-center border w-full p-2 rounded-lg shadow-sm border-[#EAECF0]">
                    <div className="w-[56px] h-[42px] min-w-[56px] min-h-[42px] rounded border border-secondary-200 overflow-hidden">
                      {item.image ? (
                        <img src={generateFileUrl(item?.image)} />
                      ) : (
                        <div className="w-[56px] h-[42px] min-w-[56px] min-h-[42px] flex rounded  overflow-hidden">
                          <NoAvatar
                            name={item?.machineName || "--"}
                            rounded={4}
                            theme={"product"}
                            minHeight="42px"
                            minWidth="56px"
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col ml-2">
                      <p className="text-sm font-medium text-secondary-900">
                        {item?.name || "---"}
                      </p>
                      <p className="text-xs font-normal text-secondary-600">
                        Stock ID: {item?.stockId}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[22%] ">
                  <QtyInput
                    theme={"product"}
                    qtyType={item?.unit}
                    onChange={(e) => handleChangePrice(index, e.target.value)}
                    value={priceValues[index] || ""}
                  />
                </div>
                <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[13%] ">
                  <p className="w-[90%] whitespace-nowrap text-center overflow-hidden text-ellipsis text-md text-secondary-600 dark:text-[#F5F5F5] font-normal">
                    {item?.price}
                  </p>
                  <p className="text-sm font-normal text-secondary-500">
                    {item?.currency}
                  </p>
                </div>
                <div className="border-r flex flex-col h-full p-3  items-center justify-center w-[13%] ">
                  <p
                    className={`w-[90%] whitespace-nowrap text-center overflow-hidden text-secondary-600  text-ellipsis text-md  dark:text-[#F5F5F5] font-normal`}
                  >
                    {item?.price *
                      updatedPurchaseSelected[index]?.orderQuantity}
                  </p>
                  <p className="text-sm font-normal text-secondary-500">
                    {item?.currency}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center  w-[22%] h-full  px-4 ">
                  <TimeInput
                    onChange={(e) => handleChangeDate(index, e)}
                    theme={"product"}
                    value={moment(dateValues[index] || new Date()).format(
                      "YYYY-MM-DD"
                    )}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex min-h-[72px] max-h-[72px] w-full border border-[#FDA29B] rounded-xl p-4 gap-x-3">
          <div className="flex min-h-[40px] max-h-[40px] min-w-[40px] max-w-[40px] rounded bg-[#FEE4E2] items-center justify-center">
            <AlertCircleIcon />
          </div>
          <span className="text-[#475467] font-normal text-sm w-[80%]">
            Í{t("product:excessPurchased")}
          </span>
        </div>
      </div>
      <div className="w-full flex flex-row items-center gap-x-4 ">
        <div className="w-[50%] flex flex-row items-center mt-4">
          <p className="text-md font-normal text-secondary-600">
            {t("product:totalNoOfProduct")}
          </p>
          <p className="text-md text-secondary-900 font-semibold ml-auto">
            {purchaseSelected?.length || "---"}
          </p>
        </div>
        <div className="w-[50%] flex flex-row items-center mt-4">
          <p className="text-md font-normal text-secondary-600">
            {t("product:totalCost")}
          </p>
          <p className="text-md text-secondary-900 font-semibold ml-auto">
            {updatedPurchaseSelected[0]?.price *
              updatedPurchaseSelected[0]?.orderQuantity || "---"}
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-4 w-full mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={() => {
            setPurchaseForm(false);
            setSupplyForm(true);
          }}
        />
        <Button
          type={"submit"}
          label={t("buttons:save")}
          colorType={"primary-product"}
          size={"md"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default PurchaseRequestForm;

export const DateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const AlertCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_13335_708)">
        <path
          d="M9.99984 6.66675V10.0001M9.99984 13.3334H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
          stroke="#D92D20"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13335_708">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
