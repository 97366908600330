import React from "react";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../../hooks/useWindowDimension";

const BarChart = ({ teoricalTime, data }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const option = {
    tooltip: {
      formatter: function (params) {
        return `${params.seriesName}:${Math.floor(
          (params.value % (3600 * 24)) / 3600
        )}:${Math.floor((params.value % 3600) / 60)}:${
          Math.floor(params.value % 60) + " " + "Saat"
        }`;
      },
    },
    legend: {
      top: -5,
    },
    grid: {
      left: "3%",
      right: "10%",
      bottom: "30%",
      containLabel: true,
      width: "93.5%",
      top: "30%",
    },
    xAxis: {
      type: "value",
      min: 0,
      max: { teoricalTime },
    },
    yAxis: {
      type: "category",
      data: [""],
    },
    series: data,
  };
  return (
    <div className="relative !w-full !h-[80px] flex flex-col">
      <div className="absolute -top-0.5 right-4 z-auto text-xs flex items-center">
        {t("settingTab:totalTime")} :{" "}
        <p className="font-semibold">{teoricalTime}</p>
      </div>
      {data.length > 0 ? (
        <>
          <ReactECharts
            option={option}
            style={{
              width: width - 250,
              height: "80px!important",
              minWidth: width - 250,
              minHeight: "80px!important",
            }}
            className="!w-full !h-[40px] !min-h-[60px]"
          />
        </>
      ) : (
        <div className="text-[10px] ml-16 ">{t("kanban:barChart")}</div>
      )}
    </div>
  );
};

export default BarChart;
