import { instance } from "../libs/client";

class EmbeddedServerService {
  async stateEmbeddedServer(deviceId, state) {
    const { data } = await instance.post("sensor/send/embedded/state", {
      deviceId,
      status: state,
    });
    return data;
  }

  async getSensorSettings(metadataId) {
    const { data } = await instance.get(
      `nodes/sensor/information/${metadataId}`
    );
    return data;
  }

  async getSensorTypes() {
    const { data } = await instance.get("system-sensor/list");
    return data;
  }

  async getRealTimeChartData(data) {
    return await instance.post("sensor/send/analog/chart/state", data);
  }

  async swithToServerPriority(id) {
    return await instance.post("sensor/priority", {
      deviceId: id,
      status: 1,
    });
  }

  async saveDigital(data) {
    return await instance.post("sensor/digital", data);
  }

  async saveAnalog(data) {
    return await instance.post("sensor/analog", data);
  }

  async resetAnalog(data) {
    return await instance.post("sensor/reset/analog", data);
  }

  async resetDigital(data) {
    return await instance.post("sensor/reset/digital", data);
  }

  async resetDevice(deviceId) {
    return instance.post(`sensor/device/reset/${deviceId}`);
  }
}

const embeddedServerService = new EmbeddedServerService();
export { embeddedServerService };
