const tr = {
  dashboard: {
    title: "Fabrikanızı kuş bakışı görün",
    plannedProduction: "Planlı Üretim",
    notifications: "Bildirimler",
    totalPoint: "Toplam Puan",
  },
  personals: {
    personal: "Personel",
    personals: "Personeller",
    startEndTime: "Başlangıç-Bitiş Saati",
    title: "firmanızdaki kişiler",
    addPersonal: "Personel ekle",
    addAttachments: "Ek Ekle",
    commentHere: "Buraya yorum yapın",
    showMore: "Daha fazla göster",
    less: "Daha az göster",
    profilePicture: "Personel Fotoğrafı",
    profileInfo: "Profil Bilgileri",
    updateInfo: "Bilgilerinizi güncelleyin",
    password: "Şifre",
    oldPassword: "Eski Şifre",
    newPassword: "Yeni Şifre",
    retype: "Yeni Şifre Tekrar",
  },
  equipments: {
    equipment: "Ekipman",
    equipments: "Ekipmanlar",
    title: "fabrikanızdaki ekipmanlar",
    deleteEquipment: "Ekipmanı Sil",
    deleteAlert: "Silmek istediğinizden emin misiniz? Bu eylem geri alınamaz.",
    defined: "Tanımlı Ekipmanlar",
    undefined: "Tanımsız Ekipmanlar",
    noLimitEmbeddedServer: "Embedded server limitiniz dolmuştur.",
    areYouSure: "Silmek istediğinizden emin misiniz? Bu eylem geri alınamaz.",
    sendErrorMessage: "Hata Mesajı Gönder",
  },
  report: {
    table: "Raporları",
    reports: "Raporlar",
    addNewReport: "Yeni Rapor Ekle",
    reportName: "Rapor Adı",
    creationDate: "Oluşturma Tarihi",
    updateDate: "Güncellenme Tarihi",
    select: "Alan Seç",
    title: "fabrikanızın raporları",
    creationDate: "Oluşturulma Tarihi",
    setDeadLine: "Son Tarihi Seç",
    getNewReport: "Yeni Rapor Al",
    nameTheReport: "Raporu İsimlendir",
    reportDelete: "adlı rapor silenecek onaylıyor musunuz?",
  },
  routes: {
    createCompany: "Firma",
    language: "Dil Ayarları",
    addProcess: "İç Operasyon",
    operationName: "Operasyon Adı",
    addPersonal: "Personel Ekle",
    processResponsible: "Operasyon Sorumlusu",
    departmentResponsible: "Departman Sorumlusu",
    addEquipment: "Ekipman",
    shift: "Vardiya",
    endOfSteps: "endOfSteps",
    personals: "Personeller",
    equipments: "Ekipmanlar",
    departments: "Departmanlar",
    department: "Departman",
    maintinance: "Bakımlar",
    reports: "Raporlar",
    losses: "Kayıplar",
    twin: "Digital Twin & KPI",
    unplannedLoss: "Plansız Kayıplar",
    // settings
    settings: "Ayarlar",
    profile: "Profil",
    company: "Firma",
    personnel: "Personel",
    notifications: "Bildirimler",
    theme: "Tema",
    upuPoint: "Upu Point",
    adress: "Adres",
    external: "Dış Operasyon",
    Product: "Ürünler",
    Order: "Siparişler",
    Stock: "Stoklar",
    OperationList: "Operasyon Listesi",
    unplannedLosses: "Plansız Kayıplar",
    lossesOfYourCompany: "Şirketinizin Kayıpları",
    companyReports: "Firma Raporları",
    mapEditor: "Harita Editörü",
    addNewArea: "Yeni Alan Ekle",
    area: "Alan",
    alreadyInMap: "Zaten haritada",
    templates: "Şablonlar",
    wall: "Duvar",
    doorEight: "Kapı (80cm)",
    doorThree: "Kapı (3m)",
    roundColumn: "Yuvarlak Kolon",
    squareColumn: "Kare Kolon",
    width: "Genişlik (m)",
    height: "Yükseklik (m)",
    MaintenanceLibrary: "Bakım Kütüphanesi",
    ConnectedEquipment: "Bağlı Ekipmanlar",
    CareCenter: "Bakım Merkezi",
    customizeYourMap: "Haritanı Özelleştir",
    returnDefault: "Varsayılan Haritaya Geri Dön ?",
  },
  table: {
    image: "Resim",
    name: "İsim",
    nameSurname: "Ad & Soyad",
    contactName: "İrtibat Ad & Soyad",
    surname: "Soyisim",
    edit: "Düzenle",
    authorization: "Yetki",
    machineModel: "Makine Modeli",
    machineName: "Makine Adı",
    searchMachineName: "Makine Adı Ara",
    score: "Puantaj",
    piece: "Adet",
    phoneNo: "Telefon No",
    lastMaintenance: "Son Yapılan Bakım",
    activeCare: "Aktif Bakım",
    hourlyApproachingMaintenance: "Saatlik Yaklaşan Bakım",
    dailyApproachingMaintenance: "Günlük Yaklaşan Bakım",
    registeredCare: "Kayıtlı Bakımlar",
    faultName: "Arıza Adı",
    thePassingTime: "Geçen Süre",
    faultType: "Arıza Tipi",
    noExplanation: "Açıklama belirtilmemiş.",
    unidentifiedFault: "Tanımlanmamış Arıza",
    identifiedFault: "Tanımlanmış Arıza",
    causeOfFailure: "Arıza Nedeni",
    faultDescription: "Arıza Açıklaması",
    deliveryDate: "Teslim Tarihi",
  },
  label: {
    date: "Tarih",
    alphabetical: "Alfabetik",
    authorization: "Yetki",
    talent: "Yetenek",
    data: "Veriler",
    noData: "Veri yok !",
  },
  legends: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  signals: {
    noElectric: "Makine kapalı",
    active: "Aktif çalışma",
    standby: "Bekleme modu",
    fault: "Arıza",
    break: "Mola",
    internetConnectionLost: "İnternet bağlantısı kesildi",
    sensorCableRemoved: "Sensör kablosu çıkarıldı",
    softwareUpdate: "Yazılım güncellemesi",
    noElectricTower: "Kulenin elektriği yok",
  },
  days: {
    day: "Gün",
    monday: "Pazartesi",
    tuesday: "Salı",
    wednesday: "Çarşamba",
    thursday: "Perşembe",
    friday: "Cuma",
    saturday: "Cumartesi",
    sunday: "Pazar",
    addNewShift: "Yeni Vardiya Ekle",
    addShift: "Vardiya Ekle",
    addShiftCredit: "Ek Shift Kredisi Satın Al",
  },
  calendarColor: {
    purple: "Mor",
    grey: "Gri",
    red: "Kırmızı",
    orange: "Turuncu",
    green: "Yeşil",
    blue: "Mavi",
  },
  buttons: {
    start: "Başla",
    addStartDateTime: "Başlangıç Tarihi / Saati Ekle",
    login: "Giriş Yap",
    next: "Sonraki",
    back: "Geri",
    skip: "Atla",
    nextDay: "Ertesi Gün",
    prev: "Geri",
    previousDay: "Önceki Gün",
    plan: "Planla",
    add: "Ekle",
    edit: "Düzenle",
    delete: "Sil",
    addNew: "Yeni Ekle",
    change: "Değiştir",
    stop: "Vazgeç",
    save: "Kaydet",
    approve: "Onayla",
    daily: "Günlük",
    weekly: "Haftalık",
    monthly: "Aylık",
    monthlyDetails: "Aylık Ayrıntılar",
    yearly: "Yıllık",
    send: "Gönder",
    selectAll: "Hepsini Seç",
    timeSelect: "Zaman Aralığı Seç",
    selectDate: "Tarih seç",
    create: "Oluştur",
    request: "Talep Et",
    createPool: "Anketi Oluştur",
    logOut: "Çıkış Yap",
    closed: "Kapat",
    okey: "Tamam",
    cancel: "İptal",
    copy: "Kopyala",
    advance: "İlerle",
    archive: "Arşivle",
    reject: "Reddet",
    view: "Raporu Gör",
    previous: "Önceki",
    detail: "Detay",
    submitForm: "Formu Gönder",
    apply: "Uygula",
    tryAgain: "Tekrar Deneyin",
    redraw: "Yeniden Çiz",
    createMap: "Harita Oluştur",
    leave: "Ayrıl",
    newNotification: "Yeni Bildirim",
    newTaskGroup: "Yeni Görev Grubu",
    exit: "Exit",
    addQuestions: "Soru Ekle",
    saveChanges: "Değişiklikleri Kaydet",
    createNewInternalOparation: "Yeni İç Operasyon Oluştur",
    previousOperation: "Önceki Operasyon",
    nextOperation: "Sonraki Operasyon",
    reset: "Sıfırla",
    sendToQualityControl: "Satışı Onayla & Kalite Kontrole Gönder",
    resetSensorSettings: "Sensör Ayarlarını Sıfırla",
    saveEndExit: "Kaydet ve Çık",
    cancelAndQuit: "İptal Et ve Çık",
    makeSettings: "Ayar Yap",
    finish: "Bitir",
    buyOperationCredits: "Ek Operasyon Kredisi Satın Al",
    buyDepartmentCredits: "Ek Departman Kredisi Satın Al",
    update: "Güncelle",
    starts: "Başlat",
  },
  colors: {
    blue: "Mavi",
    red: "Kırmızı",
    gray: "Gri",
    orange: "Turuncu",
    purple: "Mor",
    green: "Yeşil",
  },
  step: {
    title: "Adım",
    updateSupplier: "Tedarikçiyi Güncelle",
    noDataFound: "Veri Bulunamadı!",
  },
  kanban: {
    pendingJobs: "Bekleyen İşler",
    activeJobs: "Aktif İşler",
    finishedJobs: "Bitmiş İşler",
    activeJobMap: "Aktif İş",
    activeWorks: "Aktif işler sonlandıktan sonra charta işlenmektedir.",
    noActivePersonel: "Aktif personel bulunmamaktadır",
    noActiveJob: "Aktif iş bulunmamaktadır",
    losses: "Arızalar",
    barChart: "Bar Chart datası bulunamamıştır",
    newShift: "Yeni Vardiya",
    previousShift: "Önceki Vardiya",
    nextShift: "Sonraki Vardiya",
    testShift: "Test Vardiyası",
    noShiftFound: "Vardiya Bulunamadı",
    viewTimeline:
      "Zaman çizelgesi listesini görüntüleyebilmek için lütfen bir vardiya ekleyin.",
    designYourMap: "Firma haritanı kendi ayarlarına göre tasarla.",
    map: "Harita",
    editYourMap:
      "Firma haritanı dilediğin gibi düzenleyebilir ya da en baştan çizebilirsin. Yapacağın değişiklikler diğer kullanıcıları etkilemeyecektir.",
  },
  fileInput: {
    uploadImageError:
      "Lütfen geçerli bir resim dosyası yükleyin (JPEG, JPG veya PNG)",
    profilSubTitle: "Profil resmi eklemek için tıklayın.",
    uploadHeaderPicture: "Profil resmi",
    documentSubTitle: "Dosya yüklemek için tıklayınız.",
    updatePicture: "Resmi güncelle",
    uploaderHeaderDoc: "Dosya",
    uploadAvatar: "Resim yükle",
    changePic: "Resim değiştir",
    cropPicture: "Resim kırp",
    removePicture: "Resmi kaldır",
    uploadFile: "Belge yükle",
    uploaderFile: "Dosya Yükle",
    uploadPicture: "Resim Yükle",
    //Equipment
    equipmentPicture: "Ekipman resmi",
    equipSubTitle: "Ekipman resmi eklemek için tıklayınız.",
  },
  timeLine: {
    title: "Zaman Çizelgesi",
  },
  login: {
    title: "Giriş Yap",
    subTitle: "UPU için giriş yapabilirsiniz.",
    email: "E-posta",
    password: "Şifre",
    sublink: "ile tanışmak ister misiniz?",
  },
  onboard: {
    title: "’ya hoşgeldiniz.",
    subTitle:
      " Kaydınızı oluşturmanız ve upu’yu kullanabilmeniz için gerekli adımları tamamlamanız gerekmektedir. Adımları tamamlamak için “Başla” butonuna tıklayın.",
    button: "Başla",
  },
  personelInfo: {
    title: "Kişisel Bilgiler",
    name: "Ad",
    surname: "Soyad",
    email: "E-Posta",
    password: "Şifre",
  },
  addCompany: {
    title: "Firma Oluştur",
    update: "Firmanızın resmini ve bilgilerini güncelleyin.",
    company: "Firma",
    companyName: "Firma Adı",
    companyAdress: "Firma Adresi",
    companyMail: "Firma E-Posta",
    companyNumber: "Firma Telefonu",
    companyViscalNumber: "Mali Yıl Başlangıç-Bitiş",
    contactEmail: "İletişim E-Posta",
    contactName: "Yetkili Adı",
    contactNumber: "Yetkili Telefonu",
    taxOffice: "Vergi Dairesi",
    taxNumber: "Vergi Numarası",
    status: "Ürün Paketi",
    country: "Ülke",
    timeZone: "Zaman Dilimi",
    equipmentCredit: "Ekipman Kredisi",
    personCredit: "Personel Kredisi",
    companyFiscalNumber: "Mali Yıl Başlangıç Tarihi",
    fiscalYear: "Mali Yıl Başlangıcı",
    updateCompany: "Firma Bilgilerinizi Güncelleyin",
    goToSetup: "Kuruluma Git",
    kwhUnit: "KWH Birim Fiyatı",
    forUnitPrice: "Upu.point Birim Fiyat Karşılığı",
    cCount: "C-Count Katsayısı",
    changeCompany: "Firma değiştirildi.",
    createMapPosition:
      "Firma Alanınıza göre ekipmanlarınızı konumlandıracağınız bir harita oluşturmanız gerekmektedir.",
    companyLogo: "Firma Logosu",
    switch: "Değiştir",
    switchCompany: "Firma Değiştir",
    switchTitle: "Geçiş yapmak istediğiniz şirketi/şirketleri seçin.",
  },
  addPersonal: {
    noLimit: "Personel limiti dolmuştur.",
    personalAddedButLimit: "Personel eklendi fakat limitiniz dolmuştur.",
    mailInUse: "Bu e-posta zaten kullanılıyor.",
    title: "Personel Ekle",
    uploaderHeader: "Proses Ekle",
    searchInput: "Personel Ara",
    roles: "Roller",
    //Card Header
    personCredit: "Kullanıcı kredisi",
    creditTitle: "Daha fazla personel eklemek için krediye ihtiyacınız var.",
    deletedPersonalMessage: "Personel silindi",
    //No person
    subtitle: "Firmanızda çalışan personelleri ekleyebilirsiniz.",

    //Add Person Modal
    modalTitle: "Yeni Personel Ekle",
    selectAut: "Yetki seçiniz",
    selectDepartment: "Departman",
    personId: "Personel ID",
    name: "İsim",
    surname: "Soyisim",
    email: "E-posta",
    emplyDate: "İşe Giriş Tarihi",
    upuPoint: "Upu Puan",
    totalCostPerson: "Personel Toplam Maliyet",
    explanation: "Açıklama",

    //Yetenek ve Proses Seçimi
    abilityTitle: "Yetenek ve Proses Seçimi",
    personSalary: "Personel Maaş",
    talent: "Yetenek",
    point: "Puan",
    date: "Tarih",
    addTalent: "Yetenek Ekle",
    noTalent: "Yetenek Kartı Yok",
    talentMessage:
      "Personellerinize atayabileceğiniz yetenek ve operasyon bilgileri için “Yeni Yetenek Ekle” butonuna tıkla.",
    editYourStaff:
      "Personel rollerinizi düzenleyin veya yeni personel kredisi satın alın.",
    buyStaff: "Personel Kredisi Satın Al",
    //Kazanılmış Eğitim Başarı
    successTitle: "Kazanılmış Eğitim - Ödül - Başarı",
    success: "Başarı",
    soot: "Kurum",
    certificate: "Sertfika No",
    scored: "Puan",
    fullScore: "Tam Puan",
    changePic: "Resim değiştir",

    //Modal
    updatePersonal: "Personeli Güncelle",
    addNewPersonal: "Yeni Personel Ekle",
    deletePersonal: "Personel Silinsin mi?",
    deletePersonalWarning:
      "Seçtiğiniz personal ve verileri tamamen silincektir, emin misiniz?",
    updatedPersonalMessage: "Personal başarıyla güncellendi",
    addedNewPersonalMessage: "Personal başarıyla eklendi",
    //Tabs
    personalInfo: "Personel Bilgileri",
    personalTalent: "Yetenek ve Operasyon Seçimi",
    personalSuccess: "Kazanılmış Eğitim-Başarı",
    //TalentCard
    approvingUser: "Onaylayan",
    talent: "Yetenek",
    selectProcess: "Proses seçin",
    deleteTalent: "Yetenek Silinsin mi?",
    deleteTalentMessage:
      "Seçtiğiniz Yetenek Verileri silinecektir, emin misiniz?",
    //SuccessCard
    noSuccess: "Kazanılmış Eğitim-Başarı Kartı yok",
    addSuccess: "Başarı Kartı ekle",
    successMessage:
      "Personellerine atayabileceğin eğitim ve kazanılmış başarı bilgileri için “Yeni Ekle” butonuna tıkla.",
    uploadCertificate: "Sertifika yükle",
    removeCertificate: "Yüklü dosyayı kaldırmak için tıkla",
    deleteSuccess: "Yetenek Silinsin mi?",
    deleteSuccessMessage:
      "Seçtiğiniz Yetenek Verileri silinecektir, emin misiniz?",
    departmentAdded:
      "*Personelinizi eklediğiniz departmanın sorumlusu olarak atayabilirsiniz.",
    departmentEmpty: "departmanı boş.",
    addPersonnel:
      "Firmanızda çalışan personellerinizi ekleyebilir, oluşturduğunuz departmanlara atayabilirsiniz.",
  },

  addProcess: {
    noLimit: "Operasyon limiti dolmuştur.",
    processAddedButLimit: "Operasyon eklendi fakat limitiniz dolmuştur.",
    noLimitExternal: "Dış operasyon limiti dolmuştur.",
    title: "Operasyon Ekle",
    uploaderHeader: "Operasyon Ekle",
    detail: "Operasyon Detayları",
    process: "Proses",
    detail: "Detaylar",
    equipments: "Ekipmanlar",
    reset: "Verileri Sıfırla",
    //ProcessModal
    updateProcess: "Operasyon Güncelle",
    decomposition: "Ayrıştırma Operasyonu",
    assembly: "Birleştirme Operasyonu",
    conversion: "Dönüştürme Operasyonu",
    supporter: "Yardımcı Operasyon",
    addNewProcess: "Yeni Operasyon Ekle",
    name: "Proses İsmi",
    subTitle: "Proses Yetkilisi",
    searchPlaceholder: "Operasyon Ara ",
    deleteProcess: "Operasyon Silinsin mi?",
    deleteProcessWarning:
      "Seçtiğiniz operasyon ve verileri tamamen silinecektir, emin misiniz?",
    updatedProcessMessage: "Operasyon başarıyla güncellendi",
    addedNewProcessMessage: "Operasyon başarıyla eklendi",
    deletedProcessMessage: "Operasyon silindi",
    //No Prcoess
    noTitle:
      "Frezeleme, tornalama, kesme gibi firma içi operasyonlarınızı ekleyebilir ve eklemiş olduğunuz operasyonları ekipmanlarınıza göre kategorize edebilirsiniz.",
    //Modal
    processName: "Operasyon Adı",
    type: "Operasyon Tipi",
    workTolerance: "Çalışma Toleransı",
    utilization: "Kullanım",
    measurable: "Ölçülebilirlik",
    performance: "Verim",
    availability: "Kullanılabilirlik",
    Availability: "Kullanılabilirlik",
    Performance: "Performans",
    Utilization: "Faydalanabilirlik",
    avaibility: "Kullanılabilirlik",
    searchIcon: "İkon Ara",
    macAddress: "Mac-adres",
    //Tabs
    processChart: "Operasyon Grafiği",
    processInfo: "Operasyon Bilgileri",
    processIcon: "Operasyon İkonu",
    responsibleUser: "Sorumlu ",
    noUser: "Sorumlu Kullanıcı Yok",
    equipmentName: "Ekipman Adı",
    operatorName: "Operatör Adı",
    shifts: "Vardiyalar",
    userList: "Kullanıcı Listesi",
    nodeList: "Node Listesi",
    externalStep: "Firmanızın dış operasyonlarını ekleyebilirsiniz.",
    equipmentStep:
      "Firmanızda bulunan ekipmanları ekleyebilir, önceden oluşturduğunuz operasyonlara ekipmanlarınızı dahil edebilirsiniz.",
    addShiftStep:
      "Firmanızın çalışma saatlerini, molaları ve tatilleri sisteme ekleyerek, takvimden takip edebilir, personellerinizi de haberdar edebilirsiniz.",
    stepCompany:
      "upu.io’ya firmanızı tanımladınız. Başla butonuna basarak firmanızın dijital ikizini oluşturabilirsiniz.",
    editOperation: "Düzenle",
    operationDetail: "Detaya Git",
  },

  addProcessResponsible: {
    title: "Operasyon Sorumlusu Ekle",
    departmentTitle: "Departman Sorumlusu Ekle",
    chooseResponsible: "Operasyon Sorumlusu Seç",
    chooseResponsibleDepartment: "Departman Sorumlusu Seç",
  },

  addEquipment: {
    allEquipments: "Tüm Ekipmanlar",
    responsibleEquipment: "Bağlı Ekipmanlar",
    serialNo: "Seri Numarası",
    title: "Ekipman Ekle",

    //With Equipment
    undefinedTitle: "Tanımsız Ekipmanlar",
    undefinedSub: "Tanımsız",
    definedTitle: "Tanımlı Ekipmanlar",
    definedSub: "Tanımlı",
    //Equipment Modal
    updateEq: "Ekipman Güncelle",
    addEqu: "Yeni Ekipman Ekle",
    brand: "Marka",
    modal: "Model",
    process: "Proses",
    productYear: "Üretim Yılı",
    currentValEq: "Ekipman Güncel Değeri",
    upuPoint: "UPU Puanı",
    minuteCost: "Dakika Maliyet",
    scoreboardCoef: "Puantaj Katsayısı",
    depreciationRate: "Amortisman Oranı",
    usefulLife: "Faydalı Ömür",
    workedPeople: "Çalışacak Kişi Sayısı",
    sequenceNo: "Sıra No",
    deletedEqu: "Ekipman silindi",
    qrCode: "QR kod",

    //No equipment
    noTitle:
      "Firmanızda bulunan ekipmanları ekleyip, daha sonra önceden oluşturduğunuz operasyonlara ekipmanınızı dahil edebilirsiniz.",

    //searchınput
    searchNode: "Ekipman Ara",
    searchProduct: "Ürün Ara",

    //node credit
    outOfCredit: "Krediniz Bitti",
    outOfCreditSub:
      "Ekipman nodunuz bulunmamaktadır. Yeni ekipman ekleyebilmek için Nod satın alabilirsiniz.",
    buyCredit: "Yeni Al",

    //delete node
    deleteNode: "Node Silinsin mi?",
    deleteNodeMessage:
      "Silmek istediğiniz ekipman verileri tamamen silincektir, emin misiniz?",

    //modal
    updatedNode: "Ekipman Güncellendi",
    addedNode: "Ekipman Tanımlandı",
    addedFailedNode: "Ekipman Tanımlama Başarısız",
    defineEquipment: "Ekipman Tanımla",
    nodeId: "Ekipman ID",
    equipmentModel: "Ekipman Modeli",
    equipmentSerialNo: "Ekipman Sıra No",
  },

  equipmentTypes: {
    automatic: "Otomatik Ekipman",
    manuel: "Manuel Ekipman",
    passive: "Pasif Ekipman",
    assembly: "Montaj",
    handTools: "El Aleti",
  },
  addShift: {
    title: "Yeni Vardiya Ekle",
    updateShift: "Vardiya Güncelle",
    //No shift
    noTitle:
      "Firmanızın çalışma saatlerini, molaları ve tatilleri sisteme ekleyerek, takvimden takip edebilir, personellerinizi de haberdar edebilirsiniz.",

    //Shift Modal
    shiftTitle: "Başlık",
    shiftName: "Vardiya Adı",
    supervisor: "Vardiya Sorumlusu",
    startTime: "Başlangıç Saati",
    dayStartTime: "Gün Başlangıç Zamanı",
    endTime: "Bitiş Saati",
    Stopovers: "Molalar",
    stopTitle: "Mola Başlığı",
    daysTitle: "Günler",
    repeatShift: "Vardiya Tekrarı",
    endShift: "Vardiyanız bitiş tarihi var mı?",
    never: "Hiçbir zaman ",
    color: "Takvim Rengi",

    //tabs
    shiftInfo: "Vardiya Bilgileri",
    breaks: "Molalar",

    //search
    searchShift: "Vardiya Ara",

    //delete shift
    deleteShift: "Vardiya Silinsin mi?",
    deleteShiftMessage:
      "Seçtiğiniz vardiya ve verileri tamamen silinecektir, emin misiniz?",
    deletedShift: "Vardiya silindi",

    //
    addedShift: "Vardiya Eklendi",
    updatedShift: "Vardiya güncellendi",

    //breaks
    addBreak: "Yeni Mola Ekle",
    noBreak: "Vardiya Aktivitesi yok",
    noBreakSub:
      "Vardiyalarına ekleyebileciğin aktiviteler için “Yeni Ekle” butonuna tıkla.",
    deleteBreakCard: "Mola silinsin mi?",
    deleteBreakCardSub: "Seçtiğiniz mola tamamen silinecektir, emin misiniz?",
    updateYourCompany: "Firmanızın vardiya ve tatil bilgilerini güncelleyin",
    addNewHoliday: "Yeni Tatil Ekle",
    holidays: "Tatiller",
    activateHoliday: "Tatili Aktive Et",
    feastOfRamadan: "Ramazan Bayramı",
    addHoliday: "Tatil Ekle",
    holidayName: "Tatil Adı",
    //update shift type modal
    weeklyOrDaily:
      "Seçilen vardiyayı haftalık mı yoksa günlük olarak mı güncellemek istiyorsunuz?",
    chooseWeeklyOrDaily: "Haftalık veya Günlük Güncelleme",
    updateDaily: "Günlük Vardiya Güncelle",
  },

  //congrats
  congrats: {
    title: "kurulumu tamamlandı!",
    subTitle:
      "upu.io’ya firmanızı tanımladınız. Başla butonuna basarak firmanızın dijital ikizini oluşturabilirsiniz.",
  },

  reports: {
    teorical: "Teorik",
    planned: "Planlı",
    measurable: "Değerlendirilebilir",
    avaibility: "Kullanılabilirlik",
    performance: "Performans",
    upuPoint: "UPU Puanı",
    kwh: "Kwh",
    quality: "Kalite",
    utilization: "Faydalı Kullanım",
    cross_selection: "Çapraz Seçim",
    quality_loss: "Kalite Kaybı",
    performance_loss: "Performans Kaybı",
    work_loss: "İş Kaybı",
    data_loss: "Veri Kaybı",
    break_loss: "Mola Kaybı",
    dataNotAvailable: "Veri mevcut değil",
    node: "Ekipmanlar",
    user: "Kullanıcılar",
    process: "Proses",
    plannedTime: "Planlı üretim süresi",
    measurableTime: "Ölçülebilir üretim süresi",
    theme: "Tema",
    chooseInterface: "Arayüz Temasını Seçin",
    lightTheme: "Açık Tema",
    darkTheme: "Koyu Tema",
    willBeAddedSoon: "Yakında Eklenecektir",
  },

  validation: {
    empty: "Boş bırakılamaz",
    string: "Girdi stirng formatında olmalıdır.",
    email: "Girdi email formatında olmalıdır.",
    number: "Girdi sayı formatında olmalıdır.",
    max30: "30 bu input için maksimum değerdir.",
    min0: "0 bu input için minimum değerdir.",
    date: "Girdi tarih formatında olmalıdır.",
    max100: "100 bu input için maksimum değerdir.",
  },

  //maintinance

  maintinance: {
    title: "fabrikanızın bakımları",
  },

  shift: {
    title: "fabrikanızın vardiyaları",
  },

  gauge: {
    allData: "Tüm Veriler",
    kpi: "Hedef",
    prevData: "Önceki Veriye Dön",
    nextData: "Sonraki Veriye Dön",
    getData: "Verileri Göster",

    allDataDesc: "Hesaplanmış tüm verileri siler",
    kpiDesc: "Hedef verilerini siler",
    prevDataDesc: "Önceki veriye döner",
    nextDataDesc: "Sonraki veriye döner",
    kpiAdi: "KPI Adı",
    resetKpiValue: "KPI Değerlerini Sıfırla",
    allDay: "Tüm Gün",
    availability: "Kullanılabilirlik",
    utilization: "Faydalanabilirlik",
    performance: "Performans",
    returnPreviousData: "Önceki Verileri Döndür",
    returnNextData: "Sonraki Verileri Döndür",
    resetAllData: "Tüm Verileri Sıfırla",
    nextDatass: "Sonraki Veri",
    resetKpi: "KPI Sıfırla",
    plannedJunction: "Planlanmış Junction",
  },
  group: {
    questionLeaveGroup: "Gruptan Ayrıl ?",
    groupPhoto: "Grup Fotoğrafı",
    textLeaveGroup: "Gruptan ayrılmak istediğine emin misin ?",
    taskGroupName: "Görev Grubu Adı",
    groupName: "Grup Adı",
  },

  department: {
    searchDepartment: "Departman Ara",
    title: "Departman Ekle",
    subsTitle:
      "Firmanızda çalışan personelleri eklemeden önce; muhasebe, insan kaynakları, üretim gibi departmanları oluşturarak yönetim kolaylığı sağlayabilirsiniz.",
    modalTitle: "Yeni Departman Ekle",
    textDepartment:
      "Seçtiğiniz departman ve verileri tamamen silinecektir, emin misiniz?",
    questionDepartment: "Departman Silinsin mi",
    deleteDepartment: "Sil",
    updateDepartment: "Departmanı Güncelle",
    departmentName: "Departman Adı",
    deleteDepartments: "Departman Silinsin mi?",
    appointSelectedDepartment: "Seçili departmanın sorumlusu yap.*",
    departmentDelete: "Departmanı Sil",
  },
  phone: {
    phoneNumber: "Telefon Numarası",
    contactNumber: "İrtibat Numarası",
  },
  addDepartment: {
    createdDepartment: "Departman oluşturuldu",
    updatedDepartment: "Departman güncellendi",
    deletedDepartment: "Departman silindi",
    noLimit: "Departman limiti dolmuştur.",
  },
  tasks: {
    inProgg: "Yapılıyor",
    total: "Toplam",
    todo: "Yapılacak",
    done: "Yapılan",
    approved: "Onaylanan",
    move: "Taşı",
    expired: "Geciken",
    noTask: "Görev Yok, Şimdilik!",
    confirm: "Onayla",
    alreadyConfirmed: "Görev zaten onaylanmıştır",
    decline: "Reddet",
    alreadyDeclined: "Görev zaten yapılacaklar arasındadır",
    edit: "Düzenle",
    delete: "Sil",
    taskAdded: "Görev başarıyla eklendi",
    addFile: "Dosya yükle",
    save: "Kaydet",
    addParticipants: "Katılımcı Ekle",
    allTasks: "Tüm Görevler",
    myTasks: "Görevlerim",
    all: "Tüm",
    taskPlaceholder: "Task konusunu buraya yazınız.",
    tasks: "Görevler",
    comment: "Yorumlar",
    attachments: "Ekler",
    activity: "Aktivite",
    view: "görüş",
    labels: "Etiketler",
    noTaskLimit: "Görev limiti dolmuştur.",
    taskCreated: "Görev oluşturuldu",
  },
  chat: {
    message: "Mesaj",
    personalMessage: "Bireysel Mesajlar",
    groups: "Gruplar",
    task: "Görev",
    notification: "bildirim",
    score: "Puantaj",
    attend: "Yeni izin oluştur",
    poll: "Anket",
    search: "Ara",
    noMessage: "Mesaj yok, Hemen başlat!",
    clickToSendMessage: "Mesaj göndermek için tıklayın",
    admin: "Adminler",
    operators: "Operatörler",
    newMessage: "Yeni Mesaj",
    newGroup: "Yeni Grup",
    choose: "Herkesi Seç",
    chooseRead: "Hepsini Oku",
    pendingSurveys: "Bekleyen Anketler",
    noPendingPoll: "Bekleyen Anket Yok !",
    surveyEndDate: "Anket Bitiş Tarihi",
    selectAllPerson: "Tüm Personeli Seç",
    chooseDepartment: "Bir departman seçin",
    addQuestion: "Soru Ekle",
    reachedMaxLimit: "max limite ulastiniz",
    sendPoll: "Anketi Gönder",
    permissionRequest: "İzin Talep Formu",
    newAnnouncement: "Yeni Duyuru",
    sendAnnouncement: "Duyuruyu Gönder",
    selectUser: "Kullanıcı Seçin",
    announcementDetail: "Duyuru Detayı",
    announcementTitle: "Duyuru Başlığı",
    addAnnouncement: "Duyuru Ekle",
    permissionType: "İzin Türü",
    permissionRange: "İzin Aralığı",
    waitingPermission: "Bekleyen İzinler",
    personToAskPermission: "İzin Alınacak Kişi",
    archives: "Arşiv",
    dayTime: "Gün / Saat",
    permissionStatement: "İzin Açıklaması",
    startingDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    personAuthorized: "İzin Alınacak Kişi",
    newPool: "Yeni Anket Oluştur",
    titlePool: "Anket Başlığı",
    editPhoto: "Fotoğrafı Düzenle",
    whoSee: "Görenler",
    minimumValue: "Asgari Değer",
    totalValue: "Toplam Değer",
    question: "Soru",
    answer: "Cevap",
    addNewTask: "Yeni Görev Ekle",
    newTask: "Yeni Görev",
    editTask: "Görevi Düzenle",
    participants: "Katılımcılar",
    otherUser: "Diğer Kullanıcılar",
    taskEndDate: "Görev Bitiş Tarihi",
    usersAdmin: "Katılımcılar/Adminler",
    addGroupTitle: "Grup Başlığı Ekle",
    addNewContact: "Yeni Kişi Ekle",
    addNewGroup: "Görev Grubu Oluştur",
    today: "Bugün",
    noToday: "Bugünün verisi yok !",
    skillCards: "Tüm Yetenek Kartları",
    notSkillCards: "Yetenek Kartı Bulunamadı",
    checkOut: "Çıkış yapmak istiyor musun ?",
    noPoll: "Bekleyen anket yok !",
    noPast: "Geçmiş anket yok !",
    pastPoll: "Geçmiş Anketler",
    pollCompleting: "Anketi zaten doldurdunuz, teşekkürler.",
    userNotify: "Duyuruyu Şu Kişilere Gönder",
    pollDelete: "Anketi silmek istediğinizze emin misiniz?",
    pollEnd: "Anketi sonlandırmak istediğinize emin misiniz?",
    terminate: "Sonlandır",
    pollAgain: "Anketi yeniden paylaşmak istediğinize emin misiniz ?",
    publish: "Yayınla",
    pollEmpty: "Boş anket oluşturulamaz",
    taskApproved: "Bu görev zaten onaylanmıştır.",
    taskDone: "Bu görev zaten yapılacaklar arasındadır.",
    taskDeleted: "Görev Silindi",
    taskUpdated: "Görev Güncellendi",
    notAuthorized: "Bu işlem için yetkiniz bulunmamaktadır.",
    announcementCannot: "Kullanıcı eklemeden duyuru oluşturulamaz.",
    leftTheGroup: "Gruptan çıktınız",
    pollCreated: "Anket oluşturuldu.",
    announcementCreated: "Duyuru Oluşturuldu.",
    groupCreated: "Grup oluşturuldu",
    transaction: "İşlem başarıyla gerçekleştirildi.",
    notTransaction: "İşlem gerçekleştirilemedi",
    waiting: "Bekliyor",
    clickInstall: "Yüklemek için tıklayın",
    userInstall: "Kişi eklemek için tıklayın",
    taskResponsible: "Görev sorumlusu",
    deleteAll: "Tümünü sil",
    addNewLabel: "Yeni Etiket Ekle",
    addLabel: "Etiket Ekle",
    editLabel: "Etiket Düzenle",
    sendToPoll: "Anketi Şu Kişilere Gönder",
    taskHeader: "Görev Başlığı",
    groupMember: "Grup Üyesi",
    selectTaskAdmin: "Görev yöneticisi olarak seçin",
    newGroupTask: "Yeni Grup Görevi",
    archiveEmpty: "Arşiv boş.",
    user: "Kullanıcı",
    sort: "Düzenlemek",
    filter: "Filtre",
    labels: "Etiket",
    allLabels: "Tüm Etiketler",
    moveCard: "Görev Kartını Taşı",
    taskNumber: "Task Numarası",
    deadLine: "Son Teslim Tarihi",
    checkInCheckOut: "Giriş-Çıkış",
    permissions: "İzinler",
    permissionTab: "İzinler",
    permissionApprovalForm: "İzin Onay Formu",
    beginning: "Başlangıç",
    end: "Bitiş",
    hour: "Saat",
    time: "Saat",
    day: "Gün",
    breastfeedingBreak: "Emzirme izni",
    sickLeave: "Hastalık izni",
    unpaidLeave: "Doğum sonrası 6 aylık ücretsiz izin",
    maternityLeave: "Doğum izni",
    weekendLeave: "Hafta tatili izni",
    nationalHoliday: "Ulusal bayram ve genel tatil günleri izni",
    jobSearch: "İş arama izni",
    startJob: "Bir İş Başlat",
    excusedAbsences: "Mazeret izinleri",
    permissionType: "İzin Tipi",
    activePermissions: "Aktif İzinler",
    deniedPermission: "Reddedilen İzinler",
    deletePermission:
      "Bu izni silmek istediğinize emin misiniz ? Bu işlem geri alınamaz.",
    approvedDetail: "Onaylanmış İzin Detayı",
    rejectedDetail: "Reddedilen İzin Detayı",
    startTime: "Başlangıç Zamanı",
    endTime: "End Time",
    qualityControlResponsible: "Kalite Kontrol Sorumlusu",
    noLimitPoll: "Anket limiti dolmuştur.",
    pollAddedButLimit: "Anket eklendi fakat limitiniz dolmuştur.",
    comingSoon: "Yakında gelecek...",
  },
  product: {
    newExternal: "Yeni Dış Operasyon Ekle",
    stockAndProducts: "Stok ve Ürünler",
    stock: "Stok",
    totalStock: "toplam stok",
    totalOrder: "toplam sipariş",
    totalScrap: "toplam hurda",
    fromStock: "stoktan gelen",
    deliveryDate: "teslim tarihi",
    technicalPicture: "Teknik Resim",
    orId: "Sip.ID",
    sorted: "Sırala",
    filters: "Filtrele",
    activeWorkss: "aktif işler",
    earnedPoints: "Kazanılan Puan",
    planAndWork: "Planlamak için sürükle & bırak.",
    newPlanned: "Planlanan",
    totalWorkTime: "Toplam İş Süresi",
    quantityMade: "Yapılan Adet",
    qualityControlResponsible: "Kalite Konrol Sorumlusu",
    qualityControlAttachment: "Kalite Kontrol Ek",
    metricTon: "Ton",
    ounce: "Ons",
    gallon: "Galon",
    squareMetre: "Metrekare",
    squareMilimetre: "Milimetre Kare",
    squareInch: "İnç Kare",
    package: "Paket",
    box: "Kutu",
    email: "E-Posta",
    timeStarted: "başlangıç zamanı",
    productionType: "Üretim Türü",
    supplierList: "Tedarikçi Listesi",
    createNewSupplier: "Yeni Tedarikçi Oluştur",
    addSupplier: "Tedarikçi Ekle",
    junctionManually: "Manual Junction Ekle",
    manage: "Yönet",
    productionArea:
      "Üretim alanınıza dahil dönüştürme, ayrıştırma veya birleştirme operasyonları.",
    allOperations: "Üretim alanınızın dışındaki tüm operasyonlar.",
    totalLimit: "Toplam Limit",
    entireOperation: "Tüm Operasyonu Bitir",
    workType: "İş Tipi",
    listed: "listele",
    orderDetail: "Sipariş Detayı",
    sortOrder: "sipariş",
    itemsPerpage: "sayfa başına",
    qualityQuantitity: "Kalite Miktarı",
    equipmentDetails: "Ekipman Detayı",
    personnelDetail: "Personel Detayı",
    actives: "Aktif",
    waiting: "Bekleyen",
    pasted: "Geçmiş",
    purchaseds: "satın alındı",
    unitCost: "birim maliyeti",
    orderNoStockRequest: "Sipariş No / Stok Talebi",
    orderQuantityss: "Sipariş Miktarı",
    creationDates: "Oluş. Tarihi",
    materialSupplier: "Malzeme Alım Tedarikçileri",
    serviceSupplier: "Hizmet Alım Tedarikçileri",
    purchaseDetails: "Satın Alım Detayı",
    startPurchase: "Satın Alım Başlat",
    quantitys: "Miktar",
    targetedQuantitys: "Hedeflenen Miktar",
    productss: "Ürün",
    unitsCost: "Birim Maliyet",
    totalCost: "Toplam Maliyet",
    approvedQuantitys: "Onaylanan Miktar",
    rejectedQuantityss: "Reddedilen Miktar",
    junctionSubtitile: "Bağlantı kartınız hazır. Bir eylem seçin.",
    activePurchases: "Aktif Alımlar",
    qualityPendingPurchases: "Tamamlanmış & Kalite Bekleyen Alımlar",
    qualityCompletedQuality: "Kalite Kontrolü Tamamlanmış Alımlar",
    purchaseApproval: "Satın Alım Onayı",
    addTheSupplier: "Satın alımının yapılacağı tedarikçiyi ve miktarı ekleyin",
    estimatedDeliveryDate: "Tahmini Teslimat Tarihi",
    totalStocks: "toplam stok",
    rawMaterials: "hammadde",
    receivedQuantity: "Gelen Miktar",
    qualityAttachmentOne: "Kalite Ek 1",
    qualityAttachmentTwo: "Kalite Ek 2",
    startFinish: "başlangıç-bitiş",
    startFinishTwo: "baş-bit",
    attachment: "Ek",
    estimatedWorkTime: "Tahmini İş Süresi",
    averageWorkTime: "Gerçekleşen İş Süresi Ortalaması",
    estimatedSetupTime: "Tahmini Ayar Süresi",
    averageSetupTime: "Gerçekleşen Ayar Süresi Ort.",
    productDetail: "Ürün Detayı",
    scrapQuantity: "Hurda Miktarı",
    finishedPiece: "Bitmiş Adetler",
    rejectedPieces: "Reddedilen Adetler",
    high: "Yüksek",
    low: "Düşük",
    hours: "sa",
    timeRange: "Zaman Aralığı",
    chooseStartDate: "Başlangıç Tarihini Seç",
    chooseEndDate: "Bitiş Tarihini Seç",
    applyFilter: "Filtreyi Uygula",
    deliveryDates: "teslim t.",
    orderDates: "sipariş t.",
    jobStartDate: "İş Başlangıç Tarihi",
    jobEndDate: "İş Bitiş Tarihi",
    minAvg: "dk ort",
    tgtMin: "hdf dk",
    activeAcceptedPiece: "aktif onay. ad.",
    activeRejectedPiece: "aktif red. ad.",
    order: "Sipariş",
    total: "toplam",
    orders: "Siparişler",
    stockNo: "Stok No",
    rawMaterialNames: "H.Madde Adı",
    rawMaterialStock: "H.Mad.Stok",
    fromPrevious: "Önceki op. gelen",
    pcs: "ad.",
    pcss: "-ad.",
    stockId: "Stok ID",
    totalRejectedPiece: "top.red.ad.",
    minPieceAvg: "dk-ad-ort.",
    min: "dk",
    second: "sn",
    day: "gün",
    opType: "Op. Tipi",
    estTime: "Tah. Zaman",
    previousIncome: "Ön. Gelen",
    pieceModal: "Adet",
    numberOfProduct: "Bu operasyonda üretilen parça sayısı ",
    pieceConfirm: "adettir onaylıyor musunuz?",
    junctionList: "Junction Listesi",
    planView: "Plan görünümü",
    editJunction: "Junction Düzenle",
    junctionType: "Junction Tipi",
    operationTypes: "Operasyon Tipi",
    orderId: "Sipariş ID",
    rawMaterialTypess: "H.Madde Tipi",
    rawMaterialSourcess: "H.Madde Kay.",
    previousIncoming: "Önc.Gelen",
    orderQuantity: "Sipariş Adedi",
    orderQuantitys: "sipariş adedi",
    deliveryDate: "teslim tarihi",
    jobStartTime: "İş Başlangıç Saati",
    jobEndTime: "İş Bitiş Saati",
    rawMaterialTypes: "Ham Madde Tipi",
    rawMaterialSources: "H.Madde Kaynağı",
    pieces: "adet",
    operationType: "İşlem Türü",
    quantityCost: "Adet-Maliyet",
    activeApprove: "aktif onaylanan adet",
    totalRejectedQuality: "toplam reddedilen adet",
    targetMin: "hedef dk",
    madeTotal: "Yapılan / Toplam Adet",
    totalpcs: "toplam ad.",
    estimatedTimes: "Tahmini Süre",
    averageActual: "Gerçekleşen Süre Ort.",
    approvedQuantity: "Onaylanan Adet",
    approvedProductCount: "Onaylanan Ürün Sayısı",
    splitJob: " İşi Farklı Siparişe Böl",
    wantToSplit:
      "İşi bölmek istediğiniz parça sayısını girin ve sonrasında ilgili siparişleri ve operasyonlarını seçin.",
    rejectedQuantitys: "Reddedilen Adet",
    firstPart: "1.Parça",
    secondPart: "2.Parça",
    operationNo: "Operasyon Numarası",
    quantity: "Adet",
    availableTransport:
      "Seçili junctionları taşıyabileceğiniz bir ürün bulunmamaktadır.",
    moveSelectedJunction: "Seçili Junctionları Taşı",
    noPlannedJunction:
      "Operasyon bittiği için yeni bir junction planlayamaz ve başlatamazsınız..",
    newAssembly: "Yeni Montaj Ürünü Oluştur",
    createNewProduct: "Yeni Ürün Oluştur",
    newProduct: "Yeni ürün",
    createProduct: "Ürün Oluştur",
    pendingWorks: "Bekleyen İşler",
    pastWorks: "Geçmiş İşler",
    applied: "Uygulanan",
    purchased: "Satın Alınmışlar",
    pendingPurchases: "Satın Alınacaklar",
    equipment: "ekipman",
    qty: "ürün",
    activeAndPlannedName: "Aktif & Planlanan İşler",
    deleteProduct: "Ürün Sil",
    rawMaterialList: "Hammadde Listesi",
    finishJuction: "Junction Bitir",
    productSalesApproval: "Ürün Hammadde Satış Onayı",
    createRawMaterial: "Yeni Hammadde Oluşturun",
    rawMaterialName: "Hammadde Adı",
    unit: "Birim",
    units: "birim",
    price: "Fiyat",
    prices: "fiyat",
    createNewRawMaterial: "Yeni Hammadde Oluştur",
    descriptionOptionel: "Açıklama (opsiyonel)",
    controlForm: "Kontrol Formu",
    approvalForm: "Onay Formu",
    purchases: "Satın Alma",
    revizionNo: "Revizyon No",
    type: "Tip",
    rejectionForm: "Reddetme Formu",
    sendQualityForm: "Kalite Formu Gönder",
    productRawMaterial: "Ürün Hammadde Kalite Konrol",
    salesConfirmation: "Satış Onay",
    rawMaterialImage: "Hammadde Resmi",
    rejectSale: "Satışı Reddet",
    approveSale: "Satışı Onayla",
    inComingQuantity: "Gelen Ürün Sayısı",
    rejectedQuantity: "Reddedilen Ürün Sayısı",
    chooseOperationType: "Operasyon Türü Seçin",
    internalOperation: "İç Operasyon",
    externalOperation: "Dış Operasyon",
    externalOperations: "Dış Operasyonlar",
    editExternalOperation: "Dış Operasyon Düzenle",
    addNewInternalOperation: "Yeni İç Operasyon Ekle",
    addNewExternalOperation: "Yeni Dış Operasyon Ekle",
    newExternalOperationStep: "Yeni Dış Operasyon Adımı",
    newInternalOperationStep: "Yeni İç Operasyon Adımı",
    createNewOrder: "Yeni Sipariş Oluştur",
    newOrder: "Yeni sipariş",
    operationDetail: "Operasyon Detayı",
    operation: "Operasyon",
    seperationOperation: "Ayrıştırma Operasyonu",
    unificationOperation: "Birleştirme Operasyonu",
    transformationOperation: "Dönüştürme Operasyonu",
    productImage: "Ürün Resmi",
    productName: "Ürün Adı",
    productCode: "Ürün Kodu",
    stockCode: "Stok Kodu",
    addTechnicalDrawing: "Teknik Resim Ekle",
    updateTechnicalDrawing: "Teknik Resim Güncelle",
    rawMaterialType: "Hammadde Tipi",
    rawMaterialSource: "Hammadde Kaynağı",
    rawMaterial: "Hammadde",
    rawMaterials: "Hammaddeler",
    affiliatedSuppliers: "Bağlı Tedarikçi Grubu",
    costPerUnit : "Birim Fiyat & Birim",
    explanation : "Açıklama",
    width: "En",
    density: "Kalınlık",
    diameter: "Çap",
    innerDiameter: "İç Çap",
    height: "Boy",
    description: "Açıklama",
    products: "Ürünler",
    leastTwoProduct: "En Az İki Ürün Seçiniz",
    pdfCropper: "Pdf Kırpıcı",
    internalOperationName: "İç Operasyon Adı",
    estimatedTime: "Tahmini Operasyon Süresi (Dakika/Saniye)",
    estimatedTimes: "Tahmini Süre",
    processtypeonproduct: "Ürün Üzerindeki İşlem Türü",
    costOfMinute: "Maliyet / Dakika",
    minuteCost: "Dakika Maliyeti",
    estimatedSettingTime: "Tahmini Ayar Süresi (Dakika)",
    externalOperationName: "Dış Operasyon Adı",
    supplier: "Tedarikçi",
    supplierss: "tedarikçi",
    works: "iş",
    work: "İş",
    setup: "Ayar",
    totalTime: "toplam zaman",
    moveJunction: "Junction Taşı",
    orderNo: "Sipariş No",
    suppliers: "Tedarikçiler",
    createSupplier: "Tedarikçi Oluştur",
    addNewSupplier: "Yeni Tedarikçi Ekle",
    addedSupplier: "Tedarikçi Eklendi",
    noLimitSupplier: "Tedarikçi limiti dolmuştur.",
    supplierName: "Tedarikçi Adı",
    deleteSupplier: "Tedarikçi Sil",
    chooseSupplier: "Tedarikçi Seç",
    noSupplier: "Tedarikçi Yok",
    madeTransaction: "Yapılacak İşlem",
    estimatedTotalTime: "Tahmini Toplam Süre (Gün)",
    costOfPiece: "Maliyet / Adet",
    cannotBeUndone: "Bu işlem geri alınamaz onaylıyor musunuz ?",
    planWithEquipment: "Yeni ekipman ile iş planlanıyor. Onaylıyor musunuz?",
    deleteProduct: "Ürünü Sil",
    deleteOperation: "Bu operasyonu silmek istediğinize emin misiniz?",
    deleteThisProduct: "Bu ürünü silmek istediğinize emin misiniz?",
    salesApproval: "Ürün / Hammadde Satış Onayı",
    purChasedMaterial: "Satın alınan hammadde",
    materialCompany: "Firmadan gelen hammadde",
    addPhoto: "Fotoğraf Ekle",
    approvalRejection: "Onay / Red Açıklama",
    completeThisOperation: "Bu Operasyonu Tamamla",
    exportOperation: "Operasyonu Dışa Aktar",
    importOperation: "Operasyonu İçe Aktar",
    finishedWorks: "Bitmiş İşler (Ayar)",
    finishedWork: "Bitmiş İşler",
    waitingJobs: "Bekleyen İşler",
    activeJob: "Aktif İşler (Ayar)",
    activeTasks: "Aktif İşler",
    completedPendingJobs: "Tamamlanmış & Kalite Bekleyen İşler",
    remainingFromOtherDays: "Diğer Günlerden Kalanlar",
    completedJobs: "Kalite Kontrolü Tamamlanmış İşler",
    workOrders: "İş Emirleri",
    delayed: "Gecikmiş",
    waitingQualityApproval: "Kalite Onayı Bekleyenler",
    allWorkOperation:
      "Operasyonda bulunan tüm işler tamamlanmış ve onaylanmış sayılacaktır.",
    finishOperation: "Operasyonu bitir",
    qualityUpdateError: "Başlangıç ​​tarihi bitiş tarihinden sonra olamaz",
    finish: "Bitir",
    selectedEquipment: "Ekipman seçmeden önce kullanıcı bilgisi gerekiyor.",
    noEquipment: "Ekipman Yok!",
    errors: "Hata!",
    sendError: "Hata Gönder",
    chooses: "Seç",
    transactionDetail: "İşlem Detayı",
    productApproval: "Ürün Onayı",
    choosePersonnel: "Personel Seç",
    chooseEquipment: "Ekipman Seç",
    setting: "Ayar",
    all: "Hepsi",
    thePastDays: "Geçmiş Günler",
    qualtyControl: "Kalite Kontrol",
    productionResponsible: "Üretim Sorumlusu",
    noActiveOrder: "Aktif sipariş bulunmamaktadır.",
    deleteCard: "Kart Silinsin Mi?",
    startEnd: "Başlangıç / Bitiş Tarihi",
    actualEstimated: "Tahimi / Gerçek Süre",
    estimatedActualQuantity: "Tahmini / Gerçek Ürün Fiyatı",
    stockCode: "Stok Kodu",
    orderCode: "Sipariş Kodu",
    totalApproved: "Toplam / Onaylanan / Reddedilen Adet",
    appendixTechnicial: "Ek / Teknik Çizim",
    estimatedActual: "Tahmini / Fiili Toplam Maliyet",
    deleteOrder: "Siparişi Sil",
    deleteOperation: "Operasyonu Sil",
    orderNo: "Sipariş No",
    orders: "Siparişler",
    viewDrawing: "Teknik Resmi Görüntüle",
    responsiblePersonnel: "Sorumlu Personel",
    approvedForm: "Onay Formu",
    clickImage: "Resim Yüklemek İçin Tıklayın",
    uploadImage: "Resim Yükle",
    uploadImage: "fotoğraf yükleniyor",
    descOptional: "Açıklama (isteğe bağlı)",
    rejectProduct: "Ürünü Reddet",
    confirmProduct: "Ürünü Onayla",
    goToDetail: "Ürün Detayına Git",
    youAreViwing: "Teknik Resmi Görüntülüyorsunuz",
    qualityControlForm: "Kalite Kontrol Formu",
    qualityApprovalForm: "Kalite Onay Formu",
    qualityRejectionForm: "Kalite Red Formu",
    numberOfProducts: "Yapılan Ürün Sayısı",
    numberOfRejected: "Reddedilen Ürün Sayısı",
    settingStart: "Ayar Başlangıcı",
    settingUpStarted:
      "Seçtiğiniz ekipmanda ayar başlatılıyor, onaylıyor musunuz?",
    rawMaterialCompany: "Firmadan gelen hammadde",
    rawMaterialCompanys: "Firma. gelen",
    purchasedRawMaterial: "Satın alınan hammadde",
    purchasedRawMaterials: "Satın alınan",
    deleteOrder: "Siparişi Sil",
    planWork: "Çalışmayı Planla",
    productScheduled:
      "Bu ürün bu tedarikci ile planlanacaktır. Onaylıyor musunuz ?",
    purchasing: "Satın Alma",
    plannedEquipment:
      "Bu ürün bu ekipmanda planlanacaktır. Onaylıyor musunuz ?",
    finishThisOperation: "Bu Operasyonu Bitir",
    plannedWorks: "Planlanan İşler",
    createWork: "Bir İş Oluştur",
    completedWorks: "Tamamlanan İşler",
    completedNew: "Tamamlanan",
    allNew: "Tümü",
    targetMinActual: "hdf dk-ort",
    minQtyAvg: "dk-ad-ort",
    createNewWork: "Yeni İş Oluştur",
    getQrCodes: "QR Kodunu Alın",
    pastOrders: "Geçmiş Siparişler",
    orderList: "Sipariş Listesi",
    stockDetail: "Stok Detayı",
    finishOperationTitle: "Operasyonu Tamamlanmış Olarak İşaretle",
    noLimitStock: "Stok limiti dolmuştur.",
    noLimitOrder: "Sipariş limiti dolmuştur.",
    createOrder: "Sipariş Oluştur",
    updateProduct: "Ürünü Güncelle",
    confirmText:
      "Bu ürüne ait siparişler bulunmaktadır. Güncelleme yerine ürünün kopyasını oluşturup yeni ürün kaydı yapabilirsiniz veya güncelleme işlemine devam edebilirsiniz ama unutmayın ürünü güncellediğinizde siparişleriniz silinecektir.",
    goWithCopy: "Kopya ile devam et",
    updateAnyway: "Güncelleme işlemine devam et",
    copyProduct: "Ürünü Kopyala",
    copyProductText:
      "Bu işlem ile ürününüz kopyalanacak ve yeni bir ürün kaydı oluşturulacaktır.",

    activeAndPlanned: "Aktif / Planlanmış İşler",
    completedAndApproved: "Tamamlanmış / Onaylanmış İşler",
    completedWorks: "Bitmiş İşler",

    pastOrders: "Geçmiş Siparişler",
    activeOrders: "Aktif Siparişler",
    qualityPercent: "Kalite Kontrol Yüzdesi",
    receipe: "Reçete",
    details: "Detaylar",
    addOperation: "Operasyon Ekle",
    active: "aktif",
    planned: "planlanan",
    serviceOperationType: "Operasyon Türü",
    productsAndMaterials: "Ürün ve Hammaddeler",
    productsAndMaterialss: "Ürün ve Hammaddeler",
    perUnitCost: "Birim Maliyeti",
    goToOrder: "Siparişe Git",
    productAndRawmaterial:
      "Ek stok tedariği için satın alıma gönderilecek ürün ve/veya hammaddele",
    totalStock: "toplam stok",
    deliveryDate: "teslim tarihi",
    hammadde: "hammadde",
    hamade: "Hammadde",
    expectedDelivery: "Tahmini Teslimat Tarihi",
    unit: "Birim",
    cost: "Maliyet",
    productUnit: "Ürün Birimi",
    orderQuantity: "Siparis Adedi",
    events: "Olaylar",
    source: "Kaynak",
    orderAmount: "Sipariş Miktarı",
    purchase: "Satın Alım",
    supplyForm: "Tedarik Formu",
    productCode: "Ürün Kodu",
    stockCode: "Stok Kodu",
    orderCode: "Sipariş Kodu",
    orderQuantity: "Sipariş Adedi",
    delivery: "Teslim Tarihi",
    materialRequiredForWork: "İş emri için gereken ürün ve/veya hammaddeler",
    workOrderCode: " İş Emri Kodu",
    markPurchase: "Satın Alım Yap",
    totalNoOfProduct: "Toplam Ürün Sayısı",
    bringFromStock: " Stoktan Getir",
    stockAmount: "Stok Miktarı",
    supplyType: "Tedarik Türü",
    failedCreation: "Oluşturulamadı",
    sentRawMaterials:
      "İş emri için satın alıma gönderilecek ürün ve/veya hammaddeler",
    excessPurchased:
      "Satın alınacak miktarın sipariş ihtiyacından fazla olması durumunda fazla miktar, ek stok alımı olarak gösterilecektir.",
    additionalRawmaterials:
      "Ek stok tedariği için satın alıma gönderilecek ürün ve/veya hammaddeler",
    purchaseRequestForm: "Satın Alım Talep Formu",
    purchaseRequestCreation: "Satın Alım Talebi Oluştur",
    singleProcess: "İşlem",
    subProductsNumber: "alt ürün adedi",
    levels: "dk-ad-ort",
    mergeOperationSteps: "Yeni Birleştirme Operasyon Adımı",
    generalInformation: "Genel bilgiler",
    selectMinMaterials: "Bu operasyon için en iki ürün / hammadde seçin",
    operations: "operasyon",
    active: "Aktif",
    waiting: "Bekleyen",
    completed: "Tamamlanan",
    rejected: "Reddedilen",
    orderEdit: "Siparişi Düzenle",
  },

  //
  consoleModal: {
    title: "Vardiyalık Ekipman Detayları",
    time: "Süre",
    qty: "Sayı",
    count: "Sayı",
    workQty: "İş Sayısı",
    minCount: "Minimum Sayı",
    noDataForShift: "Data bulunamadı",
    noConnection:
      "Cihaz Bağlantısı Olmadığından İşleminiz Alınmamıştır. Lütfen Cihaz Bağlantısını Kontrol Edin.",
    errorSent: "Hata gönderildi",
    errorRemoved: "Hata sonlandırıldı",
    seeAuthorization:
      "Yetkiniz olmadığı için upu.twini göremiyorsunuz.Yukarıdaki menüden yetkiniz olan operasyonlara çift tıklayarak detaylarını görebilirsiniz.",
    notAuthorization: "Yetkiniz Yok",
    connectToServer: "Sunucu ile bağlantı kurulamıyor!",
    sensorError: "Sensör Hatası!",
  },
  losses: {
    addNewType: "Yeni Hata Tipi Ekle",
    editType: "Hata Tipini Güncelle",
    general: "Genel",
    iconLib: "İkon Kütüphanesi",
    viewAll: "Hepsini Gör",
    typeName: "Tip Adı",
    typeDescription: "Tip Açıklaması",
  },

  //settings KPI
  settingsKpi: {
    subTitle: "KPI bilgilerini güncelleyin",
    firmWide: "Firma Geneli",
    personels: "Kullanıcılar",
    plannedProduction: "Planlanan Üretim Süresi",
    theoreticalProduction: "Teorik Üretim Süresi",
    performance: "Verim",
  },
  loss: {
    graphView: "Grafik Görünümü",
    listView: "Liste Görünümü",
    typeOfLoss: "Kayıp Tipleri",
    allTime: "Tüm Zamanlar",
    monthly: "Aylık",
    timeLoss: "Zaman Kaybı",
    time: "Zaman",
    costLoss: "Maliyet Kaybı",
    cost: "Maliyet",
    energyLoss: "Enerji Kaybı",
    consumption: "Tüketim",
    filter: "Filtreler",
    export: "Dışa Aktar",
    lostType: "Kayıp Tipi",
    operation: "Operasyon",
    machine: "Makine Adı",
    start: "Başlangıç",
    end: "Bitiş",
    timeMass: "Zaman Kütle",
    faultTypes: "Arıza Tipleri",
    lossCode: "Kayıp Kodu",
    cannotLoss: "Bu işlem geri alınamaz.",
    icon: "İkon",
    noDataFound: "Veri Bulunamadı!",
    searchLoss: "Ara...",
    electricKwh: "Elektrik (kwh)",
    electricKw: "Elektrik (kw)",
    costt: "Maliyet (t)",
    costThree: "Maliyet (3t)",
    code: "Kod",
    getQrCode: "QR Kodu Alın",
    lossIcon: "Kayıp Simgesi",
    lossName: "Kayıp Adı",
    sensorSetting: "Sensör Ayarı",
    editLossType: "Kayıp Tipini Düzenle",
  },
  settingTab: {
    profileAndPassword: "Profil & Şifre",
    company: "Firma",
    departments: "Departmanlar",
    internalOperation: "İç Operasyon",
    shiftManagement: "Vardiya Yönetimi",
    manageYourProfile: "Profilinizi ve tercihlerinizi yönetin.",
    profilePhoto: "Profil Fotoğrafı",
    currentPassword: "Şifrenizi değiştirmek için mevcut şifrenizi girin.",
    currentPasswords: "Mevcut Şifre",
    consfirmNewPassword: "Yeni Şifreyi Onayla",
    updateYourCompany: "Şirketinizi güncelleyin.",
    manageYourDepartments: "Departmanlarınızı yönetin.",
    manageYourExternal: "Dış operasyonlarınızı yönetin.",
    newDepartment: "Yeni Departman Oluştur",
    generalInformation: "Genel Bilgiler",
    affiliatedPersonnel: "Bağlı Personeller",
    labelNameEmpty: "Etiket Adı Boş Bırakılamaz.",
    internalOperations: "İç Operasyonlar",
    externalOperations: "Dış Operasyonlar",
    manageYourInternal: "İç operasyonlarınızı yönetin.",
    updateInternalOperation: "İç Operasyonu Güncelle",
    connectedEquipment: "Bağlı Ekipmanlar",
    shiftManagement: "Vardiya Yönetimi",
    manageYourShift: "Vardiyalarınızı ve molalarınızı yönetin.",
    shiftInfo: "Vardiya Bilgisi",
    breaks: "Molalar",
    noBreak: "Mola Yok!",
    deleteBreak: "Mola silinsin mi?",
    areYouSureDelete: "Silmek istediğinize emin misiniz?",
    breakName: "Mola Adı",
    updateYourKpi: "Kpi değerlerinizi güncelleyin.",
    success: "Başarılı.",
    failed: "Başarısız!",
    costNew: "maliyet(t)",
    costThreeNew: "maliyet(3t)",
    statusType: "Durum Tipleri",
    consume: "Tüketim",
    energy: "Enerji",
    start: "Başlangıç",
    newCreateExternalOperation: "Yeni Dış Operasyon Oluştur",

    end: "Bitiş",
    graphDataNotFound: "Grafik verisi bulunamadı!",
    totalTime: "Toplam Zaman",
    profilePhotoUpdate: "Profile Photo Updated",
  },
  person: {
    selectItem: "Lütfen listeden bir öğe seçin.",
    newPoll: "Yeni Anket",
    newGroup: "Yeni Grup",
    newMessage: "Yeni Mesaj",
    createTaskGroup: "Görev Grubu Oluştur",
    startConversation: "Konuşma Başlat",
    createNewPoll: "Yeni Anket Oluştur",
    groupInfo: "Grup Bilgisi",
    groupMembers: "Grup Üyeleri",
    clickSendMessage: "Mesaj göndermek için tıklayın",
    directChats: "Doğrudan Sohbetler",
    groups: "Gruplar",
    muteGroup: "Grubu Sessize Al",
    unmuteGroup: "Grubun Sesini Aç",
    leaveGroup: "Gruptan Ayrıl",
    areYouLeave: "Gruptan ayrılmak istediginize emin misiniz ?",
    areYouDelete: "Grubu silmek istediğinize emin misiniz ?",
    areYouMute: "Grubu sessize almak istediginize emin misiniz ?",
    areYouUnmuteGroup: "Grubu sessizden çıkarmak istiyor musunuz?",
    areYouMutePerson: "Kişiyi sessize almak istediginize emin misiniz ?",
    areYouUnmutePerson: "Kişiyi sessizden çıkarmak istiyor musunuz?",
    muteThePerson: "Kişiyi sessize al",
    unmutePerson: "Sessizden çıkar",
    mute: "Sessize Al",
    unmute: "Sessizden Çıkar",
    admins: "Adminler",
    editGroup: "Grubu Düzenle",
    createNewTask: "Yeni Görev Oluştur",
    taskSubjectCreated:
      "Task konusu boş bırakılamaz ve personel seçimi yapılmaz ise task oluşturulamaz!",
    taskSubject: "Task konusu boş bırakılamaz !",
    taskCannot: "Personel seçilmeden task oluşturulamaz !",
    taskSuccess: "Task başarıyla oluşturuldu.",
    addUser: "Personel Ekle",
    writeTask: "Görev giriniz..",
    addComment: "Yorum giriniz...",
    markAsAllRead: "Tümünü Okundu Olarak İşaretle",
    deleteNoti: "Duyuruyu Sil",
    totalNotificationLimit: "Toplam Bildirim Limiti",
    selectAll: "Tümünü Seç",
    pollCompleted: "Anket Dolduruldu",
    finishPoll: "Anketi Bitir",
    deletePoll: "Anketi Sil",
    rePublishPoll: "Anketi Tekrar Yayınla",
    areDeletePoll: "Anketi silmek istediginize emin misiniz ?",
    areFinishPoll: "Anketi bitirmek istediginizden emin misiniz ?",
    areRetweetPoll: "Anketi tekrar yayınlamak istediğinize emin misiniz ?",
    completePoll: "Anketi Tamamla",
    product: "Ürün",
    connectAndDisconnect: "Bağla Sök Kaybı",
    qualityControl: "Kalite kontrol",
    availablePersonnels: "Mevcut Personel",
    leadersOfToday: "Günün Liderleri",
    start: "Başla",
    description: "Açıklama",
    semiMaterialStockCode: "Yarı Mamül Stok Kodu/Opsiyonel",
    totalTaskLimit: "Toplam Görev Limiti",
  },
  embedded: {
    activeAnalogSensor: "Aktif Analog Sensör",
    connectedEquipment: "Bağlı Olduğu Ekipman",
    activeDigitalSensor: "Aktif Dijital Sensör",
    statusColors: "Durum Renkleri",
    deviceState: "Cihaz Durumu",
    stmVersion: "Stm Sürümü",
    sensorFusion: "Veri İşleme Yazılımı",
    connectionSoftware: "Haberleşme Yazılımı",
    espVersion: "Esp Sürümü",
    status: "Durum",
    macAddress: "Mac Adresi",
    ipAddress: "IP Adresi",
    sensorType: "Sensör Tipi",
    activeOutput: "Aktif Çıkış",
    makeSure: "Lütfen tüm bilgileri eksiksiz doldurduğunuzdan emin olun!",
    sensorDetails: "Sensör Detayı",
    graph: "Grafik",
    sensorType: "Sensör Tipi",
    valueType: "Değer Türü",
    maxValue: "Max Değer",
    minValue: "Min Değer",
    registerDeleted: "Kayıtlı sensör bilgileri silindi.",
    digitalIntegral: "Dijital İntegral Frekansı",
    digitalTotalTime: "Dijital Toplam Zaman Değeri",
    digitalTotalCount: "Dijital Toplam Sayı Değeri",
    resetSensor: "Sensör Ayarını Sıfırla",
    willBeReset:
      "Sensöre ait tüm ayarlar sıfırlanacaktır. Bu işlem geri alınamaz.",
    timeRemaining: "Kalan Zaman",
    realTimeFiltered: "Anlık Filtreli",
    unfilteredRealTime: "Anlık Filtresiz",
    changesNotSaved: "Degişiklikler Kaydedilmedi",
    areYouSureChanges:
      "Değişiklikleri kaydetmeden önceki adıma geçmek istediğinize emin misiniz?",
    resetDeviceSettings: "Cihaz Ayarlarını Sıfırla",
    failedConnectEmbedded: "Embedded Server ile Bağlantı Kurulamadı",
    quickEntry: "Hızlı Giriş",
    getQr: "QR Kodu Al",
    printQr: "Bir QR kodunu indirin veya yazdırın",
    print: "Yazdır",
    download: "İndir",
    embeddedServerSession:
      "Embedded server oturumunuz 15 dakika sonra otomatik olarak sonlandırılacaktır.",
    leavePage:
      "Sayfayı terk etmek üzeresiniz. Yaptığınız değişiklikler kaybolabilir.",
    connectionLost: "ile Bağlantı Kesiliyor",
    areYouSaveChanges:
      "Değişiklikler kaydedilmeden çıkmak istediğine emin misin?",
    disconnect: "Bağlantıyı Kes",
    resetDevice: "Cihazı Sıfırla",
    actionCannot: "Bu işlem geri alınamaz.",
    internetConnection: "İşlem Başarısız İnternet Bağlantınızı Kontrol Ediniz!",
    networkError: "Ağ bağlantı sorunu",
    pleaseWaitData: "Veri yüklenirken lütfen bekleyiniz.",
    mapEditor: "Harita Editorü",
    templates: "Şablonlar",
    areaSettings: "Alan Ayarları",
    width: "Genişlik",
    height: "Yükseklik",
    mapSaved: "Harita başarıyla kaydedildi lütfen bekleyiniz.",
    works: "İşler",
    startJob: "İşi Başlat",
    operationSuccessfull: "Operasyon başarılı!",
    startJob: "İş Başlat",
    completedWorks: "Tamamlanan İşler",
    viewAll: "Tüm Planlanan Çalışmaları Görüntüle",
  },
  tower: {
    grayTitle: "Kapalı",
    grayText:
      "Cihazın veya bağlı olduğu makinenin enerjisi olmadığını belirtir.",
    yellowTitle: "Bekleme",
    yellowText:
      "Ekipmanın  şalterinin açık olduğunu, hazır durumda beklediğini fakat aktif olarak çalışmadığını belirtir. ",
    greenTitle: "Çalışma",
    greenText: "Bağlı olduğu makinenin aktif olarak çalıştığını belirtir.",
    redTitle: "Hata",
    redText: "Bağlı olduğu makinenin arıza moduna geçtiğini belirtir. ",
    purpleTitle1: "Dijital Sensör ve Analog Sensör",
    purpleText1:
      "Gömülü sunucudan seçilen Dijital veya Analog sensörün bağlantısının koptuğunu belirtir.",
    purpleTitle2: "Bluetooth Sinyali Kaybı",
    purpleText2: "Bluetooth sensörle bir bağlantı kurulamadığını belirtir.",
    purpleTitle3: "kWh Sensör Arızası",
    purpleText3:
      "kWh sensöründe bir arıza olduğu ya da bağlı olmadığı durumunu belirtir.",
    purpleFlashingTitle: "Sensör Doyum Noktası",
    purpleFlashingText: "Sensörün doyum noktasına ulaştığını ifade eder.",
    whiteTitle1: "Vardiya Başlangıç-Bitiş",
    whiteText1: "Vardiya başlangıç ve bitişleri belirtir.",
    whiteTitle2: "Çevrimiçi Zamanlı",
    whiteText2: "Uzaktan gelen bir durum sinyalini belirtir. ",
    whiteTitle3: "Sensör",
    whiteText3: "Sensör ayarlarının yapılmadığı anlamına gelir.",
    lightBlueTitle: "Wi-Fi Bağlantı Kaybı",
    lightBlueText:
      "Ekipmanın internete bağlanmaya çalıştığı durumunu belirtir.",
    darkBueTitle1: "Devam Eden Bakım",
    darkBlueText1: "Ekipmanın anlık olarak bakımda olduğu durumu belirtir. ",
    darkBueTitle2: "Planlı Bakım",
    darkBlueText2: "Ekipmanın planlanan bakım zamanının geldiğini belirtir.",
    darkBueTitle3: "Öngörülen Bakım",
    darkBlueText3: "Ekipmanın arızalanmak üzere olduğunu belirtir.",
    orangeTitle: "ESP Yazılım Güncelleme",
    orangeText: "Upu Tower'ın yazılım güncellemesi yaptığını belirtir.",
    flashingRedTitle: "Bulut Bağlantısı Kesilmesi",
    flashingRedText:
      "Ekipmanın bulut ile bağlantısının kesilmesi durumunu belirtir.",
  },
  global: {
    pageNotFound: "Sayfa bulunamadı..!",
    pageNotLoad: "Sayfa yüklenemedi",
    sorryMsg:
      "Üzgünüz, aradığınız sayfa mevcut değil veya taşınmış. İşte bazı yararlı bağlantılar:",
    toHome: "Ana Sayfa",
    goBack: "Geri gitmek",
    selected: "Seçili",
    returnActiveWorks: "Aktif İşlere Dön",
  },
  errors: {
    oldAndNewPassword: "Eski Şifre ve Yeni Şifre aynı olamaz.",
    apiError: "API veya Service kapalı. Lütfen bir süre sonra deneyin.",
  },
  password: {
    passwordConditions:
      "Şifreniz en az 8 karakter uzunluğunda olmalıdır. Büyük harf, küçük harf, rakam ve özel karakter içermelidir.",
    passwordNotMatch: "Şifreler eşleşmiyor",
    passwordNotValid: "Şifre şartları sağlanmıyor",
    passwordChanged: "Şifre değiştirildi",
    passwordNotChanged: "Şifre değiştirilemedi",
    createNewPassword: "Yeni Şifre Oluştur",
    continue: "Devam Et",
    newPassword: "Yeni Şifre",
    newPasswordRepeat: "Yeni Şifre (Tekrar)",
    english: "İngilizce",
    turkish: "Türkçe",
    passwordRequired: "Şifre Gerekli",
    currentPasswordNotTrue: "Mevcut şifre yanlış",
  },
  alert: {
    warning: "Demo kullanıcıları yetkili değildir. Lütfen yönetimle iletişime geçin."
  }
};

export default tr;
