import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, UploadProfile } from "../../../components";
import { PlusIcon, PollDeleteIcon } from "../../../assets/icons/PersonIcons";
import { DashboardPlusIcon } from "../../../assets/icons/machineIcons";
import { Toast } from "../../../utils/toastify/toast";

const PollItem = ({
  key,
  ques,
  mainIndex,
  poll,
  setPoll,
  addQuestion,
  deleteQuestion,
}) => {
  const { t } = useTranslation();
  const onChangeQuestionTitle = (text, index) => {
    const newQuestions = [...poll.questions];
    newQuestions[index].question = text;
    setPoll({ ...poll, questions: newQuestions });
  };
  const onChangeAnswer = (text, answerIndex, index) => {
    const newQuestions = [...poll.questions];
    newQuestions[index].options[answerIndex].option = text;
    setPoll({ ...poll, questions: newQuestions });
  };
  const onChangeImage = (text, answerIndex, index) => {
    const newQuestions = [...poll.questions];
    newQuestions[index].options[answerIndex].image = text;
    setPoll({ ...poll, questions: newQuestions });
  };

  const deleteAnswer = (answerIndex, index) => {
    let currentQuestions = poll.questions;
    currentQuestions[index].options = currentQuestions[index].options.filter(
      (item, i) => i !== answerIndex
    );
    setPoll({ ...poll, questions: currentQuestions || [] });
  };

  const addAnswer = (index) => {
    const currentQuestions = poll.questions;
    currentQuestions[index].options.push({ option: "" });
    setPoll({ ...poll, questions: currentQuestions || [] });
  };

  return (
    <>
      <div className="flex flex-col items-start gap-4 py-3 pl-4 pr-2 w-full rounded-lg border border-secondary-300 ">
        <div className="flex flex-row items-center gap-3 w-full">
          <Input
            label={mainIndex + 1 + ". " + t("chat:question")}
            theme={"person"}
            onChange={(e) => {
              if (poll?.question?.length - 1 === mainIndex) {
                setTimeout(() => {
                  addQuestion();
                }, 300);
              }
              onChangeQuestionTitle(e.target.value, mainIndex);
            }}
          />
          <div className="mt-6">
            <Button
              size={"md"}
              iconLeft={<PollDeleteIcon />}
              colorType={"tertiary-error"}
              onClick={() => {
                deleteQuestion(mainIndex);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-start">
          {ques?.options?.map((option, i) => (
            <div className="flex flex-col relative  w-full border rounded-lg py-3 mb-4 px-3 border-l-[6px] border-secondary-300 border-l-secondary-300 ">
              <div className="flex flex-row items-center gap-3 w-full">
                <Input
                  label={`${t("chat:answer")} ${i + 1}`}
                  theme={"person"}
                  value={option.option}
                  onChange={(e) => {
                    onChangeAnswer(e.target.value, i, mainIndex);
                    if (ques?.options?.length - 1 === i) {
                      if (ques?.options?.length >= 10) {
                        Toast("error", "Max limite ulasildi");
                      } else {
                        addAnswer(mainIndex);
                      }
                    }
                  }}
                />
                <div className="mt-6 ">
                  <Button
                    size={"md"}
                    iconLeft={<PollDeleteIcon />}
                    colorType={"tertiary-error"}
                    onClick={() => {
                      if (ques?.options?.length > 1) {
                        deleteAnswer(i, mainIndex);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mt-2">
                <UploadProfile
                  theme={"person"}
                  value={option.image}
                  title={t("product:uploadImage")}
                  subTitle={t("fileInput:removePicture")}
                  onChange={(e) => onChangeImage(e, i, mainIndex)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PollItem;
