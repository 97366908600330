import { instance } from "../libs/client";

class CompanyService {
  async activeCompany() {
    return instance.get("company/active");
  }

  async getCompany() {
    return instance.get("company/info");
  }

  async getCCount() {
    return instance.get("company/c-count");
  }

  async getCompanyAndGetCCount() {
    const companyData = await this.getCompany();
    const cCountData = await this.getCCount();
    return { companyData, cCountData };
  }

  async updateCompany(data) {
    return instance.put(`company/edit`, data);
  }
  async updateLanguage(data) {
    return instance.put(`company/edit/language`, data);
  }
}

const companyService = new CompanyService();
export { companyService };
