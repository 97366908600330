import React, { lazy } from "react";
import {
  ActiveCompanyIcon,
  ActiveDepartmentsIcon,
  ActiveEquipmentsIcon,
  ActiveExternalOperationIcon,
  ActiveInternalOperation,
  ActiveLanguageIcon,
  ActivePersonnelsIcon,
  ActiveShiftsIcon,
  CompanyIcon,
  DepartmentsIcon,
  EquipmentsIcon,
  ExternalOperationIcon,
  InternalOperationIcon,
  LanguageIcon,
  PersonnelsIcon,
  ShiftsIcon,
} from "../../../assets/icons/stepHeaderIcons";
import i18n from "../../../services/i18n";

const LazyLanguage = lazy(() => import("../../language/Language"));
const LazyCompany = lazy(() => import("../../company/Company"));
const LazyDepartment = lazy(() => import("../../department/Department"));
const LazyUsers = lazy(() => import("../../users/Users"));
const LazyProcess = lazy(() => import("../../process/Process"));
const LazyExternal = lazy(() => import("../../external/External"));
const LazyEquipment = lazy(() => import("../../equipments/Equipments"));
const LazyShift = lazy(() => import("../../shifts/Shifts"));
const LazyEndOfStep = lazy(() => import("../../end-of-steps/End-of-Steps"));

const Loading = () => {
  return <p>loading</p>;
};


export const stepData = [
  {
    path: "language",
    name: i18n?.language === "en" ? "Language" : "Dil",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyLanguage />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: LanguageIcon,
    activeIcon: ActiveLanguageIcon,
  },
  {
    path: "company",
    name: i18n?.language === "en" ? "Company" : "Firma",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyCompany />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: CompanyIcon,
    activeIcon: ActiveCompanyIcon,
  },
  {
    path: "department",
    name: i18n?.language === "en" ? "Departments" : "Departmanlar",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyDepartment />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: DepartmentsIcon,
    activeIcon: ActiveDepartmentsIcon,
  },
  {
    path: "users",
    name: i18n?.language === "en" ? "Personnels" : "Personeller",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyUsers />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: PersonnelsIcon,
    activeIcon: ActivePersonnelsIcon,
  },
  {
    path: "process",
    name: i18n?.language === "en" ? "Internal Op." : "İç Operasyon",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyProcess />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: InternalOperationIcon,
    activeIcon: ActiveInternalOperation,
  },
  {
    path: "external-operation",
    name: i18n?.language === "en" ? "External Op." : "Dış Operasyon",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyExternal />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: ExternalOperationIcon,
    activeIcon: ActiveExternalOperationIcon,
  },
  // {
  //   path: "/step/external-operation",
  //   name: "External Op.",
  //   element: ExternalOperation,
  //   icon: ExternalOperationIcon,
  //   activeIcon: ActiveExternalOperationIcon,
  // },
  {
    path: "equipment",
    name: i18n?.language === "en" ? "Equipments" : "Ekipmanlar",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyEquipment />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: EquipmentsIcon,
    activeIcon: ActiveEquipmentsIcon,
  },
  {
    path: "shift",
    name: i18n?.language === "en" ? "Shifts" : "Vardiya",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyShift />
      </React.Suspense>
    ),
    // errorElement: <Error />,
    icon: ShiftsIcon,
    activeIcon: ActiveShiftsIcon,
  },

  {
    path: "end-of-step",
    name: "End Of Step",
    element: () => (
      <React.Suspense fallback={<Loading />}>
        <LazyEndOfStep />
      </React.Suspense>
    ),
    icon: ShiftsIcon,
    activeIcon: ActiveShiftsIcon,
    visible: false,
    borderRight: false,
    // errorElement: <Error />,
  },
];

export default function StepHeaderTab() {
  return <></>;
}
