import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons/Button";
import { useQuery } from "@tanstack/react-query";
import { embeddedServerService } from "../../../services/embedded-server.service";
import Selector from "../../../components/inputs/Selector";
import CustomToggle from "./CustomToggle";
import UnitInput from "./UnitInput";
import { Toast } from "../../../utils/toastify/toast";
import AnalogSensorChart from "./AnalogSensorChart";
import { FilterIcon, NoFilterIcon, TickIcon, UpuTower } from "./svg";
import Input from "../../../components/inputs/Input";
import { Loading, RefreshIcon, SaveIcon } from "./svg/EmbeddedServerIcons";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { DangerIcon } from "../../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";

const AnalogSensorSettings = ({
  onClose,
  deviceId,
  metadataId,
  whichSensor,
  registeredSensor,
  getRegisteredSensors,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [firstStepData, setFirstStepData] = useState(null);
  const currentStep = () => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            setStep={setStep}
            onClose={onClose}
            deviceId={deviceId}
            whichSensor={whichSensor}
            setFirstStepData={setFirstStepData}
            registeredSensor={registeredSensor}
            t={t}
          />
        );
      case 1:
        if (
          firstStepData?.maxValue &&
          firstStepData?.deviceId &&
          firstStepData?.currentSensorType &&
          firstStepData?.sensorType &&
          firstStepData?.status
        ) {
          return (
            <SecondStep
              setStep={setStep}
              onClose={onClose}
              deviceId={deviceId}
              metadataId={metadataId}
              whichSensor={whichSensor}
              firstStepData={firstStepData}
              registeredSensor={registeredSensor}
              getRegisteredSensors={getRegisteredSensors}
              t={t}
            />
          );
        } else {
          setStep(0);
          Toast("error", t("embedded:makeSure"));
        }
    }
  };

  return (
    <div className="w-[852px] max-w-[852px] h-[84vh] flex flex-col mt-8">
      <div className="w-full flex items-center justify-between gap-x-4">
        <div className={`w-1/2 border-t-4 border-[#363F72] pt-2 flex flex-col`}>
          <p className="font-semibold text-[#363F72]">General</p>
          <p className="text-sm text-[#363F72]">
            Sensor type, status, value type etc.
          </p>
        </div>
        <div
          className={`w-1/2 border-t-4 pt-2 flex flex-col ${
            step === 1 ? "border-[#363F72]" : "border-secondary-200"
          }`}
        >
          <p
            className={`font-semibold ${
              step === 1 ? "text-[#363F72]" : "text-secondary-600"
            }`}
          >
            Advanced
          </p>
          <p className="text-sm text-[#363F72]">
            Instant sensor graph and settings
          </p>
        </div>
      </div>
      <div className="flex flex-col flex-1 w-full mt-8">{currentStep()}</div>
    </div>
  );
};

const FirstStep = ({
  setStep,
  onClose,
  deviceId,
  whichSensor,
  setFirstStepData,
  registeredSensor,
  t,
}) => {
  const [status, setStatus] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [unitType, setUnitType] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [valueType, setValueType] = useState(false);
  const [sensorType, setSensorType] = useState(null);
  const { data, isLoading } = useQuery({
    retry: 0,
    queryKey: "sensor-types",
    queryFn: async () => await embeddedServerService.getSensorTypes(),
  });
  const handleFindUnit = (whichSensorState) => {
    if (data?.analog?.filter((f) => f.id === whichSensorState)) {
      return data?.analog
        ?.filter((f) => f?.id === whichSensorState)
        ?.find((x) => x)?.unitList;
    }
  };
  const nextStep = () => {
    const inputValues = {
      status,
      deviceId,
      sensorType,
      whichSensor,
      maxValue: Number(maxValue),
      minValue: Number(minValue),
      currentSensorType: valueType ? "volt_sensor" : "ma_sensor",
    };
    setFirstStepData(inputValues);
    setStep(1);
  };

  const sensorTypes = data?.analog?.map((item) => {
    return { label: item?.name, value: item?.id };
  });

  const statuses = [
    { label: "Fault", value: "fault" },
    { label: "Close", value: "close" },
    { label: "Working", value: "working" },
    { label: "Standby", value: "standby" },
  ];

  useEffect(() => {
    if (registeredSensor) {
      setStatus(registeredSensor?.status);
      setMaxValue(registeredSensor?.maxValue);
      setMinValue(registeredSensor?.minValue);
      setSensorType(registeredSensor?.type?.id);
      setValueType(
        registeredSensor?.currentSensorType === "volt_sensor" ? true : false
      );
    }
  }, [registeredSensor]);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex items-center justify-between gap-x-4">
        <Selector
          isLoading={isLoading}
          label={t("embedded:sensorType")}
          items={sensorTypes}
          value={sensorType}
          onChange={setSensorType}
        />
        <Selector
          label={t("embedded:status")}
          items={statuses}
          value={status}
          onChange={setStatus}
        />
      </div>
      <div className="flex w-full mt-5">
        <CustomToggle
          width={418}
          enable={valueType}
          firstText={"4-20 mA"} // ma_sensor
          secondText={"0-333 mV"} // volt_sensor
          setEnable={setValueType}
          label={t("embedded:valueType")}
        />
      </div>
      <div className="w-full flex items-center justify-between gap-x-4 mt-5">
        <UnitInput
          value={maxValue}
          unitValue={unitType}
          setValue={setMaxValue}
          setUnitValue={setUnitType}
          label={t("embedded:maxValue")}
          units={handleFindUnit(sensorType)}
        />
        <UnitInput
          value={minValue}
          unitValue={unitType}
          setValue={setMinValue}
          setUnitValue={setUnitType}
          label={t("embedded:minValue")}
          units={handleFindUnit(sensorType)}
        />
      </div>
      <div className="flex items-center w-full justify-end gap-x-4 mt-auto">
        <Button
          size={"md"}
          width={177}
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          onClick={onClose}
        />
        <Button
          size={"md"}
          width={177}
          label={t("buttons:next")}
          colorType={"primary-embedded"}
          onClick={() => {
            nextStep();
          }}
        />
      </div>
    </div>
  );
};

const SecondStep = ({
  setStep,
  onClose,
  deviceId,
  metadataId,
  whichSensor,
  firstStepData,
  registeredSensor,
  getRegisteredSensors,
  t,
}) => {
  const [cosFi, setCosFi] = useState(1);
  const [phase, setPhase] = useState(1);
  const [closeBy, setCloseBy] = useState(0);
  const [standBy, setStandBy] = useState(0);
  const [voltage, setVoltage] = useState(380);
  const [forgetMax, setForgetMax] = useState(0);
  const [forgetmin, setForgetmin] = useState(0);
  const [timeDelay, setTimeDelay] = useState(1.5);
  const [activeKva, setActiveKva] = useState(false);
  const [instantData, setInstandData] = useState({});
  const [resetAlert, setResetAlert] = useState(false);
  const [goBackAlert, setGoBackAlert] = useState(false);
  const [samplingNumber, setSamplingNumber] = useState(0);
  const [activeTimeDelay, setActiveTimeDelay] = useState(false);
  const [functionLoading, setFunctionLoading] = useState(false);
  const [isIntegralValue, setIsIntegralValue] = useState(false);

  const getChartData = async (count) => {
    const data = {
      deviceId,
      whichSensor,
      value: count,
      samplingNumber: Number(samplingNumber),
      maxValue: Number(firstStepData?.maxValue),
      minValue: Number(firstStepData?.minValue),
      currentSensorType: firstStepData?.currentSensorType,
    };
    await embeddedServerService.getRealTimeChartData(data);
  };

  const resetSensor = async () => {
    const values = {
      deviceId,
      whichSensor,
      status: "reset",
      metadata: metadataId,
    };
    await embeddedServerService.resetAnalog(values).then((res) => {
      Toast("success", t("embedded:registerDeleted"));
      getChartData(0);
      setResetAlert(false);
      getRegisteredSensors();
    });
  };
  const saveSensorData = async () => {
    setFunctionLoading(true);
    const data = [
      {
        deviceId,
        whichSensor,
        sensorStatus: true,
        metadata: metadataId,
        cosFi: Number(cosFi),
        phase: Number(phase),
        activateKva: activeKva,
        closeBy: Number(closeBy),
        standBy: Number(standBy),
        voltage: Number(voltage),
        topCrop: Number(forgetMax),
        timeDelay: Number(timeDelay),
        status: firstStepData?.status,
        bottomCrop: Number(forgetmin),
        type: firstStepData?.sensorType,
        isIntegralValue: isIntegralValue,
        samplingNumber: Number(samplingNumber),
        maxValue: Number(firstStepData?.maxValue),
        minValue: Number(firstStepData?.minValue),
        currentSensorType: firstStepData?.currentSensorType,
      },
    ];
    await embeddedServerService.saveAnalog(data).then((res) => {
      setFunctionLoading(false);
      getRegisteredSensors();
      getChartData(0);
      onClose();
    });
  };

  const towerDinamicColors = ({ standBy, closeBy, current }) => {
    if (current > standBy) {
      return "#32D583";
    } else if (current > closeBy) {
      return "#FAC515";
    } else if (current < closeBy) {
      return "#D6D6D6";
    }
  };

  useEffect(() => {
    if (registeredSensor) {
      setPhase(registeredSensor?.phase);
      setCosFi(registeredSensor?.cosFi);
      setCloseBy(registeredSensor?.closeBy);
      setVoltage(registeredSensor?.voltage);
      setStandBy(registeredSensor?.standBy);
      setForgetMax(registeredSensor?.topCrop);
      setTimeDelay(registeredSensor?.timeDelay);
      setForgetmin(registeredSensor?.bottomCrop);
      setActiveKva(registeredSensor?.activateKva);
      setSamplingNumber(registeredSensor?.samplingNumber);
      setIsIntegralValue(registeredSensor?.isIntegralValue);
    }
  }, [registeredSensor]);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <AnalogSensorChart
          phase={phase}
          standBy={standBy}
          closeBy={closeBy}
          setStep={setStep}
          deviceId={deviceId}
          whichSensor={whichSensor}
          getChartData={getChartData}
          minValue={firstStepData?.minValue}
          maxValue={firstStepData?.maxValue}
          activeKva={activeKva}
          currentValues={(values) => {
            setInstandData(values);
          }}
        />
        <div className="w-full flex items-center mt-6">
          <div className="flex-1 h-full flex flex-col gap-y-5">
            <div className="w-full flex gap-x-4 items-end">
              <div className="w-[211px]">
                <Input
                  label={"Closeby"}
                  value={closeBy}
                  onChange={({ target }) => {
                    setCloseBy(target?.value);
                  }}
                />
              </div>
              <div className="w-[211px]">
                <Input
                  label={"Standby"}
                  value={standBy}
                  onChange={({ target }) => {
                    setStandBy(target?.value);
                  }}
                />
              </div>
              <div className="w-[126px] h-[58px] rounded-lg border border-gray-300 bg-white py-2 px-3 flex">
                <FilterIcon
                  width={18}
                  height={18}
                  color="#344054"
                  className="mt-3"
                />
                <div className="flex flex-col ml-2.5">
                  <p className="text-gray-500 text-xs truncate">
                    {t("embedded:realTimeFiltered")}
                  </p>
                  <p className="text-gray-900 font-medium truncate mt-1">
                    {instantData?.filterIntegralValue
                      ? instantData?.filterIntegralValue[
                          instantData?.filterIntegralValue.length - 1
                        ]
                      : "--"}
                  </p>
                </div>
              </div>
              <div className="w-[135px] h-[58px] rounded-lg border border-gray-300 bg-white p-2 flex">
                <NoFilterIcon
                  width={20}
                  height={20}
                  color="#344054"
                  className="mt-3 min-h-[20px] min-w-[20px]"
                />
                <div className="flex flex-col ml-2.5">
                  <p className="text-gray-500 text-xs truncate">
                    {t("embedded:unfilteredRealTime")}
                  </p>
                  <p className="text-gray-900 font-medium truncate mt-1">
                    {instantData?.rawIntegralValue
                      ? instantData?.rawIntegralValue[
                          instantData?.rawIntegralValue.length - 1
                        ]
                      : "--"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-x-4">
              <div className="w-1/3">
                <Input
                  label={"Sampling Number"}
                  value={samplingNumber}
                  onChange={({ target }) => {
                    setSamplingNumber(target?.value);
                  }}
                />
              </div>
              <div className="w-1/3">
                <Input
                  label={"Forget Max"}
                  value={forgetMax}
                  onChange={({ target }) => {
                    setForgetMax(target?.value);
                  }}
                />
              </div>
              <div className="w-1/3">
                <Input
                  label={"Forget Min"}
                  value={forgetmin}
                  onChange={({ target }) => {
                    setForgetmin(target?.value);
                  }}
                />
              </div>
            </div>

            {activeKva && (
              <div className="flex items-center gap-x-4">
                <div className="w-1/4">
                  <Input
                    label={"Voltage"}
                    value={voltage}
                    onChange={({ target }) => {
                      setVoltage(target?.value);
                    }}
                  />
                </div>
                <div className="w-1/4">
                  <Input
                    label={"Cos"}
                    value={cosFi}
                    onChange={({ target }) => {
                      setCosFi(target?.value);
                    }}
                  />
                </div>
                <div className="w-1/4">
                  <Input
                    label={"Faz"}
                    value={phase}
                    onChange={({ target }) => {
                      setPhase(target?.value);
                    }}
                  />
                </div>
                <div className="w-1/4">
                  <Input
                    disabled={!activeTimeDelay}
                    label={"Time Delay (sn)"}
                    value={timeDelay}
                    onChange={({ target }) => {
                      setTimeDelay(target?.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="mt-auto flex w-full items-center">
              <div
                onClick={() => {
                  setActiveKva(!activeKva);
                }}
                className="items-center flex select-none cursor-pointer group"
              >
                <div
                  className={`${
                    activeKva ? "border-[#3E4784]" : "border-gray-300"
                  } flex items-center justify-center w-5 h-5 rounded border`}
                >
                  {activeKva && (
                    <TickIcon
                      width={12}
                      height={12}
                      className="text-[#3E4784]"
                    />
                  )}
                </div>
                <p className="text-sm font-medium text-gray-700 ml-2 select-none group-hover:underline">
                  {"Activate kVA"}
                </p>
              </div>
              <div
                onClick={() => {
                  setActiveTimeDelay(!activeTimeDelay);
                }}
                className="items-center flex select-none cursor-pointer group ml-[44px]"
              >
                <div
                  className={`${
                    activeTimeDelay ? "border-[#3E4784]" : "border-gray-300"
                  } flex items-center justify-center w-5 h-5 rounded border`}
                >
                  {activeTimeDelay && (
                    <TickIcon
                      width={12}
                      height={12}
                      className="text-[#3E4784]"
                    />
                  )}
                </div>
                <p className="text-sm font-medium text-gray-700 ml-2 select-none group-hover:underline">
                  Activate Time Delay
                </p>
              </div>
              <div
                onClick={() => {
                  setIsIntegralValue(!isIntegralValue);
                }}
                className="items-center flex select-none cursor-pointer group ml-[44px]"
              >
                <div
                  className={`${
                    isIntegralValue ? "border-[#3E4784]" : "border-gray-300"
                  } flex items-center justify-center w-5 h-5 rounded border`}
                >
                  {isIntegralValue && (
                    <TickIcon
                      width={12}
                      height={12}
                      className="text-[#3E4784]"
                    />
                  )}
                </div>
                <p className="text-sm font-medium text-gray-700 ml-2 select-none group-hover:underline">
                  {"Analog Integral Value"}
                </p>
              </div>
            </div>
          </div>

          <div className="w-[72px] ml-[48px] flex mb-auto">
            <UpuTower
              color={towerDinamicColors({
                closeBy,
                standBy,
                current: instantData?.rawIntegralValue
                  ? instantData?.rawIntegralValue[
                      instantData?.rawIntegralValue.length - 1
                    ]
                  : 0,
              })}
            />
          </div>
        </div>
        <div className="flex items-center w-full mt-auto pt-5">
          <Button
            width={205}
            size={"md"}
            iconLeft={<RefreshIcon />}
            label={t("buttons:resetSensorSettings")}
            colorType={"secondary-error"}
            onClick={() => setResetAlert(true)}
          />
          <div className="flex ml-auto gap-x-4">
            <Button
              size={"md"}
              width={177}
              label={t("buttons:prev")}
              colorType={"secondary-gray"}
              onClick={() => {
                if (registeredSensor) {
                  setStep(0);
                  getChartData(0);
                } else {
                  setGoBackAlert(true);
                }
              }}
            />
            <Button
              size={"md"}
              width={177}
              label={
                functionLoading ? (
                  <Loading color={"#fff"} size={22} secondary={"#3E4784"} />
                ) : (
                  <p>{t("buttons:save")}</p>
                )
              }
              iconLeft={!functionLoading && <SaveIcon />}
              colorType={"primary-embedded"}
              onClick={() => {
                saveSensorData();
              }}
            />
          </div>
        </div>
      </div>
      <AlertModal
        isOpen={resetAlert}
        setIsOpen={setResetAlert}
        title={t("embedded:resetSensor")}
        subTitle={t("embedded:willBeReset")}
        label={t("buttons:reset")}
        applyButton={resetSensor}
      />
      <AlertModal
        label={t("buttons:previous")}
        isOpen={goBackAlert}
        icon={<DangerIcon />}
        setIsOpen={setGoBackAlert}
        title={t("embedded:changesNotSaved")}
        subTitle={t("embedded:areYouSureChanges")}
        applyButton={() => {
          setStep(0);
          getChartData(0);
        }}
      />
    </>
  );
};

export default AnalogSensorSettings;
