import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import {
  AcceptedIcon,
  PurchaseIcons,
  RejectedIcon,
} from "../../../assets/icons/productIcons";

const StockPurchaseCard = ({
  rawMaterialName = "---",
  allData,
  materialStockPiece = "--- ",
  acceptedPiece = "--",
  rejectedPiece = "--",
}) => {
  const { t } = useTranslation();
  return (
    <div
      data-tooltip-id={`${allData}`}
      data-tooltip-content={`
  ${t("product:rawMaterialName")} : ${rawMaterialName.slice(0,3)+"***"} 
  
  `}
      data-tooltip-place="right"
      className="py-2 pl-3 pr-2 border border-secondary-300 rounded-lg w-[294px] flex items-start flex-col relative"
    >
      <div className="flex flex-row items-center gap-x-3 w-full">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-row items-center gap-x-2">
            <PurchaseIcons />
            <p className="text-sm font-semibold text-secondary-900 w-[140px] truncate">
              {t("product:purchasing")}
            </p>
          </div>
          <div className="flex flex-row items-center h-[22px] w-full border-b border-b-secondary-200 mt-2">
            <div className="w-[50%] py-[2px] pr-[6px]">
              <p className="text-xs font-normal text-secondary-600">
                {t("product:rawMaterialNames")}
              </p>
            </div>
            <span className="h-full w-[1px] bg-secondary-200"></span>
            <div className="w-[50%] flex flex-row items-center gap-1 py-[2px]">
              <p className="text-xs font-medium text-secondary-600 w-[100px] truncate pl-[6px]">
                {rawMaterialName}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center h-[22px] w-full ">
            <div className="w-[50%] py-[2px] pr-[6px]">
              <p className="text-xs font-normal text-secondary-600">
                {t("product:rawMaterialStock")}
              </p>
            </div>
            <span className="h-full w-[1px] bg-secondary-200"></span>
            <div className="w-[50%] flex flex-row items-center gap-1 py-[2px]">
              <p className="text-xs font-medium text-secondary-600 pl-[6px]">
                {materialStockPiece}
              </p>
              <p className="text-[10px] font-normal text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
          </div>{" "}
        </div>
        {/* kalite yeni ui */}
        {/* <div className="flex flex-col border border-secondary-200 bg-white  w-[84px] min-w-[84px] min-h-[76px] max-h-[76px] h-[76px]  rounded">
          <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
            <QualityIcon />
            <p className="text-secondary-700 font-semibold text-[10px]">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex flex-col  items-center justify-center">
            <div className="flex gap-x-[2px] p-1 items-center">
              <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                <AcceptedIcon />
              </div>
              <span className="text-xs font-medium text-[#079455]">
                {acceptedPiece}
              </span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
            <div className="flex gap-x-[2px] p-1 items-center">
              <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                <RejectedIcon />
              </div>
              <span className="text-xs font-medium text-[#D92D20]">
                {rejectedPiece}
              </span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
          </div>
        </div> */}
        <div className="w-[84px] min-w-[84px] border border-secondary-200 px-[6px] pt-[2px] pb-1 flex flex-col rounded-md gap-y-2 ml-auto">
          <div className="flex flex-row justify-center items-center w-full gap-x-1">
            <span className="w-2 h-2 rounded-[2px] bg-[#17B26A]"></span>
            <p className="text-xs font-normal text-secondary-500">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
              <AcceptedIcon />
            </div>
            <p className="text-xs font-normal text-success-600">
              {acceptedPiece}
            </p>
            <p className="text-[10px] font-normal text-secondary-500">
              {t("product:pcs")}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
              <RejectedIcon />
            </div>
            <p className="text-xs font-normal text-error-600">
              {rejectedPiece}
            </p>
            <p className="text-[10px] font-normal text-secondary-500">
              {t("product:pcs")}
            </p>
          </div>
        </div>
      </div>
      <Tooltip id={`${allData}`} />
    </div>
  );
};

export default StockPurchaseCard;

const QualityIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g mask="url(#mask0_13331_70317)">
        <path
          d="M11.1016 7C11.1016 9.26524 9.26524 11.1016 7 11.1016C4.73476 11.1016 2.89844 9.26524 2.89844 7C2.89844 4.73476 4.73476 2.89844 7 2.89844C9.26524 2.89844 11.1016 4.73476 11.1016 7Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.05078 5.76953L6.58984 8.23047L4.94922 6.58984"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.46805 0.606006C6.77463 0.344873 7.22537 0.344873 7.53194 0.606006L8.59789 1.54677L10.4017 1.40718C10.8032 1.37612 11.1679 1.64105 11.2624 2.0325L11.6241 3.53028L13.2001 4.4737C13.5432 4.68457 13.6825 5.11327 13.5289 5.4855L12.9062 6.99424L13.5289 8.50299C13.6825 8.87525 13.5432 9.30394 13.2001 9.51482L11.6241 10.4582L11.2624 11.956C11.1679 12.3474 10.8032 12.6124 10.4017 12.5813L8.61552 12.4431L7.53194 13.394C7.22537 13.6551 6.77463 13.6551 6.46805 13.394L5.38448 12.4431L3.59827 12.5813C3.19676 12.6124 2.83207 12.3474 2.73757 11.956L2.37448 10.4523L0.801062 9.51482C0.457953 9.30394 0.318664 8.87525 0.472308 8.50299L1.09493 6.99424L0.472308 5.4855C0.318664 5.11327 0.457953 4.68457 0.801062 4.4737L2.23962 3.61907L2.73757 2.0325C2.83207 1.64105 3.19676 1.37612 3.59827 1.40718L5.40214 1.54677L6.46805 0.606006Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
