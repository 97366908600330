import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import Junction from "../junction/Junction";
import SearchInput from "../inputs/SearchInput";
import {
  InternalOperationIcons,
  QuantityIcons,
} from "../../assets/icons/machineIcons";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../services/product.service";
import diacritics from "diacritics";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CalendarIcon } from "../../assets/icons/PersonIcons";
import { Tooltip } from "react-tooltip";
import { ReactComponent as NoDataProduct } from "../../assets/icons/NoData/NoDataProduct.svg";

export default function SelectProductModal({
  filteredData,
  setIsOpenPlanningModal,
  setDraggedProduct,
  setIsOpenSelectProductModal,
}) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");

  const filteredOrders = filteredData.filter((order) =>
    diacritics
      .remove(`${order?.internalOperation?.product?.name}`)
      .toLowerCase()
      .includes(diacritics.remove(searchValue).toLowerCase())
  );

  return (
    <div className="flex w-[350px] flex-col h-full gap-y-6 pt-6">
      <div className="flex flex-col gap-y-4">
        <SearchInput setSearchVal={setSearchValue} />
        <div className="flex flex-col max-h-[330px] min-h-[330px] overflow-y-auto scrollbar-hide gap-y-4 border-b pb-4 items-center">
          {filteredOrders?.map((item) => {
            const statusColor = () => {
              switch (item?.status) {
                case "finished":
                  return "#12B76A";
                case "started":
                  return "#FDE172";
                case "rejected":
                  return "#D92D20";
                default:
                  return "#D0D5DD";
              }
            };
            return (
              <>
                <div
                  onClick={() => {
                    setIsOpenPlanningModal(true);
                    setDraggedProduct(item);
                    setIsOpenSelectProductModal(false);
                  }}
                  key={item?.id}
                  style={{ borderTopColor: statusColor() }}
                  className="w-[330px] min-w-[330px] h-[72px] min-h-[72px] rounded-lg  border border-l-gray-300 border-b-gray-300 border-r-gray-300 border-t-4 shadow-sm p-2 flex items-center bg-white cursor-grab "
                >
                  <img
                    className="w-[72px] h-[54px] rounded border border-gray-300 select-none"
                    src={generateFileUrl(
                      item?.internalOperation?.product?.image
                    )}
                  />
                  <div className="flex flex-col ml-2.5 flex-1 overflow-hidden">
                    <p
                      title={item?.internalOperation?.product?.name}
                      className="text-sm font-semibold text-secondary-900 truncate"
                    >
                      {item?.internalOperation?.product?.name || "Product Name"}
                    </p>
                    <p
                      title={item?.orderData?.orderNo}
                      className="text-xs text-gray-600 truncate"
                    >
                      {t("product:orderNo")}: {item?.orderData?.orderNo}
                    </p>
                    <div className="w-full overflow-hidden flex items-center justify-between">
                      <div
                        title={moment(item?.orderData?.deliveryDate).format(
                          "DD.MM.YY"
                        )}
                        className="flex items-center gap-0.5 w-[42%] overflow-hidden"
                      >
                        <CalendarIcon />
                        <p className="text-xs text-gray-600 truncate">
                          {moment(item?.orderData?.deliveryDate).format(
                            "DD.MM.YY"
                          )}
                        </p>
                      </div>
                      <div
                        title={item?.orderData?.piece}
                        className="flex items-center gap-0.5 w-[35%] justify-center overflow-hidden"
                      >
                        <QtyLight />
                        <p className="text-xs text-gray-600 truncate">
                          {item?.orderData?.piece}
                        </p>
                      </div>
                      <div className="flex items-center gap-0.5 overflow-hidden w-[28%]">
                        <div className="h-[22px] flex items-center px-1.5 border rounded-md border-[#FEDF89] bg-[#FFFAEB] ml-auto">
                          <p className="text-xs font-medium text-[#B54708]">
                            {item?.internalOperation?.step}
                            .op
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          {filteredOrders?.length === 0 && (
            <div className="flex flex-col w-full items-center mt-20">
              <NoDataProduct />
              <p className="font-semibold text-xl text-secondary-900 mt-6">
                Data Not Found
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const QtyLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.3335H3.71429C2.76751 2.3335 2 3.10101 2 4.04778V11.8692C2 12.816 2.76751 13.5835 3.71429 13.5835H12.2857C13.2325 13.5835 14 12.816 14 11.8692V4.04778C14 3.10101 13.2325 2.3335 12.2857 2.3335H10.25M5.75 2.3335V6.69481C5.75 6.75853 5.81706 6.79997 5.87405 6.77147L8 5.7085L10.126 6.77147C10.1829 6.79997 10.25 6.75853 10.25 6.69481V2.3335M5.75 2.3335H10.25"
        stroke="#475467"
      />
    </svg>
  );
};
