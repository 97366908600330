import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";

import moment from "moment";
import { timeFormatter } from "../../utils/timezoneFormatter";
import MultipleEventRect from "./components/MultipleEventRect";

import { SingleEventRect } from "./components/SingleEventRect";

import { formatSeconds, secondToHoursAndMinute2 } from "../../utils/secondToHoursAndMinute";

import ApprovedCard from "../junction/ApprovedCard";

import { authStore } from "../../stores/auth.store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import LossesCardType from "../junction/LossesCardType";
import { NotFound404 } from "../../assets/illustrations";
import Button from "../buttons/Button";

const EquipmentUpuTimeline = observer(
  ({
    data,
    startTime,
    endTime,
    refetch,

    //kontrols
    setStartTime,
    setEndTime,
    setSelectedJunction,
    selectedJunction,
    defaultStartTime,
    defaultEndTime,

    //grouped data
    groupedDatas,

    //junctionDatas
    timelineChart,
    junctionData,

    //last
    timelineValues,
    setTimelineValues,
    shiftValues,
    setShiftValues,
    PastJunctionData,
    date,
  }) => {
    const { width, height } = useWindowDimensions();
    const _ = require("lodash");
    const { t } = useTranslation();
    const [emptyTimes, setEmptyTimes] = useState([]);

    const [shiftDetected, setShiftDetected] = useState(false);

    const [selectedDayType, setSelectedDayType] = useState(1);

    const [selectedShift, setSelectedShift] = useState(null);

    const [showTooltip, setShowTooltip] = useState(null);
    const [mouseX, setMouseX] = useState(null);

    const totalWidth = width;

    const totalWidthh = totalWidth - 240;
    let svgWidth = window.innerWidth - 240;
    let svgHeight = 125;

    const handleMouseOver = (index, xPos, yPos, e) => {
      if (index.index == 0) {
        junctionData.filter((e) => {
          if (e.id == index.junctionId) {
            setShowTooltip({ index, xPos, yPos, junction: e });
          } else if (index?.lossesId) {
            setShowTooltip({ index, xPos, yPos });
          }
        });
      } else {
        setShowTooltip({ index, xPos, yPos });
      }
    };

    const handleMouseMove = (e) => {
      setMouseX(e.clientX);
    };

    const handleMouseOut = () => {
      if (showTooltip) {
        setShowTooltip(null);
      }
    };

    const handleShiftClick = (index) => {
      if (selectedJunction) {
        setStartTime(new Date(timeFormatter(selectedJunction?.startDate)?.formatted).valueOf());
        setEndTime(new Date(timeFormatter(selectedJunction?.endDate)?.formatted).valueOf());
      } else {
        setStartTime(new Date(timeFormatter(index.startDate)?.formatted).valueOf());
        setEndTime(new Date(timeFormatter(index.endDate)?.formatted).valueOf());
      }
    };

    const junctionNumber = (item) => {
      const junctions = junctionData?.filter((a) => a.status != "planned");

      let sortedJunctions = junctions?.sort((a, b) => moment(a?.startDate)?.unix() - moment(b?.startDate)?.unix());

      const datas = sortedJunctions?.map((value, index) => {
        if (value?.id == item) {
          return index + 1;
        } else {
          return "";
        }
      });

      const lastData = datas?.filter((a) => a != "");

      return lastData[0];
    };
    const [nodeId, setNodeId] = useState(null);
    const calculateData = async (data) => {
      const datas = [];

      data?.map((value, no) => {
        if (value?.nodeId) {
          setNodeId(value?.nodeId);
        }
        value?.value.map((da, i) => {
          datas.push({
            name: value.name,
            type: da?.index == 1 ? "status" : da.index == 2 ? "shift" : da.index == 0 ? "junction" : "",
            junctionId: da?.junctionId,
            lossesId: da?.lossesId,
            actionId: da?.actionId,
            productData: da?.productName,
            operationNo: da?.operationNo,
            lossesName: da?.lossesName,
            startDate: da?.start,
            endDate: da?.end,
            timeDiff: da?.timeDiff,
            index: da?.index,
            junctionType: da?.type,
            value: [
              da?.index,

              /// moment(da.start).unix(),
              // moment(da.end).unix(),
              moment(timeFormatter(da.start, authStore?.user?.company?.timeZone).formatted)?.unix(),
              moment(timeFormatter(da.end, authStore?.user?.company?.timeZone).formatted)?.unix(),
              da.timeDiff,
            ],
            shiftId: da?.shiftId,
            color:
              da?.index == 1
                ? value?.itemStyle?.normal?.color
                : da?.index == 2
                ? value?.itemStyle?.normal?.color
                : da?.index == 0
                ? da?.type == "setup"
                  ? "#EAAA08"
                  : da.type == "losses"
                  ? "#D92D20"
                  : "#12B76A"
                : value?.itemStyle?.normal?.color,
          });
        });
      });

      const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);
      const lastValues = _.uniqWith(Valuess, _.isEqual);

      let timelineDatas = Valuess?.filter((item) => {
        return item?.index != 2;
      });

      let shiftValues = lastValues?.filter((item) => {
        return item?.index == 2;
      });

      await setShiftValues(shiftValues);
      await setTimelineValues(timelineDatas);
    };

    // useEffect(() => {
    //   if (selectedJunction) {
    //     handleShiftClick(selectedJunction);
    //   }
    // }, [selectedJunction]);

    useEffect(() => {
      calculateData(timelineChart);
    }, []);

    const [invalidShiftDetected, setInvalidShiftDetected] = useState(false);
    useEffect(() => {
      const checkShifts = () => {
        const invalidShiftExists = shiftValues.some((shift) => {
          const shiftStartTime = new Date(timeFormatter(shift?.startDate)?.formatted)?.getTime();
          return isNaN(shiftStartTime) || shiftStartTime === undefined;
        });

        if (invalidShiftExists) {
          setInvalidShiftDetected(true);
        }
      };

      checkShifts();
    }, [shiftValues]);

    return (
      <div
        className=" w-full flex flex-col  border border-[#D0D5DD] rounded-xl  pl-4  bg-white"
        style={{
          width: totalWidthh - 150,
        }}
      >
        {invalidShiftDetected ? (
          <div className="w-full h-full flex items-center justify-center min-h-[150px] flex-col py-4 gap-y-4">
            <NotFound404 width={150} height={60} />
            <p className="text-sm font-semibold">We encountered a problem, please try again.</p>
            <Button
              label={"Yeniden Dene"}
              size={"sm"}
              width={width / 7}
              colorType={"primary-machine"}
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        ) : (
          <>
            <div className="w-full  rounded-t-lg  flex items-center  mt-2 mb-2  ">
              <span className="isolate inline-flex rounded-lg">
                <button
                  onClick={() => {
                    setStartTime(defaultStartTime);
                    setEndTime(defaultEndTime);
                    setSelectedDayType(1);
                    setSelectedJunction(null);
                    refetch();
                  }}
                  type="button"
                  className="relative inline-flex items-center rounded-l-lg  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  style={{
                    backgroundColor: selectedDayType == 1 ? "#F9FAFB" : "white",
                  }}
                >
                  {t("gauge:allDay")}
                </button>

                {Object?.values(groupedDatas)
                  ?.sort((a, b) => moment(a?.start)?.unix() - moment(b?.start)?.unix())
                  ?.map((shift, index) => {
                    return (
                      <button
                        onClick={() => {
                          setStartTime(new Date(timeFormatter(shift?.start)?.formatted).valueOf());
                          setEndTime(new Date(timeFormatter(shift?.end)?.formatted).valueOf());
                          setSelectedDayType(shift?.shiftId);
                        }}
                        type="button"
                        className="relative -ml-px inline-flex items-center  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300  focus:z-10 last:rounded-r-md "
                        style={{
                          backgroundColor: selectedDayType == shift?.shiftId ? "#F9FAFB" : "white",
                        }}
                      >
                        <span
                          className={`w-3 h-3 rounded-sm mr-2`}
                          style={{
                            backgroundColor: shift?.shift?.color,
                          }}
                        ></span>
                        {index == 0 ? "Day shift" : "Night shift"}
                      </button>
                    );
                  })}
              </span>
            </div>

            <div className="w-full flex flex-col  relative pr-3 ">
              <svg width={totalWidth - 170} height={svgHeight}>
                <svg width={svgWidth} height="115" y={15}>
                  <g clip-path="url(#clip0_12199_84484)">
                    {/* {[...new Array(200)].map((d, index) => (
             <line x1={index * 10} y1="15.353553" x2={(index *10 ) - 150}  y2="150.354" stroke="#EAECF0" opacity={1}/>
              ))} */}
                  </g>
                </svg>

                {shiftValues?.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(timeFormatter(shift?.startDate)?.formatted)?.getTime();

                  const shiftEndTime = new Date(timeFormatter(endDate)?.formatted)?.getTime();

                  const timeRange = endTime - startTime;

                  const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                  const shiftWidth = selectedShift === index ? svgWidth : ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                  return (
                    <>
                      <SingleEventRect
                        key={index}
                        startPos={shiftX}
                        width={shiftWidth}
                        fill={color}
                        onClick={() => handleShiftClick(shift)}
                        style={{ cursor: "pointer" }}
                        event={shift}
                        svgHeight={svgHeight}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                      />
                      {shiftWidth > 15 && (
                        <>
                          <rect
                            key={index}
                            x={shiftX}
                            y={22}
                            width={shiftWidth}
                            height={svgHeight - 10}
                            fill={shift?.color == "#FFFFFF" ? "#FFFFFF" : shift?.color}
                            opacity={0.2}
                            onClick={() => handleShiftClick(shift)}
                            // onMouseOver={() => handleMouseOver(event, startPos)}
                            // onMouseOut={handleMouseOut}
                            style={{ cursor: "pointer" }}
                            ry={"4"}
                          ></rect>
                        </>
                      )}
                    </>
                  );
                })}

                {timelineValues?.map((shift, index) => {
                  const { startDate, endDate, color, timeDiff } = shift;
                  const shiftStartTime = new Date(timeFormatter(startDate)?.formatted)?.getTime();
                  const shiftEndTime = new Date(timeFormatter(endDate)?.formatted)?.getTime();

                  const timeRange = endTime - startTime;

                  if (selectedShift !== null && selectedShift !== index) {
                    return null;
                  }

                  const shiftX = selectedShift === index ? 0 : ((shiftStartTime - startTime) / timeRange) * svgWidth;

                  const statusWidth = ((shiftEndTime - shiftStartTime) / timeRange) * svgWidth;

                  let yOffset = 70;
                  const modifiedShift = {
                    ...shift,
                    startDate: moment(shift?.startDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(shift?.endDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"),
                    nodeId: nodeId,
                  }; // Shift'i kopyala ve tarihleri güncelle
                  const dateToSend = shift?.endDate || new Date();
                  return (
                    <>
                      <MultipleEventRect
                        key={index}
                        yOffset={yOffset}
                        startPos={shiftX}
                        width={statusWidth}
                        widthS={statusWidth}
                        event={shift}
                        onClick={() => {
                          if (selectedJunction === modifiedShift?.junctionId) {
                            setSelectedJunction(!selectedJunction);
                            setStartTime(defaultStartTime);
                            setEndTime(defaultEndTime);
                            refetch();
                          } else {
                            PastJunctionData(modifiedShift, dateToSend);
                            handleShiftClick(shift);
                          }
                        }}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleMouseMove={handleMouseMove}
                        junctionNumber={junctionNumber}
                      />
                    </>
                  );
                })}
              </svg>

              {showTooltip && showTooltip?.index?.index == 0 && showTooltip?.index?.junctionId ? (
                <div
                  style={{
                    right: totalWidthh - mouseX < 600 ? "68%" : "4%",
                    top: showTooltip.yPos - 90,
                  }}
                  className={`absolute z-50 bg-white p-2  rounded-lg shadow-md `}
                >
                  <ApprovedCard
                    step={showTooltip.junction?.header?.step}
                    key={showTooltip.junction?.id}
                    id={showTooltip.junction?.id}
                    equipmentName={showTooltip.junction?.job?.product?.name}
                    equipmentImage={showTooltip.junction?.job?.product?.image}
                    qualityAcceptedPiece={showTooltip.junction?.metadata?.madeWorkCount}
                    qualityRejectedPiece={showTooltip.junction?.metadata?.rejectedWorkCount}
                    oneOrderTime={formatSeconds(
                      (
                        moment(showTooltip.junction?.endDate).diff(showTooltip.junction?.startDate, "seconds") /
                          showTooltip.junction?.metadata?.madeWorkCount || 0
                      ).toFixed(0)
                    )}
                    user={showTooltip.junction?.user}
                    status={showTooltip.junction?.status}
                    totalJunctionTime={secondToHoursAndMinute2(showTooltip.junction?.startDate, showTooltip.junction?.endDate || new Date())}
                    qualityPercent={showTooltip.junction?.metadata?.qualityPercent}
                    estimatedTrue={
                      (
                        moment(showTooltip.junction?.endDate).diff(showTooltip.junction?.startDate, "seconds") / showTooltip.junction?.metadata?.madeWorkCount
                      ).toFixed(0) < showTooltip.junction?.operations?.internalOperation?.estimatedTime
                        ? true
                        : false
                    }
                  />
                </div>
              ) : showTooltip && showTooltip?.index?.lossesId ? (
                <div
                  style={{
                    right: totalWidthh - mouseX < 600 ? "68%" : "4%",
                    top: showTooltip.yPos - 90,
                  }}
                  className={`absolute z-50 bg-white p-2  rounded-lg shadow-md  `}
                >
                  <LossesCardType
                    title={showTooltip?.index?.lossesName || "Undefined Loss"}
                    subTitle={showTooltip?.index?.reasonDescription || "Unkown"}
                    startTime={moment(timeFormatter(showTooltip?.index?.startDate).formatted).format("HH:mm")}
                    endTime={moment(timeFormatter(showTooltip?.index?.endDate).formatted).format("HH:mm")}
                    id={showTooltip?.index?.id}
                  />
                </div>
              ) : showTooltip && showTooltip?.index?.index == 1 ? (
                <div
                  className={`absolute z-50 bg-white p-2  rounded-lg shadow-md`}
                  style={{
                    left: (showTooltip?.xPos + 30) * 2 > totalWidthh ? showTooltip?.xPos - 280 : showTooltip?.xPos + 40,
                    top: showTooltip?.yPos - 140,
                    borderTop: "5px solid " + showTooltip?.index?.color,
                  }}
                >
                  <div className="flex items-center mb-1 ">
                    <div
                      style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: showTooltip?.index?.color,
                        borderRadius: 2,
                      }}
                    ></div>

                    <p
                      className="text-md font-semibold"
                      style={{
                        color: showTooltip?.index?.color === "#FFFFFF" ? "black" : showTooltip?.index?.color,
                        marginLeft: "6px",
                      }}
                    >
                      {showTooltip?.index?.name}
                    </p>
                  </div>
                  <div className="flex flex-col w-full items-center justify-center">
                    <div className="flex w-full border-b border-[#EAECF0]">
                      <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">
                        Start-End Time
                      </div>
                      <div className="flex min-w-[142px] max-w-[172px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                        <span>{moment(timeFormatter(showTooltip?.index?.startDate).formatted).format("HH:mm:ss")}</span>-
                        <span>{moment(timeFormatter(showTooltip?.index?.endDate).formatted).format("HH:mm:ss")}</span>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex min-w-[110px] max-w-[110px] border-r border-[#EAECF0] text-[#475467] text-sm font-normal py-1 pr-2">Total Time</div>
                      <div className="flex min-w-[142px] max-w-[172px] gap-x-1 text-[#475467] font-medium text-sm py-1 pl-2">
                        <span>
                          {formatSeconds(
                            moment(timeFormatter(showTooltip?.index?.endDate).formatted).diff(
                              moment(timeFormatter(showTooltip?.index?.startDate).formatted),
                              "seconds"
                            )
                          )}
                        </span>
                        -
                        <span>
                          {moment(timeFormatter(showTooltip?.index?.endDate).formatted).diff(
                            moment(timeFormatter(showTooltip?.index?.startDate).formatted),
                            "seconds"
                          ) +
                            " " +
                            t("product:second")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
  }
);

export default EquipmentUpuTimeline;
