import React, { useRef, useState } from "react";
// import { div, StyleSheet, Text, Pressable } from "react-native";
// import { Feather, Octicons } from "@expo/vector-icons";
import CustomText from "../CustomText";
import CustomImage from "../CustomImage";
import NoAvatar from "../avatar/NoAvatar";
import { DotsVerticalIcon } from "./ActiveAndPlannedCard";
import {
  EquipmentDeleteIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  OperationDetailIcon,
  ProductDetailIcon,
} from "../../assets/icons/machineIcons";
import { useTranslation } from "react-i18next";
import { Toast } from "../../utils/toastify/toast";
import { junctionService } from "../../services/junction.service";
import AlertModal from "../alert-Modal/Alert-Modal";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import JunctionInfoModal from "../modal/JunctionInfoModal";
import CustomModal from "../modal/CustomModal";
import CustomJunctionInfoModal from "../modal/CustomJunctionInfoModal";

const junctionProps = {
  isSetting: "",
  theme: "",
  page: "",
  pages: "",
  status: "",
  startDate: "",
  ring: null, // default dark = "#424242" // default light = "#D0D5DD"
  time: 0,
  product: {
    name: "",
    image: "",
    code: "",
    piece: 0,
    id: "",
  },
  node: {
    name: "",
    id: "",
    image: "",
    process: "",
  },
  user: {
    image: "",
    id: "",
    name: "",
    lastName: "",
  },
};

const styles = {
  container: {
    height: 80,
    width: "100%",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
  base: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
  },
  box: {
    width: "49%",
    height: 44,
    borderWidth: 1,
    borderRadius: 4,
    borderTopWidth: 4,
    alignItems: "center",
    position: "relative",
    paddingLeft: "12px",
    paddingRight: "12px",
    flexDirection: "column",
    display: "flex",
  },
  image: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    filter: "blur(4px)",
  },
  squareButton: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    display: "flex",
    filter: "blur(4px)",
  },
  userContent: {
    position: "absolute",
    width: 50,
    height: 50,
    zIndex: 100,
    bottom: 32,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
  },
  avatar: {
    width: 50,
    height: 50,
    flexDirection: "column",
    borderRadius: 100,
    display: "flex",
    filter: "blur(4px)",
  },
  circleButton: {
    width: 50,
    height: 50,
    borderRadius: 100,
    flexDirection: "column",
    borderWidth: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    display: "flex",
    filter: "blur(4px)",
  },
  boldText: {
    fontSize: "11px",
    flexDirection: "column",
    fontWeight: "500",
    display: "flex",
    marginTop: "-2px",
    textAlign: "center",
  },
  lightText: {
    marginTop: 9,
    flexDirection: "column",
    fontSize: "11px",
    fontWeight: "400",
    display: "flex",
    textAlign: "center",
  },
  timeSection: {
    position: "absolute",
    display: "flex",
    top: 18,
    left: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  pieceSection: {
    position: "absolute",
    top: 18,
    right: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
};

const NewJunctionCard = ({
  props = junctionProps,
  step,
  junctionId,
  refetch,
  onPressNode = null,
  onPressUser = null,
  onPressProduct = null,
  avatarPress = null,
  key,
  onPressFinish = null,
  onPressOperation = null,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isAlertPlanned, setIsAlertPlanned] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const [deleteIdPlanned, setDeleteIdPlanned] = useState("");

  const navigate = useNavigate();
  const modalRef = useRef(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleButtonClick = () => {
    const rect = modalRef?.current?.getBoundingClientRect();
    const x = rect?.left + window?.scrollX;
    const y = rect?.top + window?.scrollY;
    setModalPosition({ top: y, left: x });
    setShowDropDown(!showDropDown);
  };

  const onDeleted = async () => {
    await junctionService.deleteJunction(junctionId, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(junctionId);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  const onDeletedPlanned = async () => {
    await junctionService
      .deleteStationForJunction(junctionId, deleteIdPlanned)
      .then((res) => {
        if (res.data.code == 0) {
          Toast("success", "Junction deleted");
          refetch();
          setIsAlertPlanned(false);
          setDeleteIdPlanned(junctionId);
        } else {
          Toast("error", "Junction not deleted");
          setIsAlertPlanned(false);
          setDeleteIdPlanned("");
        }
      });
  };

  return (
    <>
      <div className="group" style={styles.container}>
        <div style={styles.timeSection}>
          <ClockIcon />
          <CustomText
            text={props?.time || "--:--"}
            style={{
              color: props?.theme === "dark" ? "#D6D6D6" : "#667085",
              fontSize: 10,
              marginLeft: 2,
              marginBottom: 1,
            }}
          />
        </div>
        <div
          data-tooltip-id={`${props.status === "planned" ? "" : junctionId}`}
          data-tooltip-place={"top"}
          data-tooltip-content={`
        ${props?.user?.name.slice(0,3)+"***" + " " + props?.user?.lastName.slice(0,3)+"***"} 
        `}
          style={{ ...styles.userContent }}
        >
          <UserContoller
            avatarPress={props?.avatarPress}
            onPress={props?.onPressUser}
            theme={props?.theme}
            image={props?.user?.image}
            name={props?.user?.name}
            lastName={props?.user?.lastName}
            navigate={navigate}
            user={props?.user}
            startDate={props?.startDate}
          />
        </div>
        <div style={styles.base}>
          <div
            style={{
              ...styles.box,
              borderColor: props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#EAAA08"
                : "#D0D5DD",
              backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
              <PressedContoller
                theme={props?.theme}
                onPress={props?.onPressProduct}
                image={props?.product?.image}
                name={props?.product?.name}
                junctionId={junctionId}
              />
            </div>

            <CustomText
              text={
                props?.product?.code
                  ? props?.product?.code?.slice(0, 3) + " ***"
                  : "---"
              }
              numberOfLines={1}
              style={{
                ...styles.lightText,
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              text={
                props?.product?.name
                  ? props?.product?.name?.slice(0, 3) + " ***"
                  : "---"
              }
              numberOfLines={1}
              style={{
                ...styles.boldText,
                color: props?.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>

          <div
            className={`z-[100] absolute ${
              props.page === "activeWork" ? "flex" : "hidden"
            }  items-center justify-center left-[46%]  w-[28px] bottom-4 h-[24px] rounded border ${
              props.status === "waiting"
                ? "bg-[#98A2B3] border-[#98A2B3]"
                : "bg-[#DC6803] border-[#DC6803]"
            }`}
          >
            {props.status === "waiting" ? <ProductControl /> : ""}
          </div>

          {props?.page === "plannedIndex" ? (
            <></>
          ) : (
            <>
              <button
                ref={modalRef}
                onClick={(e) => {
                  e.stopPropagation();
                  handleButtonClick();
                }}
                className={`hidden absolute min-h-[24px] group-hover:flex max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white   right-[2px] top-[3px] z-[50] items-center justify-center cursor-pointer`}
              >
                <DotsVerticalIcon />
              </button>
            </>
          )}

          <div
            style={{
              ...styles.box,
              borderColor: props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#EAAA08"
                : "#D0D5DD",
              backgroundColor: props.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
              <PressedContoller
                theme={props?.theme}
                onPress={props?.onPressNode}
                image={props?.node?.image}
                name={props?.node?.name}
                junctionId={junctionId}
              />
            </div>

            <CustomText
              text={
                step
                  ? `(${step}.Op) ` + props?.node?.process
                  : props?.node?.process
              }
              numberOfLines={1}
              style={{
                ...styles.lightText,
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              // text={
              //   props?.node?.name
              //     ? props?.node?.name?.slice(0, 3) + " ***"
              //     : "---"
              // }
              text={
                step
                  ? `(${step}.Op) ` +
                    (props?.node?.process
                      ? props?.node?.process.slice(0, 3) + " ***"
                      : "---")
                  : props?.node?.process
                  ? props?.node?.process.slice(0, 3) + " ***"
                  : "---"
              }
              numberOfLines={1}
              style={{
                ...styles.boldText,
                color: props.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>
        </div>
        <div
          className={`${
            props?.page === "plannedIndex"
              ? "group-hover:flex"
              : "group-hover:hidden"
          } `}
          style={styles.pieceSection}
        >
          <CustomText
            text={props?.product?.piece || "--"}
            style={{
              color: props?.theme === "dark" ? "#D6D6D6" : "#667085",
              fontSize: 10,
              marginRight: 2,
              marginBottom: 2,
            }}
          />

          <ArchiveIcon />
        </div>
        <Tooltip className="z-[200]" id={`${junctionId}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
        }}
      />
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlertPlanned}
        setIsOpen={setIsAlertPlanned}
        applyButton={() => {
          // onDeletedPlanned(deleteIdPlanned);
        }}
      />
      <CustomJunctionInfoModal
        isOpen={showDropDown}
        setIsOpen={setShowDropDown}
        onClose={() => {
          setShowDropDown(false);
        }}
        modalRef={modalRef}
        modalPosition={modalPosition}
        children={
          <JunctionInfoModal
            refetch={refetch}
            setIsOpen={setShowDropDown}
            onClose={() => {
              setShowDropDown(false);
            }}
            onPressNode={onPressNode}
            onPressFinish={onPressFinish}
            onPressProduct={onPressProduct}
            onPressOperation={onPressOperation}
            showDropDown={showDropDown}
            props={props}
            modalRef={modalRef}
            setShowDropDown={setShowDropDown}
            setIsAlert={setIsAlert}
            setIsAlertPlanned={setIsAlertPlanned}
            status={props?.status}
          />
        }
      />
    </>
  );
};

const PressedContoller = ({ onPress, image, theme, name, junctionId }) => {
  if (!onPress) {
    return (
      <>
        {image ? (
          <div
            data-tooltip-id={`${junctionId}`}
            data-tooltip-place={"top"}
            data-tooltip-content={`
         ${name.slice(0,3)+"***"}
    `}
          >
            <CustomImage
              borderRadius={4}
              src={image}
              style={{
                ...styles.image,
                borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                backgroundColor: theme === "dark" ? "#000" : "#fff",
              }}
            />
          </div>
        ) : (
          <div
            className={
              "min-w-[60px] min-h-[45px] max-w-[60px] rounded max-h-[45px] bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
            }
          >
            {/* {text?.name.toUpperCase()} */}
          </div>
        )}
      </>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onPress}
        className="outline-none"
        style={{
          ...styles.squareButton,
          borderColor: theme === "dark" ? "#424242" : "#D0D5DD",
          backgroundColor: theme === "dark" ? "#141414" : "#fff",
        }}
      >
        <PlusIcon />
      </button>
    );
  }
};
const UserContoller = ({
  onPress,
  image,
  theme,
  avatarPress,
  name,
  lastName,
  user,
  navigate,
  startDate,
}) => {
  if (!onPress) {
    return (
      <div
        className={avatarPress && "cursor-pointer"}
        onClick={() => {
          if (avatarPress) {
            avatarPress();
          }
        }}
      >
        {image ? (
          <div
            onClick={() => {
              navigate(
                `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                  startDate
                ).format("YYYY-MM-DD")}`
              );
            }}
            className="cursor-pointer"
          >
            <CustomImage
              src={image}
              style={{
                ...styles.avatar,
                borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                backgroundColor: theme === "dark" ? "#000" : "#fff",
              }}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              navigate(
                `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                  startDate
                ).format("YYYY-MM-DD")}`
              );
            }}
            className="cursor-pointer"
          >
            <NoAvatar size={50} name={name} lastName={lastName} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onPress}
        className="outline-none"
        style={{
          ...styles.circleButton,
          borderColor: theme === "dark" ? "#424242" : "#D0D5DD",
          backgroundColor: theme === "dark" ? "#141414" : "#fff",
        }}
      >
        <PlusIcon />
      </button>
    );
  }
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 5.5V19.5M5 12.5H19"
        stroke="#98A2B3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ArchiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#A3A3A3"
        stroke-width="0.75"
      />
    </svg>
  );
};

const ProductControl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.7364L13.327 2.21297C13.1636 1.53731 12.5378 1.08687 11.8516 1.14087L9.98257 1.28553L8.91188 0.337528C8.38454 -0.112441 7.61732 -0.112441 7.08816 0.337059L6.01745 1.2855L4.14838 1.14084C3.46998 1.09325 2.83598 1.53771 2.68995 2.15753C2.65973 2.25146 2.11388 3.94818 2.14932 3.83797L0.671758 4.71321C0.0798834 5.07668 -0.157242 5.80634 0.10779 6.44815L0.745446 7.99356L0.108258 9.53853C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7737L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53853L15.2544 7.99359L15.8925 6.44771C16.1571 5.8064 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1562C5.15685 13.1562 2.84376 10.8432 2.84376 8C2.84376 5.15684 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15684 13.1563 8C13.1563 10.8432 10.8432 13.1562 8.00001 13.1562Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_1100_1592)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#A3A3A3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1100_1592">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(NewJunctionCard);
