import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Selector from "../../../components/inputs/Selector";
import { rawMaterialService } from "../../../services/raw-material.service";
import { Toast } from "../../../utils/toastify/toast";

export default function NewRawMaterialsModal({
  t,
  setIsOpen,
  update,
  refetch,
}) {
  const units = [
    { label: "Adet", shortLabel: "(AD)", value: "adet" },
    { label: "Metre", shortLabel: "(MT)", value: "metre" },
    { label: "Metrekare", shortLabel: "(M2)", value: "metrekare" },
    { label: "Metreküp", shortLabel: "(M3)", value: "metrekup" },
    { label: "Kilogram", shortLabel: "(KG)", value: "kilogram" },
    { label: "Gram", shortLabel: "(G)", value: "gram" },
    { label: "Litre", shortLabel: "(L)", value: "litre" },
    { label: "Set", shortLabel: "(ST)", value: "set" },
  ];

  const rawMaterials = [
    { label: "Çubuk", value: "stick" },
    { label: "Lama", value: "lama" },
    { label: "Boru", value: "pipe" },
  ];

  const materalSource = [
    { label: t("product:rawMaterialCompany"), value: "in" },
    { label: t("product:purchasedRawMaterial"), value: "out" },
  ];

  const schema = yup.object({
    price: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    source: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    materials: yup
      .string()
      // .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    unit: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  useEffect(() => {
    if (update?.id) {
      setValue("name", update?.name);
      setValue("source", update?.source);
      setValue("materials", update?.type);
      setValue("unit", update?.unit);
      setValue("price", update?.price);
      setValue("description", update?.description);
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="min-w-[352px] max-w-[352px]"
    >
      <div className="flex flex-col gap-4 mt-5">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("product:rawMaterialName")}
              onChange={onChange}
              onBlur={onBlur}
              theme={"product"}
              value={value}
              validate={errors?.name ? "error" : ""}
              errorMessage={errors?.name ? errors?.name?.message : ""}
            />
          )}
          name="name"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Selector
              value={value}
              items={materalSource}
              theme={"product"}
              label={t("product:rawMaterialSource")}
              onChange={onChange}
              onBlur={onBlur}
              validate={errors?.source ? "error" : ""}
              errorMessage={errors?.source ? errors?.source?.message : ""}
            />
          )}
          name="source"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Selector
              value={value}
              items={rawMaterials}
              theme={"product"}
              label={t("product:rawMaterialType")}
              onChange={onChange}
              onBlur={onBlur}
              validate={errors?.source ? "error" : ""}
              errorMessage={errors?.materials ? errors?.materials?.message : ""}
            />
          )}
          name="materials"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Selector
              value={value}
              items={units}
              theme={"product"}
              label={t("product:unit")}
              onChange={onChange}
              onBlur={onBlur}
              validate={errors?.source ? "error" : ""}
              errorMessage={errors?.unit ? errors?.unit?.message : ""}
            />
          )}
          name="unit"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("product:price")}
              onBlur={onBlur}
              value={value}
              theme={"product"}
              onChange={onChange}
              validate={errors?.price ? "error" : ""}
              errorMessage={errors?.price ? errors?.price?.message : ""}
            />
          )}
          name="price"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("person:description")}
              onChange={onChange}
              onBlur={onBlur}
              theme={"product"}
              value={value}
              validate={errors?.description ? "error" : ""}
              errorMessage={
                errors?.description ? errors?.description?.message : ""
              }
            />
          )}
          name="description"
        />
        <div className="flex w-full gap-3">
          <Button
            colorType={"secondary-gray"}
            label={t("buttons:stop")}
            onClick={() => setIsOpen(false)}
          />
          <Button
            colorType={"primary-product"}
            label={update?.id ? t("buttons:update") : t("buttons:create")}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
}
