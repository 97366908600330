import React from "react";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

const VerySoonProduct = () => {
  let navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <div>
        <svg
          width="300"
          height="234"
          viewBox="0 0 300 234"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M300 196.939H0V197.089H300V196.939Z" fill="#EBEBEB" />
          <path
            d="M266.333 202.653H246.46V202.803H266.333V202.653Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.799 204.683H78.8818V204.833H108.799V204.683Z"
            fill="#EBEBEB"
          />
          <path
            d="M245.86 208.225H228.145V208.375H245.86V208.225Z"
            fill="#EBEBEB"
          />
          <path
            d="M44.4338 207.217H31.4756V207.367H44.4338V207.217Z"
            fill="#EBEBEB"
          />
          <path
            d="M72.7998 207.217H49.7754V207.367H72.7998V207.217Z"
            fill="#EBEBEB"
          />
          <path
            d="M198.732 201.025H142.526V201.175H198.732V201.025Z"
            fill="#EBEBEB"
          />
          <path
            d="M142.208 170.18H26.3486C24.4604 170.18 22.9238 168.643 22.9238 166.755V3.89648C22.9238 2.00828 24.4604 0.47168 26.3486 0.47168H142.208C144.096 0.47168 145.632 2.00828 145.632 3.89648V166.756C145.632 168.644 144.096 170.18 142.208 170.18ZM26.3486 0.62168C24.5426 0.62168 23.0738 2.09048 23.0738 3.89648V166.756C23.0738 168.562 24.5426 170.03 26.3486 170.03H142.208C144.013 170.03 145.482 168.562 145.482 166.756V3.89648C145.482 2.09108 144.013 0.62168 142.208 0.62168H26.3486Z"
            fill="#EBEBEB"
          />
          <path
            d="M271.986 170.18H156.127C154.239 170.18 152.703 168.643 152.703 166.755V3.89648C152.703 2.00828 154.24 0.47168 156.127 0.47168H271.986C273.875 0.47168 275.411 2.00828 275.411 3.89648V166.756C275.412 168.644 273.875 170.18 271.986 170.18ZM156.127 0.62168C154.322 0.62168 152.853 2.09048 152.853 3.89648V166.756C152.853 168.562 154.322 170.03 156.127 170.03H271.986C273.792 170.03 275.261 168.562 275.261 166.756V3.89648C275.261 2.09108 273.792 0.62168 271.986 0.62168H156.127Z"
            fill="#EBEBEB"
          />
          <path
            d="M170.693 124.234H261.36C265.32 124.234 268.56 120.994 268.56 117.034V22.367C268.56 18.407 265.32 15.167 261.36 15.167H170.693C166.733 15.167 163.493 18.407 163.493 22.367V117.034C163.493 120.994 166.733 124.234 170.693 124.234Z"
            fill="#FAFAFA"
          />
          <path
            d="M244.926 46.2998C249.51 46.2998 253.226 42.5838 253.226 38C253.226 33.4161 249.51 29.7002 244.926 29.7002C240.342 29.7002 236.626 33.4161 236.626 38C236.626 42.5838 240.342 46.2998 244.926 46.2998Z"
            fill="#F0F0F0"
          />
          <path
            d="M223.197 46.2998C227.781 46.2998 231.497 42.5838 231.497 38C231.497 33.4161 227.781 29.7002 223.197 29.7002C218.613 29.7002 214.897 33.4161 214.897 38C214.897 42.5838 218.613 46.2998 223.197 46.2998Z"
            fill="white"
          />
          <path
            d="M223.905 111.642C207.572 111.642 194.284 98.3544 194.284 82.0212C194.284 65.688 207.572 52.4004 223.905 52.4004C240.239 52.4004 253.526 65.688 253.526 82.0212C253.526 98.3544 240.238 111.642 223.905 111.642ZM223.905 53.0004C207.903 53.0004 194.884 66.0192 194.884 82.0212C194.884 98.0232 207.903 111.042 223.905 111.042C239.908 111.042 252.926 98.0232 252.926 82.0212C252.926 66.0192 239.907 53.0004 223.905 53.0004Z"
            fill="white"
          />
          <path
            d="M225.186 141.899C215.137 141.899 206.778 149.141 205.045 158.691C204.248 163.079 203.466 167.47 202.405 171.802L196.211 197.088H254.162L247.968 171.802C246.907 167.47 246.125 163.079 245.328 158.691C243.595 149.141 235.236 141.899 225.186 141.899Z"
            fill="#E0E0E0"
          />
          <path
            d="M228.386 141.899C218.336 141.899 209.978 149.141 208.245 158.691C207.448 163.079 206.666 167.47 205.605 171.802L199.411 197.088H257.362L251.168 171.802C250.108 167.47 249.325 163.079 248.528 158.691C246.794 149.141 238.436 141.899 228.386 141.899Z"
            fill="#E6E6E6"
          />
          <path
            d="M101.284 44.5993C102.613 39.0799 108.216 35.6515 113.735 36.9805L206.711 59.3671C212.231 60.6961 215.659 66.2989 214.33 71.8183C213.001 77.3377 207.398 80.7661 201.879 79.4371L108.903 57.0505C103.384 55.7215 99.9552 50.1187 101.284 44.5993Z"
            fill="#E0E0E0"
          />
          <path
            d="M103.079 43.4997C104.408 37.9803 110.011 34.5519 115.53 35.8809L208.506 58.2675C214.026 59.5965 217.454 65.1993 216.125 70.7187C214.796 76.2381 209.193 79.6665 203.674 78.3375L110.698 55.9509C105.179 54.6219 101.75 49.0191 103.079 43.4997Z"
            fill="#EBEBEB"
          />
          <path
            d="M115.553 46.5029C116.236 43.6637 119.118 41.9003 121.958 42.5837L199.734 61.3103C202.573 61.9937 204.336 64.8761 203.653 67.7153C202.97 70.5545 200.087 72.3179 197.248 71.6345L119.472 52.9078C116.633 52.2244 114.869 49.3421 115.553 46.5029Z"
            fill="#E0E0E0"
          />
          <path
            d="M103.284 44.4085C98.0919 39.2167 90.9201 37.2133 84.1995 38.3971C77.9223 39.5029 75.5811 47.3233 80.0883 51.8305L95.8623 67.6045C100.37 72.1117 108.189 69.7705 109.295 63.4933C110.48 56.7721 108.476 49.5997 103.284 44.4085Z"
            fill="#F5F5F5"
          />
          <path
            d="M66.9393 113.503V107.503C81.6891 107.503 93.6891 95.5031 93.6891 80.7527C93.6891 66.0029 81.6891 54.0029 66.9393 54.0029C52.1895 54.0029 40.1895 66.0029 40.1895 80.7527H34.1895C34.1895 62.6939 48.8805 48.0029 66.9393 48.0029C84.9981 48.0029 99.689 62.6939 99.689 80.7527C99.689 98.8109 84.9981 113.503 66.9393 113.503Z"
            fill="#EBEBEB"
          />
          <path
            d="M84.7243 62.9681C86.8489 65.0927 90.4393 65.2565 94.1929 63.7691C97.6987 62.3795 99.9463 57.8903 98.1019 56.0459L91.6471 49.5911C89.8027 47.7467 85.3135 49.9943 83.9239 53.5001C82.4359 57.2531 82.6003 60.8435 84.7243 62.9681Z"
            fill="#F5F5F5"
          />
          <path
            d="M103.285 51.0076C106.93 51.0076 109.885 48.0527 109.885 44.4076C109.885 40.7625 106.93 37.8076 103.285 37.8076C99.6395 37.8076 96.6846 40.7625 96.6846 44.4076C96.6846 48.0527 99.6395 51.0076 103.285 51.0076Z"
            fill="#FAFAFA"
          />
          <path
            d="M234.872 160.071L231.025 161.529C224.071 164.164 216.225 160.631 213.589 153.676L181.969 70.2324C179.334 63.2784 182.867 55.4322 189.822 52.797L193.669 51.339C200.623 48.7038 208.469 52.2372 211.104 59.1918L242.725 142.636C245.36 149.59 241.827 157.436 234.872 160.071Z"
            fill="#E0E0E0"
          />
          <path
            d="M238.073 160.071L234.226 161.529C227.272 164.164 219.426 160.631 216.791 153.676L185.17 70.2324C182.535 63.2784 186.068 55.4322 193.023 52.797L196.87 51.339C203.824 48.7038 211.67 52.2372 214.305 59.1918L245.926 142.636C248.561 149.59 245.027 157.436 238.073 160.071Z"
            fill="#F0F0F0"
          />
          <path
            d="M229.469 149.802C229.199 149.802 228.737 149.645 228.572 149.211L196.953 65.7682C196.868 65.5456 196.88 65.3104 196.99 65.0698C197.098 64.8298 197.266 64.6642 197.488 64.5802L201.335 63.1222C201.435 63.0844 201.528 63.0664 201.627 63.0664C201.897 63.0664 202.359 63.2236 202.523 63.6574L234.143 147.1C234.314 147.551 234.059 148.118 233.608 148.289L229.761 149.746C229.661 149.784 229.568 149.802 229.469 149.802Z"
            fill="#E0E0E0"
          />
          <path
            d="M235.087 141.899C225.037 141.899 216.679 149.141 214.945 158.691C214.148 163.079 213.366 167.47 212.305 171.802L206.111 197.088H264.062L257.869 171.802C256.808 167.47 256.025 163.079 255.229 158.691C253.495 149.141 245.137 141.899 235.087 141.899Z"
            fill="#E0E0E0"
          />
          <path
            d="M238.287 141.899C228.237 141.899 219.878 149.141 218.145 158.691C217.349 163.079 216.566 167.47 215.505 171.802L209.312 197.088H267.263L261.069 171.802C260.008 167.47 259.226 163.079 258.429 158.691C256.695 149.141 248.337 141.899 238.287 141.899Z"
            fill="#F5F5F5"
          />
          <path
            d="M210.71 77.0944C216.29 71.514 216.29 62.4664 210.71 56.8861C205.13 51.3057 196.082 51.3057 190.502 56.8861C184.921 62.4664 184.921 71.514 190.502 77.0944C196.082 82.6748 205.13 82.6748 210.71 77.0944Z"
            fill="#FAFAFA"
          />
          <path
            d="M200.608 78.597C194.207 78.597 189 73.3896 189 66.9894C189 60.5892 194.207 55.3818 200.608 55.3818C207.008 55.3818 212.215 60.5892 212.215 66.9894C212.215 73.3896 207.008 78.597 200.608 78.597ZM200.608 55.9434C194.517 55.9434 189.562 60.8988 189.562 66.9894C189.562 73.08 194.517 78.0354 200.608 78.0354C206.698 78.0354 211.653 73.08 211.653 66.9894C211.654 60.8988 206.698 55.9434 200.608 55.9434Z"
            fill="#E0E0E0"
          />
          <path
            d="M85.226 157.7H43.3262V196.939H85.226V157.7Z"
            fill="#F5F5F5"
          />
          <path
            d="M30.7262 196.939H43.3262L43.3262 157.701H30.7262L30.7262 196.939Z"
            fill="#E6E6E6"
          />
          <path
            d="M45.5257 157.7H81.1729V133.567H45.5257V157.7Z"
            fill="#F0F0F0"
          />
          <path
            d="M91.8925 133.566H81.1729V157.7H91.8925V133.566Z"
            fill="#E0E0E0"
          />
          <path
            d="M104.411 196.939H135.426V160.492H104.411V196.939Z"
            fill="#E6E6E6"
          />
          <path
            d="M132.393 193.322H107.445V164.109H132.393V193.322ZM108.045 192.722H131.793V164.709H108.045V192.722Z"
            fill="#F5F5F5"
          />
          <path
            d="M144.752 160.492H135.426V196.939H144.752V160.492Z"
            fill="#F5F5F5"
          />
          <path
            d="M26.5987 117.2H57.6133L57.6133 80.7532H26.5987L26.5987 117.2Z"
            fill="#E6E6E6"
          />
          <path
            d="M66.9397 80.7529H57.6133V117.2H66.9397V80.7529Z"
            fill="#F5F5F5"
          />
          <path
            d="M150 224.037C214.25 224.037 266.334 220.995 266.334 217.243C266.334 213.491 214.25 210.449 150 210.449C85.7512 210.449 33.667 213.491 33.667 217.243C33.667 220.995 85.7512 224.037 150 224.037Z"
            fill="#F5F5F5"
          />
          <path
            d="M94.7211 134.479H150.997V128.848H94.7211C93.1659 128.848 91.9053 130.108 91.9053 131.663C91.9053 133.219 93.1659 134.479 94.7211 134.479Z"
            fill="#263238"
          />
          <path
            d="M116.465 216.444H120.054L120.054 134.48H116.465L116.465 216.444Z"
            fill="#DC6803"
          />
          <path
            opacity="0.7"
            d="M116.465 216.444H120.054L120.054 134.48H116.465L116.465 216.444Z"
            fill="white"
          />
          <path
            d="M98.6575 216.444H102.246L102.246 134.48H98.6575L98.6575 216.444Z"
            fill="#DC6803"
          />
          <path
            opacity="0.7"
            d="M98.6575 216.444H102.246L102.246 134.48H98.6575L98.6575 216.444Z"
            fill="white"
          />
          <path
            d="M94.7218 133.491C95.7314 133.491 96.55 132.673 96.55 131.663C96.55 130.653 95.7314 129.835 94.7218 129.835C93.7121 129.835 92.8936 130.653 92.8936 131.663C92.8936 132.673 93.7121 133.491 94.7218 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M100.933 131.663C100.933 132.673 101.751 133.491 102.761 133.491C103.771 133.491 104.589 132.673 104.589 131.663C104.589 130.653 103.771 129.835 102.761 129.835C101.751 129.835 100.933 130.653 100.933 131.663Z"
            fill="#DC6803"
          />
          <path
            d="M110.8 133.491C111.81 133.491 112.628 132.673 112.628 131.663C112.628 130.653 111.81 129.835 110.8 129.835C109.79 129.835 108.972 130.653 108.972 131.663C108.972 132.673 109.79 133.491 110.8 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M118.839 133.491C119.849 133.491 120.667 132.673 120.667 131.663C120.667 130.653 119.849 129.835 118.839 129.835C117.829 129.835 117.011 130.653 117.011 131.663C117.011 132.673 117.829 133.491 118.839 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M126.879 133.491C127.889 133.491 128.707 132.673 128.707 131.663C128.707 130.653 127.889 129.835 126.879 129.835C125.869 129.835 125.051 130.653 125.051 131.663C125.051 132.673 125.869 133.491 126.879 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M134.918 133.491C135.928 133.491 136.746 132.673 136.746 131.663C136.746 130.653 135.928 129.835 134.918 129.835C133.908 129.835 133.09 130.653 133.09 131.663C133.09 132.673 133.908 133.491 134.918 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M142.957 133.491C143.967 133.491 144.785 132.673 144.785 131.663C144.785 130.653 143.967 129.835 142.957 129.835C141.947 129.835 141.129 130.653 141.129 131.663C141.129 132.673 141.947 133.491 142.957 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M150.997 129.835V133.491C149.987 133.491 149.169 132.673 149.169 131.663C149.168 130.653 149.987 129.835 150.997 129.835Z"
            fill="#DC6803"
          />
          <path
            d="M136.335 68.3457H194.062C196.783 68.3457 198.988 70.5507 198.988 73.2711V216.444H131.41V73.2711C131.41 70.5507 133.615 68.3457 136.335 68.3457Z"
            fill="#DC6803"
          />
          <path
            opacity="0.2"
            d="M150.11 111.799H131.41V111.244H149.555V68.3457H150.11V111.799Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M183.516 68.3457H194.062C196.783 68.3457 198.988 70.5507 198.988 73.2711V216.444H178.591V73.2711C178.591 70.5507 180.796 68.3457 183.516 68.3457Z"
            fill="#263238"
          />
          <path
            d="M185.085 92.917H192.493C193.813 92.917 194.884 93.9874 194.884 95.3074V140.404H182.695V95.3068C182.695 93.9868 183.765 92.917 185.085 92.917Z"
            fill="#DC6803"
          />
          <path
            d="M185.086 92.917H191.506C192.826 92.917 193.897 93.9874 193.897 95.3074V140.404H182.695V95.3068C182.695 93.9868 183.766 92.917 185.086 92.917Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M178.591 208.373H157.673V140.743H178.591V141.299H158.228V207.818H178.591V208.373Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M149.664 208.373C147.734 208.373 146.164 206.803 146.164 204.873C146.164 202.943 147.734 201.373 149.664 201.373C151.594 201.373 153.164 202.943 153.164 204.873C153.164 206.803 151.594 208.373 149.664 208.373ZM149.664 201.929C148.04 201.929 146.72 203.249 146.72 204.873C146.72 206.496 148.04 207.818 149.664 207.818C151.287 207.818 152.609 206.496 152.609 204.873C152.609 203.249 151.287 201.929 149.664 201.929Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M140.31 208.373C138.38 208.373 136.81 206.803 136.81 204.873C136.81 202.943 138.38 201.373 140.31 201.373C142.24 201.373 143.81 202.943 143.81 204.873C143.81 206.803 142.24 208.373 140.31 208.373ZM140.31 201.929C138.686 201.929 137.365 203.249 137.365 204.873C137.365 206.496 138.686 207.818 140.31 207.818C141.934 207.818 143.254 206.496 143.254 204.873C143.255 203.249 141.934 201.929 140.31 201.929Z"
            fill="white"
          />
          <path
            d="M256.745 134.479H200.469V128.848H256.745C258.3 128.848 259.56 130.108 259.56 131.663C259.56 133.219 258.3 134.479 256.745 134.479Z"
            fill="#DC6803"
          />
          <path
            d="M238.971 134.479H182.695V128.848H238.971C240.526 128.848 241.787 130.108 241.787 131.663C241.787 133.219 240.526 134.479 238.971 134.479Z"
            fill="#263238"
          />
          <path
            d="M235.001 134.479H231.412V216.443H235.001V134.479Z"
            fill="#DC6803"
          />
          <path
            opacity="0.7"
            d="M235.001 134.479H231.412V216.443H235.001V134.479Z"
            fill="white"
          />
          <path
            d="M252.808 134.479H249.22V216.443H252.808V134.479Z"
            fill="#DC6803"
          />
          <path
            opacity="0.7"
            d="M252.808 134.479H249.22V216.443H252.808V134.479Z"
            fill="white"
          />
          <path
            d="M238.971 133.491C239.98 133.491 240.799 132.673 240.799 131.663C240.799 130.653 239.98 129.835 238.971 129.835C237.961 129.835 237.143 130.653 237.143 131.663C237.143 132.673 237.961 133.491 238.971 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M230.932 133.491C231.941 133.491 232.76 132.673 232.76 131.663C232.76 130.653 231.941 129.835 230.932 129.835C229.922 129.835 229.104 130.653 229.104 131.663C229.104 132.673 229.922 133.491 230.932 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M222.892 133.491C223.901 133.491 224.72 132.673 224.72 131.663C224.72 130.653 223.901 129.835 222.892 129.835C221.882 129.835 221.063 130.653 221.063 131.663C221.063 132.673 221.882 133.491 222.892 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M214.853 133.491C215.862 133.491 216.681 132.673 216.681 131.663C216.681 130.653 215.862 129.835 214.853 129.835C213.843 129.835 213.024 130.653 213.024 131.663C213.024 132.673 213.843 133.491 214.853 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M206.813 133.491C207.822 133.491 208.641 132.673 208.641 131.663C208.641 130.653 207.822 129.835 206.813 129.835C205.803 129.835 204.984 130.653 204.984 131.663C204.984 132.673 205.803 133.491 206.813 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M198.774 133.491C199.783 133.491 200.602 132.673 200.602 131.663C200.602 130.653 199.783 129.835 198.774 129.835C197.764 129.835 196.945 130.653 196.945 131.663C196.945 132.673 197.764 133.491 198.774 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M190.734 133.491C191.744 133.491 192.563 132.673 192.563 131.663C192.563 130.653 191.744 129.835 190.734 129.835C189.725 129.835 188.906 130.653 188.906 131.663C188.906 132.673 189.725 133.491 190.734 133.491Z"
            fill="#DC6803"
          />
          <path
            d="M182.695 129.835V133.491C183.705 133.491 184.524 132.673 184.524 131.663C184.524 130.653 183.705 129.835 182.695 129.835Z"
            fill="#DC6803"
          />
          <path
            d="M238.971 107.017H207.319V128.848H238.971V107.017Z"
            fill="#DC6803"
          />
          <path
            opacity="0.7"
            d="M221.063 107.017H215.405V115.04H221.063V107.017Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M238.971 107.017H229.104V128.848H238.971V107.017Z"
            fill="black"
          />
          <path
            opacity="0.7"
            d="M235.616 107.017H232.459V115.04H235.616V107.017Z"
            fill="#DC6803"
          />
          <path
            d="M213.407 122.761V126.325H209.843V122.761H213.407ZM213.962 122.205H209.287V126.88H213.962V122.205Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M220.605 122.205H215.93V126.88H220.605V122.205Z"
            fill="#263238"
          />
          <path
            d="M121.652 110.627H112.369C112.109 110.627 111.898 110.838 111.898 111.098V128.377C111.898 128.638 112.109 128.848 112.369 128.848H121.652C121.912 128.848 122.123 128.638 122.123 128.377V111.098C122.123 110.838 121.912 110.627 121.652 110.627Z"
            fill="#263238"
          />
          <path
            d="M121.106 110.627H111.824C111.564 110.627 111.353 110.838 111.353 111.098V128.377C111.353 128.638 111.563 128.848 111.824 128.848H121.106C121.367 128.848 121.577 128.638 121.577 128.377V111.098C121.577 110.838 121.367 110.627 121.106 110.627Z"
            fill="#DC6803"
          />
          <path
            d="M111.908 127.863V111.611C111.908 111.374 112.1 111.182 112.338 111.182H120.593C120.83 111.182 121.022 111.374 121.022 111.611V127.863C121.022 128.1 120.83 128.292 120.593 128.292H112.338C112.1 128.292 111.908 128.1 111.908 127.863Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M116.286 111.183H114.399L112.988 128.293H114.875L116.286 111.183Z"
            fill="#DC6803"
          />
          <path
            opacity="0.2"
            d="M118.173 111.183H116.954L115.543 128.293H116.761L118.173 111.183Z"
            fill="#DC6803"
          />
          <path
            d="M112.648 93.4077H97.4765C97.1525 93.4077 96.9389 93.1449 96.9995 92.8203L100.936 71.8101C100.997 71.4861 101.309 71.2227 101.633 71.2227H116.804C117.128 71.2227 117.342 71.4855 117.281 71.8101L113.345 92.8203C113.284 93.1449 112.972 93.4077 112.648 93.4077Z"
            fill="#DC6803"
          />
          <path
            d="M112.111 92.5299H97.8282C97.5228 92.5299 97.3218 92.2827 97.3788 91.9773L101.084 72.1971C101.141 71.8917 101.435 71.6445 101.741 71.6445H116.023C116.329 71.6445 116.53 71.8917 116.473 72.1971L112.767 91.9773C112.71 92.2827 112.416 92.5299 112.111 92.5299Z"
            fill="white"
          />
          <path
            d="M78.9155 72.6813L78.7841 72.9645L78.6257 73.3257L78.3083 74.0865C78.1043 74.6073 77.9075 75.1401 77.7335 75.6855C77.3813 76.7739 77.0525 77.8845 76.7933 79.0197C76.2425 81.2817 75.9077 83.6151 75.7037 85.9707L75.6329 86.8107C75.6155 86.9223 75.6269 86.9577 75.5795 86.8617C75.5411 86.7747 75.3665 86.5521 75.2003 86.4777C75.0401 86.3967 74.9933 86.4291 75.0617 86.4543C75.2141 86.5155 75.6719 86.5071 76.1441 86.4405C76.6283 86.3703 77.1587 86.2467 77.6969 86.0889C79.8629 85.4559 82.1591 84.4707 84.3485 83.4807L85.7993 85.7403C83.7503 87.3795 81.6479 88.8117 79.1495 89.9679C78.5195 90.2487 77.8595 90.5091 77.1281 90.7227C76.3859 90.9279 75.5903 91.1091 74.4983 91.0299C73.9505 90.9747 73.2863 90.8355 72.6029 90.4095C71.9141 89.9913 71.3387 89.2521 71.0885 88.5777C70.8191 87.8973 70.7579 87.3015 70.7441 86.7909L70.7459 85.7601C70.7621 83.1309 70.9985 80.4843 71.5295 77.8713C71.7845 76.5633 72.1235 75.2643 72.5423 73.9797C72.7499 73.3371 72.9857 72.6975 73.2389 72.0579C73.3709 71.7369 73.4969 71.4177 73.6511 71.0943L73.8851 70.6059C73.9721 70.4307 74.0393 70.2975 74.1737 70.0527L78.9155 72.6813Z"
            fill="#B55B52"
          />
          <path
            d="M82.4925 51.3047C83.1093 54.4505 84.4947 61.3139 82.2939 63.4127C82.2939 63.4127 83.1543 66.6041 88.9995 66.6041C95.4273 66.6041 92.0715 63.4133 92.0715 63.4133C88.5633 62.5757 88.6539 59.9729 89.2647 57.5279L82.4925 51.3047Z"
            fill="#B55B52"
          />
          <path
            d="M80.9797 64.5067C80.9797 64.5067 80.4163 62.7259 80.2081 61.6561C79.9999 60.5863 87.884 60.9469 90.4916 61.6159C93.0986 62.2849 93.9331 65.3431 93.9331 65.3431L80.9797 64.5067Z"
            fill="#263238"
          />
          <path
            d="M96.5522 50.5752C96.5522 50.5752 96.89 52.5252 97.4924 53.5566C96.833 53.958 95.9414 53.5278 95.9414 53.5278L96.5522 50.5752Z"
            fill="#A02724"
          />
          <path
            d="M95.6214 47.3558C95.5854 47.3438 95.5517 47.3234 95.5235 47.2946C95.4263 47.1962 95.427 47.0372 95.526 46.9394C96.5142 45.9632 97.65 46.196 97.698 46.2062C97.8336 46.2344 97.9193 46.3694 97.8899 46.505C97.8605 46.64 97.7274 46.7258 97.5924 46.697C97.5546 46.6892 96.6672 46.517 95.8788 47.2964C95.8092 47.366 95.7084 47.3852 95.6214 47.3558Z"
            fill="#263238"
          />
          <path
            d="M96.327 49.778C96.2406 50.1968 96.3924 50.582 96.666 50.639C96.9396 50.6954 97.2318 50.402 97.3188 49.9832C97.4052 49.5644 97.2534 49.1792 96.9798 49.1222C96.7056 49.0658 96.4134 49.3592 96.327 49.778Z"
            fill="#263238"
          />
          <path
            d="M96.8545 49.1207L97.9201 49.0391C97.9201 49.0391 97.2415 49.7177 96.8545 49.1207Z"
            fill="#263238"
          />
          <path
            d="M55.4961 216.135H60.7977L62.4897 203.857H57.1881L55.4961 216.135Z"
            fill="#B55B52"
          />
          <path
            d="M61.7012 212.496H55.745C55.5284 212.496 55.3526 212.647 55.322 212.858L54.6434 217.571C54.5732 218.06 54.9932 218.523 55.4936 218.514C57.5702 218.478 58.571 218.356 61.1906 218.356C62.8022 218.356 65.1566 218.523 67.382 218.523C69.5588 218.523 69.7262 216.323 68.7992 216.123C64.6442 215.23 63.9878 213.997 62.588 212.821C62.3354 212.61 62.0186 212.496 61.7012 212.496Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M57.1863 203.861H62.4903L61.6155 210.19H56.3115L57.1863 203.861Z"
            fill="black"
          />
          <path
            d="M75.6286 64.6675C73.48 65.3509 71.8672 73.4575 71.8672 73.4575L78.6784 79.4773C78.6784 79.4773 83.1376 71.5914 81.8374 68.9634C80.4838 66.2262 78.1684 63.8593 75.6286 64.6675Z"
            fill="#263238"
          />
          <path
            d="M101.664 68.8675C101.684 72.2737 101.79 78.5964 99.5713 89.8926C99.1693 91.9374 99.9655 92.8483 99.6085 94.9375C99.3751 96.3043 98.6089 96.1032 98.5009 96.7704H76.9681C76.9945 96.1302 76.0267 95.7877 75.8089 94.4046C75.3091 91.2355 77.1439 90.9696 77.0845 88.9716C76.9351 83.9328 76.1989 78.2664 73.9933 68.316C73.5379 66.2616 74.9173 64.2462 76.9993 63.9438C78.5479 63.7194 80.4115 63.5028 82.2949 63.4122C85.9183 63.2376 88.8871 63.1218 92.0725 63.4128C93.9811 63.5868 96.0055 63.8832 97.6465 64.1544C99.9553 64.5372 101.65 66.5269 101.664 68.8675Z"
            fill="#263238"
          />
          <path
            opacity="0.4"
            d="M100.844 78.7116C100.705 80.2242 100.803 81.9269 100.562 83.8511C96.9993 82.0211 96.5121 77.4462 97.6035 75.1104C98.8377 76.0278 99.9771 77.4456 100.844 78.7116Z"
            fill="black"
          />
          <path
            d="M102.469 70.3701C103.061 72.6969 103.714 75.0987 104.399 77.4321C105.089 79.7709 105.813 82.1019 106.67 84.3081C107.094 85.4181 107.556 86.4759 108.055 87.4701C108.059 87.4995 107.871 87.2343 107.529 87.1167C107.18 86.9895 106.954 87.0753 106.972 87.0921C107.005 87.1467 107.452 86.9733 107.849 86.7297C108.693 86.2263 109.608 85.4631 110.49 84.6813C111.375 83.8953 112.276 83.0379 113.13 82.2057L115.316 83.7663C114.696 84.9561 114.028 86.0511 113.252 87.1383C112.466 88.2177 111.633 89.2647 110.474 90.2661C110.18 90.5187 109.856 90.7617 109.476 90.9981C109.092 91.2315 108.655 91.4673 108.05 91.6377C107.464 91.7997 106.58 91.9041 105.651 91.5291C104.727 91.1637 104.097 90.4101 103.801 89.8947C103.098 88.6947 102.467 87.4977 101.942 86.2923C100.857 83.8845 99.9959 81.4611 99.2123 79.0185C98.8229 77.7975 98.4587 76.5729 98.1281 75.3387C97.7915 74.0997 97.4711 72.8871 97.1885 71.5989L102.469 70.3701Z"
            fill="#B55B52"
          />
          <path
            d="M100.774 65.9365C103.561 69.0715 104.732 74.7721 104.732 74.7721L96.7175 80.0035C96.7175 80.0035 92.7479 71.0071 93.7127 68.2381C94.9973 64.5523 98.0891 62.9161 100.774 65.9365Z"
            fill="#263238"
          />
          <path
            d="M115.687 82.481C116.035 81.4058 114.973 81.4154 115.333 79.2212C115.461 78.4454 114.786 78.1154 114.718 78.524C114.373 80.5952 110.296 83.7272 112.474 84.7232C113.791 85.3262 115.687 82.481 115.687 82.481Z"
            fill="#B55B52"
          />
          <path
            d="M117.295 81.4521C117.251 80.2215 116.322 78.7251 115.406 80.6277L114.924 80.9979C114.924 80.9979 113.624 81.1887 111.999 83.4135L113.738 84.9759L114.312 84.8829C115.259 84.7287 116.153 84.3363 116.908 83.7429L117.032 83.6451C117.303 83.3961 117.442 83.0355 117.408 82.6695L117.295 81.4521Z"
            fill="#B55B52"
          />
          <path
            d="M82.5438 45.4286C81.7956 50.6372 81.3677 52.8254 83.4197 56.003C86.5055 60.782 93.0666 60.5618 95.3555 55.6814C97.4159 51.2888 98.2931 43.5092 93.6821 40.421C89.1389 37.3784 83.2914 40.22 82.5438 45.4286Z"
            fill="#B55B52"
          />
          <path
            d="M92.9475 49.366C95.0499 48.0004 97.2759 46.0504 97.0881 43.4008C96.8997 40.7506 93.5505 38.2516 88.9497 38.251C82.7997 38.2504 83.0499 42.2512 83.0499 42.2512C81.8499 42.4012 69.8997 52.9744 84.6999 57.0742C99.4995 61.174 92.9475 49.366 92.9475 49.366Z"
            fill="#263238"
          />
          <path
            d="M80.666 42.6172C80.666 42.6172 80.081 43.9366 80.264 44.5366C82.2638 45.0532 89.249 45.3508 92.7824 44.7004C93.0656 43.9834 94.3154 42.8002 94.3154 42.8002L80.666 42.6172Z"
            fill="white"
          />
          <path
            d="M80.0993 43.2502C80.4173 44.3266 92.5727 43.7518 96.4859 43.2808C97.8737 43.114 99.2363 42.6352 99.2363 42.6352V42.2242H97.5659C97.5659 42.2242 96.4859 34.9138 89.0597 34.9258C82.6787 34.9354 78.9401 39.3292 80.0993 43.2502Z"
            fill="#DC6803"
          />
          <path
            d="M90.7361 34.5505C90.7361 34.5505 88.4483 34.3753 87.1361 34.5505C85.9733 34.9255 84.9233 35.0881 83.4863 37.3129L86.6987 37.4173C86.6987 37.4173 88.7891 35.0959 90.8687 35.0815C90.8489 34.7671 90.7361 34.5505 90.7361 34.5505Z"
            fill="#DC6803"
          />
          <path
            opacity="0.2"
            d="M85.7244 42.3259C85.713 42.3259 85.7016 42.3253 85.6896 42.3229C85.5666 42.3043 85.4826 42.1885 85.5018 42.0661C86.3358 36.7075 90.216 34.8949 90.3816 34.8205C90.4944 34.7701 90.6276 34.8193 90.6786 34.9321C90.7302 35.0455 90.6804 35.1787 90.567 35.2303C90.5292 35.2471 86.7414 37.0261 85.9458 42.1351C85.9296 42.2467 85.8336 42.3259 85.7244 42.3259Z"
            fill="black"
          />
          <path
            d="M94.9638 48.7895C95.3118 49.9229 95.1636 51.1463 94.7688 52.0445C94.1748 53.3957 92.9112 53.0933 92.2662 51.8987C91.6854 50.8235 91.2948 48.8249 92.2806 47.8625C93.2514 46.9151 94.566 47.4935 94.9638 48.7895Z"
            fill="#B55B52"
          />
          <path
            d="M92.8141 96.7705C92.8141 96.7705 83.4763 133.16 76.7557 153.668C71.3275 170.233 62.6323 208.952 62.6323 208.952H55.4023C55.4023 208.952 59.2933 171.39 63.4207 155.007C73.8655 113.549 67.0604 110.172 76.9676 96.7705H92.8141Z"
            fill="#DC6803"
          />
          <path
            opacity="0.4"
            d="M83.0767 108.17C79.9507 119.582 80.1907 132.008 81.5287 138.104C83.4607 131.468 85.3927 124.538 87.0967 118.292C86.5093 114.92 84.7147 110.99 83.0767 108.17Z"
            fill="black"
          />
          <path
            d="M63.6942 209.096H54.4854L54.729 205.865L64.5486 205.535L63.6942 209.096Z"
            fill="#263238"
          />
          <path
            d="M91.1717 216.124H96.4733L96.0575 203.846H90.7559L91.1717 216.124Z"
            fill="#B55B52"
          />
          <path
            d="M96.8926 212.496H90.9364C90.7198 212.496 90.544 212.647 90.5134 212.858L89.8348 217.571C89.7646 218.06 90.1847 218.523 90.6851 218.514C92.7617 218.478 93.7624 218.356 96.382 218.356C97.9936 218.356 100.348 218.523 102.573 218.523C104.75 218.523 104.917 216.323 103.991 216.123C99.8356 215.23 99.1792 213.997 97.7794 212.821C97.5268 212.61 97.21 212.496 96.8926 212.496Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M90.7549 203.85H96.0589L96.2707 210.178H90.9667L90.7549 203.85Z"
            fill="black"
          />
          <path
            d="M98.4999 96.7705C98.4999 96.7705 98.5167 133.16 97.0683 153.668C95.8983 170.233 97.1565 208.952 97.1565 208.952H89.9265C89.9265 208.952 84.1611 171.39 84.0771 155.007C83.8641 113.549 76.1913 110.172 82.6533 96.7705H98.4999Z"
            fill="#DC6803"
          />
          <path
            d="M98.1455 209.096H88.9373L88.4531 205.865L98.1983 205.535L98.1455 209.096Z"
            fill="#263238"
          />
          <path
            d="M76.6444 95.5077L75.6736 97.4349C75.5986 97.5843 75.7756 97.7367 76.0246 97.7367H98.6068C98.8012 97.7367 98.9614 97.6419 98.974 97.5195L99.1684 95.5923C99.1816 95.4585 99.0136 95.3457 98.8012 95.3457H76.996C76.8346 95.3463 76.693 95.4117 76.6444 95.5077Z"
            fill="white"
          />
          <path
            d="M79.5731 97.9456H78.9893C78.8729 97.9456 78.7847 97.8856 78.7925 97.8124L79.0661 95.308C79.0739 95.2348 79.1759 95.1748 79.2923 95.1748H79.8761C79.9925 95.1748 80.0807 95.2348 80.0729 95.308L79.7993 97.8124C79.7909 97.8856 79.6895 97.9456 79.5731 97.9456Z"
            fill="#263238"
          />
          <path
            d="M93.7283 97.9456H93.1445C93.0281 97.9456 92.9399 97.8856 92.9477 97.8124L93.2213 95.308C93.2291 95.2348 93.3311 95.1748 93.4475 95.1748H94.0313C94.1477 95.1748 94.2365 95.2348 94.2281 95.308L93.9545 97.8124C93.9461 97.8856 93.8447 97.9456 93.7283 97.9456Z"
            fill="#263238"
          />
          <g filter="url(#filter0_i_9714_117875)">
            <path
              d="M175.64 63.8379C176.776 64.8976 179.379 66.578 180.712 64.8219C182.044 63.0657 189.541 67.7132 190.651 69.4794"
              stroke="#141414"
              strokeWidth="1.05481"
            />
          </g>
          <ellipse
            opacity="0.3"
            cx="170.833"
            cy="71.2242"
            rx="14.0215"
            ry="4.86675"
            fill="black"
            fill-opacity="0.5"
          />
          <path
            d="M167.536 73.3396C163.788 72.9365 162.636 72.3534 161.974 71.8389C161.798 71.6985 161.445 71.3714 161.445 71.1858C161.445 71.0001 161.454 70.0883 161.454 69.6443L177.738 68.3757V69.4327C177.738 69.4671 177.71 69.5133 177.695 69.5322C177.463 69.7465 176.722 70.4017 175.615 71.3072C174.508 72.2128 173.504 72.6278 173.14 72.7222C170.362 73.4699 168.246 73.4453 167.536 73.3396Z"
            fill="#979DA5"
          />
          <path
            d="M177.738 68.3757C177.738 68.7137 177.738 69.3983 177.738 69.4327C177.738 69.4671 177.71 69.5133 177.695 69.5322C177.463 69.7465 176.722 70.4017 175.615 71.3072C174.508 72.2128 173.504 72.6278 173.14 72.7222C170.362 73.4699 168.246 73.4453 167.536 73.3396C163.788 72.9365 162.636 72.3534 161.974 71.8389C161.798 71.6985 161.445 71.3714 161.445 71.1858C161.445 71.0001 161.454 70.0883 161.454 69.6443L177.738 68.3757ZM177.738 68.3757C177.738 68.3242 177.701 68.1815 177.549 68.0234C177.398 67.8653 177.143 67.6023 177.034 67.4906V65.3251C177.042 65.2248 177.022 64.9281 176.87 64.5431C176.719 64.1581 175.965 61.8849 175.607 60.7964C175.194 59.5417 174.213 56.7506 174.172 54.042"
            stroke="#344054"
            strokeWidth="0.068747"
          />
          <path
            d="M162.673 68.3766L165.973 66.5859L177.05 67.514L177.617 68.0984C177.672 68.1664 177.762 68.3243 177.686 68.411C177.454 68.6259 176.713 69.2824 175.606 70.1899C174.5 71.0973 173.495 71.5132 173.131 71.6078C170.354 72.3571 168.239 72.3325 167.529 72.2265C163.782 71.8226 162.63 71.2382 161.969 70.7226C161.514 70.3687 161.424 69.8003 161.453 69.5368C161.487 69.0641 162.287 68.6173 162.673 68.3766Z"
            fill="#A5AAB1"
            stroke="#344054"
            strokeWidth="0.068747"
          />
          <path
            d="M165.829 66.7969C166.207 66.1438 166.451 65.1498 166.525 64.7345C168.442 64.6417 172.532 63.7464 173.563 60.9071L174.117 54.0631C174.118 54.04 174.12 54.0184 174.122 53.998L174.117 54.0631C174.057 55.0071 174.517 58.2787 176.846 64.439C176.92 64.6252 177.066 65.0801 177.052 65.41C177.039 65.74 177.024 66.9648 177.018 67.5359C176.474 67.9713 175.134 69.0363 174.131 69.8131C173.127 70.59 171.381 70.9847 170.633 71.085C167.299 71.5232 165.236 70.8959 164.523 70.6467C164.182 70.5275 163.349 70.2239 162.847 69.6327C162.346 69.0415 163.057 68.4353 163.475 68.2062C163.959 67.9427 165.004 67.3692 165.314 67.1836C165.623 66.998 165.786 66.8484 165.829 66.7969Z"
            fill="#AAAFB6"
          />
          <path
            d="M165.569 67.1833C165.486 67.1489 165.535 67.0715 165.569 67.0372C165.638 66.9845 165.782 66.8723 165.81 66.8448C165.837 66.8173 165.884 66.736 165.904 66.6987C166.076 66.3292 166.44 65.4218 166.523 64.748C166.646 64.7932 167.025 64.8915 167.554 64.9232C167.477 66.2724 166.824 67.3465 166.763 67.4668C166.715 67.5631 166.623 67.5814 166.583 67.5786C166.279 67.4611 165.651 67.2176 165.569 67.1833Z"
            fill="#A2A8B0"
          />
          <path
            d="M161.04 57.3678L160.997 26.6637C160.994 24.1796 162.149 22.3285 163.325 21.1553C163.942 20.5396 164.564 20.1106 165.027 19.8749C166.311 19.2705 169.488 18.2898 171.533 19.7804C173.784 21.4217 174.29 23.5013 174.282 24.8333C174.225 34.8818 174.157 56.0444 174.136 57.9693C174.116 59.8943 173.469 61.378 173.148 61.8793C172.249 63.366 169.508 65.9681 165.741 64.4831C163.678 63.67 162.464 62.1395 161.788 60.6333C161.23 59.3888 161.039 58.1609 161.04 57.3678Z"
            fill="#33FE9F"
            stroke="#384250"
            strokeWidth="0.068747"
          />
          <path
            d="M161.556 24.4808C162.828 20.8097 165.667 19.8289 166.927 19.7974C171.038 19.6324 171.923 23.292 171.851 25.1425C171.805 35.6693 171.715 57.0376 171.722 58.2957C171.729 59.5538 171.135 61.0141 170.837 61.587C169.71 63.8006 167.268 63.9702 166.188 63.7783C162.194 62.9671 161.218 59.1894 161.23 57.402C161.201 47.723 161.14 28.102 161.126 27.0502C161.113 25.9984 161.407 24.899 161.556 24.4808Z"
            fill="#AAAFB6"
          />
          <path
            d="M163.002 26.5008C163.436 22.1268 166.214 22.1414 166.542 22.1354C168.407 22.101 169.662 23.4674 169.556 27.1481C169.528 36.7231 169.477 56.1592 169.477 57.3035L169.477 57.3436C169.475 58.1345 169.475 58.4238 169.36 58.8549C169 60.0665 167.36 61.6735 165.408 61.0548C163.455 60.4361 162.973 58.2419 162.976 57.2221C162.958 48.4183 162.967 29.8401 162.959 28.8834C162.951 27.9267 162.959 26.9305 163.002 26.5008Z"
            fill="#979DA5"
          />
          <g filter="url(#filter1_d_9714_117875)">
            <path
              d="M164.883 27.4704C164.423 27.2573 164.302 26.6713 164.299 26.4049L165.605 26.1213C165.975 26.0697 166.645 26.3447 166.782 27.058C166.908 27.7111 166.705 28.1493 166.516 28.4071C166.275 28.6907 166.019 28.9055 165.545 29.043V28.9227L165.562 28.4071C165.816 28.359 165.88 28.3384 166.086 28.1321C166.482 27.7365 166.464 26.9806 165.88 26.7658C165.282 26.546 164.963 27.1525 164.883 27.4704Z"
              fill="#603C81"
            />
            <path
              d="M164.883 27.4704C164.423 27.2573 164.302 26.6713 164.299 26.4049L165.605 26.1213C165.975 26.0697 166.645 26.3447 166.782 27.058C166.908 27.7111 166.705 28.1493 166.516 28.4071C166.275 28.6907 166.019 28.9055 165.545 29.043V28.9227L165.562 28.4071C165.816 28.359 165.88 28.3384 166.086 28.1321C166.482 27.7365 166.464 26.9806 165.88 26.7658C165.282 26.546 164.963 27.1525 164.883 27.4704Z"
              stroke="#603C81"
              strokeWidth="0.068747"
            />
            <path
              d="M164.883 27.4704C164.423 27.2573 164.302 26.6713 164.299 26.4049L165.605 26.1213C165.975 26.0697 166.645 26.3447 166.782 27.058C166.908 27.7111 166.705 28.1493 166.516 28.4071C166.275 28.6907 166.019 28.9055 165.545 29.043V28.9227L165.562 28.4071C165.816 28.359 165.88 28.3384 166.086 28.1321C166.482 27.7365 166.464 26.9806 165.88 26.7658C165.282 26.546 164.963 27.1525 164.883 27.4704Z"
              stroke="black"
              stroke-opacity="0.02"
              strokeWidth="0.068747"
            />
          </g>
          <g filter="url(#filter2_d_9714_117875)">
            <path
              d="M165.87 25.5988C165.575 25.6263 165.535 25.3123 165.552 25.1519C165.827 25.1118 166.408 25.0282 166.532 25.0144C166.656 25.0007 166.698 25.0717 166.704 25.109V25.4613L165.87 25.5988Z"
              fill="#603C81"
            />
            <path
              d="M166.747 26.0972L166.781 25.53L167.589 25.3925L166.747 26.0972Z"
              fill="#603C81"
            />
            <path
              d="M165.87 25.5988C165.575 25.6263 165.535 25.3123 165.552 25.1519C165.827 25.1118 166.408 25.0282 166.532 25.0144C166.656 25.0007 166.698 25.0717 166.704 25.109V25.4613L165.87 25.5988Z"
              stroke="#603C81"
              strokeWidth="0.068747"
            />
            <path
              d="M165.87 25.5988C165.575 25.6263 165.535 25.3123 165.552 25.1519C165.827 25.1118 166.408 25.0282 166.532 25.0144C166.656 25.0007 166.698 25.0717 166.704 25.109V25.4613L165.87 25.5988Z"
              stroke="black"
              stroke-opacity="0.02"
              strokeWidth="0.068747"
            />
            <path
              d="M166.747 26.0972L166.781 25.53L167.589 25.3925L166.747 26.0972Z"
              stroke="#603C81"
              strokeWidth="0.068747"
            />
            <path
              d="M166.747 26.0972L166.781 25.53L167.589 25.3925L166.747 26.0972Z"
              stroke="black"
              stroke-opacity="0.02"
              strokeWidth="0.068747"
            />
          </g>
          <g filter="url(#filter3_d_9714_117875)">
            <ellipse
              cx="165.633"
              cy="27.5036"
              rx="0.368833"
              ry="0.43946"
              transform="rotate(-1.39963 165.633 27.5036)"
              fill="#603C81"
            />
            <path
              d="M165.968 27.4954C165.973 27.7249 165.823 27.9041 165.643 27.9085C165.464 27.9129 165.305 27.7413 165.299 27.5117C165.293 27.2822 165.444 27.103 165.623 27.0986C165.803 27.0942 165.962 27.2658 165.968 27.4954Z"
              stroke="#603C81"
              strokeWidth="0.068747"
            />
            <path
              d="M165.968 27.4954C165.973 27.7249 165.823 27.9041 165.643 27.9085C165.464 27.9129 165.305 27.7413 165.299 27.5117C165.293 27.2822 165.444 27.103 165.623 27.0986C165.803 27.0942 165.962 27.2658 165.968 27.4954Z"
              stroke="black"
              stroke-opacity="0.02"
              strokeWidth="0.068747"
            />
          </g>
          <g filter="url(#filter4_i_9714_117875)">
            <ellipse
              cx="165.806"
              cy="57.7567"
              rx="1.66606"
              ry="1.98509"
              transform="rotate(-9.28549 165.806 57.7567)"
              fill="url(#paint0_linear_9714_117875)"
            />
          </g>
          <path
            d="M167.434 57.4906C167.609 58.5661 167.02 59.5523 166.124 59.6988C165.228 59.8453 164.355 59.0982 164.179 58.0227C164.003 56.9472 164.593 55.961 165.489 55.8145C166.385 55.668 167.258 56.4151 167.434 57.4906Z"
            stroke="#384250"
            strokeWidth="0.0343735"
          />
          <path
            d="M166.93 57.6181C167.059 58.4085 166.626 59.1319 165.969 59.2393C165.312 59.3466 164.671 58.799 164.542 58.0085C164.413 57.2181 164.846 56.4947 165.503 56.3873C166.16 56.2799 166.801 56.8276 166.93 57.6181Z"
            fill="url(#paint1_radial_9714_117875)"
            stroke="#384250"
            strokeWidth="0.0343735"
          />
          <path
            d="M166.495 57.6483C166.583 58.1863 166.288 58.6798 165.839 58.7531C165.391 58.8265 164.954 58.4525 164.866 57.9146C164.778 57.3766 165.073 56.8831 165.521 56.8098C165.97 56.7364 166.407 57.1104 166.495 57.6483Z"
            fill="#384456"
            stroke="#1F2A37"
            strokeWidth="0.015468"
          />
          <g filter="url(#filter5_d_9714_117875)">
            <ellipse
              cx="165.675"
              cy="57.7829"
              rx="0.749558"
              ry="0.89309"
              transform="rotate(-9.2855 165.675 57.7829)"
              fill="#384456"
            />
            <path
              d="M166.408 57.6631C166.487 58.1472 166.222 58.5914 165.818 58.6574C165.414 58.7234 165.021 58.3869 164.942 57.9027C164.863 57.4186 165.128 56.9744 165.532 56.9084C165.936 56.8424 166.329 57.1789 166.408 57.6631Z"
              stroke="#1F2A37"
              strokeWidth="0.0139213"
            />
          </g>
          <g filter="url(#filter6_d_9714_117875)">
            <ellipse
              cx="165.66"
              cy="57.7948"
              rx="0.674604"
              ry="0.803781"
              transform="rotate(-9.28549 165.66 57.7948)"
              fill="#384456"
            />
            <path
              d="M166.319 57.6869C166.391 58.1227 166.152 58.5224 165.788 58.5818C165.425 58.6412 165.071 58.3383 165 57.9026C164.929 57.4668 165.168 57.0671 165.531 57.0077C165.894 56.9483 166.248 57.2512 166.319 57.6869Z"
              stroke="#1F2A37"
              strokeWidth="0.0125292"
            />
          </g>
          <g filter="url(#filter7_f_9714_117875)">
            <path
              d="M165.254 64.7566C167.78 65.4183 172.433 64.5057 172.343 57.0054C172.254 49.5051 172.306 32.4026 172.343 24.7888C172.174 22.3741 169.937 19.0656 166.5 19.9336"
              stroke="white"
              strokeWidth="0.206241"
            />
          </g>
          <path
            d="M175.199 68.912C176.944 63.2834 175.844 62.0717 174.108 58.4453C174.108 59.0297 173.959 59.8587 173.666 60.7655C174.476 61.9915 175.56 65.363 173.408 69.0409C173.405 69.0553 173.411 69.0839 173.459 69.0839C173.507 69.0839 174.591 69.0553 175.126 69.0409C175.137 69.0409 175.167 69.0152 175.199 68.912Z"
            fill="#A2A8B0"
          />
          <defs>
            <filter
              id="filter0_i_9714_117875"
              x="175.28"
              y="63.4521"
              width="16.8722"
              height="7.36242"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1.05481" dy="1.05481" />
              <feGaussianBlur stdDeviation="1.05481" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_9714_117875"
              />
            </filter>
            <filter
              id="filter1_d_9714_117875"
              x="164.057"
              y="25.8404"
              width="3.14177"
              height="3.55779"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="0.068747" dy="0.0343735" />
              <feGaussianBlur stdDeviation="0.137494" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9714_117875"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_9714_117875"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_9714_117875"
              x="165.274"
              y="24.7379"
              width="2.74334"
              height="1.74626"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="0.0343735" dy="0.0343735" />
              <feGaussianBlur stdDeviation="0.137494" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9714_117875"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_9714_117875"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_d_9714_117875"
              x="165.024"
              y="26.8238"
              width="1.28728"
              height="1.42888"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="0.0343735" dy="0.0343735" />
              <feGaussianBlur stdDeviation="0.137494" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9714_117875"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_9714_117875"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_i_9714_117875"
              x="163.993"
              y="55.7105"
              width="3.48808"
              height="4.02383"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-0.137494" dy="-0.068747" />
              <feGaussianBlur stdDeviation="0.0859337" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_9714_117875"
              />
            </filter>
            <filter
              id="filter5_d_9714_117875"
              x="164.852"
              y="56.8592"
              width="1.64531"
              height="1.91679"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="0.0343735" />
              <feGaussianBlur stdDeviation="0.0343735" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9714_117875"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_9714_117875"
                result="shape"
              />
            </filter>
            <filter
              id="filter6_d_9714_117875"
              x="164.913"
              y="56.9598"
              width="1.49394"
              height="1.73906"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="0.0343735" />
              <feGaussianBlur stdDeviation="0.0343735" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9714_117875"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_9714_117875"
                result="shape"
              />
            </filter>
            <filter
              id="filter7_f_9714_117875"
              x="164.403"
              y="18.8635"
              width="8.87063"
              height="46.9976"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.412482"
                result="effect1_foregroundBlur_9714_117875"
              />
            </filter>
            <linearGradient
              id="paint0_linear_9714_117875"
              x1="167.426"
              y1="57.5631"
              x2="164.172"
              y2="57.7625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E5E7EB" />
              <stop offset="1" stop-color="#EAECF0" />
            </linearGradient>
            <radialGradient
              id="paint1_radial_9714_117875"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(165.69 57.8205) rotate(79.5715) scale(1.42425 1.24608)"
            >
              <stop offset="0.769445" stop-color="#1570EF" />
              <stop offset="0.789313" stop-color="#2E90FA" />
              <stop offset="0.84375" stop-color="#84CAFF" />
              <stop offset="0.871256" stop-color="#84CAFF" />
              <stop offset="0.893429" stop-color="#84CAFF" />
              <stop offset="0.939524" stop-color="#2E90FA" />
              <stop offset="0.967989" stop-color="#1570EF" />
              <stop offset="1" stop-color="#175CD3" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <h1 className="text-[#101828] font-semibold text-2xl">
        Çok Yakında Hizmetinizdeyiz..
      </h1>
      <div className="w-1/2 flex items-center justify-center flex-col">
        <p className="text-[#667085] text-sm text-center mt-5">
          Sizlere daha iyi bir hizmet verebilmek için iyileştirme
          yapmaktayız.Kullanımıza açıldığında sizlere duyuru ile bilgilendirme
          yapacağız.Anlayışınız için teşekkürler..
        </p>
        <div className="w-1/3 mt-5">
          <Button
            onClick={() => navigate("/app/machine/digital-twin")}
            label={"Ana Sayfaya Dön"}
            colorType={"secondary-product"}
          />
        </div>{" "}
      </div>
    </div>
  );
};

export default VerySoonProduct;
