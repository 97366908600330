import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../../hooks/useWindowDimension";
import moment from "moment";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import Junction from "../../../../components/junction/Junction";
import { equipmentStore } from "../../../../stores/equipment.store";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { authStore } from "../../../../stores/auth.store";
import { junctionService } from "../../../../services/junction.service";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const OperationTimeLineChart = observer(
  ({
    timelineChart,
    dayStart,
    dayEnd,
    nodeData,
    activeJunction,
    totalJunction,
  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const _ = require("lodash");
    const [values, setValues] = useState([]);
    const { width, height } = useWindowDimensions();
    const [dataZoom, setDataZoom] = useState({
      start: moment(
        timeFormatter(dayStart, authStore?.user?.company?.timeZone).formatted
      ).unix(),
      end: moment(
        timeFormatter(dayEnd, authStore?.user?.company?.timeZone).formatted
      ).unix(),
    });

    const [selectedJunction, setSelectedJunction] = useState({});

    const defaultStart = moment(
      timeFormatter(dayStart, authStore?.user?.company?.timeZone).formatted
    ).unix();

    const defaultEnd = Number(
      moment(timeFormatter(dayEnd)?.formatted).format("X")
    );

    let categories = ["Junction", t("embedded:status")];

    const renderItem = (params, api) => {
      var categoryIndex = api.value(0);
      var start = api.coord([api.value(1), categoryIndex]);
      var end = api.coord([api.value(2), categoryIndex]);
      var height =
        categoryIndex == 2
          ? api.size([0, 1])[1] * 0.5
          : categoryIndex != 1
          ? api.size([0, 1])[1] * 0.7
          : api.size([0, 1])[1] * 1.5;
      var rectShape = echarts.graphic.clipRectByRect(
        {
          x: categoryIndex == 1 ? start[0] - 2 : start[0],
          y:
            categoryIndex == 1
              ? start[1] - 35
              : categoryIndex == 2
              ? start[1] - height / 2
              : start[1] - height / 2,
          width: end[0] - start[0],
          height: height,
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
        }
      );
      return (
        rectShape && {
          type: "rect",
          transition: ["shape"],
          shape: rectShape,
          style: api.style(),
        }
      );
    };

    const calculateData = (data, totalJunction) => {
      const datas = [];
      categories?.forEach(function (category, index) {
        data?.map((value) => {
          value?.value.map((da, i) => {
            datas.push({
              name: value.name,
              type:
                da?.index == 1
                  ? "status"
                  : da.index == 2
                  ? "shift"
                  : da.index == 0
                  ? "junction"
                  : "",
              node: value.node,
              junctionId: da.junctionId,
              actionId: da.actionId,
              value: [
                da.index,
                moment(
                  timeFormatter(da?.start, authStore?.user?.company?.timeZone)
                    .formatted
                ).unix(),
                moment(
                  timeFormatter(da?.end, authStore?.user?.company?.timeZone)
                    .formatted
                ).unix(),
                da.timeDiff,
              ],
              shiftId: da?.shiftId,
              itemStyle: {
                color:
                  da?.index == 1
                    ? value?.itemStyle?.normal?.color
                    : da?.index == 2
                    ? value?.itemStyle?.normal?.color
                    : da?.index == 0
                    ? da?.type == "setup"
                      ? "#EAAA08"
                      : "#12B76A"
                    : value?.itemStyle?.normal?.color,
              },
            });
          });
        });
      });

      const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);
      const lastValues = _.uniqWith(Valuess, _.isEqual);

      let noJunctionDatas = [];

      const NoIdDatas = lastValues?.map((value, index) => {
        if (value?.value[0] == 0) {
          totalJunction?.map((item) => {
            if (item?.id == value?.junctionId) {
              noJunctionDatas?.push(value);
            } else {
              noJunctionDatas?.push(value);
            }
          });
        } else {
          noJunctionDatas.push(value);
        }
      });

      setValues(noJunctionDatas);
    };

    const chartTimeDiff = (params) => {
      const first = moment.unix(params.value[1]);
      const second = moment.unix(params.value[2]);

      const value = moment(second).diff(first, "seconds");
      let h = Math.floor(value / 3600);
      let m = Math.floor(value / 60);
      let s = Math.floor((value % 3600) % 60);
      return `${m + ":" + s}dk. - ${moment(second).diff(first, "second")}sn.`;
    };

    const options = {
      tooltip: {
        position: "left",
        formatter: function (params) {
          return `${params.marker} ${params?.name} : ${moment
            .unix(params?.value[1])

            .format("HH:mm:ss")} - ${moment
            .unix(params?.value[2])

            .format("HH:mm:ss")} - ${chartTimeDiff(params)}`;
        },
      },
      label: {
        color: "black",
        fontSize: 10,
        fontWeight: "bold",
        show: true,
        formatter: function (d) {
          if (d?.data?.type == "junction") {
            if (selectedJunction?.id == d?.data?.junctionId) {
              return t("global:selected");
            } else {
              return "";
            }
          } else {
            return "";
          }
        },
      },

      title: {
        text: values.length > 0 ? "" : "",
      },
      dataZoom: [
        {
          start: dataZoom?.start,
          end: dataZoom?.end,
          type: "slider",
          filterMode: "weakFilter",
          showDataShadow: false,
          top: "73%",
          height: 20,
          labelFormatter: "",
          show: false,
          disabled: true,
        },
        {
          type: "inside",
          filterMode: "weakFilter",
          zoomLock: true,
        },
      ],
      grid: {
        // No Works
        height: 75,
        width: "100%",
        left: "5.5%",
        top: "10%",
      },
      xAxis: [
        {
          // onZero: true,
          splitLine: {
            show: true,
          },
          min: defaultStart,
          max: defaultEnd + 5400,
          scale: true,
          axisLabel: {
            formatter: function (val) {
              return moment.unix(val).format("HH:mm:ss");
            },
          },
        },
      ],

      yAxis: { data: categories },

      series: [
        {
          type: "custom",
          renderItem: renderItem,
          itemStyle: {
            opacity: 1,
          },
          encode: {
            x: [1, 2],
            y: 0,
          },
          data: values,
          width: 400,
          height: 200,
        },
      ],
    };

    const getJunctionDetail = async (id) => {
      const data = await junctionService.getJunctionDetail(id);

      await setSelectedJunction({
        time: moment(timeFormatter(data?.data?.startDate).formatted).format(
          "HH:mm"
        ),
        id: data?.data?.id,
        ring:
          data?.data?.type == "setup"
            ? "#EAAA08"
            : data?.data?.type == "work"
            ? "#12B76A"
            : "red",
        node: {
          name:
            data?.data?.station?.metadata?.brand +
            " " +
            data?.data?.station?.metadata?.model,
          image: data?.data?.station?.metadata?.image,
          id: data?.data?.metadata?.id,
          process: data?.data?.metadata?.process?.name,
        },
        product: {
          name: data?.data?.job?.product?.name,
          image: data?.data?.job?.product?.image,
          id: data?.data?.job?.product?.id,
          piece: data?.data?.job?.piece,
          code: data?.data?.job?.product?.code,
        },
        user: {
          image: data?.data?.user?.avatar,
          name: data?.data?.user?.name,
          lastName: data?.data?.user?.lastName,
        },
      });
    };

    useEffect(() => {
      calculateData(timelineChart, activeJunction);

      setSelectedJunction({
        time: moment(
          timeFormatter(activeJunction[0]?.startDate)?.formatted
        )?.format("HH:mm"),
        id: activeJunction[0]?.id,
        ring:
          activeJunction[0]?.type == "setup"
            ? "#EAAA08"
            : activeJunction[0]?.type == "work"
            ? "#12B76A"
            : "",
        node: {
          name:
            activeJunction[0]?.station?.metadata?.brand +
            " " +
            activeJunction[0]?.station?.metadata?.model,
          image: activeJunction[0]?.station?.metadata?.image,
          id: activeJunction[0]?.metadata?.id,
          process: activeJunction[0]?.metadata?.process?.name,
        },
        product: {
          name: activeJunction[0]?.job?.product?.name,
          image: activeJunction[0]?.job?.product?.image,
          id: activeJunction[0]?.job?.product?.id,
          piece: activeJunction[0]?.job?.piece,
          code: activeJunction[0]?.job?.product?.code,
        },
        user: {
          image: activeJunction[0]?.user?.avatar,
          name: activeJunction[0]?.user?.name,
          lastName: activeJunction[0]?.user?.lastName,
        },
      });
    }, []);

    const junctionNumber = (item) => {
      const actives =
        activeJunction.length > 0
          ? activeJunction
          : activeJunction.length == 0
          ? activeJunction[0]
          : [];

      let sortedJunctions =
        actives.length > 1
          ? actives?.sort(
              (a, b) =>
                moment(a?.startDate).unix() - moment(b?.startDate).unix()
            )
          : actives;

      const data = sortedJunctions?.map((value, index) => {
        if (value?.id == item) {
          return index + 1;
        } else {
          return "";
        }
      });

      const lastData = data?.filter((a) => a != "");

      return lastData[0];
    };

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          padding: "0px",
          margin: "0px",
          marginTop: "12px",
          borderBottom: "1px solid #E5E5E5",
          paddingBottom: "12px",

          overflow: "hidden",
        }}
      >
        <div className="flex items-center w-full gap-x-4  h-[90px]">
          <div className=" max-w-[335px] min-w-[335px] relative">
            <div
              onClick={() =>
                navigate(
                  `/app/machine/digital-twin/equipment-details/${nodeData?.deviceId}-${nodeData?.nodeId}`
                )
              }
              className="right-0 w-[150px] h-full opacity-0 absolute z-[9999999] cursor-pointer"
            ></div>

            {selectedJunction?.id ? (
              <div className="w-full relative">
                {/* <div className="px-2 py-1 text-[10px] bg-primary-500 text-white absolute rounded-full -mt-1">
                    selected
                  </div> */}
                <Junction
                  junctionId={selectedJunction?.id}
                  props={{
                    ...selectedJunction,
                    ring: selectedJunction?.ring,
                  }}
                />
              </div>
            ) : nodeData?.isActiveJunction ? (
              <Junction
                junctionId={nodeData?.productId}
                props={{
                  ring: "#EAAA08",
                  time: moment(
                    timeFormatter(nodeData?.startDate).formatted
                  ).format("HH:mm"),

                  node: {
                    process: nodeData.nodeName,
                    name: nodeData.processName,
                    image: nodeData.nodeImage,
                    id: nodeData.nodeId,
                  },
                  product: {
                    name: nodeData?.productName,
                    image: nodeData?.productImage,
                    id: nodeData?.productId,
                    code: nodeData?.productCode,
                  },
                  user: {
                    image: nodeData?.personImage,
                    name: nodeData?.personName,
                    lastName: nodeData?.personLastName,
                  },
                }}
              />
            ) : (
              //active junction yok
              <Junction
                junctionId={nodeData?.nodeId}
                props={{
                  //   onPressNode: () => {},
                  onPressUser: () => {},
                  onPressProduct: () => {},
                  // theme: settings.theme,
                  ring: nodeData?.ring,
                  time: "--:--",

                  node: {
                    name: nodeData.nodeName,
                    // process: "Process Name",
                    image: nodeData.nodeImage,
                    id: nodeData.nodeId,
                  },
                }}
              />
            )}
          </div>

          <ReactECharts
            onEvents={{
              click: (event) => {
                if (event.data.type == "junction") {
                  getJunctionDetail(event?.data?.junctionId);
                } else {
                }
              },
            }}
            className=" !h-full !min-h-[70px]  w-full  "
            option={options}
            id="main"
            style={{
              height: height,

              minHeight: "400px!important",
              // minWidth: width / 1.41,
            }}
          />
        </div>
      </div>
    );
  }
);

export default OperationTimeLineChart;
