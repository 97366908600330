import React from "react";
import { EyeIcon, EyeIconPoll } from "../../../assets/icons/PersonIcons";
import CloseButton from "../../../components/buttons/CloseButton";
import { pollService } from "../../../services/poll.service";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const PollInfoDetails = ({ onClose, pollId }) => {
  const { t } = useTranslation();
  return (
    <div className="w-[375px] min-w-[375px] px-5 pt-5 border-l border-secondary-200 flex flex-col">
      <div className="flex flex-row items-center gap-2 w-full">
        <EyeIconPoll />
        <p className="text-lg font-semibold text-secondary-600">
          {t("chat:whoSee")}
        </p>

        <div className="ml-auto">
          <CloseButton onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default PollInfoDetails;
