import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { processService } from "../../../services";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { useStore } from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
  ArrowRight,
  DashboardPlusIcon,
} from "../../../assets/icons/machineIcons";
import DashboardChartsLoading from "../Twin/chart/DashboardChartsLoading";
import DashboardSwiperItem from "./DashboardSwiperItem";
import DashboardCompanyInfo from "../Twin/components/DashboardCompanyInfo";
import { CustomModal } from "../../../components";
import { AddProcessModal } from "../../../steps/process";
import { personalService } from "../../../services/personal.service";
import CompanyDetailChart from "../CompanyDetailChart";
import { Toast } from "../../../utils/toastify/toast";

const DashboardSlider = memo(({ enabled }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visibleButton, setVisibleButton] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isOpenCompanyDetail, setIsOpenCompanyDetail] = useState(false);
  const [visibleRightButton, setVisibleRightButton] = useState(false);
  const contentWrapper = useRef(null);
  const [items, setItems] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [operation, setOperation] = useState();
  const [openOperation, setOpenOperation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isLoading, data, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getProcessUtilization"],
    queryFn: async () => await processService.getQueryProcessUtilization(),
  });

  const handleData = useCallback(async () => {
    await processService.activeProcesses().then((res) => {
      res?.data?.map((d) => {
        return {
          ...d,
          responsibleUser: d.responsibleUser.map((r) => r.id),
        };
      });
      setOperation(res.data);
      refetch();
    });
  }, [refetch]);

  useEffect(() => {
    handleData();
  }, []);

  const handleEdit = useCallback(
    (id) => {
      if (operation?.length > 0) {
        const findOperation = operation?.find((a) => a?.id === id);
        setSelectedItem(findOperation);
      }
      Toast("warning", "Demo user's are not authorized.")
    },
    [operation]
  );

  const slide = useCallback((shift) => {
    contentWrapper.current.scrollLeft += shift;
  }, []);

  const sideScroll = useCallback((element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  }, []);

  useEffect(() => {
    setItems({
      ...data,
      operations: data?.operations?.sort((a, b) => b?.actual - a?.actual),
    });
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        const container = document.getElementById("container");
        if (container?.scrollWidth > container?.clientWidth) {
          setVisibleRightButton(true);
        } else {
          setVisibleRightButton(false);
        }
      }, 300);
    }
  }, [data, isLoading]);

  const memoizedData = useMemo(() => data, [data]);

  return (
    <>
      <div
        // onClick={() => setIsOpen(true)}
        onClick={() => Toast("warning", "Demo user's are not authorized.")}
        className="flex items-center justify-center bg-white shadow-xs border-secondary-300 border rounded-md p-2 hover:bg-[#F9FAFB] active:shadow-ring-gray w-9 h-[138px]"
      >
        <DashboardPlusIcon />
      </div>
      <div className="flex items-center w-[250px]">
        <DashboardCompanyInfo
          onClick={() => {
            // setIsOpenCompanyDetail(true);
            navigate("/app/machine/digital-twin/company-details");
          }}
          // data={data}
          data={memoizedData}
        />
      </div>
      {visibleButton && (
        <div
          onClick={() => slide(-90)}
          className="flex items-center justify-center cursor-pointer h-[138px] min-h-[138px] min-w-[36px] bg-secondary-100 rounded-l-[8px] backdrop-blur-sm"
        >
          <div className="-rotate-180">
            <ArrowRight />
          </div>
        </div>
      )}
      <div
        id="container"
        ref={contentWrapper}
        onScroll={(e) => {
          if (!isIntersecting) {
            setVisibleButton(true);
          }
        }}
        className="flex items-center w-[95%] overflow-x-auto scrollbar-hide gap-6 h-[150px] min-h-[150px] pl-1"
      >
        {isLoading ? (
          <>
            <div className="flex gap-x-5">
              {[...new Array(10)].map((d, index) => (
                <div
                  className="w-[166px] h-[134px] flex items-center"
                  key={index}
                >
                  <DashboardChartsLoading />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="w-[166px] h-[138px] flex gap-5">
              {items?.operations?.map((process, j) => {
                return (
                  process.type !== "planned" && (
                    <DashboardSwiperItem
                      key={j}
                      index={j}
                      process={process}
                      selectedProcess={selectedProcess}
                      setSelectedProcess={setSelectedProcess}
                      // onDoubleClickProcess={getOperationDetails}
                      enabled={enabled}
                      processId={process.id}
                      setVisibleButton={setVisibleButton}
                      setIsIntersecting={setIsIntersecting}
                      dataLength={items?.length}
                      setVisibleRightButton={setVisibleRightButton}
                      handleEdit={handleEdit}
                    />
                  )
                );
              })}
            </div>
          </>
        )}
      </div>
      <div
        onClick={() => {
          sideScroll(contentWrapper.current, 25, 200, 10);
        }}
        className="flex items-center justify-center cursor-pointer h-[138px] min-h-[138px] min-w-[36px] bg-secondary-100  backdrop-blur-sm"
      >
        <div className="">
          <ArrowRight />
        </div>
      </div>
      <CustomModal
        children={
          <AddProcessModal
            data={operation}
            isOpen={openOperation}
            setIsOpen={setOpenOperation}
            selectedProcess={selectedItem}
            handleData={handleData}
          />
        }
        isOpen={openOperation}
        setIsOpen={setOpenOperation}
        width="fit-content"
        height="fit-content"
        modalTitle={t("addProcess:updateProcess")}
      />
      <CustomModal
        isOpen={isOpenCompanyDetail}
        setIsOpen={setIsOpenCompanyDetail}
        modalTitle={"Company Detail"}
        children={<CompanyDetailChart />}
      />
    </>
  );
});

export default DashboardSlider;
