import React, { useState } from "react";
import CustomImage from "../CustomImage";
import NoAvatar from "../avatar/NoAvatar";
import CustomText from "../CustomText";
import { DeleteIcon, DotsVerticalIcon } from "./ActiveAndPlannedCard";
import { EditIcon } from "../../assets/icons/departmentIcon";
import { useTranslation } from "react-i18next";
import JunctionUpdate from "../modal/JunctionUpdate";
import CustomModal from "../modal/CustomModal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import AlertModal from "../alert-Modal/Alert-Modal";
import QualityControlForm from "../modal/QualityControlForm";
import { useNavigate } from "react-router-dom";
import {
  EquipmentDeleteIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  OperationDetailIcon,
  ProductDetailIcon,
  QualityDetailIcon,
} from "../../assets/icons/machineIcons";
import { Tooltip } from "react-tooltip";
import EditJunctionModal from "../modal/EditJunctionModal";
import moment from "moment";
import { filter } from "d3";

const junctionProps = {
  theme: "",
  ring: null,
  page: "",
  startDate: "",
  time: {
    timeGreen: "",
    timeRed: "",
  },
  product: {
    name: "",
    image: "",
    code: "",
    pieceGreen: 0,
    id: "",
    pieceRed: 0,
  },
  status: "",
  node: {
    name: "",
    id: "",
    image: "",
    process: "",
  },
  user: {
    image: "",
    id: "",
    name: "",
    lastName: "",
  },
  onPressNode: null,
  onPressUser: null,
  onPressProduct: null,
  onPressQuality: null,
  avatarPress: null,
};

const FinishedJunctionCard = ({
  props = junctionProps,
  step,
  junctionId,
  refetch,
  pages,
  onPressCard = null,
  allData,
  operation,
  isLoss,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();
  const [isJunction, setIsJunction] = useState(false);
  const [editJunction, setEditJunction] = useState(false);
  const menuOptions = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "operationDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("product:operationDetail"),
      value: "operation",
      icon: <OperationDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsPurchase = [
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
  ];

  const onDeleted = async () => {
    await junctionService.deleteJunction(junctionId, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(junctionId);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div
        // data-tooltip-id={`${junctionId}`}
        // data-tooltip-place={"right"}
        // // data-tooltip-content={`${t("product:productName")}: ${
        // //   props?.product?.name
        // // }`}
        // data-tooltip-content={`
        // ${t("product:productName")} : ${props?.product?.name}

        // `}
        onClick={onPressCard}
        className="flex flex-col h-[80px] w-full relative items-center  group  justify-end "
      >
        <div className="flex absolute flex-col -top-[0px] left-[1px] items-center">
          <div className="flex flex-row items-center gap-1 mr-auto">
            <GreenClockIcon />
            <p className={`text-xs font-normal text-secondary-600 `}>
              {props?.time?.timeGreen || "--:--"}
            </p>
          </div>
          <div className="flex flex-row items-center gap-1 mr-auto">
            <RedClockIcon />
            <p className={`text-xs font-normal text-secondary-600 `}>
              {props?.time?.timeRed || "--:--"}
            </p>
          </div>
        </div>
        <div
          data-tooltip-id={`${junctionId}`}
          data-tooltip-content={`
        ${props?.user?.name + " " + props?.user?.lastName} 
        `}
          className="flex blur-sm flex-col items-center justify-center absolute w-[50px] h-[50px] z-[100] bottom-7 "
        >
          <UserController
            avatarPress={props?.avatarPress}
            onPress={props?.onPressUser}
            pages={pages}
            theme={props?.theme}
            image={props?.user?.image}
            name={props?.user?.name}
            navigate={navigate}
            user={props?.user}
            startDate={props?.startDate}
            lastName={props?.user?.lastName}
          />
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <div
            className={`w-[49.5%] h-11 flex relative pl-3 pr-3 items-center flex-col border rounded border-t-4`}
            style={{
              borderColor: props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#DC6803"
                : "#D0D5DD",
              backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <div
              data-tooltip-id={`${junctionId}`}
              data-tooltip-content={`
            ${props?.product?.name} 
            `}
              className="absolute bottom-8 z-[100] cursor-pointer blur-sm"
              // onClick={props?.onPressProduct}
            >
              <PressedContoller
                theme={props?.theme}
                image={props?.product?.image}
              />
            </div>
            <CustomText
              text={props?.product?.code?.slice(0, 2) + " ***"}
              numberOfLines={1}
              style={{
                marginTop: 9,
                flexDirection: "column",
                fontSize: "11px",
                fontWeight: "400",
                display: "flex",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              text={props?.product?.name?.slice(0, 2) + " ***"}
              numberOfLines={1}
              style={{
                fontSize: "11px",
                flexDirection: "column",
                fontWeight: "500",
                display: "flex",
                marginTop: "-2px",
                textAlign: "center",
                color: props?.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>
          {!isLoss && (
            <div
              className={`z-[100] absolute flex items-center blur-sm justify-center left-[46%]  w-[28px] bottom-2 h-[24px] rounded border cursor-pointer ${
                props.status === "completed"
                  ? "bg-[#17B26A] border-[#17B26A]"
                  : props.status === "waiting"
                  ? "bg-[#EAAA08] border-[#EAAA08]"
                  : props.status === "control"
                  ? "bg-[#98A2B3] border-[#98A2B3]"
                  : props.status === "rejected"
                  ? "bg-[#F04438] border-[#F04438]"
                  : props.status === "accepted"
                  ? "bg-[#17B26A] border-[#17B26A]"
                  : "bg-[#DC6803] border-[#DC6803]"
              }`}
              // onClick={props?.onPressQuality}
            >
              {props.status === "completed" ? (
                <ProductCompleted />
              ) : props.status === "waiting" ? (
                <ProductWaiting />
              ) : props.status === "control" ? (
                <ProductControl />
              ) : props.status === "rejected" ? (
                <ProductRejected />
              ) : props.status === "accepted" ? (
                <ProductCompleted />
              ) : (
                <ProductIcon />
              )}
            </div>
          )}
          <div
            className={`w-[49.5%] h-11 flex relative pl-3 pr-3 items-center flex-col border rounded  border-t-4`}
            style={{
              borderColor: props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#DC6803"
                : "#D0D5DD",
              backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <div
              data-tooltip-id={`${junctionId}`}
              data-tooltip-content={`
         ${props?.node?.name} 
         `}
              className="absolute bottom-8 z-[100] cursor-pointer blur-sm"
              onClick={props?.onPressNode}
            >
              <PressedContoller
                theme={props?.theme}
                image={props?.node?.image}
              />
            </div>
            <CustomText
              // text={
              //   step
              //     ? `(${step}.Op) ` + props?.node?.process
              //     : props?.node?.process
              // }
              text={
                step
                  ? `(${step}.Op) ` +
                    (props?.node?.process
                      ? props.node.process.slice(0, 2) + " ***"
                      : "")
                  : props?.node?.process
                  ? props.node.process.slice(0, 2) + " ***"
                  : ""
              }
              numberOfLines={1}
              style={{
                marginTop: 9,
                flexDirection: "column",
                fontSize: "11px",
                fontWeight: "400",
                display: "flex",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              text={props?.node?.name?.slice(0, 2) + " ***"}
              numberOfLines={1}
              style={{
                fontSize: "11px",
                flexDirection: "column",
                fontWeight: "500",
                display: "flex",
                marginTop: "-2px",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>

          {props.page === "pending" ? (
            <>
              <div
                className={` ${
                  pages === "purchase"
                    ? "group-hover:hidden"
                    : "group-hover:hidden"
                }  absolute flex-col top-[18px] right-[1px] items-center`}
              >
                <div className="flex flex-row items-center gap-1 ml-auto">
                  <p className={`text-xs font-normal text-secondary-600 `}>
                    {props?.product?.pieceGreen || "--"}
                  </p>
                  <ArchiveIcon />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={`${
                  pages === "purchase" ? "flex" : "group-hover:hidden"
                }   absolute flex-col -top-[0px] right-[1px] items-center`}
              >
                <div className="flex flex-row items-center gap-1 ml-auto">
                  <p className={`text-xs font-normal text-secondary-600 `}>
                    {props?.product?.pieceGreen || "0"}
                  </p>
                  <div className="ml-auto">
                    <QualityGreenIcon />
                  </div>
                </div>
                <div className="flex flex-row items-center gap-1 ml-auto">
                  <p className={`text-xs font-normal text-secondary-600 `}>
                    {props?.product?.pieceRed || "0"}
                  </p>
                  <div className="ml-auto">
                    <QualityRedIcon />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          // onClick={(e) => {
          //   e.stopPropagation();
          //   setShowDropDown(!showDropDown);
          // }}
          onClick={() => Toast("warning", "You are not authorized.")}
          className={`hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white 
           ${
            pages === "purchase" ? "group-hover:flex" : "group-hover:flex"
          }
           right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer`}
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[32px] right-[3px] z-[110] rounded-lg px-[6px] py-1 group-hover:flex">
            {pages === "purchase" ? (
              <>
                {menuOptionsPurchase?.map((item, index) => {
                  if (
                    operation &&
                    (index === 0 || index === 3 || index === 5 || index === 4)
                  ) {
                    return null;
                  }
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();

                        if (item.value === "quality") {
                          props?.onPressQuality();
                        }

                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {menuOptions?.map((item, index) => {
                  if (
                    operation &&
                    (index === 0 || index === 3 || index === 5 || index === 4)
                  ) {
                    return null;
                  }
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          // setDeleteId(id);
                        }
                        if (item?.value === "details") {
                          props?.onPressNode();
                        }
                        if (item?.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              props?.user?.id
                            }/${moment(props?.startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item?.value === "operation") {
                          navigate(
                            `/app/machine/digital-twin/operation-detail/${
                              allData?.internalOperation?.id
                            }/${moment(props?.startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item.value === "quality") {
                          props?.onPressQuality();
                        }
                        if (item.value === "operationDetail") {
                          props?.onPressProduct();
                        }
                        // if (
                        //   item.value === "edit" &&
                        //   allData?.metadata?.qualityStatus === "waiting"
                        // ) {
                        //   setEditJunction(true);
                        // }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            )}
          </div>
        )}
        <Tooltip className="z-[200]" id={`${junctionId}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <QualityControlForm
            junctionData={allData}
            refetch={refetch}
            setIsOpen={setIsJunction}
            onClose={() => {
              setIsJunction(false);
            }}
            junctionId={junctionId}
          />
        }
      />
      <CustomModal
        isOpen={editJunction}
        setIsOpen={setEditJunction}
        onClose={() => {
          setEditJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <EditJunctionModal
            junctionData={allData}
            refetch={refetch}
            setIsOpen={setEditJunction}
            onClose={() => {
              setEditJunction(false);
            }}
            junctionId={junctionId}
          />
        }
      />
    </>
  );
};

const PressedContoller = ({ onPress, image, theme }) => {
  return (
    <>
      {image ? (
        <CustomImage
          borderRadius={4}
          src={image}
          style={{
            width: 60,
            height: 45,
            borderRadius: 4,
            borderWidth: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
            borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
            backgroundColor: theme === "dark" ? "#000" : "#fff",
          }}
        />
      ) : (
        <div
          className={
            "min-w-[60px] min-h-[45px] max-w-[60px] rounded max-h-[45px] bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
          }
        >
          {/* {text?.name.toUpperCase()} */}
        </div>
      )}
    </>
  );
};

const UserController = ({
  onPress,
  image,
  theme,
  avatarPress,
  name,
  lastName,
  user,
  navigate,
  pages,
  startDate,
}) => {
  if (!onPress) {
    return (
      <div
        className={avatarPress && "cursor-pointer"}
        onClick={() => {
          if (avatarPress) {
            avatarPress();
          }
        }}
      >
        {pages === "purchase" ? (
          <>
            {image ? (
              <div className="cursor-pointer">
                <CustomImage
                  src={image}
                  style={{
                    width: 50,
                    height: 50,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    marginTop: 12,
                    borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                    backgroundColor: theme === "dark" ? "#000" : "#fff",
                  }}
                />
              </div>
            ) : (
              <div className="cursor-pointer">
                <NoAvatar
                  color={"#15B79E"}
                  size={50}
                  name={name}
                  lastName={lastName}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {image ? (
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
              >
                <CustomImage
                  src={image}
                  style={{
                    width: 50,
                    height: 50,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    marginTop: 12,
                    borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                    backgroundColor: theme === "dark" ? "#000" : "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
                className="cursor-pointer"
              >
                <NoAvatar
                  color={"#15B79E"}
                  size={50}
                  name={name}
                  lastName={lastName}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onPress}
        className="outline-none"
        style={{
          width: 50,
          height: 50,
          borderRadius: 100,
          flexDirection: "column",
          borderWidth: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderStyle: "dashed",
          display: "flex",
          borderColor: theme === "dark" ? "#424242" : "#D0D5DD",
          backgroundColor: theme === "dark" ? "#141414" : "#fff",
        }}
      >
        <PlusIcon />
      </button>
    );
  }
};

const QualityRedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M4.8125 1.75H3.28571C2.57563 1.75 2 2.32563 2 3.03571V8.90179C2 9.61187 2.57563 10.1875 3.28571 10.1875H9.71429C10.4244 10.1875 11 9.61187 11 8.90179V3.03571C11 2.32563 10.4244 1.75 9.71429 1.75H8.1875M4.8125 1.75V4.02098C4.8125 4.06877 4.86279 4.09985 4.90554 4.07848L6.5 3.28125L8.09446 4.07848C8.13721 4.09985 8.1875 4.06877 8.1875 4.02098V1.75M4.8125 1.75H8.1875"
        stroke="#D92D20"
        strokeWidth="0.8"
      />
      <path
        d="M7.75 5.75L5.25 8.25M5.25 5.75L7.75 8.25"
        stroke="#D92D20"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const QualityGreenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M4.8125 1.75H3.28571C2.57563 1.75 2 2.32563 2 3.03571V8.90179C2 9.61187 2.57563 10.1875 3.28571 10.1875H9.71429C10.4244 10.1875 11 9.61187 11 8.90179V3.03571C11 2.32563 10.4244 1.75 9.71429 1.75H8.1875M4.8125 1.75V4.02098C4.8125 4.06877 4.86279 4.09985 4.90554 4.07848L6.5 3.28125L8.09446 4.07848C8.13721 4.09985 8.1875 4.06877 8.1875 4.02098V1.75M4.8125 1.75H8.1875"
        stroke="#079455"
        strokeWidth="0.8"
      />
      <path
        d="M5 7.09615L6.07692 8.17308L8.5 5.75"
        stroke="#079455"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ArchiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#A3A3A3"
        strokeWidth="0.75"
      />
    </svg>
  );
};

const ProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.73641L13.327 2.21297C13.1636 1.53732 12.5378 1.08688 11.8516 1.14088L9.98257 1.28554L8.91188 0.337535C8.38454 -0.112434 7.61732 -0.112434 7.08816 0.337066L6.01745 1.2855L4.14838 1.14085C3.46998 1.09325 2.83598 1.53772 2.68995 2.15754C2.65973 2.25147 2.11388 3.94819 2.14932 3.83797L0.671758 4.71322C0.0798834 5.07669 -0.157242 5.80635 0.10779 6.44816L0.745446 7.99357L0.108258 9.53854C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7738L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53854L15.2544 7.9936L15.8925 6.44772C16.1571 5.80641 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1563C5.15685 13.1563 2.84376 10.8432 2.84376 8C2.84376 5.15685 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15685 13.1563 8C13.1563 10.8432 10.8432 13.1563 8.00001 13.1563Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductWaiting = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.8285 4.71372L14.195 3.73641L13.827 2.21297C13.6636 1.53732 13.0378 1.08688 12.3516 1.14088L10.4826 1.28554L9.41188 0.337535C8.88454 -0.112434 8.11732 -0.112434 7.58816 0.337066L6.51745 1.2855L4.64838 1.14085C3.96998 1.09325 3.33598 1.53772 3.18995 2.15754C3.15973 2.25147 2.61388 3.94819 2.64932 3.83797L1.17176 4.71322C0.579883 5.07669 0.342758 5.80635 0.60779 6.44816L1.24545 7.99357L0.608258 9.53854C0.342758 10.1808 0.580321 10.91 1.17176 11.273L2.80357 12.2435L3.17298 13.7738C3.33548 14.449 3.95851 14.8971 4.64835 14.8459L6.49726 14.7031L7.5881 15.6622C7.85226 15.8874 8.17591 16 8.50001 16C8.82363 16 9.14776 15.8874 9.41188 15.6622L10.5027 14.703L12.3516 14.8458C13.0502 14.9012 13.6645 14.4494 13.827 13.7738L14.195 12.2503L15.8285 11.273C16.4195 10.91 16.6566 10.1808 16.392 9.53854L15.7544 7.9936L16.3925 6.44772C16.6571 5.80641 16.4199 5.07672 15.8285 4.71372ZM8.50001 13.1563C5.65685 13.1563 3.34376 10.8432 3.34376 8C3.34376 5.15685 5.65685 2.84375 8.50001 2.84375C11.3432 2.84375 13.6563 5.15685 13.6563 8C13.6563 10.8432 11.3432 13.1563 8.50001 13.1563Z"
        fill="white"
      />
      <path
        d="M8.5 3.78125C6.17366 3.78125 4.28125 5.67366 4.28125 8C4.28125 10.3263 6.17366 12.2188 8.5 12.2188C10.8263 12.2188 12.7188 10.3263 12.7188 8C12.7188 5.67366 10.8263 3.78125 8.5 3.78125ZM11.1752 6.92516L8.36266 9.73766C8.27109 9.82922 8.15116 9.875 8.03125 9.875C7.91134 9.875 7.79138 9.82922 7.69984 9.73766L5.82484 7.86266C5.64175 7.67956 5.64175 7.38291 5.82484 7.19981C6.00794 7.01672 6.30459 7.01672 6.48769 7.19981L8.03125 8.74341L10.5123 6.26231C10.6954 6.07922 10.9921 6.07922 11.1752 6.26231C11.3583 6.44541 11.3583 6.74206 11.1752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ProductCompleted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.6615 4.71372L14.028 3.73641L13.66 2.21297C13.4966 1.53732 12.8708 1.08688 12.1846 1.14088L10.3156 1.28554L9.24489 0.337535C8.71755 -0.112434 7.95033 -0.112434 7.42117 0.337066L6.35045 1.2855L4.48139 1.14085C3.80299 1.09325 3.16898 1.53772 3.02295 2.15754C2.99273 2.25147 2.44689 3.94819 2.48233 3.83797L1.00477 4.71322C0.412891 5.07669 0.175766 5.80635 0.440797 6.44816L1.07845 7.99357L0.441266 9.53854C0.175766 10.1808 0.413329 10.91 1.00477 11.273L2.63658 12.2435L3.00599 13.7738C3.16849 14.449 3.79152 14.8971 4.48136 14.8459L6.33027 14.7031L7.42111 15.6622C7.68527 15.8874 8.00892 16 8.33302 16C8.65664 16 8.98077 15.8874 9.24489 15.6622L10.3357 14.703L12.1846 14.8458C12.8832 14.9012 13.4975 14.4494 13.66 13.7738L14.028 12.2503L15.6615 11.273C16.2525 10.91 16.4896 10.1808 16.225 9.53854L15.5874 7.9936L16.2255 6.44772C16.4901 5.80641 16.253 5.07672 15.6615 4.71372ZM8.33302 13.1563C5.48986 13.1563 3.17677 10.8432 3.17677 8C3.17677 5.15685 5.48986 2.84375 8.33302 2.84375C11.1762 2.84375 13.4893 5.15685 13.4893 8C13.4893 10.8432 11.1762 13.1563 8.33302 13.1563Z"
        fill="white"
      />
      <path
        d="M8.33301 3.78125C6.00666 3.78125 4.11426 5.67366 4.11426 8C4.11426 10.3263 6.00666 12.2188 8.33301 12.2188C10.6594 12.2188 12.5518 10.3263 12.5518 8C12.5518 5.67366 10.6594 3.78125 8.33301 3.78125ZM11.0082 6.92516L8.19566 9.73766C8.1041 9.82922 7.98416 9.875 7.86426 9.875C7.74435 9.875 7.62438 9.82922 7.53285 9.73766L5.65785 7.86266C5.47476 7.67956 5.47476 7.38291 5.65785 7.19981C5.84095 7.01672 6.1376 7.01672 6.3207 7.19981L7.86426 8.74341L10.3454 6.26231C10.5284 6.07922 10.8251 6.07922 11.0082 6.26231C11.1913 6.44541 11.1913 6.74206 11.0082 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductRejected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.6615 4.71372L14.028 3.73641L13.66 2.21297C13.4966 1.53732 12.8708 1.08688 12.1846 1.14088L10.3156 1.28554L9.24489 0.337535C8.71755 -0.112434 7.95033 -0.112434 7.42117 0.337066L6.35045 1.2855L4.48139 1.14085C3.80299 1.09325 3.16898 1.53772 3.02295 2.15754C2.99273 2.25147 2.44689 3.94819 2.48233 3.83797L1.00477 4.71322C0.412891 5.07669 0.175766 5.80635 0.440797 6.44816L1.07845 7.99357L0.441266 9.53854C0.175766 10.1808 0.413329 10.91 1.00477 11.273L2.63658 12.2435L3.00599 13.7738C3.16849 14.449 3.79152 14.8971 4.48136 14.8459L6.33027 14.7031L7.42111 15.6622C7.68527 15.8874 8.00892 16 8.33302 16C8.65664 16 8.98077 15.8874 9.24489 15.6622L10.3357 14.703L12.1846 14.8458C12.8832 14.9012 13.4975 14.4494 13.66 13.7738L14.028 12.2503L15.6615 11.273C16.2525 10.91 16.4896 10.1808 16.225 9.53854L15.5874 7.9936L16.2255 6.44772C16.4901 5.80641 16.253 5.07672 15.6615 4.71372ZM8.33302 13.1563C5.48986 13.1563 3.17677 10.8432 3.17677 8C3.17677 5.15685 5.48986 2.84375 8.33302 2.84375C11.1762 2.84375 13.4893 5.15685 13.4893 8C13.4893 10.8432 11.1762 13.1563 8.33302 13.1563Z"
        fill="white"
      />
      <path
        d="M8.33301 3.78125C6.00666 3.78125 4.11426 5.67366 4.11426 8C4.11426 10.3263 6.00666 12.2188 8.33301 12.2188C10.6594 12.2188 12.5518 10.3263 12.5518 8C12.5518 5.67366 10.6594 3.78125 8.33301 3.78125ZM11.0082 6.92516L8.19566 9.73766C8.1041 9.82922 7.98416 9.875 7.86426 9.875C7.74435 9.875 7.62438 9.82922 7.53285 9.73766L5.65785 7.86266C5.47476 7.67956 5.47476 7.38291 5.65785 7.19981C5.84095 7.01672 6.1376 7.01672 6.3207 7.19981L7.86426 8.74341L10.3454 6.26231C10.5284 6.07922 10.8251 6.07922 11.0082 6.26231C11.1913 6.44541 11.1913 6.74206 11.0082 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductControl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.7364L13.327 2.21297C13.1636 1.53731 12.5378 1.08687 11.8516 1.14087L9.98257 1.28553L8.91188 0.337528C8.38454 -0.112441 7.61732 -0.112441 7.08816 0.337059L6.01745 1.2855L4.14838 1.14084C3.46998 1.09325 2.83598 1.53771 2.68995 2.15753C2.65973 2.25146 2.11388 3.94818 2.14932 3.83797L0.671758 4.71321C0.0798834 5.07668 -0.157242 5.80634 0.10779 6.44815L0.745446 7.99356L0.108258 9.53853C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7737L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53853L15.2544 7.99359L15.8925 6.44771C16.1571 5.8064 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1562C5.15685 13.1562 2.84376 10.8432 2.84376 8C2.84376 5.15684 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15684 13.1563 8C13.1563 10.8432 10.8432 13.1562 8.00001 13.1562Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const GreenClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
    >
      <path
        d="M5.07725 3.07275V5.32916L3.66699 6.17532"
        stroke="#079455"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.823438 4.99906C0.823438 2.67621 2.70648 0.793164 5.02934 0.793164C7.26687 0.793164 9.09632 2.54043 9.22768 4.74497L10.0267 5.29428C10.0324 5.1966 10.0352 5.09817 10.0352 4.99906C10.0352 2.23438 7.79402 -0.00683594 5.02934 -0.00683594C2.26465 -0.00683594 0.0234375 2.23438 0.0234375 4.99906C0.0234375 7.76374 2.26465 10.005 5.02934 10.005C5.53655 10.005 6.02615 9.92952 6.48752 9.78926V8.94531C6.03323 9.11324 5.54199 9.20496 5.02934 9.20496C2.70648 9.20496 0.823438 7.32191 0.823438 4.99906Z"
        fill="#079455"
      />
      <path
        d="M7.61523 5.8346C7.61523 5.60307 7.61523 5.48731 7.66351 5.4235C7.70556 5.3679 7.76984 5.3335 7.83943 5.32935C7.9193 5.32458 8.01562 5.38879 8.20826 5.51722L10.7152 7.18849C10.8743 7.2946 10.9539 7.34766 10.9817 7.41454C11.0059 7.47301 11.0059 7.53872 10.9817 7.59718C10.9539 7.66406 10.8743 7.71712 10.7152 7.82324L8.20826 9.4945C8.01562 9.62293 7.9193 9.68714 7.83943 9.68237C7.76984 9.67822 7.70556 9.64382 7.66351 9.58823C7.61523 9.52441 7.61523 9.40865 7.61523 9.17713V5.8346Z"
        stroke="#079455"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 5.5V19.5M5 12.5H19"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RedClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.21006 4.01904V6.33483L3.7627 7.20325"
        stroke="#D92D20"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.83418 5.99625C0.83418 3.60645 2.77149 1.66914 5.16128 1.66914C7.55108 1.66914 9.48839 3.60645 9.48839 5.99625C9.48839 6.01268 9.4883 6.02909 9.48812 6.04548H10.2882C10.2883 6.02909 10.2884 6.01267 10.2884 5.99625C10.2884 3.16462 7.99291 0.869141 5.16128 0.869141C2.32966 0.869141 0.0341797 3.16462 0.0341797 5.99625C0.0341797 8.82787 2.32966 11.1234 5.16128 11.1234C5.68032 11.1234 6.18134 11.0462 6.65352 10.9028C6.62549 10.8648 6.60048 10.8243 6.57882 10.7818C6.50203 10.6311 6.48754 10.4426 6.48481 10.1172C6.06765 10.2511 5.62291 10.3234 5.16128 10.3234C2.77149 10.3234 0.83418 8.38604 0.83418 5.99625Z"
        fill="#D92D20"
      />
      <path
        d="M7.52637 8.12993C7.52637 7.80569 7.52637 7.64357 7.58947 7.51972C7.64497 7.41079 7.73354 7.32222 7.84248 7.26671C7.96632 7.20361 8.12844 7.20361 8.45268 7.20361H10.0737C10.398 7.20361 10.5601 7.20361 10.6839 7.26671C10.7929 7.32222 10.8814 7.41079 10.937 7.51972C11.0001 7.64357 11.0001 7.80569 11.0001 8.12993V9.75098C11.0001 10.0752 11.0001 10.2373 10.937 10.3612C10.8814 10.4701 10.7929 10.5587 10.6839 10.6142C10.5601 10.6773 10.398 10.6773 10.0737 10.6773H8.45268C8.12844 10.6773 7.96632 10.6773 7.84248 10.6142C7.73354 10.5587 7.64497 10.4701 7.58947 10.3612C7.52637 10.2373 7.52637 10.0752 7.52637 9.75098V8.12993Z"
        stroke="#D92D20"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FinishedJunctionCard;
