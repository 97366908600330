//this function is used to manipulate process_name using process_id / name for demo users only

const processMapping = {
  "9f2f9e86-4bf0-49ae-a352-a58ef57de675": "CNC Grinder",
  "42f9e4a2-49c6-4268-ba8e-544ab89d3fa3": "CNC Milling",
  "532c6a35-ee79-4aac-af4f-3fbcb198102b": "CNC Gear",
  "419806b1-71c8-4603-a7c7-3c6a63a2497d": "Grinding",
  "1be2e77a-0257-4b04-86f9-a97a5880a601": "Passive Equipment",
  "8d2b651b-0591-4b12-8744-66c929820385": "CNC Grinding",
  "12c9aea1-5de2-4b2f-9d3e-122a4e9aff65": "Cutting to Length",
  "c767f6b9-80b5-4608-83ed-d73226ef47f0": "Trimming",
  "8e6bddf7-886c-46d5-87bf-279d11125afa": "Source",
  "0924dfad-757f-4df6-b657-96cf4cfc08d5": "CNC Gear Grinding",
  "957365e7-bbae-4fff-999d-07ee7e28469b": "Manual Operation",
  "955bee8f-8479-4cb2-8e62-5d59e131e0dc": "Clearance",
  "208456cb-33bc-4fb8-ad62-4f2458b01f5a": "Belt Sander",
  "debee037-69be-4906-941d-28b63412daad": "2024 Hannover CNC Lathe",
  "f68781f7-e602-4eb2-be13-15bec47eeb25": "ELLE RAYBALAMA",
  "Yağ Alma": "Fat Removal",
  "Islah": "Improvement",
  "Kumlama": "Sandblasting",
  "Fosfat Kaplama": "Phosphate Coating",
  "Lazer Kesim": "Laser cutting",
  "Galvaniz Kaplama": "Galvanized Coating",
  "Bant Zımparası" : "Belt Sander",
  "CNC Dişli" : "CNC Gear",
  "CNC Torna 2" : "CNC Lathe 2",
  "CNC Freze 2" : "CNC Milling 2",
  "CNC Freze 3" : "CNC Milling 3",
  "Bant Zımparası" : "Belt Sander",
  "Bant Zımparası 2" : "Belt Sander 2",
  "CNC Taşlama" : "CNC Grinding",
  "Taşlama" : "Grinding",
  "Çapak Alma" : "Trimming",
  "CNC Dişli Taşlama": "CNC Gear Grinding",
  "Kama Açma" : "Opening Wedge",
  "İç Çap Taşlama": "Internal Diameter Grinding",
  "Sementasyon": "Cementation",
  "Diğer" : "Other",
  "Döküm" : "Casting",
  "Nitrasyon": "Nitration",
  "Meneviş":"Iridescent",
  "İndüksiyon-Silinecek": "Induction-To be deleted",
  "Krom Kaplama" : "Chrome Plating",
  "Derin Delik Delme" : "Deep Hole Drilling",
  "ÇEKME TESTİ" : "Tensile Test",
  "Zımba" : "Staple",
  "Vibrasyon": "Vibration",
  "Planya İşlemi": "Planing Process",
  "İndüksiyon": "Induction",
  "Dövme" : "Tatto",
  "Kaplama" : "Covering",
  "KESME" : "Cutting",
  "Dişli Taşlama" : "Gear Grinding",
  "ÇENTİK DARBE ANALİZİ" : "Notch Impact Analysis",
  "Dişli Açımı": "Gear Angle",
  "Büküm": "Twist",
  "Tel Erozyon" : "Wire Erosion",
  "CNC Freze" : "CNC Milling",
  "Kaynak" : "Source",
  "Tesfiye" : "Clearance",
  "Boy Kesme" : "Cutting to Length",
  "DELME" : "Drilling",
  "Manuel Operasyon" : "Manual Operation",
  "Dış Çap Taşlama" : "External Diameter Grinding",
  "Yüzey Taşlama" : "Surface Grinding",
  "MANUEL TORNA" : "Manual Lathe",
  "Pah Kırma": "Chamfering",
  "Plazma Nitrasyon" : "Plasma Niriding",
  "MANUEL FREZE" : "Manual milling",
  "CNC TORNA" : "CNC Lathe",
  "CNC Torna" : "CNC Lathe",
  "BACA" : "BACA",
  "BACA MAKINE TEDARIK" : "Chimney Machine Supply",
  "Hizmet Giriş Kalite Kontrol" : "Service Entry Quality Control",
  "Malzeme Giriş Kalite Kontrol" : "Material Input Quality Control",
  "İç Operasyon Kalite Kontrol" : "Internal Operations Quality Control",
  "Pasif Ekipmanlar" : "Passive Equipments",
};

export const manipulate = (idOrName) => {
  if (processMapping[idOrName]) {
    return processMapping[idOrName];
  }
  if (typeof idOrName === "string" && idOrName.trim() !== "") {
    return idOrName;
  }
  return "Process";
};