import React, { useState } from "react";
import "./montajj.css";

const Montaj4Tree = () => {
  const [visible, setVisible] = useState(true);

  // const data = [
  //   {
  //     name: "MAIN",
  //     operations: [
  //       { id: 1, name: "A" },
  //       {
  //         id: 2,
  //         name: "M",
  //         montage: [
  //           {
  //             name: "SUB 1",
  //             operations: [
  //               { id: 3, name: "E" },
  //               { id: 4, name: "F" },
  //               { id: 5, name: "G" },
  //               { id: 6, name: "H" },
  //             ],
  //           },
  //           {
  //             name: "SUB 2",
  //             operations: [
  //               { id: 7, name: "I" },
  //               { id: 8, name: "J" },
  //               {
  //                 id: 9,
  //                 name: "M",
  //                 montage: [
  //                   {
  //                     name: "SUB SUB 1",
  //                     operations: [
  //                       { id: 10, name: "S" },
  //                       { id: 11, name: "T" },
  //                       { id: 12, name: "Y" },
  //                       { id: 13, name: "Z" },
  //                     ],
  //                   },
  //                   {
  //                     name: "SUB SUB 2",
  //                     operations: [
  //                       { id: 14, name: "I" },
  //                       { id: 15, name: "J" },
  //                       { id: 16, name: "K" },
  //                       { id: 17, name: "L" },
  //                     ],
  //                   },
  //                   {
  //                     name: "SUB SUB 3",
  //                     operations: [
  //                       { id: 18, name: "N" },
  //                       { id: 19, name: "O" },
  //                       { id: 20, name: "P" },
  //                       { id: 21, name: "R" },
  //                     ],
  //                   },
  //                 ],
  //               },
  //               { id: 22, name: "K" },
  //               { id: 23, name: "L" },
  //             ],
  //           },
  //           {
  //             name: "SUB 3",
  //             operations: [
  //               { id: 24, name: "N" },
  //               { id: 25, name: "O" },
  //               { id: 26, name: "P" },
  //               { id: 27, name: "R" },
  //             ],
  //           },
  //         ],
  //       },
  //       { id: 28, name: "B" },
  //       { id: 29, name: "C" },
  //     ],
  //   },
  //   {
  //     name: "DEF",
  //     operations: [
  //       { id: 30, name: "Q" },
  //       { id: 31, name: "X" },
  //       { id: 32, name: "Y" },
  //       { id: 33, name: "Z" },
  //     ],
  //   },
  // ];

  const data = [
    {
      name: "montaj",
      key: "montaj",
    },
    { name: "torna" },
    {
      name: "freze",
    },
  ];

  return (
    <div className="w-full h-full ml-10">
      <ul class="tree">
        <li>
          <span className=" flex items-center gap-x-5">
            <div>MAIN ORDER </div>
            {data.map((e) => {
              return (
                <div
                  onClick={() => {
                    setVisible(!visible);
                  }}
                  class="max-w-[350px]"
                >
                  {e.name}
                </div>
              );
            })}
          </span>
          <ul className="">
            {/* PART 1 CHILD */}
            <li className="">
              <span className="flex gap-x-5 ">
                <div className="sticky">PART PART PART 1</div>
                <div className="sticky">Montaj card</div>
              </span>
              <ul className="">
                {/* PART 1 CHILD */}
                <li className="">
                  <span className=" flex items-center gap-x-5">
                    <div>PART PART PART 2 </div>
                    {data.map((e) => {
                      return <div class="max-w-[350px]">{e.name}</div>;
                    })}
                  </span>
                  <ul>
                    <li>
                      <span className="flex gap-x-5 ">
                        <div className="sticky">Product card</div>
                        <div className="sticky">Montaj card</div>
                      </span>
                    </li>
                    <li>
                      <div>List item 2</div>
                    </li>
                    <li>
                      <div>List item 3</div>
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="flex gap-x-5 ">
                    <div className="sticky">Product card</div>
                    <div className="sticky">Montaj card</div>
                  </span>
                  <ul>
                    <li>
                      <div>List item 1</div>
                    </li>
                    <li>
                      <div>List item 2</div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <div class="sticky">Reptiles</div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Montaj4Tree;
