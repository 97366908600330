import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { Button, CustomModal, Loading } from "../../components";
import { personalService } from "../../services/personal.service";
import UserListTable from "./components/UserListTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../hooks/useStores";
import { settingsStore } from "../../stores/settings.store";
import { searchInArray } from "../../utils/arrayUtils";
import DepartmentSlider from "./components/DepartmentSlider";
import AddUserModal from "../../steps/users/modals/AddUserModal";
import { Toast } from "../../utils/toastify/toast";
import { UpuIconHeader } from "../../assets/icons";
import { PersonHeaderIcon } from "../../assets/icons/PersonIcons";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";

const PersonnelList = () => {
  const { t } = useTranslation();

  const defaultValues = {
    id: "",
    name: "",
    lastName: "",
    email: "",
    avatar: "",
    authorization: "",
    startDate: "",
    upuPoint: "0",
    totalCost: "",
    salary: "",
    talents: [],
    approvingName: "",
    approvingAvatar: "",
    gains: [],
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { settings, auth } = useStore();
  const [query, setQuery] = useState("");
  const [personals, setPersonals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchVal, setSearchVal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempPersonals, setTempPersonals] = useState([]);
  const [selectedTab, setSelectedTab] = useState("personel");
  const [selectedPersonal, setSelectedPersonal] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [tabData] = useState([
    { name: t("addPersonal:personalInfo"), value: "personel" },
    { name: t("addPersonal:personalTalent"), value: "talent" },
    { name: t("addPersonal:personalSuccess"), value: "success" },
  ]);

  const [tableHeadItems, setTableHeadItems] = useState([
    { name: "Profil", value: "avatar" },
    { name: "İsim", value: "name" },
    { name: "Soyisim ", value: "lastName" },
    { name: "Yetki ", value: "metadata.authorization" },
    { name: "Upu Point", value: "upuPoint.current_balance" },
    { name: "Telefon No", value: "telNo" },
    { name: "Yetenek", value: "talent" },
  ]);

  const getPersonals = async () => {
    setLoading(true);
    let { data } = await personalService.activePersonals();
    setTempPersonals(data);
    setPersonals(data);
    setLoading(false);
  };
  useEffect(() => {
    if (selectedDepartment) {
      let filteredUsers = tempPersonals.filter((person) => {
        return person?.metadata?.department?.id == selectedDepartment?.id;
      });
      if (filteredUsers.length > 0) {
        setPersonals(filteredUsers);
      } else {
        setPersonals(tempPersonals);
        setSelectedDepartment();
        Toast(
          "error",
          `${selectedDepartment.name} ${t("addPersonal:departmentEmpty")}`
        );
      }
    } else {
      setPersonals(tempPersonals);
    }
  }, [selectedDepartment, setSelectedDepartment]);

  useEffect(() => {
    if (searchVal) {
      const filteredPersonals = [
        searchInArray(searchVal, tempPersonals, "name"),
      ];
      filteredPersonals.push(
        searchInArray(searchVal, tempPersonals, "lastName")
      );
      setPersonals(filteredPersonals.flat());
      setCurrentPage(1);
    } else {
      setPersonals(tempPersonals);
    }
  }, [searchVal, setSearchVal, tempPersonals]);

  useEffect(() => {
    settingsStore.setSelectedPackage("person");
    getPersonals();
  }, []);

  useEffect(() => {
    if (search !== "") {
      setTempPersonals(searchInArray(search, personals, "name"));
    } else setTempPersonals(personals);
  }, [search, setSearch]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="w-full h-full flex flex-col scrollbar-hide">
        <div className="min-h-[60px] max-h-[60px] flex items-center flex-row dark:bg-[#0F0F0F]">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button
              colorType={"tertiary-gray"}
              iconLeft={<HumburgerIcon />}
              size={"md"}
              onClick={() => settings.setIsActive(true)}
            />
          </span>
          <div className="flex flex-row gap-1 items-center justify-center pt-2">
            <UpuIconHeader />
            <PersonHeaderIcon />
          </div>
          <div className="flex ml-auto">
            <Button
              label={t("addPersonal:modalTitle")}
              colorType={"primary-person"}
              // onClick={() => {
              //   setIsOpen(true);
              //   setSelectedPersonal(null);
              // }}
              onClick={() => Toast("warning", t("alert:warning"))}
              iconLeft={<PlusIcon />}
              size={"md"}
            />
          </div>
        </div>
        <hr className="h-[1px] w-full text-secondary-400 ml-auto dark:border-[#202020]  drop-shadow-md mt-0.5" />
        <div className="items-center hidden w-full pt-3 pb-5 bg-red-500">
          <button
            onClick={() => {
              setIsOpen(true);
              setSelectedPersonal(null);
            }}
            type={"button"}
            className="w-full h-10 flex justify-center items-center font-semibold rounded-lg bg-teal-600 border-[#0E9384] py-2 gap-x-1 text-[14px] text-white px-4"
          >
            <PlusIcon width={20} />
            {t("addPersonal:modalTitle")}
          </button>
        </div>
        {loading ? (
          <div className="w-full min-h-[70vh] flex items-center justify-center">
            <Loading primary={"#15B79E"} secondary={"#fff"} size={32} />
          </div>
        ) : (
          <>
            <DepartmentSlider
              theme={"primary-person"}
              selectedDepartment={selectedDepartment}
              setSelectedDepartment={setSelectedDepartment}
              setCurrentPage={setCurrentPage}
              equipmentPage={true}
            />
            <UserListTable
              query={query}
              selectedDepartment={selectedDepartment}
              setQuery={setQuery}
              handlePageChange={handlePageChange}
              tableHeadItems={tableHeadItems}
              setSearchVal={setSearchVal}
              addNewButtonClick={() => setIsOpen(true)}
              onEdit={(person) => {
                setSelectedPersonal(person);
                setIsOpen(true);
              }}
              getPersonals={getPersonals}
              tableBodyItems={personals}
              setTableBodyItems={setPersonals}
              userValue={personals.length}
              search={search}
              setSearch={setSearch}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </>
        )}
        <CustomModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalTitle={t("addPersonal:modalTitle")}
          isBadges={selectedPersonal ? false : true}
          badgesValue={
            tempPersonals?.length + "/" + auth?.user?.company?.limits?.user
          }
          badgesColor={"fill-success"}
          badgesSize={"md"}
          children={
            <AddUserModal
              theme={"primary-person"}
              defaultValues={defaultValues}
              data={tabData}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setIsOpen={setIsOpen}
              handleData={getPersonals}
              selectedPersonal={selectedPersonal}
              setSelectedPersonal={setSelectedPersonal}
              pathname={pathname}
              navigate={navigate}
            />
          }
        />
      </div>
    </>
  );
};

export default PersonnelList;
