import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ImageUploader } from "../../assets/icons/stepsIcons";

const PDFJS = window.pdfjsLib;

const CropperPdf = ({ setPdfData, pdfData, setIsCrop, pdfLink }) => {
  const { t } = useTranslation();
  const [pdf, setPdf] = useState("");
  const [width, setWidth] = useState(0);
  const [image, setImage] = useState("");
  const [height, setHeight] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfRendering, setPdfRendering] = useState(false);
  const [pageRendering, setPageRendering] = useState(false);

  const showPdf = async (event) => {
    try {
      setPdfRendering(true);
      const file = event.target.files[0];
      const uri = URL.createObjectURL(file);

      const _PDF_DOC = await PDFJS.getDocument({ url: uri });
      setPdf(_PDF_DOC);
      setPdfRendering(false);
      document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
    }
  };

  const renderPage = async () => {
    setPageRendering(true);

    const page = await pdf.getPage(currentPage);
    const viewport = page.getViewport(currentPage);
    const renderContext = {
      canvasContext: document.querySelector("#pdf-canvas").getContext("2d"),
      viewport: viewport,
    };

    setWidth(viewport.width);
    setHeight(viewport.height);

    await page.render(renderContext);

    const canvas = document.getElementById("pdf-canvas");
    const img = canvas.toDataURL("image/png");
    setPageRendering(false);
    setImage(img);

    setPdfData(img);
    setIsCrop(true);
  };

  useEffect(() => {
    pdf && renderPage();
  }, [pdf, currentPage]);

  return (
    <div className="w-full flex items-center h-[66px]">
      <div className="flex flex-row items-center focus:outline-none cursor-pointer w-full h-12 gap-3">
        <div className="flex items-center">
          <div className="w-full h-full rounded flex items-center justify-center">
            <div className="text-main flex items-center justify-center max-w-[64px] max-h-[48px] min-w-[64px] min-h-[48px]">
              {pdfData ? (
                <img
                  onDoubleClick={() => {
                    window.open(pdfLink, "_blank");
                  }}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    borderRadius: 4,
                    height: "100%",
                  }}
                  src={pdfData}
                  alt="PDF"
                />
              ) : (
                <ImageUploader color={"#D0D5DD"} width="64" height="48" />
              )}
            </div>
          </div>
        </div>

        <input
          type="file"
          id="file-to-upload"
          accept="application/pdf"
          hidden
          onChange={showPdf}
        />
        <div className="flex flex-col items-start">
          <p className="text-md font-semibold text-secondary-700">
            {t("product:technicalPicture")}
          </p>
          <button
            className="text-secondary-500 text-md font-normal underline cursor-pointer dark:text-[#D6D6D6]"
            id="upload-button"
            type="button"
            onClick={() => document.getElementById("file-to-upload").click()}
          >
            {pdfData
              ? t("product:updateTechnicalDrawing")
              : t("product:addTechnicalDrawing")}
          </button>
        </div>
      </div>

      <div id="pdf-main-container" style={{ display: "none" }}>
        <div id="pdf-contents">
          <div id="image-convas-row">
            <canvas id="pdf-canvas" width={width} height={height}></canvas>

            {image && (
              <img
                id="image-generated"
                src={image}
                alt="pdfImage"
                style={{ width: "100%", height: "100%", display: "none" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropperPdf;
