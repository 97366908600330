import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { QuantityIcon } from "../../../assets/icons/stepsIcons";
import { QuantityDeclineIcon } from "../../../assets/icons/productIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { useTranslation } from "react-i18next";
function SupplyServiceCard({ item }) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full border border-[#D0D5DD] rounded-lg p-2 flex flex-col relative">
      <div className="flex">
        {item?.job?.product?.image ? (
          item?.job?.product?.image
        ) : item?.externalOperation?.product?.image ? (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
            <img
              src={generateFileUrl(
                item?.job?.product?.image
                  ? item?.job?.product?.image
                  : item?.externalOperation?.product?.image
              )}
            />
          </div>
        ) : (
          <div className="w-[64px] h-[48px] min-w-[64px] min-h-[48px] rounded border border-secondary-200 overflow-hidden mr-3">
            <NoAvatar
              name={
                item?.job?.product?.name
                  ? item?.job?.product?.name
                  : item?.externalOperation?.product?.name || "--"
              }
              rounded={4}
              theme={"product"}
            />
          </div>
        )}
        <div className="flex flex-col gap-y-1">
          <span className="text-[#101828] font-medium text-sm">
            {item?.job?.product?.name
              ? item?.job?.product?.name
              : item?.externalOperation?.product?.name}
          </span>
          <span className="text-xs font-normal text-[#475467]">
            {t("product:stock")} ID:
            {item?.job?.product?.stockCode
              ? item?.job?.product?.stockCode
              : item?.externalOperation?.product?.stockCode
              ? item?.job?.product?.stockCode
                ? item?.job?.product?.stockCode
                : item?.externalOperation?.product?.stockCode
              : "--"}
          </span>
        </div>
      </div>
      <div className="flex w-full mt-1 items-center justify-center">
        <div className="flex w-[50%] whitespace-nowrap items-center gap-x-[2px]">
          <span className="flex items-center gap-x-1">
            <QuantityIcon />
            <span className="text-xs font-medium text-[#475467]">
              {item?.job?.product?.qualityQuantity
                ? item?.job?.product?.qualityQuantity
                : item?.externalOperation?.product?.qualityQuantity || "0"}
            </span>
          </span>
          <span className="text-[#667085] font-normal text-[10px] leading-4">
            {t("product:totalStock")}
          </span>
        </div>
        <div className="flex w-[50%] whitespace-nowrap items-center gap-x-[2px]">
          <span className="flex items-center gap-x-1">
            <QuantityDeclineIcon />
            <span className="text-xs font-medium text-[#D92D20]">
              {item?.job?.product?.scrapQuantity
                ? item?.job?.product?.scrapQuantity
                : item?.externalOperation?.product?.scrapQuantity || "0"}
            </span>
          </span>
          <span className="text-[#667085] font-normal text-[10px] leading-4">
            {t("product:totalScrap")}
          </span>
        </div>
      </div>
      {item?.type === "main_product" ? null : (
        <div className="flex h-full min-w-[24px] max-w-[24px] absolute top-0 right-0 rounded-r-lg flex-col">
          <div className="flex w-full h-full bg-[#EAECF0] border-[#D0D5DD] border-l border-b rounded-tr-lg rounded-bl-lg max-h-[70px]">
            <p
              style={{ writingMode: "vertical-rl" }}
              className="w-full h-full text-[10px] leading-[10px] mt-2 mr-[5px] text-[#475467]]"
            >
              {item?.step}.{t("product:operation")}
            </p>
          </div>
          <div className="flex min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]"></div>
        </div>
      )}
    </div>
  );
}

export default SupplyServiceCard;
