import React, { useState } from "react";
import Junction from "../../../components/junction/Junction";
import Button from "../../../components/buttons/Button";
import { TableArrowLeft } from "../../../assets/icons/lossIcon";
import { useNavigate } from "react-router-dom";
import generateFileUrl from "../../../utils/generateFileUrl";
import { DangerIcon, SensorIcon } from "../../../assets/icons/machineIcons";
import { equipmentService } from "../../../services/equipment.service";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { Loading } from "../embeddedServer/svg/EmbeddedServerIcons";
import { QrIcon } from "../../../assets/icons/equipmentIcon";
import CustomModal from "../../../components/modal/CustomModal";
import MachineQrModal from "./MachineQrModal";
import FinishJunctionModal from "../../../components/modal/FinishJunctionModal";
import moment from "moment";
import { manipulate } from "../../../utils/manipulate";

const DetailHeader = ({
  t,
  name,
  match,
  image,
  process,
  refetch,
  warning,
  isLoading,
  setWarning,
  equipmentData,
  junctionData,
  setRunState,
  isTourOpen,
  isOpenQr,
  setIsOpenQr,
  setStepIndexState,
  runState,
  dateChangeCount,
}) => {
  const activeJunction = junctionData?.find(
    (item) => item?.status === "active"
  );
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);

  const [isFaultAlert, setIsFaultAlert] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const faultButtonActive = async () => {
    await equipmentService
      .faultButton(equipmentData?.id, !warning)
      .then((res) => {
        if (res?.data?.code == 0) {
          setWarning(true);
          Toast("success", t("consoleModal:errorSent"));
        } else if (res?.data?.code == 1) {
          setWarning(false);
          Toast("error", t("consoleModal:errorRemoved"));
        } else if (res?.data?.code == 2) {
          setIsFaultAlert(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-full h-fit flex items-center sm:flex-wrap xs:flex-wrap">
      <div className="flex flex-col items-center gap-1">
        {isLoading ? (
          <div className="max-w-[32px] min-w-[32px] min-h-[32px] max-h-[32px] animate-pulse bg-gray-200 rounded" />
        ) : (
          <Button
            colorType={"tertiary-gray"}
            onClick={() => {
              navigate(-dateChangeCount);
            }}
            size={"sm"}
            iconLeft={
              <div className="rotate-180">
                <TableArrowLeft />
              </div>
            }
          />
        )}
        {isLoading ? (
          <div className="max-w-[36px] min-w-[36px] min-h-[36px] max-h-[36px] animate-pulse bg-gray-200 rounded" />
        ) : (
          <span className="step-1 ">
            <Button
            blur={"blur-sm"}
            disabled={true}
              colorType={"secondary-machine"}
              onClick={() => {
                setIsOpenQr(true);
                setStepIndexState((prev) => prev + 2);
              }}
             
              size={"sm"}
              iconLeft={<QrIcon color={"#7F56D9"} />}
            />
          </span>
        )}
      </div>
      {isLoading ? (
        <div className="max-w-[96px] min-w-[96px] min-h-[72px] max-h-[72px] ml-2 animate-pulse bg-gray-200 rounded" />
      ) : (
        <div className="max-w-[96px] min-w-[96px] min-h-[72px] max-h-[72px] ml-2 flex items-center justify-center rounded border border-secondary-200 overflow-hidden">
          <img
            src={generateFileUrl(image)}
            className="max-w-[96px] min-w-[96px] min-h-[72px] max-h-[72px] blur-sm"
          />
        </div>
      )}
      <div className="flex flex-col ml-4">
        {isLoading ? (
          <div className="h-[26px] w-[160px] rounded-full bg-gray-200 animate-pulse" />
        ) : (
          <h1 className="text-xl font-semibold text-secondary-900 truncate">
            {/* {name ? name.slice(0, 3) + " ***" : "---"} */}
            {manipulate(name)}
          </h1>
        )}
        {isLoading ? (
          <div className="h-[22px] mt-3 w-[120px] rounded-full bg-gray-200 animate-pulse" />
        ) : (
          <p className="text-lg text-secondary-600">
            {process ? process.slice(0, 3) + " ***" : "---"}
          </p>
        )}
      </div>
      {/* <div>
        <Button
          colorType={"secondary-machine"}
          size={"sm"}
          label={"start"}
          onClick={() => {
            setRunState(true);
          }}
        />
      </div> */}
      <div className="ml-auto w-fit flex items-center sm:mt-3">
        {isLoading ? (
          <div className="min-w-[335px] mr-2 h-[74px] rounded-xl bg-gray-200 animate-pulse" />
        ) : (
          <div className="min-w-[335px] mr-2">
            {/* {activeJunction && (
              <Junction
                step={activeJunction?.header?.step}
                id={activeJunction?.id}
                props={{
                  // onPressUser: function () {},
                  // onPressProduct: function () {},
                  avatarPress: () => {
                    setIsOpenFinishJunction(true);
                  },
                  node: { name, image, process },
                  product: {
                    code: activeJunction?.job?.product?.code,
                    name: activeJunction?.job?.product?.name,
                    image: activeJunction?.job?.product?.image,
                    piece: activeJunction?.job?.piece,
                  },
                  user: {
                    image: activeJunction?.user?.avatar,
                    name: activeJunction?.user?.name,
                    lastName: activeJunction?.user?.lastName,
                  },
                  time: moment(activeJunction?.startDate).format("HH:mm"),
                }}
              />
            )} */}
          </div>
        )}
        <div className="flex gap-x-2 mt-auto w-full">
          {isLoading ? (
            <div className="w-14 h-14 bg-gray-200 animate-pulse rounded-lg" />
          ) : (
            <button

            onClick={() => {
              Toast("warning", "Provides access to the screen where the machine's sensor settings are made.");
             }}
              // onClick={async () => {
              //   setButtonLoading(true);
              //   await embeddedServerService
              //     .stateEmbeddedServer(match[1], 1)
              //     .then((res) => {
              //       if (res?.code === 1) {
              //         setButtonLoading(false);
              //         Toast("error", res?.message);
              //         setAlert(true);
              //       } else if (res?.code === 2) {
              //         setButtonLoading(false);
              //         Toast("error", t("equipments:noLimitEmbeddedServer"));
              //       } else {
              //         setButtonLoading(false);
              //         navigate(
              //           `/app/machine/digital-twin/embedded-server/${match[0]}`
              //         );
              //       }
              //     });
              // }}
              
              className="w-14 h-14 border-[1px]  rounded-lg flex items-center justify-center  border-[#D6BBFB] step-5"
            >
              {buttonLoading ? (
                <Loading color={"#6941C6"} secondary={"#fff"} size={22} />
              ) : (
                <SensorIcon />
              )}
            </button>
          )}
          {isLoading ? (
            <div className="w-14 h-14 bg-gray-200 animate-pulse rounded-lg" />
          ) : (
            <button
              onClick={() => {
               Toast("warning", "Sends red signal to the machine");
              }}
             
              className={`w-14 h-14  border-[1px] rounded-lg flex items-center justify-center  border-[#FDA29B] text-[#B42318] step-6 ${
                warning ? "bg-[#D92D20] text-[#FFF]" : "bg-[#FFF]"
              } `}
            >
              <DangerIcon color={warning ? "#fff" : "#B42318"} />
            </button>
          )}
        </div>
      </div>
      <AlertModal
        icon={<DangerIcon />}
        subTitle={t("consoleModal:noConnection")}
        isOpen={isFaultAlert}
        setIsOpen={setIsFaultAlert}
        label={t("buttons:okey")}
        applyButton={() => {
          setIsFaultAlert(false);
        }}
      />
      <AlertModal
        icon={<DangerIcon />}
        title={"upu.io"}
        subTitle={t("embedded:failedConnectEmbedded")}
        isOpen={alert}
        setIsOpen={setAlert}
        label={
          buttonLoading ? (
            <Loading color={"#fff"} secondary={"red"} size={22} />
          ) : (
            <p>{t("embedded:quickEntry")}</p>
          )
        }
        applyButton={async () => {
          setButtonLoading(true);
          await embeddedServerService
            .swithToServerPriority(match[1])
            .then((res) => {
              setButtonLoading(false);
              navigate(`/app/machine/digital-twin/embedded-server/${match[0]}`);
            });
        }}
      />
      <CustomModal
        isOpen={isOpenQr}
        setIsOpen={setIsOpenQr}
        modalTitle={t("embedded:getQr")}
        children={<MachineQrModal nodeId={match[2]} name={name} />}
      />
      <CustomModal
        isOpen={isOpenFinishJunction}
        setIsOpen={setIsOpenFinishJunction}
        modalTitle={t("product:finishJuction")}
        children={
          <FinishJunctionModal
            handleData={refetch}
            modalTheme={"machine"}
            junctionId={activeJunction?.id}
            closeModal={() => setIsOpenFinishJunction(false)}
            type={activeJunction?.internalOperation ? "internal" : "external"}
          />
        }
      />
    </div>
  );
};

export default DetailHeader;
