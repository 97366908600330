import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../../hooks/useStores";
import ChatHeader from "../components/ChatHeader";
import generateFileUrl from "../../../utils/generateFileUrl";
import TextField from "../components/TextField";
import { handleComponent } from "../components/ChatBodyItems";
import { usePubNub } from "pubnub-react";
import { chatService } from "../../../services/chat.service";

const Chat = observer(() => {
  const { chat, auth } = useStore();
  const { conversationId } = useParams();
  const pubnub = usePubNub();
  const channels = [conversationId];
  const findConversation = chat?.messageList?.find(
    (item) => item.conversationId === conversationId
  );
  const handleOldChatMessages = () => {
    pubnub.fetchMessages(
      {
        channels: [conversationId],
        count: 300,
        includeMessageActions: true,
      },
      function (status, response) {
        if (Object.keys(response?.channels)?.length > 0) {
          chat.setChatMessages(response?.channels[conversationId].reverse());
        } else {
          chat.setChatMessages([]);
        }
      }
    );
  };
  const sendMessage = (message) => {
    pubnub.publish({
      channel: conversationId,
      message: message,
    });
    chatService.sendMessageToSignal({
      channel: conversationId,
      message: message,
    });
  };

  useEffect(() => {
    handleOldChatMessages();
    const listenerParams = {
      message: function (message) {
        chat?.addMessageToChat(message);
      },
    };
    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener(listenerParams);
    };
  }, [conversationId]);

  useEffect(() => {
    chat.setCurrentGroupChat(findConversation);
  }, [conversationId]);

  // const allParticipants =
  //   findConversation?.type === "group-task"
  //     ? findConversation?.participants.map(
  //         (participant) => `${participant.name + " " + participant.lastName}`
  //       )
  //     : [];

  const allMessages = useMemo(() => {
    const unique = [];
    (chat.chatMessages || []).map((x) =>
      unique.filter((a) => a?.timetoken == x?.timetoken).length > 0
        ? null
        : unique.push(x)
    );
    return unique;
  }, [chat?.chatMessages, chat?.chatMessages?.length]);

  return (
    <div className="flex-1 h-full flex-col flex justify-between p-5  w-[33%] min-w-[33%] xs:min-w-full">
      <ChatHeader
        findConversation={findConversation}
        name={
          findConversation?.type === "group-task"
            ? findConversation?.name
            : findConversation?.participants[0]?.name +
              " " +
              findConversation?.participants[0]?.lastName
        }
        image={generateFileUrl(
          findConversation?.type === "group-task"
            ? findConversation?.task?.image
            : findConversation?.participants[0]?.avatar
        )}
        type={findConversation?.type}
        conversationId={conversationId}
        setSelectedGroup={chat.setCurrentGroupChatId}
        participants={
          findConversation?.type === "group-task"
            ? findConversation?.participants?.length
            : 0
        }
      />
      <div className="flex-1 flex-col-reverse flex overflow-y-auto scrollbar-hide pt-3">
        {allMessages?.map((item, index) => {
          return handleComponent(item, index);
        })}
       
      </div>
      <TextField sendMessage={sendMessage} />
    </div>
  );
});
export default Chat;
