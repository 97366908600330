import React from "react";
import MonthlyChart from "./MonthlyChart";
import moment from "moment";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { useTranslation } from "react-i18next";
import ReactECharts from "echarts-for-react";
import useWindowDimensions from "../../../../hooks/useWindowDimension";

const PersonalMonthlyChart = ({
  data,
  legendSelected,
  setLegendSelected,
  height = "290px",
  start,
  end,
  setDate,
}) => {
  let deger = "200";
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const onChartClick = (params) => {
    if (params?.name) {
      setDate(params.name);
    }
  };
  const onEvents = {
    click: onChartClick,
    legendselectchanged: function (params) {
      setLegendSelected(params.selected);
    },
    legendselectchanged: function (params) {
      setLegendSelected(params.selected);
    },
  };
  const dates = data?.dates?.map((item) =>
    String(moment(item).format("YYYY-MM-DD"))
  );
  const option = {
    title: {},
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "15%"];
      },
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      selected: legendSelected,
      data: data?.legend,
      right: "5%",
      left: "0",
      top: "0%",
      alignTicks: false,
      formatter: function (name) {
        let itemValue = data?.series?.filter((item) => item.name === name);
        const total = itemValue[0].data.reduce((a, b) => {
          return a + b;
        }, 0);
        return `{name|${name}}:{total|Avg: ${Number((total / 30).toFixed(2))}}`;
      },
      textStyle: {
        rich: {
          name: { fontWeight: "regular", align: "left", fontSize: 10 },
          total: {
            fontSize: 10,
            fontWeight: "regular",
          },
        },
      },
    },
    grid: {
      left: "1%",
      right: "1%",
      bottom: "2%",

      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: dates,
      axisLabel: {
        formatter: function (value, index) {
          return value.slice(8, 10);
        },
        show: true,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "upu.point",
        position: "right",
        alignTicks: false,
        name: "upu.point",
        position: "right",
        alignTicks: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            left: "10px",
          },
        },
        axisLabel: {
          formatter: "{value}",
          show: true,
          style: {
            right: -"20px",
          },
          textStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            marginRight: 20,
          },
        },
      },
      {},
      {},
    ],
    series: data?.series,
  };
  console.log(option);
  return (
    <div className="w-full h-[300px] border border-secondary-200 rounded-lg shadow-xs">
      <div
        className=" relative mt-3 w-full h-full flex items-center"
        style={{
          minWidth: "100%",
          marginLeft: "20px",
        }}
      >
        <ReactECharts
          onEvents={onEvents}
          option={option}
          style={{
            height: 250,
            width: "95%",
          }}
        />
        <div
          className={`absolute -top-4 font-normal w-[400px]  gap-2 dark:text-[#F5F5F5] left-9 gap-x-2`}
        >
          <p className="text-sm mt-4 font-semibold inline-block ">
            {t("settingTab:start")} : {start ? start : ""}
          </p>
          <p className="text-sm ml-2 mt-4 font-semibold inline-block ">
            {t("settingTab:end")} : {end ? end : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PersonalMonthlyChart;
