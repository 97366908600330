import React from "react";
import CustomText from "../CustomText";
import CustomImage from "../CustomImage";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
const styles = {
  container: {
    height: 80,
    width: "100%",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
  base: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
  },
  box: {
    width: "49%",
    height: 44,
    borderWidth: 1,
    borderRadius: 4,
    borderTopWidth: 4,
    alignItems: "center",
    position: "relative",
    paddingLeft: "12px",
    paddingRight: "12px",
    flexDirection: "column",
    display: "flex",
  },
  image: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  squareButton: {
    width: 60,
    height: 45,
    borderRadius: 4,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    display: "flex",
  },
  userContent: {
    position: "absolute",
    width: 50,
    height: 50,
    zIndex: 100,
    bottom: 32,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
  },
  avatar: {
    width: 50,
    height: 50,
    flexDirection: "column",
    borderRadius: 100,
    display: "flex",
  },
  circleButton: {
    width: 50,
    height: 50,
    borderRadius: 100,
    flexDirection: "column",
    borderWidth: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    display: "flex",
  },
  boldText: {
    fontSize: "11px",
    flexDirection: "column",
    fontWeight: "500",
    display: "flex",
    marginTop: "-2px",
    textAlign: "center",
  },
  lightText: {
    marginTop: 9,
    flexDirection: "column",
    fontSize: "11px",
    fontWeight: "400",
    display: "flex",
    textAlign: "center",
  },
  timeSection: {
    position: "absolute",
    display: "flex",
    top: 18,
    left: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  pieceSection: {
    position: "absolute",
    top: 18,
    right: 1,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
};

const ActivePurchaseJunction = ({
  id,
  materialImage,
  materialName = "materialName",
  materialCode = "materialCode",
  onClickMaterial = () => {
    console.log("material");
  },
  onClickUser = () => {
    console.log("user");
  },
  onClickSupplier = () => {
    console.log("supplier");
  },
  userImage,
  userName = "userName",
  userLastName = "userLastName",
  supplierImage,
  supplierName = "supplierName",
  supplierGroupName = "supplierGroupName",
  quantity = "quantity",
  days = "7 day",
}) => {
  const { t } = useTranslation();
  return (
    <div style={styles.container}>
      <div style={styles.timeSection}>
        <ClockIcon />
        <CustomText
          text={days}
          style={{
            color: "#667085",
            fontSize: 10,
            marginLeft: 2,
            marginBottom: 1,
          }}
        />
      </div>
      <div style={{ ...styles.userContent }}>
        <UserContoller
          id={id}
          onClickUser={onClickUser}
          image={userImage}
          name={userName}
          lastName={userLastName}
        />
      </div>
      <div style={styles.base}>
        <div
          style={{
            ...styles.box,
            borderColor: "#17B26A",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
            <PressedContoller image={materialImage} onClick={onClickMaterial} />
          </div>
          <CustomText
            text={materialCode?.slice(0, 3) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.lightText,
              color: "#667085",
            }}
          />
          <CustomText
            text={materialName?.slice(0, 3) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.boldText,
              color: "#344054",
            }}
          />
        </div>
        <div
          style={{
            ...styles.box,
            borderColor: "#17B26A",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ position: "absolute", bottom: 32, zIndex: 100 }}>
            <PressedContoller image={supplierImage} onClick={onClickSupplier} />
          </div>
          <CustomText
            text={supplierGroupName?.slice(0, 3) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.lightText,
              color: "#667085",
            }}
          />
          <CustomText
            text={supplierName?.slice(0, 3) + " ***"}
            numberOfLines={1}
            style={{
              ...styles.boldText,
              color: "#344054",
            }}
          />
        </div>
      </div>
      <div style={styles.pieceSection}>
        <CustomText
          text={quantity}
          style={{
            color: "#667085",
            fontSize: 10,
            marginRight: 2,
            marginBottom: 2,
          }}
        />
        <ArchiveIcon />
      </div>
      <Tooltip className="z-[100]" id={`${id}`} />
    </div>
  );
};

const PressedContoller = ({ image, onClick }) => {
  return (
    <div>
      {image ? (
        <CustomImage
          borderRadius={4}
          onClick={onClick}
          src={image}
          style={{
            ...styles.image,
            borderColor: "#0000001A",
            backgroundColor: "#fff",
            filter: "blur(4px)",
          }}
        />
      ) : (
        <div
          className={
            "min-w-[60px] min-h-[45px] max-w-[60px] blur-sm rounded max-h-[45px] bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
          }
        >
          A
        </div>
      )}
    </div>
  );
};
const UserContoller = ({ image, onClickUser, name, lastName, id }) => {
  return (
    <div
      data-tooltip-id={`${id}`}
      data-tooltip-place={"top"}
      data-tooltip-content={`
         ${name.slice(0,3)+"***" + " " + lastName.slice(0,3)+"***"}
    `}
      className={onClickUser && "cursor-pointer"}
      onClick={() => {
        if (onClickUser) {
          onClickUser();
        }
      }}
    >
      {image ? (
        <CustomImage
          src={image}
          style={{
            ...styles.avatar,
            borderColor: "#0000001A",
            backgroundColor: "#fff",
            filter: "blur(4px)",
          }}
        />
      ) : (
        <NoAvatar size={50} name={name} lastName={lastName} />
      )}
    </div>
  );
};

const ArchiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#A3A3A3"
        strokeWidth="0.75"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_1100_1592)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#A3A3A3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1100_1592">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(ActivePurchaseJunction);
