import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, PlusIcon } from "../../assets/icons/stepsIcons";
import { Button, CustomModal } from "../../components";
import { personalService } from "../../services/personal.service";
import { chatService } from "../../services/chat.service";
import { Toast } from "../../utils/toastify/toast";
import Badgets from "../../components/buttons/Badgets";
import { DepartmentCard } from "../../steps/department/components";
import AddDepartment from "../../steps/department/modals/Add-Department";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { useMediaQuery } from "react-responsive";
import { useStore } from "../../hooks/useStores";
const SettingDepartment = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [department, setDepartment] = useState([{}, {}]);
  const [selectedDepartmant, setSelectedDepartmant] = useState(null);
  const [alerts, setAlerts] = useState(false);
  const [loading, setLoading] = useState(false);
  const xsDevice = useMediaQuery({ maxWidth: 575 });

  const GetDepartment = async () => {
    setLoading(true);
    await personalService.getDepartments().then((res) => {
      setDepartment(res.data);
    });
    setLoading(false);
  };

  const onDelete = async (id) => {
    Toast("warning", "Demo user's are not authorized");
    // await personalService
    //   .deleteDepartment(id)
    //   .then((res) => {
    //     if (res?.data?.status == 1) {
    //       GetDepartment();
    //       setIsOpen(false);
    //       setAlerts(false);
    //       Toast("success", t("addDepartment:deletedDepartment"));
    //     } else {
    //       Toast("error", t("product:errors"));
    //     }
    //   })
    //   .catch((err) => {
    //     Toast.error("error", "Errror occured");
    //   });
    // await chatService.convertDepartmentToNull(id);
  };

  useEffect(() => {
    GetDepartment();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center mt-64 justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full h-[82vh] xs:h-[78vh] sm:h-[75vh] md:h-[75vh] flex flex-col gap-5 overflow-hidden">
          <div className="flex xs:flex-col xs:gap-4 items-center min-h-[72px] border-b w-full border-secondary-200">
            <div className="flex flex-col w-full xs:h-[64px] xs:border-b xs:pb-2">
              <p className="text-gray-900 text-lg font-medium dark:text-[#FAFAFA]">
                {t("settingTab:departments")}
              </p>
              <p className="text-gray-500 text-sm dark:text-[#D6D6D6]">
                {t("settingTab:manageYourDepartments")}
              </p>
            </div>
            <div className="ml-auto xs:w-full">
              <Button
                colorType={"secondary-gray"}
                label={t("settingTab:newDepartment")}
                iconLeft={<PlusIcon />}
                size={"md"}
                // onClick={() => setIsOpen(true)}
                onClick={() => Toast("warning", "Demo user's are not authorized.")}
              />
            </div>
          </div>
          <div className="w-fit">
            <Badgets
              size={"md"}
              label={
                department?.length +
                "/" +
                auth?.user?.company?.limits?.department +
                " " +
                t("routes:department")
              }
              colorType={"fill-primary"}
            />
          </div>

          <div className="h-[78%] w-full overflow-y-scroll flex flex-col gap-4 scrollbar-hide">
            {department?.map((e, i) => (
              <DepartmentCard
                key={i}
                // name={e.name}
                name={`${e?.name?.slice(0, 3)} ***`}
                labels={e.labels}
                id={e.id}
                responsibleUser={e.responsibleUser}
                editOnClick={() => {
                   Toast("warning", "Demo user's are not authorized to edit.")
                  // setSelectedDepartmant(e);
                  // setIsOpen(true);
                }}
                deleteOnclick={() => {
                  // setAlerts(true);
                  // setSelectedDepartmant(e);
                  Toast("warning", "Demo user's are not authorized to delete.")
                  // onDelete(e?.id);
                }}
              />
            ))}
          </div>
        </div>
      )}

      {/* {selectedDepartmant ? (
        <CustomModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => {
            setSelectedDepartmant(null);
            setIsOpen(false);
          }}
          children={
            <SettingDepartmentModal
              setIsOpen={setIsOpen}
              selectedDepartmant={selectedDepartmant}
              setSelectedDepartmant={setSelectedDepartmant}
              GetDepartment={GetDepartment}
              onDelete={onDelete}
            />
          }
          modalTitle={
            selectedDepartmant
              ? selectedDepartmant?.name
              : t("department:modalTitle")
          }
        />
      ) : (
        <CustomModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={() => {
            setSelectedDepartmant(null);
            setIsOpen(false);
          }}
          children={
            <AddDepartment
              setIsOpen={setIsOpen}
              selectedDepartmant={selectedDepartmant}
              setSelectedDepartmant={setSelectedDepartmant}
              GetDepartment={GetDepartment}
              onDelete={onDelete}
            />
          }
          modalTitle={
            selectedDepartmant
              ? t("department:updateDepartment")
              : t("department:modalTitle")
          }
        />
      )} */}

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setSelectedDepartmant(null);
          setIsOpen(false);
        }}
        children={
          <AddDepartment
            setIsOpen={setIsOpen}
            selectedDepartmant={selectedDepartmant}
            setSelectedDepartmant={setSelectedDepartmant}
            GetDepartment={GetDepartment}
            onDelete={onDelete}
          />
        }
        modalTitle={
          selectedDepartmant
            ? t("department:updateDepartment")
            : t("department:modalTitle")
        }
      />

      <AlertModal
        title={t("department:deleteDepartment")}
        subTitle={
          t("department:questionDepartment") +
          " " +
          selectedDepartmant?.name +
          " ?"
        }
        isOpen={alerts}
        setIsOpen={setAlerts}
        applyButton={() => {
          onDelete(selectedDepartmant?.id);
          setSelectedDepartmant(null);
          setAlerts(false);
        }}
      />
    </>
  );
};

export default SettingDepartment;
