import { makeAutoObservable } from "mobx";
import { makePersistable, hydrateStore, isHydrated } from "mobx-persist-store";

class SettingsStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "SettingsStore",
      properties: ["theme", "selectedPackage"],
      storage: window.localStorage,
    });

    //detecting system theme
    this.theme = "light";
    // this.detectSystemTheme();
  }

  openLeftMenu = false;
  isActive = false;

  theme = "light";
  currentUser = null;
  currentTab = "machine";
  selectedPackage = "machine";
  search = "";

  // detectSystemTheme() {
  //   const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   const updateTheme = (e) => {
  //     this.setTheme(e.matches ? "dark" : "light");
  //   };
  //   darkModeQuery.addEventListener("change", updateTheme);
  //   updateTheme(darkModeQuery);
  // }

  setCurrentTab = (tabName) => {
    this.currentTab = tabName;
  };

  setOpenLeftMenu = (boolean) => {
    this.openLeftMenu = boolean;
  };

  setIsActive = (boolean) => {
    this.isActive = boolean;
  };

  setSelectedPackage = (packageName) => {
    this.selectedPackage = packageName;
  };

  setTheme = (type) => {
    // this.theme = type;
    this.theme = type;
  };

  setCurrentUser = (user) => {
    this.currentUser = user;
  };

  setSearchValue = (search) => {
    this.search = search;
  };
}
const settingsStore = new SettingsStore();
export { settingsStore };
