import React, { useEffect, useRef } from "react";
import { MapCheckIcon } from "../../../../assets/icons/stepsIcons";

const AreaModal = ({
  selectedMap,
  setSelectedMap,
  maps,
  setAreaView,
  setCurrentMap,
}) => {
  const menuRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setAreaView(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      className="absolute flex flex-col py-1  top-[56px] right-[55px] border-[1px] border-secondary-200 shadow-lg bg-white rounded-lg cursor-pointer"
    >
      {maps?.map((map, i) => (
        <div
          onClick={() => {
            setCurrentMap(map?.mapSettings, i);
          }}
          className="px-[6px] py-[1px] w-full flex-row items-center"
        >
          <div
            className={`flex flex-row items-center gap-2 px-2 py-[10px] min-w-[144px] min-h-[44px] cursor-pointer  ${
              selectedMap === i ? "rounded-md bg-secondary-50 " : "bg-white"
            }`}
          >
            <p className="text-secondary-900 text-md font-medium">
              {map?.name}
            </p>

            <div className="w-5 h-5 flex items-center ml-auto">
              <MapCheckIcon color={"#7F56D9"} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AreaModal;
