import React from "react";
import moment from "moment";

const EventRect = ({
  event,
  handleMouseOver,
  handleMouseOut,
  index,
  startPos,
  width,
  widthS,
  totalWidth,
}) => {
  return (
    <svg width={totalWidth} height={140}>
      {event.index == 0 ? (
        <>
          <rect
            key={index}
            x={startPos}
            y="94"
            width={width}
            rx={"4"}
            height="32"
            fill={event?.type !== "work" ? "orange" : "#17B26A"}
            onMouseOver={() => handleMouseOver(event, startPos)}
            onMouseOut={handleMouseOut}
            style={{
              cursor: "pointer",
            }}
          />
          {event.timeDiff > 2500 && (
            <>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos + 15}
                y="20"
                font-family="Verdana"
                font-size="10"
                fill="#FFF"
                onMouseOver={() => handleMouseOver(event, startPos)}
                onMouseOut={handleMouseOut}
              >
                {moment(event.starts).format("HH:mm")}
              </text>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos}
                y="140"
                font-family="Verdana"
                font-size="10"
                fill="#black"
                onMouseOver={() => handleMouseOver(event, startPos)}
                onMouseOut={handleMouseOut}
              >
                {moment(event.starts).format("HH:mm")}
              </text>
            </>
          )}
        </>
      ) : event.index == 1 ? (
        //status
        <g>
          <rect
            key={index}
            x={startPos}
            y="30"
            width={widthS}
            height="60"
            fill={event?.color == "#FFFFFF" ? "#F9FAFB" : event?.color}
            onMouseOver={() => handleMouseOver(event, startPos)}
            onMouseOut={handleMouseOut}
            style={{ cursor: "pointer" }}
            rx={"2"}
          />
        </g>
      ) : (
        //shiftt
        <g className="relative">
          <rect
            key={index}
            x={startPos}
            y="6"
            width={width}
            height="20"
            fill={event?.color == "#FFFFFF" ? "#F2F4F7" : event?.color}
            onMouseOver={() => handleMouseOver(event, startPos)}
            onMouseOut={handleMouseOut}
            style={{ cursor: "pointer" }}
            rx={"2"}
          ></rect>

          {event.timeDiff > 2500 && (
            <>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos + 15}
                y="20"
                font-family="Verdana"
                font-size="10"
                fill="#FFF"
                onMouseOver={() => handleMouseOver(event, startPos)}
                onMouseOut={handleMouseOut}
              >
                {moment(event.starts).format("HH:mm")}
              </text>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos}
                y="140"
                font-family="Verdana"
                font-size="10"
                fill="#black"
                onMouseOver={() => handleMouseOver(event, startPos)}
                onMouseOut={handleMouseOut}
              >
                {moment(event.starts).format("HH:mm")}
              </text>
            </>
          )}
        </g>
      )}
    </svg>
  );
};

export default EventRect;
