import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { notificationService } from "../../../services/notification.service";
import { useQuery } from "@tanstack/react-query";
import NotificationsArea from "../components/NotificationsArea";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import NoData from "../../../steps/step-navigation/components/NoData";
import NotificationWhoSee from "../components/NotificationWhoSee";
import NotificationContent from "../components/NotificationContent";
import { Button, CustomModal, Loading } from "../../../components";
import { PlusIcon } from "../../../assets/icons/PersonIcons";
import NewNotifyModal from "../modals/NewNotifyModal";
import { Toast } from "../../../utils/toastify/toast";

export default function Notify({}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isNewNotifyModalOpen, setIsNewNotifyModalOpen] = useState(false);
  const navigate = useNavigate();

  const {
    isLoading: notifyLoading,
    data: notifyData,
    refetch: notifyRefetch,
  } = useQuery({
    retry: 0,
    queryKey: ["getNotification"],
    queryFn: async () => await notificationService.getNotify(),
  });

  const memoizedNotificationsArea = useMemo(
    () => (
      <NotificationsArea
        notification={notifyData}
        t={t}
        loading={notifyLoading}
        refresh={notifyRefetch}
      />
    ),
    [notifyData]
  );

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["findOneNotify", id],
    queryFn: async () => {
      if (id) {
        return await notificationService.getNotificationDetail(id);
      }
    },
  });

  useEffect(() => {
    navigate(notifyData?.data?.[0]?._id);
  }, [notifyData?.data]);

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <>
      <div className="flex flex-row items-center w-full mb-4 mt-1  border-b">
        <span className="font-codecMedium text-2xl text-black">upu.</span>
        <p className="text-2xl font-codecMedium text-teal-700">notify</p>
        <div className="ml-auto w-fit mb-3">
          <div className="flex items-center">
            <Button
              label={t("buttons:newNotification")}
              iconLeft={<PlusIcon />}
              colorType={"primary-person"}
              size={"md"}
              // onClick={() => setIsNewNotifyModalOpen(true)}
              onClick={() => Toast("warning", t("alert:warning"))}
            />
          </div>
        </div>
      </div>
      {notifyLoading ? (
        <div className="flex w-full h-full items-center justify-center">
          <Loading primary={"#15B79E"} secondary={"#fff"} size={32} />
        </div>
      ) : (
        <div className="w-full h-full flex border border-secondary-300 shadow-md bg-white rounded-xl">
          {isLoading ? (
            <div className="flex min-w-[300px] max-w-[300px] justify-center items-center">
              <Loading primary={"#15B79E"} secondary={"#fff"} size={32} />
            </div>
          ) : (
            <NotificationWhoSee t={t} data={data} isLoading={isLoading} />
          )}
          {memoizedNotificationsArea}
          {isLoading ? (
            <div className="flex max-w-[375px] min-w-[375px] justify-center items-center">
              <Loading primary={"#15B79E"} secondary={"#fff"} size={32} />
            </div>
          ) : (
            <NotificationContent t={t} data={data} isLoading={isLoading} />
          )}
        </div>
      )}
      <CustomModal
        isOpen={isNewNotifyModalOpen}
        modalTitle={t("buttons:newNotification")}
        setIsOpen={setIsNewNotifyModalOpen}
        children={
          <NewNotifyModal
            notifyRefetch={notifyRefetch}
            setIsNewNotifyModalOpen={setIsNewNotifyModalOpen}
          />
        }
      />
    </>
  );
}
