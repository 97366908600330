import React from "react";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";

export const SingleEventRect = ({
  event,

  index,
  startPos,
  width,

  totalWidth,
  onClick,
}) => {
  return (
    <svg width={totalWidth} height={24}>
      <g className="relative">
        <rect
          onClick={onClick}
          key={index}
          x={startPos}
          y={0}
          width={width}
          height="24"
          fill={event?.color}
          // onMouseOver={() => handleMouseOver(event, startPos)}
          // onMouseOut={handleMouseOut}
          style={{ cursor: "pointer" }}
          ry={"4"}
        ></rect>
        <>
          {event?.timeDiff > 3000 && event?.timeDiff < 5200 ? (
            <>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos + 2}
                y="16"
                fontFamily="Inter"
                fontWeight={500}
                font-size="12"
                fill="#FFF"
                // onMouseOver={() => handleMouseOver(event, startPos)}
                // onMouseOut={handleMouseOut}
              >
                {moment(timeFormatter(event?.startDate)?.formatted)?.format(
                  "HH:mm"
                )}
              </text>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos + width - 36}
                y="16"
                fontFamily="Inter"
                fontWeight={500}
                font-size="12"
                fill="#FFF"
                // onMouseOver={() => handleMouseOver(event, startPos)}
                // onMouseOut={handleMouseOut}
              >
                {moment(timeFormatter(event?.endDate)?.formatted)?.format(
                  "HH:mm"
                )}
              </text>
            </>
          ) : (
            event.timeDiff > 3000 && (
              <>
                <text
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + 8}
                  y="16"
                  fontFamily="Inter"
                  fontWeight={600}
                  font-size="12"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event.startDate)?.formatted).format(
                    "HH:mm"
                  )}
                </text>
                <text
                  style={{
                    cursor: "pointer",
                  }}
                  x={startPos + width - 40}
                  y="16"
                  fontFamily="Inter"
                  fontWeight={600}
                  font-size="12"
                  fill="#FFF"
                  // onMouseOver={() => handleMouseOver(event, startPos)}
                  // onMouseOut={handleMouseOut}
                >
                  {moment(timeFormatter(event?.endDate)?.formatted).format(
                    "HH:mm"
                  )}
                </text>
              </>
            )
          )}
        </>
      </g>
    </svg>
  );
};

export const SingleEventRect2 = ({
  event,
  y,
  handleMouseOver,
  handleMouseOut,
  index,
  startPos,
  width,
  widthS,
  totalWidth,
  selectedEnd,
  selectedStart,
  setTotalTime,
  setSelectedEnd,
  onClick,
}) => {
  return (
    <svg width={totalWidth} height={30}>
      <g className="relative">
        <rect
          onClick={onClick}
          key={index}
          x={startPos}
          y={1000}
          width={width}
          height="5"
          fill={event?.color == "#FFFFFF" ? "#F2F4F7" : event?.color}
          // onMouseOver={() => handleMouseOver(event, startPos)}
          // onMouseOut={handleMouseOut}
          style={{ cursor: "pointer" }}
          ry={"4"}
        ></rect>

        <>
          <text
            style={{
              cursor: "pointer",
            }}
            x={startPos}
            y="16"
            fontFamily="Inter"
            fontWeight={500}
            font-size="12"
            fill="black"
            // onMouseOver={() => handleMouseOver(event, startPos)}
            // onMouseOut={handleMouseOut}
          >
            {moment(timeFormatter(event.startDate).formatted).format("HH:mm")}
          </text>
          {/* <text
              style={{
                cursor: "pointer",
              }}
              x={startPos}
              y="40"
              fontFamily="Inter"
              fontWeight={500}
              font-size="12"
              fill="#black"
              onMouseOver={() => handleMouseOver(event, startPos)}
              onMouseOut={handleMouseOut}
            >
              {moment(event.starts).format("HH:mm")}
            </text> */}
        </>
      </g>
    </svg>
  );
};

export default { SingleEventRect, SingleEventRect2 };
