import React, { memo } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { useTranslation } from "react-i18next";
import ReactECharts from "echarts-for-react";

const MultipleBarChart = ({ series, categories, charts, handleData }) => {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const onChartClick = (params) => {
    if (params?.name) {
      handleData(params?.name);
    }
  };

  const onEvents = {
    click: onChartClick,
    legendselectchanged: function (params) {},
  };

  const monthSelected = (month) => {
    switch (month) {
      case "1":
        return `${t("legends:january")}`;
      case "2":
        return `${t("legends:february")}`;
      case "3":
        return `${t("legends:march")}`;
      case "4":
        return `${t("legends:april")}`;
      case "5":
        return `${t("legends:may")}`;
      case "6":
        return `${t("legends:june")}`;
      case "7":
        return `${t("legends:july")}`;
      case "8":
        return `${t("legends:august")}`;
      case "9":
        return `${t("legends:september")}`;
      case "10":
        return `${t("legends:october")}`;
      case "11":
        return `${t("legends:november")}`;
      case "12":
        return `${t("legends:december")}`;
    }
  };

  const barColors =
    charts == "c-count"
      ? ["#5470C6", "#91CC75"]
      : charts == "energy"
      ? ["#91CC75", "#5470C6", "#FAC858", "#E66"]
      : ["#91CC75", "#FAC858", "#5470C6"];

  const option = React.useMemo(
    () => ({
      center: ["50%", "10%"],
      color: barColors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          formatter: function (params) {
            return `${params[0]?.name}: ${params[0]?.value}`;
          },
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: categories,
          axisLabel: {
            rotate: 35,
            formatter: function (value) {
              return monthSelected(value);
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: series,
    }),
    [barColors, categories, monthSelected, series]
  );

  return (
    <div>
      <div className="w-full h-full min-h-full flex items-center justify-center overflow-hidden relative">
        <ReactECharts
          onEvents={onEvents}
          option={option}
          style={{
            height: "100%",
            minHeight:
              charts == "energy"
                ? (height - 250) / 2
                : charts === "c-count"
                ? (height - 250) / 2
                : (height - 250) / 2,
            width: "100%",
            top: charts == "energy" ? -34 : charts === "c-count" ? -20 : -48,
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(MultipleBarChart);
