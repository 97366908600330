import React from "react";

const TaskLabelsSide = ({ labels, editMode }) => {
  if (!editMode) {
    return (
      <>
        <div className="w-full flex items-center overflow-x-auto scrollbar-hide gap-x-2">
          {labels?.map((item, index) => {
            return (
              <div
                key={index}
                className="h-[22px] w-fit px-2 rounded-md flex items-center justify-center"
                style={{ background: item?.color }}
              >
                <p className="truncate text-xs leading-4 font-medium text-white">
                  {item?.name}
                </p>
              </div>
            );
          })}
        </div>
      </>
    );
  }
};

export default TaskLabelsSide;
