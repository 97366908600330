import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { useTranslation } from "react-i18next";
import { searchInArray } from "../../../utils/arrayUtils";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button } from "../../../components";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { SensorSettingsIcon } from "../../../assets/icons/machineIcons";
import NoData from "../../../steps/step-navigation/components/NoData";
import { QrIcon } from "../../../assets/icons/equipmentIcon";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Toast } from "../../../utils/toastify/toast";

const EquipmentTable = ({ processName, onEdit, tableBodyItems, qrCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const pattern = /^(.*?)-(.+)$/;
  const match = deviceId?.match(pattern);
  const [equipmentCounter, setEquipmentCounter] = useState(0);
  const [searchVal, setSearchVal] = useState(null);
  const [nodes, setNodes] = useState(tableBodyItems);
  const [tempNodes, setTempNodes] = useState(tableBodyItems);
  const [openEmbeddedServer, setOpenEmbeddedServer] = useState(false);
  const [embeddedServerNodeId, setEmbeddedServerNodeId] = useState(null);

  const getWidthClass = (i, screenSize, isHead = false) => {
    const defaultWidths = {
      xs: [
        "92px",
        "294px",
        "295px",
        "87px",
        "87px",
        "86px",
        "98px",
        "97px",
        "90px",
      ],
      sm: [
        "92px",
        "294px",
        "295px",
        "87px",
        "87px",
        "86px",
        "98px",
        "97px",
        "90px",
      ],
      md: [
        "91px",
        "294px",
        "295px",
        "87px",
        "87px",
        "86px",
        "98px",
        "97px",
        "90px",
      ],
    };

    const defaultWidth =
      i === 1 || i === 2 ? "19%" : i === 3 || i === 4 ? "11%" : "8%";

    if (isHead) {
      return defaultWidth;
    }

    return defaultWidths[screenSize][i] || defaultWidth;
  };

  const tableHeadItems = [
    { name: t("table:image"), value: "image", type: "" },
    {
      name: t("addProcess:equipmentName"),
      value: "machineName",
      type: "string",
    },
    {
      name: t("addEquipment:equipmentModel"),
      value: "machineModal",
      type: "string",
    },
    {
      name: "Node ID",
      value: "deviceId",
      type: "number",
    },
    {
      name: t("addEquipment:serialNo"),
      value: "orderNumber",
      type: "string",
    },
    {
      name: t("table:score"),
      value: "puantaj",
      type: "number",
    },
    {
      name: t("addEquipment:qrCode"),
      value: "image",
      type: "",
    },
    {
      name: t("tasks:edit"),
      value: "edit",
      type: "string",
    },
    {
      name: t("loss:sensorSetting"),
      value: "sensor",
      type: "",
    },
  ];

  const handleEmbeddedServer = (nodeId) => {
    if (nodes) {
      setOpenEmbeddedServer(!openEmbeddedServer);
      setEmbeddedServerNodeId(nodeId);
    }
  };

  useEffect(() => {
    if (searchVal) {
      const filteredNodes = [
        searchInArray(searchVal, tempNodes, "machineName"),
      ];
      filteredNodes.push(searchInArray(searchVal, tempNodes, "machineModal"));
      setNodes(filteredNodes.flat());
      setEquipmentCounter(filteredNodes.flat().length);
    } else {
      setNodes(tempNodes);
      setEquipmentCounter(tableBodyItems?.length);
    }
  }, [searchVal, setSearchVal, tempNodes]);

  return (
    <>
      {tableBodyItems?.length > 0 || processName ? (
        <div className="w-full flex flex-1 flex-col">
          <div className="h-20 w-full border border-secondary-200 rounded-t-xl px-6 py-5 flex items-center flex-row gap-4">
            {processName ? (
              <div className="flex flex-row gap-2 items-center">
                <p className="text-lg font-semibold text-secondary-900">
                  {processName}
                </p>
                <div className="w-[33px] h-[22px] rounded-md py-[2px] px-[6px] border flex items-center justify-center border-primary-200 bg-primary-50">
                  <p className="text-xs font-medium text-primary-700">
                    {equipmentCounter}
                  </p>
                </div>
              </div>
            ) : (
              <>
                <p className="text-secondary-900 font-medium text-lg">
                  {t("addEquipment:definedSub")}
                </p>
              </>
            )}
            <div className="ml-auto w-[25%]">
              <SearchInput
                setSearchVal={setSearchVal}
                placeholder={t("chat:search")}
              />
            </div>
          </div>
          <div className="w-full overflow-y-scroll scrollbar-hide">
            <div className="min-w-full divide-y divide-secondary-200 dark:divide-[#202020] dark:border-[#292929]  border border-t-0 border-secondary-200 h-fit rounded-b-lg">
              <div className="bg-secondary-50 md:w-[1232px] sm:w-[1232px] xs:w-[1232px] h-[44px] w-full dark:bg-[#202020] md:overflow-x-scroll">
                <div className="flex h-full">
                  {tableHeadItems?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        style={{ width: getWidthClass(i, "md", true) }}
                        className={`h-full flex md:w-[1232px] sm:w-[1232px] xs:w-[1232px] ${
                          i === 8 ? "border-r-0" : "border-r"
                        } ${
                          i === 0 ||
                          i === 3 ||
                          i === 4 ||
                          i === 5 ||
                          i === 6 ||
                          i === 7 ||
                          i === 8
                            ? "items-center justify-center"
                            : "items-center"
                        } px-4 py-3 ${
                          i === 0 || i === 5 || i === 6 || i === 7 || i === 8
                            ? "w-[8%] md:w-[92px] sm:w-[92px] xs:w-[92px]"
                            : i === 1 || i === 2
                            ? "w-[19%] md:w-[304px] sm:w-[304px] xs:w-[304px]"
                            : "w-[11%] md:w-[88px] sm:w-[88px] xs:w-[88px]"
                        }`}
                      >
                        <span className="text-xs font-medium text-secondary-600">
                          {item?.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="divide-y w-full md:w-[1232px] sm:w-[1232px] xs:w-[1232px] divide-secondary-200 bg-[#FFF] dark:bg-[#141414] dark:divide-[#202020] overflow-y-scroll h-[66vh] rounded-b-xl scrollbar-hide">
                {nodes?.map((equ, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between "
                  >
                    <div className="py-4 px-4 overflow-hidden flex items-center justify-center border-r h-[72px] w-[8%] md:w-[91px] sm:w-[93px] xs:w-[93px]">
                      <div className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] rounded border border-secondary-200 overflow-hidden blur-sm">
                        <img src={generateFileUrl(equ?.metadata?.image)} />
                      </div>
                    </div>
                    <div className="py-4 px-4 whitespace-nowrap overflow-ellipsis flex items-center h-[72px] w-[19%] md:w-[303px] sm:w-[303px] xs:w-[303px] border-r overflow-hidden ">
                      <span className="text-sm font-normal  text-secondary-600 ">
                        {/* {equ?.machineName} */}
                        {equ?.machineName.slice(0, 2)}***
                      </span>
                    </div>

                    <div className="py-4 px-4 whitespace-nowrap overflow-ellipsis flex items-center h-[72px] w-[19%] md:w-[303px] sm:w-[303px] xs:w-[303px] border-r overflow-hidden ">
                      <span className="text-sm font-normal  text-secondary-600 ">
                        {equ?.machineModal.slice(0, 2)}***
                      </span>
                    </div>
                    <div className="whitespace-nowrap overflow-ellipsis overflow-hidden py-4 px-4 h-[72px] w-[11%] md:w-[88px] sm:w-[88px] xs:w-[88px] border-r flex items-center justify-center">
                      <span className="text-center text-sm font-normal  text-secondary-600">
                        {equ?.deviceId.slice(0, 2)}***
                      </span>
                    </div>

                    <div className="whitespace-nowrap overflow-ellipsis overflow-hidden py-4 px-4 h-[72px] w-[11%] md:w-[88px] sm:w-[88px] xs:w-[88px] border-r flex items-center justify-center">
                      <span className="text-center text-sm font-normal  text-secondary-600">
                        {equ?.metadata?.orderNumber.slice(0, 2)}***
                      </span>
                    </div>

                    <div className="whitespace-nowrap overflow-ellipsis overflow-hidden py-4 px-4 h-[72px] w-[8%] md:w-[92px] sm:w-[92px] xs:w-[92px] border-r flex items-center justify-center">
                      <span className="text-center text-sm font-normal  text-secondary-600">
                        {equ?.puantaj}
                      </span>
                    </div>

                    <div className="whitespace-nowrap  blur-sm overflow-ellipsis overflow-hidden py-4 text-center px-4 h-[72px] flex items-center justify-center  w-[8%] md:w-[92px] sm:w-[92px] xs:w-[92px] border-r">
                      <Button
                        iconLeft={<QrIcon size={20} />}
                        colorType={"tertiary-gray"}
                        size={"md"}
                        // onClick={() => qrCode(equ)}
                        onClick={() => Toast("warning", "Demo user's are not authorized.")}
                      />
                    </div>

                    <div className="whitespace-nowrap blur-sm overflow-ellipsis overflow-hidden py-4 text-center px-4 h-[72px] flex items-center justify-center  w-[8%] md:w-[92px] sm:w-[92px] xs:w-[92px] border-r">
                      <Button
                        iconLeft={<EditIcon />}
                        colorType={"tertiary-gray"}
                        size={"md"}
                        // onClick={() => onEdit(equ)}
                        onClick={() => Toast("warning", "Demo user's are not authorized.")}
                      />
                    </div>
                    <div className="whitespace-nowrap blur-sm overflow-ellipsis overflow-hidden py-4 text-center px-4 flex items-center justify-center h-[72px] w-[8%] md:w-[92px] sm:w-[92px] xs:w-[92px]">
                      <Button
                        // onClick={() => {
                        //   handleEmbeddedServer(`${equ?.deviceId}-${equ?.id}`);
                        //   navigate(
                        //     `/app/machine/digital-twin/embedded-server/${equ?.deviceId}-${equ?.id}`
                        //   );
                        // }}
                        onClick={() => Toast("warning", "Demo user's are not authorized.")}
                        // onClick={async () => {
                        //   try {
                        //     if (!match || match.length < 2) {
                        //       throw new Error("Invalid match structure");
                        //     }
                        //     console.log("Match:", match);
                        //     console.log("Device ID:", deviceId);
                        //     const res = await embeddedServerService.stateEmbeddedServer(match ? match[1]: deviceId);
                        //     if (res?.code === 1) {
                        //       Toast("error", res?.message);
                        //     } else if (res?.code === 2) {
                        //       Toast("error", t("equipments:noLimitEmbeddedServer"));
                        //     } else {
                        //       navigate(`/app/machine/digital-twin/embedded-server/${match[0]}`);
                        //     }
                        //   } catch (error) {
                        //     console.error("Error in onClick:", error);
                        //     // Handle the error or provide a default behavior.
                        //   }
                        // }}

                        iconLeft={<SensorSettingsIcon />}
                        colorType={"tertiary-gray"}
                        size={"md"}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center w-full h-[66vh]">
            <div className="w-full h-full flex items-center justify-center ">
              <NoData
                theme={"machine"}
                header={t("label:noData")}
                button={false}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EquipmentTable;
