import React from "react";
export default function Tab({ selectedTab, setSelectedTab, options }) {
  return (
    <div className="h-[52px] min-h-[52px] max-h-[52px] w-full bg-[#F9FAFB] border border-[#EAECF0] p-[6px] rounded-xl flex items-center justify-center mt-6 mb-4">
      {options?.map((option, index) => (
        <div
          className={`w-1/2 h-full rounded-md px-3 py-2  flex items-center justify-center cursor-pointer ${
            selectedTab == option?.value ? "bg-white shadow-sm" : ""
          }`}
          onClick={() => setSelectedTab(option?.value)}
        >
          <p
            className={`text-md font-semibold ${
              selectedTab == option?.value ? "text-[#344054]" : "text-[#667085]"
            }`}
          >
            {option?.label}
          </p>
        </div>
      ))}
    </div>
  );
}
