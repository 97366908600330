import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { DepartmentCard } from "./components";
import StepNoData from "../step-navigation/components/StepNoData";
import { useTranslation } from "react-i18next";
import StepsMainSide from "../../components/steps/StepsMainSide";
import { CustomModal } from "../../components";
import AddDepartment from "./modals/Add-Department";
import { personalService } from "../../services/personal.service";
import { chatService } from "../../services/chat.service";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { Toast } from "../../utils/toastify/toast";
import { concatArrays } from "../../utils/arrayUtils";
import { Loader } from "../../assets/icons/stepsIcons";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStores";
import diacritics from "diacritics";

const Department = observer(() => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [department, setDepartment] = useState([{}, {}]);
  const [selectedDepartmant, setSelectedDepartmant] = useState(null);
  const [alerts, setAlerts] = useState(false);
  const [searchDepartment, setSearchDepartment] = useState(null);
  const [tempDepartment, setTempDepartment] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: "departments",
    queryFn: async () => await personalService.getDepartments(),
    retry: 0,
  });

  const GetDepartment = async () => {
    setLoading(true);
    await setDepartment(data?.data);
    await setTempDepartment(data?.data);
    setLoading(false);
  };

  const onDelete = async (id) => {
    await personalService
      .deleteDepartment(id)
      .then((res) => {
        if (res?.data?.status == 1) {
          refetch();
          setIsOpen(false);
          setAlerts(false);
          Toast("success", t("addDepartment:deletedDepartment"));
        } else {
          Toast("error", t("product:errors"));
        }
      })
      .catch((err) => {
        Toast.error("error", "Errror occured");
      });
    await chatService.convertDepartmentToNull(id);
  };

  useEffect(() => {
    GetDepartment();
  }, [data]);

  useEffect(() => {
    if (searchDepartment) {
      const filterDataDepartment = tempDepartment.filter((e) => {
        let name = `${diacritics.remove(e.name)}`;
        return name
          .replace(/^\./g, "")
          .toLowerCase()
          .includes(searchDepartment.toLowerCase());
      });
      let arr = [];
      const response = concatArrays(arr, filterDataDepartment);
      setDepartment(response);
    } else {
      setDepartment(tempDepartment);
    }
  }, [searchDepartment, setSearchDepartment, tempDepartment]);
  return (
    <>
      {department ? (
        <StepsMainSide
          t={t}
          setIsOpen={setIsOpen}
          setSearchVal={setSearchDepartment}
          placeholder={t("department:searchDepartment")}
          title={t("routes:department")}
          length={
            department?.length +
            "/" +
            auth?.user?.company?.limits.department +
            " " +
            t("routes:department")
          }
          isLimitReached={
            department?.length >= auth?.department?.company?.limits.user
          }
          limitlabel={t("buttons:buyDepartmentCredits")}
          addlabel={t("department:modalTitle")}
          children={
            <>
              {loading ? (
                <div className="flex items-center mt-[120px] justify-center w-full h-full">
                  <Loader
                    currentColor="currentColor"
                    currentFill="currentFill"
                  />
                </div>
              ) : (
                <>
                  {department.map((e, i) => (
                    <DepartmentCard
                      key={i}
                      name={e.name}
                      labels={e.labels}
                      id={e.id}
                      responsibleUser={e.responsibleUser}
                      editOnClick={() => {
                        setSelectedDepartmant(e);
                        setIsOpen(true);
                      }}
                      deleteOnclick={() => {
                        setAlerts(true);
                        setSelectedDepartmant(e);
                        // onDelete(e?.id);
                      }}
                    />
                  ))}
                </>
              )}
            </>
          }
        />
      ) : (
        <StepNoData
          header={t("department:title")}
          text={t("department:subsTitle")}
          // onClick={() => setIsOpen(true)}
          onClick={() => Toast("warning", t("alert:warning"))}
        />
      )}

      <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setSelectedDepartmant(null);
          setIsOpen(false);
        }}
        children={
          <AddDepartment
            setIsOpen={setIsOpen}
            selectedDepartmant={selectedDepartmant}
            setSelectedDepartmant={setSelectedDepartmant}
            GetDepartment={refetch}
            onDelete={onDelete}
          />
        }
        modalTitle={
          selectedDepartmant
            ? t("department:updateDepartment")
            : t("department:modalTitle")
        }
      />
      <AlertModal
        title={t("department:deleteDepartment")}
        subTitle={t("department:questionDepartment")}
        isOpen={alerts}
        setIsOpen={setAlerts}
        applyButton={() => {
          onDelete(selectedDepartmant?.id);
          setSelectedDepartmant(null);
          setAlerts(false);
        }}
      />
    </>
  );
});

export default Department;
