import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class ConsoleStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ChatStore",
      properties: [""],
      storage: window.localStorage,
    });
  }

  consoleData = {};
  isModalOpen = false;
  multilineSelectedProcess = null;
  equipment = "";

  triggerModal() {
    this.isModalOpen = !this.isModalOpen;
  }
  concoleLoading = false;
  setConsoleLoading(boolean) {
    this.concoleLoading = boolean;
  }
  getDataFunction = {};
  setGetData(object) {
    this.getDataFunction = object;
  }

  removeConsoleElement(id) {
    this.consoleData = {};
  }

  selectConsoleElement(equipemnt) {
    this.consoleData = equipemnt;
    // this.triggerModal();
  }

  updateConsoleItem = (item) => {
    this.consoleData.ring = item.ring;
    this.consoleData.status = item.status;
  };

  updateConsoleItemStatus = (status) => {
    this.consoleData.metadata.warning = status;
  };

  setMultilineSelectedProcess = (id) => {
    this.multilineSelectedProcess = id;
  };

  setEquipmentDetail = (data) => {
    this.equipment = data;
  };

  setOperationDetail = (data) => {
    this.operation = data;
  };
}

const consoleStore = new ConsoleStore();

export { consoleStore };
