import React from "react";
import { DeleteIcon, EditIcon } from "../../../assets/icons/departmentIcon";
import { Button } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";

const UserCard = ({ name, editOnClick, deleteOnclick, avatar, department }) => {
  const {t} = useTranslation();
  return (
    <div className="w-full bg-white min-h-[64px] max-h-[64px]  border border-l-[8px] dark:bg-[#141414] dark:border-[#424242] border-gray-300 shadow-xs rounded-lg flex items-center justify-between px-3">
      <div className="flex items-center gap-3">
        {avatar ? (
          <img
            className={`min-w-[40px] blur-sm max-w-[40px] min-h-[40px] max-h-[40px] rounded-full flex items-center justify-center`}
            src={generateFileUrl(avatar)}
          />
        ) : (
          <div
            className={
              "min-w-[40px] max-w-[40px] blur-sm rounded-full bg-primary-700 flex border-4 border-primary-400 items-center justify-center font-medium text-white text-xl"
            }
          >
            {name?.charAt(0).toUpperCase()}
          </div>
        )}
        <div>
          <p className="text-sm font-semibold text-[#101828] dark:text-[#D6D6D6]">
            {name || "No Name"}
          </p>
          <p className="text-sm font-normal text-[#667085] dark:text-[#D6D6D6]">
            {department || "No Name"}
          </p>
        </div>
      </div>
      <div className="flex flex-row gap-4 blur-sm items-center justify-center">
        <Button
          iconLeft={<EditIcon />}
          colorType={"tertiary-gray"}
          size={"sm"}
          // onClick={editOnClick}
          onClick={() => Toast("warning", t("alert:warning"))}
        />
        <Button
          iconLeft={<DeleteIcon />}
          colorType={"tertiary-error"}
          size={"sm"}
          // onClick={deleteOnclick}
          onClick={() => Toast("warning", t("alert:warning"))}
        />
      </div>
    </div>
  );
};

export default UserCard;
