import React, { useEffect, useState } from "react";
import { ArrowRight } from "../../assets/icons/equipmentIcon";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { companyService } from "../../services/company.service";
import { authStore } from "../../stores/auth.store";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Input, TimeInput, UploadProfile } from "../../components";
import { Toast } from "../../utils/toastify/toast";
import moment from "moment";
import Selector from "../../components/inputs/Selector";
import Skeleton from "react-loading-skeleton";
import HourTimeInput from "../../components/inputs/HourTimeInput";
import { useStore } from "../../hooks/useStores";

const SettingsCompany = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [startTime, setStartTime] = useState();

  const { isLoading, data } = useQuery({
    queryKey: ["settingsCompany"],
    queryFn: async () => await companyService.activeCompany(),
    retry: 0,
  });

  const schema = yup.object({
    image: yup.string(),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    address: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    email: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
    phone: yup
      .number()
      .notRequired()
      .typeError(`${t("validation:number")}`),
    fiscalYearStart: yup.date().required(`${t("validation:empty")}`),
    contactName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactEmail: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    contactPhone: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    taxOffice: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    taxNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    country: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    timeZone: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    nodeLimit: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    userLimit: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    t1Standard: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t1: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t2: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    t3: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      image: "",
      name: "",
      address: "",
      email: "",
      phone: "",
      country: "",
      timeZone: "",
      userLimit: "",
      nodeLimit: "",
      contactPhone: "",
      contactEmail: "",
      contactName: "",
      fiscalYearStart: "",
      taxOffice: "",
      taxNo: "",
      t1Standard: "",
      t1: "",
      t2: "",
      t3: "",
    },
  });

  //handling inputs
  const handleCompany = async () => {
    const { data } = await companyService.activeCompany();
    setValue("image", data.image);
    setValue("name", data.name);
    setValue("address", data.address);
    setValue("email", data.email);
    setValue("phone", data.phone);
    setValue("fiscalYearStart", data.fiscalYearStart);
    setValue("contactName", data.contactName);
    setValue("contactEmail", data.contactEmail);
    setValue("contactPhone", data.contactPhone);
    setValue("country", data.country);
    setValue("timeZone", data.timeZone);
    setValue("nodeLimit", data.nodeLimit);
    setValue("userLimit", data.userLimit);
    setValue("taxOffice", data.taxOffice);
    setValue("taxNo", data.taxNo);
    setValue("t1Standard", data.feeCoefficients.t1Standard);
    setValue("t1", data.feeCoefficients.t1);
    setValue("t2", data.feeCoefficients.t2);
    setValue("t3", data.feeCoefficients.t3);
    setStartTime(data?.dayStartHour);
  };

  const onSubmit = async (data) => {
    Toast("warning", "Demo user not authorized. ");
    // await companyService
    //   .updateCompany({
    //     ...data,
    //     dayStartHour: `${startTime}`,
    //   })
    //   .then((res) => {
    //     if (res?.data?.code === 1) {
    //       auth.setCompany(res?.data?.data);
    //       navigate("../department");
    //     } else {
    //       alert.error("Hata oluştu");
    //     }
    //   });
  };

  useEffect(() => {
    handleCompany();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex xs:flex-col xs:h-full"
    >
      <div
        className={`flex flex-col sm:hidden md:hidden items-start relative h-[82vh] xs:h-[70vh] xs:max-h-[70vh]  overflow-y-auto w-full scrollbar-hide`}
      >
        <div className="flex justify-between items-center h-[72px] xs:pb-2 border-b w-full border-secondary-200">
          <div className="flex flex-col w-1/2 xs:w-full">
            <div className="flex flex-row items-center justify-between">
              <p className="text-secondary-900 text-lg font-medium dark:text-[#FAFAFA]">
                {t("settingTab:company")}
              </p>
            </div>
            <p className="text-sm text-secondary-500 font-normal dark:text-[#D6D6D6]">
              {t("settingTab:updateYourCompany")}
            </p>
          </div>
          <div className="w-1/2 flex flex-row items-center gap-3 justify-end ">
            <div className="w-[30%] xs:w-full sm:w-[65%] md:w-1/2 lg:w-1/2 xl:w-[35%] xs:hidden">
              <Button
                size={"lg"}
                colorType={"primary-machine"}
                label={t("buttons:saveChanges")}
                // type={"submit"}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
        <form
          // onSubmit={handleSubmit(onSubmit)}
          className=" max-h-[590px] w-full flex flex-col items-center mt-8 gap-6"
        >
          <div className="flex flex-row w-full xs:flex-col items-start gap-8 scrollbar-hide ">
            <div className={`w-full gap-y-4 h-full flex flex-col items-start`}>
              {isLoading ? (
                <div className={`w-full gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={40}
                    // width={320}
                    count={6}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <UploadProfile
                        title={t("fileInput:uploadAvatar")}
                        subTitle={t("fileInput:removePicture")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        borderRadius={4}
                      />
                    )}
                    name="image"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyName")}
                        placeholder={t("addCompany:companyName")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.name ? "error" : ""}
                        errorMessage={errors.name ? errors?.name?.message : ""}
                      />
                    )}
                    name="name"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyAdress")}
                        placeholder={t("addCompany:companyAdress")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        isLoading={true}
                        value={value}
                        validate={errors?.address ? "error" : ""}
                        errorMessage={
                          errors.address ? errors?.address?.message : ""
                        }
                      />
                    )}
                    name="address"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyMail")}
                        placeholder={t("addCompany:companyMail")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.email ? "error" : ""}
                        errorMessage={
                          errors?.email ? errors?.email?.message : ""
                        }
                      />
                    )}
                    name="email"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TimeInput
                        label={t("addCompany:companyFiscalNumber")}
                        placeholder={t("addCompany:companyFiscalNumber")}
                        value={moment(value).format("YYYY-MM-DD")}
                        onBlur={true}
                        disabled={false}
                        onChange={(v) => {
                          setValue("fiscalYearStart", v);
                        }}
                      />
                    )}
                    name="fiscalYearStart"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyNumber")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.phone ? "error" : ""}
                        errorMessage={
                          errors?.phone ? errors?.phone?.message : ""
                        }
                      />
                    )}
                    name="phone"
                  />
                </>
              )}
            </div>

            <hr className="w-[1px] h-full bg-secondary-200 xs:hidden" />

            {/*2nd row*/}
            <div className={`w-full gap-y-4 h-full flex flex-col items-start `}>
              {isLoading ? (
                <div className={`w-full gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={40}
                    // width={320}
                    count={6}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactName")}
                        placeholder={t("addCompany:contactName")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactName ? "" : ""}
                        errorMessage={
                          errors?.contactName
                            ? errors?.contactName?.message
                            : ""
                        }
                      />
                    )}
                    name="contactName"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactEmail")}
                        placeholder={t("addCompany:contactEmail")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactEmail ? "error" : ""}
                        errorMessage={
                          errors?.contactEmail
                            ? errors?.contactEmail?.message
                            : ""
                        }
                      />
                    )}
                    name="contactEmail"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactNumber")}
                        placeholder={t("addCompany:contactNumber")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactPhone ? "error" : ""}
                        errorMessage={
                          errors?.contactPhone
                            ? errors?.contactPhone?.message
                            : ""
                        }
                      />
                    )}
                    name="contactPhone"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:taxOffice")}
                        placeholder={t("addCompany:taxOffice")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.taxOffice ? "error" : ""}
                        errorMessage={
                          errors?.taxOffice ? errors?.taxOffice?.message : ""
                        }
                      />
                    )}
                    name="taxOffice"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:taxNumber")}
                        placeholder={t("addCompany:taxNumber")}
                        disabled={true}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.taxNo ? "error" : ""}
                        errorMessage={
                          errors?.taxNo ? errors?.taxNo?.message : ""
                        }
                      />
                    )}
                    name="taxNo"
                  />

                  <Input
                    label={t("addCompany:status")}
                    value={auth?.user?.company?.solutions?.map((e) => {
                      return `${e?.label} `;
                    })}
                    blur="blur-sm"
                    disabled
                  />
                </>
              )}
            </div>
            <hr className="w-[1px] h-full bg-secondary-200 xs:hidden" />

            {/*3nd row*/}
            <div className={`w-full gap-y-4 h-full flex flex-col items-start `}>
              {isLoading ? (
                <>
                  <div className={`w-full gap-y-4 h-full flex flex-col `}>
                    <Skeleton
                      height={40}
                      // width={320}
                      count={6}
                      borderRadius={8}
                      containerClassName="flex flex-col gap-y-3"
                    />
                  </div>
                </>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:country")}
                        value={auth?.user?.company?.country}
                        disabled={true}
                        // icon={<Arrow />}
                        iconPosition="right"
                        blur="blur-sm"
                      />
                    )}
                    name="country"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:timeZone")}
                        value={auth?.user?.company?.timeZone}
                        disabled={true}
                        blur="blur-sm"
                      />
                    )}
                    name="timeZone"
                  />

                  <div
                    className={`w-full h-full justify-between items-center flex gap-2`}
                  >
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:equipmentCredit")}
                          value={auth?.user?.company?.nodeLimit}
                          disabled
                          blur="blur-sm"
                        />
                      )}
                      name="nodeLimit"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={t("addCompany:personCredit")}
                          value={auth?.user?.company?.userLimit}
                          disabled
                          blur="blur-sm"
                        />
                      )}
                      name="userLimit"
                    />
                  </div>

                  <HourTimeInput
                    onChange={(v) => {
                      setStartTime(v);
                    }}
                    onBlur={true}
                    // setValue={setStartTime}
                    value={startTime}
                    label={t("addShift:dayStartTime")}
                  />

                  <div className={`w-full h-full justify-between flex gap-2`}>
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input
                          label={"T1 Standard"}
                          value={value}
                          blur="blur-sm"
                        />
                      )}
                      name="t1Standard"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T1"} value={value} blur="blur-sm" />
                      )}
                      name="t1"
                    />
                  </div>
                  <div className={`w-full h-full justify-between flex gap-2`}>
                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T2"} value={value} blur="blur-sm" />
                      )}
                      name="t2"
                    />

                    <Controller
                      control={control}
                      render={({ field: { onchange, onBlur, value } }) => (
                        <Input label={"T3"} value={value} blur="blur-sm" />
                      )}
                      name="t3"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <hr className="w-full h-[1px] bg-secondary-200 mt-2" />
        </form>
      </div>

      {/*sm & md*/}
      <div className="sm:flex md:flex flex-col hidden items-start relative  h-full w-full scrollbar-hide">
        <div className="flex items-center h-[72px] border-b w-full border-secondary-200">
          <div className="flex flex-col w-1/2">
            <div className="flex flex-row items-center justify-between">
              <p className="text-secondary-900 text-lg font-medium dark:text-[#FAFAFA]">
                {t("settingTab:company")}
              </p>
            </div>
            <p className="text-sm text-secondary-500 font-normal dark:text-[#D6D6D6]">
              {t("settingTab:updateYourCompany")}
            </p>
          </div>
          <div className="w-1/2 flex flex-row items-center gap-3 justify-end ">
            <div className="w-[30%] xs:w-full sm:w-[65%] md:w-1/2 lg:w-1/2">
              <Button
                size={"lg"}
                colorType={"primary-machine"}
                label={t("buttons:saveChanges")}
                // type={"submit"}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" max-h-[590px] overflow-auto scrollbar-hide w-full flex flex-col items-center mt-8 gap-6"
        >
          <div className="flex  flex-row w-full gap-x-6 justify-between  scrollbar-hide ">
            <div className={`w-full gap-y-4  h-full flex flex-col`}>
              {isLoading ? (
                <div className={`w-full gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={40}
                    // width={320}
                    count={6}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <UploadProfile
                        title={t("fileInput:uploadAvatar")}
                        subTitle={t("fileInput:removePicture")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        borderRadius={4}
                      />
                    )}
                    name="image"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyName")}
                        placeholder={t("addCompany:companyName")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.name ? "error" : ""}
                        errorMessage={errors.name ? errors?.name?.message : ""}
                      />
                    )}
                    name="name"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyAdress")}
                        placeholder={t("addCompany:companyAdress")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        isLoading={true}
                        value={value}
                        validate={errors?.address ? "error" : ""}
                        errorMessage={
                          errors.address ? errors?.address?.message : ""
                        }
                      />
                    )}
                    name="address"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyMail")}
                        placeholder={t("addCompany:companyMail")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.email ? "error" : ""}
                        errorMessage={
                          errors?.email ? errors?.email?.message : ""
                        }
                      />
                    )}
                    name="email"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TimeInput
                        label={t("addCompany:companyFiscalNumber")}
                        placeholder={t("addCompany:companyFiscalNumber")}
                        value={moment(value).format("YYYY-MM-DD")}
                        disabled={false}
                        onBlur={true}
                        onChange={(v) => {
                          setValue("fiscalYearStart", v);
                        }}
                      />
                    )}
                    name="fiscalYearStart"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:companyNumber")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.phone ? "error" : ""}
                        errorMessage={
                          errors?.phone ? errors?.phone?.message : ""
                        }
                      />
                    )}
                    name="phone"
                  />
                </>
              )}
            </div>

            {/*2nd row*/}
            <div className={`w-full gap-y-4  h-full flex flex-col `}>
              {isLoading ? (
                <div className={`w-full gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={40}
                    // width={320}
                    count={6}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              ) : (
                <>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactName")}
                        placeholder={t("addCompany:contactName")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactName ? "" : ""}
                        errorMessage={
                          errors?.contactName
                            ? errors?.contactName?.message
                            : ""
                        }
                      />
                    )}
                    name="contactName"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactEmail")}
                        placeholder={t("addCompany:contactEmail")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactEmail ? "error" : ""}
                        errorMessage={
                          errors?.contactEmail
                            ? errors?.contactEmail?.message
                            : ""
                        }
                      />
                    )}
                    name="contactEmail"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:contactNumber")}
                        placeholder={t("addCompany:contactNumber")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.contactPhone ? "error" : ""}
                        errorMessage={
                          errors?.contactPhone
                            ? errors?.contactPhone?.message
                            : ""
                        }
                      />
                    )}
                    name="contactPhone"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:taxOffice")}
                        placeholder={t("addCompany:taxOffice")}
                        disabled={false}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.taxOffice ? "error" : ""}
                        errorMessage={
                          errors?.taxOffice ? errors?.taxOffice?.message : ""
                        }
                      />
                    )}
                    name="taxOffice"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:taxNumber")}
                        placeholder={t("addCompany:taxNumber")}
                        disabled={true}
                        onBlur={onBlur}
                        blur="blur-sm"
                        onChange={onChange}
                        value={value}
                        validate={errors?.taxNo ? "error" : ""}
                        errorMessage={
                          errors?.taxNo ? errors?.taxNo?.message : ""
                        }
                      />
                    )}
                    name="taxNo"
                  />

                  <Input
                    label={t("addCompany:status")}
                    value={auth?.user?.company?.solutions?.map((e) => {
                      return `${e?.label} `;
                    })}
                    disabled
                    blur="blur-sm"
                  />
                </>
              )}
            </div>
          </div>
          {/*3nd row*/}

          <div className={`w-full gap-y-4 h-full flex flex-col items-start `}>
            {isLoading ? (
              <>
                <div className={`w-full gap-y-4 h-full flex flex-col `}>
                  <Skeleton
                    height={40}
                    // width={320}
                    count={6}
                    borderRadius={8}
                    containerClassName="flex flex-col gap-y-3"
                  />
                </div>
              </>
            ) : (
              <>
                <Controller
                  control={control}
                  render={({ field: { onchange, onBlur, value } }) => (
                    <Input
                      label={t("addCompany:country")}
                      value={auth?.user?.company?.country}
                      disabled={true}
                      // icon={<Arrow />}
                      blur="blur-sm"
                      iconPosition="right"
                    />
                  )}
                  name="country"
                />

                <Controller
                  control={control}
                  render={({ field: { onchange, onBlur, value } }) => (
                    <Input
                      label={t("addCompany:timeZone")}
                      value={auth?.user?.company?.timeZone}
                      disabled={true}
                      blur="blur-sm"
                    />
                  )}
                  name="timeZone"
                />

                <div
                  className={`w-full h-full justify-between items-center flex gap-2`}
                >
                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:equipmentCredit")}
                        value={auth?.user?.company?.nodeLimit}
                        disabled
                        blur="blur-sm"
                      />
                    )}
                    name="nodeLimit"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input
                        label={t("addCompany:personCredit")}
                        value={auth?.user?.company?.userLimit}
                        disabled
                        blur="blur-sm"
                      />
                    )}
                    name="userLimit"
                  />
                </div>

                <HourTimeInput
                  onChange={(v) => {
                    setStartTime(v);
                  }}
                  // setValue={setStartTime}
                  value={startTime}
                  label={t("addShift:dayStartTime")}
                  onBlur={true}
                />

                <div className={`w-full h-full justify-between flex gap-2`}>
                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input
                        label={"T1 Standard"}
                        value={value}
                        blur="blur-sm"
                      />
                    )}
                    name="t1Standard"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input label={"T1"} value={value} blur="blur-sm" />
                    )}
                    name="t1"
                  />
                </div>
                <div className={`w-full h-full justify-between flex gap-2`}>
                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input label={"T2"} value={value} blur="blur-sm" />
                    )}
                    name="t2"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onchange, onBlur, value } }) => (
                      <Input label={"T3"} value={value} blur="blur-sm" />
                    )}
                    name="t3"
                  />
                </div>
              </>
            )}
          </div>

          <hr className="w-full h-[1px] bg-secondary-200 mt-2" />
          {/* <div className="w-full flex flex-row items-center gap-3 justify-end ">
            <div className="w-[20%] sm:w-[40%] md:w-[30%] xs:w-full">
              <Button
                size={"lg"}
                colorType={"primary-machine"}
                label={t("buttons:saveChanges")}
                type={"submit"}
              />
            </div>
          </div> */}
        </form>
      </div>
      <div className=" xs:flex hidden xs:w-full mt-2">
        <Button
          size={"lg"}
          colorType={"primary-machine"}
          label={t("buttons:saveChanges")}
          // type={"submit"}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

export default SettingsCompany;
