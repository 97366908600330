import React from "react";
import { InternalOperationIcon } from "../../../assets/icons/stepHeaderIcons";
import { EquipmentIcon } from "../../../assets/icons/equipmentIcon";
import { Arrow } from "../../../assets/icons/stepsIcons";
import { manipulate } from "../../../utils/manipulate";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LossSelectCard = ({
  name,
  piece,
  onClick,
  setValue,
  values = [{ value: "empty", label: "Empty" }],
}) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-row shadow-xs items-center gap-3 w-full h-[54px] min-h-[54px] border border-secondary-300 rounded-lg z-10 cursor-pointer px-3 py-2 relative"
    >
      <div className="w-8 h-8 min-w-[32px] min-h-[32px] flex items-center justify-center">
        {name === "Operasyon" ? (
          <InternalOperationIcon width={24} height={24} />
        ) : (
          <EquipmentIcon />
        )}
      </div>
      <div className="flex flex-col w-[80%] truncate">
        <p className="text-xs font-normal text-secondary-600">{manipulate(name)}</p>
        <p className="text-sm font-semibold text-secondary-700">
          {piece || "--"}
        </p>
      </div>
      <div className="w-8 h-8 ml-auto flex items-center">
        <Arrow />
      </div>
      <select
        onChange={({ target }) => {
          setValue(target.value);
        }}
        className="opacity-0 absolute inset-0 -bottom-3  ml-4"
      >
        {values
          ?.sort((a, b) => a.value - b.value)
          ?.map((item, index) => {
            return (
              <option className="text-xs h-[20px]" value={item?.value} key={index}>
                {/* {item?.label} */}
                {manipulate(item?.label)}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default React.memo(LossSelectCard);
