import React, { useEffect, useState } from "react";
import { Button, Input, UploadProfile } from "../../components";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Selector from "../../components/inputs/Selector";
import UnitInput from "../machine/embeddedServer/UnitInput";
import TextArea from "../../components/inputs/TextArea";
import UnitPriceInput from "../../components/inputs/UnitPriceInput";
import { rawMaterialService } from "../../services/raw-material.service";
import { Toast } from "../../utils/toastify/toast";
import { useQuery } from "@tanstack/react-query";
const NewCreateRawMaterial = () => {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["supplierGroup"],
    queryFn: async () => {
      return await rawMaterialService.supplierGroup();
    },
    retry: 0,
  });

  const {
    data: rawMaterialData,
    isLoading: rawMaterialDataLoading,
    refetch: rawMaterialDataRefetch,
  } = useQuery({
    queryKey: ["getRawMaterialDetail", itemId],
    queryFn: async () =>
      itemId ? await rawMaterialService.getPurchaseList(itemId) : null,
    enabled: !!itemId,
    retry: 0,
  });

  console.log("rawMaterialData", rawMaterialData);

  const schema = yup.object({
    image: yup.string().notRequired(),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stockCode: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    materialType: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    supplierGroup: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    unit: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    price: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stockQuantity: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const rawMaterials = [
    { label: "Çubuk", value: "stick" },
    { label: "Lama", value: "lama" },
    { label: "Boru", value: "pipe" },
  ];
  const priceOptions = [
    {
      label: "USD",
      value: "usd",
    },
    {
      label: "EUR",
      value: "eur",
    },
    {
      label: "GBP",
      value: "gbp",
    },
    {
      label: "RUB",
      value: "rub",
    },
    {
      label: "UAH",
      value: "uah",
    },
    {
      label: "TRY",
      value: "try",
    },
  ];
  const units = [
    { label: "Kilogram", shortLabel: "kg", value: "kg" },
    { label: t("product:metricTon"), shortLabel: "ton", value: "ton" },
    { label: "Gram", shortLabel: "gr", value: "gr" },
    { label: "Miligram", shortLabel: "mg", value: "mg" },
    { label: "Microgram", shortLabel: "mcg", value: "mcg" },
    { label: "Pound", shortLabel: "lb", value: "lb" },
    { label: t("product:ounce"), shortLabel: "oz", value: "oz" },
    { label: "Metre", shortLabel: "m", value: "m" },
    { label: "Millilitre", shortLabel: "mm", value: "mm" },
    { label: t("product:gallon"), shortLabel: "gal", value: "gal" },
    { label: t("product:squareMetre"), shortLabel: "m2", value: "m2" },
    { label: t("product:squareMilimetre"), shortLabel: "mm2", value: "mm2" },
    { label: t("product:squareInch"), shortLabel: "in2", value: "mm2" },
    { label: t("product:pieceModal"), shortLabel: "ad", value: "ad" },
    { label: t("product:package"), shortLabel: "pkg", value: "pkg" },
    { label: t("product:box"), shortLabel: "box", value: "box" },
  ];

  const onSubmit = async (value) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  useEffect(() => {
    if (rawMaterialData) {
      setValue("image", rawMaterialData.image);
      setValue("name", rawMaterialData.name);
      setValue("stockCode", rawMaterialData.stockCode);
      setValue("materialType", rawMaterialData.type);
      setValue("supplierGroup", rawMaterialData.supplierGroup?.id);
      setValue("unit", rawMaterialData.unitType);
      setValue("price", rawMaterialData.price);
      setValue("stockQuantity", rawMaterialData.quantity);
      setValue("description", rawMaterialData.description);
    }
  }, [rawMaterialData, setValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex flex-col overflow-hidden"
    >
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <Button
          colorType={"tertiary-gray"}
          onClick={() => {
            navigate(-1);
          }}
          size={"sm"}
          iconLeft={
            <div className="rotate-180">
              <TableArrowLeft />
            </div>
          }
        />

        <div className="flex items-center">
          <span className="text-2xl text-secondary-900 font-semibold ml-2">
            {itemId
              ? "Hammadde / Sarf Düzenle"
              : "Yeni Hammadde / Sarf Oluştur"}
          </span>
        </div>
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <Button
            type={"submit"}
            label={itemId ? t("buttons:save") : t("buttons:create")}
            colorType={"primary-product"}
            size={"md"}
          />
        </div>
      </div>
      <div className="w-full">
        <div className="w-[30%]">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile
                title={"Hammadde / Sarf Fotoğrafı"}
                subTitle={t("fileInput:removePicture")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={errors?.image ? errors?.image?.message : ""}
                validate={errors?.image ? "error" : ""}
              />
            )}
            name="image"
          />
        </div>
      </div>
      <div className="w-full flex items-center flex-row gap-x-6 px-1">
        <div className="flex flex-col gap-y-4 w-[49.5%] mt-4">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={"Hammadde / Sarf Adı"}
                onChange={onChange}
                onBlur={onBlur}
                theme={"product"}
                value={value}
                validate={errors?.name ? "error" : ""}
                errorMessage={errors?.name ? errors?.name?.message : ""}
              />
            )}
            name="name"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={"Stok Kodu"}
                onChange={onChange}
                onBlur={onBlur}
                theme={"product"}
                value={value}
                validate={errors?.rawMaterialCode ? "error" : ""}
                errorMessage={
                  errors?.rawMaterialCode
                    ? errors?.rawMaterialCode?.message
                    : ""
                }
              />
            )}
            name="stockCode"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                items={rawMaterials}
                theme={"product"}
                label={t("Hammadde / Sarf Tipi")}
                onChange={onChange}
                onBlur={onBlur}
                validate={errors?.materialType ? "error" : ""}
                errorMessage={
                  errors?.materialType ? errors?.materialType?.message : ""
                }
              />
            )}
            name="materialType"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                value={value}
                items={data?.map((item) => {
                  return { label: item?.name, value: item?.id };
                })}
                theme={"product"}
                label={t("Bağlı Olduğu Tedarikçi Grubu")}
                onChange={onChange}
                onBlur={onBlur}
                validate={errors?.materialType ? "error" : ""}
                errorMessage={
                  errors?.materialType ? errors?.materialType?.message : ""
                }
              />
            )}
            name="supplierGroup"
          />
        </div>
        <div className="flex flex-col gap-y-4 w-[49.5%] mt-4">
          <div className="flex gap-x-3">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  value={value}
                  items={units}
                  theme={"product"}
                  label={t("product:unit")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.unit ? "error" : ""}
                  errorMessage={errors?.unit ? errors?.unit?.message : ""}
                />
              )}
              name="unit"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <UnitPriceInput
                  value={value}
                  theme={"product"}
                  items={priceOptions}
                  label={"Birim / Fiyat"}
                  onChange={onChange}
                  setValue={setValue}
                  currency={value?.currency}
                  onBlur={onBlur}
                  validate={errors?.price ? "error" : ""}
                  errorMessage={errors?.price ? errors?.price?.message : ""}
                />
              )}
              name="price"
            />
          </div>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={"Güncel Stok Adedi"}
                onChange={onChange}
                onBlur={onBlur}
                theme={"product"}
                value={value}
                validate={errors?.rawMaterialCode ? "error" : ""}
                errorMessage={
                  errors?.rawMaterialCode
                    ? errors?.rawMaterialCode?.message
                    : ""
                }
              />
            )}
            name="stockQuantity"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                label={t("product:description")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                rows={5}
                validate={errors?.description ? "error" : ""}
                errorMessage={
                  errors?.description ? errors?.description?.message : ""
                }
                height={122}
              />
            )}
            name="description"
          />
        </div>
      </div>
    </form>
  );
};

export default NewCreateRawMaterial;
