import React, { memo, useState } from "react";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { authStore } from "../../../../stores/auth.store";
import { useTranslation } from "react-i18next";

const MonthlyChart = ({
  data,
  height = "255px",
  start,
  end,
  setDate,
  setLegendSelected,
  legendSelected,
}) => {
  const { t } = useTranslation();
  const onChartClick = (params) => {
    if (params?.name) {
      setDate(params.name);
    }
  };
  const onEvents = {
    click: onChartClick,
    legendselectchanged: function (params) {
      setLegendSelected(params.selected);
    },
  };
  const dates = data?.dates?.map((item) =>
    String(moment(item).format("YYYY-MM-DD"))
  );
  const option = {
    title: {},
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "15%"];
      },
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      selected: legendSelected,
      data: data?.legend,
      right: "5%",
      left: "2%",
      top: "0%",
      alignTicks: false,
      formatter: function (name) {
        let itemValue = data?.series?.filter((item) => item.name === name);
        const total = itemValue[0].data.reduce((a, b) => {
          return a + b;
        }, 0);
        return `{name|${name}}:{total|Avg: ${Number((total / 30).toFixed(2))}}`;
      },
      textStyle: {
        rich: {
          name: { fontWeight: "regular", align: "left", fontSize: 10 },
          total: {
            fontSize: 10,
            fontWeight: "regular",
          },
        },
      },
    },
    grid: {
      left: "2%",
      right: "2%",
      bottom: "0%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: dates,
      axisLabel: {
        formatter: function (value, index) {
          return value.slice(8, 10);
        },
        show: true,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "kVa/h",
        position: "right",
        alignTicks: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            left: "10px",
          },
        },
        axisLabel: {
          formatter: "{value} kVa/h",
          show: true,
          textStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            right: "-10px",
          },
        },
      },
      {},
      {},
    ],
    series: data?.series,
  };
  return (
    <div
      className="relative pt-7 w-full h-full"
      style={{
        minWidth: "100%",
      }}
    >
      <ReactECharts
        onEvents={onEvents}
        option={option}
        style={{
          height: height,
          width: "100%",
        }}
      />
      <div
        className={`absolute -top-4 font-normal gap-2 dark:text-[#F5F5F5] left-4 w-full gap-x-2`}
      >
        <p className="text-[12px] mt-6 font-semibold inline-block ">
          {t("settingTab:start")} :{" "}
          {start
            ? moment(
                timeFormatter(start, authStore?.user?.company?.timeZone)
                  .formatted
              ).format("DD-MM-YYYY")
            : ""}
        </p>
        <p className="text-[12px] ml-2 mt-6 font-semibold inline-block ">
          {t("settingTab:end")} :{" "}
          {end
            ? moment(
                timeFormatter(end, authStore?.user?.company?.timeZone).formatted
              ).format("DD-MM-YYYY")
            : ""}
        </p>
      </div>
    </div>
  );
};

export default memo(MonthlyChart);
