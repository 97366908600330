import React, { useEffect, useState } from "react";
import PollList from "../components/PollList";
import { useQuery } from "@tanstack/react-query";
import { pollService } from "../../../services/poll.service";
import { Outlet, useParams } from "react-router-dom";
import PollInfoDetails from "./PollInfoDetails";
import { useStore } from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";
import NoData from "../../../steps/step-navigation/components/NoData";
import { useTranslation } from "react-i18next";
import { Button, CustomModal } from "../../../components";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import NewPollModal from "../modals/NewPollModal";
import { Toast } from "../../../utils/toastify/toast";

const Poll = observer(() => {
  const { t } = useTranslation();
  const { chat, auth } = useStore();
  const { id } = useParams();
  const [isNewPollModalOpen, setIsNewPollModalOpen] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    retry: 0,
    queryKey: ["getPolls"],
    queryFn: async () => await pollService.getPolls(),
  });

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <>
      <div className="flex flex-row items-center w-full mb-4 mt-1  border-b">
        <span className="font-codecMedium text-2xl text-black">upu.</span>
        <p className="text-2xl font-codecMedium text-teal-700">poll</p>
        <div className="ml-auto w-fit mb-3">
          <Button
            label={t("person:newPoll")}
            iconLeft={<PlusIcon />}
            colorType={"primary-person"}
            // onClick={() => setIsNewPollModalOpen(true)}
            onClick={() => Toast("warning", t("alert:warning"))}
            size={"md"}
          />
        </div>
      </div>

      <div className="w-full h-full flex  border border-secondary-200 shadow-md bg-white rounded-xl">
        {id ? (
          <div className="p-4 w-full flex flex-1">
            <Outlet />
            {chat?.currentPoll?._id && (
              <PollInfoDetails
                pollId={chat?.currentPoll?._id}
                onClose={() => chat?.setCurrentPoll(null)}
              />
            )}
          </div>
        ) : (
          <div className="flex-1 h-full flex items-center justify-center">
            <NoData
              button={false}
              theme={"person"}
              header={t("chat:poll")}
              text={t("person:selectItem")}
            />
          </div>
        )}
        <PollList data={data} isLoading={isLoading} refetch={refetch} />
      </div>
      <CustomModal
        isOpen={isNewPollModalOpen}
        setIsOpen={setIsNewPollModalOpen}
        modalTitle={t("person:createNewPoll")}
        isBadges={true}
        badgesValue={data?.length + "/" + auth?.user?.company?.limits?.poll}
        badgesColor={"fill-success"}
        badgesSize={"md"}
        children={
          <NewPollModal
            setIsNewPollModalOpen={setIsNewPollModalOpen}
            refetch={refetch}
          />
        }
      />
    </>
  );
});

export default Poll;
