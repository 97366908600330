import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { NoEquIcon } from "../../../assets/icons/machineIcons";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, UploadProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../../../components/inputs/TextArea";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";

function PurchaseJunctionFinish({ rawData, junctionData, refetch, close }) {
  const { t } = useTranslation(["product"]);
  const schema = yup.object({
    purchaseFile: yup.string().notRequired(),
    purchaseDescription: yup.string().notRequired(),
    incomingUnit: yup.number(),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const onSubmit = async (value) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
      <div className="flex flex-row w-full  mt-6 border border-secondary-300 rounded-lg pl-2 h-full ">
        <div className="w-full flex flex-col py-2">
          <div className="flex flex-row gap-x-2 items-center  ">
            {rawData?.image ? (
              <img
                src={generateFileUrl(rawData?.image)}
                className="min-w-[64px] w-[64px] h-[48px] rounded border border-secondary-300 object-cover"
              />
            ) : (
              <div className="w-[64px] min-w-[64px] h-[48px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
                <NoEquIcon />
              </div>
            )}

            <div className="flex flex-col items-start">
              <p className="text-lg text-secondary-900 w-[330px] truncate font-medium">
                {rawData?.name || "---"}
              </p>
              <p className="text-sm text-secondary-600">
                {t("product:stockId")}: {rawData?.stockCode || "---"}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <QtyIcon />
              <p className="text-xs font-medium text-secondary-600">
                {rawData?.quantity === null ? 0 : rawData?.quantity}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:totalStocks")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1 mt-2">
              <DateIcon />
              <p className="text-xs font-medium text-secondary-600">
                {rawData?.deliveryDate || "---"}
              </p>
              <p className="text-[10px] text-secondary-500 font-normal">
                {t("product:deliveryDate")}
              </p>
            </div>
          </div>
        </div>
        <div className="h-full flex items-start border-l rounded-bl-lg border-r-0 border-t-0 border-b bg-[#EAECF0] border border-[#D0D5DD] p-[3px]">
          <div className=" w-6 h-[70px] flex items-center justify-center">
            <p className="-rotate-90 text-[10px] font-normal text-secondary-600 tracking-widest">
              {t("product:rawMaterials")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-y-4">
        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={t("product:receivedQuantity")}
                errorMessage={errors?.acceptedPiece?.message}
                validate={errors?.acceptedPiece ? "error" : ""}
              />
            )}
            name="incomingUnit"
          />
        </div>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea
              label={t("product:descOptional")}
              width={"100%"}
              onBlur={onBlur}
              onChange={onChange}
              theme={"product"}
              rows={3}
              value={value}
            />
          )}
          name="purchaseDescription"
        />
        <div className="flex flex-col">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile
                title={t("product:attachment")}
                subTitle={t("product:removePicture")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
            name="purchaseFile"
          />
        </div>
      </div>
      <div className="flex mt-6 w-full">
        <Button
          colorType={"primary-person"}
          type={"submit"}
          label={t("buttons:sendToQualityControl")}
          size={"lg"}
        />
      </div>
    </form>
  );
}

export default PurchaseJunctionFinish;

export const QtyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

export const DateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
