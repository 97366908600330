import React from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { UpuPointIcon } from "../../assets/icons/leftMenuIcons";
import { useTranslation } from "react-i18next";
import Badgets from "../buttons/Badgets";
import { Toast } from "../../utils/toastify/toast";

const junctionProps = {
  ring: "",
  status: "",
  leftImage: "",

  texts: {
    first: "",
    second: "",
    piece: "",
    time: "",
    qualityAcceptedPiece: "",
    qualityRejectedPiece: "",
    estimatedTime: "",
    estimatedTrue: "",
    point: "",
    oeeData: "",
    performanceData: "",
  },
  user: { avatar: "", name: "", lastName: "" },
  equipment: { name: "", image: "" },
  rightButton: () => null,
  onClick: () => null,
};

const SingleJunctionCard = ({ props = junctionProps, id, quality, step }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => {
        if (props?.onClick) {
          props?.onClick();
        }
      }}
      style={{
        borderLeftWidth: "1px",
        borderBottomWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: props.status !== "completed" ? "4px" : "1px",
        borderTopColor: props.status !== "completed" ? props.ring : "#D0D5DD",
        cursor: props?.onClick ? "pointer" : "default",
      }}
      className="w-full p-2 rounded-lg flex border-l-gray-300 border-b-gray-300 border-r-gray-300 shadow h-[80px] max-h-[80px] min-h-[80px] bg-white"
    >
      <img
        data-tooltip-id={`${id}`}
        data-tooltip-content={`${props?.texts?.first.slice(0,3)+"***"} `}
        src={generateFileUrl(props.leftImage)}
        className="w-[72px] min-w-[72px] h-[54px] blur-sm rounded border border-gray-300 mt-0.5"
      />
      <div className="flex-1 flex ml-3 flex-col overflow-hidden">
        <div className="w-full overflow-hidden -mt-0.5">
          <p className="truncate w-[130px] text-xs text-secondary-500">
            {props?.texts?.first?.slice(0, 2) + " ***" || "---"}
          </p>
        </div>
        <div className="w-full overflow-hidden">
          {props.status === "completed" ? (
            <p></p>
          ) : (
            <p className="truncate text-xs text-secondary-700 font-medium">
              {props?.texts?.second?.slice(0, 2) + " ***" || "---"}
            </p>
          )}
        </div>
        {props.status === "completed" ? (
          <div className="flex flex-col w-full">
            <div className="w-full flex items-center mt-0.5">
              <div className="w-1/2 flex items-center gap-1 overflow-hidden">
                <QualityAccepted />
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.qualityAcceptedPiece?.slice(0, 2) + " ***" ||
                    "--"}{" "}
                  {t("product:pcs")}
                </p>
              </div>
              <div className="w-1/2 flex items-center gap-1 overflow-hidden">
                {props.texts.estimatedTrue ? (
                  <GreenTimeIcon />
                ) : (
                  <RedTimeIcon />
                )}

                {/* <ClockIcon /> */}
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.estimatedTime?.slice(0, 2) + " ***" || "--"}
                </p>
              </div>
            </div>

            <div className="w-full flex items-center -mb-1">
              <div className="w-1/2 flex items-center gap-1 overflow-hidden">
                <QualityRejected />
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.qualityRejectedPiece === undefined
                    ? "0"
                    : props.texts?.qualityRejectedPiece?.slice(0, 2) +
                      " ***"}{" "}
                  {t("product:pcs")}
                </p>
              </div>
              <div className="w-1/2 flex items-center gap-1 overflow-hidden  ">
                <TimeIcon />
                <p className="truncate text-xs text-secondary-700 font-medium">
                  {props.texts?.totalTime || "--"}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex items-center gap-x-2 ">
            <div className="w-[70%] flex items-center gap-1 overflow-hidden">
              <QtyIcon />
              <p className="truncate text-xs text-secondary-700 font-medium">
                {props?.texts?.piece || "---"} ad
              </p>
            </div>
            <div className="w-[60%] flex items-center gap-1 overflow-hidden">
              <ClockIcon />
              <p className="truncate text-xs text-secondary-700 font-medium">
                {props.texts?.time || "--"}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="ml-3">
        {props?.rightButton ? (
          <button
            onClick={() => {
              // props?.rightButton();
              Toast(
                "warning",
                "Demo users are not authorized to access contact administration."
              );
            }}
            type="button"
            className="min-w-[50px] h-[50px] blur-sm rounded-full border-dashed border border-gray-300 flex items-center justify-center mt-1"
          >
            <PlusIcon />
          </button>
        ) : (
          <>
            {props?.user ? (
              <div
                data-tooltip-id={`${id}`}
                data-tooltip-content={`${
                  props?.user?.name + " " + props?.user?.lastName
                } `}
                className="mt-0.5"
              >
                {props?.user?.avatar ? (
                  <div className=" relative ">
                    <img
                      className="w-[54px] min-w-[54px] h-[54px] rounded-full border border-gray-300"
                      src={generateFileUrl(props?.user?.avatar)}
                      alt={props?.user?.name + " " + props?.user?.lastName}
                    />

                    {quality && (
                      <div
                        className={`z-[1] absolute flex items-center justify-center ml-[14px]  w-[28px] -bottom-2.5 h-[24px] rounded border ${
                          quality === "completed"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : quality === "waiting"
                            ? "bg-[#EAAA08] border-[#EAAA08]"
                            : quality === "control"
                            ? "bg-[#98A2B3] border-[#98A2B3]"
                            : quality === "rejected"
                            ? "bg-[#F04438] border-[#F04438]"
                            : quality === "accepted"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : "bg-[#DC6803] border-[#DC6803]"
                        }`}
                      >
                        {quality === "completed" ? (
                          <ProductCompleted />
                        ) : quality === "waiting" ? (
                          <ProductWaiting />
                        ) : quality === "control" ? (
                          <ProductControl />
                        ) : quality === "rejected" ? (
                          <ProductRejected />
                        ) : quality === "accepted" ? (
                          <ProductCompleted />
                        ) : (
                          <ProductIcon />
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="relative">
                    <NoAvatar
                      size={54}
                      fontSize={22}
                      name={props?.user?.name}
                      lastName={props?.user?.lastName}
                      color={"#475467"}
                    />
                    {quality && (
                      <div
                        className={`z-[1] absolute flex items-center justify-center ml-[14px]  w-[28px] -bottom-2.5 h-[24px] rounded border ${
                          quality === "completed"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : quality === "waiting"
                            ? "bg-[#EAAA08] border-[#EAAA08]"
                            : quality === "control"
                            ? "bg-[#98A2B3] border-[#98A2B3]"
                            : quality === "rejected"
                            ? "bg-[#F04438] border-[#F04438]"
                            : quality === "accepted"
                            ? "bg-[#17B26A] border-[#17B26A]"
                            : "bg-[#DC6803] border-[#DC6803]"
                        }`}
                      >
                        {quality === "completed" ? (
                          <ProductCompleted />
                        ) : quality === "waiting" ? (
                          <ProductWaiting />
                        ) : quality === "control" ? (
                          <ProductControl />
                        ) : quality === "rejected" ? (
                          <ProductRejected />
                        ) : quality === "accepted" ? (
                          <ProductCompleted />
                        ) : (
                          <ProductIcon />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <img
                src={generateFileUrl(props?.equipment?.image)}
                className="w-[72px] min-w-[72px] h-[54px] rounded border blur-sm border-gray-300"
              />
            )}
          </>
        )}
      </div>
      <Tooltip id={`${id}`} className="z-[100]" />
      {step === undefined ? null : (
        <span className="absolute left-1 top-2">
          <Badgets colorType={"fill-gray"} label={step + ".op"} size={"sm"} />
        </span>
      )}
    </div>
  );
};

export default SingleJunctionCard;

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 5V19M5 12H19"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const QtyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#667085"
      />
    </svg>
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_1627_2858)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1627_2858">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QualityAccepted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V4.02098C4.3125 4.06877 4.36279 4.09985 4.40554 4.07848L6 3.28125L7.59446 4.07848C7.63721 4.09985 7.6875 4.06877 7.6875 4.02098V1.75M4.3125 1.75H7.6875"
        stroke="#079455"
        strokeWidth="0.8"
      />
      <path
        d="M4.5 7.09615L5.57692 8.17308L8 5.75"
        stroke="#079455"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const QualityRejected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V4.02098C4.3125 4.06877 4.36279 4.09985 4.40554 4.07848L6 3.28125L7.59446 4.07848C7.63721 4.09985 7.6875 4.06877 7.6875 4.02098V1.75M4.3125 1.75H7.6875"
        stroke="#D92D20"
        strokeWidth="0.8"
      />
      <path
        d="M7.25 5.75L4.75 8.25M4.75 5.75L7.25 8.25"
        stroke="#D92D20"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RedTimeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.71055 4.01917V6.33495L4.26318 7.20338"
        stroke="#D92D20"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33418 5.99628C1.33418 3.60648 3.27149 1.66917 5.66128 1.66917C8.05108 1.66917 9.98839 3.60648 9.98839 5.99628C9.98839 6.0127 9.9883 6.02911 9.98812 6.04549H10.7882C10.7883 6.02911 10.7884 6.0127 10.7884 5.99628C10.7884 3.16465 8.49291 0.869171 5.66128 0.869171C2.82966 0.869171 0.53418 3.16465 0.53418 5.99628C0.53418 8.8279 2.82966 11.1234 5.66128 11.1234C6.18034 11.1234 6.68139 11.0462 7.15358 10.9028C7.12555 10.8648 7.10054 10.8244 7.07888 10.7819C7.00209 10.6311 6.9876 10.4427 6.98487 10.1172C6.56769 10.2511 6.12293 10.3234 5.66128 10.3234C3.27149 10.3234 1.33418 8.38607 1.33418 5.99628Z"
        fill="#D92D20"
      />
      <path
        d="M8.02637 8.12968C8.02637 7.80544 8.02637 7.64332 8.08947 7.51948C8.14497 7.41054 8.23354 7.32198 8.34248 7.26647C8.46632 7.20337 8.62844 7.20337 8.95268 7.20337H10.5737C10.898 7.20337 11.0601 7.20337 11.1839 7.26647C11.2929 7.32198 11.3814 7.41054 11.437 7.51948C11.5001 7.64332 11.5001 7.80544 11.5001 8.12969V9.75074C11.5001 10.075 11.5001 10.2371 11.437 10.3609C11.3814 10.4699 11.2929 10.5584 11.1839 10.614C11.0601 10.6771 10.898 10.6771 10.5737 10.6771H8.95268C8.62844 10.6771 8.46632 10.6771 8.34248 10.614C8.23354 10.5584 8.14497 10.4699 8.08947 10.3609C8.02637 10.2371 8.02637 10.075 8.02637 9.75074V8.12968Z"
        stroke="#D92D20"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const GreenTimeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
    >
      <path
        d="M5.577 3.07257V5.32898L4.16675 6.17514"
        stroke="#079455"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.32319 4.99897C1.32319 2.67612 3.20624 0.793072 5.52909 0.793072C7.76663 0.793072 9.59607 2.54034 9.72744 4.74488L10.5264 5.29419C10.5321 5.19651 10.535 5.09807 10.535 4.99897C10.535 2.23429 8.29377 -0.00692749 5.52909 -0.00692749C2.76441 -0.00692749 0.523193 2.23429 0.523193 4.99897C0.523193 7.76365 2.76441 10.0049 5.52909 10.0049C6.03631 10.0049 6.52591 9.92943 6.98728 9.78916V8.94522C6.53298 9.11315 6.04175 9.20487 5.52909 9.20487C3.20624 9.20487 1.32319 7.32182 1.32319 4.99897Z"
        fill="#079455"
      />
      <path
        d="M8.11548 5.83447C8.11548 5.60295 8.11548 5.48719 8.16375 5.42337C8.20581 5.36778 8.27009 5.33338 8.33967 5.32923C8.41954 5.32446 8.51586 5.38867 8.7085 5.5171L11.2154 7.18836C11.3746 7.29448 11.4542 7.34754 11.4819 7.41442C11.5062 7.47288 11.5062 7.53859 11.4819 7.59706C11.4542 7.66394 11.3746 7.717 11.2154 7.82311L8.7085 9.49438C8.51586 9.62281 8.41954 9.68702 8.33967 9.68225C8.27009 9.6781 8.20581 9.6437 8.16375 9.5881C8.11548 9.52429 8.11548 9.40853 8.11548 9.177V5.83447Z"
        stroke="#079455"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.73641L13.327 2.21297C13.1636 1.53732 12.5378 1.08688 11.8516 1.14088L9.98257 1.28554L8.91188 0.337535C8.38454 -0.112434 7.61732 -0.112434 7.08816 0.337066L6.01745 1.2855L4.14838 1.14085C3.46998 1.09325 2.83598 1.53772 2.68995 2.15754C2.65973 2.25147 2.11388 3.94819 2.14932 3.83797L0.671758 4.71322C0.0798834 5.07669 -0.157242 5.80635 0.10779 6.44816L0.745446 7.99357L0.108258 9.53854C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7738L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53854L15.2544 7.9936L15.8925 6.44772C16.1571 5.80641 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1563C5.15685 13.1563 2.84376 10.8432 2.84376 8C2.84376 5.15685 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15685 13.1563 8C13.1563 10.8432 10.8432 13.1563 8.00001 13.1563Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductWaiting = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.8285 4.71372L14.195 3.73641L13.827 2.21297C13.6636 1.53732 13.0378 1.08688 12.3516 1.14088L10.4826 1.28554L9.41188 0.337535C8.88454 -0.112434 8.11732 -0.112434 7.58816 0.337066L6.51745 1.2855L4.64838 1.14085C3.96998 1.09325 3.33598 1.53772 3.18995 2.15754C3.15973 2.25147 2.61388 3.94819 2.64932 3.83797L1.17176 4.71322C0.579883 5.07669 0.342758 5.80635 0.60779 6.44816L1.24545 7.99357L0.608258 9.53854C0.342758 10.1808 0.580321 10.91 1.17176 11.273L2.80357 12.2435L3.17298 13.7738C3.33548 14.449 3.95851 14.8971 4.64835 14.8459L6.49726 14.7031L7.5881 15.6622C7.85226 15.8874 8.17591 16 8.50001 16C8.82363 16 9.14776 15.8874 9.41188 15.6622L10.5027 14.703L12.3516 14.8458C13.0502 14.9012 13.6645 14.4494 13.827 13.7738L14.195 12.2503L15.8285 11.273C16.4195 10.91 16.6566 10.1808 16.392 9.53854L15.7544 7.9936L16.3925 6.44772C16.6571 5.80641 16.4199 5.07672 15.8285 4.71372ZM8.50001 13.1563C5.65685 13.1563 3.34376 10.8432 3.34376 8C3.34376 5.15685 5.65685 2.84375 8.50001 2.84375C11.3432 2.84375 13.6563 5.15685 13.6563 8C13.6563 10.8432 11.3432 13.1563 8.50001 13.1563Z"
        fill="white"
      />
      <path
        d="M8.5 3.78125C6.17366 3.78125 4.28125 5.67366 4.28125 8C4.28125 10.3263 6.17366 12.2188 8.5 12.2188C10.8263 12.2188 12.7188 10.3263 12.7188 8C12.7188 5.67366 10.8263 3.78125 8.5 3.78125ZM11.1752 6.92516L8.36266 9.73766C8.27109 9.82922 8.15116 9.875 8.03125 9.875C7.91134 9.875 7.79138 9.82922 7.69984 9.73766L5.82484 7.86266C5.64175 7.67956 5.64175 7.38291 5.82484 7.19981C6.00794 7.01672 6.30459 7.01672 6.48769 7.19981L8.03125 8.74341L10.5123 6.26231C10.6954 6.07922 10.9921 6.07922 11.1752 6.26231C11.3583 6.44541 11.3583 6.74206 11.1752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductCompleted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.6615 4.71372L14.028 3.73641L13.66 2.21297C13.4966 1.53732 12.8708 1.08688 12.1846 1.14088L10.3156 1.28554L9.24489 0.337535C8.71755 -0.112434 7.95033 -0.112434 7.42117 0.337066L6.35045 1.2855L4.48139 1.14085C3.80299 1.09325 3.16898 1.53772 3.02295 2.15754C2.99273 2.25147 2.44689 3.94819 2.48233 3.83797L1.00477 4.71322C0.412891 5.07669 0.175766 5.80635 0.440797 6.44816L1.07845 7.99357L0.441266 9.53854C0.175766 10.1808 0.413329 10.91 1.00477 11.273L2.63658 12.2435L3.00599 13.7738C3.16849 14.449 3.79152 14.8971 4.48136 14.8459L6.33027 14.7031L7.42111 15.6622C7.68527 15.8874 8.00892 16 8.33302 16C8.65664 16 8.98077 15.8874 9.24489 15.6622L10.3357 14.703L12.1846 14.8458C12.8832 14.9012 13.4975 14.4494 13.66 13.7738L14.028 12.2503L15.6615 11.273C16.2525 10.91 16.4896 10.1808 16.225 9.53854L15.5874 7.9936L16.2255 6.44772C16.4901 5.80641 16.253 5.07672 15.6615 4.71372ZM8.33302 13.1563C5.48986 13.1563 3.17677 10.8432 3.17677 8C3.17677 5.15685 5.48986 2.84375 8.33302 2.84375C11.1762 2.84375 13.4893 5.15685 13.4893 8C13.4893 10.8432 11.1762 13.1563 8.33302 13.1563Z"
        fill="white"
      />
      <path
        d="M8.33301 3.78125C6.00666 3.78125 4.11426 5.67366 4.11426 8C4.11426 10.3263 6.00666 12.2188 8.33301 12.2188C10.6594 12.2188 12.5518 10.3263 12.5518 8C12.5518 5.67366 10.6594 3.78125 8.33301 3.78125ZM11.0082 6.92516L8.19566 9.73766C8.1041 9.82922 7.98416 9.875 7.86426 9.875C7.74435 9.875 7.62438 9.82922 7.53285 9.73766L5.65785 7.86266C5.47476 7.67956 5.47476 7.38291 5.65785 7.19981C5.84095 7.01672 6.1376 7.01672 6.3207 7.19981L7.86426 8.74341L10.3454 6.26231C10.5284 6.07922 10.8251 6.07922 11.0082 6.26231C11.1913 6.44541 11.1913 6.74206 11.0082 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductRejected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.6615 4.71372L14.028 3.73641L13.66 2.21297C13.4966 1.53732 12.8708 1.08688 12.1846 1.14088L10.3156 1.28554L9.24489 0.337535C8.71755 -0.112434 7.95033 -0.112434 7.42117 0.337066L6.35045 1.2855L4.48139 1.14085C3.80299 1.09325 3.16898 1.53772 3.02295 2.15754C2.99273 2.25147 2.44689 3.94819 2.48233 3.83797L1.00477 4.71322C0.412891 5.07669 0.175766 5.80635 0.440797 6.44816L1.07845 7.99357L0.441266 9.53854C0.175766 10.1808 0.413329 10.91 1.00477 11.273L2.63658 12.2435L3.00599 13.7738C3.16849 14.449 3.79152 14.8971 4.48136 14.8459L6.33027 14.7031L7.42111 15.6622C7.68527 15.8874 8.00892 16 8.33302 16C8.65664 16 8.98077 15.8874 9.24489 15.6622L10.3357 14.703L12.1846 14.8458C12.8832 14.9012 13.4975 14.4494 13.66 13.7738L14.028 12.2503L15.6615 11.273C16.2525 10.91 16.4896 10.1808 16.225 9.53854L15.5874 7.9936L16.2255 6.44772C16.4901 5.80641 16.253 5.07672 15.6615 4.71372ZM8.33302 13.1563C5.48986 13.1563 3.17677 10.8432 3.17677 8C3.17677 5.15685 5.48986 2.84375 8.33302 2.84375C11.1762 2.84375 13.4893 5.15685 13.4893 8C13.4893 10.8432 11.1762 13.1563 8.33302 13.1563Z"
        fill="white"
      />
      <path
        d="M8.33301 3.78125C6.00666 3.78125 4.11426 5.67366 4.11426 8C4.11426 10.3263 6.00666 12.2188 8.33301 12.2188C10.6594 12.2188 12.5518 10.3263 12.5518 8C12.5518 5.67366 10.6594 3.78125 8.33301 3.78125ZM11.0082 6.92516L8.19566 9.73766C8.1041 9.82922 7.98416 9.875 7.86426 9.875C7.74435 9.875 7.62438 9.82922 7.53285 9.73766L5.65785 7.86266C5.47476 7.67956 5.47476 7.38291 5.65785 7.19981C5.84095 7.01672 6.1376 7.01672 6.3207 7.19981L7.86426 8.74341L10.3454 6.26231C10.5284 6.07922 10.8251 6.07922 11.0082 6.26231C11.1913 6.44541 11.1913 6.74206 11.0082 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductControl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.7364L13.327 2.21297C13.1636 1.53731 12.5378 1.08687 11.8516 1.14087L9.98257 1.28553L8.91188 0.337528C8.38454 -0.112441 7.61732 -0.112441 7.08816 0.337059L6.01745 1.2855L4.14838 1.14084C3.46998 1.09325 2.83598 1.53771 2.68995 2.15753C2.65973 2.25146 2.11388 3.94818 2.14932 3.83797L0.671758 4.71321C0.0798834 5.07668 -0.157242 5.80634 0.10779 6.44815L0.745446 7.99356L0.108258 9.53853C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7737L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53853L15.2544 7.99359L15.8925 6.44771C16.1571 5.8064 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1562C5.15685 13.1562 2.84376 10.8432 2.84376 8C2.84376 5.15684 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15684 13.1563 8C13.1563 10.8432 10.8432 13.1562 8.00001 13.1562Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const TimeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.66667 4.00016V8.00016L11.3333 9.3335M15.3333 8.00016C15.3333 11.6821 12.3486 14.6668 8.66667 14.6668C4.98477 14.6668 2 11.6821 2 8.00016C2 4.31826 4.98477 1.3335 8.66667 1.3335C12.3486 1.3335 15.3333 4.31826 15.3333 8.00016Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
