import React, { useEffect, useRef, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { QuantityIcon } from "../../../assets/icons/stepsIcons";
import {
  PencilIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../../assets/icons/productIcons";
import moment from "moment";
import { orderService } from "../../../services/order.service";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { MenuIcon } from "../../../assets/icons/PersonIcons";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import OrderEditModal from "../modals/OrderEditModal";
import { CustomModal } from "../../../components";
const NewOrderCard = ({
  t,
  order,
  qrFunction,
  showMenuOptions = true,
  refetch,
}) => {
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    {
      label: t("product:orderDetail"),
      value: "details",
      icon: <DetailIcon />,
    },
    {
      label: t("product:orderEdit"),
      value: "edit",
      icon: <PencilIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
    { label: t("product:getQrCodes"), value: "qr", icon: <QrCode /> },
  ];

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const onDeleted = async () => {
    await orderService.orderDelete(deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId("");
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      data-tooltip-id={`${order?.id}`}
      data-tooltip-content={`
      ${t("product:productName")} : ${order?.product?.name.slice(0,3)+"***"} 
       -
      ${t("product:orderId")} : ${order?.orderNo.slice(0,3)+"***"}
      `}
      ref={menuRef}
      className="flex w-[324px] min-w-[324px] max-w-[324px] h-full border shadow-sm rounded-lg pl-3 pr-2 py-2 gap-x-3 group relative"
    >
      <div className="flex flex-col gap-y-[2px]">
        <div className="flex gap-x-3">
          <img
            className="flex border border-[#0000001A] blur-sm max-w-[64px] min-w-[64px] max-h-[48px] min-h-[48px] rounded"
            src={generateFileUrl(order?.product?.image)}
          />
          <div className="flex flex-col h-[48px] w-[132px]">
            <span className="text-[#101828] text-md font-semibold truncate">
              {order?.product?.name?.slice(0, 3) + " ***"}
            </span>
            <span className="text-[#475467] text-sm font-normal truncate">
              {t("product:orId")}: {order?.orderNo?.slice(0, 3) + " ***"}
            </span>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full border-b flex gap-x-[2px] items-center py-[2px] pr-[6px]">
            <div className="flex gap-x-1 items-center">
              <QuantityIcon />
              <span className="text-[#475467] text-xs font-medium">
                {order?.piece}
              </span>
            </div>
            <span className="text-[#667085] text-xxs">
              {t("product:orderQuantitys")}
            </span>
          </div>
          <div className="flex w-full">
            <div className="flex w-full gap-x-[2px] items-center pr-[5px] py-[2px]">
              <div className="flex gap-x-1">
                <CalenderIcon />
                <span className="text-xs font-medium text-[#475467]">
                  {moment(order?.deliveryDate).format("DD.MM.YY")}
                </span>
              </div>
              <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                {t("product:deliveryDate")}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* kalite yeni ui */}
      {/* <div className="flex flex-col border border-secondary-200 bg-white  w-[84px] min-w-[84px] min-h-[76px] max-h-[76px] h-[76px]  rounded">
          <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
            <QualityIcon />
            <p className="text-secondary-700 font-semibold text-[10px]">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex flex-col  items-center justify-center">
            <div className="flex gap-x-[2px] p-1 items-center">
              <QuantityAcceptedIcon />
              <span className="text-xs font-medium text-[#079455]">
                {order?.finishedPiece}
              </span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
            <div className="flex gap-x-[2px] p-1 items-center">
              <QuantityDeclineIcon />
              <span className="text-xs font-medium text-[#D92D20]">
                {order?.rejectedPiece}
              </span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
          </div>
        </div> */}
      <div className="flex px-[6px] py-[2px] w-[84px] max-w-[84px] min-w-[84px] min-h-[76px] max-h-[76px] h-[76px] border border-[#D0D5DD] shadow-xs rounded flex-col gap-y-2">
        <div className="flex gap-x-1 items-center justify-center">
          <span className="w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[#17B26A] rounded-[2px]"></span>
          <p className="text-[#475467] font-normal text-xs">
            {t("reports:quality")}
          </p>
        </div>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-[2px] items-center">
            <QuantityAcceptedIcon />
            <span className="text-xs text-[#079455]">
              {order?.finishedPiece}
            </span>
            <span className="text-[10px] leading-[18px] text-[#667085]">
              {t("product:pcs")}
            </span>
          </div>
          <div className="flex gap-x-[2px] items-center">
            <QuantityDeclineIcon />
            <span className="text-xs text-[#D92D20]">
              {order?.rejectedPiece}
            </span>
            <span className="text-[10px] leading-[18px] text-[#667085]">
              {t("product:pcs")}
            </span>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`min-w-[20px] absolute bottom-1 right-3 h-[20px] cursor-pointer hidden  ${
          showMenuOptions ? "group-hover:flex" : "hidden"
        }`}
      >
        <MenuIcon />
      </div>
      {isOpen && (
        <div className="absolute w-[190px] p-1 bg-white right-3 top-[104px] border border-secondary-200 rounded-lg shadow-sm flex flex-col z-[100]">
          {menuOptions?.map((item, index) => {
            return (
              <button
                onClick={() => {
                  if (item.value === "delete") {
                    // setIsAlert(true);
                    // setDeleteId(order?.id);
                    Toast("warning", t("alert:warning"));
                  } else if (item.value === "details") {
                    // navigate(`/app/product/order/edit/${order?.id}`);
                    Toast("warning", t("alert:warning"));
                  } else if (item.value === "qr") {
                    // return qrFunction(order);
                    Toast("warning", t("alert:warning"));
                  } else if (item.value === "edit") {
                    // setEditModal(true);
                    Toast("warning", t("alert:warning"));
                  }
                  // setIsOpen(false);
                }}
                key={index}
                type="button"
                className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
              >
                {item?.icon}
                <p
                  className={`font-medium text-sm ${
                    index == 2 ? "text-[#D92D20]" : "text-secondary-700"
                  }  ml-1`}
                >
                  {index === "taskList" ? "" : item.label}
                </p>
              </button>
            );
          })}
        </div>
      )}
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
          // Toast("warning", t("alert:warning"))
        }}
      />
      <Tooltip className="z-50" position={"top"} id={`${order?.id}`} />
      <CustomModal
        isOpen={editModal}
        setIsOpen={setEditModal}
        onClose={() => {
          setEditModal(false);
        }}
        modalTitle={t("product:createNewOrder")}
        children={
          <OrderEditModal
            selectedProduct={order?.product}
            setIsOpen={setEditModal}
            orderData={order}
            refresh={refetch}
            editData={order}
            t={t}
          />
        }
      />
    </div>
  );
};

export default NewOrderCard;

const CalenderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66634H2M10.6667 1.33301V3.99967M5.33333 1.33301V3.99967M5.2 14.6663H10.8C11.9201 14.6663 12.4802 14.6663 12.908 14.4484C13.2843 14.2566 13.5903 13.9506 13.782 13.5743C14 13.1465 14 12.5864 14 11.4663V5.86634C14 4.74624 14 4.18618 13.782 3.75836C13.5903 3.38204 13.2843 3.07607 12.908 2.88433C12.4802 2.66634 11.9201 2.66634 10.8 2.66634H5.2C4.0799 2.66634 3.51984 2.66634 3.09202 2.88433C2.71569 3.07607 2.40973 3.38204 2.21799 3.75836C2 4.18618 2 4.74624 2 5.86634V11.4663C2 12.5864 2 13.1465 2.21799 13.5743C2.40973 13.9506 2.71569 14.2566 3.09202 14.4484C3.51984 14.6663 4.0799 14.6663 5.2 14.6663Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const QualityIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g mask="url(#mask0_13331_70317)">
        <path
          d="M11.1016 7C11.1016 9.26524 9.26524 11.1016 7 11.1016C4.73476 11.1016 2.89844 9.26524 2.89844 7C2.89844 4.73476 4.73476 2.89844 7 2.89844C9.26524 2.89844 11.1016 4.73476 11.1016 7Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.05078 5.76953L6.58984 8.23047L4.94922 6.58984"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.46805 0.606006C6.77463 0.344873 7.22537 0.344873 7.53194 0.606006L8.59789 1.54677L10.4017 1.40718C10.8032 1.37612 11.1679 1.64105 11.2624 2.0325L11.6241 3.53028L13.2001 4.4737C13.5432 4.68457 13.6825 5.11327 13.5289 5.4855L12.9062 6.99424L13.5289 8.50299C13.6825 8.87525 13.5432 9.30394 13.2001 9.51482L11.6241 10.4582L11.2624 11.956C11.1679 12.3474 10.8032 12.6124 10.4017 12.5813L8.61552 12.4431L7.53194 13.394C7.22537 13.6551 6.77463 13.6551 6.46805 13.394L5.38448 12.4431L3.59827 12.5813C3.19676 12.6124 2.83207 12.3474 2.73757 11.956L2.37448 10.4523L0.801062 9.51482C0.457953 9.30394 0.318664 8.87525 0.472308 8.50299L1.09493 6.99424L0.472308 5.4855C0.318664 5.11327 0.457953 4.68457 0.801062 4.4737L2.23962 3.61907L2.73757 2.0325C2.83207 1.64105 3.19676 1.37612 3.59827 1.40718L5.40214 1.54677L6.46805 0.606006Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

const DetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 7.99992L6 7.99992M14 3.99992L6 3.99992M14 11.9999L6 11.9999M3.33333 7.99992C3.33333 8.36811 3.03486 8.66659 2.66667 8.66659C2.29848 8.66659 2 8.36811 2 7.99992C2 7.63173 2.29848 7.33325 2.66667 7.33325C3.03486 7.33325 3.33333 7.63173 3.33333 7.99992ZM3.33333 3.99992C3.33333 4.36811 3.03486 4.66659 2.66667 4.66659C2.29848 4.66659 2 4.36811 2 3.99992C2 3.63173 2.29848 3.33325 2.66667 3.33325C3.03486 3.33325 3.33333 3.63173 3.33333 3.99992ZM3.33333 11.9999C3.33333 12.3681 3.03486 12.6666 2.66667 12.6666C2.29848 12.6666 2 12.3681 2 11.9999C2 11.6317 2.29848 11.3333 2.66667 11.3333C3.03486 11.3333 3.33333 11.6317 3.33333 11.9999Z"
        stroke="#667085"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.6667 3.99992V3.46659C10.6667 2.71985 10.6667 2.34648 10.5213 2.06126C10.3935 1.81038 10.1895 1.60641 9.93865 1.47858C9.65344 1.33325 9.28007 1.33325 8.53333 1.33325H7.46667C6.71993 1.33325 6.34656 1.33325 6.06135 1.47858C5.81046 1.60641 5.60649 1.81038 5.47866 2.06126C5.33333 2.34648 5.33333 2.71985 5.33333 3.46659V3.99992M6.66667 7.66659V10.9999M9.33333 7.66659V10.9999M2 3.99992H14M12.6667 3.99992V11.4666C12.6667 12.5867 12.6667 13.1467 12.4487 13.5746C12.2569 13.9509 11.951 14.2569 11.5746 14.4486C11.1468 14.6666 10.5868 14.6666 9.46667 14.6666H6.53333C5.41323 14.6666 4.85318 14.6666 4.42535 14.4486C4.04903 14.2569 3.74307 13.9509 3.55132 13.5746C3.33333 13.1467 3.33333 12.5867 3.33333 11.4666V3.99992"
        stroke="#D92D20"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QrCode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.33333 4.33333H4.34M11.6667 4.33333H11.6733M4.33333 11.6667H4.34M8.66667 8.66667H8.67333M11.6667 11.6667H11.6733M11.3333 14H14V11.3333M9.33333 11V14M14 9.33333H11M10.4 6.66667H12.9333C13.3067 6.66667 13.4934 6.66667 13.636 6.594C13.7614 6.53009 13.8634 6.4281 13.9273 6.30266C14 6.16005 14 5.97337 14 5.6V3.06667C14 2.6933 14 2.50661 13.9273 2.36401C13.8634 2.23856 13.7614 2.13658 13.636 2.07266C13.4934 2 13.3067 2 12.9333 2H10.4C10.0266 2 9.83995 2 9.69734 2.07266C9.5719 2.13658 9.46991 2.23856 9.406 2.36401C9.33333 2.50661 9.33333 2.6933 9.33333 3.06667V5.6C9.33333 5.97337 9.33333 6.16005 9.406 6.30266C9.46991 6.4281 9.5719 6.53009 9.69734 6.594C9.83995 6.66667 10.0266 6.66667 10.4 6.66667ZM3.06667 6.66667H5.6C5.97337 6.66667 6.16005 6.66667 6.30266 6.594C6.4281 6.53009 6.53009 6.4281 6.594 6.30266C6.66667 6.16005 6.66667 5.97337 6.66667 5.6V3.06667C6.66667 2.6933 6.66667 2.50661 6.594 2.36401C6.53009 2.23856 6.4281 2.13658 6.30266 2.07266C6.16005 2 5.97337 2 5.6 2H3.06667C2.6933 2 2.50661 2 2.36401 2.07266C2.23856 2.13658 2.13658 2.23856 2.07266 2.36401C2 2.50661 2 2.6933 2 3.06667V5.6C2 5.97337 2 6.16005 2.07266 6.30266C2.13658 6.4281 2.23856 6.53009 2.36401 6.594C2.50661 6.66667 2.6933 6.66667 3.06667 6.66667ZM3.06667 14H5.6C5.97337 14 6.16005 14 6.30266 13.9273C6.4281 13.8634 6.53009 13.7614 6.594 13.636C6.66667 13.4934 6.66667 13.3067 6.66667 12.9333V10.4C6.66667 10.0266 6.66667 9.83995 6.594 9.69734C6.53009 9.5719 6.4281 9.46991 6.30266 9.406C6.16005 9.33333 5.97337 9.33333 5.6 9.33333H3.06667C2.6933 9.33333 2.50661 9.33333 2.36401 9.406C2.23856 9.46991 2.13658 9.5719 2.07266 9.69734C2 9.83995 2 10.0266 2 10.4V12.9333C2 13.3067 2 13.4934 2.07266 13.636C2.13658 13.7614 2.23856 13.8634 2.36401 13.9273C2.50661 14 2.6933 14 3.06667 14Z"
        stroke="#667085"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
