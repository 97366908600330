import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ClockIcon,
  PurchaseIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../../assets/icons/productIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";

export default function PurchaseAvgCard({
  status,
  operations,
  iconKey,
  source = "in",
}) {
  const { t } = useTranslation();

  const qualityStatus = () => {
    if (operations?.hasQualityControl) {
      return "#17B26A";
    } else {
      return "#98A2B3";
    }
  };

  return (
    <div className="flex h-[112px] min-h-[112px] max-h-[112px] min-w-[296px] border shadow-sm rounded-lg flex-col gap-y-[2px]">
      <div className="flex w-full h-full pl-3 pr-1 py-1 gap-x-2 rounded-b-lg">
        <div className="flex flex-col w-full h-full">
          <div className="w-full flex items-center overflow-hidden">
            <div className="w-4 h-4 flex items-center justify-center">
              <PurchaseIcon />
            </div>
            <p className="text-sm text-secondary-700 font-semibold truncate ml-1.5">
              {t("product:purchasing")}
            </p>
          </div>
          <div className="flex flex-col">
            <div className="flex border-b">
              <div className="w-1/2 border-r pr-[6px] py-1 text-xs font-normal">
                {t("product:orderQuantity")}
              </div>
              <div className="w-1/2 px-[6px] py-1 flex gap-x-[2px] items-end">
                <span className="font-medium text-xs text-[#475467]">
                  15.324
                </span>
                <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
            <div className="flex border-b">
              <div className="w-1/2 border-r pr-[6px] py-1 text-xs font-normal">
                {t("product:rawMaterialTypess")}
              </div>
              <div className="w-1/2 px-[6px] py-1 flex gap-x-[2px] items-end">
                <span className="font-medium text-xs text-[#475467]">
                  15.324
                </span>
                <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/2 border-r pr-[6px] py-1 text-xs font-normal">
                {t("product:rawMaterialSourcess")}
              </div>
              <div className="w-1/2 pl-[6px] py-1 flex">
                <span className="font-medium text-xs text-[#475467]">
                  {source === "out"
                    ? t("product:purchasedRawMaterial")
                    : source === "in"
                    ? t("product:rawMaterialCompanys")
                    : "---"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col border-[0.5px] w-[82px] h-[68px] px-[6px] py-[2px] gap-y-2 rounded">
          <div className="flex gap-x-1 items-center justify-center">
            <span className="w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[#17B26A] rounded-[2px]"></span>
            <p className="text-[#475467] font-normal text-xs">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex flex-col gap-y-[2px]">
            <div className="flex gap-x-[2px] items-center">
              <QuantityAcceptedIcon />
              <span className="text-xs text-[#079455]">11.632</span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
            <div className="flex gap-x-[2px] items-center">
              <QuantityDeclineIcon />
              <span className="text-xs text-[#D92D20]">11.632</span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
          </div>
        </div>

        {/* kalite yeni ui */}
        {/* <div className="flex flex-col border border-secondary-200 bg-white w-[86px] min-w-[86px]  h-[72px] rounded">
          <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
            <QualityIcon />
            <p className="text-secondary-700 font-semibold text-[10px]">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex flex-col  items-center justify-center">
            <div className="flex gap-x-[2px] p-1 items-center">
              <QuantityAcceptedIcon />
              <span className="text-xs font-medium text-[#079455]">11.632</span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
            <div className="flex gap-x-[2px] p-1 items-center">
              <QuantityDeclineIcon />
              <span className="text-xs font-medium text-[#D92D20]">11.632</span>
              <span className="text-[10px] leading-[18px] text-[#667085]">
                {t("product:pcs")}
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

const QualityIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g mask="url(#mask0_13331_70317)">
        <path
          d="M11.1016 7C11.1016 9.26524 9.26524 11.1016 7 11.1016C4.73476 11.1016 2.89844 9.26524 2.89844 7C2.89844 4.73476 4.73476 2.89844 7 2.89844C9.26524 2.89844 11.1016 4.73476 11.1016 7Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.05078 5.76953L6.58984 8.23047L4.94922 6.58984"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.46805 0.606006C6.77463 0.344873 7.22537 0.344873 7.53194 0.606006L8.59789 1.54677L10.4017 1.40718C10.8032 1.37612 11.1679 1.64105 11.2624 2.0325L11.6241 3.53028L13.2001 4.4737C13.5432 4.68457 13.6825 5.11327 13.5289 5.4855L12.9062 6.99424L13.5289 8.50299C13.6825 8.87525 13.5432 9.30394 13.2001 9.51482L11.6241 10.4582L11.2624 11.956C11.1679 12.3474 10.8032 12.6124 10.4017 12.5813L8.61552 12.4431L7.53194 13.394C7.22537 13.6551 6.77463 13.6551 6.46805 13.394L5.38448 12.4431L3.59827 12.5813C3.19676 12.6124 2.83207 12.3474 2.73757 11.956L2.37448 10.4523L0.801062 9.51482C0.457953 9.30394 0.318664 8.87525 0.472308 8.50299L1.09493 6.99424L0.472308 5.4855C0.318664 5.11327 0.457953 4.68457 0.801062 4.4737L2.23962 3.61907L2.73757 2.0325C2.83207 1.64105 3.19676 1.37612 3.59827 1.40718L5.40214 1.54677L6.46805 0.606006Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};
