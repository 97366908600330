import { useState } from "react";
import Linkify from "react-linkify";
import { usePubNub } from "pubnub-react";
import moment from "moment";
import { AttachmentIcon } from "../../../assets/icons/PersonIcons";
import { chatStore } from "../../../stores/chat.store";
import { authStore } from "../../../stores/auth.store";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";

const ImagePreviewerSend = ({
  sendedAt,
  item,
  sendedBy,
  setVisible,
  setVisibleImage,
}) => {
  const [imageDimensions, setImageDimensions] = useState({
    width: 200,
    height: 200,
  });
  const [resizeMode, setResizeMode] = useState("contain");

  const onImageLoad = (event) => {
    const { width, height } = event.target;
    if (width > height) {
      setImageDimensions({ width, height });
      setResizeMode("contain");
    } else {
      setImageDimensions({ width: 200, height: 320 });
      setResizeMode("cover");
    }
  };

  if (item.type === "image")
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          setVisibleImage(item.urls);
          setVisible(true);
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginBottom: 16,
          borderWidth: 1,
          borderColor: "#EAECF0",
          borderRadius: 12,
          borderTopLeftRadius: 0,
          overflow: "hidden",
          paddingTop: 4,
          backgroundColor: "#F2F4F7",
          minWidth: `${imageDimensions.width}px`,
          maxWidth: "230px",
          minHeight: `${imageDimensions.height + 20}px`,
        }}
      >
        <p
          style={{
            color: "#667085",
            marginLeft: "auto",
            fontWeight: "600",
            marginRight: 8,
            marginBottom: 6,
            fontSize: 10,
          }}
        >
          {moment(new Date()).format("DD.MM.YYYY") ===
          String(moment.unix(sendedAt / 10000000).format("DD.MM.YYYY"))
            ? `Bugün - ${moment.unix(sendedAt / 10000000).format("hh:mm")}`
            : String(moment.unix(sendedAt / 10000000).format("MM:HH DD.MM.YY"))}
        </p>

        <img
          onLoad={onImageLoad}
          style={{
            objectFit: resizeMode,
            filter: "blur(4px)"
          }}
          src={generateFileUrl(item.urls)}
        />
      </div>
    );
};

const ImagePreviewerGet = ({
  sendedAt,
  item,
  sendedBy,
  setVisible,
  setVisibleImage,
  sendedByImage,
}) => {
  const [imageDimensions, setImageDimensions] = useState({
    width: 200,
    height: 200,
  });
  const [resizeMode, setResizeMode] = useState("contain");

  const onImageLoad = (event) => {
    const { width, height } = event.target;
    if (width > height) {
      setImageDimensions({ width, height });
      setResizeMode("contain");
    } else {
      setImageDimensions({ width: 200, height: 320 });
      setResizeMode("cover");
    }
  };
  if (item.type === "image")
    return (
      <div className="flex">
        {sendedBy && (
          <div style={{ width: 28, height: 28, filter: "blur(4px)" }}>
            {sendedByImage ? (
              <img
                src={generateFileUrl(sendedByImage)}
                style={{ width: 28, height: 28, borderRadius: 28, filter: "blur(4px)" }}
              />
            ) : (
              <NoAvatar
                name={sendedBy}
                size={28}
                fontSize={12}
                bgColor={"#107569"}
              />
            )}
          </div>
        )}
        <div
          onClick={() => {
            // setVisibleImage(item.urls);
            // setVisible(true);
          }}
          className="cursor-pointer"
          style={{
            flexDirection: "column",
            marginRight: "auto",
            marginBottom: 16,
            borderWidth: 1,
            borderColor: "#EAECF0",
            borderRadius: 12,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 12,
            overflow: "hidden",
            paddingTop: 6,
            backgroundColor: "#F2F4F7",
            marginLeft: 4,
            display: "flex",
            minWidth: `${imageDimensions.width}px`,
            maxWidth: "230px",
            minHeight: `${imageDimensions.height + 20}px`,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 6,
              width: "100%",
              display: "flex",
            }}
          >
            {sendedBy ? (
              <span
                style={{
                  color: "#0E9384",
                  fontSize: 10,
                  marginBottom: 4,
                  marginTop: 2,
                  marginLeft: 8,
                }}
              >
                {String(sendedBy)}
              </span>
            ) : null}
            <p
              style={{
                marginLeft: "auto",
                color: "#667085",
                fontWeight: "500",
                marginBottom: 4,
                fontSize: 10,
                textAlign: "right",
                marginRight: 8,
              }}
            >
              {moment.unix(sendedAt / 10000000).format("MM:HH - DD.MM.YY")}
            </p>
          </div>

          <img
            onLoad={onImageLoad}
            style={{
              objectFit: resizeMode,
            }}
            src={generateFileUrl(item.urls)}
          />
        </div>
      </div>
    );
};

const GetElement = ({ text, sendedAt, sendedBy, sendedByImage }) => {
  return (
    <div
      style={{
        marginBottom: 12,
        marginRight: "auto",
        maxWidth: "80%",
        minWidth: sendedBy ? "60%" : "44%",
      }}
      className="flex"
    >
      {sendedBy && (
        <>
          {sendedByImage ? (
            <img
              className="w-7 min-w-[28px] blur-sm h-7 rounded-full mr-1 mt-0.5"
              src={generateFileUrl(sendedByImage)}
            />
          ) : (
            <div
              className={
                "w-7 min-w-[28px] h-7 blur-sm rounded-full mr-1 bg-teal-700 flex items-center justify-center text-white text-[11px] font-medium mt-0.5"
              }
            >
              {sendedBy?.charAt(0).toUpperCase()}
            </div>
          )}
        </>
      )}
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="blank"
            href={decoratedHref}
            key={key}
            style={{ textDecorationLine: "underline", color: "#1570EF" }}
          >
            {decoratedText}
          </a>
        )}
      >
        <div
          className="rounded-r-lg rounded-b-lg max-w-[400px] min-w-[200px] overflow-hidden"
          style={{
            backgroundColor: "#F2F4F7",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div className="p-2 px-2.5 flex flex-col w-full">
            {sendedBy ? (
              <p
                className="truncate font-medium"
                style={{
                  color: "#107569",
                  fontSize: 12,
                  marginRight: 12,
                  marginBottom: 2,
                }}
              >
                {String(sendedBy)}
              </p>
            ) : null}

            <p
              className="text-sm text-gray-900"
              style={{ wordWrap: "break-word" }}
            >
              {text}
            </p>

            <p
              style={{
                color:
                  localStorage.color_theme === "dark" ? "#D6D6D6" : "#667085",
                fontSize: 10,
                marginLeft: "auto",
                marginTop: 2,
              }}
              className="truncate"
            >
              {moment(new Date()).format("DD.MM.YYYY") ===
              String(moment.unix(sendedAt / 10000000).format("DD.MM.YYYY"))
                ? `Bugün - ${moment.unix(sendedAt / 10000000).format("HH:mm")}`
                : String(
                    moment.unix(sendedAt / 10000000).format("HH:mm - DD.MM.YY")
                  )}
            </p>
          </div>
        </div>
      </Linkify>
    </div>
  );
};

const SendElement = ({ text, sendedAt, sendedBy }) => {
  return (
    <div
      style={{
        marginBottom: 12,
        marginLeft: "auto",
      }}
    >
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="blank"
            href={decoratedHref}
            key={key}
            style={{ textDecorationLine: "underline" }}
            className="hover:text-[#53B1FD]"
          >
            {decoratedText}
          </a>
        )}
      >
        <div
          className="rounded-l-lg rounded-b-lg max-w-[400px] min-w-[200px] overflow-hidden"
          style={{
            backgroundColor: "#0E9384",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div className="p-2 px-2.5 flex flex-col w-full">
            <p
              className="text-sm text-white"
              style={{ wordWrap: "break-word" }}
            >
              {text}
            </p>

            <p
              style={{
                color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#fff",
                fontSize: 10,
                marginLeft: "auto",
              }}
            >
              {moment(new Date()).format("DD.MM.YYYY") ===
              String(moment.unix(sendedAt / 10000000).format("DD.MM.YYYY"))
                ? `Bugün - ${moment.unix(sendedAt / 10000000).format("HH:mm")}`
                : String(
                    moment.unix(sendedAt / 10000000).format("HH:mm - DD.MM.YY")
                  )}
            </p>
          </div>
        </div>
      </Linkify>
    </div>
  );
};

const FileElement = ({
  isGet,
  url,
  fileName,
  sendedAt,
  size,
  sendedBy,
  sendedByImage,
}) => {
  if (size)
    return (
      <div className="w-full min-h-[54px] max-h-[54px] mb-3 flex">
        {sendedBy && (
          <>
            {sendedByImage ? (
              <img
                className="w-7 min-w-[28px] blur-sm h-7 rounded-full mr-1 mt-0.5"
                src={generateFileUrl(sendedByImage)}
              />
            ) : (
              <div
                className={
                  "w-7 min-w-[28px] h-7 blur-sm rounded-full mr-1 bg-teal-700 flex items-center justify-center text-white text-[11px] font-medium mt-0.5"
                }
              >
                {sendedBy?.charAt(0).toUpperCase()}
              </div>
            )}
          </>
        )}
        <a
          target="_blank"
          href={`https://cdn.upu.io/${url}`}
          style={{
            marginLeft: !isGet ? "auto" : 0,
            marginRight: isGet ? "auto" : 0,
            borderBottomLeftRadius: 8,
            borderTopRightRadius: isGet ? 8 : 0,
            borderBottomRightRadius: 8,
            borderTopLeftRadius: !isGet ? 8 : 0,
          }}
          className="border border-gray-300 flex max-w-[310px] h-[53px] items-center px-2"
          rel="noreferrer"
        >
          <div className="h-8 w-8 rounded bg-teal-600 text-white flex items-center justify-center">
            <AttachmentIcon />
          </div>
          <div className="flex flex-col ml-2 w-[80%]">
            <p className="text-xs font-medium text-secondary-800 truncate">
              {sendedBy ? (
                <p
                  className="truncate font-medium inline"
                  style={{
                    color: "#107569",
                    fontSize: 12,
                    marginRight: 5,
                    marginBottom: 2,
                  }}
                >
                  {String(sendedBy)} {"-"}
                </p>
              ) : null}
              {fileName}
            </p>
            <div className="flex items-center justify-between mt-1">
              <p className="text-[10px] text-gray-500">
                {parseFloat(size / 1000000).toFixed(2) + " MB"}
              </p>
              <p
                style={{
                  color: "#667085",
                  fontSize: 10,
                }}
              >
                {String(
                  moment.unix(sendedAt / 10000000).format("MM:HH - DD.MM.YY")
                )}
              </p>
            </div>
          </div>
        </a>
      </div>
    );
};

const handleUsername = (uuid) => {
  const user = chatStore.currentGroupChat?.participants?.find(
    (member) => member.id === uuid
  );
  if (user) {
    return user.name + " " + user.lastName;
  } else {
    return "";
  }
};

const handleUserImage = (uuid) => {
  const user = chatStore.currentGroupChat?.participants?.find(
    (member) => member.id === uuid
  );
  if (user) {
    return user.avatar;
  } else {
    return "";
  }
};

export const handleComponent = (item, index) => {
  if (
    String(item.publisher) !== authStore.user.id &&
    String(item.uuid) !== authStore.user.id
  ) {
    if (item?.message?.type === "file") {
      return (
        <FileElement
          fileName={item?.message?.name}
          url={item?.message?.url}
          isGet={true}
          sendedBy={handleUsername(item.uuid || item.publisher)}
          sendedAt={item.timetoken}
          size={item?.message?.size}
          sendedByImage={handleUserImage(item.uuid || item.publisher)}
        />
      );
    }
    if (item?.message?.type === "image") {
      return (
        <ImagePreviewerGet
          setVisible={() => {}}
          setVisibleImage={() => {}}
          sendedAt={item.timetoken}
          item={item.message}
          sendedBy={handleUsername(item.uuid || item.publisher)}
          sendedByImage={handleUserImage(item.uuid || item.publisher)}
        />
      );
    } else {
      return (
        <GetElement
          text={item.message}
          sendedAt={item.timetoken}
          sendedBy={handleUsername(item.uuid || item.publisher)}
          sendedByImage={handleUserImage(item.uuid || item.publisher)}
        />
      );
    }
  } else {
    if (item?.message?.type === "file") {
      return (
        <FileElement
          fileName={item?.message?.name}
          url={item?.message?.url}
          isGet={false}
          sendedAt={item.timetoken}
          size={item?.message?.size}
        />
      );
    } else if (item?.message?.type === "image") {
      return (
        <ImagePreviewerSend
          sendedAt={item.timetoken}
          item={item.message}
          setVisible={() => {}}
          setVisibleImage={() => {}}
        />
      );
    } else {
      return <SendElement text={item.message} sendedAt={item.timetoken} />;
    }
  }
};
