import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { QualityOeeCard, QualityOperationOeeCard } from "../components";
import { useQuery } from "@tanstack/react-query";
import { processService } from "../../../../services";
import { junctionService } from "../../../../services/junction.service";
import moment from "moment";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { useStore } from "../../../../hooks/useStores";
import { purchaseService } from "../../../../services/purchase.service";

const QualityMain = ({ date }) => {
  let navigate = useNavigate();
  const { auth } = useStore();
  const [items, setItems] = useState([]);

  const [internalData, setInternalData] = useState([]);
  const [externalData, setExternalData] = useState([]);
  const [supplyData, setSupplyData] = useState([]);

 

  const getInternalData = async () => {
    await junctionService
      .legacyQualityData({
        date: moment(
          timeFormatter(date, auth?.user?.company?.timeZone)?.formatted
        ).format("YYYY-MM-DD"),
      })

      .then((r) => {
        setInternalData(r);
      });
  };

  const getExternalData = async () => {
    await junctionService
      .legacyQualityExternalData({
        date: moment(
          timeFormatter(date, auth?.user?.company?.timeZone)?.formatted
        ).format("YYYY-MM-DD"),
      })
      .then((r) => {
        setExternalData(r);
      });
  };

  const getSupplyData = async () => {
    return await purchaseService.getPurchaseJunctionsForCompany().then((r) => {
      setSupplyData(r);
    });
  };

  useEffect(() => {
    getInternalData();
    getExternalData();
    getSupplyData();
  }, [date]);

  return (
    <div className="w-full h-full gap-y-4 flex flex-col pt-4 overflow-y-auto overflow-x-hidden scrollbar-hide">
      <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl ">
        <div
          onClick={() => {
            navigate("/app/product/quality-control/internal");
          }}
          className="bg-white rounded-l-xl border-r-[1px] border-[#EAECF0]  w-[292px] min-w-[292px] h-full px-3"
        >
          <QualityOeeCard
            timeMass={0}
            value={0}
            kpi1={0}
            kpi2={0}
            gaugeWidth={200}
            activeLength={internalData?.activeJunction?.length || 0}
            waitingLength={internalData?.pendingJunction?.length || 0}
            gaugeHeight={108}
            gaugeRadius={"160%"}
            name={"İç Operasyon Kalite Kontrol"}
          />
        </div>
        <div className="flex w-full h-full gap-x-4 px-6 items-center overflow-x-auto overflow-y-hidden">
          {internalData?.operations
            ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
            .map((process, index) => {
              return (
                <QualityOperationOeeCard
                  onClick={() => {
                    navigate(`/app/product/quality-control/internal`);
                  }}
                  gaugeWidth={150}
                  gaugeHeight={84}
                  gaugeRadius={"160%"}
                  name={process.name}
                  activeLength={process?.activeJunction}
                  waitingLength={process?.pendingJunction}
                  timeMass={0}
                  value={0}
                  kpi1={0}
                  kpi2={0}
                />
              );
            })}
        </div>
      </div>
      <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl ">
        <div
          onClick={() => {
            navigate("/app/product/quality-control/external");
          }}
          className="bg-white rounded-l-xl border-r-[1px] border-[#EAECF0]  w-[292px] min-w-[292px] h-full px-3"
        >
          <QualityOeeCard
            timeMass={0}
            value={0}
            kpi1={0}
            kpi2={0}
            gaugeWidth={200}
            activeLength={externalData?.activeJunction?.length || 0}
            waitingLength={externalData?.pendingJunction?.length || 0}
            gaugeHeight={108}
            gaugeRadius={"160%"}
            name={"Hizmet Giriş Kalite Kontrol"}
          />
        </div>
        <div className="flex w-full h-full gap-x-4 px-6 items-center overflow-x-auto overflow-y-hidden">
          {externalData?.operations
            ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
            ?.map((process, index) => {
              return (
                <QualityOperationOeeCard
                  gaugeWidth={150}
                  gaugeHeight={84}
                  gaugeRadius={"155%"}
                  name={process.name}
                  // value={process.actual}
                  // kpi1={process.average ? process?.average.toFixed(2) : 0}
                  // kpi2={process.average == 0 ? 0 : process.kpiValue}
                  // timeMass={(process.timeMass / 3600).toFixed(1) || 25000}
                  value={0}
                  kpi1={0}
                  kpi2={0}
                  timeMass={0}
                  activeLength={process?.activeJunction}
                  waitingLength={process?.pendingJunction}
                  onClick={() => {
                    navigate("/app/product/quality-control/external");
                  }}
                />
              );
            })}
        </div>
      </div>
      <div className="min-h-[268px] max-h-[268px] h-[268px] flex w-full bg-[#FCFCFD] border-[1px] border-[#D0D5DD] rounded-xl ">
        <div
          onClick={() => {
            navigate("/app/product/quality-control/supply");
          }}
          className="bg-white rounded-l-xl border-r-[1px] border-[#EAECF0]  w-[292px] min-w-[292px] h-full px-3"
        >
          <QualityOeeCard
            timeMass={0}
            value={0}
            kpi1={0}
            kpi2={0}
            activeLength={supplyData?.activeJunction?.length || 0}
            waitingLength={supplyData?.pendingJunction?.length || 0}
            gaugeWidth={200}
            gaugeHeight={108}
            gaugeRadius={"160%"}
            name={"Malzeme Giriş Kalite Kontrol"}
          />
        </div>
        <div className="flex w-full h-full gap-x-4 px-6 items-center overflow-x-auto overflow-y-hidden">
          {supplyData?.operations
            ?.sort((a, b) => b?.activeJunction - a?.activeJunction)
            ?.map((process, index) => {
              return (
                <QualityOperationOeeCard
                  gaugeWidth={150}
                  gaugeHeight={84}
                  gaugeRadius={"155%"}
                  name={process?.name}
                  activeLength={process?.activeJunction}
                  waitingLength={process?.pendingJunction}
                  timeMass={0}
                  value={0}
                  kpi1={0}
                  kpi2={0}
                  onClick={() => {
                    navigate("/app/product/quality-control/supply");
                  }}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default QualityMain;
