import React, { useState } from "react";
import CustomImage from "../CustomImage";
import NoAvatar from "../avatar/NoAvatar";
import CustomText from "../CustomText";
import { DeleteIcon, DotsVerticalIcon } from "./ActiveAndPlannedCard";
import { EditIcon } from "../../assets/icons/departmentIcon";
import { useTranslation } from "react-i18next";
import JunctionUpdate from "../modal/JunctionUpdate";
import CustomModal from "../modal/CustomModal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import AlertModal from "../alert-Modal/Alert-Modal";
import QualityControlForm from "../modal/QualityControlForm";
import { useNavigate } from "react-router-dom";
import {
  EquipmentDeleteIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  ProductDetailIcon,
  QualityDetailIcon,
} from "../../assets/icons/machineIcons";
import { Tooltip } from "react-tooltip";
import EditJunctionModal from "../modal/EditJunctionModal";
import moment from "moment";

const junctionProps = {
  theme: "",
  ring: null,
  page: "",
  startDate: "",
  time: {
    timeGreen: "",
    timeRed: "",
  },
  product: {
    name: "",
    image: "",
    code: "",
    pieceGreen: 0,
    id: "",
    pieceRed: 0,
  },
  status: "",
  node: {
    name: "",
    id: "",
    image: "",
    process: "",
  },
  user: {
    image: "",
    id: "",
    name: "",
    lastName: "",
  },
  onPressNode: null,
  onPressUser: null,
  onPressProduct: null,
  onPressQuality: null,
  avatarPress: null,
};

const NewFinishedJunctionCard = ({
  props = junctionProps,
  step,
  junctionId,
  refetch,
  statusType,
  pages,
  onPressCard = null,
  allData,
  operation,
  completed,
  qualityPercent,
  externalOperation,
  onClick,
  supplier,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();
  const [isJunction, setIsJunction] = useState(false);
  const [editJunction, setEditJunction] = useState(false);

  const menuOptionsActive = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:finishJuction"),
      value: "finish",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "operationDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptions = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "operationDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];
  const menuOptionsExternalActive = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:finishJuction"),
      value: "finish",
      icon: <ProductDetailIcon />,
    },

    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "operationDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsExternal = [
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },

    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:productDetail"),
      value: "operationDetail",
      icon: <ProductDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const onDeleted = async () => {
    await junctionService.deleteJunction(junctionId, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(junctionId);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div
        onClick={onPressCard}
        className="flex w-full h-[88px] items-center justify-center group relative gap-x-1"
      >
        <div className="flex relative -top-[0px]  flex-col  w-[49.5%] min-w-[49.5%] gap-y-1">
          <div className="flex  flex-row items-center w-full">
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-1 mr-auto">
                <div className="mr-auto">
                  <QualityGreenIcon />
                </div>
                <p className={`text-xs font-normal text-secondary-600 `}>
                  {props?.product?.pieceGreen || "0"}
                </p>
              </div>
              <div className="flex flex-row items-center gap-1 mr-auto">
                <div className="mr-auto">
                  <QualityRedIcon />
                </div>
                <p className={`text-xs font-normal text-secondary-600 `}>
                  {props?.product?.pieceRed || "0"}
                </p>
              </div>
            </div>
            <div
              data-tooltip-id={`${junctionId}`}
              data-tooltip-content={`
        ${props?.product?.name.slice(0, 3) + "***"} 
        `}
              className="ml-auto mr-[35%]"
            >
              <PressedContoller
                theme={props?.theme}
                image={props?.product?.image}
              />
            </div>
          </div>
          <div
            className={`flex h-[40px] min-h-[40px] max-h-[40px] items-center justify-center flex-col border rounded border-t-4`}
            style={{
              borderColor: completed
                ? "#D0D5DD"
                : props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#DC6803"
                : "#D0D5DD",
              backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <CustomText
              text={
                props?.product?.code
                  ? props?.product?.code.slice(0, 3) + " ***"
                  : "---"
              }
              numberOfLines={1}
              style={{
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "400",
                display: "flex",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              text={
                props?.product?.name
                  ? props?.product?.name?.slice(0, 3) + " ***"
                  : "---"
              }
              numberOfLines={1}
              style={{
                fontSize: "12px",
                flexDirection: "column",
                fontWeight: "500",
                display: "flex",
                textAlign: "center",
                color: props?.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>
          <div
            data-tooltip-id={`${junctionId}`}
            //     data-tooltip-content={`
            // ${props?.user?.name + " " + props?.user?.lastName}
            // `}
            className={`flex absolute -right-[28px] items-center justify-center w-[56px] h-[56px] z-[100] `}
          >
            <UserController
              avatarPress={props?.avatarPress}
              onPress={props?.onPressUser}
              theme={props?.theme}
              image={props?.user?.image}
              name={props?.user?.name}
              navigate={navigate}
              user={props?.user}
              startDate={props?.startDate}
              lastName={props?.user?.lastName}
              externalOperation={externalOperation}
            />
            {statusType === "active" ? (
              <></>
            ) : (
              <div
                className={`z-[100] absolute flex items-center justify-center w-fit px-[6px] py-1 gap-[2px] -bottom-[26px] h-[24px] blur-sm rounded border cursor-pointer ${
                  props.status === "completed"
                    ? "bg-[#17B26A] border-[#17B26A]"
                    : props.status === "waiting"
                    ? "bg-[#EAAA08] border-[#EAAA08]"
                    : props.status === "control"
                    ? "bg-[#98A2B3] border-[#98A2B3]"
                    : props.status === "rejected"
                    ? "bg-[#F04438] border-[#F04438]"
                    : props.status === "accepted"
                    ? "bg-[#17B26A] border-[#17B26A]"
                    : "bg-[#DC6803] border-[#DC6803]"
                }`}
                onClick={props?.onPressQuality}
              >
                {props.status === "completed" ? (
                  <ProductCompleted />
                ) : props.status === "waiting" ? (
                  <ProductWaiting />
                ) : props.status === "control" ? (
                  <ProductControl />
                ) : props.status === "rejected" ? (
                  <ProductRejected />
                ) : props.status === "accepted" ? (
                  <ProductCompleted />
                ) : (
                  <ProductIcon />
                )}
                <span className="text-white font-semibold text-[10px] leading-4">
                  {qualityPercent || "0"}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex -top-[0px]  flex-col w-[49.5%]  min-w-[49.5%] gap-y-1">
          <div className="flex  flex-row items-center w-full">
            <div
              data-tooltip-id={`${junctionId}`}
              data-tooltip-content={`
        ${
          externalOperation
            ? supplier?.name.slice(0, 3) + "***"
            : props?.node?.process.slice(0, 3) + "***"
        } 
        `}
              className="mr-auto ml-[35%]"
            >
              <PressedContoller
                name={
                  externalOperation
                    ? supplier?.name?.slice(0, 2)?.toUpperCase()
                    : ""
                }
                theme={props?.theme}
                image={
                  externalOperation
                    ? supplier?.image
                      ? supplier?.image
                      : null
                    : props?.node?.image
                }
                externalOperation={externalOperation}
              />
            </div>
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-center gap-1 ml-auto">
                <p className={`text-xs font-normal text-secondary-600 `}>
                  {props?.time?.timeGreen || "--:--"}
                </p>
                <div className="ml-auto">
                  <GreenClockIcon />
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 ml-auto">
                <p className={`text-xs font-normal text-secondary-600 `}>
                  {props?.time?.timeRed || "--:--"}
                </p>
                <div className="ml-auto">
                  <RedClockIcon />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex h-[40px] min-h-[40px] max-h-[40px] items-center justify-center flex-col border rounded border-t-4`}
            style={{
              borderColor: completed
                ? "#D0D5DD"
                : props.ring
                ? props?.ring
                : props?.theme === "dark"
                ? "#424242"
                : props?.isSetting === true
                ? "#DC6803"
                : "#D0D5DD",
              backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
            }}
          >
            <CustomText
              text={
                externalOperation
                  ? step
                    ? `(${step}.Op) ` +
                        externalOperation.name?.slice(0, 3) +
                        " ***" || "--"
                    : props.node.process?.slice(0, 3) + " ***" || "--"
                  : `(${step}.Op) ` +
                      props?.node?.process?.slice(0, 3) +
                      " ***" || "--"
              }
              numberOfLines={1}
              style={{
                flexDirection: "column",
                fontSize: "12px",
                fontWeight: "400",
                display: "flex",
                textAlign: "center",
                color: props.theme === "dark" ? "#D6D6D6" : "#667085",
              }}
            />
            <CustomText
              text={
                externalOperation
                  ? supplier?.name?.slice(0, 3) + " ***"
                  : props?.node?.name?.slice(0, 3) + " ***" || "--"
              }
              numberOfLines={1}
              style={{
                fontSize: "12px",
                flexDirection: "column",
                fontWeight: "500",
                display: "flex",
                textAlign: "center",
                color: props?.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            />
          </div>
        </div>
        <div
          // onClick={(e) => {
          //   e.stopPropagation();
          //   setShowDropDown(!showDropDown);
          // }}
          onClick={() => Toast("warning", t("alert:warning"))}
          className={`hidden absolute  rotate-90 max-w-[24px]  p-[2px]  ${
            pages === "purchase" ? "group-hover:hidden" : "group-hover:flex"
          } right-[0px] -top-[14px] z-[50] items-center justify-center cursor-pointer`}
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[4px] right-[3px] z-[110] rounded-lg px-[6px] py-1">
            {externalOperation ? (
              <>
                {statusType === "active" ? (
                  <>
                    {menuOptionsExternalActive?.map((item, index) => {
                      if (
                        operation &&
                        (index === 0 ||
                          index === 3 ||
                          index === 5 ||
                          index === 4)
                      ) {
                        return null;
                      }
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.value === "delete") {
                              setIsAlert(true);
                            }
                            if (item?.value === "finish") {
                              onClick();
                            }

                            if (item.value === "quality") {
                              setIsJunction(true);
                            }
                            if (item.value === "operationDetail") {
                              props?.onPressProduct();
                            }
                            if (item.value === "edit") {
                              setEditJunction(true);
                            }
                            setShowDropDown(false);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            item?.value === "delete"
                              ? "hover:bg-[#FECDCA]"
                              : "hover:bg-secondary-100"
                          }`}
                        >
                          {item?.icon}
                          <p
                            className={`font-medium text-sm  ${
                              item?.value === "delete"
                                ? "text-[#D92D20]"
                                : "text-secondary-700"
                            }`}
                          >
                            {item.label}
                          </p>
                        </button>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {menuOptionsExternal?.map((item, index) => {
                      if (
                        operation &&
                        (index === 0 ||
                          index === 3 ||
                          index === 5 ||
                          index === 4)
                      ) {
                        return null;
                      }
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.value === "delete") {
                              setIsAlert(true);
                            }

                            if (item.value === "quality") {
                              setIsJunction(true);
                            }
                            if (item.value === "operationDetail") {
                              props?.onPressProduct();
                            }
                            if (item.value === "edit") {
                              setEditJunction(true);
                            }
                            setShowDropDown(false);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            item?.value === "delete"
                              ? "hover:bg-[#FECDCA]"
                              : "hover:bg-secondary-100"
                          }`}
                        >
                          {item?.icon}
                          <p
                            className={`font-medium text-sm  ${
                              item?.value === "delete"
                                ? "text-[#D92D20]"
                                : "text-secondary-700"
                            }`}
                          >
                            {item.label}
                          </p>
                        </button>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <>
                {statusType === "active" ? (
                  <>
                    {menuOptionsActive?.map((item, index) => {
                      if (
                        operation &&
                        (index === 0 ||
                          index === 3 ||
                          index === 5 ||
                          index === 4)
                      ) {
                        return null;
                      }
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.value === "delete") {
                              setIsAlert(true);
                            }
                            if (item?.value === "details") {
                              props?.onPressNode();
                            }
                            if (item?.value === "finish") {
                              onClick();
                            }
                            if (item?.value === "personnel") {
                              navigate(
                                `/app/machine/digital-twin/user-detail/${
                                  props?.user?.id
                                }/${moment(props?.startDate).format(
                                  "YYYY-MM-DD"
                                )}`
                              );
                            }
                            if (item.value === "quality") {
                              setIsJunction(true);
                            }
                            if (item.value === "operationDetail") {
                              props?.onPressProduct();
                            }
                            if (item.value === "edit") {
                              setEditJunction(true);
                            }
                            setShowDropDown(false);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            item?.value === "delete"
                              ? "hover:bg-[#FECDCA]"
                              : "hover:bg-secondary-100"
                          }`}
                        >
                          {item?.icon}
                          <p
                            className={`font-medium text-sm  ${
                              item?.value === "delete"
                                ? "text-[#D92D20]"
                                : "text-secondary-700"
                            }`}
                          >
                            {item.label}
                          </p>
                        </button>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {menuOptions?.map((item, index) => {
                      if (
                        operation &&
                        (index === 0 ||
                          index === 3 ||
                          index === 5 ||
                          index === 4)
                      ) {
                        return null;
                      }
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.value === "delete") {
                              setIsAlert(true);
                            }
                            if (item?.value === "details") {
                              props?.onPressNode();
                            }
                            if (item?.value === "finish") {
                              onClick();
                            }
                            if (item?.value === "personnel") {
                              navigate(
                                `/app/machine/digital-twin/user-detail/${
                                  props?.user?.id
                                }/${moment(props?.startDate).format(
                                  "YYYY-MM-DD"
                                )}`
                              );
                            }
                            if (item.value === "quality") {
                              setIsJunction(true);
                            }
                            if (item.value === "operationDetail") {
                              props?.onPressProduct();
                            }
                            if (item.value === "edit") {
                              setEditJunction(true);
                            }
                            setShowDropDown(false);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            item?.value === "delete"
                              ? "hover:bg-[#FECDCA]"
                              : "hover:bg-secondary-100"
                          }`}
                        >
                          {item?.icon}
                          <p
                            className={`font-medium text-sm  ${
                              item?.value === "delete"
                                ? "text-[#D92D20]"
                                : "text-secondary-700"
                            }`}
                          >
                            {item.label}
                          </p>
                        </button>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        )}
        <Tooltip className="z-[100]" id={`${junctionId}`} />
      </div>
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:qualityControlForm")}
        children={
          <QualityControlForm
            junctionData={allData}
            refetch={refetch}
            setIsOpen={setIsJunction}
            onClose={() => {
              setIsJunction(false);
            }}
            junctionId={junctionId}
          />
        }
      />
      <CustomModal
        isOpen={editJunction}
        setIsOpen={setEditJunction}
        onClose={() => {
          setEditJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={allData?.id}
            onClose={() => {
              setEditJunction(false);
            }}
            pages={pages}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

const PressedContoller = ({
  onPress,
  image,
  theme,
  name,
  externalOperation,
}) => {
  return (
    <>
      {image ? (
        <CustomImage
          borderRadius={4}
          src={image}
          style={{
            width: 60,
            height: 45,
            borderRadius: 4,
            borderWidth: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
            filter: "blur(4px)",
            borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
            backgroundColor: theme === "dark" ? "#000" : "#fff",
          }}
        />
      ) : (
        <div
          className={
            "min-w-[60px] min-h-[45px] max-w-[60px] blur-sm rounded max-h-[45px] bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
          }
        >
          {externalOperation ? name : ""}
        </div>
      )}
    </>
  );
};

const UserController = ({
  onPress,
  image,
  theme,
  avatarPress,
  name,
  lastName,
  user,
  navigate,
  startDate,
  externalOperation,
}) => {
  if (!onPress) {
    return (
      <div
        className={avatarPress && "cursor-pointer"}
        onClick={() => {
          if (avatarPress) {
            avatarPress();
          }
        }}
      >
        {externalOperation ? (
          <>
            {image ? (
              <div className="cursor-pointer">
                <CustomImage
                  src={image}
                  style={{
                    width: 56,
                    height: 56,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    filter: "blur(4px)",
                    borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                    backgroundColor: theme === "dark" ? "#000" : "#fff",
                  }}
                />
              </div>
            ) : (
              <div>
                <div
                  style={{
                    marginBottom: 12,
                  }}
                >
                  <NoAvatar
                    color={"#15B79E"}
                    size={56}
                    name={name}
                    lastName={lastName}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {image ? (
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
              >
                <CustomImage
                  src={image}
                  style={{
                    width: 56,
                    height: 56,
                    flexDirection: "column",
                    borderRadius: 100,
                    display: "flex",
                    filter: "blur(4px)",
                    borderColor: theme === "dark" ? "#FFFFFF26" : "#0000001A",
                    backgroundColor: theme === "dark" ? "#000" : "#fff",
                  }}
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
                className="cursor-pointer"
              >
                <div
                  style={{
                    marginBottom: 12,
                  }}
                >
                  <NoAvatar
                    color={"#15B79E"}
                    size={56}
                    name={name}
                    lastName={lastName}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <button
        type="button"
        onClick={onPress}
        className="outline-none"
        style={{
          width: 56,
          height: 56,
          borderRadius: 100,
          flexDirection: "column",
          borderWidth: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderStyle: "dashed",
          display: "flex",
          borderColor: theme === "dark" ? "#424242" : "#D0D5DD",
          backgroundColor: theme === "dark" ? "#141414" : "#fff",
        }}
      >
        <PlusIcon />
      </button>
    );
  }
};

const ProductCompleted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.6615 4.71372L14.028 3.73641L13.66 2.21297C13.4966 1.53732 12.8708 1.08688 12.1846 1.14088L10.3156 1.28554L9.24489 0.337535C8.71755 -0.112434 7.95033 -0.112434 7.42117 0.337066L6.35045 1.2855L4.48139 1.14085C3.80299 1.09325 3.16898 1.53772 3.02295 2.15754C2.99273 2.25147 2.44689 3.94819 2.48233 3.83797L1.00477 4.71322C0.412891 5.07669 0.175766 5.80635 0.440797 6.44816L1.07845 7.99357L0.441266 9.53854C0.175766 10.1808 0.413329 10.91 1.00477 11.273L2.63658 12.2435L3.00599 13.7738C3.16849 14.449 3.79152 14.8971 4.48136 14.8459L6.33027 14.7031L7.42111 15.6622C7.68527 15.8874 8.00892 16 8.33302 16C8.65664 16 8.98077 15.8874 9.24489 15.6622L10.3357 14.703L12.1846 14.8458C12.8832 14.9012 13.4975 14.4494 13.66 13.7738L14.028 12.2503L15.6615 11.273C16.2525 10.91 16.4896 10.1808 16.225 9.53854L15.5874 7.9936L16.2255 6.44772C16.4901 5.80641 16.253 5.07672 15.6615 4.71372ZM8.33302 13.1563C5.48986 13.1563 3.17677 10.8432 3.17677 8C3.17677 5.15685 5.48986 2.84375 8.33302 2.84375C11.1762 2.84375 13.4893 5.15685 13.4893 8C13.4893 10.8432 11.1762 13.1563 8.33302 13.1563Z"
        fill="white"
      />
      <path
        d="M8.33301 3.78125C6.00666 3.78125 4.11426 5.67366 4.11426 8C4.11426 10.3263 6.00666 12.2188 8.33301 12.2188C10.6594 12.2188 12.5518 10.3263 12.5518 8C12.5518 5.67366 10.6594 3.78125 8.33301 3.78125ZM11.0082 6.92516L8.19566 9.73766C8.1041 9.82922 7.98416 9.875 7.86426 9.875C7.74435 9.875 7.62438 9.82922 7.53285 9.73766L5.65785 7.86266C5.47476 7.67956 5.47476 7.38291 5.65785 7.19981C5.84095 7.01672 6.1376 7.01672 6.3207 7.19981L7.86426 8.74341L10.3454 6.26231C10.5284 6.07922 10.8251 6.07922 11.0082 6.26231C11.1913 6.44541 11.1913 6.74206 11.0082 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductRejected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.6615 4.71372L14.028 3.73641L13.66 2.21297C13.4966 1.53732 12.8708 1.08688 12.1846 1.14088L10.3156 1.28554L9.24489 0.337535C8.71755 -0.112434 7.95033 -0.112434 7.42117 0.337066L6.35045 1.2855L4.48139 1.14085C3.80299 1.09325 3.16898 1.53772 3.02295 2.15754C2.99273 2.25147 2.44689 3.94819 2.48233 3.83797L1.00477 4.71322C0.412891 5.07669 0.175766 5.80635 0.440797 6.44816L1.07845 7.99357L0.441266 9.53854C0.175766 10.1808 0.413329 10.91 1.00477 11.273L2.63658 12.2435L3.00599 13.7738C3.16849 14.449 3.79152 14.8971 4.48136 14.8459L6.33027 14.7031L7.42111 15.6622C7.68527 15.8874 8.00892 16 8.33302 16C8.65664 16 8.98077 15.8874 9.24489 15.6622L10.3357 14.703L12.1846 14.8458C12.8832 14.9012 13.4975 14.4494 13.66 13.7738L14.028 12.2503L15.6615 11.273C16.2525 10.91 16.4896 10.1808 16.225 9.53854L15.5874 7.9936L16.2255 6.44772C16.4901 5.80641 16.253 5.07672 15.6615 4.71372ZM8.33302 13.1563C5.48986 13.1563 3.17677 10.8432 3.17677 8C3.17677 5.15685 5.48986 2.84375 8.33302 2.84375C11.1762 2.84375 13.4893 5.15685 13.4893 8C13.4893 10.8432 11.1762 13.1563 8.33302 13.1563Z"
        fill="white"
      />
      <path
        d="M8.33301 3.78125C6.00666 3.78125 4.11426 5.67366 4.11426 8C4.11426 10.3263 6.00666 12.2188 8.33301 12.2188C10.6594 12.2188 12.5518 10.3263 12.5518 8C12.5518 5.67366 10.6594 3.78125 8.33301 3.78125ZM11.0082 6.92516L8.19566 9.73766C8.1041 9.82922 7.98416 9.875 7.86426 9.875C7.74435 9.875 7.62438 9.82922 7.53285 9.73766L5.65785 7.86266C5.47476 7.67956 5.47476 7.38291 5.65785 7.19981C5.84095 7.01672 6.1376 7.01672 6.3207 7.19981L7.86426 8.74341L10.3454 6.26231C10.5284 6.07922 10.8251 6.07922 11.0082 6.26231C11.1913 6.44541 11.1913 6.74206 11.0082 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductControl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.7364L13.327 2.21297C13.1636 1.53731 12.5378 1.08687 11.8516 1.14087L9.98257 1.28553L8.91188 0.337528C8.38454 -0.112441 7.61732 -0.112441 7.08816 0.337059L6.01745 1.2855L4.14838 1.14084C3.46998 1.09325 2.83598 1.53771 2.68995 2.15753C2.65973 2.25146 2.11388 3.94818 2.14932 3.83797L0.671758 4.71321C0.0798834 5.07668 -0.157242 5.80634 0.10779 6.44815L0.745446 7.99356L0.108258 9.53853C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7737L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53853L15.2544 7.99359L15.8925 6.44771C16.1571 5.8064 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1562C5.15685 13.1562 2.84376 10.8432 2.84376 8C2.84376 5.15684 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15684 13.1563 8C13.1563 10.8432 10.8432 13.1562 8.00001 13.1562Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3285 4.71372L13.695 3.73641L13.327 2.21297C13.1636 1.53732 12.5378 1.08688 11.8516 1.14088L9.98257 1.28554L8.91188 0.337535C8.38454 -0.112434 7.61732 -0.112434 7.08816 0.337066L6.01745 1.2855L4.14838 1.14085C3.46998 1.09325 2.83598 1.53772 2.68995 2.15754C2.65973 2.25147 2.11388 3.94819 2.14932 3.83797L0.671758 4.71322C0.0798834 5.07669 -0.157242 5.80635 0.10779 6.44816L0.745446 7.99357L0.108258 9.53854C-0.157242 10.1808 0.0803209 10.91 0.671758 11.273L2.30357 12.2435L2.67298 13.7738C2.83548 14.449 3.45851 14.8971 4.14835 14.8459L5.99726 14.7031L7.0881 15.6622C7.35226 15.8874 7.67591 16 8.00001 16C8.32363 16 8.64776 15.8874 8.91188 15.6622L10.0027 14.703L11.8516 14.8458C12.5502 14.9012 13.1645 14.4494 13.327 13.7738L13.695 12.2503L15.3285 11.273C15.9195 10.91 16.1566 10.1808 15.892 9.53854L15.2544 7.9936L15.8925 6.44772C16.1571 5.80641 15.9199 5.07672 15.3285 4.71372ZM8.00001 13.1563C5.15685 13.1563 2.84376 10.8432 2.84376 8C2.84376 5.15685 5.15685 2.84375 8.00001 2.84375C10.8432 2.84375 13.1563 5.15685 13.1563 8C13.1563 10.8432 10.8432 13.1563 8.00001 13.1563Z"
        fill="white"
      />
      <path
        d="M8 3.78125C5.67366 3.78125 3.78125 5.67366 3.78125 8C3.78125 10.3263 5.67366 12.2188 8 12.2188C10.3263 12.2188 12.2188 10.3263 12.2188 8C12.2188 5.67366 10.3263 3.78125 8 3.78125ZM10.6752 6.92516L7.86266 9.73766C7.77109 9.82922 7.65116 9.875 7.53125 9.875C7.41134 9.875 7.29138 9.82922 7.19984 9.73766L5.32484 7.86266C5.14175 7.67956 5.14175 7.38291 5.32484 7.19981C5.50794 7.01672 5.80459 7.01672 5.98769 7.19981L7.53125 8.74341L10.0123 6.26231C10.1954 6.07922 10.4921 6.07922 10.6752 6.26231C10.8583 6.44541 10.8583 6.74206 10.6752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const ProductWaiting = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.8285 4.71372L14.195 3.73641L13.827 2.21297C13.6636 1.53732 13.0378 1.08688 12.3516 1.14088L10.4826 1.28554L9.41188 0.337535C8.88454 -0.112434 8.11732 -0.112434 7.58816 0.337066L6.51745 1.2855L4.64838 1.14085C3.96998 1.09325 3.33598 1.53772 3.18995 2.15754C3.15973 2.25147 2.61388 3.94819 2.64932 3.83797L1.17176 4.71322C0.579883 5.07669 0.342758 5.80635 0.60779 6.44816L1.24545 7.99357L0.608258 9.53854C0.342758 10.1808 0.580321 10.91 1.17176 11.273L2.80357 12.2435L3.17298 13.7738C3.33548 14.449 3.95851 14.8971 4.64835 14.8459L6.49726 14.7031L7.5881 15.6622C7.85226 15.8874 8.17591 16 8.50001 16C8.82363 16 9.14776 15.8874 9.41188 15.6622L10.5027 14.703L12.3516 14.8458C13.0502 14.9012 13.6645 14.4494 13.827 13.7738L14.195 12.2503L15.8285 11.273C16.4195 10.91 16.6566 10.1808 16.392 9.53854L15.7544 7.9936L16.3925 6.44772C16.6571 5.80641 16.4199 5.07672 15.8285 4.71372ZM8.50001 13.1563C5.65685 13.1563 3.34376 10.8432 3.34376 8C3.34376 5.15685 5.65685 2.84375 8.50001 2.84375C11.3432 2.84375 13.6563 5.15685 13.6563 8C13.6563 10.8432 11.3432 13.1563 8.50001 13.1563Z"
        fill="white"
      />
      <path
        d="M8.5 3.78125C6.17366 3.78125 4.28125 5.67366 4.28125 8C4.28125 10.3263 6.17366 12.2188 8.5 12.2188C10.8263 12.2188 12.7188 10.3263 12.7188 8C12.7188 5.67366 10.8263 3.78125 8.5 3.78125ZM11.1752 6.92516L8.36266 9.73766C8.27109 9.82922 8.15116 9.875 8.03125 9.875C7.91134 9.875 7.79138 9.82922 7.69984 9.73766L5.82484 7.86266C5.64175 7.67956 5.64175 7.38291 5.82484 7.19981C6.00794 7.01672 6.30459 7.01672 6.48769 7.19981L8.03125 8.74341L10.5123 6.26231C10.6954 6.07922 10.9921 6.07922 11.1752 6.26231C11.3583 6.44541 11.3583 6.74206 11.1752 6.92516Z"
        fill="white"
      />
    </svg>
  );
};

const QualityRedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04169H3.25C2.42157 2.04169 1.75 2.71326 1.75 3.54169V10.3854C1.75 11.2139 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2139 12.25 10.3854V3.54169C12.25 2.71326 11.5784 2.04169 10.75 2.04169H8.96875M5.03125 2.04169V4.69117C5.03125 4.74692 5.08992 4.78318 5.13979 4.75825L7 3.82815L8.86021 4.75825C8.91008 4.78318 8.96875 4.74692 8.96875 4.69117V2.04169M5.03125 2.04169H8.96875"
        stroke="#D92D20"
      />
      <path
        d="M8.45841 6.70831L5.54175 9.62498M5.54175 6.70831L8.45841 9.62498"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QualityGreenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04169H3.25C2.42157 2.04169 1.75 2.71326 1.75 3.54169V10.3854C1.75 11.2139 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2139 12.25 10.3854V3.54169C12.25 2.71326 11.5784 2.04169 10.75 2.04169H8.96875M5.03125 2.04169V4.69117C5.03125 4.74692 5.08992 4.78318 5.13979 4.75825L7 3.82815L8.86021 4.75825C8.91008 4.78318 8.96875 4.74692 8.96875 4.69117V2.04169M5.03125 2.04169H8.96875"
        stroke="#079455"
      />
      <path
        d="M5.25 8.27883L6.50641 9.53524L9.33333 6.70831"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const GreenClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.50638 4.75128V7.38376L4.86108 8.37094"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7868 7.69119C12.8114 7.46376 12.824 7.23275 12.824 6.99879C12.824 3.47878 9.97046 0.625244 6.45045 0.625244C2.93044 0.625244 0.0769043 3.47878 0.0769043 6.99879C0.0769043 10.5188 2.93044 13.3723 6.45045 13.3723C7.03961 13.3723 7.61011 13.2924 8.15167 13.1428V12.0975C7.61704 12.2758 7.04502 12.3723 6.45045 12.3723C3.48272 12.3723 1.0769 9.96652 1.0769 6.99879C1.0769 4.03106 3.48272 1.62524 6.45045 1.62524C9.41818 1.62524 11.824 4.03106 11.824 6.99879C11.824 7.00893 11.824 7.01906 11.8239 7.02918L12.7868 7.69119Z"
        fill="#079455"
      />
      <path
        d="M9.46777 7.97353C9.46777 7.70342 9.46777 7.56836 9.52409 7.49392C9.57316 7.42906 9.64815 7.38892 9.72933 7.38408C9.82251 7.37851 9.93489 7.45343 10.1596 7.60326L13.0844 9.55307C13.2701 9.67687 13.3629 9.73878 13.3953 9.8168C13.4236 9.88501 13.4236 9.96167 13.3953 10.0299C13.3629 10.1079 13.2701 10.1698 13.0844 10.2936L10.1596 12.2434C9.93489 12.3933 9.82251 12.4682 9.72933 12.4626C9.64815 12.4578 9.57316 12.4176 9.52409 12.3528C9.46777 12.2783 9.46777 12.1433 9.46777 11.8732V7.97353Z"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 5.5V19.5M5 12.5H19"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RedClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.66247 4.68896V7.39072L4.97388 8.40388"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.59009 6.99561C1.59009 4.22592 3.83536 1.98065 6.60504 1.98065C9.37473 1.98065 11.62 4.22592 11.62 6.99561C11.62 7.01478 11.6199 7.03392 11.6197 7.05305H12.6064C12.6108 7.05305 12.6153 7.05305 12.6197 7.05305C12.6199 7.03392 12.62 7.01478 12.62 6.99561C12.62 3.67364 9.92701 0.980652 6.60504 0.980652C3.28308 0.980652 0.590088 3.67364 0.590088 6.99561C0.590088 10.3176 3.28308 13.0106 6.60504 13.0106C7.21835 13.0106 7.81022 12.9188 8.36766 12.7482C8.32609 12.6957 8.28961 12.6391 8.25891 12.5788C8.16661 12.3977 8.15145 12.1697 8.14897 11.7684C7.66261 11.9256 7.14374 12.0106 6.60504 12.0106C3.83536 12.0106 1.59009 9.76529 1.59009 6.99561Z"
        fill="#D92D20"
      />
      <path
        d="M9.36426 9.48463C9.36426 9.10635 9.36426 8.91721 9.43788 8.77273C9.50263 8.64563 9.60596 8.54231 9.73305 8.47755C9.87754 8.40393 10.0667 8.40393 10.445 8.40393H12.3362C12.7145 8.40393 12.9036 8.40393 13.0481 8.47755C13.1752 8.54231 13.2785 8.64563 13.3433 8.77273C13.4169 8.91721 13.4169 9.10635 13.4169 9.48463V11.3759C13.4169 11.7541 13.4169 11.9433 13.3433 12.0878C13.2785 12.2149 13.1752 12.3182 13.0481 12.3829C12.9036 12.4566 12.7145 12.4566 12.3362 12.4566H10.445C10.0667 12.4566 9.87754 12.4566 9.73305 12.3829C9.60596 12.3182 9.50263 12.2149 9.43788 12.0878C9.36426 11.9433 9.36426 11.7541 9.36426 11.3759V9.48463Z"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default NewFinishedJunctionCard;
