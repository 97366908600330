import React, { memo } from "react";
import { CheckIcon } from "../../../assets/icons/stepsIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";

const ResponsibleUserCard = React.memo(
  ({ userName, description, selected, avatar, onClick, border }) => {
    return (
      <>
        <div
          onClick={onClick}
          className={` w-full py-2.5 flex items-center gap-3 p-0 ${
            border && "border-r dark:border-[#292929] xs:hidden "
          }`}
        >
          {selected ? (
            <div
              type="checkbox"
              className={`w-5 h-5 rounded-md border border-solid bg-[#7F56D9] border-[#D0D5DD] items-center justify-center flex`}
            >
              <CheckIcon color="white" />
            </div>
          ) : (
            <div
              type="checkbox"
              className={`w-5 h-5 rounded-md border border-solid bg-[#fff] dark:border-[#424242] dark:bg-[#0F0F0F] border-[#D0D5DD] items-center justify-center flex`}
            >
            </div>
          )}

          <div className={`w-fit h-full flex items-center gap-3 `}>
            {avatar !== "Empty" ? (
              <img
                src={avatar}
                alt=""
                className={`flex w-10 h-10 justify-center items-center rounded-full border border-solid border-[#0000001A] bg-[#0000001A]`}
              />
            ) : (
              <NoAvatar
                name={userName}
                size={40}
                fontSize={20}
                theme={"primary"}
              />
            )}

            <div className={`flex flex-col items-start w-fit h-full`}>
              <span className={`text-[#344054] text-sm font-semibold dark:text-[#D6D6D6]`}>
                {userName}
              </span>
              <span className={`text-[#667085] text-sm font-normal dark:text-[#D6D6D6]`}>
                {description}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default memo(ResponsibleUserCard);
