import React, { useEffect, useState } from "react";
import { Button, Input, KpiInput, UploadProfile } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { processService } from "../../../services/process.service";
import { yupResolver } from "@hookform/resolvers/yup";
import Selector from "../../../components/inputs/Selector";
import { equipmentService } from "../../../services/equipment.service";
import { Toast } from "../../../utils/toastify/toast";

const AddEquipmentModal = ({
  t,
  setIsOpen,
  selectedNode,
  setSelectedNode,
  selectedEquipment,
  handleData,
  theme,
  refetch,
}) => {
  const { width, height } = useWindowDimensions();
  const [processes, setProcesses] = useState([]);
  const schema = yup.object({
    id: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    node: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    image: yup.string(),
    brand: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    model: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    process: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    manufactureDate: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    electricty: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    estimatedPrice: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    costOfMinute: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    deprecationPercent: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    usefulUsage: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    concurrentUser: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    orderNumber: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    payrollCoefficient: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    // ---------Kpi Values ---------
    utilization: yup
      .number()
      .required(`${t("validation:empty")}`)
      .min(0, `${t("validation:min0")}`)
      .max(30, `${t("validation:max30")}`)
      .typeError(`${t("validation:number")}`),
    measurable: yup
      .number()
      .required(`${t("validation:empty")}`)
      .min(0, `${t("validation:min0")}`)
      .max(30, `${t("validation:max30")}`)
      .typeError(`${t("validation:number")}`),
    performance: yup
      .number()
      .required(`${t("validation:empty")}`)
      .min(0, `${t("validation:min0")}`)
      .max(30, `${t("validation:max30")}`)
      .typeError(`${t("validation:number")}`),
    availability: yup
      .number()
      .required(`${t("validation:empty")}`)
      .min(0, `${t("validation:min0")}`)
      .max(30, `${t("validation:max30")}`)
      .typeError(`${t("validation:number")}`),
    workTolerance: yup.number().typeError(`${t("validation:number")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    handleProcess();
    if (selectedNode?.metadata) {
      setValue("image", selectedNode?.metadata.image);
      setValue("brand", selectedNode?.metadata.brand);
      setValue("model", selectedNode?.metadata.model);
      setValue("process", selectedNode?.process?.id);
      setValue("manufactureDate", selectedNode?.metadata.manufactureDate);
      setValue("electricty", selectedNode?.metadata.electricty);
      setValue("estimatedPrice", selectedNode?.metadata.estimatedPrice);
      setValue("costOfMinute", selectedNode?.metadata.costOfMinute);
      setValue("deprecationPercent", selectedNode?.metadata.deprecationPercent);
      setValue("usefulUsage", selectedNode?.metadata.usefulUsage);
      setValue("concurrentUser", selectedNode?.metadata.concurrentUser);
      setValue("orderNumber", selectedNode?.metadata.orderNumber);
      setValue("payrollCoefficient", selectedNode?.metadata.payrollCoefficient);
      setValue("measurable", selectedNode?.measurable);
      setValue("availability", selectedNode?.availability);
      setValue("performance", selectedNode?.performance);
      setValue("utilization", selectedNode?.utilization);
      setValue("workTolerance", selectedNode?.metadata?.workTolerance);
      setValue("node", selectedNode?.deviceId);
    } else {
      setValue("measurable", 10);
      setValue("availability", 10);
      setValue("performance", 10);
      setValue("utilization", 10);
    }
    setValue("node", selectedNode?.deviceId);
    setValue("id", selectedNode?.id);
  }, [selectedNode, setSelectedNode]);

  const handleProcess = async () => {
    try {
      const response = await processService.activeProcesses();
      if (response && response?.data) {
        setProcesses(
          response?.data?.map((d) => {
            return {
              label: d.name,
              value: d.id,
            };
          })
        );
      } else {
        console.error("issue in Response or data is undefined.");
      }
    } catch (error) {
      console.error("issue", error.message);
    }
  };

  const onSubmit = (data) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[688px] xs:w-[335px] h-fit xs:h-[600px] xs:flex-col sm:w-[530px] sm:h-[600px] mt-3 flex items-start sm:px-3 sm:pt-2  gap-x-12"
      >
        <div className="w-full h-full flex flex-col ">
          <div className="flex sm:flex-wrap  relative gap-x-10 xs:flex-col overflow-y-scroll scrollbar-hide ">
            <div className="absolute ml-[344px] w-[1px] h-full bg-secondary-100 dark:bg-[#292929] xs:hidden sm:hidden md:ml-[325px]" />
            <div className="flex flex-col w-full h-fit gap-4 sm:gap-y-2">
              <Controller
                className="xs:pl-4"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <UploadProfile
                    theme={theme}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ""}
                    title={t("fileInput:uploadAvatar")}
                    subTitle={t("fileInput:removePicture")}
                    validate={errors.node ? "error" : ""}
                    errorMessage={errors.node ? errors.node.message : ""}
                  />
                )}
                name="image"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    label={"ID"}
                    width={"100%"}
                    disabled={true}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.node ? "error" : ""}
                    errorMessage={errors.node ? errors.node.message : ""}
                  />
                )}
                name="node"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    label={t("addEquipment:brand")}
                    width={"100%"}
                    disabled={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.brand ? "error" : ""}
                    errorMessage={errors.brand ? errors.brand.message : ""}
                  />
                )}
                name="brand"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    label={t("addEquipment:modal")}
                    width={"100%"}
                    disabled={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.model ? "error" : ""}
                    errorMessage={errors.model ? errors.model.message : ""}
                  />
                )}
                name="model"
              />

              <Controller
                control={control}
                render={({ field: { onBlur, value } }) => (
                  <Selector
                    items={processes}
                    label={t("addEquipment:process")}
                    placeholder={t("addEquipment:process")}
                    value={value}
                    onBlur={onBlur}
                    disabled={false}
                    onChange={(v) => {
                      setValue("process", v);
                    }}
                    validate={errors.model ? "error" : ""}
                    errorMessage={errors.model ? errors.model.message : ""}
                  />
                )}
                name="process"
              />

              <Input
                label={t("addProcess:macAddress")}
                width={"100%"}
                disabled={true}
                value={String(selectedNode?.macAddress || "null")}
              />

              <div className="flex items-center gap-x-4 ">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("addEquipment:productYear")}
                      width={"100%"}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.manufactureDate ? "error" : ""}
                      errorMessage={
                        errors.manufactureDate
                          ? errors.manufactureDate.message
                          : ""
                      }
                    />
                  )}
                  name="manufactureDate"
                />

                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={"KW"}
                      width={"100%"}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.electricty ? "error" : ""}
                      errorMessage={
                        errors.electricty ? errors.electricty.message : ""
                      }
                    />
                  )}
                  name="electricty"
                />
              </div>

              <div className="flex items-center gap-x-4">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("addEquipment:scoreboardCoef")}
                      width={"100%"}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.payrollCoefficient ? "error" : ""}
                      errorMessage={
                        errors.payrollCoefficient
                          ? errors.payrollCoefficient.message
                          : ""
                      }
                    />
                  )}
                  name="payrollCoefficient"
                />

                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("addEquipment:depreciationRate")}
                      width={"100%"}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.deprecationPercent ? "error" : ""}
                      errorMessage={
                        errors.deprecationPercent
                          ? errors.deprecationPercent.message
                          : ""
                      }
                    />
                  )}
                  name="deprecationPercent"
                />
              </div>
            </div>
            <div className="flex flex-col w-full h-fit gap-4 sm:gap-y-2">
              <div className="flex items-center gap-x-4 ">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("addEquipment:currentValEq")}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.estimatedPrice ? "error" : ""}
                      errorMessage={
                        errors.estimatedPrice
                          ? errors.estimatedPrice.message
                          : ""
                      }
                    />
                  )}
                  name="estimatedPrice"
                />

                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("addEquipment:minuteCost")}
                      width={"100%"}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.costOfMinute ? "error" : ""}
                      errorMessage={
                        errors.costOfMinute ? errors.costOfMinute.message : ""
                      }
                    />
                  )}
                  name="costOfMinute"
                />
              </div>

              <div className="flex items-center gap-x-4 ">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("addEquipment:sequenceNo")}
                      width={"100%"}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.orderNumber ? "error" : ""}
                      errorMessage={
                        errors.orderNumber ? errors.orderNumber.message : ""
                      }
                    />
                  )}
                  name="orderNumber"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("addEquipment:workedPeople")}
                      width={"100%"}
                      disabled={false}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      validate={errors.concurrentUser ? "error" : ""}
                      errorMessage={
                        errors.concurrentUser
                          ? errors.concurrentUser.message
                          : ""
                      }
                    />
                  )}
                  name="concurrentUser"
                />
              </div>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    label={t("addEquipment:usefulLife")}
                    width={"100%"}
                    disabled={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.usefulUsage ? "error" : ""}
                    errorMessage={
                      errors.usefulUsage ? errors.usefulUsage.message : ""
                    }
                    //   size={width <= 1024 ? "sm" : "sm"}
                  />
                )}
                name="usefulUsage"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    label={t("addProcess:workTolerance")}
                    width={"100%"}
                    disabled={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.workTolerance ? "error" : ""}
                    errorMessage={
                      errors.workTolerance ? errors.workTolerance.message : ""
                    }
                  />
                )}
                name="workTolerance"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <KpiInput
                    label={t("addProcess:utilization")}
                    placeholder="10"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.utilization ? "error" : ""}
                    errorMessage={
                      errors.utilization ? errors.utilization.message : ""
                    }
                  />
                )}
                name="utilization"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <KpiInput
                    label={t("addProcess:measurable")}
                    placeholder="10"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.measurable ? "error" : ""}
                    errorMessage={
                      errors.measurable ? errors.measurable.message : ""
                    }
                  />
                )}
                name="measurable"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <KpiInput
                    label={t("addProcess:performance")}
                    placeholder="10"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.performance ? "error" : ""}
                    errorMessage={
                      errors.performance ? errors.performance.message : ""
                    }
                  />
                )}
                name="performance"
              />

              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <KpiInput
                    label={t("addProcess:avaibility")}
                    placeholder="10"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    validate={errors.availability ? "error" : ""}
                    errorMessage={
                      errors.availability ? errors.availability.message : ""
                    }
                  />
                )}
                name="availability"
              />
            </div>
          </div>

          <div className="flex xs:flex xs:bottom-0 items-center gap-3 w-full mt-6 ">
            <Button
              type={"button"}
              label={t("buttons:stop")}
              size={window.width > 768 ? "xl" : "lg"}
              colorType={"secondary-gray"}
              onClick={() => {
                setIsOpen(false);
                setSelectedNode(null);
              }}
            />
            <Button
              label={t("buttons:save")}
              colorType={"primary-machine"}
              size={window.width > 768 ? "xl" : "lg"}
              type="submit"
              setIsOpen={false}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEquipmentModal;
