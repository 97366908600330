import React, { useEffect, useState } from "react";
import FinishedJunctionCard from "../../../components/junction/FinishedJunctionCard";
import Badgets from "../../../components/buttons/Badgets";
import { Button, Input } from "../../../components";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import Selector from "../../../components/inputs/Selector";
import CloseButton from "../../../components/buttons/CloseButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HourTimeInput from "../../../components/inputs/HourTimeInput";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../../services/order.service";
import { junctionService } from "../../../services/junction.service";
import { Toast } from "../../../utils/toastify/toast";

const SplitOrderModal = ({ id, dataJunction, handleData, closeModal }) => {
  console.log('dataJunctionss', dataJunction)
  const [orders, setOrders] = useState([]);
  const [operations, setOperations] = useState([]);
  const { t } = useTranslation();
  const isCompetedQuality = dataJunction?.metadata?.qualityStatus !== "waiting";
  const junctionTypes = [
    { label: t("product:work"), value: "work" },
    { label: t("product:setup"), value: "setup" },
  ];
  const { data } = useQuery({
    queryKey: ["split-order"],
    queryFn: async () =>
      await orderService.getCanBeMovedJunctions(
        dataJunction?.header?.type,
        dataJunction?.job?.product?.id,
        dataJunction?.header?.id,
        dataJunction?.header?.type == "internal"
          ? dataJunction?.internalOperation?.id
          : dataJunction?.externalOperation?.id,
        dataJunction?.job?.product?.stockCode
      ),
    retry: 0,
  });

  const schema = yup.object({
    orderNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    piece: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    type: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    operationNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    start: yup.date().required(`${t("validation:empty")}`),
    end: yup.date().required(`${t("validation:empty")}`),
    orderNoTwo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    pieceTwo: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    typeTwo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:number")}`),
    operationNoTwo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    startTwo: yup.date().required(`${t("validation:empty")}`),
    endTwo: yup.date().required(`${t("validation:empty")}`),
    rejectedPiece: yup.number().typeError(`${t("validation:number")}`),
    acceptedPiece: yup.number().typeError(`${t("validation:number")}`),
    rejectedPieceTwo: yup.number().typeError(`${t("validation:number")}`),
    acceptedPieceTwo: yup.number().typeError(`${t("validation:number")}`),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (value) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  useEffect(() => {
    if (data?.data?.code === 1) {
      closeModal();
      Toast("info", data?.data?.message);
    } else {
      setOrders(
        data?.data?.data?.map((a) => {
          return { value: a.id, label: a.orderNo };
        })
      );
      setValue("orderNo", dataJunction?.job?.orderNo);
      setValue("operationNo", dataJunction?.header?.step);
      setValue("piece", dataJunction?.metadata?.madeWorkCount);
      setValue("start", dataJunction?.startDate);
      setValue("end", dataJunction?.endDate);
      setValue("acceptedPiece", dataJunction?.metadata?.acceptedWorkCount);
      setValue("rejectedPiece", dataJunction?.metadata?.rejectedWorkCount);
    }
  }, [data]);
  const orderNoSelected = useWatch({
    control: control,
    name: "orderNoTwo",
  });
  useEffect(() => {
    if (orderNoSelected != null) {
      const find = data?.data?.data?.find((a) => {
        return a.id == orderNoSelected;
      });
      if (find) {
        setOperations(
          find?.operations?.map((a) => {
            return { value: a.id, label: `${a.step}.Operation` };
          })
        );
      }
    }
  }, [orderNoSelected]);

  const startDate = moment(dataJunction?.startDate);
  const endDate = moment(dataJunction?.endDate);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[776px] flex flex-col">
      <p className="font-normal text-sm text-secondary-600 mt-2">
        {t("product:wantToSplit")}
      </p>
      <div className="flex flex-row items-center w-full">
        <div className="mt-8 w-[400px]">
          <FinishedJunctionCard
            pages={"purchase"}
            props={{
              ring:
                dataJunction.type === "work"
                  ? "#17B26A"
                  : dataJunction.type === "setup"
                  ? "#EAAA08"
                  : "#98A2B3",
              user: {
                image: dataJunction?.user?.avatar,
              },
              status: dataJunction?.metadata?.qualityStatus,
              product: {
                name: dataJunction?.job?.product?.name,
                code: dataJunction?.job?.orderNo,
                image: dataJunction?.job?.product?.image,
                pieceGreen: dataJunction?.metadata?.madeWorkCount,
                pieceRed: dataJunction?.metadata?.rejectedWorkCount,
              },
              node: {
                name:
                  dataJunction?.station?.metadata?.brand +
                  " " +
                  dataJunction?.station?.metadata?.model,
                image: dataJunction?.station?.metadata?.image,
                process: dataJunction?.internalOperation?.name,
              },

              time: {
                timeGreen:
                  moment(endDate).diff(startDate, "minutes").toFixed(1) + "dk",
              },
            }}
          />
        </div>
      </div>
      <div className="flex  flex-row gap-x-6 w-full">
        <div className="flex flex-col gap-3 w-[49%] mt-4">
          <div className="flex flex-row items-center border-b pb-0.5 w-full">
            <p className="font-semibold text-base text-secondary-900">
              {t("product:firstPart")}
            </p>
            {/* <div className="ml-auto">
              <CloseButton size={"sm"} />
            </div> */}
          </div>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={t("product:orderNo")}
                disabled={true}
                errorMessage={errors?.orderNo?.message}
                validate={errors?.orderNo ? "error" : ""}
              />
            )}
            name="orderNo"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                theme={"product"}
                label={t("product:operationNo")}
                disabled={true}
                errorMessage={errors?.operationNo?.message}
                validate={errors?.operationNo ? "error" : ""}
              />
            )}
            name="operationNo"
          />
          <div className="flex w-full items-center gap-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <HourTimeInput
                  label={t("addShift:startTime")}
                  setValue={moment(timeFormatter(setValue).formatted).format(
                    "HH:mm"
                  )}
                  validate={errors.start ? "error" : ""}
                  onChange={(v) => {
                    setValue(
                      `start`,
                      moment(dataJunction?.startDate)
                        .set("hour", v.split(":")[0])
                        .set("minute", v.split(":")[1])
                        .toDate()
                    );
                  }}
                  value={moment(value).format("HH:mm")}
                  errorMessage={errors.start ? errors.start.message : ""}
                />
              )}
              name="start"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <HourTimeInput
                  label={t("addShift:endTime")}
                  onChange={(v) => {
                    setValue(
                      `end`,
                      moment(dataJunction?.endDate)
                        .set("hour", v.split(":")[0])
                        .set("minute", v.split(":")[1])
                        .toDate()
                    );
                  }}
                  value={moment(value).format("HH:mm")}
                  validate={errors.end ? "error" : ""}
                  errorMessage={errors.end ? errors.end.message : ""}
                />
              )}
              name="end"
            />
          </div>
          <div className="flex w-full items-center gap-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  theme={"product"}
                  label={t("product:quantity")}
                  errorMessage={errors?.piece?.message}
                  validate={errors?.piece ? "error" : ""}
                />
              )}
              name="piece"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  value={value}
                  items={junctionTypes}
                  theme={"product"}
                  label={t("product:junctionType")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.source ? "error" : ""}
                  errorMessage={errors?.source ? errors?.source?.message : ""}
                />
              )}
              name="type"
            />
          </div>
          {isCompetedQuality && (
            <div className="flex w-full items-center gap-4">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    label={t("product:approvedQuantity")}
                    errorMessage={errors?.acceptedPiece?.message}
                    validate={errors?.acceptedPiece ? "error" : ""}
                  />
                )}
                name="acceptedPiece"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    label={t("product:rejectedQuantitys")}
                    errorMessage={errors?.rejectedPiece?.message}
                    validate={errors?.rejectedPiece ? "error" : ""}
                  />
                )}
                name="rejectedPiece"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-3 w-[49%] mt-4">
          <div className="flex flex-row items-center border-b pb-0.5 w-full">
            <p className="font-semibold text-base text-secondary-900">
              {t("product:secondPart")}
            </p>
            {/* <div className="ml-auto">
              <CloseButton size={"sm"} />
            </div> */}
          </div>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                items={orders}
                label={t("product:orderNo")}
                onChange={onChange}
                theme={"product"}
                onBlur={onBlur}
                value={value}
                validate={errors?.orderNo ? "error" : ""}
                errorMessage={errors?.orderNo ? errors?.orderNo?.message : ""}
              />
            )}
            name="orderNoTwo"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Selector
                items={operations}
                label={t("product:operationNo")}
                onChange={onChange}
                onBlur={onBlur}
                theme={"product"}
                value={value}
                validate={errors?.operationNo ? "error" : ""}
                disabled={orderNoSelected ? false : true}
                errorMessage={
                  errors?.operationNo ? errors?.operationNo?.message : ""
                }
              />
            )}
            name="operationNoTwo"
          />
          <div className="flex w-full items-center gap-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <HourTimeInput
                  label={t("addShift:startTime")}
                  setValue={moment(timeFormatter(setValue).formatted).format(
                    "HH:mm"
                  )}
                  validate={errors.start ? "error" : ""}
                  onChange={(v) => {
                    setValue(
                      `startTwo`,
                      moment(dataJunction?.startDate)
                        .set("hour", v.split(":")[0])
                        .set("minute", v.split(":")[1])
                        .toDate()
                    );
                  }}
                  value={moment(value).format("HH:mm")}
                  errorMessage={errors.start ? errors.start.message : ""}
                />
              )}
              name="startTwo"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <HourTimeInput
                  label={t("addShift:endTime")}
                  onChange={(v) => {
                    setValue(
                      `endTwo`,
                      moment(dataJunction?.endDate)
                        .set("hour", v.split(":")[0])
                        .set("minute", v.split(":")[1])
                        .toDate()
                    );
                  }}
                  value={moment(value).format("HH:mm")}
                  validate={errors.end ? "error" : ""}
                  errorMessage={errors.end ? errors.end.message : ""}
                />
              )}
              name="endTwo"
            />
          </div>
          <div className="flex w-full items-center gap-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  theme={"product"}
                  label={t("product:quantity")}
                  errorMessage={errors?.pieceTwo?.message}
                  validate={errors?.pieceTwo ? "error" : ""}
                />
              )}
              name="pieceTwo"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Selector
                  value={value}
                  items={junctionTypes}
                  theme={"product"}
                  label={t("product:junctionType")}
                  onChange={onChange}
                  onBlur={onBlur}
                  validate={errors?.source ? "error" : ""}
                  errorMessage={errors?.source ? errors?.source?.message : ""}
                />
              )}
              name="typeTwo"
            />
          </div>
          {isCompetedQuality && (
            <div className="flex w-full items-center gap-4">
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    label={t("product:approvedQuantity")}
                    errorMessage={errors?.acceptedPieceTwo?.message}
                    validate={errors?.acceptedPieceTwo ? "error" : ""}
                  />
                )}
                name="acceptedPieceTwo"
              />
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    theme={"product"}
                    label={t("product:rejectedQuantitys")}
                    errorMessage={errors?.rejectedPieceTwo?.message}
                    validate={errors?.rejectedPieceTwo ? "error" : ""}
                  />
                )}
                name="rejectedPieceTwo"
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-[50%] flex items-center ml-auto justify-end gap-3 mt-8">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          onClick={closeModal}
        />
        <Button
          type={"submit"}
          label={t("buttons:submitForm")}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};

export default SplitOrderModal;
