import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

const LossTimeCard = ({
  onClick,
  selected,
  selectedCategory,
  icon,
  name,
  value,
  index,
  page,
}) => {
  const { t } = useTranslation();
  const cardStyle = useMemo(() => {
    return {
      backgroundColor: selectedCategory === selected ? "#fff" : "#fff",
    };
  }, [selectedCategory, selected]);

  return (
    <div
      onClick={onClick}
      style={cardStyle}
      className={`flex flex-row shadow-xs items-center gap-3 w-full h-[54px] min-h-[54px] rounded-lg z-10 cursor-pointer px-3 py-2  ${
        page === "energy" && (index === 2 || index === 3)
          ? "sm:w-full"
          : page === "loss"
          ? "sm:min-w-[47%]"
          : ""
      } ${
        selectedCategory === selected
          ? name === t("settingTab:company")
            ? ""
            : name === "C-Count"
            ? " outline  outline-2 outline-[#B9D7AB]"
            : name === t("loss:electricKwh")
            ? " outline  outline-2 outline-[#9BA9D4]"
            : name === t("loss:electricKw")
            ? "outline  outline-2 outline-[#B9D7AB]"
            : name === t("loss:costt")
            ? "outline  outline-2 outline-[#EED59D] "
            : name === t("loss:costThree")
            ? "outline  outline-2 outline-[#E8A4A4] "
            : name === t("loss:timeLoss")
            ? "outline  outline-2 outline-[#B9D7AB]"
            : name === t("loss:costLoss")
            ? "outline  outline-2 outline-[#EED59D]"
            : name === t("loss:energyLoss")
            ? "outline  outline-2 outline-[#9BA9D4]"
            : "border-secondary-300 border"
          : "border-secondary-300 border"
      } `}
    >
      <div className="w-8 h-8 min-w-[32px] min-h-[32px] flex items-center justify-center">
        {icon}
      </div>
      <div
        className={`flex  ${
          name === t("settingTab:company")
            ? "flex-row items-center"
            : "flex-col items-start"
        }  w-full  h-full`}
      >
        <p
          className={`${
            name === t("settingTab:company")
              ? "text-sm font-semibold text-secondary-700 "
              : "text-xs font-normal text-secondary-600 "
          }`}
        >
          {name}
        </p>
        <p
          className={`${
            name === t("settingTab:company")
              ? ""
              : "text-sm font-semibold text-secondary-700"
          }`}
        >
          {value}
        </p>
      </div>
      {name === t("settingTab:company") ? (
        <></>
      ) : name === "C-Count" ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#91CC75]"></div>
        </div>
      ) : name === t("loss:electricKwh") ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#5470C6]"></div>
        </div>
      ) : name === t("loss:electricKw") ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#91CC75]"></div>
        </div>
      ) : name === t("loss:costt") ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#FAC858]"></div>
        </div>
      ) : name === t("loss:costThree") ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#E66]"></div>
        </div>
      ) : name === t("loss:timeLoss") ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#91CC75]"></div>
        </div>
      ) : name === t("loss:costLoss") ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#FAC858]"></div>
        </div>
      ) : name === t("loss:energyLoss") ? (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#5470C6]"></div>
        </div>
      ) : (
        <div className="flex items-center ml-auto">
          <div className="w-[6px] h-6 rounded-[2px] bg-[#5470C6]"></div>
        </div>
      )}
    </div>
  );
};

export default memo(LossTimeCard);
