import React from "react";
import { Button } from "../../components";
import { Arrow } from "../../assets/icons/stepsIcons";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { ArrowRight } from "../../assets/icons/equipmentIcon";
import { RightArrow } from "../../assets/icons/buttonIcons";
import { authService } from "../../services/auth.service";

const EndOfSteps = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useStore();
  return (
    <div className="flex flex-col mt-[176px] gap-4 w-[672px] xs:w-[375px] xs:text-center sm:text-center sm:w-[500px] xs:mt-[20px] items-center justify-center">
      <p className="text-displaySm font-semibold text-secondary-900 dark:text-[#FAFAFA]">
        {auth?.user?.company?.name}, {t("congrats:title")}
      </p>
      <p className="text-lg font-normal text-secondary-500 text-center dark:text-[#D6D6D6]">
        {t("congrats:subTitle")}
      </p>
      <div className="w-[60%] mt-2">
        <Button
          size={"2xl"}
          colorType={"primary-machine"}
          iconRight={<RightArrow className="min-w-[24px] min-h-[24px]" />}
          label={t("person:start")}
          onClick={() => {
            authService?.setSteps(7);
            navigate("/app/machine/digital-twin");
          }}
        />
      </div>
    </div>
  );
};

export default EndOfSteps;
