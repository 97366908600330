import React, { memo, useCallback, useMemo } from "react";
import moment from "moment";
import LossTimeCard from "../../../machine/Card/LossTimeCard";
import LossSelectCard from "../../../machine/Card/LossSelectCard";
import LossesPieChart from "../../../machine/Tabs/LossesPieChart";
import MultipleBarChart from "../../../machine/Tabs/MultipleBarChart";

const MockNodeOperation = ({
  t,
  page,
  Tabs,
  data,
  month,
  tabskey,
  thirdTab,
  setThirdTab,
  selectedLegend,
  setSelectedLegend,
  getLossesData,
  selectedLegend2,
}) => {
  const monthlyData = useMemo(() => {
    return data?.find((item) => {
      return item?.month == moment(month).format("M");
    });
  }, [data, month]);
  const anuallyProcessedData = useMemo(() => {
    return data?.map((month) => {
      return month?.operations?.find((op) => {
        return (
          op?.nodeId == "00000000-0000-0000-0000-000000000000" ||
          op?.nodeId == selectedLegend
        );
      });
    });
  }, [data, selectedLegend, selectedLegend2]);

  const chartData = useMemo(() => {
    return data
      ?.find((item) => {
        return item.month == moment(month).format("M");
      })
      ?.operations.find((op) => {
        return op?.nodeId == selectedLegend;
      });
  }, [data, month, selectedLegend, selectedLegend2]);

  const handleThirdTabClick = useCallback(
    (tabKey) => {
      setThirdTab(tabKey);
    },
    [setThirdTab]
  );

  return (
    <>
      <div className="w-full flex flex-col h-[340px] max-h-[340px] md:h-full md:max-h-full sm:h-full sm:max-h-full xs:h-full xs:max-h-full">
        <div className="flex items-center gap-6 gap-y-3 xs:gap-y-6 h-full xs:px-2 xs:flex-col sm:flex-wrap md:gap-4 z-10 min-w-full">
          <LossSelectCard
            setValue={setSelectedLegend}
            name={
              monthlyData?.operations?.find((op) => {
                return op.nodeId == selectedLegend;
              })?.name
            }
            piece={
              monthlyData?.operations?.find((op) => {
                return op.nodeId == selectedLegend;
              })?.[thirdTab]
            }
            values={monthlyData?.operations
              ?.filter((item) => {
                return item.processId == selectedLegend2;
              })
              .map((item, index) => {
                return {
                  value: `${item.nodeId}`,
                  label: `${item.name}`,
                };
              })}
          />
          {Tabs?.map((tab, index) => {
            return (
              <div
                className={`w-full px-1 lg:px-1 md:px-0 sm:px-0 xs:px-0 ${
                  index === 0 ? "sm:w-[47%]" : "" ? "xs:w-[100%]" : "sm:w-[47%]"
                }`}
                key={index}
              >
                <LossTimeCard
                  page="loss"
                  index={index}
                  name={tab?.name}
                  icon={tab?.icon}
                  value={
                    anuallyProcessedData?.find((item) => {
                      return item?.month == moment(month).format("M");
                    })?.[tab?.key] || "--"
                  }
                  onClick={() => {
                    handleThirdTabClick(tab?.key);
                  }}
                  selected={tab?.key}
                  selectedCategory={thirdTab}
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-row md:flex-col sm:flex-col xs:flex-col items-center gap-2 w-full h-full">
          <div className="w-1/2 h-[274px] max-h-[274px]  md:w-full md:h-[500px] md:max-h-[500px] sm:w-[100%] sm:h-[500px] xs:h-[500px] md:mt-4 xs:w-[100%]">
            <MultipleBarChart
              handleData={getLossesData}
              charts={"loss"}
              categories={data
                ?.sort((a, b) => {
                  if (a.year !== b.year) {
                    return a.year - b.year;
                  } else {
                    return a.month - b.month;
                  }
                })
                ?.map((item) => {
                  return `${item.month}`;
                })}
              series={tabskey?.map((tab) => {
                return {
                  name: tab,
                  type: "bar",
                  barGap: 0,
                  emphasis: {
                    focus: "series",
                  },
                  data: anuallyProcessedData
                    ?.sort((a, b) => {
                      if (a.year !== b.year) {
                        return a.year - b.year;
                      } else {
                        return a.month - b.month;
                      }
                    })
                    ?.map((item) => {
                      return item && item[tab];
                    }),
                };
              })}
            />
          </div>

          <div className="flex items-center gap-2 w-1/2 md:w-full sm:w-full xs:w-full h-full">
            <LossesPieChart
              page={page}
              data={chartData?.equipments?.map((operation) => {
                return {
                  value: operation[thirdTab],
                  id: operation.nodeId,
                  name: `${operation.name} ${operation.lastName}`,
                };
              })}
              setSelectedLegend={() => null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(MockNodeOperation);
