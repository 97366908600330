import React, { useEffect, useRef, useState } from "react";
import { equipmentService, processService } from "../../../services";
import { useQuery } from "@tanstack/react-query";
import { Button, CustomModal } from "../../../components";
import { Loader, PlusIcon } from "../../../assets/icons/stepsIcons";
import { ChevronLeft } from "../../../assets/icons";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import { useTranslation } from "react-i18next";
import { FactoryIcon } from "../../../assets/icons/plannerIcons";
import Swiper from "./Swiper";
import { ArrowRight } from "../../../assets/icons/machineIcons";
import { CompanyIcon } from "../../../assets/icons/stepHeaderIcons";
import { AddProcessModal } from "../../../steps/process";
import { Toast } from "../../../utils/toastify/toast";

const Slider = ({
  enabled,
  selectedProcess,
  setSelectedProcess,
  allProcess,
  selectedNode,
  setSelectedNode,
}) => {
  const { t } = useTranslation();
  const [visibleRightButton, setVisibleRightButton] = useState(false);
  const [modalProcess, setModalProcess] = useState(null);
  const [items, setItems] = useState([]);
  const [processOpen, setProcessOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const contentWrapper = useRef(null);
  const [visibleButton, setVisibleButton] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isCompanyModal, setIsCompanyModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("process");
  const [operation, setOperation] = useState();
  const [openOperation, setOpenOperation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { isLoading, data, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getProcessUtilization"],
    queryFn: async () => await processService.getQueryProcessUtilization(),
  });

  const onDoubleClickProcess = async (item) => {
    await equipmentService.getOperationInformations(item.id).then((res) => {
      setModalProcess(res.data);
    });
    setIsOpen(true);
  };

  const handleData = async () => {
    await processService.activeProcesses().then((res) => {
      res?.data?.map((d) => {
        return {
          ...d,
          responsibleUser: d.responsibleUser.map((r) => r.id),
        };
      });
      setOperation(res.data);
      refetch();
    });
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleEdit = (id) => {
    if (operation?.length > 0) {
      const findOperation = operation?.find((a) => a?.id === id);
      setSelectedItem(findOperation);
    }
    setOpenOperation(true);
  };

  const slide = (shift) => {
    contentWrapper.current.scrollLeft += shift;
  };

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        const container = document.getElementById("container");
        if (container?.scrollWidth > container?.clientWidth) {
          setVisibleRightButton(true);
        } else {
          setVisibleRightButton(false);
        }
      }, 300);
    }
  }, [data, isLoading]);
  return (
    <>
      <Button
        size={"md"}
        colorType={"secondary-gray"}
        iconRight={<PlusIcon />}
        // onClick={() => setProcessOpen(true)}
        onClick={() => Toast("warning", "Demo user's are not allowed.")}
      />
      {visibleButton && (
        <div className="rotate-180">
          <Button
            iconLeft={<ArrowRight />}
            colorType={"secondary-gray"}
            onClick={() => slide(-90)}
            size={"md"}
          />
        </div>
      )}
      <div
        id="container"
        ref={contentWrapper}
        onScroll={(e) => {
          if (!isIntersecting) {
            setVisibleButton(true);
          }
        }}
        className="flex items-center w-[95%] h-32 overflow-x-auto scrollbar-hide gap-6"
      >
        {allProcess === true && (
          <div
            onClick={() => setSelectedProcess("all")}
            className={`px-1 py-1 cursor-pointer max-w-[170px] min-w-[170px] ${
              selectedProcess == process.id || selectedProcess == "all"
                ? "bg-secondary-50"
                : "  bg-[#fff]"
            } flex border  border-secondary-300 justify-center items-center rounded-lg text-[10px]  gap-x-2 max-h-[40px] min-h-[40px]`}
          >
            <div className="flex flex-row items-center justify-center gap-2">
              <div className="text-secondary-600 ">
                <CompanyIcon />
              </div>
              <p
                className={`text-sm font-semibold ${
                  selectedProcess == process.id
                    ? "text-primary-900 dark:text-[#FFFFFF]"
                    : "text-secondary-700 dark:text-[#FFFFFF]"
                }`}
                style={{
                  display: "inline-block",
                  width: 50,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  numberOfLines: 1,
                  textOverflow: "ellipsis",
                }}
              >
                {t("product:all")}
              </p>
            </div>
            {/* <div className="w-auto flex  text-center gap-x-2">
             
            </div> */}
          </div>
        )}
        <div className="w-[170px]  flex gap-6">
          {items?.operations
            ?.sort((a, b) => b?.actual - a?.actual)
            ?.map((process, j) => {
              return (
                process.type !== "planned" && (
                  <Swiper
                    key={j}
                    index={j}
                    process={process}
                    selectedProcess={selectedProcess}
                    setSelectedProcess={setSelectedProcess}
                    // onDoubleClickProcess={getOperationDetails}
                    enabled={enabled}
                    handleEdit={handleEdit}
                    setVisibleButton={setVisibleButton}
                    setIsIntersecting={setIsIntersecting}
                    dataLength={items?.length}
                    setVisibleRightButton={setVisibleRightButton}
                  />
                )
              );
            })}
        </div>
      </div>

      <Button
        size={"md"}
        iconLeft={<ArrowRight />}
        colorType={"secondary-gray"}
        onClick={() => {
          sideScroll(contentWrapper.current, 25, 200, 10);
        }}
      />
      <CustomModal
        children={
          <AddProcessModal
            data={operation}
            isOpen={openOperation}
            setIsOpen={setOpenOperation}
            selectedProcess={selectedItem}
            handleData={handleData}
          />
        }
        isOpen={openOperation}
        setIsOpen={setOpenOperation}
        width="fit-content"
        height="fit-content"
        modalTitle={t("addProcess:updateProcess")}
      />
      <CustomModal
        isOpen={processOpen}
        setIsOpen={setProcessOpen}
        modalTitle={
          modalProcess
            ? t("addProcess:updateProcess")
            : t("addProcess:addNewProcess")
        }
        width="fit-content"
        children={
          <AddProcessModal
            data={data}
            t={t}
            setIsOpen={setProcessOpen}
            isOpen={processOpen}
            selectedProcess={modalProcess}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            handleData={handleData}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default Slider;
