import React from "react";
import ReactECharts from "echarts-for-react";
import useWindowDimensions from "../../../../hooks/useWindowDimension";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MonthlyChart = ({ data, height = "240px", start, end, setDate }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const onChartClick = (params) => {
    if (params?.name) {
      setDate(params.name);
    }
  };
  const onEvents = {
    click: onChartClick,
    legendselectchanged: function (params) {},
  };
  const dates = data?.monthlyChart?.dates.map((item) =>
    String(moment(item).format("YYYY-MM-DD"))
  );

  // manipulating legends
  const modifiedLegends = data?.monthlyChart?.legend.map((legend) => {
    if (legend === "kw") {
      return "kVa";
    } else if (legend === "kWh") {
      return "kVa/h";
    } else if (legend === "kWh Cost") {
      return "kVa/h Cost";
    } else {
      return legend;
    }
  });

  const option = {
    title: {},
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      position: function (pos, params, el, elRect, size) {
        var obj = { top: 10 };
        obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
        return obj;
      },
      extraCssText: "width: 170px",
    },
    legend: {
      data: data?.monthlyChart?.legend,
      // data: modifiedLegends,
      right: "5%",
      left: "2%",
      top: "1%",
      alignTicks: false,
      formatter: function (name) {
        // const modifiedIndex = modifiedLegends.indexOf(name);
        let itemValue = data?.monthlyChart?.series?.filter(
          (item) => item.name === name
        );
        const total = itemValue[0].data.reduce((a, b) => {
          return a + b;
        }, 0);
        return `{name|${name}}:{total|Avg: ${Number((total / 30).toFixed(2))}}`;
      },

      textStyle: {
        rich: {
          name: { fontWeight: "regular", align: "left", fontSize: 10 },
          total: {
            fontSize: 10,
            fontWeight: "regular",
          },
        },
      },
    },
    grid: {
      left: "2%",
      right: "2%",
      bottom: "0%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: dates,
      axisLabel: {
        formatter: function (value, index) {
          return value.slice(8, 10);
        },
        show: true,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "kVa/h",
        position: "right",
        alignTicks: false,
        axisLine: {
          show: true,
          lineStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            left: "10px",
          },
        },
        axisLabel: {
          formatter: "{value} kVa/h",
          show: true,
          textStyle: {
            color: localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            right: "-10px",
          },
        },
      },
      {},
      {},
    ],
    series: data?.monthlyChart?.series,
  };
  return (
    <div
      className=" relative mt-3"
      style={{
        height: height,
        minWidth: "110%",
        marginLeft: "-20px",
      }}
    >
      <ReactECharts
        onEvents={onEvents}
        option={option}
        style={{
          height: height,
          width: width > 1024 ? "92%" : "92%",
          paddingLeft: "5px",
          top: "25px",
          left: "10px",
        }}
      />
      <div
        className={`absolute -top-4 font-normal w-[400px]  gap-2 dark:text-[#F5F5F5] left-9 gap-x-2`}
      >
        <p className="text-sm mt-4 font-semibold inline-block ">
          {t("settingTab:start")} : {start ? start : ""}
        </p>
        <p className="text-sm ml-2 mt-4 font-semibold inline-block ">
          {t("settingTab:end")} : {end ? end : ""}
        </p>
      </div>
    </div>
  );
};

export default MonthlyChart;
