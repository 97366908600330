import React, { useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import generateFileUrl from "../../../utils/generateFileUrl";
import { chatService } from "../../../services/chat.service";
import { useQuery } from "@tanstack/react-query";
import { usePubNub } from "pubnub-react";
import { useStore } from "../../../hooks/useStores";
import { NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";

const ConversationModal = observer(({ setIsOpen }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    retry: 0,
    queryKey: ["getConversationPersonel"],
    queryFn: async () => await chatService.getUsers(),
  });
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = data?.data?.filter((item) =>
    diacritics
      .remove(`${item?.name} ${item?.lastName}`)
      .toLowerCase()
      .includes(diacritics.remove(searchTerm).toLowerCase())
  );

  const createConversation = async (id) => {
    const { data } = await chatService
      .createConversation(id)
      .catch((err) => console.log(err));
    navigate(`/app/person/message/${data.conversationId}`);
  };

  return (
    <div className="w-[500px] h-[600px] flex flex-col ">
      <div className="w-full mt-6">
        <SearchInput
          setSearchVal={setSearchTerm}
          placeholder={t("chat:search")}
        />
      </div>
      <div className="h-[550px] flex flex-col  mt-5 w-full overflow-y-auto scrollbar-hide">
        {filteredData?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                setIsOpen(false);
                createConversation(item?.id);
              }}
              className={`flex flex-row items-center gap-3 px-3 py-2 w-full hover:bg-secondary-50 hover:rounded-lg cursor-pointer`}
            >
              {item.avatar ? (
                <div
                  style={{ border: "1px solid rgba(0, 0, 0, 0.10)" }}
                  className="w-10 h-10 rounded-full dark:border-[#424242] overflow-hidden"
                >
                  <img src={generateFileUrl(item?.avatar)} alt="avatar" />
                </div>
              ) : (
                <div className="w-10 h-10 border bg-secondary-100 border-secondary-300 text-secondary-500 rounded-full flex items-center font-semibold justify-center text-md ">
                  {item?.name?.charAt(0).toUpperCase() +
                    "" +
                    item?.lastName?.charAt(0).toUpperCase()}
                </div>
              )}
              <div className="flex flex-col items-start">
                <p className="">{item?.name + " " + item?.lastName}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default ConversationModal;
