import React from "react";
import Badgets from "../../../components/buttons/Badgets";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AllOperationsCard = ({
  selected,
  onClick,
  isPastDay,
  allCount,
  pastJunctionlength,
  activeJunctionlength,
  pendingJunctionlength,
  type,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      // onClick={() => {
      //   onClick("all");
      //   type && type == "internal"
      //     ? navigate("/app/product/quality-control/internal/all")
      //     : type == "material"
      //     ? navigate("/app/product/quality-control/material-receipt/all")
      //     : navigate("/app/product/quality-control/service-entry/all");
      // }}
      className={`flex flex-col max-w-[302px] min-w-[302px] w-[302px] border-[1.5px] max-h-[142px] h-[96%] rounded-lg shadow-sm cursor-pointer ${
        selected === "all" ? "border-[#DC6803]" : "border-[#EAECF0]"
      }`}
    >
      <div className="flex gap-x-2 border-b border-[#EAECF0] w-full px-2 py-3 justify-center items-center">
        <span
          className={`min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px] ${
            selected === "all" ? "text-[#DC6803]" : "text-[#475467]"
          }`}
        >
          <FactoryIcon />
        </span>
        <span
          className={`text-sm font-semibold ${
            selected === "all" ? "text-[#B54708]" : "text-[#344054]"
          }`}
        >
          {t("product:allNew")}
        </span>
      </div>
      <div className="flex w-full h-full">
        {!isPastDay && (
          <div className="flex w-1/3 h-full border-r py-2 px-1 items-center justify-center gap-y-2 flex-col">
            <Badgets
              colorType={"fill-success"}
              label={activeJunctionlength || 0}
              size={"sm"}
            />
            <div className="flex flex-col gap-y-1 items-center justify-center">
              <span className="text-[#079455] font-medium text-xs">
                {t("product:actives")}
              </span>
            </div>
          </div>
        )}

        <div
          className={`flex flex-col ${
            !isPastDay ? "w-1/3" : "w-1/2"
          } border-r py-2 px-1 ${
            !isPastDay ? "gap-y-2" : "gap-y-[0.5px]"
          } items-center justify-center`}
        >
          <Badgets
            colorType={"fill-warning"}
            label={pendingJunctionlength || 0}
            size={"sm"}
          />
          <div className="flex flex-col gap-y-1 items-center justify-center">
            <span className="text-[#A15C07] font-medium text-xs">
              {t("product:waiting")}
            </span>
          </div>
        </div>
        <div
          className={`flex flex-col ${
            !isPastDay ? "w-1/3" : "w-1/2"
          } py-2 px-1 ${
            !isPastDay ? "gap-y-2" : "gap-y-[0.5px]"
          } items-center justify-center`}
        >
          <Badgets
            colorType={"fill-gray"}
            label={pastJunctionlength || 0}
            size={"sm"}
          />
          <div className="flex flex-col gap-y-1 items-center justify-center">
            <span className="text-[#344054] font-medium text-xs">
              {t("product:completedNew")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const FactoryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_14159_5392"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_14159_5392)">
        <path
          d="M19.4141 17.6562H0.585938V10.9375L9.41406 7.42188V10.9375L19.4141 7.42188V17.6562Z"
          stroke="currentColor"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M8.82812 17.6562V15.3125H11.1719V17.6562"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.14062 17.6562V15.3125H6.48437V17.6562"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5156 17.6562V15.3125H15.8594V17.6562"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.55469 9.75537V2.34389H7.07031V8.35533"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9297 9.70166V2.34389H16.4453V8.46568"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.55469 4.6875H7.07031"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9297 4.6875H16.4453"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AllOperationsCard;
