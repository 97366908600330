import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../components";
import { Loader, PlusIcon } from "../../assets/icons/stepsIcons";
import { shiftService } from "../../services/shift.service";
import { personalService } from "../../services/personal.service";
import Badgets from "../../components/buttons/Badgets";
import { useTranslation } from "react-i18next";
import { Toast } from "../../utils/toastify/toast";
import ShiftCard from "../../steps/shifts/components/ShiftCard";
import moment from "moment";
import NewShift from "../../steps/shifts/modals/NewShift";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { useStore } from "../../hooks/useStores";
import { toast } from "react-toastify";
const SettingShift = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("shiftInfo");
  const [deletedShift, setDeletedShift] = useState("");
  const [selectedShift, setSelectedShift] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [personals, setPersonals] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleShifts = async () => {
    setLoading(true);
    await shiftService.getShifts().then(({ data }) => {
      setShifts(data);
    });
    setLoading(false);
  };

  const handlePersonals = async () => {
    await personalService.activePersonals().then(({ data }) => {
      setPersonals(
        data.map((d) => {
          return {
            value: d.id,
            label: d.name + " " + d.lastName,
          };
        })
      );
    });
  };

  const onDeleted = async (id) => {
    await shiftService
      .deleteShift(id)
      .then(() => Toast("success", t("addShift:deletedShift")));
  };
  useEffect(() => {
    handleShifts();
    handlePersonals();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center mt-64 justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full max-h-[730px] flex xs:h-[78vh] sm:h-[75vh] md:h-[75vh] flex-col gap-5 overflow-hidden">
          <div className="flex xs:flex-col items-center min-h-[72px] xs:gap-4 border-b w-full border-secondary-200">
            <div className="flex flex-col w-full xs:border-b xs:pb-2">
              <p className="text-gray-900 text-lg font-medium dark:text-[#FAFAFA]">
                {t("settingTab:shiftManagement")}
              </p>
              <p className="text-gray-500 text-sm dark:text-[#D6D6D6]">
                {t("settingTab:manageYourShift")}
              </p>
            </div>
            <div className="ml-auto xs:w-full">
              <Button
                colorType={"secondary-gray"}
                label={t("addShift:title")}
                iconLeft={<PlusIcon />}
                size={"md"}
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>
          <div className="w-fit">
            <Badgets
              size={"md"}
              label={
                shifts?.length +
                "/" +
                auth?.user?.company?.limits?.shift +
                " " +
                t("routes:shift")
              }
              colorType={"fill-primary"}
            />
          </div>
          <div className="h-[78%] w-full overflow-y-scroll flex flex-col gap-4 scrollbar-hide">
            {shifts?.map((e, i) => (
              <ShiftCard
                key={i}
                name={`${e?.name.slice(0, 2)} ***`}
                labels={e?.labels}
                id={e?.id}
                responsibleUser={e.responsibleUser}
                description={
                  moment(e?.start).format("HH:mm") +
                  " - " +
                  moment(e?.end).format("HH:mm")
                }
                editOnClick={() => {
                
                }}
                deleteOnclick={() => {
                  
                }}
              />
            ))}
          </div>
        </div>
      )}
      <CustomModal
        onClose={() => {
          setSelectedShift(null);
          setIsOpen(false);
        }}
        children={
          <NewShift
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setIsOpen={setIsOpen}
            selectedShift={selectedShift}
            handleData={handleShifts}
            personals={personals}
          />
        }
        // isOpen={isOpen}
        isOpen={false}
        setIsOpen={setIsOpen}
        // width={"736px"}
      />
      <AlertModal
        title={t("addShift:deleteShift")}
        subTitle={t("addShift:deleteShiftMessage")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={async () => {
          // await onDeleted(deletedShift);
          // await handleShifts();
          setIsAlert(false);
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default SettingShift;
