import React from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftIcon,
  ClockIcon,
  ClockIconStart,
  PlaySquareIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../assets/icons/productIcons";
import { legacyFormatSeconds } from "../../utils/secondToHoursAndMinute";
import generateFileUrl from "../../utils/generateFileUrl";
import Button from "../buttons/Button";

const AssemblyCard = ({
  name = "Montaj",
  hours = "22.2",
  hoursJunctionTime = "33.3",
  previousPiece = 999.999,
  iconKey,
  qualityAcceptedPiece = 999.999,
  qualityRejectedPiece = 12,
  estimatedTime = "15:04",
  newAvgActualCalculate = "13:45",
  totalJunctionLength,
  madeAssembly = 366,
  totalAssembly = 666,

  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={onClick}
        className="flex w-full flex-col h-full bg-white  cursor-pointer"
      >
        <div
          style={{
            backgroundColor: "#FDE172",
          }}
          className={`h-1 max-h-1 min-h-[4px]  rounded-t`}
        ></div>
        <div className="flex w-full hover:bg-secondary-50 h-full border-x pl-3 pr-1 pt-1 pb-[6px] items-center justify-between gap-x-[6px]">
          <div className="flex flex-col w-full h-full">
            <div className="w-full flex items-center overflow-hidden">
              <div className="w-5 h-5 min-w-5 min-h-5 flex items-center justify-center">
                <AssemblyIconBig />
              </div>
              <p className="text-sm text-secondary-700 font-semibold truncate ml-2">
                {name}
              </p>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex border-b pt-[6px] w-full">
                <div className="min-w-[65px] border-r pr-[6px] py-[2px] text-xs font-normal text-[#475467]">
                  Income
                  {/* {t("product:previousIncoming")} */}
                </div>
                <div className="w-[57px] min-w-[57px] border-r px-[6px] py-[2px] flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {hours}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:hours")}
                  </span>
                </div>
                <div className="w-[80px] min-w-[80px] px-[6px] py-[2px] flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {previousPiece || "---"}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="min-w-[65px] border-r pr-[6px] py-[2px] text-xs font-normal text-[#475467]">
                  {t("product:applied")}
                </div>
                <div className="w-[57px] min-w-[57px] border-r px-[6px] py-[2px] flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {hoursJunctionTime || "--"}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:hours")}
                  </span>
                </div>
                <div className="w-[80px] min-w-[80px] px-[6px] py-[2px] flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {qualityAcceptedPiece || "---"}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col border border-secondary-200 bg-white w-[86px] min-w-[86px]  rounded">
            <div className="flex bg-[#F9FAFB] rounded-t  px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
              <QualityIcon />
              <p className="text-secondary-700 font-semibold text-[10px]">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col  items-center justify-center">
              <div className="flex  py-1 px-2 items-center">
                <QuantityAcceptedIcon />
                <span className="text-xs font-medium  text-[#079455] ml-[2px]">
                  {qualityAcceptedPiece || "--"}
                </span>
                <span className="text-xxs text-[#667085] ml-[2px]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex py-1 px-2 items-center">
                <QuantityDeclineIcon />
                <span className="text-xs font-medium text-[#D92D20] ml-[2px]">
                  {qualityRejectedPiece || "---"}
                </span>
                <span className="text-xxs text-[#667085] ml-[2px]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="border min-h-[26px] max-h-[26px] hover:bg-[#F2F4F7] rounded-b-lg bg-[#F9FAFB] pl-3 pr-[6px] py-[3px] flex flex-row gap-x-3 items-center">
          <div className="flex h-full items-center">
            <TargetIcon />
            <span className="text-xs text-[#344054] ml-[2px] font-medium">
              {estimatedTime || "---"}
            </span>
            <span className="text-[10px] ml-[2px] leading-[18px] text-[#475467]">
              tgt min
              {/* {t("product:targetMin")} */}
            </span>
          </div>
          <div
            className={`flex h-full items-center gap-x-[2px] text-[#079455]`}
          >
            <AverageIcon />
            <span className="text-xs ml-1 font-medium text-[#079455]">
              {newAvgActualCalculate || "---"}
            </span>
            <span className="text-[10px] ml-[2px] leading-[18px] text-[#475467]">
              min avg
              {/* {t("product:minPieceAvg")} */}
            </span>
          </div>
          <div className="flex h-full items-center gap-x-[2px]">
            <AssemblyIcon />
            <span className="text-xs text-[#344054] font-medium">
              {madeAssembly || "--"}/{totalAssembly || "--"}
            </span>
          </div>

          <div className="w-4 h-4 min-w-4 min-h-4 flex items-center ml-auto justify-center">
            <ArrowDownIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssemblyCard;

const AssemblyIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M10.7695 1.16669H3.2307C2.09081 1.16669 1.16675 2.09075 1.16675 3.23064V10.7694C1.16675 11.9093 2.09081 12.8334 3.23071 12.8334H10.7695C11.9094 12.8334 12.8334 11.9093 12.8334 10.7694V3.23064C12.8334 2.09075 11.9093 1.16669 10.7695 1.16669Z"
        stroke="#475467"
        stroke-miterlimit="10"
      />
      <path
        d="M10.9283 7H3.07202"
        stroke="#475467"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M5.64941 3.49399L6.96352 4.80812C6.98367 4.82827 7.01634 4.82827 7.03649 4.80812L8.35059 3.49399"
        stroke="#475467"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M8.35059 10.506L7.03649 9.19188C7.01634 9.17173 6.98367 9.17173 6.96352 9.19188L5.64941 10.506"
        stroke="#475467"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
};

const AverageIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.66667 7.58333V9.91667M9.33333 6.41667V9.91667M7 4.08333V9.91667M4.55 12.25H9.45C10.4301 12.25 10.9201 12.25 11.2945 12.0593C11.6238 11.8915 11.8915 11.6238 12.0593 11.2945C12.25 10.9201 12.25 10.4301 12.25 9.45V4.55C12.25 3.56991 12.25 3.07986 12.0593 2.70552C11.8915 2.37623 11.6238 2.10852 11.2945 1.94074C10.9201 1.75 10.4301 1.75 9.45 1.75H4.55C3.56991 1.75 3.07986 1.75 2.70552 1.94074C2.37623 2.10852 2.10852 2.37623 1.94074 2.70552C1.75 3.07986 1.75 3.56991 1.75 4.55V9.45C1.75 10.4301 1.75 10.9201 1.94074 11.2945C2.10852 11.6238 2.37623 11.8915 2.70552 12.0593C3.07986 12.25 3.56991 12.25 4.55 12.25Z"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const TargetIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M11 6H9M3 6H1M6 3V1M6 11V9M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QualityIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g mask="url(#mask0_13331_70317)">
        <path
          d="M11.1016 7C11.1016 9.26524 9.26524 11.1016 7 11.1016C4.73476 11.1016 2.89844 9.26524 2.89844 7C2.89844 4.73476 4.73476 2.89844 7 2.89844C9.26524 2.89844 11.1016 4.73476 11.1016 7Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.05078 5.76953L6.58984 8.23047L4.94922 6.58984"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.46805 0.606006C6.77463 0.344873 7.22537 0.344873 7.53194 0.606006L8.59789 1.54677L10.4017 1.40718C10.8032 1.37612 11.1679 1.64105 11.2624 2.0325L11.6241 3.53028L13.2001 4.4737C13.5432 4.68457 13.6825 5.11327 13.5289 5.4855L12.9062 6.99424L13.5289 8.50299C13.6825 8.87525 13.5432 9.30394 13.2001 9.51482L11.6241 10.4582L11.2624 11.956C11.1679 12.3474 10.8032 12.6124 10.4017 12.5813L8.61552 12.4431L7.53194 13.394C7.22537 13.6551 6.77463 13.6551 6.46805 13.394L5.38448 12.4431L3.59827 12.5813C3.19676 12.6124 2.83207 12.3474 2.73757 11.956L2.37448 10.4523L0.801062 9.51482C0.457953 9.30394 0.318664 8.87525 0.472308 8.50299L1.09493 6.99424L0.472308 5.4855C0.318664 5.11327 0.457953 4.68457 0.801062 4.4737L2.23962 3.61907L2.73757 2.0325C2.83207 1.64105 3.19676 1.37612 3.59827 1.40718L5.40214 1.54677L6.46805 0.606006Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

const AssemblyIconBig = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.3849 0.666687H3.61526C1.98684 0.666687 0.666748 1.98678 0.666748 3.61519V14.3848C0.666748 16.0133 1.98684 17.3334 3.61526 17.3334H14.3849C16.0133 17.3334 17.3334 16.0133 17.3334 14.3848V3.61519C17.3334 1.98678 16.0133 0.666687 14.3849 0.666687Z"
        stroke="#475467"
        stroke-width="1.33"
        stroke-miterlimit="10"
      />
      <path
        d="M14.6117 9H3.38843"
        stroke="#475467"
        stroke-width="1.33"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M7.07056 3.99145L8.94785 5.86877C8.97663 5.89756 9.0233 5.89756 9.05209 5.86877L10.9294 3.99145"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M10.9294 14.0085L9.05209 12.1312C9.0233 12.1024 8.97663 12.1024 8.94785 12.1312L7.07056 14.0085"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
};

const ArrowDownIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="#475467"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
