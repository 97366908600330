import React from "react";
import { NoDataPerson } from "../../../assets/illustrations";
import { useTranslation } from "react-i18next";

const Attend = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex items-center justify-center flex-col">
      <NoDataPerson />
      <p className="text-gray-700 font-medium text-xl mt-5">
        {" "}
        {t("chat:comingSoon")}
      </p>
    </div>
  );
};

export default Attend;
