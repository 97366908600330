import React from "react";

const MultipleEventRect = ({
  event,
  handleMouseOver,
  handleMouseOut,
  handleMouseMove,
  index,
  startPos,
  width,
  widthS,
  totalWidth,

  setSelectedJunction,
  onClick,
  junctionNumber,
}) => {
  let yOffset = 86;
  let junctionColor;
  if (event?.color === "#EAAA08") {
    junctionColor = "#EAAA08";
  } else if (event?.color === "#12B76A") {
    junctionColor = "#079455";
  } else if (event?.color === "#D92D20") {
    junctionColor = "#D92D20";
  } else {
    junctionColor = event?.color;
  }
  return (
    <svg onMouseMove={handleMouseMove} width={totalWidth}>
      {event?.index == 0 ? (
        <>
          <rect
            onClick={onClick}
            key={index}
            x={startPos}
            y={94}
            width={width}
            rx={"4"}
            height="24"
            stroke={junctionColor}
            fill={junctionColor}
            onMouseOver={(e) => handleMouseOver(event, startPos, yOffset, e)}
            onMouseOut={handleMouseOut}
            style={{
              cursor: "pointer",
            }}
          />

          {width > 30 ? (
            <>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos + 22}
                y={yOffset + 25}
                font-family="Inter"
                font-size="14"
                fill="#FFF"
                onMouseOver={(e) =>
                  handleMouseOver(event, startPos, yOffset, e)
                }
                onMouseOut={handleMouseOut}
              >
                {junctionNumber(event?.junctionId)}
              </text>
              {event?.junctionType === "losses" ? (
                <svg
                  x={startPos + 6}
                  y={yOffset + 14}
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM7 2.5C7 1.94772 6.55228 1.5 6 1.5C5.44772 1.5 5 1.94772 5 2.5V6C5 6.55228 5.44772 7 6 7C6.55228 7 7 6.55228 7 6V2.5ZM6 8.5C5.44772 8.5 5 8.94772 5 9.5C5 10.0523 5.44772 10.5 6 10.5H6.01C6.56228 10.5 7.01 10.0523 7.01 9.5C7.01 8.94772 6.56228 8.5 6.01 8.5H6Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  x={startPos + 6}
                  y={yOffset + 14}
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM4.08189 3.13742C4.00708 3.23734 4.00708 3.41632 4.00708 3.77428V8.32628C4.00708 8.68425 4.00708 8.86323 4.08189 8.96315C4.14708 9.05023 4.24686 9.10471 4.35536 9.11246C4.47987 9.12135 4.63042 9.02456 4.93154 8.83099L8.47198 6.55499C8.73327 6.38702 8.86391 6.30303 8.90904 6.19624C8.94847 6.10293 8.94847 5.99764 8.90904 5.90433C8.86391 5.79753 8.73327 5.71355 8.47198 5.54558L4.93153 3.26958C4.63042 3.076 4.47987 2.97922 4.35536 2.98811C4.24686 2.99586 4.14708 3.05034 4.08189 3.13742Z"
                    fill="white"
                  />
                </svg>
              )}
            </>
          ) : width >= 6 ? (
            <>
              {" "}
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos + 2}
                y={yOffset + 18}
                font-family="Inter"
                font-size="14"
                fill="#FFF"
                onMouseOver={(e) =>
                  handleMouseOver(event, startPos, yOffset, e)
                }
                onMouseOut={handleMouseOut}
              >
                {junctionNumber(event?.junctionId)}
              </text>
            </>
          ) : (
            <>
              <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos - 2}
                y={yOffset + 25}
                font-family="Inter"
                font-size="14"
                // fill={event?.color}
                fill="#FFF"
                onMouseOver={(e) =>
                  handleMouseOver(event, startPos, yOffset, e)
                }
                onMouseOut={handleMouseOut}
              >
                {/* .. */}
                {junctionNumber(event?.junctionId)}
              </text>
            </>
          )}

          {event?.timeDiff > 2500 && (
            <>
              {/* <text s
                style={{
                  cursor: "pointer",
                }}
                x={startPos}
                y={ 110}
                font-family="Verdana"
                font-size="10"
                fill="#black"
                onMouseOver={() => handleMouseOver(event, startPos)}
                onMouseOut={handleMouseOut}
              >
                {moment(event.starts).format("HH:mm")}
              </text> */}
              {/* <text
                style={{
                  cursor: "pointer",
                }}
                x={startPos + width - 20}
                y="110"
                font-family="Verdana"
                font-size="10"
                fill="#black"
                onMouseOver={() => handleMouseOver(event, startPos)}
                onMouseOut={handleMouseOut}
              >
                {moment(event.ends).format("HH:mm")}
              </text> */}
            </>
          )}
        </>
      ) : (
        //status
        <g>
          <rect
            key={index}
            x={startPos}
            y={30}
            width={widthS}
            height="60"
            fill={event?.color == "#FFFFFF" ? "#F9FAFB" : event?.color}
            onMouseOver={(e) =>
              handleMouseOver(event, startPos, yOffset - 30, e)
            }
            onMouseOut={handleMouseOut}
            style={{ cursor: "pointer" }}
            rx={"2"}
            // strokeWidth="1"
            // stroke={hexToRGB("#000000", 10)}

            // stroke="red"
          />
        </g>
      )}
    </svg>
  );
};

export default MultipleEventRect;
