import React, { useEffect, useState } from "react";
import { NewAndonButton, NewDynamicTower } from "./svg";
import { useTranslation } from "react-i18next";

const StatusColors = () => {
  const [state, setState] = useState(false);
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(0);
  const [selectedCard, setSelectedCard] = useState({
    index: 0,
    color: "#9DA4AE",
    hoverColor: "#475467",
    status: "Gray (No Light)",
    info: [
      {
        title: t("tower:grayTitle"),
        text: t("tower:grayText"),
      },
    ],
  });
  const towerStatus = [
    {
      index: 0,
      color: "#9DA4AE",
      hoverColor: "#475467",
      status: "Gray (No Light)",
      info: [
        {
          title: t("tower:grayTitle"),
          text: t("tower:grayText"),
        },
      ],
    },
    {
      index: 1,
      color: "#FAC515",
      hoverColor: "#EAAA08",
      status: "Yellow",
      info: [
        {
          title: t("tower:yellowTitle"),
          text: t("tower:yellowText"),
        },
      ],
    },
    {
      index: 2,
      color: "#54CF8D",
      hoverColor: "#039855",
      status: "Green",
      info: [
        {
          title: t("tower:greenTitle"),
          text: t("tower:greenText"),
        },
      ],
    },
    {
      index: 3,
      color: "#F04438",
      hoverColor: "#D92D20",
      status: "Red",
      info: [
        {
          title: t("tower:redTitle"),
          text: t("tower:redText"),
        },
      ],
    },
    {
      index: 4,
      color: "#C7A4F8",
      hoverColor: "#7F56D9",
      status: "Purple",
      info: [
        {
          title: t("tower:purpleTitle1"),
          text: t("tower:purpleText1"),
        },
        {
          title: t("tower:purpleTitle2"),
          text: t("tower:purpleText2"),
        },
        {
          title: t("tower:purpleTitle3"),
          text: t("tower:purpleText3"),
        },
      ],
    },
    {
      index: 5,
      color: "#C7A4F8",
      hoverColor: "#7F56D9",
      status: "Purple Flashing",
      info: [
        {
          title: t("tower:purpleFlashingTitle"),
          text: t("tower:purpleFlashingText"),
        },
      ],
    },
    {
      index: 6,
      color: "#EAECF0",
      hoverColor: "#98A2B3",
      status: "White",
      info: [
        {
          title: t("tower:whiteTitle1"),
          text: t("tower:whiteText1"),
        },
        {
          title: t("tower:whiteTitle2"),
          text: t("tower:whiteText2"),
        },
        {
          title: t("tower:whiteTitle3"),
          text: t("tower:whiteText3"),
        },
      ],
    },

    {
      index: 7,
      color: "#84CAFF",
      hoverColor: "#7CD4FD",
      status: "Light Blue",
      info: [
        {
          title: t("tower:lightBlueTitle"),
          text: t("tower:lightBlueText"),
        },
      ],
    },
    {
      index: 8,
      color: "#2E90FA",
      hoverColor: "#155EEF",
      status: "Dark Blue",
      info: [
        {
          title: t("tower:darkBueTitle1"),
          text: t("tower:darkBlueText1"),
        },
        {
          title: t("tower:darkBueTitle2"),
          text: t("tower:darkBlueText2"),
        },
        {
          title: t("tower:darkBueTitle3"),
          text: t("tower:darkBlueText3"),
        },
      ],
    },

    {
      index: 9,
      color: "#DC6803",
      hoverColor: "#DC6803",
      status: "Orange",
      info: [
        {
          title: t("tower:orangeTitle"),
          text: t("tower:orangeText"),
        },
      ],
    },
    {
      index: 10,
      color: "#039855",
      hoverColor: "#D92D20",
      status: "Flashing Red Andon Button",
      info: [
        {
          title: t("tower:flashingRedTitle"),
          text: t("tower:flashingRedText"),
        },
      ],
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setState((prevState) => !prevState);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-[870px] flex flex-col ">
      <div className="flex justify-between mt-5 h-full">
        <div className="flex w-[62%] flex-wrap gap-6">
          {towerStatus.map((item, index) => {
            return (
              <>
                <div
                  onClick={() => {
                    setSelectedCard(item);
                  }}
                  onMouseEnter={() => setIsHover(index)}
                  onMouseLeave={() => setIsHover(null)}
                  style={{
                    borderColor:
                      selectedCard?.index === index
                        ? item?.hoverColor
                        : isHover === index
                        ? item?.hoverColor
                        : "",
                  }}
                  className={`w-[30%] h-[148px] rounded-md border-t-[6px] border  transition-all flex items-center flex-col gap-y-1 justify-center cursor-pointer group duration-300`}
                >
                  {index === 10 ? (
                    <NewAndonButton
                      width={82}
                      height={68}
                      color={state ? "red" : "#f1f1f1"}
                      className={`mb-6 translate-y-3.5 transition-all duration-300`}
                    />
                  ) : (
                    <div className="h-[106px] w-[40px] overflow-visible flex items-center justify-center">
                      <NewDynamicTower
                        height={
                          index == isHover || selectedCard?.index === index
                            ? 106
                            : 96
                        }
                        width={
                          index == isHover || selectedCard?.index === index
                            ? 40
                            : 30
                        }
                        color={
                          item?.index === 5
                            ? state
                              ? "#C7A4F8"
                              : "#9DA4AE"
                            : item?.color
                        }
                        className="transition-all duration-300"
                      />
                    </div>
                  )}
                  <p
                    style={{
                      color:
                        selectedCard?.index === index
                          ? item?.hoverColor
                          : isHover === index
                          ? item?.hoverColor
                          : "#98a2b3",
                    }}
                    className={`text-center text-xs font-codec`}
                  >
                    {item?.status}
                  </p>
                </div>
              </>
            );
          })}
        </div>
        <div
          className="flex w-[35%] items-center justify-center gap-y-3.5 py-3 px-2 border-t-[6px] rounded-md border flex-col"
          style={{ borderColor: selectedCard?.hoverColor }}
        >
          {selectedCard?.index === 10 ? (
            <NewAndonButton width={240} height={220} />
          ) : (
            <NewDynamicTower
              color={
                selectedCard?.index === 5
                  ? state
                    ? "#C7A4F8"
                    : "#9DA4AE"
                  : selectedCard?.color
              }
            />
          )}
          <p
            className="font-codec font-semibold mt-2"
            style={{ color: selectedCard?.hoverColor }}
          >
            {selectedCard?.status}
          </p>
          {selectedCard?.info?.map((t, i) => {
            return (
              <div className="flex flex-col items-center gap-1 justify-center w-full">
                <p className="text-sm text-gray-700 font-medium text-center">
                  {t?.title}
                </p>

                <p className="text-xs text-gray-500 mt-1 text-center">
                  {t?.text}
                </p>
                {selectedCard?.info?.length == 3 && (
                  <div
                    style={{ display: i == 2 ? "none" : "block" }}
                    className="w-[90%] h-px bg-gray-300 translate-y-1.5"
                  />
                )}
                {selectedCard?.info?.length == 2 && (
                  <div
                    style={{ display: i == 1 ? "none" : "block" }}
                    className="w-[90%] h-px bg-gray-300 translate-y-1.5"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StatusColors;
