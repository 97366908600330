import React, { useEffect, useRef, useState } from "react";
import {
  EyeIcon,
  MenuIcon,
  PollDeleteIcon,
} from "../../../assets/icons/PersonIcons";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { pollService } from "../../../services/poll.service";
import { Toast } from "../../../utils/toastify/toast";
import { useNavigate } from "react-router-dom";

const PollHeader = observer(({ title, setSelectedGroup, data }) => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const navigate = useNavigate();
  const [pollItem, setPollItem] = useState({});
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deletePoll = async (id) => {
    await pollService
      .deletePoll(id)
      .then((res) => {
        if (res?.data?.code == 1) {
          Toast("error", res?.data?.message);
        } else {
          Toast("success", res?.data?.message);
        }
        setDeleteAlert(false);
        navigate("/app/person/poll");
      })
      .catch((err) => console.log(err));
  };

  const menuItems = [
    // {
    //   name: "Görenler",
    //   icon: <EyeIcon currentColor={"#667085"} />,
    //   onClick: () => {
    //     setSelectedGroup(data);
    //   },
    //   index: 1,
    // },
    {
      name: t("person:deletePoll"),
      icon: <PollDeleteIcon />,
      onClick: () => {
        setPollItem(data?._id);
        setDeleteAlert(true);
      },
      index: 2,
    },
  ];

  return (
    <>
      <div className="w-full flex items-center border-b border-secondary-200 pb-3  px-1">
        <div className="flex w-full items-center">
          <p className="font-semibold text-secondary-900 text-lg ">{title ? title?.slice(0, 3)+" ***" : "---"}</p>
          <div className="flex flex-col relative ml-auto" ref={menuRef}>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="w-8 h-8 flex items-center justify-center rotate-90"
              type="button"
            >
              <MenuIcon size={20} />
            </button>
            {isOpen && (
              <div className="absolute hidden w-[190px] p-1 bg-white right-0 top-8 border border-secondary-200 rounded-lg shadow-sm flex-col gap-y-1">
                {menuItems.map((item, index) => {
                  return (
                    <button
                      onClick={() => {
                        item.onClick();
                        setIsOpen(false);
                      }}
                      key={index}
                      type="button"
                      className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                    >
                      <div className="w-6">{item.icon}</div>
                      <p
                        className={`font-medium ${
                          item.index === 2
                            ? "text-red-600"
                            : "text-secondary-700"
                        } text-sm `}
                      >
                        {item.name}
                      </p>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <AlertModal
        title={t("person:deletePoll")}
        subTitle={t("person:areDeletePoll")}
        isOpen={deleteAlert}
        setIsOpen={setDeleteAlert}
        applyButton={() => {
          deletePoll(data?._id);
        }}
      />
    </>
  );
});

export default PollHeader;
