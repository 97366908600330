import React, { useEffect, useState } from "react";
import { junctionService } from "../../../services/junction.service";
import generateFileUrl from "../../../utils/generateFileUrl";
import Junction from "../../../components/junction/Junction";
import SearchInput from "../../../components/inputs/SearchInput";
import { instance } from "../../../libs/client";
import { Button } from "../../../components";
import { PlayIcon } from "../../../assets/icons/productIcons";
import diacritics from "diacritics";
import { useTranslation } from "react-i18next";

export default function SelectSupplierModal({
  processId,
  selectedJunction,
  currentOperation,
  setIsOpen,
  handleData,
}) {
  const { t } = useTranslation();
  const [suppllierData, setSupplierData] = useState(null);
  const [selectedEqu, setSelectedEqu] = useState();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(
          `external-operation/list/supplier/${processId}`
        );
        setSupplierData(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (processId) {
      fetchData();
    }
  }, [processId]);

  const filteredUsers = suppllierData?.filter((d) =>
    diacritics
      .remove(`${d?.name}`)
      .toLowerCase()
      .includes(diacritics.remove(searchValue).toLowerCase())
  );

  return (
    <div className="flex w-[350px] flex-col h-full gap-y-6 mt-6 ">
      {selectedEqu ? (
        <Junction
          props={{
            onPressUser: function () {},
            node: {
              name: selectedEqu?.name,
              process: currentOperation?.internalOperation?.process?.name,
              id: selectedEqu?.id,
              image: selectedEqu?.image,
            },
            product: {
              name: selectedJunction?.job?.product?.name,
              code: selectedJunction?.job?.orderNo,
              image: selectedJunction?.job?.product?.image,
              piece: selectedJunction?.targetedWorkCount,
            },
          }}
        />
      ) : (
        <Junction
          props={{
            onPressUser: function () {},
            onPressNode: function () {},
            node: {
              name: "--",
              process: currentOperation?.internalOperation?.process?.name,
              id: "test-id",
            },
            product: {
              name: selectedJunction?.job?.product?.name,
              code: selectedJunction?.job?.orderNo,
              image: selectedJunction?.job?.product?.image,
              piece: selectedJunction?.targetedWorkCount,
            },
          }}
        />
      )}
      {selectedEqu ? (
        <>
          <Button
            colorType={"primary-machine"}
            size={"md"}
            iconLeft={<PlayIcon />}
            label={t("buttons:plan")}
            onClick={async () => {
              await junctionService
                .startExternalPlannedJunction(
                  selectedJunction?.id,
                  selectedEqu?.id
                )
                .then((response) => {
                  setIsOpen(false);
                  handleData();
                });
            }}
          />
        </>
      ) : (
        <div className="flex flex-col gap-y-4">
          <SearchInput setSearchVal={setSearchValue} />
          <div className="flex flex-col max-h-[330px] mt-2 min-h-[330px] overflow-y-auto scrollbar-hide gap-y-2 border-y">
            {filteredUsers?.map((d, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setSelectedEqu(d)}
                  className="border px-3 py-[10px] rounded-lg shadow-xs border-[#D0D5DD] flex gap-x-3 hover:bg-[#F9FAFB] cursor-pointer"
                >
                  {d?.image ? (
                    <img
                      className="min-w-[60px] max-w-[60px] min-h-[45px] max-h-[45px] rounded border border-[#0000001A]"
                      src={generateFileUrl(d?.image)}
                    />
                  ) : (
                    <div
                      className={
                        "min-w-[60px] min-h-[45px] max-w-[60px] max-h-[45px] rounded bg-[#DC6803] flex border-4 border-[#DC6803] items-center justify-center font-medium text-white text-xl"
                      }
                    >
                      {d?.name?.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className="flex w-[70%] items-center">
                    <span className="text-sm font-semibold text-[#344054] truncate">
                      {d?.name}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
