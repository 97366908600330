import React, { memo } from "react";
import {
  ChevronLeft,
  GlobalLoginIcon,
  PersonIllustrator,
  UpuPerson,
} from "../../../assets/icons";
import { PersonLoginIcon } from "../../../assets/icons/stepsIcons";

const PersonSlider = ({ handleNext, handlePrev }) => {
  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(13.13deg, #0E9384 9.64%, #134E48 90.99%)",
        }}
        className="w-full h-full flex flex-col items-center py-8 xs:py-2 xs:pt-5"
      >
        {/* <img src={GlobalLoginIcon} color={"#2ED3B7"} className="xs:w-[135px] xs:h-[36px]" /> */}
        <PersonLoginIcon />

        <p className="text-2xl lg:text-base xs:text-xs font-extralight text-primary-100 mt-4 xs:mt-2 md:text-lg">
          Bird’s eye-view of manufacturing
        </p>

        <img
          src={PersonIllustrator}
          alt="Person Sketch"
          className="mt-4 lg:w-[460px] w-[460px] lg:h-[460px] h-[460px] xs:w-[225px] xs:h-[214px] xs:-translate-y-3 md:max-w-[380px]"
        />
        <div className="mt-auto lg:mt-0 w-full flex items-center flex-col">
          <img
            src={UpuPerson}
            alt="Person text icon"
            className="lg:w-[166px] lg:h-[26px] lg:mt-20 xs:h-[26px] xs:max-w-[166px] xs:mt-2"
          />
          <div className="my-3 w-[234px] xs:w-[178px] h-9 flex items-center justify-between">
            <button
              type="button"
              // onClick={() => {
              //   index.slidePrev();
              // }}
              onClick={handlePrev}
              className="w-9 h-9 xs:w-7 xs:h-7 rounded-lg hover:bg-teal-600 flex items-center justify-center"
            >
              <img
                src={ChevronLeft}
                color="#fff"
                width={24}
                height={24}
                alt="Left arrow button"
              />
            </button>

            <div className="h-full w-[86px] p-3 flex items-center justify-between">
              <div
                // onClick={() => {
                //   index.slidePrev();
                //   index.slidePrev();
                // }}

                onClick={handlePrev}
                className="w-2.5 h-2.5 rounded-full bg-teal-400 cursor-pointer hover:bg-white"
              />
              <div
                // onClick={() => {
                //   index.slidePrev();
                // }}
                onClick={handlePrev}
                className="w-2.5 h-2.5 rounded-full bg-teal-400 cursor-pointer hover:bg-white"
              />
              <div className="w-2.5 h-2.5 rounded-full bg-white" />
            </div>

            <div className="w-9 h-9 xs:w-7 xs:h-7 rounded-lg flex items-center justify-center">
              <img
                src={ChevronLeft}
                color="#2ED3B7"
                width={24}
                height={24}
                className="rotate-180"
                alt="Right arrow button"
              />
            </div>
          </div>

          <div className="w-[62%] my-3 h-[72px] xs:hidden">
            <p className="text-sm leading-6 text-white text-center lg:text-xs md:text-xs">
              In addition to ready-made modules such as messaging, surveys and
              suggestions, you will have access to all of your employees'
              performance data.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Icon = () => {
  return (
    <svg
      width="272"
      height="74"
      viewBox="0 0 272 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.5694 62.1846C89.5845 62.1846 86.3708 61.0187 83.9284 58.6868C81.486 56.3549 80.2639 53.2812 80.2622 49.4656V33.1426H87.8978V48.595C87.8978 50.6039 88.4004 52.174 89.4057 53.3054C90.411 54.4368 91.7989 55.0033 93.5694 55.005C95.3399 55.005 96.7217 54.435 97.7149 53.295C98.7081 52.155 99.2013 50.5883 99.1944 48.595V33.1452H106.825V49.4682C106.825 53.2821 105.616 56.3558 103.197 58.6894C100.779 61.023 97.5698 62.1889 93.5694 62.1872"
        fill="white"
      />
      <path
        d="M140.997 39.6362C139.762 37.3935 137.927 35.5397 135.696 34.2833C133.387 32.9819 130.775 32.3159 128.125 32.353C124.621 32.353 121.598 33.4758 119.057 35.7213V33.1303H112.725V71.5827H120.363V59.8612C122.646 61.4366 125.362 62.2666 128.136 62.2371C130.784 62.2704 133.394 61.5979 135.696 60.2887C137.927 59.0226 139.763 57.1613 140.997 54.9125C142.255 52.561 142.912 49.9357 142.912 47.2692C142.912 44.6026 142.255 41.9773 140.997 39.6258V39.6362ZM132.989 52.8008C131.534 54.2673 129.7 54.9997 127.485 54.998C125.271 54.9963 123.437 54.2639 121.982 52.8008C120.531 51.3292 119.806 49.4853 119.806 47.2692C119.806 45.053 120.533 43.21 121.987 41.7401C123.443 40.2684 125.278 39.5326 127.49 39.5326C129.703 39.5326 131.538 40.2649 132.994 41.7297C134.453 43.191 135.182 45.0375 135.18 47.2692C135.179 49.5008 134.45 51.3482 132.994 52.8112"
        fill="white"
      />
      <path
        d="M160.223 62.1846C156.238 62.1846 153.025 61.0187 150.582 58.6868C148.14 56.3549 146.919 53.2812 146.919 49.4656V33.1426H154.554V48.595C154.554 50.6039 155.056 52.174 156.06 53.3054C157.063 54.4368 158.451 55.0033 160.223 55.005C161.992 55.005 163.374 54.435 164.369 53.295C165.364 52.155 165.858 50.5883 165.851 48.595V33.1452H173.486V49.4682C173.486 53.2821 172.277 56.3558 169.859 58.6894C167.441 61.023 164.229 62.188 160.223 62.1846Z"
        fill="white"
      />
      <path
        d="M186.764 53.2207H178.693V61.3952H186.764V53.2207Z"
        fill="white"
      />
      <path
        d="M192.127 22.6797H199.737V29.7815H192.127V22.6797ZM192.127 33.5332H199.763V61.7746H192.127V33.5332Z"
        fill="#2ED3B7"
      />
      <path
        d="M219.425 62.5737C216.697 62.6108 214.005 61.9423 211.611 60.6331C209.324 59.3863 207.428 57.5297 206.134 55.2698C204.836 52.9362 204.154 50.3099 204.154 47.6395C204.154 44.9691 204.836 42.3428 206.134 40.0091C207.431 37.7579 209.327 35.9103 211.611 34.6718C214.017 33.4057 216.695 32.7441 219.414 32.7441C222.133 32.7441 224.81 33.4057 227.216 34.6718C229.504 35.9155 231.404 37.767 232.707 40.0221C234.013 42.3507 234.699 44.9759 234.699 47.646C234.699 50.316 234.013 52.9412 232.707 55.2698C231.404 57.5281 229.505 59.3838 227.216 60.6331C224.83 61.9399 222.146 62.6083 219.425 62.5737ZM214.01 53.2463C215.425 54.6869 217.23 55.4063 219.425 55.4046C221.621 55.4029 223.426 54.6834 224.841 53.2463C226.252 51.8092 226.958 49.9489 226.96 47.6654C226.962 45.3819 226.255 43.5225 224.841 42.0871C223.426 40.6482 221.621 39.9288 219.425 39.9288C217.23 39.9288 215.425 40.6482 214.01 42.0871C212.599 43.5242 211.894 45.3836 211.894 47.6654C211.894 49.9472 212.599 51.8075 214.01 53.2463Z"
        fill="#2ED3B7"
      />
      <path
        d="M236.399 24.8673H237.575C237.745 24.865 237.913 24.9042 238.065 24.9813C238.203 25.0514 238.319 25.1592 238.399 25.2922C238.48 25.433 238.521 25.5934 238.518 25.756C238.52 25.876 238.497 25.995 238.451 26.1058C238.404 26.2046 238.338 26.2928 238.256 26.3649C238.172 26.4404 238.073 26.4985 237.966 26.5359L238.583 27.5722H238.01L237.471 26.6058H236.899V27.5722H236.399V24.8673ZM237.557 26.2068C237.64 26.2071 237.721 26.1876 237.795 26.1498C237.864 26.1134 237.921 26.0587 237.961 25.9918C238.001 25.9196 238.021 25.8384 238.021 25.756C238.021 25.6773 238 25.6001 237.958 25.5332C237.918 25.4654 237.86 25.4099 237.79 25.3725C237.718 25.3328 237.637 25.3123 237.554 25.3129H236.899V26.2068H237.557Z"
        fill="white"
      />
      <path
        d="M237.392 28.938C236.86 28.938 236.339 28.78 235.895 28.4839C235.452 28.1878 235.107 27.767 234.903 27.2746C234.699 26.7822 234.646 26.2404 234.75 25.7177C234.854 25.195 235.11 24.7149 235.487 24.3381C235.864 23.9612 236.344 23.7046 236.867 23.6006C237.389 23.4966 237.931 23.55 238.424 23.7539C238.916 23.9579 239.337 24.3033 239.633 24.7464C239.929 25.1895 240.087 25.7105 240.087 26.2434C240.087 26.9581 239.803 27.6435 239.298 28.1488C238.793 28.6541 238.107 28.938 237.392 28.938ZM237.392 24.0022C236.949 24.0022 236.516 24.1337 236.147 24.3799C235.779 24.6262 235.492 24.9762 235.322 25.3858C235.152 25.7953 235.108 26.2459 235.194 26.6807C235.281 27.1154 235.494 27.5147 235.808 27.8282C236.121 28.1416 236.521 28.3551 236.955 28.4415C237.39 28.528 237.841 28.4836 238.25 28.314C238.66 28.1444 239.01 27.8571 239.256 27.4886C239.502 27.12 239.634 26.6867 239.634 26.2434C239.633 25.6492 239.397 25.0796 238.976 24.6594C238.556 24.2393 237.987 24.0029 237.392 24.0022Z"
        fill="white"
      />
      <path
        d="M54.8363 47.1171C54.8363 50.6155 54.1656 54.0797 52.8626 57.3117C51.5596 60.5437 49.6497 63.4802 47.2422 65.9535C44.8346 68.4268 41.9765 70.3884 38.8311 71.7262C35.6857 73.064 32.3147 73.7519 28.9106 73.7505V62.4841C31.6043 62.5111 34.2595 61.8266 36.6219 60.4962C38.9015 59.2149 40.7945 57.3144 42.0942 55.0023C43.3963 52.617 44.0802 49.9278 44.0802 47.1927C44.0802 44.4577 43.3963 41.7685 42.0942 39.3832C40.7962 37.0731 38.9025 35.1764 36.6219 33.9026C34.2238 32.6057 31.5546 31.928 28.8447 31.928C26.1349 31.928 23.4657 32.6057 21.0676 33.9026C18.7949 35.1691 16.9079 37.0579 15.6159 39.3593C14.7043 40.976 14.0987 42.7555 13.8314 44.6037C9.90145 42.008 6.66886 38.4395 4.43031 34.2256C2.19177 30.0116 1.019 25.2873 1.01965 20.4863H28.9106C32.3146 20.4846 35.6856 21.1721 38.8309 22.5097C41.9763 23.8472 44.8344 25.8085 47.242 28.2816C49.6496 30.7547 51.5595 33.691 52.8625 36.9229C54.1656 40.1547 54.8363 43.6188 54.8363 47.1171Z"
        fill="white"
      />
      <path d="M54.8364 24.581V12.29H74.7707L54.8364 24.581Z" fill="white" />
      <path
        d="M54.8362 6.14413V12.2883H34.8993C34.1134 12.2893 33.3351 12.1312 32.6087 11.8229C31.8823 11.5146 31.2222 11.0623 30.666 10.4917C30.1098 9.92106 29.6685 9.2434 29.3673 8.49742C29.0661 7.75145 28.9109 6.95178 28.9105 6.14413V5.73292e-07H48.8474C49.6327 -0.000348058 50.4104 0.158315 51.136 0.46693C51.8617 0.775545 52.521 1.22806 53.0765 1.79863C53.6319 2.3692 54.0725 3.04664 54.3731 3.79225C54.6737 4.53787 54.8284 5.33705 54.8284 6.14413"
        fill="white"
      />
      <path
        d="M34.2639 52.8945C32.8659 54.356 31.0849 55.0929 28.9207 55.1053H28.8665C26.6766 55.1053 24.8774 54.3684 23.4691 52.8945C22.242 51.5966 21.5089 49.8891 21.4031 48.0827C21.3773 47.7881 21.3644 47.4882 21.3644 47.1777C21.3644 44.8421 22.0685 42.9374 23.4769 41.4635C24.8852 39.9896 26.6843 39.2518 28.8743 39.25C31.0642 39.25 32.8634 39.9878 34.2717 41.4635C35.68 42.9391 36.3842 44.8439 36.3842 47.1777C36.379 49.5185 35.6722 51.4241 34.2639 52.8945Z"
        fill="#2ED3B7"
      />
    </svg>
  );
};

export default memo(PersonSlider);
