import React, { useState } from "react";
import { Button } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FilterIcon } from "../machine/embeddedServer/svg";
import SearchInput from "../../components/inputs/SearchInput";
import { ScrollSyncPane } from "react-scroll-sync";
import { DeleteIcon, EditIcon } from "../../assets/icons/departmentIcon";
import generateFileUrl from "../../utils/generateFileUrl";
import { InfoIcon } from "../../assets/icons/PersonIcons";
import { QuantityIcon } from "../../assets/icons/stepsIcons";
import { QuantityDeclineIcon } from "../../assets/icons/productIcons";
import SupplyProductCard from "../person/components/SupplyProductCard";
import { useQuery } from "@tanstack/react-query";
import { orderService } from "../../services/order.service";
import moment from "moment";
import diacritics from "diacritics";

function Supply() {
  const { settings, auth } = useStore();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("active");
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading, refetch } = useQuery({
    queryKey: "purchaseTransactions",
    queryFn: async () => {
      return await orderService.ListPurchaseTransactions();
    },
    retry: 0,
  });

  const tableHeadItems = [
    { name: t("product:productsAndMaterials "), value: "image", type: "" },

    { name: t("product:orderNoStockRequest"), value: "companyName", type: "" },
    {
      name: t("product:orderQuantityss"),
      value: "productionType",
      type: "string",
    },
    { name: t("product:cost"), value: "phoneNumber", type: "number" },
    {
      name: t("product:creationDates"),
      value: "authorization",
      type: "string",
    },
    { name: t("product:delivery"), value: "address", type: "string" },
    { name: t("product:suppliers"), value: "address", type: "string" },
    { name: "", value: "email", type: "string" },
  ];

  const filteredSupplier = data?.filter((d) =>
    diacritics
      .remove(`${d?.name} ${d?.productionType}`)
      .toLowerCase()
      .includes(diacritics.remove(searchQuery).toLowerCase())
  );
  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <span className="mr-3 md:flex sm:flex xs:flex hidden">
          <Button
            colorType={"tertiary-gray"}
            iconLeft={<HumburgerIcon />}
            size={"md"}
            onClick={() => settings.setIsActive(true)}
          />
        </span>
        <div className="flex items-center">
          <span className="font-codecMedium text-2xl text-black">upu.</span>
          <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
        </div>
        <span className="ml-auto">
          <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:supplierList")}
            onClick={() => navigate("list")}
          />
        </span>
      </div>
      <div className="flex w-full min-h-[40px] max-h-[40px] items-center">
        <div className="flex border h-full items-center justify-center rounded-lg text-sm font-semibold text-secondary-700 whitespace-nowrap cursor-pointer">
          <div
            onClick={() => setSelectedTab("active")}
            className={`flex w-1/2 items-center justify-center h-full px-4 py-2 ${
              selectedTab === "active" ? "bg-gray-100" : ""
            }`}
          >
            {t("product:actives")}
          </div>
          <div
            onClick={() => setSelectedTab("waiting")}
            className={`flex w-1/2 items-center justify-center border-l h-full px-4 py-2 ${
              selectedTab === "waiting" ? "bg-gray-100" : ""
            }`}
          >
            {t("product:waiting")}
          </div>
          <div
            onClick={() => setSelectedTab("past")}
            className={`flex w-1/2 items-center justify-center border-l h-full px-4 py-2 ${
              selectedTab === "past" ? "bg-gray-100" : ""
            }`}
          >
            {t("product:pasted")}
          </div>
        </div>
        <div className="flex ml-auto h-full items-center gap-x-3 min-h-[40px] max-h-[40px]">
          <span className="flex min-w-[220px] max-w-[220px] h-full items-center justify-center">
            <SearchInput setSearchVal={setSearchQuery} theme={"product"} />
          </span>
          <Button
            colorType={"secondary-gray"}
            size={"md"}
            label={t("product:sorted")}
            iconLeft={<SwitchIcon />}
          />
          <Button
            colorType={"secondary-gray"}
            size={"md"}
            label={t("product:filters")}
            iconLeft={<FilterIcon />}
          />
        </div>
      </div>
      <div className="flex-1 w-full mt-4 border rounded-xl flex flex-col overflow-hidden relative sm:px-11">
        <ScrollSyncPane>
          <div
            id="content-wrapper"
            className="min-w-full max-w-full w-full h-[86vh] rounded-xl overflow-x-auto scrollbar-hide flex flex-col"
          >
            <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
              {tableHeadItems.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={`flex px-4 py-3 ${
                      i === 5
                        ? "w-[8%] sm:min-w-[150px] sm:max-w-[150px]"
                        : i === 0
                        ? "w-[25%] sm:min-w-[200px] sm:max-w-[200px]"
                        : i === 2
                        ? `w-[13%] sm:min-w-[200px] sm:max-w-[200px] hidden`
                        : i === 1
                        ? "w-[13%] sm:min-w-[200px] sm:max-w-[200px]"
                        : i === 3
                        ? "w-[13%] sm:min-w-[200px] sm:max-w-[200px]"
                        : i === 4
                        ? "w-[8%] sm:min-w-[200px] sm:max-w-[200px]"
                        : i == 6
                        ? `w-[10%] sm:min-w-[200px] sm:max-w-[200px]`
                        : i == 7
                        ? `w-[10%] sm:min-w-[200px] sm:max-w-[200px]`
                        : ""
                    }] items-center text-center bg-secondary-50 border-gray-200 border-b border-r`}
                  >
                    <span
                      className={`text-center rounded flex text-xs font-medium ${
                        i === 1 || i === 6 ? "" : "justify-center"
                      }  items-center whitespace-nowrap`}
                    >
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full bg-[#FFF] flex-1 flex flex-col">
              {filteredSupplier
                ?.filter((item) => {
                  if (selectedTab === "active") {
                    return item?.status === "started";
                  } else if (selectedTab === "waiting") {
                    return item?.status === "waiting";
                  } else {
                    return item?.status === "applied";
                  }
                })
                ?.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className={`flex w-full items-center border-b h-[106px] min-h-[106px] max-h-[106px]`}
                    >
                      <div className="py-2 px-3 overflow-hidden flex items-center justify-center border-r h-[106px] w-[25%] md:w-[91px] sm:w-[93px] xs:w-[93px]">
                        <SupplyProductCard item={item} />
                      </div>
                      <div className="w-[13%] border-r h-full p-3 flex items-center  sm:min-w-[200px] sm:max-w-[200px]">
                        <p className="w-[90%] whitespace-nowrap  overflow-hidden text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                          {item?.rawMaterial?.stockCode ||
                            item?.product?.stockCode ||
                            "--"}
                        </p>
                      </div>
                      <div className="flex flex-col border-r h-full p-3 justify-center w-[13%] sm:min-w-[150px] sm:max-w-[150px]">
                        <span className="flex gap-x-[2px] items-center">
                          <p className="text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                            {item?.orderedUnit}
                          </p>
                          <p className="text-xs text-[#667085] dark:text-[#F5F5F5] font-normal mt-[1px]">
                            {item?.unitType}
                          </p>
                        </span>
                        <p
                          className={`w-[90%] text-xs ${
                            item?.acceptedUnit === item?.orderedUnit
                              ? "text-[#079455]"
                              : item?.acceptedUnit === null
                              ? "text-[#667085]"
                              : "text-[#DC6803]"
                          } dark:text-[#F5F5F5] font-normal`}
                        >
                          {item?.acceptedUnit || "--"}/
                          {item?.orderedUnit || "--"} {t("product:purchaseds")}
                        </p>
                      </div>
                      <div className="flex flex-col border-r h-full p-3 justify-center w-[13%] sm:min-w-[150px] sm:max-w-[150px]">
                        <span className="flex gap-x-[2px] items-center">
                          <p className="text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                            ₺ {item?.totalCost}
                          </p>
                        </span>
                        <p className="w-[90%] text-xs text-[#667085] dark:text-[#F5F5F5] font-normal">
                          ₺ {item?.unitCost} {t("product:unitCost")}
                        </p>
                      </div>
                      <div className="border-r h-full p-3 flex items-center justify-center w-[8%] sm:min-w-[150px] sm:max-w-[150px]">
                        <p className="w-[90%] whitespace-nowrap overflow-hidden text-center text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                          {moment(item?.createdDate).format("DD.MM.YYYY")}
                        </p>
                      </div>
                      <div className="border-r h-full p-3 flex items-center justify-center w-[8%] sm:min-w-[150px] sm:max-w-[150px]">
                        <p className="w-[90%] whitespace-nowrap overflow-hidden text-center text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                          {item?.estimatedDeliveryDate
                            ? moment(item?.estimatedDeliveryDate).format(
                                "DD.MM.YYYY"
                              )
                            : "--.--.----"}
                        </p>
                      </div>
                      <div
                        className={`h-full border-r flex  p-3 items-center w-[10%] sm:min-w-[150px] sm:max-w-[150px]`}
                      ></div>
                      <div
                        className={`h-full border-r flex  p-3 items-center w-[10%] sm:min-w-[150px] sm:max-w-[150px]`}
                      >
                        <Button
                          colorType={"secondary-gray"}
                          iconLeft={<InfoIcon />}
                          label={"Detaylar"}
                          onClick={() =>
                            navigate(`details/${item?.type + "+" + item?.id}`)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </ScrollSyncPane>
      </div>
    </div>
  );
}

export default Supply;

const SwitchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83333 3.33337V16.6667M5.83333 16.6667L2.5 13.3334M5.83333 16.6667L9.16667 13.3334M14.1667 16.6667V3.33337M14.1667 3.33337L10.8333 6.66671M14.1667 3.33337L17.5 6.66671"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
