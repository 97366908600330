import React, { memo, useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { settingsStore } from "../../stores/settings.store";
import {
  TurkishFlag,
  BritishFlag,
  CheckIcon,
  UpuIo,
} from "../../assets/icons/stepsIcons";
import { Button } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { RightArrow } from "../../assets/icons/buttonIcons";
import { stepData } from "../step-navigation/components/StepHeaderTab";

const Language = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedLanguage, setSelectedlanguage] = useState({
    language: "",
  });

  const LanguageFlag = [
    { id: "tr", title: "TR", img: <TurkishFlag /> },
    { id: "en", title: "EN", img: <BritishFlag /> },
  ];

  const handleLanguage = (languageId) => {
    if (languageId !== i18next.language) {
      i18next.changeLanguage(languageId);
      setSelectedlanguage({ language: languageId });
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const size = screenWidth <= 575 ? "xs" : "sm";

  return (
    <>
      <div className="w-full h-full xs:mt-[52px] md:max-h-[60vh] flex items-center flex-col overflow-hidden md:mt-12 sm:mt-12 mt-[144px] lg:mt-16">
        {i18next.language === "tr" ? (
          <p className="xs:text-[32px] xs:flex-wrap justify-center flex flex-row gap-2 text-[44px] leading-10 font-semibold dark:text-[#FAFAFA] text-[#101828]">
            <span className=" xs:mt-1">
              <UpuIo
                color={settingsStore.theme === "light" ? "#141414" : "#FAFAFA"}
                size={size}
              />
            </span>
            {t("onboard:title")}
          </p>
        ) : (
          <p className="xs:text-[32px] xs:flex-wrap justify-center flex flex-row gap-2 text-[44px] leading-10 font-semibold dark:text-[#FAFAFA] text-[#101828]">
            {t("onboard:title")}
            <span className=" xs:mt-1">
              <UpuIo
                color={settingsStore.theme === "light" ? "#141414" : "#FAFAFA"}
                size={size}
              />
            </span>
          </p>
        )}

        <p className="text-center xs:text-base dark:text-[#D6D6D6] text-[#667085] text-lg font-normal leading-7 max-w-[632px] xs:w-10/12  mt-6 ">
          {t("onboard:subTitle")}
        </p>

        <div className=" items-center my-12 gap-x-3 flex  ">
          {LanguageFlag?.map((language) => (
            <div
              key={language?.id}
              onClick={() => handleLanguage(language?.id)}
              className="flex items-center cursor-pointer"
            >
              <div className="flex items-center h-5 px-4 pb-10 py-6 gap-x-3 border-b-[2px] border-secondary-200 dark:border-[#292929]">
                <div
                  id={language?.id}
                  name="notification-method"
                  type="checkbox"
                  checked={language?.id === i18next.language}
                  onChange={() => {
                    setSelectedlanguage({ language: language?.id });
                  }}
                  className={`h-6 w-6 rounded-md border border-primary ${
                    language.id === i18next.language
                      ? "border-[#7F56D9] dark:border-[#B692F6] bg-[#7F56D9]"
                      : "border-[#D0D5DD] dark:border-[#525252]"
                  }   focus:ring-indigo-500 dark:focus:ring-[#B692F6] flex items-center justify-center`}
                >
                  {language?.id === i18next.language && (
                    <CheckIcon
                      color={
                        settingsStore.theme === "light" ? "#FFFFFF" : "#B692F6"
                      }
                    />
                  )}
                </div>
                {language?.img}
                <p className="text-[#101828] font-semibold text-base dark:text-[#FAFAFA]">
                  {language?.title}{" "}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="max-w-[350px] w-[350px] z-10 dark:z-50 max-h-[60px] flex xs:min-w-[335px] xs:max-w-[335px] xs:min-h-[48px] xs:max-h-[48px] ">
          <Button
            label={t("buttons:start")}
            size={"2xl"}
            colorType={"primary-machine"}
            iconRight={<RightArrow />}
            onClick={() => {
              const index = stepData?.findIndex((route) =>
                pathname?.includes(route?.path)
              );
              navigate("/step/" + stepData[index + 1]?.path);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default memo(Language);
