import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import { EditIcon, RefreshIcon } from "./svg/EmbeddedServerIcons";
import CustomModal from "../../../components/modal/CustomModal";
import DigitalSensorSettings, {
  digital_sensor_statuses,
} from "./DigitalSensorSettings";
import { useTranslation } from "react-i18next";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { Toast } from "../../../utils/toastify/toast";

const DigitalSensorBox = ({
  name,
  isActive,
  deviceId,
  metadataId,
  whichSensor,
  registeredSensor,
  getRegisteredSensors,
}) => {
  const { t } = useTranslation();
  const [resetAlert, setResetAlert] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const resetSensor = async () => {
    const values = {
      deviceId,
      whichSensor,
      status: "reset",
      metadata: metadataId,
    };
    await embeddedServerService.resetDigital(values).then((res) => {
      Toast("success", t("embedded:registerDeleted"));
      setResetAlert(false);
      getRegisteredSensors();
    });
  };

  const sensorData = {
    sensorType: registeredSensor?.type?.name,
    activeOutput:
      registeredSensor?.activeOutput === false
        ? 0
        : registeredSensor?.activeOutput === true
        ? 1
        : "--",
    status: registeredSensor?.status,
    valueType: registeredSensor?.valueOrFreq,
  };
  return (
    <>
      <div className="w-1/3 h-[256px] rounded-xl border border-gray-300 shadow py-3 px-5">
        <div className="w-full flex items-center">
          <div
            className="w-[18px] h-[18px] rounded"
            style={{ backgroundColor: isActive ? "#17B26A" : "red" }}
          />
          <p className="text-lg font-semibold text-secondary-900 ml-3">
            {name}
          </p>
          <div className="ml-auto -mr-2 flex items-center gap-2">
            <Button
              // onClick={() => {
              //   setResetAlert(true);
              // }}
              onClick={() => Toast("warning", "Demo users are nit authorized, contact administration.")}
              size={"md"}
              blur={"blur-sm"}
              iconLeft={<RefreshIcon />}
              colorType={"secondary-error"}
            />
            <Button
              disabled={!isActive}
              // onClick={() => {
              //   setIsOpenModal(true);
              // }}
              onClick={() => Toast("warning", "Demo users are nit authorized, contact administration.")}
              size={"md"}
              blur={"blur-sm"}
              iconLeft={
                <div style={{ opacity: isActive ? 1 : 0.5 }}>
                  <EditIcon />
                </div>
              }
              colorType={"secondary-embedded"}
            />
          </div>
        </div>
        <div className="w-full flex flex-col border-b border-secondary-300 pb-3 mt-2">
          <p className="text-secondary-500 text-sm">
            {t("embedded:sensorType")}
          </p>
          <span className="text-secondary-700 font-medium">
            {/* {sensorData.sensorType || "---"} */}
            {sensorData?.sensorType ? sensorData?.sensorType?.slice(0, 2)+ " ***" : "****"}
          </span>
        </div>
        <div className="w-full flex flex-col border-b border-secondary-300 pb-3 mt-2">
          <p className="text-secondary-500 text-sm">
            {t("embedded:activeOutput")}
          </p>
          <span className="text-secondary-700 font-medium">
            {String(sensorData.activeOutput) || "--"}
          </span>
        </div>

        <div className="w-full flex items-center justify-between mt-2">
          <div className="w-1/2">
            <p className="text-secondary-500 text-sm">Value Type</p>
            <span className="text-secondary-700 font-medium">
              {sensorData.valueType?.replace(/^\w/, (first) =>
                first.toUpperCase()
              ) || "---"}
            </span>
          </div>
          <div className="w-1/2">
            <p className="text-secondary-500 text-sm">{t("embedded:status")}</p>
            <span className="text-secondary-700 font-medium">
              {digital_sensor_statuses.find(
                (item) => item.value === sensorData.status
              )?.label || "---"}
            </span>
          </div>
        </div>
      </div>
      <CustomModal
        modalTitle={name}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        children={
          <DigitalSensorSettings
            deviceId={deviceId}
            metadataId={metadataId}
            whichSensor={whichSensor}
            registeredSensor={registeredSensor}
            getRegisteredSensors={getRegisteredSensors}
            onClose={() => {
              setIsOpenModal(false);
            }}
          />
        }
      />
      <AlertModal
        isOpen={resetAlert}
        setIsOpen={setResetAlert}
        title={t("embedded:resetSensor")}
        subTitle={t("embedded:willBeReset")}
        label={t("buttons:reset")}
        applyButton={resetSensor}
      />
    </>
  );
};

export default React.memo(DigitalSensorBox);
