import React, { useState } from "react";
import { Button } from "../../../../components";
import EndDatePicker from "../../../person/components/EndDatePicker";
import moment from "moment";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
} from "../assets/qualitySvg";

const QualityHeader = ({ date, setDate, subtitle = "" }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="w-full flex items-center border-b-[1px] border-secondary py-2 mb-4">
      <div className="flex items-center">
        <span className="font-codecMedium text-2xl text-black">upu.</span>
        <p className="text-2xl font-codecMedium text-[#B54708]">quality</p>
        <p className="text-xl ml-3 text-[#475467]   ">{subtitle}</p>
      </div>
      <div className="ml-auto flex items-center gap-3 sm:hidden xs:hidden">
        <Button
          iconLeft={<ArrowLeftIcon />}
          size={"md"}
          colorType={"secondary-gray"}
          onClick={() =>
            setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"))
          }
        />
        <EndDatePicker
          date={date}
          visible={visible}
          setDate={setDate}
          setVisible={setVisible}
          buttonClassName={"flex flex-col items-center"}
          calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
          visibleFooter
        >
          <Button
            onClick={() => setVisible(!visible)}
            iconLeft={<CalendarIcon />}
            size={"md"}
            colorType={"secondary-gray"}
            label={moment(date).format("DD.MM.YYYY")}
          />
        </EndDatePicker>
        <Button
          disabled={
            moment(new Date()).format("YYYY-MM-DD") ===
            moment(new Date(date)).format("YYYY-MM-DD")
          }
          iconLeft={<ArrowRightIcon />}
          size={"md"}
          colorType={"secondary-gray"}
          onClick={() =>
            setDate(moment(date).subtract(-1, "days").format("YYYY-MM-DD"))
          }
        />
      </div>
    </div>
  );
};

export default QualityHeader;
