import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { productService } from "../../../services/product.service";
import {
  Button,
  CustomModal,
  Input,
  Loading,
  UploadProfile,
} from "../../../components";
import CropperPdf from "../../../components/inputs/CropperPdf";
import { instance } from "../../../libs/client";
import Preview from "../../../components/modal/Preview";
import Selector from "../../../components/inputs/Selector";
import { Toast } from "../../../utils/toastify/toast";
import { useQuery } from "@tanstack/react-query";
import { rawMaterialService } from "../../../services/raw-material.service";
import TextArea from "../../../components/inputs/TextArea";
import { useNavigate, useLocation } from "react-router-dom";
import generateFileUrl from "../../../utils/generateFileUrl";
export default function NewProduct({ t, setIsOpen }) {
  const { data } = useQuery({
    queryKey: ["raw-material-sources"],
    queryFn: async () => await rawMaterialService.getRawMaterials(),
  });
  const [pdfData, setPdfData] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropData, setCropData] = useState("");
  const [croppedDataCDN, setCroppedDataCDN] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdfDataCDN, setPdfDataCDN] = useState("");
  const [isCrop, setIsCrop] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const onClose = () => setIsOpen(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const schema = yup.object({
    image: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    code: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stockCode: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    materialId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .notRequired()
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  const uploadBase64 = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setPdfDataCDN(response.data?.file);
  };
  const uploadBase64Cropped = async (file) => {
    const data = { imageBinary: file };
    const response = await instance({
      method: "post",
      url: "file/upload/base64",
      data: { data },
    });
    setCroppedDataCDN(response?.data?.file);
  };
  useEffect(() => {
    pdfData && uploadBase64(pdfData);
    cropData && uploadBase64Cropped(cropData);
  }, [pdfData, cropData]);
  return (
    <>
      <div className="flex mt-6 overflow-auto scrollbar-hide w-[566px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex min-w-full w-full h-full flex-col gap-6">
            <div className="flex w-[566px] px-1 gap-6">
              <div className="flex w-1/2 flex-col gap-4">
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <UploadProfile
                      title={t("fileInput:uploadAvatar")}
                      subTitle={t("fileInput:removePicture")}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={errors?.image ? errors?.image?.message : ""}
                      validate={errors?.image ? "error" : ""}
                    />
                  )}
                  name="image"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      theme={"product"}
                      label={t("product:productName")}
                      validate={errors?.name ? "error" : ""}
                      errorMessage={errors?.name ? errors?.name?.message : ""}
                    />
                  )}
                  name="name"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("product:productCode")}
                      onChange={onChange}
                      onBlur={onBlur}
                      theme={"product"}
                      value={value}
                      validate={errors?.code ? "error" : ""}
                      errorMessage={errors?.code ? errors?.code?.message : ""}
                    />
                  )}
                  name="code"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      label={t("product:stockCode")}
                      onChange={onChange}
                      theme={"product"}
                      onBlur={onBlur}
                      value={value}
                      validate={errors?.stockCode ? "error" : ""}
                      errorMessage={
                        errors?.stockCode ? errors?.stockCode?.message : ""
                      }
                    />
                  )}
                  name="stockCode"
                />
              </div>
              <div className="h-full w-px bg-gray-200" />
              <div className="flex w-1/2 flex-col gap-4">
                <CropperPdf
                  pdfData={croppedImage}
                  setPdfData={setPdfData}
                  setIsCrop={setIsCrop}
                  pdfLink={generateFileUrl(pdfDataCDN)}
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Selector
                      value={value}
                      theme={"product"}
                      items={data?.map((item) => {
                        return { label: item?.name, value: item?.id };
                      })}
                      label={t("product:rawMaterial")}
                      onChange={onChange}
                      onBlur={onBlur}
                      validate={errors?.materialId ? "error" : ""}
                      errorMessage={
                        errors?.materialId ? errors?.materialId?.message : ""
                      }
                    />
                  )}
                  name="materialId"
                />
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextArea
                      label={t("product:description")}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      height={122}
                      theme={"product"}
                      validate={errors?.description ? "error" : ""}
                      errorMessage={
                        errors?.description ? errors?.description?.message : ""
                      }
                    />
                  )}
                  name="description"
                />
              </div>
            </div>
            <div className="flex w-full gap-3 px-1">
              <div className="flex w-1/2">
                <Button
                  onClick={() => closeModal()}
                  colorType={"secondary-gray"}
                  label={t("buttons:cancel")}
                  size={"md"}
                />
              </div>
              <div className="flex w-1/2">
                {loading ? (
                  <div className="w-[275px] h-[42px] border bg-[#DC6803] border-[#DC6803] rounded-lg items-center justify-center flex">
                    <Loading size={32} primary={"#B54708"} secondary={"#fff"} />
                  </div>
                ) : (
                  <Button
                    colorType={"primary-product"}
                    label={t("product:createProduct")}
                    size={"md"}
                    type="submit"
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <CustomModal
        isOpen={isCrop}
        setIsOpen={setIsCrop}
        modalTitle={t("product:pdfCropper")}
        children={
          <Preview
            file={pdfData}
            setCropData={setCropData}
            setIsCrop={setIsCrop}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
          />
        }
      />
    </>
  );
}
