import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { screens } from "../App";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../hooks/useStores";
import { instance } from "../libs/client";
import { UpuIoLogo } from "../assets/icons/modalIcons";
import PackageTab from "./PackageTab";
import {
  ChangeIcon,
  LogoutIcon,
  SettingsIcon,
  UpuHeaderIcon,
} from "../assets/icons/leftMenuIcons";
import generateFileUrl from "../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import CustomModal from "./modal/CustomModal";
import ChangeCompanyModal from "./modal/ChangeCompanyModal";
import LoadingOverlay from "./loading/LoadingOverlay";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { Toast } from "../utils/toastify/toast";
import { ArrowDownIcon } from "../assets/icons/stepsIcons";
const MemorizedTab = memo(PackageTab);

const LeftMenu = observer(({}) => {
  const { settings } = useStore();
  const { t } = useTranslation();
  const { auth, app } = useStore();
  const { pathname } = useLocation();
  const isEmbeddedPage = pathname.includes("embedded-server");
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(null);
  const [selectedTab, setSelectedTab] = useState(
    auth.user?.rolesKey !== "ADMIN" ? "person" : "machine"
  );
  const [loading, setLoading] = useState(false);
  const [openSubHeader, setOpenSubHeader] = useState(null);
  // const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  async function logOut() {
    setLoading(true);
    await instance.post(`auth/logout`, { token: auth.token.access_token });
    auth?.logOut();
    navigate("/auth/login");
    setLoading(false);
  }

  const changeCompany = async () => {
    setLoading(true);

    await instance.get(`auth/me/${auth.user?.id}`).then((res) => {
      setLoading(true);
      if (res?.data?.company) {
        auth.setCompanyList(res?.data?.company);
        setLoading(false);
        setIsOpen(true);
      } else {
        setLoading(false);
      }
    });
  };

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      runInAction(() => {
        settings.setIsActive(false);
      });
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const segmentColor = useCallback(() => {
    if (pathname.includes("person")) {
      return {
        backgroundColor: "#107569",
        borderColor: "#0E9384",
        textColor: "#5FE9D0",
        buttonColor: "#0E9384",
        labelColor: "#CCFBEF",
        labelActiveColor: "#F0FDF9",
        segmentName: "person",
      };
    } else if (pathname.includes("product")) {
      return {
        backgroundColor: "#B54708",
        borderColor: "#DC6803",
        textColor: "#FEC84B",
        buttonColor: "#DC6803",
        labelColor: "#FEF0C7",
        labelActiveColor: "#FFFAEB",
        segmentName: "product",
      };
    } else if (pathname.includes("embedded-server")) {
      return {
        backgroundColor: "#363F72",
        borderColor: "#3E4784",
        textColor: "#FFFFFF",
        buttonColor: "#3E4784",
        labelColor: "#D5D9EB",
        labelActiveColor: "#EAECF5",
        segmentName: "machine",
      };
    } else {
      return {
        backgroundColor: "#6941C6",
        borderColor: "#7F56D9",
        textColor: "#E9D7FE",
        buttonColor: "#7F56D9",
        labelColor: "#E9D7FE",
        labelActiveColor: "#F4EBFF",
        segmentName: "machine",
      };
    }
  }, [selectedTab, setSelectedTab, pathname]);
  useEffect(() => {
    setSelectedTab(segmentColor().segmentName);
  }, [pathname]);
  return (
    <>
      <div
        ref={menuRef}
        onMouseEnter={() => {
          if (!isEmbeddedPage) {
            settings.setIsActive(true);
          }
        }}
        onMouseLeave={() => settings.setIsActive(false)}
        style={{ backgroundColor: segmentColor().backgroundColor }}
        className={`${
          settings?.isActive
            ? "w-[280px] min-w-[280px] p-5 rounded-r-xl shadow-ring-gray"
            : "w-[80px] min-w-[80px] items-center py-5 md:hidden sm:hidden xs:hidden"
        } flex flex-col transition-all duration-150 ease-linear fixed left-0 top-0 bottom-0 z-[200] overflow-hidden`}
      >
        {isEmbeddedPage && (
          <div
            onClick={() => {
              Toast("warning", "Please exit with the cancel button.");
            }}
            className="fixed left-0 top-0 bottom-0 w-[80px] min-w-[80px] z-[300] backdrop-blur-sm"
          />
        )}
        {loading && <LoadingOverlay />}
        {settings?.isActive ? (
          <>
            <div
              onClick={() => navigate("/app/machine/digital-twin")}
              className="ml-4 cursor-pointer"
            >
              <UpuIoLogo />
            </div>

            <MemorizedTab
              currentColor={segmentColor()}
              selected={selectedTab}
              setSelected={setSelectedTab}
              isAdmin={auth?.user?.rolesKey === "ADMIN" ? true : false}
            />

            <div className="mt-3 flex flex-col">
              {screens.map(function (e, i) {
                if (e.content) {
                  return e.subRoutes.map((item, index) => {
                    if (item?.roles?.includes(String(auth?.user?.rolesKey))) {
                      if (item.isVisible) {
                        if (selectedTab === "machine") {
                          if (item.package === "machine") {
                            return (
                              <MemorizedLargeCard
                                to={item.path}
                                index={index}
                                item={item}
                                segmentColor={segmentColor()}
                              />
                            );
                          }
                        } else if (selectedTab === "product") {
                          if (item.package === "product") {
                            if (
                              item?.isSubMenu &&
                              item?.path == "product/quality-control"
                            ) {
                              return (
                                <MemorizedExpandibleLargeCard
                                  to={item.path}
                                  index={index}
                                  item={item}
                                  segmentColor={segmentColor()}
                                  pathname={pathname}
                                  setOpenSubHeader={setOpenSubHeader}
                                  openSubHeader={openSubHeader}
                                  type="quality-control"
                                  navigate={navigate}
                                />
                              );
                            } else if (
                              item?.isSubMenu &&
                              item?.path == "product/stock"
                            ) {
                              return (
                                <MemorizedExpandibleLargeCard
                                  to={item.path}
                                  index={index}
                                  item={item}
                                  segmentColor={segmentColor()}
                                  pathname={pathname}
                                  setOpenSubHeader={setOpenSubHeader}
                                  openSubHeader={openSubHeader}
                                  type="product/stock"
                                  navigate={navigate}
                                />
                              );
                            } else if (item?.isSubMenu) {
                              return (
                                <MemorizedExpandibleLargeCard
                                  to={item.path}
                                  index={index}
                                  item={item}
                                  segmentColor={segmentColor()}
                                  pathname={pathname}
                                  setOpenSubHeader={setOpenSubHeader}
                                  openSubHeader={openSubHeader}
                                  navigate={navigate}
                                />
                              );
                            } else {
                              return (
                                <MemorizedLargeCard
                                  to={item.path}
                                  index={index}
                                  item={item}
                                  segmentColor={segmentColor()}
                                />
                              );
                            }
                          }
                        } else if (selectedTab === "person") {
                          if (item.package === "person") {
                            return item?.subRoutes?.map((d, i) => {
                              return (
                                <MemorizedLargeCard
                                  to={`person/${d.path}`}
                                  index={i}
                                  item={d}
                                  segmentColor={segmentColor()}
                                />
                              );
                            });
                          }
                        }
                      }
                    }
                  });
                }
              })}
            </div>
            <div className="flex-1 flex-col justify-end flex">
              {auth?.user?.rolesKey === "OPERATOR" ? null : (
                <NavLink
                  onMouseEnter={() => setIsHover("settings")}
                  onMouseLeave={() => setIsHover(null)}
                  to={"settings/profile"}
                  className={
                    "h-10 w-full px-3 py-2 flex items-center rounded-lg my-1 mb-4"
                  }
                  style={({ isActive }) => ({
                    color: isActive
                      ? segmentColor().labelActiveColor
                      : segmentColor().labelColor,
                    backgroundColor:
                      isActive || isHover === "settings"
                        ? segmentColor().buttonColor
                        : "transparent",
                  })}
                >
                  <div className="w-7 h-7 flex items-center justify-center">
                    <SettingsIcon />
                  </div>
                  <p className="ml-2 font-codec">Settings</p>
                </NavLink>
              )}

              <div
                className="w-full h-16 pt-3 flex items-center pl-1 border-t mb-3"
                style={{ borderColor: segmentColor().borderColor }}
              >
                <div className="flex items-center w-full">
                  <img
                    className="w-12 h-9 rounded bg-white "
                    src={generateFileUrl("80f34f4c-c4c7-4ae4-b460-391122b6b1b6")}
                  />
                  <div className="flex flex-col ml-3">
                    <p className="text-sm font-semibold text-white  w-[140px] overflow-hidden truncate select-none ">
                      {/* {auth?.user?.company?.name} */}
                      Company Name
                    </p>
                    <p className="text-xs text-white/70 mt-0.5 w-[140px] overflow-hidden truncate select-none">
                      {/* {auth?.user?.company?.email} */}
                      Company Mail
                    </p>
                  </div>

                  <button
                    onClick={() => {
                      changeCompany();
                    }}
                    type="button"
                    onMouseEnter={() => setIsHover("button")}
                    onMouseLeave={() => setIsHover(null)}
                    style={{
                      color:
                        isHover === "button"
                          ? segmentColor().labelActiveColor
                          : segmentColor().labelColor,
                      backgroundColor:
                        isHover === "button"
                          ? segmentColor().buttonColor
                          : "transparent",
                    }}
                    className="w-9 h-9 rounded-lg flex items-center justify-center ml-auto"
                  >
                    <ChangeIcon />
                  </button>
                </div>
              </div>

              <div
                className="w-full h-16 py-3 flex items-center pl-1 border-t"
                style={{ borderColor: segmentColor().borderColor }}
              >
                <div className="flex items-center w-full">
                  <img
                    className="w-10 h-10 rounded-full bg-white "
                    src={generateFileUrl("80f34f4c-c4c7-4ae4-b460-391122b6b1b6")}
                  />
                  <div className="flex flex-col ml-3">
                    <p className="text-sm font-semibold text-white w-[140px] overflow-hidden truncate ">
                      {auth.user.name + " " + auth.user.lastName}
                    </p>
                    <p className="text-xs text-white/70 mt-0.5 w-[140px] overflow-hidden truncate">
                      {auth.user.email}
                    </p>
                  </div>
                  <button
                    onClick={logOut}
                    type="button"
                    onMouseEnter={() => setIsHover("leave")}
                    onMouseLeave={() => setIsHover(null)}
                    style={{
                      color:
                        isHover === "leave"
                          ? segmentColor().labelActiveColor
                          : segmentColor().labelColor,
                      backgroundColor:
                        isHover === "leave"
                          ? segmentColor().buttonColor
                          : "transparent",
                    }}
                    className="w-9 h-9 rounded-lg flex items-center justify-center ml-auto"
                  >
                    <LogoutIcon />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="px-2 flex flex-col items-center h-full">
            <div className="ml-1 mb-3.5">
              <UpuHeaderIcon />
            </div>
            <PackageTab
              closed={true}
              currentColor={segmentColor()}
              selected={selectedTab}
              setSelected={setSelectedTab}
              isAdmin={auth?.user?.rolesKey === "ADMIN" ? true : false}
            />

            <div className="mt-3 flex flex-col gap-2">
              {screens.map(function (e, i) {
                if (e.content) {
                  return e.subRoutes.map((item, index) => {
                    if (item?.roles?.includes(String(auth?.user?.rolesKey))) {
                      if (item.isVisible) {
                        if (selectedTab === "machine") {
                          if (item.package === "machine") {
                            return (
                              <MemorizedCard
                                key={index}
                                to={item.path}
                                item={item}
                                segmentColor={segmentColor()}
                              />
                            );
                          }
                        } else if (selectedTab === "product") {
                          if (item.package === "product") {
                            return (
                              <MemorizedCard
                                key={index}
                                to={item.path}
                                item={item}
                                segmentColor={segmentColor()}
                              />
                            );
                          }
                        } else if (selectedTab === "person") {
                          if (item.package === "person") {
                            return item?.subRoutes?.map((d, i) => {
                              return (
                                <MemorizedCard
                                  key={i}
                                  to={`person/${d.path}`}
                                  item={d}
                                  segmentColor={segmentColor()}
                                />
                              );
                            });
                          }
                        }
                      }
                    }
                  });
                }
              })}
            </div>
            <div className="flex-1 flex-col justify-end items-center flex">
              {auth?.user?.rolesKey === "OPERATOR" ? null : (
                <NavLink
                  onMouseEnter={() => setIsHover("settings")}
                  onMouseLeave={() => setIsHover(null)}
                  to={"settings"}
                  className={
                    "h-10 w-10 flex items-center justify-center rounded-lg mb-4"
                  }
                  style={({ isActive }) => ({
                    color: isActive
                      ? segmentColor().labelActiveColor
                      : segmentColor().labelColor,
                    backgroundColor:
                      isActive || isHover === "settings"
                        ? segmentColor().buttonColor
                        : "transparent",
                  })}
                >
                  <div className="w-7 h-7 flex items-center justify-center">
                    <SettingsIcon />
                  </div>
                </NavLink>
              )}

              <div
                className="w-full h-16 pt-3 flex items-center border-t mb-3"
                style={{ borderColor: segmentColor().borderColor }}
              >
                <div className="flex items-center w-full">
                  <img
                    className="w-12 h-9 rounded bg-white "
                    src={generateFileUrl("80f34f4c-c4c7-4ae4-b460-391122b6b1b6")}
                  />
                </div>
              </div>

              <div
                className=""
                style={{ borderColor: segmentColor().borderColor }}
              >
                <div className="flex items-center w-full">
                  <img
                    className="w-10 h-10 rounded-full bg-white "
                    src={generateFileUrl(auth?.user?.avatar)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <CustomModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalTitle={t("addCompany:switchCompany")}
        children={<ChangeCompanyModal setIsOpen={setIsOpen} />}
      /> */}
    </>
  );
});

export default memo(LeftMenu);

const SecondIcon = memo(({ children }) => {
  return <>{children}</>;
});

const MemorizedCard = memo(({ to, item, segmentColor }) => {
  return (
    <>
      <NavLink
        to={to}
        className={"h-10 w-10 flex items-center justify-center rounded-lg"}
        style={({ isActive }) => ({
          color: isActive
            ? segmentColor?.labelActiveColor
            : segmentColor?.labelColor,
          backgroundColor: isActive ? segmentColor?.buttonColor : "transparent",
        })}
      >
        <div className="w-7 min-w-[28px] h-7 flex items-center justify-center">
          <SecondIcon>
            <item.icon />
          </SecondIcon>
        </div>
      </NavLink>
    </>
  );
});

const MemorizedLargeCard = memo(({ to, item, segmentColor, index }) => {
  const [isHover, setIsHover] = useState(null);
  return (
    <>
      <NavLink
        onMouseEnter={() => setIsHover(index)}
        onMouseLeave={() => setIsHover(null)}
        to={to}
        className={
          "h-10 w-full px-3 py-2 flex items-center rounded-lg my-1 select-none"
        }
        style={({ isActive }) => ({
          color: isActive
            ? segmentColor?.labelActiveColor
            : segmentColor?.labelColor,
          backgroundColor:
            isActive || isHover === index
              ? segmentColor?.buttonColor
              : "transparent",
        })}
      >
        <div className="w-7 h-7 flex items-center justify-center">
          <SecondIcon>
            <item.icon />
          </SecondIcon>
        </div>
        <p className="ml-2 font-codec truncate">{item.name}</p>
      </NavLink>
    </>
  );
});

const MemorizedExpandibleLargeCard = memo(
  ({
    item,
    segmentColor,
    pathname,
    index,
    openSubHeader,
    setOpenSubHeader,
    type,
    navigate,
  }) => {
    const [isHover, setIsHover] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
      if (type === "quality-control") {
        navigate("/app/product/quality-control/dashboard");
      } else if (type === "product/stock") {
        navigate("/app/product/stock");
      }
      setIsOpen(!isOpen);
      setOpenSubHeader(isOpen ? null : index);
    };

    const handleClick = (currentIndex) => {
      if (openSubHeader === currentIndex) {
        setOpenSubHeader(null);
      } else {
        setOpenSubHeader(currentIndex);
      }
    };
    return (
      <>
        <div className="flex-col flex justify-center items-center select-none">
          <div
            onMouseEnter={() => setIsHover(index)}
            onMouseLeave={() => setIsHover(null)}
            onClick={handleOpen}
            className="h-10 w-full px-3 py-2 flex flex-col items-center rounded-lg my-1 relative cursor-pointer"
            style={{
              color: pathname.includes(item?.path)
                ? segmentColor?.labelActiveColor
                : segmentColor?.labelColor,
              backgroundColor:
                pathname.includes(item.path) || isHover === index
                  ? segmentColor?.buttonColor
                  : "transparent",
            }}
          >
            <div className="h-full w-full flex items-center">
              <div className="w-7 h-7 flex items-center justify-center">
                <SecondIcon>
                  <item.icon />
                </SecondIcon>
              </div>
              <p className="ml-2 font-codec truncate ">{item?.name}</p>
              <div
                className={`ml-auto flex items-center rounded p-0.5 duration-300 transition-transform ${
                  isOpen && "transform rotate-180"
                }`}
              >
                <ArrowDownIcon />
              </div>
            </div>
          </div>
          {isOpen && (
            <div className=" flex flex-col items-center justify-center ml-auto w-[85%]">
              {item?.subRoutes?.map((d, i) => {
                if (d?.isSubItem) {
                  return (
                    <NavLink
                      key={i}
                      onMouseEnter={() => setIsHover(i)}
                      onMouseLeave={() => setIsHover(null)}
                      to={`${item?.path}/${d?.path}`}
                      className={
                        "h-10 w-full px-4 py-2 flex items-center rounded-lg my-1"
                      }
                      style={({ isActive }) => ({
                        color: isActive
                          ? segmentColor?.labelActiveColor
                          : segmentColor?.labelColor,
                        backgroundColor:
                          isActive || isHover === i
                            ? segmentColor?.buttonColor
                            : "transparent",
                      })}
                      onClick={() => handleClick(index)}
                    >
                      <p className="font-codec truncate">{d?.name}</p>
                    </NavLink>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </>
    );
  }
);
