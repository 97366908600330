import React, { useEffect, useState } from "react";
import OperationDetailHeader from "./components/OperationDetailHeader";
import { reportService } from "../../../services/report.service";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import OperationDetailInfo from "./components/OperationDetailInfo";
import MothlyDetailChart from "./components/MothlyDetailChart";
import Skeleton from "react-loading-skeleton";
import ShiftSelecter from "./components/ShiftSelecter";
import ShiftTimeLine from "./components/ShiftTimeLine";
import OperationTimeLineChart from "./components/OperationTimeLineChart";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { authStore } from "../../../stores/auth.store";
import { Button } from "../../../components";
import { t } from "i18next";
import OperationUpuTimeline from "./OperationUpuTimeline";

const OperationDetail = () => {
  const { operationId, dates } = useParams();
  // console.log("data", operationId, dates);
  const navigate = useNavigate();
  const [groupedDatas, setGroupedDatas] = useState([]);

  // console.log('timeZone', authStore?.user?.company?.timeZone)

  // const [date, setDate] = useState(
  //   timeFormatter(moment(dates), authStore?.user?.company?.timeZone).formatted
  // );

  // let datas = {
  //   processId: operationId,
  //   date: moment(
  //     timeFormatter(date, authStore?.user?.company?.timeZone).formatted
  //   ).format("YYYY-MM-DD"),
  // };

  const [date, setDate] = useState(moment(dates).format("YYYY-MM-DD"));
  const formattedDate = moment(date).format("YYYY-MM-DD");

  let datas = {
    processId: operationId,
    date: formattedDate,
  };

  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["operationDetail", formattedDate],
    queryFn: async () => await reportService.getOperationConsoleData(datas),
  });

  const shiftlyDatas = async (data) => {
    await data?.shiftlyTimelineData?.reduce((acc, item) => {
      const key = item?.status;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key]?.push(item);
      setGroupedDatas(acc);
      return acc;
    }, {});
  };

  // const handleDateChange = (newDate) => {
  //   navigate(
  //     `/app/machine/digital-twin/operation-detail/${operationId}/${newDate}`
  //   );
  // };

  const handleDateChange = (newDate) => {
    const formattedNewDate = moment(newDate).format("YYYY-MM-DD");
    setDate(formattedNewDate);
    navigate(
      `/app/machine/digital-twin/operation-detail/${operationId}/${formattedNewDate}`
    );
  };

  useEffect(() => {
    // if (
    //   moment(date).format("YYYY-MM-DD") ===
    //   moment(new Date()).format("YYYY-MM-DD")
    // ) {
    // } else
    if (
      moment(date).format("YYYY-MM-DD") === moment(dates).format("YYYY-MM-DD")
    ) {
    } else {
      handleDateChange(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  // useEffect(() => {
  //   refetch();
  //   shiftlyDatas(data);
  // }, [dates]);

  useEffect(() => {
    if (data) {
      shiftlyDatas(data);
    }
  }, [data]);

  useEffect(() => {
    setDate(dates);
  }, []);

  return (
    <div className="w-full h-full flex flex-col">
      <OperationDetailHeader
        index={data?.operations?.findIndex((x) => x.id === operationId)}
        data={data}
        isLoading={isLoading}
        datas={datas}
        refetch={refetch}
        isFetching={isFetching}
        date={date}
      />
      <div className="flex flex-row items-start w-full pt-2 ">
        <OperationDetailInfo
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
        />
        {isLoading || isFetching ? (
          <Skeleton
            containerClassName="w-full h-[312px] "
            className="h-[312px]"
            borderRadius={6}
          />
        ) : (
          <MothlyDetailChart data={data} currentDay={date} setDate={setDate} />
        )}
      </div>
      <div className="w-full my-3">
        <ShiftSelecter
          shiftSelecter={(r) => {
            setDate(r);
          }}
          dates={dates}
          isLoading={isLoading}
          currentDay={date}
          setCurrentDay={setDate}
          isFetching={isFetching}
        />
      </div>
      {isLoading || isFetching ? (
        <Skeleton containerClassName="w-full" height={450} borderRadius={8} />
      ) : (
        <div className="flex flex-col w-full overflow-auto scrollbar-hide overflow-x-hidden">
          <div className="flex w-full h-full flex-col max-w-full my-2">
            <div className="w-full">
              <OperationUpuTimeline
                date={date}
                data={data}
                shiftlyData={data?.shiftlyTimelineData}
                groupedDatas={groupedDatas}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationDetail;
