import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import AlertModal from "../alert-Modal/Alert-Modal";
import { DeleteIcon, DotsVerticalIcon } from "./ActiveAndPlannedCard";
import { EditIcon } from "../../assets/icons/departmentIcon";
import JunctionUpdate from "../modal/JunctionUpdate";
import CustomModal from "../modal/CustomModal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import QualityControlForm from "../modal/QualityControlForm";

import { useNavigate } from "react-router-dom";

import {
  DetailOperationIcon,
  EquipmentDeleteIcon,
  EquipmentDetailIcon,
  EquipmentEditIcon,
  NoEquIcon,
  QualityDetailIcon,
} from "../../assets/icons/machineIcons";
import moment from "moment";
import Badgets from "../buttons/Badgets";

const ApprovedCard = ({
  id,
  onClick,
  status,
  equipmentImage,
  equipmentName,
  type,
  totalJunctionTime,
  estimatedTime,
  piece,
  oneOrderTime,
  qualityAcceptedPiece,
  qualityRejectedPiece,
  qualityPercent,
  estimatedOperationTime,
  pages,
  point,
  oeeData,
  refetch,
  user,
  estimatedTrue,
  startDate,
  allData,
  step,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);
  const navigate = useNavigate();
  const [isOpenForm, setIsOpenForm] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    // { label: t("Move Junction"), value: "details", icon: <ArrowRight /> },

    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:qualityControlForm"),
      value: "quality",
      icon: <QualityDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsEquipment = [
    // { label: t("Move Junction"), value: "details", icon: <ArrowRight /> },
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  const menuOptionsPersonnel = [
    // { label: t("Move Junction"), value: "details", icon: <ArrowRight /> },
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "details",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("buttons:delete"),
      value: "delete",
      icon: <EquipmentDeleteIcon />,
    },
  ];

  // const onDeleted = async () => {
  //   // await orderService.orderDelete(deleteId).then((res) => {
  //   //   if (res.data.code === 0) {
  //   //     Toast("success", res.data.message);
  //   //     refetch();
  //   //     setIsAlert(false);
  //   //     setDeleteId("");
  //   //   } else {
  //   //     Toast("error", res.data.message);
  //   //     setIsAlert(false);
  //   //     setDeleteId("");
  //   //   }
  //   // });
  // };

  const onDeleted = async () => {
    await junctionService.deleteJunction(id, deleteId).then((res) => {
      if (res.data.code === 0) {
        Toast("success", res.data.message);
        refetch();
        setIsAlert(false);
        setDeleteId(id);
      } else {
        Toast("error", res.data.message);
        setIsAlert(false);
        setDeleteId("");
      }
    });
  };

  return (
    <>
      <div
        // onClick={() => {
        //   if (onClick) {
        //     onClick();
        //   }
        // }}
        className={`flex w-full cursor-pointer h-[80px] flex-row gap-x-3 items-center rounded border ${
          type == "work"
            ? "border-success-500 border-t-4"
            : type === "setup"
            ? "border-[#EAAA08] border-t-4"
            : "border-[#98A2B3] border-t-4"
        }  p-2 shadow-xs max-h-[80px] min-h-[80px] bg-white group relative`}
      >
        {equipmentImage ? (
          <img
            data-tooltip-id={`${allData?.id}`}
            data-tooltip-place={"left"}
           
            src={generateFileUrl(equipmentImage || "")}
            className="w-[72px] min-w-[72px] h-[54px] rounded border border-secondary-300 blur-sm "
          />
        ) : (
          <div className="w-[72px] min-w-[72px] h-[54px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
            <NoEquIcon />
          </div>
        )}

        <div className="flex flex-col gap-0.5 overflow-hidden w-[45%]">
          <p className="truncate w-[180px] text-sm text-secondary-700 font-semibold">
            {type === "manual_finished_work"
              ? "Finished Works"
              : equipmentName.slice(0,3)+"***" || "---"}
          </p>

          <div className="flex flex-row gap-2 items-center">
            <div className="w-full flex flex-col items-start gap-0.5 overflow-hidden">
              <div className="flex flex-row gap-1">
                <ClockIcon />
                <p className="text-xs font-normal text-secondary-600">
                  {totalJunctionTime || "---"}
                </p>
              </div>

              <div className="flex flex-row gap-1">
                {estimatedTime > estimatedOperationTime ? (
                  <SinglePiece2 />
                ) : (
                  <SinglePiece />
                )}

                <div className="flex flex-row items-center">
                  <p
                    className={`text-xs font-normal  ${
                      estimatedTime > estimatedOperationTime
                        ? "text-red-500"
                        : "text-success-600"
                    }`}
                  >
                    {oneOrderTime || "---"}
                  </p>
                  <p className="text-xs font-normal text-secondary-600">
                    {t("product:pcss")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="min-w-[84px] w-[84px] border border-secondary-200 ml-auto gap-y-0.5 px-[6px] pt-[2px] pb-1 flex flex-col rounded-md items-start">
          <div className="flex flex-row items-center gap-x-1 justify-center w-full">
            {status === "completed" && (
              <span className="min-w-[31px] min-h-[14px] rounded-[2px] bg-[#17B26A] text-[#FFFFFF] font-semibold text-[10px] leading-[10px] items-center justify-center flex">
                %{qualityPercent || "---"}
              </span>
            )}
            <p className="text-xs font-normal text-secondary-500">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex items-start flex-col justify-center w-full">
            <div className="flex flex-row items-center gap-x-1">
              <ApprovedPiece />
              <p className="text-xs font-normal text-success-600">
                {qualityAcceptedPiece || "0"}
              </p>
              <p className="text-[10px] font-normal text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <RejectedIcon />
              <p className="text-xs font-normal text-error-600">
                {qualityRejectedPiece || "0"}
              </p>
              <p className="text-[10px] font-normal text-secondary-500">
                {t("product:pcs")}
              </p>
            </div>
          </div>
        </div>
        {user?.avatar ? (
          <div
            onClick={() => {
              navigate(
                `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                  startDate
                ).format("YYYY-MM-DD")}`
              );
            }}
            data-tooltip-id={`${id}`}
            data-tooltip-place={"left"}
            data-tooltip-content={`${user?.name.slice(0,3)+"***" + " " + user?.lastName.slice(0,3)+"***"} `}
            className="ml-auto relative -mt-2"
          >
            <img
              className="w-[54px] min-w-[54px] h-[54px] rounded-full border border-gray-300 blur-sm"
              src={generateFileUrl(user?.avatar)}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              navigate(
                `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                  startDate
                ).format("YYYY-MM-DD")}`
              );
            }}
            data-tooltip-id={`${id}`}
            data-tooltip-place={"left"}
            data-tooltip-content={`${user?.name.slice(0,3)+"***" + " " + user?.lastName.slice(0,3)+"***"} `}
            className="ml-auto relative -mt-2"
          >
            <NoAvatar
              size={54}
              fontSize={22}
              name={user?.name}
              lastName={user?.lastName}
              color={"#475467"}
            />
            {/* {status === "completed" && (
              <div
                className={`z-[1] absolute flex items-center left-3 justify-center   -bottom-2 h-[24px] `}
              >
                {status === "completed" ? (
                  <ProductCompleted />
                ) : (
                  <ProductIcon />
                )}
                <div className="flex flex-col items-center justify-center absolute -top-1 ">
                  <p className="text-[8px] font-extrabold text-white">%</p>
                  <p className="text-[10px] text-white font-extrabold -mt-[5px]">
                    {qualityPercent || "---"}
                  </p>
                </div>
              </div>
            )} */}
          </div>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div className="hidden flex-col absolute min-w-[200px] max-w-[200px] h-fit bg-white border top-[2px] right-[32px] z-[150] rounded-lg px-[6px] py-1 group-hover:flex">
            {pages === "equipmentDetail" ? (
              <>
                {menuOptionsEquipment?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        if (item.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              user?.id
                            }/${moment(startDate).format("YYYY-MM-DD")}`
                          );
                        } else setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : pages === "personnelDetail" ? (
              <>
                {menuOptionsPersonnel?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        if (item.value === "details") {
                          onClick();
                        } else setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {menuOptions?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        if (item.value === "details") {
                          onClick();
                        }
                        if (item.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              user?.id
                            }/${moment(startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item?.value === "quality") {
                          setIsOpenForm(true);
                        } else setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            )}
          </div>
        )}
        {step === undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets colorType={"fill-gray"} label={step + ".op"} size={"sm"} />
          </span>
        )}

        <Tooltip className="z-[99]" id={`${id}`} />
      </div>
      {/* <Tooltip id={`${id}`} /> */}
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isOpenForm}
        setIsOpen={setIsOpenForm}
        modalTitle={t("product:qualityControlForm")}
        onClose={() => {
          setIsOpenForm(false);
        }}
        children={
          <QualityControlForm
            onClose={() => {
              setIsOpenForm(false);
            }}
            junctionId={id}
            refetch={refetch}
          />
        }
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={id}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.33268 4.00016V8.00016L10.9993 9.3335M14.9993 8.00016C14.9993 11.6821 12.0146 14.6668 8.33268 14.6668C4.65078 14.6668 1.66602 11.6821 1.66602 8.00016C1.66602 4.31826 4.65078 1.3335 8.33268 1.3335C12.0146 1.3335 14.9993 4.31826 14.9993 8.00016Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ApprovedPiece = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.3335H3.71429C2.76751 2.3335 2 3.10101 2 4.04778V11.8692C2 12.816 2.76751 13.5835 3.71429 13.5835H12.2857C13.2325 13.5835 14 12.816 14 11.8692V4.04778C14 3.10101 13.2325 2.3335 12.2857 2.3335H10.25M5.75 2.3335V5.36147C5.75 5.42519 5.81706 5.46664 5.87405 5.43814L8 4.37516L10.126 5.43814C10.1829 5.46664 10.25 5.42519 10.25 5.36147V2.3335M5.75 2.3335H10.25"
        stroke="#079455"
      />
      <path
        d="M6 9.46138L7.4359 10.8973L10.6667 7.6665"
        stroke="#079455"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PointIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
    >
      <path
        d="M10.7078 11.3044C10.7078 11.9714 10.5764 12.6318 10.3212 13.2479C10.066 13.8641 9.69185 14.4239 9.22025 14.8954C8.74865 15.3669 8.18879 15.7408 7.57266 15.9959C6.95653 16.2509 6.2962 16.3821 5.62939 16.3818V14.234C6.15704 14.2391 6.67716 14.1086 7.13991 13.855C7.58644 13.6107 7.95725 13.2484 8.21185 12.8076C8.46691 12.3529 8.60087 11.8402 8.60087 11.3188C8.60087 10.7974 8.46691 10.2848 8.21185 9.83003C7.9576 9.38963 7.58664 9.02805 7.13991 8.7852C6.67016 8.53797 6.14731 8.40877 5.61649 8.40877C5.08567 8.40877 4.56282 8.53797 4.09307 8.7852C3.64789 9.02666 3.27826 9.38673 3.02518 9.82547C2.8466 10.1337 2.72799 10.4729 2.67563 10.8253C1.90581 10.3304 1.2726 9.65012 0.834108 8.84678C0.395615 8.04344 0.165888 7.1428 0.166016 6.22754H5.62939C6.29618 6.22721 6.9565 6.35828 7.57262 6.61327C8.18875 6.86826 8.74861 7.24216 9.22022 7.71363C9.69182 8.18509 10.0659 8.74487 10.3212 9.36099C10.5764 9.97711 10.7078 10.6375 10.7078 11.3044Z"
        fill="#CFAA01"
      />
      <path
        d="M10.707 7.00066V4.67236H14.5925L10.707 7.00066Z"
        fill="#CFAA01"
      />
      <path
        d="M6.62088 12.4072C6.35579 12.6843 6.01806 12.824 5.60768 12.8264H5.5974C5.18213 12.8264 4.84097 12.6866 4.57391 12.4072C4.34122 12.161 4.2022 11.8373 4.18215 11.4947C4.17725 11.4389 4.1748 11.382 4.1748 11.3231C4.1748 10.8802 4.30833 10.519 4.57538 10.2396C4.84244 9.96007 5.1836 9.82016 5.59887 9.81982C6.01414 9.81982 6.3553 9.95973 6.62235 10.2396C6.88941 10.5194 7.02293 10.8806 7.02293 11.3231C7.02195 11.767 6.88794 12.1283 6.62088 12.4072Z"
        fill="#CFAA01"
      />
      <path
        d="M11.5562 2.21917C11.5562 2.0195 11.42 1.85708 11.2527 1.85708C11.0853 1.85708 10.9492 2.0195 10.9492 2.21917C10.9492 2.28421 10.9638 2.34513 10.9891 2.39793L9.6347 3.04429L9.11469 1.80353C9.27791 1.70396 9.38998 1.5014 9.38998 1.2681C9.38998 0.936635 9.16393 0.666992 8.88613 0.666992C8.60832 0.666992 8.3823 0.936659 8.3823 1.2681C8.3823 1.5014 8.49435 1.70393 8.65757 1.80353L8.13758 3.04429L6.77589 2.39446C6.80011 2.34247 6.81401 2.28275 6.81401 2.21917C6.81401 2.0195 6.67788 1.85708 6.51051 1.85708C6.34314 1.85708 6.20703 2.0195 6.20703 2.21917C6.20703 2.36588 6.28066 2.49223 6.38605 2.54905L7.06784 4.56438C7.09124 4.63357 7.14747 4.67883 7.21 4.67883H10.5827C10.6458 4.67883 10.7024 4.63269 10.7254 4.5626L11.3884 2.54256C11.4877 2.48301 11.5562 2.36059 11.5562 2.21917Z"
        fill="#CFAA01"
      />
    </svg>
  );
};

export const SinglePiece = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M6.76804 4.42969V7.43823L4.8877 8.56644"
        stroke="#079455"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.0625 6.99813C1.0625 3.88258 3.58815 1.35693 6.7037 1.35693C9.7135 1.35693 12.1728 3.71405 12.3362 6.68302L13.3347 7.36945C13.3415 7.24654 13.3449 7.12274 13.3449 6.99813C13.3449 3.3303 10.3715 0.356934 6.7037 0.356934C3.03587 0.356934 0.0625 3.3303 0.0625 6.99813C0.0625 10.666 3.03587 13.6393 6.7037 13.6393C7.38035 13.6393 8.03337 13.5381 8.64844 13.35V12.2951C8.0422 12.5178 7.38714 12.6393 6.7037 12.6393C3.58815 12.6393 1.0625 10.1137 1.0625 6.99813Z"
        fill="#079455"
      />
      <path
        d="M10.1523 8.11198C10.1523 7.80328 10.1523 7.64893 10.2167 7.56385C10.2728 7.48973 10.3585 7.44386 10.4513 7.43832C10.5578 7.43196 10.6862 7.51758 10.943 7.68881L14.2856 9.91717C14.4978 10.0587 14.6039 10.1294 14.6409 10.2186C14.6732 10.2965 14.6732 10.3841 14.6409 10.4621C14.6039 10.5513 14.4978 10.622 14.2856 10.7635L10.943 12.9919C10.6862 13.1631 10.5578 13.2487 10.4513 13.2424C10.3585 13.2368 10.2728 13.1909 10.2167 13.1168C10.1523 13.0317 10.1523 12.8774 10.1523 12.5687V8.11198Z"
        stroke="#079455"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SinglePieceRed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M6.76804 4.42969V7.43823L4.8877 8.56644"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.0625 6.99813C1.0625 3.88258 3.58815 1.35693 6.7037 1.35693C9.7135 1.35693 12.1728 3.71405 12.3362 6.68302L13.3347 7.36945C13.3415 7.24654 13.3449 7.12274 13.3449 6.99813C13.3449 3.3303 10.3715 0.356934 6.7037 0.356934C3.03587 0.356934 0.0625 3.3303 0.0625 6.99813C0.0625 10.666 3.03587 13.6393 6.7037 13.6393C7.38035 13.6393 8.03337 13.5381 8.64844 13.35V12.2951C8.0422 12.5178 7.38714 12.6393 6.7037 12.6393C3.58815 12.6393 1.0625 10.1137 1.0625 6.99813Z"
        fill="#D92D20"
      />
      <path
        d="M10.1523 8.11198C10.1523 7.80328 10.1523 7.64893 10.2167 7.56385C10.2728 7.48973 10.3585 7.44386 10.4513 7.43832C10.5578 7.43196 10.6862 7.51758 10.943 7.68881L14.2856 9.91717C14.4978 10.0587 14.6039 10.1294 14.6409 10.2186C14.6732 10.2965 14.6732 10.3841 14.6409 10.4621C14.6039 10.5513 14.4978 10.622 14.2856 10.7635L10.943 12.9919C10.6862 13.1631 10.5578 13.2487 10.4513 13.2424C10.3585 13.2368 10.2728 13.1909 10.2167 13.1168C10.1523 13.0317 10.1523 12.8774 10.1523 12.5687V8.11198Z"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RejectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.3335H3.71429C2.76751 2.3335 2 3.10101 2 4.04778V11.8692C2 12.816 2.76751 13.5835 3.71429 13.5835H12.2857C13.2325 13.5835 14 12.816 14 11.8692V4.04778C14 3.10101 13.2325 2.3335 12.2857 2.3335H10.25M5.75 2.3335V5.36147C5.75 5.42519 5.81706 5.46664 5.87405 5.43814L8 4.37516L10.126 5.43814C10.1829 5.46664 10.25 5.42519 10.25 5.36147V2.3335M5.75 2.3335H10.25"
        stroke="#D92D20"
      />
      <path
        d="M9.66634 7.6665L6.33301 10.9998M6.33301 7.6665L9.66634 10.9998"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ProductCompleted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_10445_36480)">
        <circle cx="15.715" cy="11.715" r="11.715" fill="#17B26A" />
        <path
          d="M26.9267 19.9536L26.9215 19.9574C25.2334 22.2468 22.8898 23.9332 20.1609 24.8075L20.1465 24.8179L25.2492 31.8412L26.2342 26.0986L32 26.9364L26.9267 19.9536Z"
          fill="#17B26A"
        />
        <path
          d="M5.07856 19.9574L5.07331 19.9536L0 26.9364L5.76594 26.0986L6.75087 31.8412L11.8536 24.8179L11.8392 24.8076C9.11025 23.9332 6.76663 22.2468 5.07856 19.9574Z"
          fill="#17B26A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10445_36480">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SinglePiece2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M6.76804 4.42969V7.43823L4.8877 8.56644"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.0625 6.99813C1.0625 3.88258 3.58815 1.35693 6.7037 1.35693C9.7135 1.35693 12.1728 3.71405 12.3362 6.68302L13.3347 7.36945C13.3415 7.24654 13.3449 7.12274 13.3449 6.99813C13.3449 3.3303 10.3715 0.356934 6.7037 0.356934C3.03587 0.356934 0.0625 3.3303 0.0625 6.99813C0.0625 10.666 3.03587 13.6393 6.7037 13.6393C7.38035 13.6393 8.03337 13.5381 8.64844 13.35V12.2951C8.0422 12.5178 7.38714 12.6393 6.7037 12.6393C3.58815 12.6393 1.0625 10.1137 1.0625 6.99813Z"
        fill="#D92D20"
      />
      <path
        d="M10.1523 8.11198C10.1523 7.80328 10.1523 7.64893 10.2167 7.56385C10.2728 7.48973 10.3585 7.44386 10.4513 7.43832C10.5578 7.43196 10.6862 7.51758 10.943 7.68881L14.2856 9.91717C14.4978 10.0587 14.6039 10.1294 14.6409 10.2186C14.6732 10.2965 14.6732 10.3841 14.6409 10.4621C14.6039 10.5513 14.4978 10.622 14.2856 10.7635L10.943 12.9919C10.6862 13.1631 10.5578 13.2487 10.4513 13.2424C10.3585 13.2368 10.2728 13.1909 10.2167 13.1168C10.1523 13.0317 10.1523 12.8774 10.1523 12.5687V8.11198Z"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ProductIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_10445_36485)">
        <circle cx="15.715" cy="11.715" r="11.715" fill="#EAAA08" />
        <path
          d="M26.9267 19.9536L26.9215 19.9574C25.2334 22.2468 22.8898 23.9332 20.1609 24.8075L20.1465 24.8179L25.2492 31.8412L26.2342 26.0986L32 26.9364L26.9267 19.9536Z"
          fill="#EAAA08"
        />
        <path
          d="M5.07856 19.9574L5.07331 19.9536L0 26.9364L5.76594 26.0986L6.75087 31.8412L11.8536 24.8179L11.8392 24.8076C9.11025 23.9332 6.76663 22.2468 5.07856 19.9574Z"
          fill="#EAAA08"
        />
      </g>
      <defs>
        <clipPath id="clip0_10445_36485">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ConsumeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00065 14.6663C11.6826 14.6663 14.6673 11.6816 14.6673 7.99967C14.6673 4.31778 11.6826 1.33301 8.00065 1.33301C4.31875 1.33301 1.33398 4.31778 1.33398 7.99967C1.33398 11.6816 4.31875 14.6663 8.00065 14.6663Z"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.48145 7.99975C3.48145 5.50758 5.50904 3.47998 8.00122 3.47998C10.4934 3.47998 12.521 5.50758 12.521 7.99975"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00098 3.47998V4.60992"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.80664 4.80518L5.6041 5.60263"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.48145 8H4.61139"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3906 8H12.5206"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3984 5.60263L11.1959 4.80518"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.13 10.2595C9.13 10.8835 8.6241 11.3894 8.00006 11.3894C7.37602 11.3894 6.87012 10.8835 6.87012 10.2595C6.87012 9.63542 8.00006 6.86963 8.00006 6.86963C8.00006 6.86963 9.13 9.63542 9.13 10.2595Z"
        stroke="#475467"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ApprovedCard;
