import React, { useEffect, useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { ThreeDotsIcon } from "../../../assets/icons/machineIcons";
import { manipulate } from "../../../utils/manipulate";
const ExternalOperationSwiper = ({
  item,
  index,
  setVisibleButton,
  setIsIntersecting,
  selectedOperation,
  setSelectedOperation,
}) => {
  const divRef = useRef();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [view, setView] = useState(false);
  const menuRef = useRef();
  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (index === 0) {
          if (entry.isIntersecting) {
            setVisibleButton(false);
            setIsIntersecting(entry.isIntersecting);
          } else {
            setVisibleButton(true);
          }
        }
      });
    }, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setView(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      ref={divRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setSelectedOperation(item)}
      className={`w-full relative cursor-pointer h-full max-w-[166px] max-h-[92px] min-w-[166px] min-h-[92px] border rounded-lg flex flex-col justify-between ${
        selectedOperation?.id === item?.id
          ? "bg-white border-[#DC6803]"
          : "border-secondary-300"
      }`}
    >
      <div
        onClick={() => setSelectedOperation(item)}
        className={`flex flex-row items-center gap-x-1 w-full font-semibold ${
          selectedOperation?.id === item?.id ? "text-[#B54708]" : "#344054"
        } px-3 pt-2 max-h-[32px] truncate`}
      >
        <ExternalOperationIcon
          color={selectedOperation?.id === item?.id ? "#DC6803" : "#475467"}
        />
        {item?.name ? manipulate(item?.name) : "---"}
        {isHovered && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setView(!view);
            }}
            type="button"
            className="w-5 h-5 group justify-end mt-1 cursor-pointer absolute right-2 top-2"
          >
            <ThreeDotsIcon />
          </div>
        )}
      </div>
      <div className="flex w-full max-w-[142px] max-h-[44px] flex-col items-center self-center mt-1 mb-3">
        <div className="w-full flex items-center justify-between border-b border-[#EAECF0]">
          <div className="text-xs text-[#667085] min-w-[63px] w-[63px] max-w-[63px] border-r border-[#EAECF0]">
            {t("product:active")}
          </div>
          <div className="text-xs text-[#079455] font-semibold w-[79px] text-center">
            {item?.active ? item?.active : 0}
          </div>
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="text-xs text-[#667085] min-w-[63px] w-[63px] max-w-[63px] border-r border-[#EAECF0]">
            {t("product:newPlanned")}
          </div>
          <div className="text-xs font-semibold w-[79px] text-center">
            {item?.planned ? item?.planned : 0}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ExternalOperationIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.4045 7.55677C11.322 7.47427 11.2807 7.43301 11.267 7.38366C11.255 7.34025 11.2575 7.29267 11.2742 7.24775C11.2932 7.19668 11.339 7.15085 11.4307 7.05918L14.7698 3.72006L13.5913 2.54155L17.9452 2.31239L17.7161 6.66634L16.5375 5.48783L13.1984 8.82694C13.1068 8.91861 13.0609 8.96445 13.0098 8.98341C12.9649 9.00008 12.9174 9.00259 12.8739 8.99056C12.8246 8.97689 12.7833 8.93564 12.7008 8.85313L11.4045 7.55677Z"
        stroke={color}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.09277 7.94661C6.99273 9.04665 6.99276 10.8302 8.09279 11.9302C9.19283 13.0303 10.9764 13.0303 12.0764 11.9302M10.5984 2.68042L10.1893 1.78127C10.0443 1.4626 9.69023 1.29547 9.35206 1.38606L6.44279 2.1656C6.10465 2.25622 5.88156 2.578 5.91533 2.92645L6.01069 3.90971C6.11941 5.03127 5.17745 5.97323 4.05589 5.86451L3.07263 5.76916C2.72418 5.73538 2.4024 5.95847 2.31178 6.29661L1.53224 9.20588C1.44164 9.54405 1.60878 9.89815 1.92745 10.0431L2.8266 10.4522C3.85227 10.9188 4.19707 12.2055 3.54211 13.1225L2.96793 13.9263C2.76447 14.2112 2.79675 14.6014 3.04427 14.849L5.17405 16.9787C5.42158 17.2263 5.81183 17.2585 6.09667 17.0551L6.90052 16.4809C7.81747 15.8259 9.10421 16.1707 9.57082 17.1964L9.97991 18.0956C10.1249 18.4142 10.479 18.5814 10.8171 18.4908L13.7264 17.7112C14.0645 17.6206 14.2876 17.2988 14.2539 16.9504L14.1585 15.9671C14.0498 14.8456 14.9917 13.9036 16.1133 14.0123L17.0966 14.1077C17.445 14.1415 17.7668 13.9184 17.8574 13.5802L18.637 10.671C18.7275 10.3328 18.5604 9.97869 18.2418 9.83373"
        stroke={color}
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ExternalOperationSwiper;
