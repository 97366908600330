import React, { useState } from "react";
import "./montajj.css";

const Montaj3Tree = () => {
  const data = [
    {
      name: "montaj",
    },
    { name: "torna" },
    {
      name: "freze",
    },
  ];
  const [parentStates, setParentStates] = useState({});
  const toggleChildVisibility = (parentId) => {
    setParentStates((prevStates) => ({
      ...prevStates,
      [parentId]: !prevStates[parentId],
    }));
  };
  return (
    <div className="w-full h-full ml-10">
      <ul class="tree">
        {/* <!-- Root node (Category) --> */}
        <li className="">
          <span className=" flex items-center gap-x-5">
            <div>orderss </div>
            {data.map((e) => {
              return <div class="max-w-[300px]">{e.name}</div>;
            })}
          </span>
          <ul>
            {data.map((parent, index) => (
               <li key={index}>
               <span  onClick={() => toggleChildVisibility(parent?.name)} className="flex gap-x-5 ">
                 <div className="sticky">Product card</div>
                 <div className="sticky">Montaj card</div>
               </span>
               {parentStates[parent.name] && (

               <ul className="">
                 {data.map((parent) => (
                   <li key={parent?.id}>
                     <span className=" flex items-center gap-x-5">
                       <div>orderss </div>
                       {data.map((e) => {
                         return <div class="max-w-[300px]">{e.name}</div>;
                       })}
                     </span>
                     <ul>
                       <li>
                         <span className="flex gap-x-5 ">
                           <div
                             onClick={() => toggleChildVisibility(parent?.id)}
                             className="sticky"
                           >
                             Product card Clickable
                           </div>
                           <div className="sticky">Montaj card</div>
                         </span>
                         {parentStates[parent?.id] && (
                           <ul>
                             <li>
                               <div>List item 1</div>
                             </li>
                             <li>
                               <div>List item 2</div>
                             </li>
                           </ul>
                         )}
                       </li>
                       {/* <li>
                      <div>List item 2</div>
                    </li>
                    <li>
                      <div>List item 3</div>
                    </li>
                    <li>
                      <div>List item 4</div>
                    </li> */}
                     </ul>
                   </li>
                 ))}
                 <li>
                   <span className="flex gap-x-5 ">
                     <div className="sticky">Product card</div>
                     <div className="sticky">Montaj card</div>
                   </span>
                   <ul>
                     <li>
                       <div>List item 1</div>
                     </li>
                     <li>
                       <div>List item 2</div>
                     </li>
                   </ul>
                 </li>
               </ul>
               )}
             </li>
            ))}

            <li>
              <div class="sticky">Reptiles</div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Montaj3Tree;
