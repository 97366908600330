import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Button, Input } from "../../../components";
import EndDatePicker from "../../person/components/EndDatePicker";
import moment from "moment";
import { CalendarIcon } from "../../../assets/icons/PersonIcons";
import TextArea from "../../../components/inputs/TextArea";
import { Toast } from "../../../utils/toastify/toast";
import StockExternalOperationCard from "../cards/StockExternalOperationCard";
import SupplyServiceCard from "../../person/components/SupplyProductCard";
import { purchaseService } from "../../../services/purchase.service";
const PlanPurchaseJunctionModal = ({
  selectedJunction,
  setIsOpen,
  supplierId,
  refetch,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(moment(new Date()));
  const [visible, setVisible] = useState(false);
  const schema = yup.object({
    unitCost: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    orderedUnit: yup
      .number()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (value) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[500px] flex flex-col "
    >
      <p className="font-normal text-sm text-secondary-600 mt-1">
        {t("product:addTheSupplier")}
      </p>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />
      <div className="flex w-full h-full mt-4 gap-x-3">
        <div className="flex min-h-full min-w-[16px] max-w-[16px] flex-col items-center justify-center">
          <div className="flex min-w-[16px] max-w-[16px] max-h-[16px] min-h-[16px] rounded-2xl border bg-[#F9FAFB] border-[#D0D5DD] p-1">
            <div className="bg-[#D0D5DD] rounded-3xl w-full h-full"></div>
          </div>
          <LineIcon />
          <div className="flex min-w-[16px] max-w-[16px] max-h-[16px] min-h-[16px] rounded-2xl border bg-[#F9FAFB] border-[#D0D5DD] p-1"></div>
        </div>
        <div className="flex flex-col h-full gap-y-3 min-w-[300px] max-w-[300px]">
          <SupplyServiceCard item={selectedJunction} />
          <StockExternalOperationCard
            width={"300px"}
            type={selectedJunction?.externalOperation?.madeTransaction}
            name={selectedJunction?.externalOperation?.process?.name}
            estimatedTime={selectedJunction?.externalOperation?.estimatedTime}
            allData={selectedJunction?.job?.product?.id}
          />
        </div>
      </div>
      <div className="flex flex-col mt-5 w-full gap-y-4">
        <hr className="bg-secondary-200 w-full h-px" />
        <div className="flex gap-x-3">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:price")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.unitCost ? "error" : ""}
                errorMessage={errors?.unitCost ? errors?.unitCost?.message : ""}
              />
            )}
            name="unitCost"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                value={value}
                theme={"product"}
                label={t("product:quantitys")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.orderedUnit ? "error" : ""}
                errorMessage={
                  errors?.orderedUnit ? errors?.orderedUnit?.message : ""
                }
              />
            )}
            name="orderedUnit"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm font-medium text-secondary-700">
            {t("product:estimatedDeliveryDate")}
          </p>
          <EndDatePicker
            date={date}
            visible={visible}
            setDate={(e) => {
              setDate(e);
            }}
            setVisible={setVisible}
            buttonClassName={"flex flex-col items-center"}
            calendarClassName={
              "absolute bottom-12 rounded-lg shadow-xl z-[600]"
            }
          >
            <Button
              onClick={() => {
                setVisible(!visible);
              }}
              iconLeft={<CalendarIcon />}
              size={"md"}
              colorType={"secondary-gray"}
              label={moment(date).format("DD.MM.YYYY")}
            />
          </EndDatePicker>
        </div>
        <div className="flex flex-row items-center gap-x-3 w-full">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                value={value}
                theme={"product"}
                label={t("product:description")}
                onChange={onChange}
                setValue={setValue}
                onBlur={onBlur}
                validate={errors?.description ? "error" : ""}
                errorMessage={
                  errors?.description ? errors?.description?.message : ""
                }
              />
            )}
            name="description"
          />
        </div>
      </div>
      <hr className="mt-4 bg-secondary-200 w-full h-px" />
      <div className="w-[100%] flex items-center  gap-3 mt-4">
        <Button
          label={t("buttons:stop")}
          colorType={"secondary-gray"}
          size={"md"}
          type={"button"}
          // onClick={onClose}
        />
        <Button
          type={"submit"}
          label={t("buttons:starts")}
          colorType={"primary-product"}
          size={"md"}
        />
      </div>
    </form>
  );
};
export default PlanPurchaseJunctionModal;

export const LineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="90"
      viewBox="0 0 12 90"
      fill="none"
    >
      <path
        d="M6 1L6 45M6 89L6 45M6 45L0.999998 40.1111M6 45L11 40.1111"
        stroke="#D0D5DD"
        stroke-linecap="round"
      />
    </svg>
  );
};
