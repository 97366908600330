import React, { useState } from "react";
import Junction from "../junction/Junction";
import SearchInput from "../inputs/SearchInput";
import generateFileUrl from "../../utils/generateFileUrl";
import { useQuery } from "@tanstack/react-query";
import { personalService } from "../../services/personal.service";
import Button from "../buttons/Button";
import { PlayIcon, SettingsIcon } from "../../assets/icons/productIcons";
import { Toast } from "../../utils/toastify/toast";
import { junctionService } from "../../services/junction.service";
import Loading from "../loading/Loading";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";
import { ArchiveIcon, ClockIcon } from "../../assets/icons/machineIcons";
import Input from "../inputs/Input";
import { purchaseService } from "../../services/purchase.service";
export default function SelectUserModal({
  selectedJunction,
  setIsOpen,
  handleData,
  purchase = false,
  externalPlanned = false,
}) {
  const { t } = useTranslation();
  const [change, setChange] = useState(false);
  const [selectUser, setSelectedUser] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [number, setNumber] = useState(0);
  const [hasInternalOperation, setHasInternalOperation] = useState(
    !!selectedJunction?.internalOperation
  );
  const onClose = () => {
    setIsOpen(false);
  };

  async function startJunction(type) {
    if (type === "work") {
      const data = selectedJunction?.supplier
        ? {
            orderId: selectedJunction?.job?.id,
            operationType: "external",
            process: selectedJunction?.externalOperation?.id,
            supplier: selectedJunction?.supplier?.id,
            headerId: selectedJunction?.header?.id,
            type: "work",
            user: selectUser?.id,
          }
        : {
            orderId: selectedJunction?.job?.id,
            operationType: "internal",
            process: selectedJunction?.internalOperation?.id,
            nodeId: selectedJunction?.station?.id,
            headerId: selectedJunction?.header?.id,
            type: "work",
            user: selectUser?.id,
          };
      await junctionService.createJunction(data).then((res) => {
        if (res.data.code === 1) {
          Toast("error", res.data.message);
        } else if (res.data.code === 0) {
          if (res.data.status === true) {
            Toast("success", t("embedded:operationSuccessfull"));
            onClose();
            handleData();
          } else if (res.data.status === false) {
            Toast("error", res.data.message);
            onClose();
          }
        } else if (res?.data?.code === 2) {
          onClose();
          Toast("error", res.data.message);
        }
      });
    } else if (type === "purchase") {
      const data = {
        supplier: selectedJunction?.supplier?.id,
        orderedUnit: number,
        unitCost: selectedJunction?.purchaseRequest?.unitCost,
        currency: selectedJunction?.purchaseRequest?.currency,
        requestId: selectedJunction?.purchaseRequest?.id,
        description: selectedJunction?.description,
        estimatedDeliveryDate: selectedJunction?.estimatedDeliveryDate,
        userId: selectUser?.id,
      };
      await purchaseService.startPurchaseJunction(data).then((res) => {
        if (res.data.code === 0) {
          Toast("success", t("embedded:operationSuccessfull"));
          onClose();
          handleData();
        } else {
          onClose();
          Toast("error", res.data.message);
        }
      });
    } else if (type === "externalJunction") {
      const data = {
        estimatedWorkCount: number,
        orderId: selectedJunction?.job?.id,
        operationType: "external",
        process: selectedJunction?.externalOperation?.id,
        supplier: selectedJunction?.supplier?.id,
        headerId: selectedJunction?.header?.id,
        type: "work",
        user: selectUser?.id,
      };
      await junctionService.createJunction(data).then((res) => {
        if (res.data.code === 1) {
          Toast("error", res.data.message);
        } else if (res.data.code === 0) {
          if (res.data.status === true) {
            Toast("success", t("embedded:operationSuccessfull"));
            onClose();
            handleData();
          } else if (res.data.status === false) {
            Toast("error", res.data.message);
            onClose();
          }
        } else if (res?.data?.code === 2) {
          onClose();
          Toast("error", res.data.message);
        }
      });
    } else {
      const data = {
        orderId: selectedJunction?.job?.id,
        operationType: "internal",
        process: selectedJunction?.internalOperation?.id,
        nodeId: selectedJunction?.station?.id,
        headerId: selectedJunction?.header?.id,
        type: "setup",
        user: selectUser?.id,
      };
      await junctionService.createJunction(data).then((res) => {
        if (res.data.code === 1) {
          Toast("error", res.data.message);
        } else if (res.data.code === 0) {
          if (res.data.status === true) {
            Toast("success", t("embedded:operationSuccessfull"));
            onClose();
            handleData();
          } else if (res.data.status === false) {
            Toast("error", res.data.message);
            onClose();
          }
        } else if (res?.data?.code === 2) {
          onClose();
          Toast("error", res.data.message);
        }
      });
    }
  }

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["activePersonals"],
    queryFn: async () => await personalService?.activePersonals(),
    retry: 0,
  });

  const filteredUsers = data?.data?.filter((user) =>
    diacritics
      .remove(`${user.name} ${user.lastName}`)
      .toLowerCase()
      .includes(diacritics.remove(searchValue).toLowerCase())
  );

  return (
    <div className="flex w-[350px] flex-col h-full gap-y-6 pt-6">
      {change ? (
        <Junction
          leftIcon={<ArchiveIcon />}
          rightIcon={<ClockIcon />}
          step={selectedJunction?.header?.step}
          props={{
            user: {
              image: selectUser?.avatar,
              name: selectUser?.name,
              lastName: selectUser?.lastName,
            },
            product: {
              name: selectedJunction?.job?.product?.name,
              code: selectedJunction?.job?.orderNo,
              image: selectedJunction?.job?.product?.image,
              piece: selectedJunction?.targetedWorkCount,
            },
            node: {
              name:
                selectedJunction?.station?.metadata?.brand &&
                selectedJunction?.station?.metadata?.model
                  ? selectedJunction?.station?.metadata?.brand +
                    " " +
                    selectedJunction?.station?.metadata?.model
                  : selectedJunction?.supplier?.contactName,
              image:
                selectedJunction?.station?.metadata?.image ||
                selectedJunction?.supplier?.image,
              process:
                selectedJunction?.supplier?.name ||
                selectedJunction?.internalOperation?.name,
            },
          }}
        />
      ) : (
        <Junction
          leftIcon={<ArchiveIcon />}
          rightIcon={<ClockIcon />}
          step={selectedJunction?.header?.step}
          props={{
            onPressUser: () => {},
            product: {
              name: selectedJunction?.job?.product?.name,
              code: selectedJunction?.job?.orderNo,
              image: selectedJunction?.job?.product?.image,
              piece: selectedJunction?.targetedWorkCount,
            },
            node: {
              name:
                selectedJunction?.station?.metadata?.brand &&
                selectedJunction?.station?.metadata?.model
                  ? selectedJunction?.station?.metadata?.brand +
                    " " +
                    selectedJunction?.station?.metadata?.model
                  : selectedJunction?.supplier?.contactName,
              image:
                selectedJunction?.station?.metadata?.image ||
                selectedJunction?.supplier?.image,
              process:
                selectedJunction?.supplier?.name ||
                selectedJunction?.internalOperation?.name,
            },
          }}
        />
      )}
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loading primary={"#7F56D9"} secondary={"#fff"} size={22} />
        </div>
      ) : (
        <>
          {change ? (
            <div className="flex flex-col w-full gap-y-3">
              {hasInternalOperation ? (
                <Button
                  colorType={"tertiary-product"}
                  size={"md"}
                  label={t("buttons:makeSettings")}
                  iconLeft={<SettingsIcon color={"#fff"} />}
                  onClick={() => {
                    startJunction("setup");
                  }}
                />
              ) : null}
              <Button
                colorType={"success-primary"}
                size={"md"}
                label={t("embedded:startJob")}
                iconLeft={<PlayIcon />}
                onClick={() => {
                  purchase
                    ? startJunction("purchase")
                    : externalPlanned
                    ? startJunction("externalJunction")
                    : startJunction("work");
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-y-4">
              <SearchInput setSearchVal={setSearchValue} theme={"product"} />
              {(purchase || externalPlanned) && (
                <Input
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                  theme={"product"}
                  label={t("table:piece")}
                />
              )}
              <div className="flex flex-col max-h-[330px] min-h-[330px] overflow-y-auto scrollbar-hide gap-y-2 border-y">
                {filteredUsers?.map((user, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedUser(user);
                        setChange(true);
                      }}
                      className="border px-3 py-[10px] rounded-lg shadow-xs border-[#D0D5DD] flex gap-x-3 hover:bg-[#F9FAFB] cursor-pointer"
                    >
                      {user?.avatar ? (
                        <img
                          className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-full border border-[#0000001A]"
                          src={generateFileUrl(user?.avatar)}
                        />
                      ) : (
                        <div
                          className={
                            "min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full bg-primary-700 flex border-4 border-primary-400 items-center justify-center font-medium text-white text-xl"
                          }
                        >
                          {user?.name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                      <div className="flex flex-col w-[70%]">
                        <span className="text-sm font-semibold text-[#344054] truncate">
                          {user?.name} {user?.lastName}
                        </span>
                        <span className="truncate text-sm font-normal text-[#667085]">
                          {user?.metadata?.department?.name || "Diğer"}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
