import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons/Button";
import { QRCode } from "react-qrcode-logo";
import { DownloadIcon, PrinterIcon } from "../../../assets/icons/buttonIcons";
import { useTranslation } from "react-i18next";

const MachineQrModal = ({ nodeId, name }) => {
  const { t } = useTranslation();
  const [isPhone, setIsPhone] = useState(window.innerWidth < 575);

  const downloadCode = () => {
    const canvas = document.getElementById("qr-code");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${name}-qr.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const printCode = () => {
    const canvas = document.getElementById("qr-code");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
      <html>
        <head>
          <title>${name} - QR CODE</title>
        </head>
        <body>
          <div style="align-items: center; background: #F9FAFB; widht: 100%; height: 100%; display: flex; justify-content: center;">
            <img src="${pngUrl}" alt="QR Code" />
          </div>
        </body>
      </html> 
    `);
      setTimeout(() => {
        printWindow.document.close();
        printWindow.print();
      }, 200);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth <= 575);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="min-w-[452px] flex flex-col xs:min-w-[300px] ">
      <p className="text-sm text-secondary-500 mt-0.5">
        {t("embedded:printQr")} {name.slice(0, 3)+"***"}
      </p>
      <div className="w-full min-h-[424px] xs:min-h-[300px] rounded-lg bg-gray-50 my-6 flex items-center justify-center step-2 blur-sm ">
        <QRCode
          id="qr-code"
          fgColor="#2C1C5F"
          bgColor="#FFF"
          size={isPhone ? 220 : 320}
          ecLevel="M"
          value={nodeId || undefined}
          eyeRadius={[
            {
              outer: [2, 2, 2, 2],
              inner: [2, 2, 2, 2],
            },
            {
              outer: [2, 2, 2, 2],
              inner: [2, 2, 2, 2],
            },
            {
              outer: [2, 2, 2, 2],
              inner: [2, 2, 2, 2],
            },
          ]}
          qrStyle="squares"
        />
      </div>
      <div className="w-full flex items-center gap-4 justify-between ">
        <span className="step-3 w-full">
          <Button
              onClick={()=>{
              
              }}
            iconLeft={<PrinterIcon />}
            label={t("embedded:print")}
            colorType={"secondary-gray"}
            size={"md"}
           
          />
        </span>
        <span className="step-4 w-full">
          <Button
            onClick={()=>{

            }}
            iconLeft={<DownloadIcon color={"#fff"} />}
            label={t("embedded:download")}
            colorType={"primary-machine"}
            size={"md"}
            
          />
        </span>
      </div>
    </div>
  );
};

export default MachineQrModal;
