import React, { useEffect, useState } from "react";
import { Button, CustomModal, Input } from "../../components";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "../../assets/icons/departmentIcon";
import {
  ColumnIcon,
  DoorIcon,
  DoubleDoorIcon,
  SettingsIcon,
  WallIcon,
} from "../../assets/icons/plannerIcons";
import SearchInput from "../../components/inputs/SearchInput";
import { equipmentService, mapService } from "../../services";
import Badgets from "../../components/buttons/Badgets";
import MapEditorEquCard from "./MapEditorEquCard";
import generateFileUrl from "../../utils/generateFileUrl";
import { useQuery } from "@tanstack/react-query";
import diacritics from "diacritics";

export default React.memo(function Editor({
  width,
  height,
  onSaveSize,
  maps,
  setMaps,
  selectedMap,
  setSelectedMap,
  setCurrentMap,
  addNewFloor,
  selectTool,
  workCardEvent,
  lastData,
}) {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState({
    width: parseFloat(width).toFixed(2),
    height: parseFloat(height).toFixed(2),
  });
  // const equipments = useQuery({
  //   queryKey: ["process-to-equipments"],
  //   queryFn: async () => await equipmentService.getGroupEquipmentToProcess(),
  // });

  const deleteMap = (index) => {
    setMaps(maps.filter((item, k) => k !== index));

    let newIndex = index == 0 ? 0 : index - 1;
    setCurrentMap(maps[newIndex].mapSettings, newIndex);
  };

  const tools = [
    {
      name: t("routes:wall"),
      icon: <WallIcon />,
      tool: "wall",
    },
    {
      name: t("routes:doorEight"),
      icon: <DoorIcon />,
      tool: "door",
    },
    {
      name: t("routes:doorThree"),
      icon: <DoubleDoorIcon />,
      tool: "sliding door",
    },
    {
      name: t("routes:roundColumn"),
      icon: <ColumnIcon />,
      tool: "round column",
    },
  ];

  return (
    <>
      <div className="flex flex-col p-5 gap-6 border border-solid rounded-lg shadow-md border-[#EAECF0] bg-[#FFF] min-w-[300px] max-w-[300px] overflow-y-auto h-full scrollbar-hide">
        <div className="flex flex-col gap-5">
          <p className="text-xl font-semibold text-[#101828]">
            {t("routes:area")}
          </p>
          <div className="flex flex-col gap-3">
            {maps?.map((item, i) => (
              <div className="flex items-center gap-3">
                <Button
                  onClick={() => setSelectedMap(item?.mapSettings, i)}
                  size={"md"}
                  colorType={
                    selectedMap === i ? "primary-machine" : "secondary-gray"
                  }
                  label={t("routes:area") + " " + (i + 1)}
                />
                {selectedMap === i && (
                  <Button
                    onClick={() => deleteMap(i)}
                    iconLeft={<DeleteIcon />}
                    colorType={"tertiary-error"}
                    size={"md"}
                  />
                )}
              </div>
            ))}

            <Button
              onClick={() => addNewFloor()}
              size={"md"}
              iconLeft={<PlusIcon />}
              colorType={"secondary-gray"}
              label={t("routes:addNewArea")}
            />
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <p className="text-xl font-semibold text-[#101828]">
              {t("embedded:templates")}
            </p>
            <Button
              onClick={() => setIsOpen(true)}
              iconLeft={<SettingsIcon />}
              size={"sm"}
              colorType={"tertiary-gray"}
            />
          </div>
          <div className="flex flex-col gap-3">
            {tools.map((item, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  onClick={() => {
                    selectTool(item?.tool);
                  }}
                  className="flex gap-3 px-3 py-2 border rounded-lg bg-[#FFF]"
                >
                  <span className="text-[#475467] min-w-[24px] min-h-[24px]">
                    {item.icon}
                  </span>
                  <p className="text-[#344054] text-sm font-semibold">
                    {item.name}
                  </p>
                </button>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <p className="text-xl font-semibold text-[#101828]">
              {t("equipments:equipments")}
            </p>
          </div>
          <SearchInput setSearchVal={setSearch} placeholder={t("chat:search")} />
          {lastData?.map((process, index) => (
            <div className="flex flex-col">
              <div
                key={index}
                className="flex justify-between items-center border-b border-[#EAECF0] border-solid pb-2"
              >
                <div className="flex gap-2 items-center">
                  <p>{process?.name}</p>
                  <Badgets
                    colorType={"fill-primary"}
                    label={process?.equipments?.length}
                    size={"sm"}
                  />
                </div>
                {/* <Button
                    iconLeft={<ChevronDown />}
                    size={"sm"}
                    colorType={"tertiary-gray"}
                  /> */}
              </div>

              {process?.equipments
                ?.filter((f) => {
                  let data = `${f?.brand} ${f?.model}`;
                  let dataWords =
                    typeof data === "string" && data?.toLocaleLowerCase();
                  return diacritics.remove(dataWords)?.toLowerCase().includes(diacritics.remove(search));
                })
                ?.map((equ, i) => {
                  return (
                    <div className="mt-2 w-full">
                      <MapEditorEquCard
                        key={i}
                        t={t}
                        onClick={() => {
                          equ.index = i;
                          selectTool("workCard", equ);
                          setTimeout(() => {
                            workCardEvent();
                          }, 3000);
                        }}
                        machineAvatar={generateFileUrl(equ?.image)}
                        equipmentModal={equ?.brand + " " + equ?.model}
                        process={process?.name}
                        isMap={equ.plannerId ? true : false}
                      />
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      </div>

      <CustomModal
        modalTitle={t("embedded:areaSettings")}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        children={
          <div className="w-[300px] flex flex-col mt-3 gap-3">
            <Input
              label={t("embedded:width")}
              value={size.width / 100}
              type="number"
              onChange={(e) =>
                setSize({ ...size, width: e.target.value * 100 })
              }
            />
            <Input
              label={t("embedded:height")}
              value={size.height / 100}
              type="number"
              onChange={(e) =>
                setSize({ ...size, height: e.target.value * 100 })
              }
            />

            <div className="mt-2">
              <Button
                onClick={() => {
                  setIsOpen(false);
                  onSaveSize(size.width, size.height);
                }}
                size={"sm"}
                label={t("buttons:save")}
                colorType={"primary-machine"}
              />
            </div>
          </div>
        }
      />
    </>
  );
});
