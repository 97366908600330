import React, { useRef, useState } from "react";
import { Button } from "../../../components";
import { useTranslation } from "react-i18next";
import { ArrowRight } from "../../../assets/icons/machineIcons";
import ExternalOperationSwiper from "./ExternalOperationSwiper";
const SliderComponent = ({
  selectedOperation,
  setSelectedOperation,
  items,
}) => {
  const { t } = useTranslation();
  const contentWrapper = useRef(null);
  const [visibleButton, setVisibleButton] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const slide = (shift) => {
    contentWrapper.current.scrollLeft += shift;
  };
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <div className="flex justify-between items-center gap-x-4">
      {visibleButton && (
        <div className="rotate-180">
          <Button
            iconLeft={<ArrowRight />}
            colorType={"secondary-gray"}
            onClick={() => slide(-90)}
            size={"md"}
          />
        </div>
      )}
      <div
        id="container"
        ref={contentWrapper}
        onScroll={(e) => {
          if (!isIntersecting) {
            setVisibleButton(true);
          }
        }}
        className="flex items-center w-[99%] h-24 overflow-x-auto scrollbar-hide gap-6"
      >
        <div className="w-[170px] flex gap-6">
          {items?.map((element, index) => {
            return (
              <ExternalOperationSwiper
                key={index}
                item={element}
                index={index}
                setVisibleButton={setVisibleButton}
                setIsIntersecting={setIsIntersecting}
                dataLength={items?.length}
                setSelectedOperation={setSelectedOperation}
                selectedOperation={selectedOperation}
              />
            );
          })}
        </div>
      </div>
      <Button
        size={"md"}
        iconLeft={<ArrowRight />}
        colorType={"secondary-gray"}
        onClick={() => {
          sideScroll(contentWrapper.current, 25, 200, 10);
        }}
      />
    </div>
  );
};

export default SliderComponent;
