import React, { useEffect, useState } from "react";
import { instance } from "../../libs/client";
import Header from "./embeddedServer/Header";
import { Toast } from "../../utils/toastify/toast";
import { authStore } from "../../stores/auth.store";
import Button from "../../components/buttons/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { DangerIcon } from "../../assets/icons/machineIcons";
import AnalogSensorBox from "./embeddedServer/AnalogSensorBox";
import DigitalSensorBox from "./embeddedServer/DigitalSensorBox";
import AlertModal from "../../components/alert-Modal/Alert-Modal";
import { equipmentService } from "../../services/equipment.service";
import { embeddedServerService } from "../../services/embedded-server.service";
import {
  Loading,
  RefreshIcon,
  SaveIcon,
} from "./embeddedServer/svg/EmbeddedServerIcons";
import { useTranslation } from "react-i18next";

const EmbeddedServer = ({ deviceId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { nodeId } = useParams();
  const pattern = /^(.*?)-(.+)$/;
  // const match = nodeId.match(pattern);
  const match = nodeId ? nodeId.match(pattern) : deviceId;
  const [exitAlert, setExitAlert] = useState(false);
  const [resetAlert, setResetAlert] = useState(false);
  const [nodeLoading, setNodeLoading] = useState(false);
  const [connectedNode, setConnectedNode] = useState(null);
  const [activeSensors, setActiveSensors] = useState(null);
  const [sensorLoading, setSensorLoading] = useState(false);
  const [functionLoading, setFunctionLoading] = useState(false);
  const [firstReceivedData, setFirstReceivedData] = useState(null);
  const [registeredSensors, setRegisteredSensors] = useState(null);
  const channel = useChannel(match ? match[1] : deviceId);
  useEvent(channel, "active-sensor-list", (socketEvent) => {
    setActiveSensors(socketEvent);
  });
  // const priorityChannel = useChannel(
  //   String(match[1]) + "-" + String(authStore.user.id)
  // );
  const priorityChannel = useChannel(
    match ? `${match[1]}-${authStore.user.id}` : null
  );
  useEvent(priorityChannel, "logout-embedded-server", (socket) => {
    Toast("error", socket?.message);
    navigate(-1);
  });

  const getNodeInformations = async () => {
    setNodeLoading(true);
    await equipmentService
      .findOneNode(match?.[2])
      .then(({ data }) => {
        setConnectedNode(data);
        setNodeLoading(false);
      })
      .catch(() => {
        Toast("error", t("consoleModal:connectToServer"));
        navigate(-1);
      });
  };

  const getRegisteredSensors = async () => {
    setSensorLoading(true);
    if (connectedNode) {
      await embeddedServerService
        .getSensorSettings(connectedNode?.metadata?.id)
        .then((data) => {
          setSensorLoading(false);
          setRegisteredSensors(data);
          setFirstReceivedData(data);
        })
        .catch(() => {
          Toast("error", t("consoleModal:sensorError"));
          navigate(-1);
        });
    }
  };

  const embeddedServerFinish = async () => {
    setFunctionLoading(true);
    const analogSensors = [];
    const digitalSensors = [];
    Object.values(JSON.parse(JSON.stringify(firstReceivedData))).forEach(
      (sensor) => {
        if (sensor.type.type === "analog") {
          analogSensors.push(sensor);
        } else if (sensor.type.type === "digital") {
          digitalSensors.push(sensor);
        }
      }
    );
    const digitalMapper = digitalSensors.map((sensor) => {
      return {
        filter: null,
        sensorStatus: true,
        deviceId: match ? match[1] : deviceId,
        type: sensor.type.id,
        status: sensor.status,
        topCrop: Number(sensor.topCrop),
        closeBy: Number(sensor.closeBy),
        standBy: Number(sensor.standBy),
        valueOrFreq: sensor.valueOrFreq,
        whichSensor: sensor.whichSensor,
        activeOutput: sensor.activeOutput,
        metadata: connectedNode?.metadata?.id,
        bottomCrop: Number(sensor.bottomCrop),
        isIntegralFreq: sensor?.isIntegralFreq,
        isTotalTimeValue: sensor.isTotalTimeValue,
        isTotalCountValue: sensor.isTotalCountValue,
        samplingNumber: Number(sensor.samplingNumber),
      };
    });
    const analogMapper = analogSensors.map((sensor) => {
      return {
        deviceId: match ? match[1] : deviceId,
        sensorStatus: true,
        phase: sensor.phase,
        cosFi: sensor.cosFi,
        type: sensor.type.id,
        status: sensor.status,
        voltage: sensor.voltage,
        unitType: sensor.unitType,
        whichSensor: sensor.whichSensor,
        topCrop: Number(sensor.topCrop),
        closeBy: Number(sensor.closeBy),
        standBy: Number(sensor.standBy),
        maxValue: Number(sensor.maxValue),
        minValue: Number(sensor.minValue),
        metadata: connectedNode?.metadata?.id,
        bottomCrop: Number(sensor.bottomCrop),
        isIntegralValue: sensor.isIntegralValue,
        currentSensorType: sensor.currentSensorType,
        samplingNumber: Number(sensor.samplingNumber),
      };
    });
    const allData = {
      status: 0,
      deviceId: match ? match[1] : null,
      analog: analogMapper,
      digital: digitalMapper,
    };
    await instance.post("sensor/digital", digitalMapper);
    await instance.post("sensor/analog", analogMapper);
    await instance.post("sensor/save/exit", allData);
    setFunctionLoading(false);
    navigate(-1);
  };

  const exitEmbeddedServer = async () => {
    setFunctionLoading(true);
    let allSensorValues = null;
    await embeddedServerService
      .getSensorSettings(connectedNode?.metadata?.id)
      .then((data) => (allSensorValues = data));
    const allData = {
      status: 0,
      deviceId: match ? match[1] : deviceId,
      analog: [
        allSensorValues?.firstAnalog,
        allSensorValues?.secondAnalog,
      ].filter(Boolean),
      digital: [
        allSensorValues?.firstDigital,
        allSensorValues?.secondDigital,
      ].filter(Boolean),
    };
    await instance.post("sensor/save/exit", allData).then(() => {
      Toast("success", t("chat:transaction"));
      setFunctionLoading(false);
      navigate(-1);
    });
  };

  const cancelButton = async () => {
    await embeddedServerService
      .stateEmbeddedServer(match[1], 0)
      .then((res) => {
        if (res.code === 1) {
          Toast("success", res.message);
          navigate(-1);
        } else {
          Toast("error", t("embedded:internetConnection"));
        }
      })
      .catch((err) => Toast("error", t("embedded:networkError")));
  };

  const resetDevice = async () => {
    await embeddedServerService
      .resetDevice(match[1])
      .then(() => setResetAlert(false));
  };

  const deviceInfo = {
    ring: "#F2F4F7",
    nodeId: match ? match[1] : deviceId,
    activeAnalog: "-",
    activeDigital: "-",
    rssi: connectedNode?.rssi,
    status: connectedNode?.status,
    ipAddress: connectedNode?.ipAddress,
    macAddress: connectedNode?.macAddress,
    image: connectedNode?.metadata?.image,
    stmVersion: connectedNode?.stmVersion,
    espVersion: connectedNode?.espVersion,
    deviceState: connectedNode?.deviceState,
    name: connectedNode?.metadata?.brand + " " + connectedNode?.metadata?.model,
    analog: {
      first: { signal: activeSensors?.firstAnalog },
      second: { signal: activeSensors?.secondAnalog },
      bt: { signal: activeSensors?.btAnalog },
    },
    digital: {
      first: { signal: activeSensors?.firstDigital },
      second: { signal: activeSensors?.secondDigital },
      bt: { signal: activeSensors?.btDigital },
    },
  };

  useEffect(() => {
    Toast("info", t("embedded:embeddedServerSession"));
    getNodeInformations();
  }, []);

  useEffect(() => {
    getRegisteredSensors();
  }, [connectedNode, setConnectedNode]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = t("embedded:leavePage");
      event.returnValue = message;
      return message;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col pt-3">
        <Header deviceInfo={deviceInfo} loading={nodeLoading} />
        <div className="mt-8 flex flex-1 flex-col">
          <div className="flex items-center justify-between gap-x-8">
            {sensorLoading ? (
              <>
                <div className="w-1/3 h-[256px] bg-gray-200 rounded-xl animate-pulse" />
                <div className="w-1/3 h-[256px] bg-gray-200 rounded-xl animate-pulse" />
                <div className="w-1/3 h-[256px] bg-gray-200 rounded-xl animate-pulse" />
              </>
            ) : (
              <>
                <AnalogSensorBox
                  deviceId={match ? match[1] : deviceId}
                  whichSensor={"first"}
                  name={"Analog Sensor 1"}
                  metadataId={connectedNode?.metadata?.id}
                  getRegisteredSensors={getRegisteredSensors}
                  registeredSensor={registeredSensors?.firstAnalog}
                  isActive={deviceInfo.analog.first.signal === 1 ? true : false}
                />
                <AnalogSensorBox
                  deviceId={match ? match[1] : deviceId}
                  whichSensor={"second"}
                  name={"Analog Sensor 2"}
                  metadataId={connectedNode?.metadata?.id}
                  getRegisteredSensors={getRegisteredSensors}
                  registeredSensor={registeredSensors?.secondAnalog}
                  isActive={
                    deviceInfo.analog.second.signal === 1 ? true : false
                  }
                />
                <AnalogSensorBox
                  whichSensor={"bt"}
                  deviceId={match ? match[1] : deviceId}
                  name={"Bluetooth Sensor"}
                  metadataId={connectedNode?.metadata?.id}
                  getRegisteredSensors={getRegisteredSensors}
                  isActive={deviceInfo.analog.bt.signal === 1 ? true : false}
                />
              </>
            )}
          </div>
          <div className="flex items-center justify-between gap-x-8 mt-8">
            {sensorLoading ? (
              <>
                <div className="w-1/3 h-[256px] bg-gray-200 rounded-xl animate-pulse" />
                <div className="w-1/3 h-[256px] bg-gray-200 rounded-xl animate-pulse" />
                <div className="w-1/3 h-[256px] bg-gray-200 rounded-xl animate-pulse" />
              </>
            ) : (
              <>
                <DigitalSensorBox
                  deviceId={match ? match[1] : deviceId}
                  whichSensor={"first"}
                  name={"Digital Sensor 1"}
                  metadataId={connectedNode?.metadata?.id}
                  getRegisteredSensors={getRegisteredSensors}
                  registeredSensor={registeredSensors?.firstDigital}
                  isActive={
                    deviceInfo.digital.first.signal === 1 ? true : false
                  }
                />
                <DigitalSensorBox
                  deviceId={match ? match[1] : deviceId}
                  whichSensor={"second"}
                  name={"Digital Sensor 2"}
                  metadataId={connectedNode?.metadata?.id}
                  getRegisteredSensors={getRegisteredSensors}
                  registeredSensor={registeredSensors?.secondDigital}
                  isActive={
                    deviceInfo.digital.second.signal === 1 ? true : false
                  }
                />
                <DigitalSensorBox
                  whichSensor={"bt"}
                  deviceId={match ? match[1] : deviceId}
                  name={"Bluetooth Sensor"}
                  metadataId={connectedNode?.metadata?.id}
                  getRegisteredSensors={getRegisteredSensors}
                  isActive={deviceInfo.digital.bt.signal === 1 ? true : false}
                />
              </>
            )}
          </div>
          <div className="mt-8 flex w-full items-center">
            <Button
              width={205}
              size={"md"}
              blur={"blur-sm"}
              iconLeft={<RefreshIcon />}
              colorType={"secondary-error"}
              label={"Reset Device"}
              onClick={() =>
                //  setResetAlert(true)
                Toast("warning", "Demo users are not authorized, contact administration.")
                }
            />
            <div className="flex ml-auto gap-x-4">
              <Button
                size={"md"}
                width={177}
                label={t("buttons:stop")}
                colorType={"secondary-embedded"}
                onClick={() => {
                  cancelButton();
                }}
              />
              <Button
                size={"md"}
                width={180}
                blur={"blur-sm"}
                disabled={functionLoading}
                colorType={"primary-embedded"}
                iconLeft={!functionLoading && <SaveIcon />}
                label={
                  functionLoading ? (
                    <Loading color={"#fff"} secondary={"#3E4784"} size={22} />
                  ) : (
                    <p>{t("buttons:saveEndExit")}</p>
                  )
                }
                onClick={() => {
                  // exitEmbeddedServer();
                  Toast("warning", "Demo users are not authorized, contact administration.")
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        isOpen={exitAlert}
        icon={<DangerIcon />}
        setIsOpen={setExitAlert}
        applyButton={embeddedServerFinish}
        title={`${
          connectedNode?.metadata?.brand + " " + connectedNode?.metadata?.model
        } ${t("embedded:connectionLost")}`}
        subTitle={t("embedded:areYouSaveChanges")}
        label={
          functionLoading ? (
            <Loading color={"#fff"} secondary={"red"} size={22} />
          ) : (
            <p>{t("embedded:disconnect")}</p>
          )
        }
      />

      <AlertModal
        label={t("buttons:reset")}
        isOpen={resetAlert}
        title={t("embedded:resetDevice")}
        setIsOpen={setResetAlert}
        applyButton={resetDevice}
        subTitle={t("embedded:actionCannot")}
      />
    </>
  );
};

export default React.memo(EmbeddedServer);
