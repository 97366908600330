import React, { useState } from "react";
import {
  Button,
  CustomModal,
  Input,
  TimeInput,
  UploadProfile,
} from "../../../components";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { InfoIcon } from "../../../assets/icons/PersonIcons";
import HourTimeInput from "../../../components/inputs/HourTimeInput";
import AddUserToNotification from "./AddUserToNotification";
import { Toast } from "../../../utils/toastify/toast";

export default function NewNotifyModal({
  setIsNewNotifyModalOpen,
  notifyRefetch,
}) {
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [sendedData, setSendedData] = useState();
  const [isActive, setIsActive] = useState(false);
  const [isSelectUsersModal, setIsSelectUsersModal] = useState(false);

  const schema = yup.object({
    title: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    text: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    users: yup.array().required(`${t("validation:empty")}`),
    image: yup.string(),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      text: "",
      users: [],
    },
  });

  const onSubmit = async (data) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="min-w-[452px] max-w-[452px] flex flex-col"
    >
      <div className="flex flex-col mt-6">
        <div className="flex flex-col gap-y-5">
          {/* <div className="flex items-center w-full">
            <InfoIcon />
            <span className="ml-2 text-sm font-normal text-[#667085]">
              {t("person:totalNotificationLimit")}
            </span>
            <span className="text-sm font-medium text-[#0E9384] ml-auto">
              140 / 1.200
            </span>
          </div> */}
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("chat:announcementTitle")}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                theme={"person"}
                validate={errors.title ? "error" : ""}
                errorMessage={errors.title ? errors.title.message : ""}
              />
            )}
            name="title"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("chat:announcementDetail")}
                onBlur={onBlur}
                onChange={onChange}
                theme={"person"}
                value={value}
                validate={errors.text ? "error" : ""}
                errorMessage={errors.text ? errors.text.message : ""}
              />
            )}
            name="text"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <UploadProfile
                theme={"person"}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                borderRadius={"4px"}
                title={t("fileInput:uploadAvatar")}
                subTitle={t("fileInput:removePicture")}
              />
            )}
            name="image"
          />
        </div>
        <div className="flex items-center w-full mt-6">
          <p className="text-sm font-medium text-[#344054]">
            {t("buttons:addStartDateTime")}
          </p>
          <div className="ml-auto">
            <div className="flex items-center justify-center">
              <div
                className={`w-[38px] ${
                  isActive ? "bg-[#0E9384]" : "bg-[#F2F4F7]"
                } h-[20px] rounded-xl  relative cursor-pointer`}
                onClick={() => setIsActive(!isActive)}
              >
                <div
                  className={`w-4 h-4 rounded-full bg-white absolute inset-y-[2px]  transition-transform transform ${
                    isActive ? "translate-x-5" : "translate-x-1"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {isActive && (
          <div className="w-full flex flex-col gap-y-4 mt-5">
            <TimeInput
              label={t("label:date")}
              onChange={(e) => {
                setStartDate(e);
              }}
              theme={"person"}
              value={moment(startDate).format("YYYY-MM-DD")}
              minDate={new Date()}
              errorMessage={
                errors?.deliveryDate ? errors?.deliveryDate?.message : ""
              }
              validate={errors?.deliveryDate ? "error" : ""}
            />
            {/* <HourTimeInput
              label={t("addShift:endTime")}
              onChange={(v) => {
                setStartTime(
                  moment(startDate)
                    .set("hour", v.split(":")[0])
                    .set("minute", v.split(":")[1])
                    .toDate()
                );
              }}
              value={moment(startTime).format("HH:mm")}
              validate={errors.end ? "error" : ""}
              errorMessage={errors.end ? errors.end.message : ""}
            /> */}
            <HourTimeInput
              onChange={(v) => {
                setStartTime(v);
              }}
              theme={"person"}
              // setValue={setStartTime}
              value={startTime}
              label={t("addShift:dayStartTime")}
            />
          </div>
        )}
      </div>
      <div className="flex mt-6">
        <Button
          colorType={"primary-person"}
          label={t("chat:sendAnnouncement")}
          type={"submit"}
          onClick={() => setIsSelectUsersModal(true)}
          size={"lg"}
        />
      </div>
      <CustomModal
        isOpen={isSelectUsersModal}
        modalTitle={t("chat:userNotify")}
        setIsOpen={setIsSelectUsersModal}
        children={
          <AddUserToNotification
            notifyRefetch={notifyRefetch}
            t={t}
            setValue={setValue}
            sendedData={sendedData}
            setIsSelectUsersModal={setIsSelectUsersModal}
            setIsNewNotifyModalOpen={setIsNewNotifyModalOpen}
          />
        }
      />
    </form>
  );
}
