import React, { useEffect, useState } from "react";
import { UpuEquipment } from "../../assets/icons/machineIcons";
import { Button, CustomModal } from "../../components";
import { Loader, PlusIcon } from "../../assets/icons/stepsIcons";
import Slider from "./Slider/Slider";
import { equipmentService } from "../../services";
import EquipmentTable from "./Equipment/EquipmentTable";
import generateFileUrl from "../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import AddEquipmentModal from "../../steps/equipments/modals/AddEquipmentModal";
import MachineQrModal from "./components/MachineQrModal";
import { useStore } from "../../hooks/useStores";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../utils/toastify/toast";

const NodeList = () => {
  const { settings } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedNode, setSelectedNode] = useState({});
  const [selectedProcess, setSelectedProcess] = useState("all");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [openQrCode, setOpenQrCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qrData, setQrData] = useState(null);
  const [data, setData] = useState("");
  // const { isLoading, data, refetch } = useQuery({
  //   queryKey: ["getGroupEquipments"],
  //   queryFn: async () => await equipmentService?.getAllEquipmentsAndProcess(),
  // });

  const refetch = () => {
    setIsLoading(true);
    equipmentService.getAllEquipmentsAndProcess().then((res) => {
      setData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <div className="w-full max-w-full flex flex-col overflow-hidden">
          <div className="w-full h-[56px] border-b border-secondary-200 flex items-center  dark:text-[#FAFAFA] ">
            <span className="mr-3 md:flex sm:flex xs:flex hidden">
              <Button
                colorType={"tertiary-gray"}
                iconLeft={<HumburgerIcon />}
                size={"md"}
                onClick={() => settings.setIsActive(true)}
              />
            </span>
            <UpuEquipment />
            <div className="ml-auto flex flex-row items-center">
              <Button
                size={"md"}
                colorType={"primary-machine"}
                iconLeft={<PlusIcon />}
                label={t("addEquipment:addEqu")}
                // onClick={() => navigate("/step/equipment")}
                onClick={() => Toast("warning", "Demo user's are not allowed.")}
              />
            </div>
          </div>
          <div className="flex items-center gap-6 h-10 my-4">
            <Slider
              allProcess={true}
              selectedProcess={selectedProcess}
              setSelectedProcess={setSelectedProcess}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              handleData={refetch}
            />
          </div>

          {selectedProcess === "all" ? (
            <>
              {data?.allEquipments?.length > 0 ? (
                <>
                  <EquipmentTable
                    processName={t("product:all")}
                    // tableHeadItems={tableHeadItems}
                    onEdit={(item) => {
                      setIsEditOpen(true);
                      setSelectedNode(item);
                    }}
                    qrCode={(item) => {
                      setQrData({
                        nodeId: item?.id,
                        name:
                          item?.metadata?.brand + " " + item?.metadata?.model,
                      });
                      setOpenQrCode(true);
                    }}
                    tableBodyItems={data?.allEquipments?.map((d) => {
                      return {
                        ...d,
                        image: generateFileUrl(d?.metadata?.image),
                        machineModal: d?.metadata?.model,
                        machineName: d?.metadata?.brand,
                        puantaj: d?.metadata?.payrollCoefficient,
                        user: [],
                        upuPoint: d?.upuPoint?.current_balance || "",
                      };
                    })}
                    processValue={data?.length}
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              {data?.processEquipments?.map((e, i) => {
                return (
                  <>
                    {selectedProcess === e?.id ? (
                      <>
                        <EquipmentTable
                          key={i}
                          processName={e?.name}
                          // tableHeadItems={tableHeadItems}
                          onEdit={(item) => {
                            setIsEditOpen(true);
                            setSelectedNode(item);
                          }}
                          qrCode={(item) => {
                            setOpenQrCode(true);
                            setQrData({
                              nodeId: item?.id,
                              name:
                                item?.metadata?.model +
                                " " +
                                item?.metadata?.model,
                            });
                          }}
                          tableBodyItems={e?.equipments?.map((d) => {
                            return {
                              ...d,
                              image: generateFileUrl(d?.metadata?.image),
                              machineModal: d?.metadata?.model,
                              machineName: d?.metadata?.brand,
                              puantaj: d?.metadata?.payrollCoefficient,
                              user: [],
                              upuPoint: d?.upuPoint?.current_balance || "",
                            };
                          })}
                          processValue={e?.equ?.length}
                        />
                      </>
                    ) : null}
                  </>
                );
              })}
            </>
          )}
        </div>
      )}

      <CustomModal
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        onClose={() => {
          setSelectedNode(null);
          setIsEditOpen(false);
        }}
        modalTitle={
          selectedNode?.metadata
            ? t("addEquipment:updateEq")
            : t("addEquipment:defineEquipment")
        }
        width="fit-content"
        children={
          <AddEquipmentModal
            t={t}
            setIsOpen={setIsEditOpen}
            isOpen={isEditOpen}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            refetch={refetch}
          />
        }
      />

      <CustomModal
        isOpen={openQrCode}
        onClose={() => {
          setSelectedNode(null);
          setOpenQrCode(false);
        }}
        modalTitle={t("loss:getQrCode")}
        children={
          <MachineQrModal name={qrData?.name} nodeId={qrData?.nodeId} />
        }
      />
    </>
  );
};

export default NodeList;
