import React, { useEffect, useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, ThreeDotsIcon } from "../../../assets/icons/machineIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { InternalOperationIcon } from "../../../assets/icons/stepHeaderIcons";
import { ThreeDots } from "../../../assets/icons/gaugeChartIcons";
import { EditIcon } from "../../../assets/icons/departmentIcon";
import { manipulate } from "../../../utils/manipulate";

const Swiper = ({
  name,
  process,
  enabled,
  onDoubleClickProcess,
  setSelectedProcess,
  selectedProcess,
  index,
  setVisibleButton,
  setIsIntersecting,
  dataLength,
  setVisibleRightButton,
  handleEdit,
}) => {
  const divRef = useRef();
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [view, setView] = useState(false);
  const menuRef = useRef();

  const handleImageError = () => {
    setImageError(true);
  };

  console.log('process', process)

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (index === 0) {
          if (entry.isIntersecting) {
            setVisibleButton(false);
            setIsIntersecting(entry.isIntersecting);
          } else {
            setVisibleButton(true);
          }
        } else if (index === dataLength - 1) {
          if (entry.isIntersecting) {
            setVisibleRightButton(false);
          } else {
            setVisibleRightButton(true);
          }
        }
      });
    }, options);

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setView(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative h-10">
      <div
        ref={divRef}
        key={index}
        className={`flex flex-col h-full cursor-pointer w-[170px]  max-w-[170px] min-w-[170px]  overflow-hidden ${
          selectedProcess === process.id ? "bg-secondary-50 " : ""
        }  gap-2  px-2 rounded-lg border border-secondary-300 justify-center items-center`}
        // onDoubleClick={() => onDoubleClickProcess(process)}
        onClick={() => setSelectedProcess(process.id)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          onClick={() => setSelectedProcess(process.id)}
          className={` cursor-pointer border-secondary-300 w-full flex items-center justify-center text-[10px] gap-x-2 `}
        >
          <div className="w-full flex items-center gap-x-2 text-center justify-center">
            {imageError ? (
              <div className="text-secondary-600">
                <InternalOperationIcon width={20} height={20} />
              </div>
            ) : (
              <img
                src={generateFileUrl(process?.iconKey)}
                onError={handleImageError}
                className="w-5 h-5 "
                alt={name}
              />
            )}
            <p
              className={`text-sm font-semibold text-secondary-700 dark:text-[#FFFF`}
              style={{
                display: "inline-block",
                width: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                numberOfLines: 1,
                textOverflow: "ellipsis",
              }}
            >
              {/* {process?.name.slice(0, 3)} *** */}
              {manipulate(process.id)}
            </p>
          </div>

          {isHovered && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setView(!view);
              }}
              type="button"
              className="w-5 h-5 group justify-end mt-1 cursor-pointer"
            >
              <ThreeDotsIcon />
            </div>
          )}
        </div>
      </div>
      {view === true && (
        <div
          ref={menuRef}
          className="absolute flex flex-col py-1  top-8 right-[12px]  border-[1px] border-secondary-200 z-[999] shadow-lg bg-white rounded-lg cursor-pointer"
        >
          <div className="px-[6px] py-[1px] w-full flex-row items-center">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(process?.id);
              }}
              type="button"
              className={`flex flex-row items-center gap-2 hover:bg-secondary-50 bg-white px-[6px] py-[10px] min-w-[144px] min-h-[40px] cursor-pointer`}
            >
              <div className="w-4 h-4 flex items-center text-[#667085]">
                <EditIcon />
              </div>
              <p className="text-secondary-700 text-sm font-medium">
                {t("addProcess:editOperation")}
              </p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Swiper);
