import React, { useEffect, useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import Junction from "../junction/Junction";
import SearchInput from "../inputs/SearchInput";
import Button from "../buttons/Button";
import { PlayIcon } from "../../assets/icons/productIcons";
import { junctionService } from "../../services/junction.service";
import diacritics from "diacritics";
import { instance } from "../../libs/client";

export default function SelectEquModal({
  processId,
  selectedJunction,
  currentOperation,
  setIsOpen,
  handleData,
}) {
  const [equipmentData, setEquipmentData] = useState(null);
  const [selectedEqu, setSelectedEqu] = useState();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(`nodes/list/for/${processId}`);
        setEquipmentData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (processId) {
      fetchData();
    }
  }, [processId]);

  const filteredEquipment = equipmentData?.filter((d) =>
    diacritics
      .remove(`${d?.metadata?.brand} ${d?.metadata?.model}`)
      .toLowerCase()
      .includes(diacritics.remove(searchValue).toLowerCase())
  );

  return (
    <div className="flex w-[350px] flex-col h-full gap-y-6 mt-6">
      {selectedEqu ? (
        <Junction
          props={{
            onPressUser: function () {},
            node: {
              name:
                selectedEqu?.metadata?.brand +
                " " +
                selectedEqu?.metadata?.model,
              process: currentOperation,
              id: selectedEqu?.id,
              image: selectedEqu?.metadata?.image,
            },
            product: {
              name: selectedJunction?.job?.product?.name,
              code: selectedJunction?.job?.orderNo,
              image: selectedJunction?.job?.product?.image,
              piece: selectedJunction?.targetedWorkCount,
            },
          }}
        />
      ) : (
        <Junction
          props={{
            onPressUser: function () {},
            onPressNode: function () {},
            node: {
              name: "--",
              process: currentOperation,
              id: "test-id",
            },
            product: {
              name: selectedJunction?.job?.product?.name,
              code: selectedJunction?.job?.orderNo,
              image: selectedJunction?.job?.product?.image,
              piece: selectedJunction?.targetedWorkCount,
            },
          }}
        />
      )}
      {selectedEqu ? (
        <Button
          colorType={"primary-machine"}
          size={"md"}
          iconLeft={<PlayIcon />}
          label={"Planla"}
          onClick={async () => {
            await junctionService
              .startPlannedJunction(selectedJunction?.id, selectedEqu?.id)
              .then((response) => {
                setIsOpen(false);
                handleData();
              });
          }}
        />
      ) : (
        <div className="flex flex-col gap-y-4">
          <SearchInput setSearchVal={setSearchValue} />
          <div className="flex flex-col max-h-[330px] min-h-[330px] overflow-y-auto scrollbar-hide gap-y-2 border-y">
            {filteredEquipment?.map((d, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setSelectedEqu(d)}
                  className="border px-3 py-[10px] rounded-lg shadow-xs border-[#D0D5DD] flex gap-x-3 hover:bg-[#F9FAFB] cursor-pointer"
                >
                  <img
                    className="min-w-[60px] max-w-[60px] min-h-[45px] max-h-[45px] rounded border border-[#0000001A]"
                    src={generateFileUrl(d?.metadata?.image)}
                    alt="equipment-image"
                  />
                  <div className="flex flex-col w-[70%]">
                    <span className="text-sm font-semibold text-[#344054] truncate">
                      {d?.metadata?.brand} {d?.metadata?.model}
                    </span>
                    <span className="truncate text-sm font-normal text-[#667085]">
                      {currentOperation}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
