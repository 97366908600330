import React, { useRef, useState } from "react";
import { ArrowDownIcon } from "../../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";

const FinishOperationDropDown = ({
  setIsOpenFinishOperation,
  setIsOpenJunction,
  disabled,
}) => {
  const { t } = useTranslation();
  const [isSortDropdown, setIsSortDropdown] = useState(false);

  const menuRef = useRef();

  const options = [
    {
      label: t("product:junctionManually"),
      value: "addJunction",
      icon: "plus",
    },
    {
      label: t("product:finishThisOperation"),
      value: "finishOperation",
      icon: "stop",
    },
  ];

  return (
    <div ref={menuRef} className="flex w-full h-full relative">
      <div
        onClick={() => setIsSortDropdown(true && !isSortDropdown)}
        className={`flex border  border-secondary-300 px-[14px] py-[10px] rounded-lg gap-x-1 justify-center items-center cursor-pointer ${
          isSortDropdown ? "shadow-ring-gray" : ""
        }`}
      >
        <span className="text-sm font-semibold text-secondary-700 whitespace-nowrap">
          {t("product:manage")}
        </span>
        <span className="min-w-[20px] min-h-[20px]">
          <ArrowDownIcon />
        </span>
      </div>
      {isSortDropdown === true && (
        <div className="flex flex-col min-w-[240px] max-w-[240px] h-fit absolute top-[48px] right-0 bg-white border z-[999999999] rounded-lg shadow-lg py-1 px-[6px] gap-y-[2px] border-secondary-200">
          {options.map((item, index) => (
            <div
              onClick={(e) => {
                e.stopPropagation();

                if (item.value === "addJunction") {
                  setIsOpenJunction(true);
                  setIsSortDropdown(false);
                }
                if (!disabled) {
                  if (item.value === "finishOperation") {
                    setIsOpenFinishOperation(true);
                    setIsSortDropdown(false);
                  }
                }
              }}
              key={index}
              className={`flex items-center min-h-[38px] ${
                item.value === "finishOperation" && disabled
                  ? "bg-[#F2F4F7] opacity-70"
                  : "hover:bg-secondary-50"
              }  max-h-[38px] rounded-md gap-x-3 px-[10px] py-[9px] cursor-pointer  `}
            >
              <div className="flex gap-x-2 items-center w-full">
                <div className="flex min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] justify-end items-center">
                  {item?.icon === "stop" ? (
                    <StopIcon
                      color={
                        item.value === "finishOperation" && disabled
                          ? "#98A2B3"
                          : "#D92D20"
                      }
                    />
                  ) : (
                    <PlusIcon />
                  )}
                </div>

                <span
                  className={`text-sm ${
                    index == 1
                      ? ` ${
                          item.value === "finishOperation" && disabled
                            ? "text-[#667085]"
                            : "text-[#D92D20]"
                        }`
                      : "text-secondary-700"
                  }  font-semibold`}
                >
                  {item?.label}
                </span>
              </div>
              {/* <div
                className={`flex gap-x-1 items-center justify-center text-secondary-500 text-xs font-normal`}
              >
                <>
                  <span>
                    <SwitchIconHorizon />
                  </span>
                  <span>{t("product:low")}</span>
                </>
              </div> */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.00065 1.33398V10.6673M1.33398 6.00065H10.6673"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const StopIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2Z"
        stroke={color}
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FinishOperationDropDown;
