import React, { useEffect, useState } from "react";
import { Button, Input } from "../../../components";
import HourTimeInput from "../../../components/inputs/HourTimeInput";
import moment from "moment";
import { shiftService } from "../../../services/shift.service";

export default function NewBreak({
  setIsOpen,
  selectedActivity,
  t,
  activities,
  setActivities,
  setSelectedActivity,
}) {
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [name, setName] = useState("");
  useEffect(() => {
    if (selectedActivity && selectedActivity?.id) {
      setName(selectedActivity?.name);
      setStart(selectedActivity?.start);
      setEnd(selectedActivity.end);
    } else {
      setName("");
      setStart(new Date());
      setEnd(new Date());
    }
  }, [selectedActivity]);
  const onApprove = async () => {
    const sendData = {
      name: name,
      start: start,
      end: end,
      unique: moment(),
    };
    if (selectedActivity && selectedActivity.id) {
      delete sendData.unique;
      await shiftService.updateActivity(selectedActivity.id, sendData);
    } else {
      setActivities((p) => [...p, sendData]);
    }
    setIsOpen(false);
  };
  return (
    <div className="flex flex-col gap-6 mt-6">
      <div className="flex flex-col gap-4">
        <Input
          label={t("settingTab:breakName")}
          disabled={false}
          onChange={(v) => {
            setName(v.target.value);
          }}
          value={name}
        />
        <HourTimeInput
          label={t("addShift:startTime")}
          onChange={(v) => {
            setStart(
              moment()
                .set("hour", v.split(":")[0])
                .set("minute", v.split(":")[1])
                .toDate()
            );
          }}
          value={moment(start).format("HH:mm")}
        />
        <HourTimeInput
          label={t("addShift:endTime")}
          onChange={(v) => {
            setEnd(
              moment()
                .set("hour", v.split(":")[0])
                .set("minute", v.split(":")[1])
                .toDate()
            );
          }}
          value={moment(end).format("HH:mm")}
        />
      </div>
      <div className="flex gap-3">
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          colorType={"secondary-gray"}
          label={t("buttons:stop")}
        />
        <Button
          onClick={async () => {
            await onApprove();
          }}
          type={"button"}
          colorType={"primary-machine"}
          label={t("buttons:save")}
        />
      </div>
    </div>
  );
}
