import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../../../components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Calender,
} from "../../../../assets/icons/machineIcons";
import moment from "moment";
import EndDatePicker from "../../../person/components/EndDatePicker";
import { useTranslation } from "react-i18next";
import { timeFormatter } from "../../../../utils/timezoneFormatter";
import { authStore } from "../../../../stores/auth.store";

const ShiftSelecter = ({
  data,
  isLoading,
  shiftSelecter,
  currentDay,
  refetch,
  setCurrentDay,
  isFetching,
}) => {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const handlePreviousShift = () => {
    const previousDay = moment(currentDay)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    shiftSelecter(previousDay);
    // refetch();
  };

  const handleNextShift = () => {
    const nextDay = moment(currentDay)
      .subtract(-1, "days")
      .format("YYYY-MM-DD");
    shiftSelecter(nextDay);
    // refetch();
  };

  return (
    <div className="w-full flex items-center justify-between z-10">
      <div className="flex items-center">
        {isLoading || isFetching ? (
          <Skeleton width={130} height={36} borderRadius={8} />
        ) : (
          <Button
            width={144}
            size={"sm"}
            colorType={"secondary-gray"}
            iconLeft={<ArrowLeftIcon />}
            label={t("buttons:previous")}
            onClick={handlePreviousShift}
          />
        )}
      </div>
      {isLoading || isFetching ? (
        <Skeleton height={42} width={135} borderRadius={8} />
      ) : (
        <EndDatePicker
          date={currentDay}
          setDate={setCurrentDay}
          setVisible={setShowDatePicker}
          visible={showDatePicker}
        >
          <div
            onClick={() => {
              setShowDatePicker(!showDatePicker);
            }}
            className="h-[38px] gap-2 border border-secondary-300 rounded-lg cursor-pointer flex items-center justify-between px-3"
          >
            <Calender color={"#344054"} />
            <p className="text-sm font-medium text-secondary-800">
              {moment(
                timeFormatter(currentDay, authStore?.user.company.timeZone)
                  .formatted
              ).format("DD-MM-YYYY")}
            </p>
          </div>
        </EndDatePicker>
      )}
      <div className="flex items-center ">
        {isLoading || isFetching ? (
          <Skeleton width={130} height={36} borderRadius={8} />
        ) : (
          <>
            <Button
              width={144}
              size={"sm"}
              label={t("buttons:next")}
              colorType={"secondary-gray"}
              iconRight={
                <div
                  className={
                    moment(currentDay).format("YYYY-MM-DD") <
                    moment().format("YYYY-MM-DD")
                      ? "opacity-100"
                      : "opacity-30"
                  }
                >
                  <ArrowRightIcon />
                </div>
              }
              disabled={
                // timeFormatter(currentDay) <
                // timeFormatter(moment(), authStore.user.company.timeZone)
                //   .formatted
                //   ? false
                //   : true
                moment(new Date()).format("YYYY-MM-DD") ===
                moment(new Date(currentDay)).format("YYYY-MM-DD")
              }
              onClick={handleNextShift}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ShiftSelecter;
