import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserCardSkeleton = () => {
  return (
    <div className="flex items-center justify-center rounded-sm w-[85%] h-14 gap-x-3 border animate-pulse border-secondary-200">
      <Skeleton
        height={20}
        width={20}
        borderRadius={6}
        containerClassName="items-center "
      />

      <Skeleton
        height={40}
        width={40}
        containerClassName="items-center "
        borderRadius={100}
      />

      <Skeleton
        height={40}
        width={200}
        containerClassName="items-center "
        borderRadius={4}
      />
    </div>
  );
};

export default UserCardSkeleton;
