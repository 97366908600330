export const StartLossesIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="content">
      <g id="Group 45135">
        <path
          id="Icon"
          d="M5.57676 4.07257V6.32898L4.1665 7.17513"
          stroke="#667085"
          strokeWidth="0.846154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.34615 5.99898C1.34615 3.68887 3.21887 1.81616 5.52897 1.81616C7.74815 1.81616 9.56368 3.54433 9.70318 5.72828L10.5485 6.30946C10.5548 6.20677 10.5579 6.10324 10.5579 5.99898C10.5579 3.22155 8.3064 0.97 5.52897 0.97C2.75155 0.97 0.5 3.22155 0.5 5.99898C0.5 8.7764 2.75155 11.0279 5.52897 11.0279C6.03607 11.0279 6.52563 10.9529 6.98716 10.8133V9.9206C6.53313 10.0895 6.04183 10.1818 5.52897 10.1818C3.21887 10.1818 1.34615 8.30908 1.34615 5.99898Z"
        fill="#667085"
      />
      <path
        id="Icon_2"
        d="M8.11523 6.83447C8.11523 6.60295 8.11523 6.48718 8.16351 6.42337C8.20556 6.36778 8.26984 6.33338 8.33943 6.32922C8.4193 6.32445 8.51562 6.38867 8.70826 6.51709L11.2152 8.18836C11.3743 8.29448 11.4539 8.34754 11.4817 8.41441C11.5059 8.47288 11.5059 8.53859 11.4817 8.59706C11.4539 8.66394 11.3743 8.71699 11.2152 8.82311L8.70826 10.4944C8.51562 10.6228 8.4193 10.687 8.33943 10.6822C8.26984 10.6781 8.20556 10.6437 8.16351 10.5881C8.11523 10.5243 8.11523 10.4085 8.11523 10.177V6.83447Z"
        stroke="#667085"
        strokeWidth="0.846154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const StopLossesIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 45142">
      <g id="Group 45135">
        <path
          id="Icon"
          d="M5.71055 4.01919V6.33498L4.26318 7.2034"
          stroke="#667085"
          strokeWidth="0.868421"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.36842 5.99632C1.36842 3.62542 3.29042 1.70342 5.66132 1.70342C8.03222 1.70342 9.95421 3.62542 9.95421 5.99632C9.95421 6.01274 9.95412 6.02914 9.95393 6.04553H10.8053C10.811 6.04553 10.8167 6.04553 10.8224 6.04553C10.8226 6.02914 10.8226 6.01274 10.8226 5.99632C10.8226 3.1458 8.51183 0.835 5.66132 0.835C2.8108 0.835 0.5 3.1458 0.5 5.99632C0.5 8.84683 2.8108 11.1576 5.66132 11.1576C6.18837 11.1576 6.69697 11.0786 7.17592 10.9318C7.13872 10.8856 7.10616 10.8354 7.07891 10.7819C6.99935 10.6257 6.98667 10.429 6.98464 10.0814C6.56782 10.2163 6.12308 10.2892 5.66132 10.2892C3.29042 10.2892 1.36842 8.36722 1.36842 5.99632Z"
        fill="#667085"
      />
      <path
        id="Icon_2"
        d="M8.02637 8.12971C8.02637 7.80547 8.02637 7.64335 8.08947 7.51951C8.14497 7.41057 8.23354 7.32201 8.34248 7.2665C8.46632 7.2034 8.62844 7.2034 8.95268 7.2034H10.5737C10.898 7.2034 11.0601 7.2034 11.1839 7.2665C11.2929 7.32201 11.3814 7.41057 11.437 7.51951C11.5001 7.64335 11.5001 7.80547 11.5001 8.12971V9.75077C11.5001 10.075 11.5001 10.2371 11.437 10.361C11.3814 10.4699 11.2929 10.5585 11.1839 10.614C11.0601 10.6771 10.898 10.6771 10.5737 10.6771H8.95268C8.62844 10.6771 8.46632 10.6771 8.34248 10.614C8.23354 10.5585 8.14497 10.4699 8.08947 10.361C8.02637 10.2371 8.02637 10.075 8.02637 9.75077V8.12971Z"
        stroke="#667085"
        strokeWidth="0.868421"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const DefaultLossesIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Loss Types">
      <path
        id="Icon"
        d="M15.3335 9.05611C15.6919 8.03714 16.3995 7.17791 17.3307 6.63061C18.262 6.0833 19.3569 5.88323 20.4215 6.06585C21.4861 6.24846 22.4518 6.80196 23.1474 7.62832C23.843 8.45468 24.2238 9.50058 24.2222 10.5808C24.2222 13.63 19.6482 15.1547 19.6482 15.1547M19.7074 19.3333V18"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Icon_2"
        d="M24.6669 28.6667L17.4212 21.4209C17.1571 21.1569 17.0251 21.0249 16.8729 20.9754C16.739 20.9319 16.5948 20.9319 16.4609 20.9754C16.3087 21.0249 16.1767 21.1569 15.9127 21.4209L13.4212 23.9124C13.1571 24.1764 13.0251 24.3084 12.8729 24.3579C12.739 24.4014 12.5948 24.4014 12.4609 24.3579C12.3087 24.3084 12.1767 24.1764 11.9127 23.9124L6.00024 18M24.6669 28.6667H19.3336M24.6669 28.6667V23.3333"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Icon_3"
        d="M22.6667 28L15.4209 20.7543C15.1569 20.4902 15.0249 20.3582 14.8727 20.3088C14.7388 20.2653 14.5946 20.2653 14.4607 20.3088C14.3084 20.3582 14.1764 20.4902 13.9124 20.7542L11.4209 23.2458C11.1569 23.5098 11.0249 23.6418 10.8727 23.6912C10.7388 23.7347 10.5946 23.7347 10.4607 23.6912C10.3084 23.6418 10.1764 23.5098 9.91242 23.2458L4 17.3333M22.6667 28H17.3333M22.6667 28V22.6667"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
