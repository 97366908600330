export const InfoIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M10.681 13.3337V10.0003M10.681 6.66699H10.6893M19.0143 10.0003C19.0143 14.6027 15.2834 18.3337 10.681 18.3337C6.07862 18.3337 2.34766 14.6027 2.34766 10.0003C2.34766 5.39795 6.07862 1.66699 10.681 1.66699C15.2834 1.66699 19.0143 5.39795 19.0143 10.0003Z"
        stroke="#363F72"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.39668 15.0963C2.43497 14.7517 2.45411 14.5794 2.50624 14.4184C2.55249 14.2755 2.61784 14.1396 2.70051 14.0142C2.79369 13.8729 2.91627 13.7503 3.16142 13.5052L14.1667 2.49992C15.0871 1.57945 16.5795 1.57945 17.5 2.49993C18.4205 3.4204 18.4205 4.91279 17.5 5.83326L6.49475 16.8385C6.2496 17.0836 6.12702 17.2062 5.98572 17.2994C5.86035 17.3821 5.72439 17.4474 5.58152 17.4937C5.42048 17.5458 5.24819 17.5649 4.90362 17.6032L2.08331 17.9166L2.39668 15.0963Z"
        stroke="#363F72"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RefreshIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.1667 15.7285C15.9345 14.4406 17.0833 12.3543 17.0833 9.99967C17.0833 6.08766 13.912 2.91634 10 2.91634H9.58334M10 17.083C6.08799 17.083 2.91667 13.9117 2.91667 9.99967C2.91667 7.64506 4.06556 5.55878 5.83334 4.27084M9.16667 18.6663L10.8333 16.9997L9.16667 15.333M10.8333 4.66634L9.16667 2.99967L10.8333 1.33301"
        stroke="#B42318"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M6.33333 2.5V5.33333C6.33333 5.80004 6.33333 6.0334 6.42416 6.21166C6.50406 6.36846 6.63154 6.49594 6.78834 6.57584C6.9666 6.66667 7.19996 6.66667 7.66667 6.66667H13.3333C13.8 6.66667 14.0334 6.66667 14.2117 6.57584C14.3685 6.49594 14.4959 6.36846 14.5758 6.21166C14.6667 6.0334 14.6667 5.80004 14.6667 5.33333V3.33333M14.6667 17.5V12.1667C14.6667 11.7 14.6667 11.4666 14.5758 11.2883C14.4959 11.1315 14.3685 11.0041 14.2117 10.9242C14.0334 10.8333 13.8 10.8333 13.3333 10.8333H7.66667C7.19996 10.8333 6.9666 10.8333 6.78834 10.9242C6.63154 11.0041 6.50406 11.1315 6.42416 11.2883C6.33333 11.4666 6.33333 11.7 6.33333 12.1667V17.5M18 7.77124V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V6.5C3 5.09987 3 4.3998 3.27248 3.86502C3.51217 3.39462 3.89462 3.01217 4.36502 2.77248C4.8998 2.5 5.59987 2.5 7 2.5H12.7288C13.1364 2.5 13.3402 2.5 13.5321 2.54605C13.7021 2.58688 13.8647 2.65422 14.0138 2.7456C14.182 2.84867 14.3261 2.9928 14.6144 3.28105L17.219 5.88562C17.5072 6.17387 17.6513 6.318 17.7544 6.48619C17.8458 6.63531 17.9131 6.79789 17.9539 6.96795C18 7.15976 18 7.36358 18 7.77124Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Loading = ({ color, size, secondary }) => {
  return (
    <svg
      aria-hidden="true"
      width={size}
      height={size}
      className="animate-spin"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill={color}
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill={secondary}
      />
    </svg>
  );
};
