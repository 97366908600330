import React from "react";

const CustomToggle = ({
  width,
  label,
  enable,
  setEnable,
  firstText,
  secondText,
}) => {
  return (
    <div style={{ width: width || "100%" }} className="flex flex-col">
      {label && (
        <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 dark:text-darkTrue-200 font-medium">
          {label}
        </span>
      )}
      <div className="h-[42px] w-full flex items-center justify-between bg-gray-200 rounded-lg px-1 border border-secondary-200">
        <button
          onClick={() => {
            setEnable(false);
          }}
          type="button"
          className={`${
            enable ? "bg-transparent" : "bg-white rounded-md shadow-sm"
          } flex items-center justify-center h-[34px] w-1/2`}
        >
          <p
            className={`text-xs font-semibold ${
              enable ? "text-secondary-500" : "text-secondary-700"
            }`}
          >
            {firstText}
          </p>
        </button>
        <button
          onClick={() => {
            setEnable(true);
          }}
          type="button"
          className={`${
            !enable ? "bg-transparent" : "bg-white rounded-md shadow-sm"
          } flex items-center justify-center h-[34px] w-1/2`}
        >
          <p
            className={`text-xs font-semibold ${
              !enable ? "text-secondary-500" : "text-secondary-700"
            }`}
          >
            {secondText}
          </p>
        </button>
      </div>
    </div>
  );
};

export default React.memo(CustomToggle);
