import React, { memo, useEffect, useRef, useState } from "react";
import NoAvatar from "../../../components/avatar/NoAvatar";
import {
  ChatHeaderUsersIcon,
  ChevronRightIcon,
  HeaderMuteIcon,
  InfoSquareIcon,
  LeaveGroupIcon,
  MenuIcon,
  MuteIcon,
  MuteIconNew,
  PollDeleteIcon,
  UnmuteIcon,
  UsersIcon,
} from "../../../assets/icons/PersonIcons";
import { observer } from "mobx-react-lite";
import { Loader } from "../../../assets/icons/stepsIcons";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { ArrowLeftIcon, DangerIcon } from "../../../assets/icons/machineIcons";
import { authStore } from "../../../stores/auth.store";
import { chatService } from "../../../services/chat.service";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { Button } from "../../../components";
import { ChevronLeft } from "../../../assets/icons";

const ChatHeader = observer(
  ({
    image,
    name,
    type,
    conversationId,
    setSelectedGroup,
    findConversation,
    participants,
  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { width } = useWindowDimensions();
    const menuRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [alertLeaveGroup, setAlertLeaveGroup] = useState(false);
    const [leaveGroupData, setLeaveGroupData] = useState({});
    const [alertTaskGroup, setAlertTaskGroup] = useState(false);
    const [unmuteAlert, setUnmuteAlert] = useState(false);

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const menuItems = [
      {
        name: t("person:groupInfo"),
        icon: <InfoSquareIcon />,
        onClick: () => {
          setSelectedGroup(conversationId);
        },
      },
      {
        name:
          findConversation?.isQuite === true
            ? t("person:unmuteGroup")
            : t("person:muteGroup"),
        icon:
          findConversation?.isQuite === true ? <UnmuteIcon /> : <MuteIconNew />,
        onClick: () => setUnmuteAlert(true),
      },
      {
        name: t("person:leaveGroup"),
        icon: <LeaveGroupIcon />,
        onClick: () => {
          setAlertLeaveGroup(true);
          setLeaveGroupData({
            conversationId: conversationId,
            userId: authStore.user.id,
          });
        },
      },
      {
        name: t("buttons:delete"),
        icon: <PollDeleteIcon />,
        onClick: () => setAlertTaskGroup(true),
      },
    ];

    return (
      <>
        <div className="w-full flex items-center border-b border-secondary-200 pb-3">
          {(width < 768 && width > 575) || (width < 576 && width > 370) ? (
            <Button
              colorType={"tertiary-gray"}
              size={"sm"}
              iconLeft={<ArrowLeftIcon />}
              onClick={() => navigate("/app/person/message")}
            />
          ) : (
            <></>
          )}
          {findConversation === undefined ? (
            <div className="text-secondary-700 w-12 h-12 flex items-center justify-center">
              <Loader currentColor={"#667085"} />
            </div>
          ) : (
            <>
              {image !== "Empty" ? (
                <img
                  src={image}
                  className="w-12 h-12 blur-sm rounded-full border border-secondary-200"
                />
              ) : (
                <NoAvatar size={48} name={name} fontSize={20} />
              )}
            </>
          )}
          <div className="flex flex-col items-start w-[95%]">
            <p className="font-semibold text-secondary-700 text-lg ml-3 ">
              {findConversation === undefined ? "Loading..." : name.slice(0, 3) + "***"}
            </p>
            {type === "group-task" && (
              <div className="flex flex-row items-center gap-1 ml-3">
                <ChatHeaderUsersIcon />
                <p className="font-normal text-secondary-600 text-sm">
                  {participants} Users
                </p>
              </div>
            )}
          </div>
          <div className="flex ml-2 items-center ">
            {findConversation?.isQuite === true && <HeaderMuteIcon />}
          </div>

          {type === "group-task" && (
            <div className="flex flex-col relative ml-auto" ref={menuRef}>
              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="w-8 h-8 flex items-center justify-center"
                type="button"
              >
                <MenuIcon size={20} />
              </button>
              {isOpen && (
                <div className="absolute w-[190px] p-1 bg-white right-0 top-8 border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1">
                  {menuItems.map((item, index) => {
                    return (
                      <button
                        // onClick={() => {
                        //   item.onClick();
                        //   setIsOpen(false);
                        // }}
                        onClick={() => Toast("warning", t("alert:warning"))}
                        key={index}
                        type="button"
                        className="w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded"
                      >
                        <div className="w-6">{item.icon}</div>
                        <p
                          className={`font-medium text-sm ${
                            index === 3 ? "text-red-500" : "text-secondary-700"
                          } `}
                        >
                          {item.name}
                        </p>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <AlertModal
          icon={<DangerIcon />}
          title={"upu.io"}
          subTitle={t("person:areYouLeave")}
          label={t("buttons:leave")}
          isOpen={alertLeaveGroup}
          setIsOpen={setAlertLeaveGroup}
          applyButton={async () => {
            await chatService
              .leaveGroupConversations(leaveGroupData)
              .then((res) => {
                Toast("success", res?.data?.message);
                if (pathname?.includes("message")) {
                  navigate("/app/person/message");
                }
                if (pathname?.includes("task")) {
                  navigate(`/app/person/task`);
                }
                setAlertLeaveGroup(false);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        />
        <AlertModal
          icon={<DangerIcon />}
          title={"upu.io"}
          subTitle={t("person:areYouDelete")}
          isOpen={alertTaskGroup}
          setIsOpen={setAlertTaskGroup}
          applyButton={async () => {
            await chatService
              .deleteTaskGroup(conversationId)
              .then((res) => {
                if (res.data.code === 1) {
                  Toast("success", res?.data?.message);
                  if (pathname?.includes("message")) {
                    navigate("/app/person/message");
                  }
                  if (pathname?.includes("task")) {
                    navigate(`/app/person/task`);
                  }
                  setAlertTaskGroup(false);
                } else {
                  Toast("error", t("chat:notTransaction"));
                }
              })
              .catch((err) => console.log("err", err));
          }}
        />
        <AlertModal
          icon={
            findConversation?.isQuite === false ? (
              <MuteIconNew />
            ) : (
              <UnmuteIcon />
            )
          }
          title={"upu.io"}
          subTitle={
            findConversation?.isQuite === false
              ? t("person:areYouMute")
              : t("person:areYouUnmuteGroup")
          }
          label={
            findConversation?.isQuite === false
              ? t("person:mute")
              : t("person:unmute")
          }
          isOpen={unmuteAlert}
          setIsOpen={setUnmuteAlert}
          applyButton={async () => {
            await chatService
              .handleQuiteConversation(findConversation?.conversationId, {
                status: findConversation?.isQuite ? false : true,
              })
              .then((res) => {
                if (res?.data?.code === 0) {
                  Toast("success", res?.data?.message);
                  // refetch();
                  setUnmuteAlert(false);
                }
              });
          }}
        />
      </>
    );
  }
);

export default memo(ChatHeader);
