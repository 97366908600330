import React, { useState, useEffect } from "react";
import { Button, CustomModal, Loading, Tab } from "../../components";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FilterIcon } from "../machine/embeddedServer/svg";
import SearchInput from "../../components/inputs/SearchInput";
import { junctionService } from "../../services/junction.service";
import Badgets from "../../components/buttons/Badgets";
import Junction from "../../components/junction/Junction";
import NewFinishedJunctionCard from "../../components/junction/NewFinishedJunctionCard";
import generateFileUrl from "../../utils/generateFileUrl";
import AllOperationsCard from "./components/AllOperationsCard";
import ExternalPlanView from "./components/ExternalPlanView";
import SliderComponent from "../machine/Slider/SliderComponent";
import { orderService } from "../../services/order.service";
import diacritics from "diacritics";
import FinishJunctionModal from "../../components/modal/FinishJunctionModal";
import { manipulate } from "../../utils/manipulate";
const ServiceSupply = () => {
  const { t } = useTranslation();
  const { settings } = useStore();
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOperation, setSelectedOperation] = useState("all");
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [junctionType, setJunctionType] = useState("external");
  const [selectedTab, setSelectedTab] = useState("junction");
  const [selectOperationData, setSelectOperationData] = useState(null);
  const [selected, setSelected] = useState("all");
  const junctionData = useQuery({
    queryKey: ["all-operation-junctions"],
    enabled: true,
    retry: 0,
    queryFn: async () => {
      return await junctionService.getExternalJunctions();
    },
  });
  const dataByOperation = useQuery({
    queryKey: ["operations-junctions"],
    enabled: false,
    retry: 0,
    queryFn: async () => {
      return await junctionService
        .getExternalJunctionsForOperations(selectedOperation.id)
        .then((res) => {
          setSelectOperationData(res);
          return res;
        });
    },
  });
  const planView = useQuery({
    queryKey: ["external-operation-detail-view-plan"],
    enabled: false,
    queryFn: async () =>
      await junctionService.getExtenralOperationJunctions(
        selectedOperation?.id
      ),
    retry: 0,
  });
  useEffect(() => {
    if (selectedOperation == "all") {
      if (selectedTab == "junction") {
        junctionData?.refetch();
      } else {
        setSelectedOperation(
          junctionData?.data?.operations?.sort(
            (a, b) =>
              b?.active +
              b?.past +
              b?.pending -
              (a?.active + a?.past + a?.pending)
          )[0] || "all"
        );
      }
    } else {
      if (selectedTab == "junction") {
        dataByOperation?.refetch();
      } else {
        planView?.refetch();
      }
    }
  }, [selectedOperation, selectedTab]);
  const cleanAndMatchSearch = (item, searchTerm) => {
    const fullName = `${item?.user?.name} ${item?.user?.lastName}`;
    const cleanedFullName = diacritics.remove(fullName).toLowerCase();
    const cleanedSearchTerm = diacritics.remove(searchTerm.toLowerCase());
    return cleanedFullName.includes(cleanedSearchTerm);
  };

  const filteredActiveJunction = junctionData?.data?.activeJunction?.filter(
    (item) => {
      return cleanAndMatchSearch(item, searchTerm);
    }
  );

  const filteredOperationActiveJunction =
    selectOperationData?.activeJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPendingJunction = junctionData?.data?.pendingJunction?.filter(
    (item) => {
      return cleanAndMatchSearch(item, searchTerm);
    }
  );

  const filteredOperationPendingJunction =
    selectOperationData?.pendingJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPreviousPendingJunction =
    junctionData?.data?.previousPending?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredOperationPreviousPendingJunction =
    selectOperationData?.previousPending?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });

  const filteredPastJunction = junctionData?.data?.pastJunction?.filter(
    (item) => {
      return cleanAndMatchSearch(item, searchTerm);
    }
  );

  const filteredOperationPastJunction =
    selectOperationData?.pastJunction?.filter((item) => {
      return cleanAndMatchSearch(item, searchTerm);
    });
  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <>
        <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
          <span className="mr-3 md:flex sm:flex xs:flex hidden">
            <Button
              colorType={"tertiary-gray"}
              iconLeft={<HumburgerIcon />}
              size={"md"}
              onClick={() => settings.setIsActive(true)}
            />
          </span>
          <div className="flex items-center">
            <span className="font-codecMedium text-2xl text-black">upu.</span>
            <p className="text-2xl font-codecMedium text-[#B54708]">supply</p>
          </div>
          <span className="ml-auto">
            <Button
              colorType={"primary-product"}
              size={"md"}
              label={t("product:supplierList")}
              onClick={() => navigate("list")}
            />
          </span>
        </div>
        {selectedTab == "junction" ? (
          <div
            className={`flex w-full gap-x-5 overflow-x-auto pb-1 ${
              !false
                ? "min-h-[152px] max-h-[152px]"
                : "min-h-[132px] max-h-[132px]"
            }`}
          >
            <AllOperationsCard
              false={false}
              pendingJunctionlength={
                junctionData?.data?.pendingJunction?.length
              }
              activeJunctionlength={junctionData?.data?.activeJunction?.length}
              pastJunctionlength={junctionData?.data?.pastJunction?.length}
              allCount={junctionData?.data?.allCount}
              onClick={setSelectedOperation}
              selected={selectedOperation}
            />
            {junctionData?.data?.operations
              ?.sort(
                (a, b) =>
                  b?.active +
                  b?.past +
                  b?.pending -
                  (a?.active + a?.past + a?.pending)
              )
              ?.map((item, index) => {
                console.log('item', item)
                return (
                  <div
                    onClick={async () => {
                      setSelectedOperation(item);
                    }}
                    key={index}
                    className={`flex flex-col min-w-[172px] max-w-[172px] border h-[96%] shadow-sm ${
                      selectedOperation?.id === item?.id
                        ? "border-[#DC6803]"
                        : "border-[#EAECF0]"
                    }  px-3 py-2 rounded-lg cursor-pointer`}
                  >
                    <div className="flex gap-x-2 pb-[6px] items-center">
                      <span className="min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]">
                        {item?.iconKey ? (
                          <img src={generateFileUrl(item?.iconKey)} />
                        ) : (
                          <TransformIcon />
                        )}
                      </span>
                      <span className="text-[#344054] text-sm font-semibold truncate">
                        {/* {item?.name ? item?.name?.slice(0, 3)+ " ***" : "***"} */}
                        {manipulate(item?.name) || "Process"}
                      </span>
                    </div>
                    <div className="flex flex-col w-full h-full justify-center">
                      {!false && (
                        <div className="flex min-h-[30px] max-h-[30px] border-b border-[#EAECF0] py-1 items-center">
                          <span className="text-[#667085] font-normal text-xs">
                            {t("product:actives")}
                          </span>
                          <span className="ml-auto">
                            <Badgets
                              colorType={"fill-success"}
                              size={"sm"}
                              label={item?.active || 0}
                            />
                          </span>
                        </div>
                      )}
                      <div className="flex min-h-[30px] max-h-[30px] border-b border-[#EAECF0] py-1 items-center">
                        <span className="text-[#667085] font-normal text-xs">
                          {t("product:waiting")}
                        </span>
                        <span className="ml-auto">
                          <Badgets
                            colorType={"fill-warning"}
                            size={"sm"}
                            label={item?.pending || 0}
                          />
                        </span>
                      </div>
                      <div className="flex min-h-[26px] max-h-[26px] py-1 items-center">
                        <span className="text-[#667085] font-normal text-xs">
                          {t("product:completedNew")}
                        </span>
                        <span className="ml-auto pt-1">
                          <Badgets
                            colorType={"fill-gray"}
                            size={"sm"}
                            label={item?.past || 0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <SliderComponent
            selectedOperation={selectedOperation}
            setSelectedOperation={setSelectedOperation}
            items={junctionData?.data?.operations?.sort(
              (a, b) =>
                b?.active +
                b?.past +
                b?.pending -
                (a?.active + a?.past + a?.pending)
            )}
          />
        )}
        <Tab
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          options={[
            { value: "junction", label: t("product:junctionList") },
            { value: "plan", label: t("product:planView") },
          ]}
        />
        {dataByOperation?.isFetching ||
        dataByOperation?.isLoading ||
        junctionData?.isFetching ||
        junctionData?.isLoading ? (
          <div className="flex w-full h-full items-center justify-center">
            <Loading color={"#B54708"} secondary={"#B54708"} size={48} />
          </div>
        ) : selectedTab == "junction" ? (
          <>
            <div className="flex w-full flex-1 border border-[#D0D5DD] rounded-xl mt-4 flex-col overflow-hidden">
              <div className="flex min-h-[72px] max-h-[72px] p-4 justify-between w-full items-center">
                <span className="text-[#101828] text-xl font-semibold">
                  {selectedOperation?.name
                    ? manipulate(selectedOperation?.name) // ? selectedOperation?.name?.slice(0, 3) + " ***"
                    : t("product:allNew")}
                </span>
                <div className="flex gap-x-3">
                  <span className="flex min-w-[220px] max-w-[220px] h-full items-center justify-center">
                    <SearchInput
                      theme={"product"}
                      setSearchVal={setSearchTerm}
                      placeholder={t("chat:search")}
                    />
                  </span>
                  <Button
                    colorType={"secondary-gray"}
                    size={"md"}
                    label={t("product:sorted")}
                    iconLeft={<SwitchIcon />}
                  />
                  <Button
                    colorType={"secondary-gray"}
                    size={"md"}
                    label={t("product:filters")}
                    iconLeft={<FilterIcon />}
                  />
                </div>
              </div>
              <div className="flex w-full flex-1 overflow-hidden">
                {!false && (
                  <div className="flex flex-col w-1/3 border-t border-r h-full overflow-hidden">
                    <div className="flex min-h-[38px] max-h-[38px] px-4 py-2 bg-[#F9FAFB] border-b border-[#EAECF0] w-full gap-x-2 items-center">
                      <span className="text-[#344054] text-sm font-semibold">
                        {t("product:activeTasks")}
                      </span>
                      <Badgets
                        colorType={"fill-success"}
                        label={
                          selectedOperation === "all"
                            ? filteredActiveJunction?.length
                            : filteredOperationActiveJunction?.length || 0
                        }
                        size={"sm"}
                      />
                    </div>
                    <div className="flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide flex-1 w-full px-4 pt-2 gap-8">
                      {selectedOperation === "all" ? (
                        <>
                          {filteredActiveJunction?.map((item, index) => {
                            const startDate = moment(item?.startDate);
                            return (
                              <div className="min-h-[80px] cursor-pointer">
                                <NewFinishedJunctionCard
                                  qualityPercent={
                                    item?.metadata?.qualityPercent
                                  }
                                  false={false}
                                  allData={item}
                                  externalOperation={item?.externalOperation}
                                  supplier={item?.supplier}
                                  pages={"activeAndPlanned"}
                                  refetch={junctionData?.refetch}
                                  statusType={item?.status}
                                  junctionId={item?.id}
                                  step={item?.header?.step}
                                  key={index}
                                  onClick={() => {
                                    setJunctionType(
                                      item?.externalOperation
                                        ? "external"
                                        : "internal"
                                    );
                                    setFinishJunctionId(item?.id);
                                    setIsOpenJunctionPiece(true);
                                  }}
                                  props={{
                                    startDate: startDate,
                                    ring:
                                      item.type === "work"
                                        ? "#17B26A"
                                        : item.type === "setup"
                                        ? "#EAAA08"
                                        : "#98A2B3",
                                    user: {
                                      image: item?.user?.avatar,
                                      name: item?.user?.name,
                                      lastName: item?.user?.lastName,
                                      id: item?.user?.id,
                                    },
                                    status: item?.metadata?.qualityStatus,
                                    product: {
                                      name: item?.job?.product?.name,
                                      code: item?.job?.orderNo,
                                      image: item?.job?.product?.image,
                                      pieceGreen: item?.estimatedWorkCount,
                                      pieceRed: item?.rejectedWorkCount,
                                    },
                                    node: {
                                      name:
                                        item?.station?.metadata?.brand +
                                        " " +
                                        item?.station?.metadata?.model,
                                      image: item?.station?.metadata?.image,
                                      process: item?.internalOperation?.name,
                                    },
                                    time: {
                                      timeRed: "--",
                                      timeGreen:
                                        moment(startDate)?.format("HH:mm"),
                                    },
                                    // onPressNode: (e) => {
                                    //   // e.stopPropagation();
                                    //   navigate(
                                    //     `/app/machine/digital-twin/equipment-details/${
                                    //       item?.station?.deviceId
                                    //     }-${item?.station?.id}/${moment(
                                    //       item?.startDate
                                    //     ).format("YYYY-MM-DD")}`
                                    //   );
                                    // },
                                    // onPressProduct: (e) => {
                                    //   // e.stopPropagation();
                                    //   navigate(
                                    //     `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                    //   );
                                    // },
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {filteredOperationActiveJunction?.map(
                            (item, index) => {
                              const startDate = moment(item?.startDate);
                              return (
                                <div className="min-h-[80px] cursor-pointer">
                                  <NewFinishedJunctionCard
                                    qualityPercent={
                                      item?.metadata?.qualityPercent
                                    }
                                    false={false}
                                    allData={item}
                                    externalOperation={item?.externalOperation}
                                    supplier={item?.supplier}
                                    pages={"activeAndPlanned"}
                                    refetch={junctionData?.refetch}
                                    statusType={item?.status}
                                    junctionId={item?.id}
                                    step={item?.header?.step}
                                    key={index}
                                    onClick={() => {
                                      setJunctionType(
                                        item?.externalOperation
                                          ? "external"
                                          : "internal"
                                      );
                                      setFinishJunctionId(item?.id);
                                      setIsOpenJunctionPiece(true);
                                    }}
                                    props={{
                                      startDate: startDate,
                                      ring:
                                        item.type === "work"
                                          ? "#17B26A"
                                          : item.type === "setup"
                                          ? "#EAAA08"
                                          : "#98A2B3",
                                      user: {
                                        image: item?.user?.avatar,
                                        name: item?.user?.name,
                                        lastName: item?.user?.lastName,
                                        id: item?.user?.id,
                                      },
                                      status: item?.metadata?.qualityStatus,
                                      product: {
                                        name: item?.job?.product?.name,
                                        code: item?.job?.orderNo,
                                        image: item?.job?.product?.image,
                                        pieceGreen: item?.madeWorkCount,
                                        pieceRed: item?.rejectedWorkCount,
                                      },
                                      node: {
                                        name:
                                          item?.station?.metadata?.brand +
                                          " " +
                                          item?.station?.metadata?.model,
                                        image: item?.station?.metadata?.image,
                                        process: item?.internalOperation?.name,
                                      },
                                      time: {
                                        timeRed: "--",
                                        timeGreen:
                                          moment(startDate)?.format("HH:mm"),
                                      },
                                      // onPressNode: (e) => {
                                      //   // e.stopPropagation();
                                      //   navigate(
                                      //     `/app/machine/digital-twin/equipment-details/${
                                      //       item?.station?.deviceId
                                      //     }-${item?.station?.id}/${moment(
                                      //       item?.startDate
                                      //     ).format("YYYY-MM-DD")}`
                                      //   );
                                      // },
                                      // onPressProduct: (e) => {
                                      //   // e.stopPropagation();
                                      //   navigate(
                                      //     `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                      //   );
                                      // },
                                    }}
                                  />
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`flex flex-col h-full overflow-hidden ${
                    !false ? "w-1/3" : "w-1/2"
                  } border-t border-r`}
                >
                  <div className="flex min-h-[38px] max-h-[38px] px-4 py-2 bg-[#F9FAFB] border-b border-[#EAECF0] w-full gap-x-2 items-center">
                    <span className="text-[#344054] text-sm font-semibold">
                      {t("product:completedPendingJobs")}
                    </span>
                    <Badgets
                      colorType={"fill-warning"}
                      label={
                        selectedOperation === "all"
                          ? filteredPendingJunction?.length
                          : filteredOperationPendingJunction?.length || 0
                      }
                      size={"sm"}
                    />
                  </div>
                  <div className="flex flex-col w-full px-4 flex-1 overflow-hidden gap-y-4">
                    <div className="flex flex-col overflow-y-auto overflow-x-hidden gap-y-8 scrollbar-hide h-full w-full pt-2">
                      <JunctionListController
                        items={
                          selectedOperation === "all"
                            ? filteredPendingJunction
                            : filteredOperationPendingJunction
                        }
                        refetch={junctionData?.refetch}
                        navigate={navigate}
                        completed={true}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`flex flex-col flex-1 overflow-hidden ${
                    !false ? "w-1/3" : "w-1/2"
                  } border-t`}
                >
                  <div className="flex min-h-[38px] max-h-[38px] px-4 py-2 bg-[#F9FAFB] border-b border-[#EAECF0] w-full gap-x-2 items-center">
                    <span className="text-[#344054] text-sm font-semibold">
                      {t("product:completedJobs")}
                    </span>
                    <Badgets
                      colorType={"outline-gray"}
                      label={
                        selectedOperation === "all"
                          ? filteredPastJunction?.length
                          : filteredOperationPastJunction?.length || 0
                      }
                      size={"sm"}
                    />
                  </div>
                  <div className="flex flex-col overflow-y-auto overflow-x-hidden scrollbar-hide flex-1 gap-y-8 w-full px-4 pt-2">
                    <JunctionListController
                      items={
                        selectedOperation === "all"
                          ? filteredPastJunction
                          : filteredOperationPastJunction
                      }
                      refetch={junctionData?.refetch}
                      navigate={navigate}
                      completed={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <ExternalPlanView
              data={planView?.data}
              isLoading={planView?.isLoading || planView?.isFetching}
              refetch={planView?.refetch}
              selectedOperation={selectedOperation}
            />
          </>
        )}
      </>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            handleData={junctionData?.refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </div>
  );
};

export default ServiceSupply;

const JunctionListController = ({ items, refetch, navigate, completed }) => {
  return items?.map((item, index) => {
    const startDate = moment(item?.startDate);
    const endDate = moment(item?.endDate);
    return (
      <div className="min-h-[80px]">
        <NewFinishedJunctionCard
          completed={completed || false}
          qualityPercent={item?.metadata?.qualityPercent}
          allData={item}
          refetch={refetch}
          externalOperation={item?.externalOperation}
          supplier={item?.supplier}
          junctionId={item?.id}
          step={item?.header?.step}
          key={index}
          props={{
            startDate: startDate,
            ring:
              item.type === "work"
                ? "#17B26A"
                : item.type === "setup"
                ? "#EAAA08"
                : "#98A2B3",
            user: {
              image: item?.user?.avatar,
              name: item?.user?.name,
              lastName: item?.user?.lastName,
              id: item?.user?.id,
            },
            status: item?.metadata?.qualityStatus,
            product: {
              name: item?.job?.product?.name,
              code: item?.job?.orderNo,
              image: item?.job?.product?.image,
              pieceGreen: item?.madeWorkCount,
              pieceRed: item?.rejectedWorkCount,
            },
            node: {
              name:
                item?.station?.metadata?.brand +
                " " +
                item?.station?.metadata?.model,
              image: item?.station?.metadata?.image,
              process: item?.externalOperation?.name,
            },
            time: {
              timeRed: moment(endDate)?.format("HH:mm"),
              timeGreen: moment(startDate)?.format("HH:mm"),
            },
            onPressNode: (e) => {
              // e.stopPropagation();
              navigate(
                `/app/machine/digital-twin/equipment-details/${
                  item?.station?.deviceId
                }-${item?.station?.id}/${moment(item?.startDate).format(
                  "YYYY-MM-DD"
                )}`
              );
            },
            onPressProduct: (e) => {
              // e.stopPropagation();
              navigate(
                `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
              );
            },
          }}
        />
      </div>
    );
  });
};

const ActiveJunctionListController = ({
  items,
  navigate,
  refetch,
  setFinishJunctionId,
  setIsOpenJunctionPiece,
  setJunctionType,
}) => {
  return items?.map((item, index) => {
    const startDate = moment(item?.createdDate);
    return (
      <div className="min-h-[80px] cursor-pointer">
        <NewFinishedJunctionCard
          qualityPercent={item?.metadata?.qualityPercent}
          false={false}
          allData={item}
          externalOperation={item?.externalOperation}
          supplier={item?.supplier}
          pages={"activeAndPlanned"}
          refetch={refetch}
          statusType={item?.status}
          junctionId={item?.id}
          step={item?.header?.step}
          key={index}
          onClick={() => {
            setJunctionType(item?.externalOperation ? "external" : "internal");
            setFinishJunctionId(item?.id);
            setIsOpenJunctionPiece(true);
          }}
          props={{
            startDate: startDate,
            ring:
              item.type === "work"
                ? "#17B26A"
                : item.type === "setup"
                ? "#EAAA08"
                : "#98A2B3",
            user: {
              image: item?.user?.avatar,
              name: item?.user?.name,
              lastName: item?.user?.lastName,
              id: item?.user?.id,
            },
            status: item?.metadata?.qualityStatus,
            product: {
              name: item?.job?.product?.name,
              code: item?.job?.orderNo,
              image: item?.job?.product?.image,
              pieceGreen: item?.madeWorkCount,
              pieceRed: item?.rejectedWorkCount,
            },
            node: {
              name:
                item?.station?.metadata?.brand +
                " " +
                item?.station?.metadata?.model,
              image: item?.station?.metadata?.image,
              process: item?.internalOperation?.name,
            },
            time: {
              timeRed: "--",
              timeGreen: moment(startDate)?.format("HH:mm"),
            },
            // onPressNode: (e) => {
            //   // e.stopPropagation();
            //   navigate(
            //     `/app/machine/digital-twin/equipment-details/${
            //       item?.station?.deviceId
            //     }-${item?.station?.id}/${moment(item?.startDate).format(
            //       "YYYY-MM-DD"
            //     )}`
            //   );
            // },
            // onPressProduct: (e) => {
            //   // e.stopPropagation();
            //   navigate(
            //     `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
            //   );
            // },
          }}
        />
      </div>
    );
  });
};

const SwitchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83333 3.33337V16.6667M5.83333 16.6667L2.5 13.3334M5.83333 16.6667L9.16667 13.3334M14.1667 16.6667V3.33337M14.1667 3.33337L10.8333 6.66671M14.1667 3.33337L17.5 6.66671"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const TransformIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.3847 0.666504H3.61501C1.9866 0.666504 0.666504 1.9866 0.666504 3.61501V14.3847C0.666504 16.0131 1.9866 17.3332 3.61501 17.3332H14.3847C16.0131 17.3332 17.3332 16.0131 17.3332 14.3847V3.61501C17.3332 1.9866 16.0131 0.666504 14.3847 0.666504Z"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
      />
      <path
        d="M14.6114 9H3.38818"
        stroke="#475467"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M11.9482 6.05122C11.9482 6.05122 11.9101 5.78294 10.8063 4.6726C9.70241 3.56226 7.91269 3.56226 6.80883 4.6726C6.41773 5.066 6.16519 5.54599 6.05123 6.05122M11.9482 6.05122V4.15576M11.9482 6.05122H10.0638"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.05176 11.9488C6.05176 11.9488 6.08986 12.2171 7.19372 13.3274C8.29759 14.4377 10.0873 14.4377 11.1912 13.3274C11.5823 12.934 11.8348 12.454 11.9488 11.9488M6.05176 11.9488V13.8442M6.05176 11.9488H7.93617"
        stroke="#475467"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
