import React, { useState } from "react";
import Linkify from "react-linkify";

const TaskMessageSide = ({ text }) => {
  const [showFullText, setShowFullText] = useState(false);
  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };
  const renderText = () => {
    if (showFullText) {
      return text;
    }
    if (text.length > 230) {
      return text.slice(0, 230) + "...";
    }
    return text;
  };
  const renderButton = () => {
    if (text.length > 230) {
      return (
        <p
          className="text-xs inline font-medium text-blue-600 dark:text-[#15B79E] hover:underline select-none cursor-pointer ml-1"
          onClick={handleToggleText}
        >
          {!showFullText ? `Show more` : `Less`}
        </p>
      );
    }
    return null;
  };

  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="blank"
          href={decoratedHref}
          key={key}
          style={{ textDecorationLine: "underline" }}
          className="hover:text-[#1570EF]"
        >
          {decoratedText}
        </a>
      )}
    >
      <div className="w-full rounded-lg border shadow-sm border-gray-300 px-3 py-2 dark:border-[#525252] dark:bg-[#292929] overflow-hidden blur-sm">
        <p className="text-sm text-secondary-800 leading-5 dark:text-[#FAFAFA] ">
          {renderText()}
          {renderButton()}
          {/* {text} */}
        </p>
      </div>
    </Linkify>
  );
};

export default TaskMessageSide;
