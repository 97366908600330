import React, { useState } from "react";
import { Button, Input } from "../../../components";
import TextArea from "../../../components/inputs/TextArea";
import { useTranslation } from "react-i18next";
import { purchaseService } from "../../../services/purchase.service";
import { Toast } from "../../../utils/toastify/toast";

const AddSupplierGroupModal = ({ closeModal, refetch }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const handleSave = async () => {
    const body = { name, description };
    await purchaseService.createSupplierGroup(body).then((res) => {
      if (res.data.code == 0) {
        Toast("success", res?.data?.message);
      } else {
        Toast("error", res?.data?.message);
      }
      closeModal();
    });
  };
  return (
    <div className="w-[352px] flex flex-col gap-4 mt-4">
      <Input
        label={"Grup Adı"}
        value={name}
        onChange={({ target }) => setName(target?.value)}
      />
      <TextArea
        label={"Açıklama"}
        value={description}
        onChange={({ target }) => setDescription(target?.value)}
      />
      <div className="w-full h-11 flex gap-3">
        <Button
          label={t("buttons:cancel")}
          colorType={"secondary-gray"}
          size={"sm"}
          width={152}
          onClick={closeModal}
        />
        <Button
          label={"Grubu Kaydet"}
          colorType={"primary-product"}
          size={"sm"}
          width={200}
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default AddSupplierGroupModal;
