import { instance } from "../../libs/client";

export const LossesLoader = async () => {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "token"
  )}`;
  return instance.get("losses/monthly");
};

export const LossTypeLoader = async () => {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "token"
  )}`;
  return instance.get("losses");
};
