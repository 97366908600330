import React, { memo, useState } from "react";
import { Visible } from "../../assets/icons";
import moment from "moment";

const DateTimeInput = ({
  onChange,
  value,
  initialValue,
  setValue,
  label,
  disabled,
  validate,
  icon,
  size,
  iconPosition,
  type,
  onBlur,
  theme,
  placeholder,
  errorMessage,
  minDate,
  maxDate,
}) => {
  const [datetime, setDatetime] = useState(initialValue || "");
  const [isVisible, setIsVisible] = useState(false);
  const sizeStyles = {
    xs: "40px",
    sm: "44px",
  };
  const inputSize = sizeStyles[size] || "40px";
  const handleChange = (event) => {
    const { value } = event.target;
    setDatetime(value);
    onChange(formatDateTime(value));
  };
  const formatDateTime = (datetime) => {
    const formattedDateTime = moment(datetime).format("YYYY-MM-DDTHH:mm:ss");
    return formattedDateTime;
  };
  return (
    <div className={`flex flex-col relative w-full`}>
      <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 dark:text-darkTrue-200 font-medium">
        {label}
      </span>
      <div
        style={{ height: inputSize }}
        className={` ${
          size === "sm" ? "px-[14px] py-[10px]" : "px-2 py-0.5"
        }  ${
          theme === "person"
            ? "focus-within:border-teal-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#C4EDE6] "
            : theme === "product"
            ? "focus-within:border-warning-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#FDE3C1] "
            : theme === "embedded"
            ? "focus-within:border-grayBlue-300 focus-within:outline focus-within:outline-4 focus-within:outline-[#D2D6E8] "
            : "focus-within:border-primary-300 focus-within:outline  focus-within:outline-4 focus-within:outline-[#E8DFFB] "
        } flex w-full items-center border focus-within:ring-opacity-50 transition duration-300 rounded-lg dark:border-darkTrue-600 dark:bg-[#0F0F0F]
        ${
          validate === "success"
            ? "border-validate-success"
            : validate === "warning"
            ? "border-validate-warning"
            : validate === "error"
            ? "border-validate-errorBorder"
            : "border-secondary-300"
        } text-secondary-500 text-base
          ${disabled ? "bg-secondary-50  mx-0" : "bg-white"} `}
      >
        {icon ? (
          type !== "password" ? (
            <span
              className={`absolute  ${
                iconPosition === "right" ? "right-[14px]" : "left-[14px] mr-5"
              }  text-secondary-500 max-w-[20px] max-h-[20px]`}
            >
              {icon}
            </span>
          ) : (
            <></>
          )
        ) : type == "password" ? (
          <span
            className={`absolute right-[14px]  max-w-[20px] max-h-[20px] ${
              validate === "success"
                ? "text-validate-success"
                : validate === "warning"
                ? "text-validate-warning"
                : validate === "error"
                ? "text-validate-error"
                : "text-secondary-500"
            }`}
            onClick={() => setIsVisible(!isVisible)}
          >
            <img src={Visible} />
          </span>
        ) : (
          <></>
        )}

        <input
          type="datetime-local"
          value={datetime}
          min={minDate}
          max={maxDate}
          disabled={disabled}
          step={"any"}
          onChange={handleChange}
          style={{ height: inputSize }}
          className={`h-full border-transparent text-base font-normal  outline-none ${
            placeholder
              ? "text-secondary-900"
              : disabled
              ? "text-secondary-500"
              : "text-secondary-900"
          } dark:bg-[#0F0F0F] dark:text-darkTrue-50 focus:border-transparent focus:ring-0 border-none rounded-lg w-full px-0 ${
            type !== "password" && icon
              ? iconPosition === "right"
                ? "mr-5"
                : "mx-5"
              : "pl-0"
          } ${disabled ? "bg-secondary-50 dark:bg-[#0F0F0F] " : <></>} `}
        />
      </div>
      {validate ? (
        <p
          className={` ${
            validate === "success" ? (
              "text-validate-success"
            ) : validate === "warning" ? (
              "text-validate-warning"
            ) : validate === "error" ? (
              "text-validate-error"
            ) : validate === "tooltip" ? (
              "text-secondary-500 text-sm leading-5"
            ) : (
              <></>
            )
          } text-sm mt-1.5 font-normal `}
        >
          {errorMessage || validate.toLocaleUpperCase()}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default memo(DateTimeInput);
