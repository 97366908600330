import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components";
import QuantityModal from "./QuantityModal";
import { CheckIcon } from "../../../assets/icons/stepsIcons";
import { observer } from "mobx-react-lite";

const colors = [
  localStorage.color_theme === "dark" ? "#9E77ED" : "#9E77ED",
  localStorage.color_theme === "dark" ? "#F79009" : "#F04438",
  localStorage.color_theme === "dark" ? "#15B79E" : "#DC6803",
  localStorage.color_theme === "dark" ? "#FFFF5D" : "#0E9384",
  localStorage.color_theme === "dark" ? "#2E90FA" : "#12B76A",
  localStorage.color_theme === "dark" ? "#8CF3AF" : "#EAAA08",
  localStorage.color_theme === "dark" ? "#F79009" : "#4E5BA6",
  localStorage.color_theme === "dark" ? "#667085" : "#008999",
];

const WorkCountCard = observer(({ workCount }) => {
  const { t } = useTranslation();
  const contentWrapper = useRef(null);
  const [isOpenWorkCountModal, setIsOpenWorkCountModal] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const sorted = workCount?.sort(
    (a, b) => {
      if (a.count !== b.count) {
        return b.count - a.count;
      }
    },
    [workCount]
  );

  const totalSelectedWorkCount = () => {
    let totalCount = 0;
    for (const index in checkboxStates) {
      if (checkboxStates[index]) {
        totalCount += workCount[index]?.count || 0;
      }
    }
    return totalCount;
  };

  const toggleCheckbox = (index) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useState(() => {
    const initialCheckboxStates = {};
    workCount?.forEach((_, index) => {
      initialCheckboxStates[index] = true;
    });
    setCheckboxStates(initialCheckboxStates);
  }, []);

  return (
    <>
      <div className="w-full flex min-h-[52px] h-[52px] mb-4  justify-start items-center border dark:border-[#525252] dark:bg-[#292929] border-secondary-300 rounded-md pointer mt-4">
        <div className="flex whitespace-nowrap border-r h-full pl-3 pr-5 py-[2px] gap-x-1 items-center text-[#475467]">
          <QuantityIcon />
          <div className="flex gap-x-[2px] items-center">
            <span className="text-[#344054] font-semibold text-base">
              {totalSelectedWorkCount()}
            </span>
            <span className="text-[#667085] font-normal text-[10px] leading-4 mt-[2px]">
              {t("product:totalpcs")}
            </span>
          </div>
        </div>
        <div
          ref={contentWrapper}
          className="flex flex-row p-1 pl-2 items-center overflow-x-auto scrollbar-hide gap-x-3"
        >
          {sorted?.map((item, index) => {
            const isChecked = checkboxStates[index] || false;
            return (
              <div
                onDoubleClick={(e) => {
                  setIsOpenWorkCountModal(true);
                }}
                onClick={(e) => {
                  toggleCheckbox(index);
                }}
                key={index}
                style={{
                  backgroundColor: isChecked
                    ? `${colors[index % colors.length]}`
                    : "#F2F4F7",
                  color: isChecked
                    ? `${colors[index % colors.length]}`
                    : "#98A2B3",
                }}
                className={`w-fit max-h-[48px] flex items-center py-0.5 px-2 rounded-lg gap-x-2 cursor-pointer border transition duration-300 ease-in-out ${
                  isChecked
                    ? `border-[${colors[index % colors.length]}]`
                    : "border-[#EAECF0]"
                }`}
              >
                <div
                  className={`flex min-w-[16px] max-w-[16px] bg-white min-h-[16px] max-h-[16px] rounded p-[2px] items-center justify-center border transition duration-300 ease-in-out ${
                    isChecked
                      ? `border-[${colors[index % colors.length]}]`
                      : "border-[#D0D5DD]"
                  }`}
                >
                  {isChecked ? (
                    <CheckIcon color={`${colors[index % colors.length]}`} />
                  ) : null}
                </div>
                <div
                  style={{
                    backgroundColor: `${
                      localStorage.color_theme === "dark"
                        ? "#F4EBFF"
                        : "#FFFFFF"
                    }`,
                  }}
                  className="h-5 w-5 flex items-center justify-center font-semibold text-[10px] rounded-full p-2"
                >
                  {item?.workQty ? item?.workQty : 0}
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-start gap-x-2">
                    <span
                      className={`text-xs font-normal ${
                        isChecked ? "text-[#FAFAFA]" : "text-[#98A2B3]"
                      }`}
                    >
                      <QuantityIcon />
                    </span>
                    <span
                      className={`text-sm font-semibold whitespace-nowrap ${
                        isChecked
                          ? "text-[#FAFAFA] dark:text-[#FFFFFF]"
                          : "text-[#98A2B3]"
                      }`}
                    >
                      {item?.count ? item?.count : 0} {t("product:pcs")}
                    </span>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-x-2">
                    <span
                      className={`text-xs font-normal ${
                        isChecked ? "text-[#FAFAFA]" : "text-[#98A2B3]"
                      }`}
                    >
                      <ClockIcon />
                    </span>
                    <span
                      className={` text-sm font-semibold whitespace-nowrap ${
                        isChecked
                          ? "text-[#FAFAFA] dark:text-[#FFFFFF]"
                          : "text-[#98A2B3]"
                      }`}
                    >
                      {item?.maxRepeatValue ? item?.maxRepeatValue : 0} ±{" "}
                      {item?.workTolerance ? item?.workTolerance : 0}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CustomModal
        isOpen={isOpenWorkCountModal}
        setIsOpen={setIsOpenWorkCountModal}
        modalTitle={t("consoleModal:Qty")}
        children={
          <QuantityModal
            t={t}
            workCount={workCount}
            onClose={() => setIsOpenWorkCountModal(false)}
            // groupDetails={groupDetails}
            // handleLabel={handleLabel}
          />
        }
      />
    </>
  );
});
export default WorkCountCard;

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_11689_58725)">
        <path
          d="M7.99998 3.99992V7.99992L10.6666 9.33325M14.6666 7.99992C14.6666 11.6818 11.6819 14.6666 7.99998 14.6666C4.31808 14.6666 1.33331 11.6818 1.33331 7.99992C1.33331 4.31802 4.31808 1.33325 7.99998 1.33325C11.6819 1.33325 14.6666 4.31802 14.6666 7.99992Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11689_58725">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const QuantityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.375H3.71429C2.76751 2.375 2 3.14251 2 4.08929V11.9107C2 12.8575 2.76751 13.625 3.71429 13.625H12.2857C13.2325 13.625 14 12.8575 14 11.9107V4.08929C14 3.14251 13.2325 2.375 12.2857 2.375H10.25M5.75 2.375V6.73631C5.75 6.80003 5.81706 6.84147 5.87405 6.81298L8 5.75L10.126 6.81298C10.1829 6.84147 10.25 6.80003 10.25 6.73631V2.375M5.75 2.375H10.25"
        stroke="currentColor"
        strokeWidth="1.33333"
      />
    </svg>
  );
};
