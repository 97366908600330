import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { Button } from "./components";
import { ArrowLeftIcon } from "./assets/icons/machineIcons";
import { Illustration } from "./assets/illustrations";
import { useTranslation } from "react-i18next";

const Error = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center sm:hidden xs:hidden">
        <div className="w-[40%] lg:w-[45%] flex justify-center flex-col max-w-[560px]">
          <h1 className="text-[60px] lg:text-[52px] md:text-4xl font-semibold text-secondary-900">
            {t("global:pageNotLoad")}
          </h1>
          <p className="text-xl lg:text-lg md:text-base text-secondary-500 mt-4">
            {t("global:sorryMsg")}
          </p>
          <div className="flex items-center mt-12 gap-4">
            <Button
              width={130}
              colorType={"secondary-gray"}
              label={t("global:goBack")}
              iconLeft={<ArrowLeftIcon />}
              size={"sm"}
              onClick={() => navigate(-1)}
            />
            <Button
              width={152}
              colorType={"primary-machine"}
              label={t("global:toHome")}
              size={"sm"}
              onClick={() => navigate("/app/machine/digital-twin")}
            />
          </div>
        </div>
        <div className="w-[40%] flex items-center justify-center ml-8">
          <div className="relative flex-col items-center flex">
            <Illustration className="md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px]" />
            <svg
              className="animate-spin absolute bottom-[75px] lg:bottom-[112px] md:w-12 md:h-12 md:bottom-[80px]"
              xmlns="http://www.w3.org/2000/svg"
              width="57"
              height="57"
              viewBox="0 0 57 57"
              fill="none"
            >
              <path
                d="M28.5463 3.09131V13.2731M28.5463 43.8186V54.0004M13.2736 28.5459H3.0918M54.0009 28.5459H43.8191M46.5642 46.5637L39.3645 39.364M46.5642 10.7275L39.3645 17.9272M10.5285 46.5637L17.7282 39.364M10.5285 10.7275L17.7282 17.9272"
                stroke="white"
                strokeWidth="4.36364"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="hidden xs:flex sm:flex flex-col p-6 items-center w-screen h-screen justify-center">
        <h1 className="text-3xl font-semibold text-secondary-900">
          {t("global:pageNotLoad")}
        </h1>
        <p className="text-base text-secondary-500 mt-4 text-center px-5">
          {t("global:sorryMsg")}
        </p>
        <div className="relative flex-col items-center flex">
          <Illustration className="w-[230px] h-[230px]" />
          <svg
            className="animate-spin absolute bottom-[65px] w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
          >
            <path
              d="M28.5463 3.09131V13.2731M28.5463 43.8186V54.0004M13.2736 28.5459H3.0918M54.0009 28.5459H43.8191M46.5642 46.5637L39.3645 39.364M46.5642 10.7275L39.3645 17.9272M10.5285 46.5637L17.7282 39.364M10.5285 10.7275L17.7282 17.9272"
              stroke="white"
              strokeWidth="4.36364"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="flex w-full items-center mt-7 gap-4">
          <Button
            width={"48%"}
            colorType={"secondary-gray"}
            label={t("global:goBack")}
            iconLeft={<ArrowLeftIcon />}
            size={"sm"}
            onClick={() => navigate(-1)}
          />
          <Button
            width={"48%"}
            colorType={"primary-machine"}
            label={t("global:toHome")}
            size={"sm"}
            onClick={() => navigate("/app/machine/digital-twin")}
          />
        </div>
      </div>
    </>
  );
};

export default Error;
