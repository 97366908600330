import React from "react";

const CustomSwich = ({
  isActive,
  setIsActive,
  color,
  secondaryColor,
  onClick = () => {},
}) => {
  return (
    <div
      onClick={() => {
        setIsActive(!isActive);
        onClick();
      }}
      style={{ backgroundColor: color || "#3E4784" }}
      className="w-[44px] h-[24px] rounded-full flex items-center px-0.5 relative"
    >
      <div
        style={{ backgroundColor: secondaryColor || "#fff" }}
        className={`w-[20px] h-[20px] absolute rounded-full cursor-pointer ${
          isActive ? "right-0.5" : "left-0.5"
        } `}
      />
    </div>
  );
};

export default CustomSwich;
