import React from "react";

export default function MapEditorEquCard({
  machineAvatar,
  equipmentModal,
  process,
  onClick,
  isMap,
}) {
  return (
    <button
      disabled={isMap}
      onClick={!isMap && onClick}
      type="button"
      className="bg-white border border-[#EAECF0] shadow-xs rounded-lg flex items-center gap-3 py-[10px] pl-3 pr-1 w-full hover:bg-gray-50"
    >
      <img
        className="w-[60px] h-[45px] min-w-[60px] min-h-[45px] rounded border-[1px] border-secondary-300 dark:border-[#424242] border-solid"
        src={machineAvatar}
        alt="image"
        style={{
          opacity: isMap ? 0.3 : 1,
        }}
      />
      <div
        style={{
          opacity: isMap ? 0.3 : 1,
        }}
        className="text-[14px] flex flex-col"
      >
        <p className="text-[#667085] font-semibold text-left">
          {equipmentModal}
        </p>
        <p className="text-[#667085] font-normal text-left">{process}</p>
      </div>
    </button>
  );
}
