export const QuantityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V5.02098C4.3125 5.06877 4.36279 5.09985 4.40554 5.07848L6 4.28125L7.59446 5.07848C7.63721 5.09985 7.6875 5.06877 7.6875 5.02098V1.75M4.3125 1.75H7.6875"
        stroke="#667085"
      />
    </svg>
  );
};
export const NewClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_1602_4331)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1602_4331">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 5.5V19.5M5 12.5H19"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const QuantityAcceptedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V4.02098C4.3125 4.06877 4.36279 4.09985 4.40554 4.07848L6 3.28125L7.59446 4.07848C7.63721 4.09985 7.6875 4.06877 7.6875 4.02098V1.75M4.3125 1.75H7.6875"
        stroke="#079455"
        strokeWidth="0.8"
      />
      <path
        d="M4.5 7.09615L5.57692 8.17308L8 5.75"
        stroke="#079455"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClockStart = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="clock" clip-path="url(#clip0_1602_4331)">
        <path
          id="Icon"
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1602_4331">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const QuantityDeclinedIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="quantity declined">
        <path
          id="Vector 295"
          d="M4.3125 1.75H2.78571C2.07563 1.75 1.5 2.32563 1.5 3.03571V8.90179C1.5 9.61187 2.07563 10.1875 2.78571 10.1875H9.21429C9.92437 10.1875 10.5 9.61187 10.5 8.90179V3.03571C10.5 2.32563 9.92437 1.75 9.21429 1.75H7.6875M4.3125 1.75V4.02098C4.3125 4.06877 4.36279 4.09985 4.40554 4.07848L6 3.28125L7.59446 4.07848C7.63721 4.09985 7.6875 4.06877 7.6875 4.02098V1.75M4.3125 1.75H7.6875"
          stroke="#D92D20"
          strokeWidth="0.8"
        />
        <path
          id="Icon"
          d="M7.25 5.75L4.75 8.25M4.75 5.75L7.25 8.25"
          stroke="#D92D20"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const ClockEnd = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 45142">
        <g id="Group 45135">
          <path
            id="Icon"
            d="M5 3.25V5.25L3.75 6"
            stroke="#D92D20"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2751 4.95751C1.2751 2.92372 2.92381 1.27501 4.9576 1.27501C6.99139 1.27501 8.6401 2.92372 8.6401 4.95751C8.6401 4.97169 8.64002 4.98586 8.63986 5.00001H9.40007C9.41356 5.00001 9.42684 5.00001 9.4399 5.00001C9.44003 4.98586 9.4401 4.97169 9.4401 4.95751C9.4401 2.48189 7.43321 0.475006 4.9576 0.475006C2.48198 0.475006 0.475098 2.48189 0.475098 4.95751C0.475098 7.43312 2.48198 9.44001 4.9576 9.44001C5.41899 9.44001 5.86411 9.37029 6.28298 9.24083C6.24354 9.19526 6.2095 9.14483 6.18182 9.0905C6.11113 8.95176 6.10157 8.77594 6.10028 8.45928C5.74055 8.57659 5.35648 8.64001 4.9576 8.64001C2.92381 8.64001 1.2751 6.99129 1.2751 4.95751Z"
          fill="#D92D20"
        />
        <path
          id="Icon_2"
          d="M7 6.8C7 6.51997 7 6.37996 7.0545 6.273C7.10243 6.17892 7.17892 6.10243 7.273 6.0545C7.37996 6 7.51997 6 7.8 6H9.2C9.48003 6 9.62004 6 9.727 6.0545C9.82108 6.10243 9.89757 6.17892 9.9455 6.273C10 6.37996 10 6.51997 10 6.8V8.2C10 8.48003 10 8.62004 9.9455 8.727C9.89757 8.82108 9.82108 8.89757 9.727 8.9455C9.62004 9 9.48003 9 9.2 9H7.8C7.51997 9 7.37996 9 7.273 8.9455C7.17892 8.89757 7.10243 8.82108 7.0545 8.727C7 8.62004 7 8.48003 7 8.2V6.8Z"
          stroke="#D92D20"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
