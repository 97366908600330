import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomModal, Loading } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import { junctionService } from "../../../services/junction.service";
import { ScrollSyncPane } from "react-scroll-sync";
import JunctionCardType from "../../../components/junction/JunctionCardType";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DroppedJunctionPlan from "./DroppedJunctionPlan";
import { toast } from "react-toastify";
import SelectUserModal from "../../../components/modal/SelectUserModal";
import FinishJunctionModal from "../../../components/modal/FinishJunctionModal";
import { useTranslation } from "react-i18next";
import { ScrollChevronIcon } from "../../../assets/icons/productIcons";
import { Tooltip } from "react-tooltip";
import SearchInput from "../../../components/inputs/SearchInput";
import diacritics from "diacritics";
import Badgets from "../../../components/buttons/Badgets";
import Junction from "../../../components/junction/Junction";
import SelectProductModal from "../../../components/modal/SelectProductModal";
import { Toast } from "../../../utils/toastify/toast";
import NewJunctionCard from "../../../components/junction/NewJunctionCard";
import { manipulate } from "../../../utils/manipulate";

const InternalPlanView = ({ data, isLoading, refetch, selectedOperation }) => {
  const { t } = useTranslation();
  const [rowLoading, setRowLoading] = useState(null);
  const [arr, setArr] = useState([]);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [isOpenSelectProductModal, setIsOpenSelectProductModal] =
    useState(false);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [drappedEquipment, setDrappedEquipment] = useState(null);
  const [tableHeadItemsLength, settableHeadItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const handleHeaderLength = () => {
    const head = data?.equipments?.map((d) => {
      return d?.plannedJunction?.concat(d?.activeJunction)?.length;
    });
    settableHeadItemsLength(Math?.max(...head));
  };
  const handleDrop = (node) => {
    setDrappedEquipment(node);
    setIsOpenPlanningModal(true);
  };
  const tableHeadItems = [];
  for (let i = 0; i < Math.max(2, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i}`, id: i });
  }
  const onDragEnd = async (result) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  useEffect(() => {
    if (data) {
      setArr(data?.equipments);
      handleHeaderLength();
    }
  }, [data]);
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd =
      container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const [isLeftArrowHidden2, setIsLeftArrowHidden2] = useState(true);
  const [isRightArrowHidden2, setIsRightArrowHidden2] = useState(false);
  const handleScroll2 = () => {
    const container = document.getElementById("content-wrapper2");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd =
      container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden2(isAtBeginning);
    setIsRightArrowHidden2(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper2");
    container?.addEventListener("scroll", handleScroll2);
    return () => {
      container?.removeEventListener("scroll", handleScroll2);
    };
  }, [handleScroll2]);
  const filteredData = data?.orderList?.filter((item) =>
    diacritics
      .remove(item?.internalOperation?.product?.name)
      .toLowerCase()
      .includes(diacritics.remove(searchQuery).toLowerCase())
  );
  const groupedData = {};
  filteredData?.forEach((item) => {
    const productName = item?.internalOperation?.product?.name;
    if (!groupedData[productName]) {
      groupedData[productName] = [];
    }
    groupedData[productName].push(item);
  });
  const orderedGroupKeys = Object.keys(groupedData).sort();
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full items-center justify-center flex">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <>
          <div className="flex border flex-col rounded-xl">
            <div className="flex w-full px-6 py-3 items-center border-b mb-2">
              <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                <p className="flex text-[#101828] font-semibold text-lg">
                  {selectedOperation?.name
                    ? manipulate(selectedOperation?.id) // ? selectedOperation?.name?.slice(0, 3) + " ***"
                    : t("product:products")?.slice(0, 2) + " ***"}
                </p>
                <Badgets
                  colorType={"outline-warning"}
                  label={filteredData?.length}
                />
              </div>
              <div className="flex max-w-[320px] w-full justify-end">
                <SearchInput
                  expandable={true}
                  theme={"product"}
                  setSearchVal={setSearchQuery}
                />
              </div>
            </div>
            <div className="flex w-full px-4 pb-3">
              {data?.orderList?.length > 0 && (
                <div className="w-full h-[88px] flex relative">
                  <div
                    className={`w-11 h-full ${
                      isRightArrowHidden2 ? "hidden duration-1000" : ""
                    } z-50 right-0 backdrop-blur-md cursor-pointer flex absolute items-center justify-center group bg-[#FFFFFF33]`}
                    onClick={() => {
                      sideScroll(
                        document.getElementById("content-wrapper2"),
                        55,
                        300,
                        50
                      );
                    }}
                  >
                    <ScrollChevronIcon />
                  </div>
                  <div
                    className={`w-11 h-full ${
                      isLeftArrowHidden2 ? "hidden duration-1000" : ""
                    } z-50 left-0 absolute cursor-pointer flex bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
                    onClick={() => {
                      sideScroll(
                        document.getElementById("content-wrapper2"),
                        55,
                        300,
                        -20
                      );
                    }}
                  >
                    <ScrollChevronIcon />
                  </div>
                  <div
                    id="content-wrapper2"
                    className="w-full  overflow-x-auto flex pb-20 overflow-y-hidden"
                  >
                    {orderedGroupKeys.map((productName) => {
                      const items = groupedData[productName];
                      return (
                        <div className="flex" key={productName}>
                          {items.map((item) => {
                            const statusColor = () => {
                              switch (item?.status) {
                                case "finished":
                                  return "#12B76A";
                                case "started":
                                  return "#FDE172";
                                case "rejected":
                                  return "#D92D20";
                                default:
                                  return "#D0D5DD";
                              }
                            };
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    navigate(
                                      `/app/product/order/detail/${item?.id}+${item?.orderData?.id}`
                                    );
                                  }}
                                  data-tooltip-id={`${item?.id}`}
                                  data-tooltip-content={`${t(
                                    "product:productName"
                                  )}: ${
                                    item?.internalOperation?.product?.name.slice(0,3)+"***"
                                  } - ${t("product:orderNo")}: ${
                                    item?.orderData?.orderNo.slice(0,3)+"***"
                                  }`}
                                  data-tooltip-place="right"
                                  key={item?.id}
                                  draggable
                                  onDragStart={() => setDraggedProduct(item)}
                                  style={{ borderTopColor: statusColor() }}
                                  className="w-[284px] min-w-[284px] mr-3 h-[72px] min-h-[72px] rounded-lg  border border-l-gray-300 border-b-gray-300 border-r-gray-300 border-t-4 shadow-sm p-2 flex items-center bg-white cursor-grab "
                                >
                                  <img
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(
                                        `/app/product/order/detail/${item?.id}+${item?.orderData?.id}`
                                      );
                                    }}
                                    className="w-[72px] h-[54px] blur-sm rounded border border-gray-300 select-none"
                                    src={generateFileUrl(
                                      item?.internalOperation?.product?.image
                                    )}
                                  />
                                  <div className="flex flex-col ml-2.5 flex-1 overflow-hidden">
                                    <p
                                      title={
                                        item?.internalOperation?.product?.name
                                      }
                                      className="text-sm font-semibold text-secondary-900 truncate"
                                    >
                                      {item?.internalOperation?.product?.name
                                        ? item?.internalOperation?.product?.name?.slice(
                                            0,
                                            3
                                          ) + " ***"
                                        : "Product Name"}
                                    </p>
                                    <p
                                      title={item?.orderData?.orderNo}
                                      className="text-xs text-gray-600 truncate"
                                    >
                                      {t("product:orderNo")}:{" "}
                                      {item?.orderData?.orderNo
                                        ? item?.orderData?.orderNo?.slice(
                                            0,
                                            2
                                          ) + " ***"
                                        : "---"}
                                    </p>
                                    <div className="w-full overflow-hidden flex items-center justify-between">
                                      <div
                                        title={moment(
                                          item?.orderData?.deliveryDate
                                        ).format("DD.MM.YY")}
                                        className="flex items-center gap-0.5 w-[42%] overflow-hidden"
                                      >
                                        <CalendarIcon />
                                        <p className="text-xs text-gray-600 truncate">
                                          {moment(
                                            item?.orderData?.deliveryDate
                                          ).format("DD.MM.YY")}
                                        </p>
                                      </div>
                                      <div
                                        title={item?.orderData?.piece}
                                        className="flex items-center gap-0.5 w-[35%] justify-center overflow-hidden"
                                      >
                                        <QtyLight />
                                        <p className="text-xs text-gray-600 truncate">
                                          {item?.orderData?.piece}
                                        </p>
                                      </div>
                                      <div className="flex items-center gap-0.5 overflow-hidden w-[28%]">
                                        <div className="h-[22px] flex items-center px-1.5 border rounded-md border-[#FEDF89] bg-[#FFFAEB] ml-auto">
                                          <p className="text-xs font-medium text-[#B54708]">
                                            {item?.internalOperation?.step}
                                            .op
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item?.id && <Tooltip id={`${item?.id}`} />}
                              </>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex-1 w-full mt-4 border rounded-xl border-gray-300 flex flex-col overflow-hidden relative">
            <div
              className={`w-11 h-full ${
                isRightArrowHidden ? "hidden duration-1000" : ""
              } z-[120] right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
              onClick={() => {
                sideScroll(
                  document.getElementById("content-wrapper"),
                  55,
                  300,
                  50
                );
              }}
            >
              <ScrollChevronIcon />
            </div>

            <div
              className={`w-11 h-full ${
                isLeftArrowHidden ? "hidden duration-1000" : ""
              } z-[120] left-0 absolute cursor-pointer flex bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
              onClick={() => {
                sideScroll(
                  document.getElementById("content-wrapper"),
                  55,
                  300,
                  -20
                );
              }}
            >
              <ScrollChevronIcon />
            </div>
            <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
              <ScrollSyncPane>
                <div className="flex items-center w-full h-full overflow-x-auto scrollbar-hide">
                  {tableHeadItems.map((item, index) => (
                    <div
                      key={index}
                      className={`min-w-[335px] min-h-[44px] ${
                        !isLeftArrowHidden && index === 0
                          ? "sticky left-11 z-50"
                          : ""
                      } flex items-center  justify-center text-sm bg-[#F9FAFB] font-semibold text-[#344054] border-r border-secondary-200`}
                    >
                      {index === 0 ? (
                        <>
                          <p className="text-sm text-[#344054] font-semibold">
                            {t("product:activeAndPlannedName")}
                          </p>
                        </>
                      ) : (
                        <>{item?.title}</>
                      )}
                    </div>
                  ))}
                </div>
              </ScrollSyncPane>
            </div>

            <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide bg-white">
              {arr?.map((a, b) => {
                let filledData = [
                  ...(a?.activeJunction || []),
                  ...(a.plannedJunction?.sort(
                    (a, b) => a?.planningOrder - b?.planningOrder
                  ) || []),
                  ...Array.from(
                    {
                      length: Math.max(
                        tableHeadItemsLength -
                          a?.plannedJunction?.concat(a?.activeJunction)?.length,
                        0
                      ),
                    },
                    () => null
                  ),
                ];
                return (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`${b}`} direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => handleDrop(a)}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="flex w-full min-h-[96px]"
                        >
                          <ScrollSyncPane>
                            <div
                              id="content-wrapper"
                              className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200 max-h-[96px] m-0 p-0 overflow-y-visible"
                            >
                              {filledData?.map((item, index) => {
                                if (item) {
                                  if (item?.status === "active") {
                                    return (
                                      <div
                                        className={`min-w-[335px] ${
                                          isLeftArrowHidden
                                            ? ""
                                            : "sticky left-11"
                                        }  max-w-[335px] z-50 flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white cursor-pointer`}
                                      >
                                        <div className="cursor-pointer w-full">
                                          <NewJunctionCard
                                            junctionId={item?.id}
                                            step={item?.header?.step}
                                            key={index}
                                            refetch={refetch}
                                            onPressNode={() => {
                                              navigate(
                                                `/app/machine/digital-twin/equipment-details/${
                                                  item?.station?.deviceId
                                                }-${item?.station?.id}/${moment(
                                                  item?.startDate
                                                ).format("YYYY-MM-DD")}`
                                              );
                                            }}
                                            onPressFinish={(e) => {
                                              setIsOpenFinishJunction(true);
                                              setSelectedJunction(item);
                                            }}
                                            onPressProduct={(e) => {
                                              navigate(
                                                `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                              );
                                            }}
                                            onPressOperation={() => {
                                              navigate(
                                                `/app/machine/digital-twin/operation-detail/${
                                                  item?.internalOperation
                                                    ? item?.internalOperation
                                                        ?.id
                                                    : item?.externalOperation
                                                        ?.id
                                                }/${moment(
                                                  item?.startDate
                                                ).format("YYYY-MM-DD")}`
                                              );
                                            }}
                                            props={{
                                              startDate: item?.startDate,
                                              // page: "activeWork",
                                              status: item?.status,
                                              time: moment(
                                                item?.metadata?.startDate
                                              ).format("HH:mm"),
                                              ring:
                                                item.type === "work"
                                                  ? "#17B26A"
                                                  : item.type === "setup"
                                                  ? "#EAAA08"
                                                  : "#98A2B3",
                                              user: {
                                                image: item?.user?.avatar,
                                                name: item?.user?.name,
                                                lastName: item?.user?.lastName,
                                                id: item?.user?.id,
                                              },
                                              product: {
                                                name: item?.job?.product?.name,
                                                code: item?.job?.orderNo,
                                                image:
                                                  item?.job?.product?.image,
                                                piece: item?.targetedWorkCount,
                                              },
                                              node: {
                                                name:
                                                  item?.station?.metadata
                                                    ?.brand &&
                                                  item?.station?.metadata?.model
                                                    ? item?.station?.metadata
                                                        ?.brand +
                                                      " " +
                                                      item?.station?.metadata
                                                        ?.model
                                                    : item?.supplier
                                                        ?.contactName,
                                                image:
                                                  item?.station?.metadata
                                                    ?.image ||
                                                  item?.supplier?.image,
                                                process:
                                                  item?.internalOperation?.name,
                                              },
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    if (b === rowLoading) {
                                      return (
                                        <div className="min-w-[335px] max-w-[335px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                          <div className="w-full h-full rounded-lg bg-gray-200 animate-pulse" />
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <Draggable
                                          key={item?.id}
                                          draggableId={item?.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className={`${
                                                !isLeftArrowHidden &&
                                                index === 0
                                                  ? "sticky left-11 z-50"
                                                  : ""
                                              }`}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div
                                                className={`min-w-[335px] max-w-[335px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white`}
                                              >
                                                {item?.status === "planned" ? (
                                                  <>
                                                    {index === 0 ? (
                                                      <div className="relative w-full group">
                                                        <NewJunctionCard
                                                          step={
                                                            item?.header?.step
                                                          }
                                                          refetch={refetch}
                                                          junctionId={item?.id}
                                                          key={index}
                                                          onPressNode={() => {
                                                            navigate(
                                                              `/app/machine/digital-twin/equipment-details/${
                                                                item?.station
                                                                  ?.deviceId
                                                              }-${
                                                                item?.station
                                                                  ?.id
                                                              }/${moment(
                                                                item?.startDate
                                                                  ? item.startDate
                                                                  : new Date()
                                                              ).format(
                                                                "YYYY-MM-DD"
                                                              )}`
                                                            );
                                                          }}
                                                          onPressFinish={(
                                                            e
                                                          ) => {
                                                            setIsOpenFinishJunction(
                                                              true
                                                            );
                                                            setSelectedJunction(
                                                              item
                                                            );
                                                          }}
                                                          onPressProduct={(
                                                            e
                                                          ) => {
                                                            navigate(
                                                              `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                                            );
                                                          }}
                                                          onPressOperation={() => {
                                                            navigate(
                                                              `/app/machine/digital-twin/operation-detail/${
                                                                item?.internalOperation
                                                                  ? item
                                                                      ?.internalOperation
                                                                      ?.id
                                                                  : item
                                                                      ?.externalOperation
                                                                      ?.id
                                                              }/${moment(
                                                                item?.startDate
                                                                  ? item.startDate
                                                                  : new Date()
                                                              ).format(
                                                                "YYYY-MM-DD"
                                                              )}`
                                                            );
                                                          }}
                                                          props={{
                                                            onPressUser: () => {
                                                             
                                                            
                                                            },
                                                            startDate:
                                                              item?.startDate,
                                                            status:
                                                              item?.status,
                                                            time: moment(
                                                              item?.metadata
                                                                ?.startDate
                                                            ).format("HH:mm"),
                                                            ring: "#98A2B3",
                                                            product: {
                                                              name: item?.job
                                                                ?.product?.name,
                                                              code: item?.job
                                                                ?.orderNo,
                                                              image:
                                                                item?.job
                                                                  ?.product
                                                                  ?.image,
                                                              piece:
                                                                item?.targetedWorkCount,
                                                            },
                                                            node: {
                                                              name:
                                                                item?.station
                                                                  ?.metadata
                                                                  ?.brand &&
                                                                item?.station
                                                                  ?.metadata
                                                                  ?.model
                                                                  ? item
                                                                      ?.station
                                                                      ?.metadata
                                                                      ?.brand +
                                                                    " " +
                                                                    item
                                                                      ?.station
                                                                      ?.metadata
                                                                      ?.model
                                                                  : item
                                                                      ?.supplier
                                                                      ?.contactName,
                                                              image:
                                                                item?.station
                                                                  ?.metadata
                                                                  ?.image ||
                                                                item?.supplier
                                                                  ?.image,
                                                              process:
                                                                item
                                                                  ?.internalOperation
                                                                  ?.name,
                                                            },
                                                          }}
                                                        />
                                                        {/* <button
                                                          onClick={async () => {
                                                            await junctionService
                                                              .deleteStationForJunction(
                                                                item?.id
                                                              )
                                                              .then((res) => {
                                                                if (
                                                                  res.data
                                                                    .code == 0
                                                                ) {
                                                                  Toast(
                                                                    "success",
                                                                    "Junction deleted"
                                                                  );
                                                                  refetch();
                                                                } else {
                                                                  Toast(
                                                                    "error",
                                                                    "Junction not deleted"
                                                                  );
                                                                }
                                                              });
                                                          }}
                                                          type="button"
                                                          className="w-9 h-9 rounded-lg bg-white border border-[#FDA29B] hidden group-hover:flex items-center justify-center absolute -top-1 -right-2"
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
                                                              stroke="#B42318"
                                                              strokeWidth="1.66667"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </button> */}
                                                      </div>
                                                    ) : (
                                                      <div className="relative w-full group">
                                                        <>
                                                          <div
                                                            onClick={() => {
                                                              navigate(
                                                                `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                                              );
                                                            }}
                                                            data-tooltip-id={`${item?.id}`}
                                                            data-tooltip-content={`${t(
                                                              "product:productName"
                                                            )}: ${
                                                              item?.job?.product
                                                                ?.name.slice(0,3)+"***"
                                                            } - ${t(
                                                              "product:orderNo"
                                                            )}: ${
                                                              item?.job?.orderNo.slice(0,3)+"***"
                                                            }`}
                                                            data-tooltip-place="right"
                                                            key={item?.id}
                                                            className="w-[310px] min-w-[310px] mr-3 h-[72px] min-h-[72px] rounded-lg  border border-l-gray-300 border-b-gray-300 border-r-gray-300 border-t-4 shadow-sm p-2 flex items-center bg-white cursor-grab "
                                                          >
                                                            <img
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                navigate(
                                                                  `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                                                );
                                                              }}
                                                              className="w-[72px] blur-sm h-[54px] rounded border border-gray-300 select-none"
                                                              src={generateFileUrl(
                                                                item?.job
                                                                  ?.product
                                                                  ?.image
                                                              )}
                                                            />
                                                            <div className="flex bgred flex-col ml-2.5 flex-1 overflow-hidden">
                                                              <p
                                                                title={
                                                                  item?.job
                                                                    ?.product
                                                                    ?.name
                                                                }
                                                                className="text-sm font-semibold text-secondary-900 truncate"
                                                              >
                                                                {item?.job
                                                                  ?.product
                                                                  ?.name
                                                                  ? item?.job?.product?.name?.slice(
                                                                      0,
                                                                      3
                                                                    ) + " ***"
                                                                  : "Product Name"}
                                                              </p>
                                                              <p
                                                                title={
                                                                  item?.job
                                                                    ?.orderNo
                                                                }
                                                                className="text-xs text-gray-600 truncate"
                                                              >
                                                                {t(
                                                                  "product:orderNo"
                                                                )}
                                                                :{" "}
                                                                {item?.job
                                                                  ?.orderNo
                                                                  ? item?.job?.orderNo?.slice(
                                                                      0,
                                                                      2
                                                                    ) + " ***"
                                                                  : "---"}
                                                              </p>
                                                              <div className="w-full overflow-hidden flex items-center justify-between">
                                                                <div
                                                                  title={moment(
                                                                    item?.job
                                                                      ?.deliveryDate
                                                                  ).format(
                                                                    "DD.MM.YY"
                                                                  )}
                                                                  className="flex items-center gap-0.5 w-[42%] overflow-hidden"
                                                                >
                                                                  <CalendarIcon />
                                                                  <p className="text-xs text-gray-600 truncate">
                                                                    {moment(
                                                                      item?.job
                                                                        ?.deliveryDate
                                                                    ).format(
                                                                      "DD.MM.YY"
                                                                    )}
                                                                  </p>
                                                                </div>
                                                                <div
                                                                  title={
                                                                    item?.job
                                                                      ?.piece
                                                                  }
                                                                  className="flex items-center gap-0.5 w-[35%] justify-center overflow-hidden"
                                                                >
                                                                  <QtyLight />
                                                                  <p className="text-xs text-gray-600 truncate">
                                                                    {
                                                                      item
                                                                        ?.header
                                                                        ?.step
                                                                    }
                                                                  </p>
                                                                </div>
                                                                <div className="flex items-center gap-0.5 overflow-hidden w-[28%]">
                                                                  <div className="h-[22px] flex items-center px-1.5 border rounded-md border-[#FEDF89] bg-[#FFFAEB] ml-auto">
                                                                    <p className="text-xs font-medium text-[#B54708]">
                                                                      {
                                                                        item
                                                                          ?.header
                                                                          ?.step
                                                                      }
                                                                      .op
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {item?.id && (
                                                            <Tooltip
                                                              className="z-[50]"
                                                              id={`${item?.id}`}
                                                            />
                                                          )}
                                                        </>
                                                        <button
                                                          // onClick={async () => {
                                                          //   await junctionService
                                                          //     .deleteStationForJunction(
                                                          //       item?.id
                                                          //     )
                                                          //     .then((res) => {
                                                          //       if (
                                                          //         res.data
                                                          //           .code == 0
                                                          //       ) {
                                                          //         toast.success(
                                                          //           "Junction deleted"
                                                          //         );
                                                          //         refetch();
                                                          //       } else {
                                                          //         toast.error(
                                                          //           "Junction not deleted"
                                                          //         );
                                                          //       }
                                                          //     });
                                                          // }}
                                                          onClick={() =>
                                                            Toast(
                                                              "warning",
                                                              t("alert:warning")
                                                            )
                                                          }
                                                          type="button"
                                                          className="w-9 h-9 rounded-lg bg-white border border-[#FDA29B] hidden group-hover:flex items-center justify-center absolute -top-1 -right-2"
                                                        >
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
                                                              stroke="#B42318"
                                                              strokeWidth="1.66667"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                            />
                                                          </svg>
                                                        </button>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <JunctionCardType
                                                    id={item?.id}
                                                    step={item?.header?.step}
                                                    props={{
                                                      ring: "#17B26A",
                                                      leftImage:
                                                        item?.job?.product
                                                          ?.image,
                                                      texts: {
                                                        first:
                                                          item?.job?.orderNo,
                                                        second:
                                                          item?.job?.product
                                                            ?.name,
                                                        a: item?.header?.piece,
                                                        b:
                                                          String(
                                                            Math.floor(
                                                              item?.header
                                                                ?.totalEstimatedTime /
                                                                3600
                                                            )
                                                          ) + " h",
                                                      },
                                                      user: {
                                                        name: item?.user?.name,
                                                        lastName:
                                                          item?.user?.lastName,
                                                        avatar:
                                                          item?.user?.avatar ||
                                                          "empty",
                                                      },
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                  }
                                } else {
                                  return (
                                    <>
                                      {index === 0 ? (
                                        <div
                                          className={`min-w-[335px] ${
                                            !isLeftArrowHidden && index === 0
                                              ? "sticky left-11 z-50"
                                              : ""
                                          } max-w-[335px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white`}
                                        >
                                          <NewJunctionCard
                                            step={item?.header?.step}
                                            key={index}
                                            refetch={refetch}
                                            junctionId={item?.id}
                                            props={{
                                              page: "plannedIndex",
                                              onPressUser: () => {
                                               
                                              },
                                              onPressProduct: () => {
                                                setIsOpenSelectProductModal(
                                                  true
                                                );
                                                setDrappedEquipment(a);
                                              },
                                              product: {
                                                name: "----",
                                                code: "---",
                                              },
                                              status: item?.status,
                                              ring: "#98A2B3",
                                              node: {
                                                name:
                                                  a?.metadata?.brand +
                                                  " " +
                                                  a?.metadata?.model,
                                                image: a?.metadata?.image,
                                                process:
                                                  a?.metadata?.brand +
                                                  " " +
                                                  a?.metadata?.model,
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div className="min-w-[335px] max-w-[335px]" />
                                      )}
                                    </>
                                  );
                                }
                              })}
                              {filledData?.length < 1 && (
                                <div className="min-w-[335px] max-w-[335px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                  <Junction
                                    props={{
                                      onPressUser: () => {
                                       
                                      },
                                      onPressProduct: () => {
                                       
                                      },
                                      product: {
                                        name: "----",
                                        code: "---",
                                      },
                                      ring: "#98A2B3",
                                      node: {
                                        name:
                                          a?.metadata?.brand +
                                          " " +
                                          a?.metadata?.model,
                                        image: a?.metadata?.image,
                                        process:
                                          a?.metadata?.brand +
                                          " " +
                                          a?.metadata?.model,
                                      },
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </ScrollSyncPane>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                );
              })}
            </div>
          </div>
        </>
      )}
      {draggedProduct && (
        <DroppedJunctionPlan
          nodeData={drappedEquipment}
          orderData={draggedProduct}
          isOpen={isOpenPlanningModal}
          setIsOpen={setIsOpenPlanningModal}
          refetch={refetch}
          processId={data?.id}
        />
      )}
      <CustomModal
        isOpen={isOpenSelectUserModal}
        setIsOpen={setIsOpenSelectUserModal}
        onClose={() => {
          setIsOpenSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsOpenSelectUserModal}
            isOpen={isOpenSelectUserModal}
            selectedJunction={selectedJunction}
            handleData={refetch}
          />
        }
      />
      <CustomModal
        isOpen={isOpenSelectProductModal}
        setIsOpen={setIsOpenSelectProductModal}
        onClose={() => {
          setIsOpenSelectProductModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectProductModal
            setIsOpenSelectProductModal={setIsOpenSelectProductModal}
            filteredData={filteredData}
            setIsOpenPlanningModal={setIsOpenPlanningModal}
            setDraggedProduct={setDraggedProduct}
          />
        }
      />
      <CustomModal
        isOpen={isOpenFinishJunction}
        setIsOpen={setIsOpenFinishJunction}
        modalTitle={t("product:finishJuction")}
        children={
          <FinishJunctionModal
            handleData={refetch}
            modalTheme={"product"}
            junctionId={selectedJunction?.id}
            closeModal={() => setIsOpenFinishJunction(false)}
            type={selectedJunction?.internalOperation ? "internal" : "external"}
          />
        }
      />
    </>
  );
};

export default InternalPlanView;
const QtyLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.3335H3.71429C2.76751 2.3335 2 3.10101 2 4.04778V11.8692C2 12.816 2.76751 13.5835 3.71429 13.5835H12.2857C13.2325 13.5835 14 12.816 14 11.8692V4.04778C14 3.10101 13.2325 2.3335 12.2857 2.3335H10.25M5.75 2.3335V6.69481C5.75 6.75853 5.81706 6.79997 5.87405 6.77147L8 5.7085L10.126 6.77147C10.1829 6.79997 10.25 6.75853 10.25 6.69481V2.3335M5.75 2.3335H10.25"
        stroke="#475467"
      />
    </svg>
  );
};
const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
