import "./App.css";
import React, { useEffect, lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import { PusherProvider } from "@harelpls/use-pusher";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import { useStore } from "./hooks/useStores";
import DigitalTwin from "./pages/machine/DigitalTwin";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import Shift from "./pages/machine/Shift";
import AuthNavigation from "./auth/AuthNavigation";
import NodeList from "./pages/machine/NodeList";
import Settings from "./pages/Settings";
import PersonnelList from "./pages/person/PersonnelList";
import { StepNavigation } from "./steps";
import { Login } from "./pages/login";
import PersonLayout from "./pages/person/PersonLayout";
import Task from "./pages/person/pages/Task";
import Message from "./pages/person/pages/Message";
import Chat from "./pages/person/pages/Chat";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  AttendIcon,
  EnergyIcon,
  ExternalOperationIcon,
  InternalOperationIcon,
  LeftMenuCCountIcon,
  LossIcon,
  MessageIcon,
  NodeListIcon,
  NotifyIcon,
  OrderIcon,
  PointIcon,
  PollIcon,
  PurchaseIcon,
  QualityIcon,
  ShiftIcon,
  StockIcon,
  TaskIcon,
  TwinIcon,
} from "./assets/icons/leftMenuIcons";
import { LossTypeLoader } from "./utils/loaders/machine.loader";
import SettingProfile from "./pages/settings/SettingProfile";
import SettingsCompany from "./pages/settings/SettingsCompany";
import SettingKPI from "./pages/settings/SettingKPI";
import PrivateRoute from "./auth/PrivateRoute.js";
import TwinNavigation from "./pages/machine/TwinNavigation.js";
import MapEditor from "./pages/machine/MapEditor.js";
import EquipmentDetail from "./pages/machine/EquipmentDetail.js";
import OperationDetail from "./pages/machine/Twin/OperationDetail.js";
import { PersonnelsIcon } from "./assets/icons/stepHeaderIcons.js";
import Notify from "./pages/person/pages/Notify.js";
import Poll from "./pages/person/pages/Poll.js";
import PollViewer from "./pages/person/pages/PollViewer.js";
import Error from "./Error.js";
import SettingDepartment from "./pages/settings/SettingDepartment.js";
import SettingInternalOperation from "./pages/settings/SettingInternalOperation.js";
import SettingShift from "./pages/settings/SettingShift.js";
import NotifyViewer from "./pages/person/pages/NotifyViewer.js";
import EmbeddedServer from "./pages/machine/EmbeddedServer.js";
import Attend from "./pages/person/pages/Attend.js";
import UpuPoint from "./pages/person/pages/UpuPoint.js";
import NotFound from "./pages/errors/NotFound.js";
import { observer } from "mobx-react-lite";
import SettingExternal from "./pages/settings/SettingExternal.js";
import CompanyDetail from "./pages/machine/CompanyDetail.js";
import NewPassword from "./pages/login/NewPassword.js";
import ProductOperationPage from "./pages/product/components/ProductOperationPage.js";
import InternalOperation from "./pages/product/components/InternalOperation.js";
import ExternalOperation from "./pages/product/components/ExternalOperation.js";
import ProductExternalOperationPage from "./pages/product/components/ProductExternalOperationPage.js";
import VerySoonProduct from "./pages/product/VerySoonProduct.js";
import Quality from "./pages/product/Quality.js";
import Purchase from "./pages/product/Purchase.js";
import QualityExternal from "./pages/product/QualityExternal.js";
import RawMaterialList from "./pages/product/RawMaterialList.js";
import PersonelDetail from "./pages/machine/Twin/PersonelDetail.js";
import SupplierList from "./pages/product/SupplierList.js";
import Supply from "./pages/product/Supply.js";
import ServiceSupply from "./pages/product/ServiceSupply.js";
import PurchaseDetail from "./pages/product/PurchaseDetail.js";
import NewCreateRawMaterial from "./pages/product/NewCreateRawMaterial.js";
import Montaj from "./pages/product/Montaj.js";
import Montaj2 from "./pages/product/Montaj2.js";
import Montaj2Tree from "./pages/product/Montaj2Tree.js";
import Montaj3Tree from "./pages/product/Montaj3Tree.js";
import Montaj4Tree from "./pages/product/Montaj4Tree.js";
import { SupplyIcon } from "./assets/icons/productIcons.js";
import QualitySupply from "./pages/product/QualitySupply";
import MontageTest from "./pages/product/MontageTest.js";
import MaterialSupply from "./pages/product/MaterialSupply.js";
import Stock from "./pages/product/Stock";
import QualityDashboard from "./pages/product/quality/pages/QualityDashboard.js";
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});
const LazyLanguage = lazy(() => import("./steps/language/Language"));
const LazyCompany = lazy(() => import("./steps/company/Company"));
const LazyDepartment = lazy(() => import("./steps/department/Department"));
const LazyUsers = lazy(() => import("./steps/users/Users"));
const LazyProcess = lazy(() => import("./steps/process/Process"));
const LazyExternal = lazy(() => import("./steps/external/External"));
const LazyEquipment = lazy(() => import("./steps/equipments/Equipments"));
const LazyShift = lazy(() => import("./steps/shifts/Shifts"));
const LazyEndOfStep = lazy(() => import("./steps/end-of-steps/End-of-Steps"));
const LazyCCount = lazy(() => import("./pages/machine/C-Count"));
const LazyEnergy = lazy(() => import("./pages/machine/Energy"));
const LazyLoss = lazy(() => import("./pages/machine/loss/Losses"));
const LazyGraphicalView = lazy(() =>
  import("./pages/machine/loss/LossGraphicView")
);
const LazyListView = lazy(() => import("./pages/machine/loss/LossListView"));
const LazyLossTable = lazy(() => import("./pages/machine/loss/LossTable"));
const LazyStockDetails = lazy(() => import("./pages/product/StockDetails.js"));
const LazyStockEdit = lazy(() => import("./pages/product/StockEdit.js"));
const LazyStock = lazy(() => import("./pages/product/Stock"));
const LazyProductOperationDetails = lazy(() =>
  import("./pages/product/ProductOperationDetail.js")
);
const LazyMoveJunctionDetail = lazy(() =>
  import("./pages/product/components/MoveJunctionDetail.js")
);
const LazyOrderEditClone = lazy(() =>
  import("./pages/product/OrderEditClone.js")
);
const LazyOrder = lazy(() => import("./pages/product/Order"));

const Loading = () => {
  return <div />;
};

export const screens = [
  {
    path: "/auth",
    content: false,
    element: AuthNavigation,
    isPrivate: false,
    subRoutes: [
      {
        path: "login",
        element: Login,
      },
    ],
  },
  {
    path: "change-password",
    roles: [
      "ADMIN",
      "SHIFT_RESPONSIBLE",
      "OPERATOR",
      "QUALITY_CONTROL_RESPONSIBLE",
      "OPERATION_RESPONSIBLE",
      "QUALITY_CONTROL_OPERATOR",
      "PURCHASING_RESPONSIBLE",
      "PURCHASING_OPERATOR",
    ],
    element: NewPassword,
    isPrivate: false,
    subRoutes: [],
  },
  {
    path: "/step",
    roles: ["ADMIN"],
    content: false,
    isPrivate: true,
    element: StepNavigation,
    subRoutes: [
      {
        path: "language",
        roles: ["ADMIN"],
        initialRouteName: "language",
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyLanguage />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "company",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyCompany />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "department",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyDepartment />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "users",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyUsers />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "process",
        roles: ["ADMIN"],
        isPrivate: true,
        loadingElement: () => <p>loading</p>,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyProcess />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "external-operation",
        roles: ["ADMIN"],
        isPrivate: true,
        loadingElement: () => <p>loading</p>,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyExternal />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "equipment",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyEquipment />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "shift",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyShift />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "end-of-step",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyEndOfStep />
          </React.Suspense>
        ),
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "/app",
    element: Layout,
    content: true,
    isPrivate: true,
    subRoutes: [
      // machine
      {
        path: "machine/digital-twin",
        roles: ["ADMIN", "OPERATION_RESPONSIBLE"],
        isPrivate: true,
        element: TwinNavigation,
        isVisible: true,
        icon: TwinIcon,
        package: "machine",
        name: "digital twin",
        subRoutes: [
          {
            path: "",
            roles: ["ADMIN"],
            element: DigitalTwin,
            isPrivate: true,
            package: "machine",
            isVisible: false,
          },
          {
            path: "map-editor",
            roles: ["ADMIN"],
            element: MapEditor,
            isPrivate: true,
            package: "machine",
            isVisible: true,
          },

          // {
          //   path: "timeline2",
          //   roles: ["ADMIN"],
          //   element: TimelineTest2,
          //   isPrivate: true,
          //   package: "machine",
          //   isVisible: false,
          // },

          // {
          //   path: "timeline3",
          //   roles: ["ADMIN"],
          //   element: Timeliness,
          //   isPrivate: true,
          //   package: "machine",
          //   isVisible: false,
          // },

          //testttt
          {
            path: "equipment-details/:deviceId/:dates/*",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE"],
            element: EquipmentDetail,
            package: "machine",
            name: "equipment details",
            isVisible: false,
            isPrivate: true,
          },

          {
            path: "user-detail/:userId/:dates/*",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE"],
            element: PersonelDetail,
            package: "machine",
            isVisible: true,
            isPrivate: true,
          },
          {
            path: "operation-detail/:operationId/:dates/*",
            roles: ["ADMIN", "OPERATION_RESPONSIBLE"],
            element: OperationDetail,
            package: "machine",
            isVisible: true,
            isPrivate: true,
          },
          {
            path: "embedded-server/:nodeId",
            roles: ["ADMIN"],
            element: EmbeddedServer,
            package: "machine",
            isVisible: false,
            isPrivate: true,
          },
          {
            path: "company-details",
            roles: ["ADMIN"],
            element: CompanyDetail,
            package: "machine",
            isVisible: false,
            isPrivate: true,
          },
        ],
      },
      {
        path: "machine/loss",
        roles: ["ADMIN"],
        icon: LossIcon,
        package: "machine",
        isPrivate: true,
        isVisible: true,
        name: "loss",
        element: () => {
          return (
            <React.Suspense fallback={<Loading />}>
              <LazyLoss />
            </React.Suspense>
          );
        },
        subRoutes: [
          {
            path: "",
            roles: ["ADMIN"],
            element: LazyGraphicalView,
            isPrivate: true,
          },
          {
            path: "list-view",
            roles: ["ADMIN"],
            element: LazyListView,
            isPrivate: true,
          },
          {
            path: "loss-table",
            roles: ["ADMIN"],
            loader: LossTypeLoader,
            isPrivate: true,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyLossTable />
              </React.Suspense>
            ),
            package: "machine",
          },
        ],
      },
      {
        path: "machine/energy",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyEnergy />
          </React.Suspense>
        ),
        icon: EnergyIcon,
        package: "machine",
        isVisible: true,
        name: "energy",
      },

      {
        path: "machine/c-count",
        roles: ["ADMIN"],
        isPrivate: true,
        element: () => (
          <React.Suspense fallback={<Loading />}>
            <LazyCCount />
          </React.Suspense>
        ),
        icon: LeftMenuCCountIcon,
        package: "machine",
        isVisible: true,
        name: "c-count",
        isPrivate: true,
      },
      {
        path: "machine/shift",
        roles: ["ADMIN"],
        isPrivate: true,
        element: Shift,
        icon: ShiftIcon,
        package: "machine",
        isVisible: true,
        name: "shift",
      },
      {
        path: "machine/node-list",
        roles: ["ADMIN"],
        element: NodeList,
        isPrivate: true,
        name: "node List",
        icon: NodeListIcon,
        package: "machine",
        isVisible: true,
      },
      {
        path: "product/verysoon",
        element: VerySoonProduct,
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: false,
        name: "order",
      },

      {
        path: "product/montaj",
        element: MontageTest,
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: false,
        name: "order",
      },

      {
        path: "product/montaj2",
        element: Montaj2,
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: false,
        name: "order",
      },

      {
        path: "product/montaj3",
        element: Montaj2Tree,
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: false,
        name: "order",
      },

      {
        path: "product/montaj4",
        element: Montaj3Tree,
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: false,
        name: "order",
      },
      {
        path: "product/montaj5",
        element: Montaj4Tree,
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: false,
        name: "order",
      },
      {
        path: "product/order",
        roles: ["ADMIN"],
        element: () => (
          <div className="w-full h-full">
            <Outlet />
          </div>
        ),
        icon: OrderIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "order",
        subRoutes: [
          {
            path: ":pageNo?/detail/:operationId/*",
            roles: ["ADMIN"],
            isPrivate: true,
            // element: OperationDetailModal,
            // element: ProductOperationDetail,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyProductOperationDetails />
              </React.Suspense>
            ),
            isVisible: false,
            name: "Stock Detail",
          },
          {
            path: ":pageNo?/detail/:operationId/move-junction",
            roles: ["ADMIN"],
            isPrivate: true,
            // element: MoveJunctionDetail,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyMoveJunctionDetail />
              </React.Suspense>
            ),
            isVisible: false,
            name: "Move Junction",
          },
          {
            path: ":pageNo?/edit/:orderId",
            roles: ["ADMIN"],
            isPrivate: true,
            // element: OrderEditClone,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyOrderEditClone />
              </React.Suspense>
            ),
            isVisible: false,
            name: "Order Copy",
          },
          {
            path: ":pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            // element: Order,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyOrder />
              </React.Suspense>
            ),
            isVisible: false,
            name: "Stock",
          },
        ],
      },
      {
        path: "product/stock",
        roles: ["ADMIN"],
        element: () => (
          <div className="w-full h-full">
            <Outlet />
          </div>
        ),
        icon: StockIcon,
        package: "product",
        isPrivate: true,
        isVisible: true,
        name: "stock",
        isSubMenu: true,
        subRoutes: [
          {
            path: ":pageNo?/detail/:stockId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyStockDetails />
              </React.Suspense>
            ),
            isVisible: false,
            name: "Stock Detail",
          },
          {
            path: ":pageNo?/:type/:stockId",
            roles: ["ADMIN"],
            isPrivate: true,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyStockEdit />
              </React.Suspense>
            ),
            isVisible: false,
            name: "Stock Edit",
          },
          {
            path: ":pageNo?",
            roles: ["ADMIN"],
            isPrivate: true,
            element: () => (
              <React.Suspense fallback={<Loading />}>
                <LazyStock />
              </React.Suspense>
            ),
            isVisible: false,
            name: "Stock",
          },
          {
            path: ":pageNo?/create-raw",
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewCreateRawMaterial,
            isVisible: false,
            name: "Create Raw",
          },
          {
            path: "",
            icon: StockIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: Stock,
            isVisible: false,
            name: "Stock",
            isSubItem: true,
          },
          {
            path: "raw-material/list",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: RawMaterialList,
            isVisible: false,
            name: "Raw Material List",
            isSubItem: true,
          },
          {
            path: "raw-material/create",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewCreateRawMaterial,
            isVisible: false,
            name: "Raw Material List",
          },
          {
            path: "raw-material/edit/:itemId",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: NewCreateRawMaterial,
            isVisible: false,
            name: "Raw Material List",
          },
        ],
      },
      {
        path: "product/quality-control",
        roles: ["ADMIN"],
        element: () => {
          return (
            <div className="w-full h-full">
              <Outlet />
            </div>
          );
        },
        icon: QualityIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "quality",
        isSubMenu: true,
        subRoutes: [
          {
            path: "dashboard",
            roles: ["ADMIN"],
            isPrivate: true,
            element: QualityDashboard,
            isVisible: false,
            name: "dashboard",
            isSubItem: false,
          },
          {
            path: "internal",
            roles: ["ADMIN"],
            isPrivate: true,
            element: Quality,
            isVisible: false,
            name: "operation",
            isSubItem: true,
          },
          {
            path: "external",
            roles: ["ADMIN"],
            isPrivate: true,
            element: QualityExternal,
            isVisible: false,
            name: "service",
            isSubItem: true,
          },
          {
            path: "supply",
            roles: ["ADMIN"],
            isPrivate: true,
            element: QualitySupply,
            isVisible: false,
            name: "material",
            isSubItem: true,
          },
        ],
      },
      // {
      //   path: "product/internal-operation",
      //   roles: ["ADMIN", "OPERATION_RESPONSIBLE"],
      //   element: () => (
      //     <div className="w-full h-full">
      //       <Outlet />
      //     </div>
      //   ),
      //   icon: InternalOperationIcon,
      //   isPrivate: true,
      //   package: "product",
      //   isVisible: true,
      //   name: "internal operation",
      //   subRoutes: [
      //     {
      //       path: "detail/:operationId",
      //       roles: ["ADMIN"],
      //       isPrivate: true,
      //       element: ProductOperationPage,
      //       isVisible: false,
      //       name: "Operation Detail",
      //     },
      //     {
      //       path: "",
      //       roles: ["ADMIN", "OPERATION_RESPONSIBLE"],
      //       isPrivate: true,
      //       element: InternalOperation,
      //       isVisible: false,
      //       name: "Operation",
      //     },
      //   ],
      // },
      // {
      //   path: "product/external-operation",
      //   roles: ["ADMIN", "OPERATION_RESPONSIBLE"],
      //   element: () => (
      //     <div className="w-full h-full">
      //       <Outlet />
      //     </div>
      //   ),
      //   icon: ExternalOperationIcon,
      //   isPrivate: true,
      //   package: "product",
      //   isVisible: true,
      //   name: "external operation",
      //   subRoutes: [
      //     {
      //       path: "detail/:operationId",
      //       roles: ["ADMIN"],
      //       isPrivate: true,
      //       element: ProductExternalOperationPage,
      //       isVisible: false,
      //       name: "Operation Detail",
      //     },
      //     {
      //       path: "",
      //       roles: ["ADMIN"],
      //       isPrivate: true,
      //       element: ExternalOperation,
      //       isVisible: false,
      //       name: "Operation",
      //     },
      //   ],
      // },

      // {
      //   path: "product/purchase",
      //   roles: ["ADMIN"],
      //   element: () => {
      //     return (
      //       <div className="w-full h-full">
      //         <Outlet />
      //       </div>
      //     );
      //   },
      //   icon: PurchaseIcon,
      //   isPrivate: true,
      //   package: "product",
      //   isVisible: true,
      //   name: "purchase",
      //   subRoutes: [
      //     {
      //       path: "",
      //       roles: ["ADMIN"],
      //       isPrivate: true,
      //       element: Purchase,
      //       isVisible: false,
      //       name: "Purchase Main",
      //     },
      //     {
      //       path: "raw-materials",
      //       roles: ["ADMIN"],
      //       isPrivate: true,
      //       element: RawMaterialList,
      //       isVisible: false,
      //       name: "Raw Material",
      //     },

      //     {
      //       path: "supplier",
      //       roles: ["ADMIN"],
      //       isPrivate: true,
      //       element: SupplierList,
      //       isVisible: false,
      //       name: "Supplier",
      //     },
      //   ],
      // },
      {
        path: "product/supply",
        roles: ["ADMIN"],
        element: () => {
          return (
            <div className="w-full h-full">
              <Outlet />
            </div>
          );
        },
        icon: SupplyIcon,
        isPrivate: true,
        package: "product",
        isVisible: true,
        name: "supply",
        isSubMenu: true,
        subRoutes: [
          {
            path: "material",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: MaterialSupply,
            isVisible: false,
            name: "material supply",
            isSubItem: true,
          },
          {
            path: "service",
            icon: PurchaseIcon,
            roles: ["ADMIN"],
            isPrivate: true,
            element: ServiceSupply,
            isVisible: false,
            name: "service supply",
            isSubItem: true,
          },
          {
            path: ":type/list",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SupplierList,
            isVisible: false,
            name: "Raw Material",
          },
          {
            path: "material/details/:id",
            roles: ["ADMIN"],
            isPrivate: true,
            element: PurchaseDetail,
            isVisible: false,
            name: "Details",
          },
        ],
      },
      {
        roles: ["ADMIN"],
        path: "settings",
        element: Settings,
        package: "product",
        isVisible: false,
        isPrivate: true,
        name: "Settings",
        subRoutes: [
          {
            path: "profile",
            isPrivate: true,
            element: SettingProfile,
            isVisible: true,
            name: "Profile",
          },
          {
            path: "company",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingsCompany,
            isVisible: true,
            name: "Company",
          },
          {
            path: "department",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingDepartment,
            isVisible: true,
            name: "Department",
          },
          {
            path: "internal-operation",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingInternalOperation,
            isVisible: true,
            name: "Internal Operation",
          },
          {
            path: "external-operation",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingExternal,
            isVisible: true,
            name: "External Operation",
          },
          {
            path: "shift",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingShift,
            isVisible: true,
            name: "Shift Management",
          },

          {
            path: "kpi",
            roles: ["ADMIN"],
            isPrivate: true,
            element: SettingKPI,
            isVisible: true,
            name: "KPI",
          },
        ],
      },
      {
        path: "person",
        roles: [
          "ADMIN",
          "SHIFT_RESPONSIBLE",
          "OPERATOR",
          "QUALITY_CONTROL_RESPONSIBLE",
          "OPERATION_RESPONSIBLE",
          "QUALITY_CONTROL_OPERATOR",
          "PURCHASING_RESPONSIBLE",
          "PURCHASING_OPERATOR",
        ],
        element: PersonLayout,
        package: "person",
        isVisible: true,
        icon: () => <></>,
        name: "Person Layout",
        subRoutes: [
          {
            path: "message",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: Message,
            icon: MessageIcon,
            isPrivate: true,
            package: "person",
            isVisible: true,
            name: "message",
            subIdRoutes: [
              {
                path: ":conversationId",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: Chat,
                package: "person",
                isVisible: false,
                isPrivate: true,
              },
            ],
          },
          {
            path: "task",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: Task,
            isPrivate: true,
            subIdRoutes: [
              {
                path: ":conversationId",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: Chat,
                package: "person",
                isVisible: false,
              },
            ],
            icon: TaskIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "task",
          },
          {
            path: "notify",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: () => <Notify />,
            icon: NotifyIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "notify",
            subIdRoutes: [
              {
                path: ":id",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: NotifyViewer,
                package: "person",
                isVisible: "",
              },
            ],
          },
          {
            path: "poll",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: () => <Poll />,
            icon: PollIcon,
            isPrivate: true,
            package: "person",
            isVisible: true,
            name: "poll",
            subIdRoutes: [
              {
                path: ":id",
                roles: [
                  "ADMIN",
                  "SHIFT_RESPONSIBLE",
                  "OPERATOR",
                  "QUALITY_CONTROL_RESPONSIBLE",
                  "OPERATION_RESPONSIBLE",
                  "QUALITY_CONTROL_OPERATOR",
                  "PURCHASING_RESPONSIBLE",
                  "PURCHASING_OPERATOR",
                ],
                element: PollViewer,
                package: "person",
                isVisible: false,
              },
            ],
          },

          {
            path: "attend",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: Attend,
            icon: AttendIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "attend",
          },
          {
            path: "point",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: UpuPoint,
            icon: PointIcon,
            package: "person",
            isVisible: true,
            isPrivate: true,
            name: "point",
          },
          {
            path: "person/personnel-list",
            roles: [
              "ADMIN",
              "SHIFT_RESPONSIBLE",
              "OPERATOR",
              "QUALITY_CONTROL_RESPONSIBLE",
              "OPERATION_RESPONSIBLE",
              "QUALITY_CONTROL_OPERATOR",
              "PURCHASING_RESPONSIBLE",
              "PURCHASING_OPERATOR",
            ],
            element: PersonnelList,
            icon: PersonnelsIcon,
            package: "person",
            isPrivate: true,
            isVisible: true,
            name: "user list",
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: NotFound,
  },
];

const App = observer(() => {
  const { auth } = useStore();
  const pubnub = new PubNub({
    publishKey: "pub-c-12c05759-f8bf-4dfb-b709-f7236dff04eb",
    subscribeKey: "sub-c-53a32eb2-a8c4-47a3-84df-4cc6369ee509",
    uuid: auth?.user?.id || "test",
  });

  useEffect(() => {
    document.documentElement.classList.remove("dark");
    const handleWheel = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };
    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);
  const pusherConfig = {
    clientKey: "3fd6b03f01edee2945a0",
    cluster: "eu",
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      screens.map((item, index) => {
        return (
          <Route
            key={index}
            path={String(item.path)}
            element={
              item.isPrivate ? (
                <PrivateRoute>
                  <item.element />
                </PrivateRoute>
              ) : (
                <item.element />
              )
            }
            errorElement={<Error />}
          >
            {item?.subRoutes?.map((sub, ind) => {
              return (
                <Route
                  key={ind}
                  path={String(sub.path)}
                  element={<sub.element />}
                  loader={sub?.loader || null}
                >
                  {sub?.subRoutes?.map((a, b) => {
                    return (
                      <Route
                        key={b}
                        path={String(a.path)}
                        element={<a.element />}
                        loader={a?.loader || null}
                      >
                        {a?.subIdRoutes?.map((s, i) => {
                          return (
                            <Route
                              index
                              path={String(s.path)}
                              element={<s.element />}
                              key={i}
                            ></Route>
                          );
                        })}
                      </Route>
                    );
                  })}
                </Route>
              );
            })}
          </Route>
        );
      })
    )
  );

  return (
    <PusherProvider {...pusherConfig}>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <QueryClientProvider client={queryClient}>
        <PubNubProvider client={pubnub}>
          <RouterProvider router={router} />
        </PubNubProvider>
      </QueryClientProvider>
    </PusherProvider>
  );
});
export default App;
