import React, { useState } from "react";
import Badgets from "../../../../components/buttons/Badgets";
import JunctionCardType from "../../../../components/junction/JunctionCardType";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../../components";
import SendErrorMessageModal from "../../components/SendErrorMessageModal";
import moment from "moment";
import LossesCardType from "../../../../components/junction/LossesCardType";
import { Toast } from "../../../../utils/toastify/toast";

const EquipmentLosses = ({ lossesData, date, refetch }) => {
  const { t } = useTranslation();
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [selectedLoss, setSelectedLoss] = useState();
  const isNotToday = moment(date).isSame(moment(), "day");

  return (
    <div className="w-full h-[74px] px-4 flex items-center my-3">
      <div className="flex flex-col gap-1 w-[120px] min-w-[120px] max-w-[120px]">
        <div className="max-w-[90px] flex h-[22px]">
          <Badgets
            label={lossesData?.length || "0" + ` ${t("table:piece")}`}
            size={"sm"}
            colorType={"fill-error"}
          />
        </div>

        <p className={`text-base font-semibold text-red-600`}>
          {t("routes:losses")}
        </p>
      </div>

      <div className="flex-1 flex items-center ml-4 overflow-x-auto pb-4 gap-x-4 mt-6">
        {lossesData?.map((item, index) => {
          return (
            <>
              <div
                className="min-w-[300px] cursor-pointer"
                onClick={() => {
                  if (isNotToday) {
                    setIsWarningModal(true);
                    setSelectedLoss(item?.id);
                  }
                }}
              >
                {/* <JunctionCardType
                  key={index}
                  props={{
                    ring: "red",
                    isFinished: false,
                    leftImage: item?.node?.metadata?.image,
                    texts: {
                      second:
                        item?.node?.metadata?.brand +
                        " " +
                        item?.node?.metadata?.model,
                      first: item?.process?.name,
                      b: item?.timeDiff,
                      a: item.totalCost + " " + "tl",
                    },
                    user: {
                      name: item?.startedPerson,
                      lastName: "",
                      avatar: "",
                    },
                  }}
                /> */}
                <LossesCardType
                  title={item?.type ? item?.type?.name : "Undefined Loss"}
                  subTitle={item?.reasonDescription || "Unkown"}
                  startTime={moment(item?.startDate).format("HH:mm")}
                  endTime={moment(item?.endDate).format("HH:mm")}
                  id={item?.id}
                />
              </div>
            </>
          );
        })}
      </div>
      <CustomModal
        width={400}
        // isOpen={isWarningModal}
        setIsOpen={setIsWarningModal}
        children={
          <SendErrorMessageModal
            setIsOpen={setIsWarningModal}
            t={t}
            selectedLossId={selectedLoss}
            refetch={refetch}
          />
        }
      />
    </div>
  );
};

export default EquipmentLosses;
