import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CloseButton from "../../../../components/buttons/CloseButton";

const StatusInfoModal = ({ setView }) => {
  const { t } = useTranslation();
  const menuRef = useRef();

  const numbers = [
    {
      name: t("signals:active"),
      color: localStorage.color_theme === "dark" ? "#17B26A" : "#17B26A",
    },
    {
      name: t("signals:standby"),
      color: localStorage.color_theme === "dark" ? "#EAAA08" : "#EAAA08",
    },
    {
      name: t("signals:fault"),
      color: localStorage.color_theme === "dark" ? "#F04438" : "#F04438",
    },
    {
      name: t("signals:sensorCableRemoved"),
      color: localStorage.color_theme === "dark" ? "#9E77ED" : "#9E77ED",
    },
    {
      name: t("signals:internetConnectionLost"),
      color: localStorage.color_theme === "dark" ? "#53B1FD" : "#53B1FD",
    },
    {
      name: t("signals:softwareUpdate"),
      color: localStorage.color_theme === "dark" ? "#DC6803" : "#DC6803",
    },
    {
      name: t("signals:break"),
      color: localStorage.color_theme === "dark" ? "#F2F4F7" : "#F2F4F7",
    },
    {
      name: t("signals:noElectric"),
      color: localStorage.color_theme === "dark" ? "#98A2B3" : "#98A2B3",
    },

    {
      name: t("signals:noElectricTower"),
      color: localStorage.color_theme === "dark" ? "#475467" : "#475467",
    },
  ];

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setView(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      className="absolute flex p-6 flex-col top-[56px] shadow-xl right-[100px]  border-[1px] border-secondary-200 bg-white rounded-xl cursor-pointer dark:bg-[#141414] dark:border-[#292929]"
    >
      <div className="flex flex-row items-center w-full relative ">
        <p className="font-semibold text-xl pb-5 text-secondary-900">
          {t("settingTab:statusType")}
        </p>
        <div className="absolute -right-2 -top-2 w-11 h-11 hover:  flex justify-center text-secondary-400 items-center">
          <CloseButton size={"lg"} onClick={() => setView(false)} />
        </div>
      </div>
      {numbers.map((number, i) => (
        <div
          className={`flex flex-row items-center ${
            i === 8 ? "pb-0" : "pb-4"
          } gap-x-4 w-full`}
        >
          <div
            style={{
              backgroundColor: number.color,
            }}
            className="w-6 h-6 rounded"
          ></div>
          <p className="text-secondary-700 text-md font-semibold">
            {number.name}
          </p>
        </div>
      ))}
    </div>
  );
};

export default StatusInfoModal;
