import React, { useEffect, useState } from "react";
import SearchInput from "../../../components/inputs/SearchInput";
import { useQuery } from "@tanstack/react-query";
import { personalService } from "../../../services/personal.service";
import Badgets from "../../../components/buttons/Badgets";
import { Button } from "../../../components";
import { CheckIcon } from "../../../assets/icons/stepsIcons";
import { ChevronDown } from "../../../assets/icons/PersonIcons";
import { useStore } from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import diacritics from "diacritics";

const AddUserToPoll = ({
  setIsOpen,
  selectedUsers,
  setSelectedUsers,
  createPoll,
}) => {
  const { t } = useTranslation();
  const { chat, auth } = useStore();

  const { isLoading, data, refetch } = useQuery({
    retry: 0,
    queryKey: ["pollUserList"],
    queryFn: async () => await personalService?.getDepartmentToUser(),
  });

  const triggerUser = (user) => {
    const filter = selectedUsers?.filter((u) => {
      return u.id == user.id;
    });
    if (filter?.length) {
      setSelectedUsers(selectedUsers?.filter((item) => item?.id !== user?.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    selectedUsers.includes(user);
  };

  const selectAllPress = (users) => {
    const diff = users.filter((x) => !selectedUsers.includes(x));
    if (diff.length > 0) {
      setSelectedUsers([...selectedUsers, ...diff]);
    } else {
      setSelectedUsers(selectedUsers.filter((item) => !users.includes(item)));
    }
  };

  const PersonalItem = observer(({ isSelected, chat, item }) => {
    return (
      <div className="flex flex-col items-start gap-4 select-none ">
        <div
          onClick={() => {
            triggerUser(item);
            chat?.setCurrentChatUser(
              `${item?.name} ${item?.lastName}`,
              item?.avatar
            );
          }}
          className="flex flex-row items-center justify-center gap-3 p-0 w-full h-16"
        >
          <div className="w-full flex flex-row items-center gap-3 p-0 h-full">
            {isSelected ? (
              <div className="flex flex-row items-center gap-3 w-full ">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(`${item?.name} ${item?.lastName}`);
                  }}
                  className={`cursor-pointer flex w-4 h-4 rounded items-center justify-center bg-[#0E9384] text-[#FFF]`}
                >
                  <CheckIcon color={"#fff"} />
                </div>
                <div className="flex flex-row items-center gap-3">
                  {item?.avatar ? (
                    <img
                      src={generateFileUrl(item?.avatar)}
                      className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]"
                    />
                  ) : (
                    <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                      {item?.name?.charAt(0).toUpperCase() +
                        "" +
                        item?.lastName?.charAt(0).toUpperCase()}
                    </div>
                  )}

                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex items-center gap-3">
                <div
                  onClick={() => {
                    triggerUser(item);
                    chat?.setCurrentChatUser(
                      `${item?.name} ${item?.lastName}`,
                      item?.avatar
                    );
                  }}
                  className="cursor-pointer w-4 h-4 border border-[#D0D5DD] rounded items-center justify-center bg-[#fff]"
                ></div>
                <div className="flex flex-row items-center gap-3">
                  {item.avatar ? (
                    <img
                      src={generateFileUrl(item?.avatar)}
                      className="w-10 h-10 rounded-full border-[0.75px] border-solid box-border border-[#EAECF0]"
                    />
                  ) : (
                    <div className="w-10 h-10 border-4 border-teal-500 bg-teal-100 text-teal-700 rounded-full flex items-center justify-center text-md">
                      {item?.name?.charAt(0).toUpperCase() +
                        "" +
                        item?.lastName?.charAt(0).toUpperCase()}
                    </div>
                  )}

                  <div className="flex flex-col justify-center items-start gap-1 p-0  h-12">
                    <p className="text-sm font-semibold text-[#344054]">
                      {item?.name} {item?.lastName}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="flex flex-col w-[500px] sm:w-[400px] xs:w-[300px] h-[600px] mt-6 gap-6">
      <SearchInput setSearchVal={setSearchValue} />
      <div className="flex flex-row items-center justify-between w-full">
        <Badgets
          colorType={"fill-success"}
          label={selectedUsers?.length + " " + "User"}
          // label={`${selectedUsers?.length ?? 0}/${
          //   data?.[0]?.users?.length ?? 0
          // }`}
          size={"md"}
        />
        <button className="text-md font-semibold text-teal-700">
          {t("chat:selectAllPerson")}
        </button>
      </div>

      <div className="h-[430px] flex flex-col  w-full items-start gap-6 overflow-y-scroll scrollbar-hide border-b">
        {data?.data?.map((item, i) => {
          if (item?.users?.length > 0) {
            return (
              <ExpandedCard
                item={item}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                index={i}
                t={t}
                key={i}
                chat={chat}
                auth={auth}
                PersonalItem={PersonalItem}
                selectedUsers={selectedUsers}
                triggerUser={triggerUser}
                selectAllPress={selectAllPress}
              />
            );
          }
        })}
      </div>
      <Button
        colorType={"primary-person"}
        label={t("buttons:createPool")}
        onClick={createPoll}
      />
    </div>
  );
};

const ExpandedCard = ({
  t,
  item,
  index,
  auth,
  chat,
  selectedUsers,
  selectedDepartment,
  PersonalItem,
  selectAllPress,
  triggerUser,
  setSearchValue,
  searchValue,
}) => {
  const [expanded, setExpanded] = useState("");

  const filteredUsers = item?.users?.filter((d) =>
    diacritics
      .remove(`${d?.name} ${d?.lastName}`)
      .toLowerCase()
      .includes(diacritics.remove(searchValue).toLowerCase())
  );

  useEffect(() => {
    if (selectedDepartment) {
      setExpanded(selectedDepartment);
    }
  }, [selectedDepartment]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    if (selectedDepartment) {
      setSelectAllChecked(item?.departmentId === selectedDepartment);
    }
  }, [selectedDepartment]);

  // const handleSelectAllClick = (e) => {
  //   e.stopPropagation();
  //   setSelectAllChecked(!allUsersSelected);
  //   selectAllPress(item?.users);
  // };
  // const allUsersSelected =
  //   selectedUsers &&
  //   item?.users &&
  //   selectedUsers.length === item.users.length &&
  //   selectedUsers.every((selectedUser) =>
  //     item.users.some((departmentUser) => departmentUser.id === selectedUser.id)
  //   );
  return (
    <>
      <div
        key={index}
        className="flex items-center w-full justify-between cursor-pointer border-b pb-2 "
      >
        <div className="flex items-center gap-2">
          <p className="text-base font-semibold text-[#101828]">
            {item.departmentId === "BLANK_DEPARTMENT"
              ? "Diğer"
              : item?.departmentName}
          </p>
          <Badgets
            colorType={"fill-success"}
            label={filteredUsers?.length || 0}
          />
        </div>
        <div className="ml-auto gap-4 flex items-center justify-center z-20 cursor-pointer">
          <div
            className="ml-auto flex gap-3 items-center justify-center text-sm font-normal cursor-pointer select-none text-[#667085] py-2"
            onClick={(e) => {
              selectAllPress(item?.users);
            }}
          >
            {/* {allUsersSelected ? (
              <>
                <div
                  className={`cursor-pointer w-4 h-4 flex rounded items-center justify-center bg-[#0E9384] text-[#FFFFFF]`}
                >
                  <CheckIcon color={"#fff"} />
                </div>
              </>
            ) : (
              <>
                <div
                  className={`cursor-pointer w-4 h-4 border border-solid border-[#D0D5DD] rounded items-center justify-center `}
                ></div>
              </>
            )} */}

            <p className="whitespace-nowrap">Select All</p>
          </div>
          <Button
            colorType={"tertiary-gray"}
            onClick={() => {
              if (item?.departmentId === expanded) {
                setExpanded("");
              } else {
                setExpanded(item?.departmentId);
              }
            }}
            size={"md"}
            iconLeft={
              expanded === item?.departmentId ? (
                <div className="rotate-180">
                  <ChevronDown />
                </div>
              ) : (
                <ChevronDown />
              )
            }
          />
        </div>
      </div>
      {expanded === item?.departmentId && (
        <div className="flex flex-wrap">
          {filteredUsers?.map((user, index) => {
            return (
              user?.id !== auth?.user?.id && (
                <div
                  key={index}
                  className={`w-${
                    item?.users?.length === 1 ? "full" : "full"
                  } px-2`}
                >
                  <PersonalItem
                    chat={chat}
                    item={user}
                    index={index}
                    isSelected={
                      selectedUsers?.find((a) => a?.id == user?.id)
                        ? true
                        : false
                    }
                    triggerUser={triggerUser}
                  />
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default AddUserToPoll;
