import React from "react";
import CustomImage from "../CustomImage";
import CustomText from "../CustomText";
import { NewClockIcon, PlusIcon, QuantityIcon } from "./junctionIcons";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../hooks/useWindowDimension";

const junctionProps = {
  type: "",
  theme: "",
  ring: null, // default dark = "#424242" // default light = "#D0D5DD
  isSetting: false,
  isFinished: false,
  leftImage: "",
  userImage: "",
  rightImage: "",
  rightButton: function () {},
  onPressCard: function () {},
  user: {
    name: "",
    lastName: "",
    avatar: "",
  },
  finishedTexts: {
    first: "",
    second: "",
    a: "",
    b: "",
    c: "",
    d: "",
  },
  texts: {
    first: "",
    second: "",
    a: "",
    b: "",
  },
  onPressProduct: null,
};

const JunctionCardType = ({ props = junctionProps, step, id }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  return (
    <div
      data-tooltip-id={`${id}`}
      data-tooltip-content={`${t("product:orderNo")}: ${
        props?.texts?.first
      } - ${
        step
          ? `(${step}.Op) ` + props?.texts?.second
          : props?.texts?.second || "---"
      }`}
      data-tooltip-place="right"
      onClick={props?.onPressCard ? () => props?.onPressCard() : undefined}
      style={{
        ...styles.card,
        background: "white",
        borderColor: props?.ring
          ? props?.ring
          : props?.theme === "dark"
          ? "#292929"
          : "#D0D5DD",
        borderTopWidth: props?.isFinished ? 1 : 4,
        // zIndex: 99999999,
      }}
    >
      <CustomImage
        onClick={props?.onPressProduct}
        src={props?.leftImage}
        borderRadius={4}
        style={{
          ...styles.squareImage,
          borderColor: props?.theme === "dark" ? "#292929" : "#D0D5DD",
        }}
      />
      <div style={styles.center}>
        {props?.isFinished ? (
          <>
            <CustomText
              numberOfLines={1}
              text={props?.finishedTexts?.first}
              style={{ ...styles.lightText }}
            />
            <div
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
              }}
            ></div>
          </>
        ) : (
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <p className="text-[#667085] text-xs font-normal w-[120px] truncate">
              {props?.texts?.first
                ? props?.texts?.first?.slice(0, 3) + " ***"
                : "---"}
            </p>
            {/* <CustomText
                numberOfLines={1}
                text={props?.texts?.first || "---"}
                style={{
                  ...styles.lightText,
                  color: props?.theme === "dark" ? "#D6D6D6" : "#667085",
                }}
              /> */}
            <p className="text-xs font-medium w-[120px] truncate text-[#344054]">
              {step
                ? `(${step}.Op) ` + props?.texts?.second?.slice(0, 2) + " ***"
                : props?.texts?.second?.slice(0, 2) + " ***" || "---"}
            </p>
            {/* <CustomText
              numberOfLines={1}
              text={
                step
                  ? `(${step}.Op) ` + props?.texts?.second
                  : props?.texts?.second || "---"
              }
              style={{
                ...styles.boldText,
                color: props?.theme === "dark" ? "#D6D6D6" : "#344054",
              }}
            /> */}
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flexShrink: 1,
                marginTop: 2,
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <QuantityIcon />
                <CustomText
                  numberOfLines={1}
                  text={props?.texts?.a || "--"}
                  style={{
                    ...styles.lightText,
                    color: props?.theme === "dark" ? "#D6D6D6" : "#667085",
                    marginLeft: 6,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <NewClockIcon />
                <CustomText
                  numberOfLines={1}
                  text={props?.texts?.b || "--"}
                  style={{
                    ...styles.lightText,
                    color: props?.theme === "dark" ? "#D6D6D6" : "#667085",
                    marginLeft: 6,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {props?.rightButton ? (
        <div
          onClick={() => props?.rightButton()}
          style={{
            ...styles.rightButton,
            borderColor: props?.theme === "dark" ? "#424242" : "#D0D5DD",
            backgroundColor: props?.theme === "dark" ? "#141414" : "#fff",
          }}
        >
          <PlusIcon />
        </div>
      ) : (
        <>
          {props?.user?.avatar ? (
            <>
              {props?.user?.avatar === "empty" ? (
                <NoAvatar
                  name={props?.user?.name}
                  lastName={props?.user?.lastName}
                  size={54}
                />
              ) : (
                <CustomImage
                  src={props?.user?.avatar}
                  borderRadius={100}
                  style={{
                    ...styles.sircleImage,
                    borderColor:
                      props?.theme === "dark" ? "#292929" : "#D0D5DD",
                  }}
                />
              )}
            </>
          ) : (
            <CustomImage
              src={props?.rightImage}
              borderRadius={4}
              style={{
                ...styles.squareImage,
                borderColor: props?.theme === "dark" ? "#292929" : "#D0D5DD",
              }}
            />
          )}
        </>
      )}
      <Tooltip
        id={`${id}`}
        className={`z-[99999] ${width < 1200 ? "hidden" : "flex"} text-xs `}
      />
    </div>
  );
};

const styles = {
  card: {
    borderWidth: 1,
    width: "100%",
    padding: 8,
    borderRadius: 4,
    flexDirection: "row",
    overflow: "hidden",
    maxHeight: 80,
    paddingVertical: 10,
    alignItems: "center",
    display: "flex",
  },
  lightText: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: "400",
    display: "flex",
    flexDirection: "column",
  },
  boldText: {
    fontSize: 12,
    fontWeight: "500",
    lineHeight: "18px",
    display: "flex",
    flexDirection: "column",
  },
  squareImage: {
    borderRadius: 4,
    borderWidth: 1,
    width: 72,
    minWidth: 72,
    height: 54,
    display: "flex",
    flexDirection: "column",
    filter: "blur(4px)",
  },
  sircleImage: {
    width: 54,
    height: 54,
    borderRadius: 100,
    borderWidth: 1,
    display: "flex",
    flexDirection: "column",
    filter: "blur(4px)",
  },
  center: {
    flexGrow: 1,
    height: "100%",
    maxWidth: "48.5%",
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
  },
  rightButton: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 100,
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    filter: "blur(4px)",
  },
};

export default JunctionCardType;
