import React from "react";
import { Button } from "../../../components";
import { ReactComponent as ValidateError } from "../../../assets/icons/input/ValidateError.svg";
import { PlusIcon } from "../../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";

const StepNoData = ({ header, text, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full h-full items-center">
      <div className="flex flex-col gap-4 w-full h-full items-center mt-[176px] sm:mt-[72px] xs:mt-12 ">
        <p className="text-secondary-900 dark:text-darkTrue-50 text-displaySm font-semibold ">
          {header}
        </p>
        <p className="text-lg font-normal text-secondary-500 dark:text-darkTrue-300 xs:text-md text-center w-2/5 md:w-4/6 sm:w-11/12 xs:w-11/12 lg:w-4/6">
          {text}
        </p>
        <div className="w-4/12 xs:hidden block sm:w-8/12 md:w-2/4 mt-2 lg:w-2/4">
          <Button
            size={"xl"}
            label={t("buttons:add")}
            iconLeft={<PlusIcon />}
            colorType={"secondary-gray"}
            onClick={onClick}
          />
        </div>
        <div className="w-4/12 xs:w-11/12 xs:block hidden ">
          <Button
            size={"xl"}
            label={t("buttons:add")}
            iconLeft={<PlusIcon />}
            colorType={"secondary-gray"}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

export default StepNoData;
