import React, { memo, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { CustomModal } from "../../../components";
import OperationDetailModal from "../modals/OperationDetailModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ClockIcon,
  ClockIconStart,
  PlaySquareIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../../assets/icons/productIcons";
import {
  legacySecondToDay,
  secondToDay,
} from "../../../utils/secondToHoursAndMinute";
import { manipulate } from "../../../utils/manipulate";

const ExternalOperationCard = ({
  status,
  iconKey,
  name,
  operations,
  filledOperations,
  orderData,
  totalEstimatedTime,
  listOrder,
  totalJunctionTime,
}) => {
  const { t } = useTranslation();
  const [operationDetailModal, setIsOperationDetailModal] = useState(false);
  const hours = Math.floor(totalEstimatedTime / 3600);
  const day = Math.floor(operations?.totalEstimatedTime / 3600);
  const dayJunctionTime = Math.ceil(operations?.totalJunctionTime / 3600);
  const navigate = useNavigate();
  const statusColor = () => {
    switch (status) {
      case "finished":
        return "#12B76A";
      case "started":
        return "#FDE172";
      case "rejected":
        return "#D92D20";
      default:
        return "#D0D5DD";
    }
  };

  const qualityStatus = () => {
    if (operations?.hasQualityControl) {
      return "#17B26A";
    } else {
      return "#98A2B3";
    }
  };

  return (
    <>
      <div
        // onClick={() => setIsOperationDetailModal(true)}
        onClick={() => {
          navigate(
            `/app/product/order/detail/${operations?.id}+${orderData?.id}`
          );
        }}
        className="flex w-full flex-col h-full cursor-pointer"
      >
        <div
          style={{
            backgroundColor: statusColor(),
          }}
          className={`h-1 max-h-1 min-h-[4px]  rounded-t`}
        ></div>
        <div className="flex w-full h-full border-x pl-3 pr-1 py-1 justify-between">
          <div className="flex flex-col w-[184px] h-full">
            <div className="w-full flex items-center overflow-hidden">
              <div className="w-4 h-4 flex items-center justify-center">
                <Process />
              </div>
              <p className="text-sm text-secondary-700 font-semibold truncate ml-1.5">
                {/* {name?.slice(0, 2)+ " ***"} */}
                {manipulate( name)}
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex border-b">
                <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">
                  {t("product:previousIncoming")}
                </div>
                <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {operations?.totalEstimatedTime}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:day")}
                  </span>
                </div>
                <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {operations?.previousPiece}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">
                  {t("product:applied")}
                </div>
                <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {dayJunctionTime}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:day")}
                  </span>
                </div>
                <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {operations?.qualityAcceptedPiece}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col border-[0.5px] w-[82px] h-[68px] px-[6px] py-[2px] gap-y-2 rounded">
            <div className="flex gap-x-1 items-center justify-center">
              <span className="w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[#17B26A] rounded-[2px]"></span>
              <p className="text-[#475467] font-normal text-xs">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col gap-y-[2px]">
              <div className="flex gap-x-[2px] items-center">
                <QuantityAcceptedIcon />
                <span className="text-xs text-[#079455]">
                  {" "}
                  {!operations?.hasQualityControl
                    ? "--"
                    : operations?.qualityAcceptedPiece}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex gap-x-[2px] items-center">
                <QuantityDeclineIcon />
                <span className="text-xs text-[#D92D20]">
                  {!operations?.hasQualityControl
                    ? "--"
                    : operations?.qualityRejectedPiece}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="border min-h-[26px] max-h-[26px] rounded-b-lg bg-[#F9FAFB] pl-3 pr-2 py-1 flex justify-between items-center">
          <div className="flex h-full items-center gap-x-[2px]">
            {/* <PlaySquareIcon /> */}
            <span className="text-[10px] leading-[18px] text-[#475467]">
              {t("product:events")}:
            </span>
            <span className={`text-[10px] text-success-500 font-medium`}>
              {operations?.acceptedJunctionLength || "--"}
            </span>

            <span className="text-[10px] text-[#344054] font-medium">
              /{operations?.totalJunctionLength || "--"}
            </span>
          </div>
        </div>
      </div>

      <CustomModal
        isOpen={operationDetailModal}
        setIsOpen={setIsOperationDetailModal}
        modalTitle={t("product:operationDetail")}
        children={
          <OperationDetailModal
            t={t}
            listOrder={listOrder}
            operations={operations}
            filledOperations={filledOperations}
            orderData={orderData}
          />
        }
      />
    </>
  );
};

export default memo(ExternalOperationCard);

const Process = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.4045 7.55677C11.322 7.47427 11.2807 7.43301 11.267 7.38366C11.255 7.34025 11.2575 7.29267 11.2742 7.24775C11.2932 7.19668 11.339 7.15085 11.4307 7.05918L14.7698 3.72006L13.5913 2.54155L17.9452 2.31239L17.7161 6.66634L16.5375 5.48783L13.1984 8.82694C13.1068 8.91861 13.0609 8.96445 13.0098 8.98341C12.9649 9.00008 12.9174 9.00259 12.8739 8.99056C12.8246 8.97689 12.7833 8.93564 12.7008 8.85313L11.4045 7.55677Z"
        stroke="#344054"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.09277 7.94466C6.99273 9.0447 6.99276 10.8282 8.09279 11.9283C9.19283 13.0283 10.9764 13.0283 12.0764 11.9283M10.5984 2.67847L10.1893 1.77932C10.0443 1.46065 9.69023 1.29351 9.35206 1.38411L6.44279 2.16365C6.10465 2.25426 5.88156 2.57605 5.91533 2.9245L6.01069 3.90776C6.11941 5.02931 5.17745 5.97128 4.05589 5.86256L3.07263 5.7672C2.72418 5.73343 2.4024 5.95652 2.31178 6.29466L1.53224 9.20393C1.44164 9.5421 1.60878 9.89619 1.92745 10.0412L2.8266 10.4502C3.85227 10.9169 4.19707 12.2036 3.54211 13.1205L2.96793 13.9244C2.76447 14.2092 2.79675 14.5995 3.04427 14.847L5.17405 16.9768C5.42158 17.2243 5.81183 17.2566 6.09667 17.0531L6.90052 16.4789C7.81747 15.824 9.10421 16.1688 9.57082 17.1945L9.97991 18.0936C10.1249 18.4123 10.479 18.5794 10.8171 18.4888L13.7264 17.7093C14.0645 17.6187 14.2876 17.2969 14.2539 16.9484L14.1585 15.9652C14.0498 14.8436 14.9917 13.9016 16.1133 14.0104L17.0966 14.1057C17.445 14.1395 17.7668 13.9164 17.8574 13.5783L18.637 10.669C18.7275 10.3308 18.5604 9.97674 18.2418 9.83178"
        stroke="#344054"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const QualityAccepted = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M4.8125 1.75H3.28571C2.57563 1.75 2 2.32563 2 3.03571V8.90179C2 9.61187 2.57563 10.1875 3.28571 10.1875H9.71429C10.4244 10.1875 11 9.61187 11 8.90179V3.03571C11 2.32563 10.4244 1.75 9.71429 1.75H8.1875M4.8125 1.75V4.02098C4.8125 4.06877 4.86279 4.09985 4.90554 4.07848L6.5 3.28125L8.09446 4.07848C8.13721 4.09985 8.1875 4.06877 8.1875 4.02098V1.75M4.8125 1.75H8.1875"
        stroke={color || "#079455"}
        strokeWidth="0.75"
      />
      <path
        d="M5 7.09615L6.07692 8.17308L8.5 5.75"
        stroke={color || "#079455"}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const QualityRejected = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M4.8125 1.75H3.28571C2.57563 1.75 2 2.32563 2 3.03571V8.90179C2 9.61187 2.57563 10.1875 3.28571 10.1875H9.71429C10.4244 10.1875 11 9.61187 11 8.90179V3.03571C11 2.32563 10.4244 1.75 9.71429 1.75H8.1875M4.8125 1.75V4.02098C4.8125 4.06877 4.86279 4.09985 4.90554 4.07848L6.5 3.28125L8.09446 4.07848C8.13721 4.09985 8.1875 4.06877 8.1875 4.02098V1.75M4.8125 1.75H8.1875"
        stroke={color || "#D92D20"}
        strokeWidth="0.75"
      />
      <path
        d="M7.75 5.75L5.25 8.25M5.25 5.75L7.75 8.25"
        stroke={color || "#D92D20"}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
