import React, { useState } from "react";
import { UpuTower, UpuTowerText } from "./svg";
import generateFileUrl from "../../../utils/generateFileUrl";
import Button from "../../../components/buttons/Button";
import { InfoIcons } from "./svg/EmbeddedServerIcons";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import StatusColors from "./StatusColors";
import { CustomModal } from "../../../components";

const Header = ({ deviceInfo, loading }) => {
  const { t } = useTranslation();
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  console.log("deviceInfo?.deviceState", deviceInfo?.deviceState);
  const headItems = [
    { name: "Node ID", value: deviceInfo?.nodeId },
    { name: t("embedded:deviceState"), value: deviceInfo?.deviceState },
    { name: t("embedded:status"), value: deviceInfo?.status },
    { name: t("embedded:macAddress"), value: String(deviceInfo?.macAddress) },
    { name: t("embedded:ipAddress"), value: deviceInfo?.ipAddress },
    { name: t("embedded:sensorFusion"), value: deviceInfo?.stmVersion },
    { name: t("embedded:connectionSoftware"), value: deviceInfo?.espVersion },
    { name: "rssi", value: deviceInfo?.rssi },
  ];
  return (
    <>
      <div className="flex items-center w-full  h-[144px] min-h-[144px]">
        <div className="lg:w-[90%] xl:w-[90%] grow flex h-full flex-col justify-between mr-8">
          <div className="w-full h-[60px] flex items-center">
            <div className="flex flex-col h-full justify-between">
              <div className="w-[135px]">
                <UpuTowerText />
              </div>
              <span className="text-lg lg:text-base md:text-base sm:text-sm text-secondary-500 truncate">
                Embedded Server
              </span>
            </div>
            {loading ? (
              <Skeleton
                height={60}
                width={180}
                className="ml-[150px] md:ml-[50px] lg:ml-[64px]"
              />
            ) : (
              <>
                <img
                  src={generateFileUrl(deviceInfo.image)}
                  className="w-[80px] h-[60px] rounded-md blur-sm border border-secondary-200 ml-[150px] md:ml-[50px] lg:ml-[64px]"
                />
                <div className="flex flex-col ml-4 w-[130px]">
                  <p className="text-secondary-500 text-sm md:text-xs truncate">
                    {t("embedded:connectedEquipment")}
                  </p>
                  <span className="text-secondary-700 font-medium truncate">
                    {deviceInfo?.name?.slice(0, 2) + " ***"}
                  </span>
                </div>
              </>
            )}
            {loading ? (
              <Skeleton className="ml-10 w-[130px] h-[60px]" />
            ) : (
              <div className="flex flex-col ml-10 w-[130px]">
                <p className="text-secondary-500 text-sm md:text-xs truncate">
                  {t("embedded:activeAnalogSensor")}
                </p>
                <span className="text-secondary-700 font-medium">
                  {deviceInfo.activeAnalog} / 3
                </span>
              </div>
            )}
            {loading ? (
              <Skeleton className="ml-10 w-[130px] h-[60px]" />
            ) : (
              <div className="flex flex-col ml-10 w-[130px]">
                <p className="text-secondary-500 text-sm md:text-xs truncate">
                  {t("embedded:activeDigitalSensor")}
                </p>
                <span className="text-secondary-700 font-medium">
                  {deviceInfo.activeDigital} / 3
                </span>
              </div>
            )}
            <div className="w-[162px] ml-auto">
              {/* {loading ? (
              <Skeleton width={162} height={38} />
            ) : (
              <Button
                colorType={"secondary-embedded"}
                iconLeft={<InfoIcons />}
                label={t("embedded:statusColors")}
                size={"sm"}
              />
            )} */}
            </div>
            {loading ? (
              <Skeleton width={162} height={38} />
            ) : (
              <div className="w-[162px]">
                <Button
                  size={"md"}
                  label={"Status Colors"}
                  colorType={"primary-embedded"}
                  onClick={() => setIsOpenStatus(true)}
                  iconLeft={<InfoIcon />}
                />
              </div>
            )}
          </div>
          <div className="h-px w-full bg-secondary-200" />
          <div className="h-[62px] w-full scrollbar-hide flex items-center justify-between gap-x-4">
            {headItems.map((item, index) => {
              if (loading) {
                return <Skeleton className="h-[56px] min-w-[10.5%]" />;
              } else {
                return (
                  <div
                    key={index}
                    className="px-3.5 overflow-hidden flex h-[60px] min-w-[10.5%] border border-gray-300 shadow flex-col justify-center items-center rounded-lg"
                  >
                    <p className="text-sm text-gray-500 truncate">
                      {item?.name}
                    </p>
                    <p
                      className={`font-medium mt-0.5 truncate w-full text-center ${
                        index === 1 &&
                        deviceInfo?.deviceState === "NOT_CONNECTED"
                          ? "text-[#FF0000]"
                          : "text-gray-700"
                      }`}
                    >
                      {item?.value?.slice(0, 2)+ " ***" || "****"}
                    </p>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <UpuTower color={deviceInfo?.ring} />
      </div>
      <CustomModal
        isOpen={isOpenStatus}
        setIsOpen={setIsOpenStatus}
        modalTitle={"Status Colors"}
        children={
          <StatusColors
            onClose={() => {
              setIsOpenStatus(false);
            }}
          />
        }
      />
    </>
  );
};

export default React.memo(Header);

export const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_13596_91658)">
        <path
          d="M9.99984 13.3334V10.0001M9.99984 6.66675H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs></defs>
    </svg>
  );
};
