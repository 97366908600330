import React, { useEffect, useState } from "react";
import { useStore } from "../../hooks/useStores";
import { useQuery } from "@tanstack/react-query";
import { reportService } from "../../services/report.service";
import CloseButton from "../../components/buttons/CloseButton";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import ReactECharts from "echarts-for-react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
} from "../../assets/icons/PersonIcons";
import moment from "moment";
import DetailGaugeChart from "./Twin/chart/DetailGaugeChart";
import { timeFormatter } from "../../utils/timezoneFormatter";
import CompanyDetailsWorks from "./components/CompanyDetailsWorks";
import CompanyDetailsLosses from "./components/CompanyDetailsLosses";
import CompanyDetailsUsers from "./components/CompanyDetailsUsers";
import EndDatePicker from "../person/components/EndDatePicker";
import { useTranslation } from "react-i18next";
import {
  Cost1Icon,
  Cost3Icon,
  DetailCcountIcon,
  DetailConsumeIcon,
  DetailEnergyIcon,
} from "../../assets/icons/machineIcons";
import CompanyGaugeChart from "./Twin/chart/CompanyGaugeChart";
import Skeleton from "react-loading-skeleton";
import { TableArrowLeft } from "../../assets/icons/lossIcon";

const CompanyDetail = () => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const navigate = useNavigate();
  const [isToday, setIsToday] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const { data, refetch, isLoading, isFetching } = useQuery({
    retry: 0,
    queryKey: ["company-console-data"],
    queryFn: async () =>
      await reportService.getCompanyConsoleData({
        date: moment(
          timeFormatter(date, auth?.user?.company?.timeZone)?.formatted
        ).format("YYYY-MM-DD"),
      }),
  });
  const loading = isLoading || isFetching;

  const newDates = data?.monthlyChart?.dates?.map((item) =>
    moment(item).format("DD-MM-YYYY")
  );

  const reverseDateFormat = (dateString) => {
    const parts = dateString.split("-");
    const reversedDateString = parts.reverse().join("-");
    return reversedDateString;
  };

  const onChartClick = (params) => {
    if (params?.name) {
      const newDate = reverseDateFormat(params?.name);
      setDate(newDate);
    }
  };
  const onEvents = {
    click: onChartClick,
  };

  const options = React.useMemo(() => {
    return {
      title: {},
      tooltip: {
        trigger: "axis",
        position: "top",
      },
      legend: {
        data: data?.monthlyChart?.legend,
        show: true,
        right: "5%",
        left: "4%",
        alignTicks: false,
        formatter: function (name) {
          let itemValue = data?.monthlyChart?.series?.filter(
            (item) => item.name === name
          );
          const total = itemValue[0].data.reduce((a, b) => {
            return a + b;
          }, 0);
          return `{name|${name}}:{total|Avg: ${Number(
            (total / 30).toFixed(2)
          )}}`;
        },
        textStyle: {
          rich: {
            name: { fontWeight: "regular", align: "left", fontSize: 10 },
            total: {
              fontSize: 10,
              fontWeight: "regular",
            },
          },
        },
      },
      grid: {
        left: "3%",
        right: "5%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: newDates,
        axisLabel: {
          formatter: function (value, index) {
            return value?.slice(0, 2);
          },
          show: true,
        },
      },
      yAxis: [
        {
          type: "value",
          name: "Kwh",
          position: "right",
          alignTicks: false,
          axisLine: {
            show: true,
            lineStyle: {
              color:
                localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
            },
          },
          axisLabel: {
            formatter: "{value} Kwh",
            show: true,
            textStyle: {
              color:
                localStorage.color_theme === "dark" ? "#D6D6D6" : "#3c3c3c",
              right: "-20px",
            },
          },
        },
        {},
        {},
      ],
      series: data?.monthlyChart?.series,
    };
  }, [data]);

  useEffect(() => {
    if (
      moment(new Date()).format("YYYY-MM-DD") !==
      moment(new Date(date)).format("YYYY-MM-DD")
    ) {
      refetch();
    }
  }, [date]);

  useEffect(() => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const isToday = today === moment(date).format("YYYY-MM-DD");
    setIsToday(isToday);
  }, [date, setDate]);

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="w-full h-[56px] flex items-center  border-b border-secondary-200">
        <div className="w-10 h-10">
          <Button
            colorType={"tertiary-gray"}
            onClick={() => {
              navigate(-1);
            }}
            size={"sm"}
            iconLeft={
              <div className="rotate-180">
                <TableArrowLeft />
              </div>
            }
          />
        </div>
        <h1 className="text-2xl text-secondary-900 font-semibold ml-1">
         Company Name
        </h1>
      </div>
      <div className="flex w-full mt-6">
        <div className="w-[472px] flex flex-col">
          {loading ? (
            <div className="w-full h-[140px] mb-3 bg-gray-200 animate-pulse rounded-lg" />
          ) : (
            <div className="flex items-center justify-between border rounded-lg border-secondary-200 shadow mb-3 h-[140px]">
              {data?.gaugeData?.map((item, index) => {
                  console.log('item' , item)
                return (
                  <CompanyGaugeChart
                    key={index}
                    index={index}
                    name={t(`addProcess:${item?.type}`)}
                    value={item?.actual}
                    timeMass={item?.timeMass}
                    kpi1={index == 0 ? 0.65 : 0.75}
                    kpi2={index == 0 ? 0.85 : 0.92}
                  />
                );
              })}
            </div>
          )}
          {loading ? (
            <div className="w-full bg-gray-200 rounded-lg animate-pulse h-[152px]" />
          ) : (
            <div className="flex flex-col w-full border-secondary-200 border rounded-lg shadow">
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full border-b border-secondary-200 items-center gap-3 px-6 py-1 h-[50px]">
                  <DetailCcountIcon color="#B54708" width={24} height={24} />
                  <div className="flex flex-col whitespace-nowrap">
                    <p className="text-[#475467] text-xs font-normal">
                      {" "}
                      C-Count{" "}
                    </p>
                    <p className="text-[#B54708] text-sm font-semibold">
                      {data?.energyData?.cCount || "-- "}
                      <span className="text-[#475467] font-normal"> kgs</span>
                    </p>
                  </div>
                </div>
                <div className="flex flex-row w-full items-center gap-3 px-6 py-1 h-[50px] border-b border-secondary-200">
                  {/* <DetailConsumeIcon color="#6941C6" /> */}
                  <DetailEnergyIcon color="#3E4784" width={24} height={24} />
                  <div className="flex flex-col whitespace-nowrap ml-1.5">
                    <p className="text-[#475467] text-xs font-normal">
                      {t("settingTab:energy")}
                    </p>
                    <p className="text-[#6941C6] text-sm font-semibold">
                      {data?.energyData?.kwh?.toFixed(3) || "-- "}
                      <span className="text-[#475467] font-normal"> kgs</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between w-full h-[50px]">
                <div className="flex items-center justify-center w-full px-6 py-1">
                  <div className="flex w-full">
                    <div className="flex items-center w-1/2">
                      <Cost1Icon />
                      <div className="flex flex-col ml-3">
                        <p className="text-[#475467] text-xs font-normal">
                          {t("loss:costt")}
                        </p>
                        <p className="text-[#3E4784] text-sm font-semibold">
                          {data?.energyData?.kwhCostT1 || "-- "} ₺
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2 flex items-center">
                      <Cost3Icon />
                      <div className="flex flex-col ml-3">
                        <p className="text-[#475467] text-xs font-normal whitespace-nowrap">
                          {t("loss:costThree")}
                        </p>
                        <p className="text-[#3E4784] text-sm font-semibold">
                          {data?.energyData?.kwhCostForT3 || "-- "} ₺
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {loading ? (
          <div className="flex-1 ml-4 bg-gray-200 animate-pulse rounded-lg" />
        ) : (
          <div className="flex-1 ml-4 border rounded-lg shadow-sm border-gray-200 relative">
            <ReactECharts
              onEvents={onEvents}
              option={options}
              style={{
                height: "80%",
                width: "98%",
                top: "8px",
                marginLeft: "8px",
                marginTop: "36px",
              }}
            />
            <div
              className={`absolute top-1 left-3 font-normal gap-2 w-full gap-x-2`}
            >
              <p className="text-[12px] mt-0.5 font-semibold inline-block">
                {t("settingTab:start")} :{" "}
                {moment(
                  timeFormatter(data?.monthlyChart?.startDate)?.formatted
                ).format("DD-MM-YYYY")}
              </p>
              <p className="text-[12px] ml-2 mt-0.5 font-semibold inline-block">
                {t("settingTab:end")} :{" "}
                {moment(
                  timeFormatter(data?.monthlyChart?.endDate)?.formatted
                ).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>
        )}
      </div>
      <DateSelect date={date} setDate={setDate} loading={loading} />
      <div className="flex justify-between mt-4 w-full flex-1 gap-x-6 overflow-hidden">
        {loading ? (
          <>
            <div className="w-1/3 rounded-xl bg-gray-200 animate-pulse h-full" />
            <div className="w-1/3 rounded-xl bg-gray-200 animate-pulse h-full" />
            <div className="w-1/3 rounded-xl bg-gray-200 animate-pulse h-full" />
          </>
        ) : (
          <>
            <CompanyDetailsWorks
              junctions={data?.activeJunctionList}
              isToday={isToday}
              refetch={refetch}
            />
            <CompanyDetailsLosses todayData={data?.losses} isToday={isToday} />
            <CompanyDetailsUsers
              todayData={data?.availableUser}
              isToday={isToday}
            />
          </>
        )}
      </div>
    </div>
  );
};

const DateSelect = React.memo(({ date, setDate, loading }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="w-full justify-between flex items-center gap-3 mt-4">
      {loading ? (
        <Skeleton width={140} height={36} borderRadius={8} />
      ) : (
        <Button
          iconLeft={<ArrowLeftIcon />}
          size={"sm"}
          colorType={"secondary-gray"}
          onClick={() => {
            setDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"));
          }}
        />
      )}
      {loading ? (
        <Skeleton width={140} height={36} borderRadius={8} />
      ) : (
        <EndDatePicker
          date={date}
          visible={visible}
          setDate={setDate}
          setVisible={setVisible}
          buttonClassName={"flex flex-col items-center"}
          calendarClassName={"absolute top-12 rounded-lg shadow-xl z-[600]"}
          visibleFooter
        >
          <Button
            onClick={() => {
              setVisible(!visible);
            }}
            iconLeft={<CalendarIcon />}
            size={"sm"}
            colorType={"secondary-gray"}
            label={moment(date).format("DD.MM.YYYY")}
          />
        </EndDatePicker>
      )}
      {loading ? (
        <Skeleton width={140} height={36} borderRadius={8} />
      ) : (
        <Button
          iconLeft={<ArrowRightIcon />}
          size={"sm"}
          colorType={"secondary-gray"}
          onClick={() => {
            setDate(moment(date).subtract(-1, "days").format("YYYY-MM-DD"));
          }}
        />
      )}
    </div>
  );
});

export default CompanyDetail;
