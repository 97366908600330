import React, { useEffect, useState } from "react";
import moment from "moment";
import { timeFormatter } from "../../../utils/timezoneFormatter";
import { authStore } from "../../../stores/auth.store";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import { SingleEventRect } from "../../../components/upuTimeline/components/SingleEventRect";
import { useTranslation } from "react-i18next";
import {
  formatSeconds,
  secondToHoursAndMinute2,
} from "../../../utils/secondToHoursAndMinute";
import { statusToStringTR } from "../../../utils/statusToString";
import generateFileUrl from "../../../utils/generateFileUrl";
import ApprovedCard from "../../../components/junction/ApprovedCard";
import MultipleEventRectMulti from "../../../components/upuTimeline/components/MultipleEventRectMulti";
import { Tooltip } from "react-tooltip";
import OperationNodeTimelines from "./OperationNodeTimelines";
import NoData from "../../../steps/step-navigation/components/NoData";
import Junction from "../../../components/junction/Junction";
import FinishedJunctionCard from "../../../components/junction/FinishedJunctionCard";
import { useNavigate } from "react-router-dom";
import TimeLineTooltip from "./components/TimeLineTooltip";
import LossesCardType from "../../../components/junction/LossesCardType";
import NewApprovedCard from "../../../components/junction/NewApprovedCard";
import NewActiveAndPlannedCard from "../../../components/junction/NewActiveAndPlannedCard";
import NewCompletedAndWorkingCard from "../../../components/junction/NewCompletedAndWorkingCard";
import FinishJunctionModal from "../../../components/modal/FinishJunctionModal";
import { CustomModal } from "../../../components";

const OperationUpuTimeline = ({ data, shiftlyData, groupedDatas, date }) => {
  const navigate = useNavigate();
  const [shiftValues, setShiftValues] = useState([]);
  const [junctions, setJunctions] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [defaultStartTime, setDefaultStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [defaultEndTime, setDefaultEndTime] = useState(null);
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedDayType, setSelectedDayType] = useState(1);
  const [isOpenJunctionPiece, setIsOpenJunctionPiece] = useState(false);
  const [finishJunctionId, setFinishJunctionId] = useState(null);
  const [junctionType, setJunctionType] = useState("internal");
  const [pageLoadTime, setPageLoadTime] = useState(moment());
  const _ = require("lodash");
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();

  const [showTooltip, setShowTooltip] = useState(null);
  const [mouseX, setMouseX] = useState(null);

  const totalWidth = width;

  const totalWidthh = totalWidth - 390;
  let svgWidth = width - 510;

  let svgHeight = data?.data?.length <= 2 ? 250 : data?.data?.length * 94;

  const junctionNumber = (item) => {
    const junctions = shiftlyData?.filter((a) => a.status != "planned");

    let sortedJunctions = junctions?.sort(
      (a, b) => moment(a?.endDate)?.unix() - moment(b?.endDate)?.unix()
    );

    const datas = sortedJunctions?.map((value, index) => {
      if (value?.id == item) {
        return index + 1;
      } else {
        return "";
      }
    });

    const lastData = datas?.filter((a) => a != "");

    if (lastData?.length > 0) {
      return lastData[0];
    } else {
      return 1;
    }

    // return 1;
  };

  // const handleMouseOver = (index, xPos, yPos, e) => {
  //   if (index.index == 0) {
  //     data?.junctionData?.filter((e) => {
  //       if (e.id == index.junctionId) {
  //         setShowTooltip({ index, xPos, yPos, junction: e });
  //       }
  //     });
  //   } else {
  //     setShowTooltip({ index, xPos, yPos });
  //   }
  // };

  const handleMouseMove = (e) => {
    setMouseX(e.clientX);
  };

  const handleMouseOut = () => {
    if (showTooltip) {
      setShowTooltip(null);
    }
  };

  const handleShiftClick = (index) => {
    if (selectedJunction) {
      setStartTime(
        new Date(timeFormatter(selectedJunction.startDate).formatted).valueOf()
      );
      setEndTime(
        new Date(timeFormatter(selectedJunction.endDate).formatted).valueOf()
      );
    } else {
      setStartTime(
        new Date(timeFormatter(index.startDate).formatted).valueOf()
      );
      setEndTime(new Date(timeFormatter(index.endDate).formatted).valueOf());
    }
  };

  const calculateShiftData = async (data) => {
    const datas = [];

    data?.map((value, no) => {
      value?.value.map((da, i) => {
        datas.push({
          name: value.name,
          type:
            da?.index == 1
              ? "status"
              : da.index == 2
              ? "shift"
              : da.index == 0
              ? "junction"
              : "",
          node: value.node,
          junctionId: da?.junctionId,

          actionId: da?.actionId,
          productData: da?.productName,
          operationNo: da?.operationNo,

          startDate: da?.start,
          endDate: da?.end,
          timeDiff: da?.timeDiff,
          index: da?.index,

          value: [
            da?.index,

            /// moment(da.start).unix(),
            // moment(da.end).unix(),
            moment(
              timeFormatter(da.start, authStore?.user?.company?.timeZone)
                .formatted
            )?.unix(),
            moment(
              timeFormatter(da.end, authStore?.user?.company?.timeZone)
                .formatted
            )?.unix(),
            da.timeDiff,
          ],
          shiftId: da?.shiftId,
          color:
            da?.index == 1
              ? value?.itemStyle?.normal?.color
              : da?.index == 2
              ? value?.itemStyle?.normal?.color
              : da?.index == 0
              ? da?.type == "setup"
                ? "#EAAA08"
                : "#12B76A"
              : value?.itemStyle?.normal?.color,
        });
      });
    });

    const Valuess = datas?.sort((a, b) => a.value[1] - b.value[1]);
    const lastValues = _.uniqWith(Valuess, _.isEqual);

    let shiftValues = Valuess?.filter((item) => {
      return item?.index == 2;
    });

    await setShiftValues(shiftValues);
    //   await  setTimelineValues(timelineDatas)
  };

  useEffect(() => {
    calculateShiftData(shiftlyData);

    setStartTime(moment(timeFormatter(data?.startDate)?.formatted).valueOf());
    setDefaultStartTime(
      moment(timeFormatter(data?.startDate)?.formatted).valueOf()
    );
    setEndTime(
      moment(timeFormatter(data?.startDate)?.formatted).valueOf() + 86400000
    );
    setDefaultEndTime(
      moment(timeFormatter(data?.startDate)?.formatted).valueOf() + 86400000
    );
  }, []);

  useEffect(() => {
    setPageLoadTime(moment());
  }, [data]);

  return (
    <>
      <div
        className=" w-full flex flex-col h-full  border-[2px] border-gray-300 rounded-xl bg-white"
        style={{
          width: totalWidthh - 150,
        }}
      >
        <div className="w-full  rounded-t-lg  flex items-center ml-3 mt-2 ">
          <span className="isolate inline-flex rounded-lg">
            <button
              onClick={() => {
                setStartTime(defaultStartTime);
                setEndTime(defaultEndTime);
                setSelectedDayType(1);
                setSelectedJunction(null);
              }}
              type="button"
              className="relative inline-flex items-center rounded-l-lg  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              style={{
                backgroundColor: selectedDayType == 1 ? "#F9FAFB" : "white",
              }}
            >
              {t("gauge:allDay")}
            </button>
            {Object?.values(groupedDatas)?.map((shift, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    setStartTime(
                      new Date(
                        timeFormatter(shift[0]?.value[0]?.start)?.formatted
                      ).valueOf()
                    );
                    setEndTime(
                      new Date(
                        timeFormatter(shift[shift?.length - 1]?.end)?.formatted
                      ).valueOf()
                    );
                    setSelectedDayType(shift[0]?.shiftId);
                  }}
                  type="button"
                  className="relative -ml-px inline-flex items-center  px-4 py-2 text-sm font-semibold text-secondary-700 ring-1 ring-inset ring-gray-300  focus:z-10 last:rounded-r-md "
                  style={{
                    backgroundColor:
                      selectedDayType == shift[0]?.shiftId
                        ? "#F9FAFB"
                        : "white",
                  }}
                >
                  <span
                    className={`w-3 h-3 rounded-sm mr-2`}
                    style={{
                      backgroundColor: shift[0]?.itemStyle?.normal?.color,
                    }}
                  ></span>
                  {shift[0]?.name}
                </button>
              );
            })}
          </span>
        </div>

        <div className="w-full flex mt-2 ">
          <div className="w-[350px] min-w-[350px] max-w-[350px] flex flex-col items-start z-[30] pt-3">
            <>
              {data?.data?.map((e, i) => {
                const valuesWithoutItemStyle = e?.timelineData.filter(
                  (item) => !item.hasOwnProperty("itemStyle")
                );
                const sortedJunction = valuesWithoutItemStyle
                  ?.map((el) => {
                    return el?.value?.map((e) => {
                      return { name: el?.name, status: el?.status, ...e };
                    });
                  })
                  ?.flat(1)
                  ?.sort(
                    (a, b) => moment(b?.end).unix() - moment(a?.end).unix()
                  );
                return (
                  <div
                    className={`w-full flex mx-1 my-1 max-h-[64px] h-[64px] rounded-lg ${
                      i === 0 ? "" : "mt-6"
                    }`}
                  >
                    {selectedJunction &&
                    sortedJunction?.find(
                      (e) =>
                        (e?.junctionId || e?.lossesId) ===
                        (selectedJunction?.junctionId ||
                          selectedJunction?.lossesId)
                    ) ? (
                      <>
                        {selectedJunction?.lossesId ? (
                          <Junction
                            losses={true}
                            props={{
                              time: moment(selectedJunction?.startDate)
                                .add(3, "hour")
                                ?.format("HH:mm"),
                              ring: "#D92D20",
                              node: {
                                image: e?.nodeImage,
                                name: e?.nodeName,
                                onClick: () => {
                                  navigate(
                                    `/app/machine/digital-twin/equipment-details/${
                                      e?.deviceId
                                    }-${e?.nodeId}/${moment(
                                      selectedJunction?.startDate
                                    ).format("YYYY-MM-DD")}`
                                  );
                                },
                              },
                              product: {
                                name:
                                  selectedJunction?.lossesName ||
                                  "Undefined Loss",
                                code: "Losses",
                                piece: "--",
                              },
                              user: {
                                image: selectedJunction?.avatar,
                                lastName: "",
                                id: selectedJunction?.status,
                                name: selectedJunction?.name,
                              },
                              avatarPress: () => {
                                navigate(
                                  `/app/machine/digital-twin/user-detail/${
                                    selectedJunction?.status
                                  }/${moment(
                                    selectedJunction?.startDate
                                  ).format("YYYY-MM-DD")}`
                                );
                              },
                            }}
                          />
                        ) : (
                          <FinishedJunctionCard
                            operation={true}
                            junctionId={selectedJunction?.junctionId}
                            props={{
                              startDate: selectedJunction?.startDate,
                              ring:
                                selectedJunction?.junctionType === "work"
                                  ? "#17B26A"
                                  : selectedJunction?.junctionType === "setup"
                                  ? "#EAAA08"
                                  : "#98A2B3",
                              user: {
                                image: selectedJunction?.avatar,
                                name: selectedJunction?.name,
                                lastName: "",
                                id: selectedJunction?.status,
                              },
                              product: {
                                name: selectedJunction?.productData,
                                image: selectedJunction?.productImage,
                                pieceGreen: selectedJunction?.acceptedWorkCount,
                                pieceRed: selectedJunction?.rejectedWorkCount,
                              },
                              node: {
                                name: e?.nodeName,
                                image: e?.nodeImage,
                              },
                              time: {
                                timeGreen:
                                  moment(selectedJunction?.endDate)
                                    .diff(
                                      selectedJunction?.startDate,
                                      "minutes"
                                    )
                                    .toFixed(0) + "dk",
                              },
                              onPressNode: () => {
                                navigate(
                                  `/app/machine/digital-twin/equipment-details/${
                                    e?.deviceId
                                  }-${e?.nodeId}/${moment(
                                    selectedJunction?.startDate
                                  ).format("YYYY-MM-DD")}`
                                );
                              },
                              // onPressProduct: (e) => {
                              //   // e.stopPropagation();
                              //   navigate(
                              //     `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                              //   );
                              // },
                              avatarPress: () => {},
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {valuesWithoutItemStyle?.length == 0 ? (
                          <Junction
                            props={{
                              onPressProduct: () => {},
                              onPressUser: () => {},
                              node: {
                                name: e?.nodeName,
                                image: e?.nodeImage,
                                onClick: () => {
                                  navigate(
                                    `/app/machine/digital-twin/equipment-details/${
                                      e?.deviceId
                                    }-${e?.nodeId}/${moment(date).format(
                                      "YYYY-MM-DD"
                                    )}`
                                  );
                                },
                              },
                            }}
                          />
                        ) : e?.activeJunction?.id === undefined ? (
                          <>
                            {sortedJunction[0]?.lossesId ? (
                              <Junction
                                losses={true}
                                props={{
                                  time: moment(sortedJunction[0]?.start)
                                    .add(3, "hour")
                                    ?.format("HH:mm"),
                                  ring: "#D92D20",
                                  node: {
                                    image: e?.nodeImage,
                                    name: e?.nodeName,
                                    onClick: () => {
                                      navigate(
                                        `/app/machine/digital-twin/equipment-details/${
                                          e?.deviceId
                                        }-${e?.nodeId}/${moment(
                                          sortedJunction[0]?.start
                                        ).format("YYYY-MM-DD")}`
                                      );
                                    },
                                  },
                                  product: {
                                    name:
                                      sortedJunction[0]?.index?.lossesName ||
                                      "Undefined Loss",
                                    code: "Losses",
                                    piece: "--",
                                  },
                                  user: {
                                    image: sortedJunction[0]?.avatar,
                                    lastName: "",
                                    id: sortedJunction[0]?.status,
                                    name: sortedJunction[0]?.name,
                                  },
                                  avatarPress: () => {
                                    navigate(
                                      `/app/machine/digital-twin/user-detail/${
                                        sortedJunction[0]?.status
                                      }/${moment(
                                        sortedJunction[0]?.start
                                      ).format("YYYY-MM-DD")}`
                                    );
                                  },
                                }}
                              />
                            ) : (
                              <FinishedJunctionCard
                                operation={true}
                                junctionId={sortedJunction[0]?.junctionId}
                                props={{
                                  startDate: sortedJunction[0]?.start,
                                  ring:
                                    sortedJunction[0]?.type === "work"
                                      ? "#17B26A"
                                      : sortedJunction[0]?.type === "setup"
                                      ? "#EAAA08"
                                      : "#98A2B3",
                                  user: {
                                    image: sortedJunction[0]?.avatar,
                                    name: sortedJunction[0]?.name,
                                    lastName: "",
                                    id: sortedJunction[0]?.status,
                                  },
                                  product: {
                                    name: sortedJunction[0]?.productName,
                                    image: sortedJunction[0]?.productImage,
                                    pieceGreen:
                                      sortedJunction[0]?.acceptedWorkCount,
                                    pieceRed:
                                      sortedJunction[0]?.rejectedWorkCount,
                                  },
                                  node: {
                                    name: e?.nodeName,
                                    image: e?.nodeImage,
                                  },
                                  time: {
                                    timeGreen:
                                      moment(sortedJunction[0]?.end)
                                        .diff(
                                          sortedJunction[0]?.start,
                                          "minutes"
                                        )
                                        .toFixed(0) + "dk",
                                  },
                                  onPressNode: () => {
                                    navigate(
                                      `/app/machine/digital-twin/equipment-details/${
                                        e?.deviceId
                                      }-${e?.nodeId}/${moment(
                                        sortedJunction[0]?.start
                                      ).format("YYYY-MM-DD")}`
                                    );
                                  },
                                  // onPressProduct: (e) => {
                                  //   // e.stopPropagation();
                                  //   navigate(
                                  //     `/app/product/order/detail/${item?.header?.id}+${item?.job?.id}`
                                  //   );
                                  // },
                                  avatarPress: () => {},
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <div
                            onClick={() => {
                              setJunctionType("internal");
                              setFinishJunctionId(e?.activeJunction?.id);
                              setIsOpenJunctionPiece(true);
                            }}
                            className="cursor-pointer w-full"
                          >
                            <Junction
                              props={{
                                time: moment(
                                  e?.activeJunction?.startDate
                                )?.format("HH:mm"),
                                ring: "#17B26A",
                                node: {
                                  image: e?.nodeImage,
                                  name: e?.nodeName,
                                  onClick: () => {
                                    navigate(
                                      `/app/machine/digital-twin/equipment-details/${
                                        e?.deviceId
                                      }-${e?.nodeId}/${moment(date).format(
                                        "YYYY-MM-DD"
                                      )}`
                                    );
                                  },
                                },
                                product: {
                                  name: e?.activeJunction?.job?.product?.name,
                                  image: e?.activeJunction?.job?.product?.image,
                                  code: e?.activeJunction?.job?.product?.code,
                                  piece: e?.activeJunction?.job?.piece,
                                  id: e?.activeJunction?.job?.product?.id,
                                },
                                user: {
                                  image: e?.activeJunction?.user?.avatar,
                                  id: e?.activeJunction?.user?.id,
                                  name: e?.activeJunction?.user?.name,
                                  lastName: e?.activeJunction?.user?.lastName,
                                },
                                avatarPress: () => {
                                  navigate(
                                    `/app/machine/digital-twin/user-detail/${
                                      e?.activeJunction?.user?.id
                                    }/${moment(date).format("YYYY-MM-DD")}`
                                  );
                                },
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </>
          </div>
          <div className="w-full flex flex-col relative pr-3 ml-3">
            {data?.data === undefined ? (
              <div className="flex w-full items-center justify-center mt-5">
                <span className="mr-[264px]">
                  <NoData
                    theme={"machine"}
                    header={t("label:noData")}
                    button={false}
                  />
                </span>
              </div>
            ) : (
              <>
                <svg width={svgWidth} height={svgHeight}>
                  {shiftValues?.map((shift, index) => {
                    const { startDate, endDate, color } = shift;
                    const shiftStartTime = new Date(
                      timeFormatter(startDate)?.formatted
                    )?.getTime();
                    const shiftEndTime = new Date(
                      timeFormatter(endDate)?.formatted
                    )?.getTime();

                    if (selectedShift !== null && selectedShift !== index) {
                      return null;
                    }

                    const timeRange = endTime - startTime;

                    const shiftX =
                      selectedShift === index
                        ? 0
                        : ((shiftStartTime - startTime) / timeRange) * svgWidth;
                    const shiftWidth =
                      selectedShift === index
                        ? svgWidth
                        : ((shiftEndTime - shiftStartTime) / timeRange) *
                          svgWidth;

                    return (
                      <>
                        <SingleEventRect
                          key={index}
                          startPos={shiftX}
                          width={shiftWidth}
                          fill={color}
                          onClick={() => handleShiftClick(shift)}
                          style={{ cursor: "pointer" }}
                          event={shift}
                          svgHeight={svgHeight}
                          handleMouseOut={handleMouseOut}
                        />

                        {shiftWidth > 15 && (
                          <>
                            <rect
                              key={index}
                              x={shiftX}
                              y={30}
                              width={shiftWidth}
                              height={svgHeight}
                              fill={
                                shift?.color == "#FFFFFF"
                                  ? "#F2F4F7"
                                  : shift?.color
                              }
                              opacity={0.2}
                              onClick={() => handleShiftClick(shift)}
                              // onMouseOver={() => handleMouseOver(event, startPos)}
                              // onMouseOut={handleMouseOut}
                              stroke={shift?.color}
                              strokeWidth={1}
                              style={{ cursor: "pointer" }}
                              ry={"4"}
                            ></rect>
                          </>
                        )}
                      </>
                    );
                  })}

                  {/* Status and junctions */}
                  {data?.data?.map((e, i) => {
                    return (
                      <OperationNodeTimelines
                        showTooltip={showTooltip}
                        setShowTooltip={setShowTooltip}
                        data={e?.timelineData}
                        endTime={endTime}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                        selectedShift={selectedShift}
                        setSelectedShift={setSelectedShift}
                        svgWidth={svgWidth}
                        junctionNumber={junctionNumber}
                        handleMouseMove={handleMouseMove}
                        handleMouseOut={handleMouseOut}
                        // handleMouseOver={handleMouseOver}
                        handleShiftClick={handleShiftClick}
                        selectedJunction={selectedJunction}
                        setSelectedJunction={setSelectedJunction}
                        yOffset={(i + 1) * 90}
                      />
                    );
                  })}
                </svg>
                {showTooltip && showTooltip?.index?.index == 0 ? (
                  <div
                    key={showTooltip?.index?.startDate}
                    style={{
                      right: totalWidthh - mouseX < 300 ? "62%" : "5%",
                      top: showTooltip.yPos - 80,
                    }}
                    className={`absolute z-50 bg-white p-2 rounded-lg shadow-md `}
                  >
                    {moment(pageLoadTime).format("YYYY-MM-DDTHH:mm") ===
                    moment(showTooltip.junction.endDate)
                      .add(3, "hours")
                      .format("YYYY-MM-DDTHH:mm") ? (
                      <div className="flex min-w-[400px] max-w-[400px] bg-red-500">
                        <NewActiveAndPlannedCard
                          id={showTooltip?.junction?.junctionId}
                          type={showTooltip?.junction?.junctionType}
                          // status={activeJunction?.status}
                          name={showTooltip?.junction?.productData}
                          image={showTooltip?.junction?.productImage}
                          user={{
                            avatar: showTooltip?.junction?.avatar,
                            name: showTooltip?.junction?.name,
                          }}
                          piece={showTooltip?.junction?.acceptedWorkCount}
                          estimatedTime={secondToHoursAndMinute2(
                            showTooltip?.junction?.startDate,
                            showTooltip?.junction?.endDate
                          )}
                          targetMin={"--"}
                          oneOrderTime={"--"}
                        />
                      </div>
                    ) : showTooltip?.index?.junctionType === "losses" ? (
                      <LossesCardType
                        title={
                          showTooltip?.index?.lossesName || "Undefined Loss"
                        }
                        subTitle={
                          showTooltip?.index?.reasonDescription || "Unkown"
                        }
                        startTime={moment(
                          timeFormatter(showTooltip?.index?.startDate).formatted
                        ).format("HH:mm")}
                        endTime={moment(
                          timeFormatter(showTooltip?.index?.endDate).formatted
                        ).format("HH:mm")}
                        id={showTooltip?.index?.id}
                      />
                    ) : (
                      <>
                        <NewApprovedCard
                          key={showTooltip.junction?.id}
                          id={showTooltip.junction?.id}
                          equipmentName={showTooltip.junction?.productData}
                          equipmentImage={showTooltip.junction?.productImage}
                          qualityAcceptedPiece={
                            showTooltip.junction?.acceptedWorkCount
                          }
                          qualityRejectedPiece={
                            showTooltip.junction?.rejectedWorkCount
                          }
                          oneOrderTime={formatSeconds(
                            (
                              moment(showTooltip.junction?.endDate).diff(
                                showTooltip.junction?.startDate,
                                "seconds"
                              ) /
                                showTooltip.junction?.metadata?.madeWorkCount ||
                              0
                            ).toFixed(0)
                          )}
                          user={{
                            avatar: showTooltip.junction?.avatar,
                            name: showTooltip?.junction?.name,
                          }}
                          status={showTooltip.junction?.status}
                          piece={showTooltip.junction?.acceptedWorkCount}
                          totalJunctionTime={moment(
                            timeFormatter(showTooltip?.junction?.startDate)
                              .formatted
                          ).format("HH:mm")}
                          totalEndTime={moment(
                            timeFormatter(showTooltip?.junction?.endDate)
                              .formatted
                          ).format("HH:mm")}
                          qualityPercent={
                            showTooltip.junction?.metadata?.qualityPercent
                          }
                          // totalJunctionTime={secondToHoursAndMinute2(
                          //   showTooltip.junction?.startDate,
                          //   showTooltip.junction?.endDate
                          // )}
                          estimatedTrue={
                            (
                              moment(showTooltip.junction?.endDate).diff(
                                showTooltip.junction?.startDate,
                                "seconds"
                              ) / showTooltip.junction?.metadata?.madeWorkCount
                            ).toFixed(0) <
                            showTooltip.junction?.operations?.internalOperation
                              ?.estimatedTime
                              ? true
                              : false
                          }
                          type={showTooltip?.junction?.junctionType}
                        />
                        {/* <NewCompletedAndWorkingCard
                        id={showTooltip?.id}
                        type={showTooltip?.type}
                        showTooltip={showTooltip}
                        status={showTooltip?.metadata?.qualityStatus}
                        image={showTooltip?.station?.metadata?.image}
                        targetMin={operation?.estimatedTime}
                        totalJunctionTime={moment(showTooltip?.startDate).format(
                          "HH:mm"
                        )}
                        totalEndTime={moment(showTooltip.endDate).format("HH:mm")}
                        // estimatedOperationTime={operation?.estimatedTime * 60}
                        startDate={showTooltip?.startDate}
                        junctionData={showTooltip}
                        // oneOrderTime={formatSeconds(
                        //   oneOrderTime(
                        //     item?.startDate,
                        //     item?.endDate,
                        //     item?.metadata?.madeWorkCount
                        //   )
                        // )}
                        name={`${showTooltip?.station?.metadata?.brand} ${showTooltip?.station?.metadata?.model}`}
                        // estimatedTime={oneOrderTime(
                        //   item?.startDate,
                        //   item?.endDate,
                        //   item?.metadata?.madeWorkCount
                        // )}
                        piece={showTooltip?.madeWorkCount}
                        user={showTooltip?.user}
                        qualityPercent={showTooltip?.metadata?.qualityPercent}
                        // onClick={() => {
                        //   setJunctionId(item?.id);
                        //   setIsOpenForm(true);
                        // }}
                        // openCard={() => {
                        //   navigate(
                        //     `/app/machine/digital-twin/equipment-details/${
                        //       item?.station?.deviceId
                        //     }-${item?.station?.id}/${moment(
                        //       item?.startDate
                        //     ).format("YYYY-MM-DD")}`
                        //   );
                        // }}
                      /> */}
                      </>
                    )}
                  </div>
                ) : showTooltip && showTooltip?.index?.index == 1 ? (
                  <div
                    key={showTooltip?.index?.startDate}
                    className={`absolute z-50 bg-white p-2  rounded-lg shadow-md`}
                    style={{
                      left:
                        (showTooltip?.xPos + 30) * 2 > totalWidthh
                          ? showTooltip?.xPos - 280
                          : showTooltip?.xPos + 40,
                      top: showTooltip?.yPos - 90,
                      borderTop: "5px solid " + showTooltip?.index?.color,
                    }}
                  >
                    <TimeLineTooltip showTooltip={showTooltip} />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={isOpenJunctionPiece}
        setIsOpen={setIsOpenJunctionPiece}
        onClose={() => {
          setIsOpenJunctionPiece(false);
        }}
        modalTitle={t("product:finishJuction")}
        width="fit-content"
        children={
          <FinishJunctionModal
            t={t}
            setIsOpen={setIsOpenJunctionPiece}
            isOpen={isOpenJunctionPiece}
            // handleData={refetch}
            junctionId={finishJunctionId}
            type={junctionType}
            closeModal={() => {
              setIsOpenJunctionPiece(false);
            }}
          />
        }
      />
    </>
  );
};

export default OperationUpuTimeline;
