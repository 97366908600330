import React from "react";
import MonthlyChart from "./MonthlyChart";
import moment from "moment";
import { timeFormatter } from "../../../../utils/timezoneFormatter";

const MothlyDetailChart = ({ data, currentDay, setDate }) => {
  return (
    <div className="w-full h-[300px] border border-secondary-200 rounded-lg shadow-xs">
      <MonthlyChart
        data={data}
        start={moment(currentDay).format("YYYY/MM/DD")}
        end={moment(data?.endDate).format("YYYY/MM/DD")}
        setDate={setDate}
      />
    </div>
  );
};

export default MothlyDetailChart;
