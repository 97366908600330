import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { createRef } from "react";
import Button from "../buttons/Button";
import { useTranslation } from "react-i18next";

const Preview = ({ file, setCropData, setIsCrop, setCroppedImage }) => {
  const {t} = useTranslation()
  const cropperRef = createRef();
  const previewCanvasRef = useRef(null);

  const [completedCrop, setCompletedCrop] = useState(null);

  const handleCropChange = () => {
    const croppedImgData = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    setCroppedImage(croppedImgData);
    setCropData(croppedImgData);
  };

  // {imageBinary}

  return (
    <div className="w-full h-full mt-[24px] flex flex-col gap-y-6 items-center justify-center">
      <div className="flex w-full h-full">
        <div className="flex">
          {file && (
            <Cropper
              style={{ maxWidth: "100%", width: "100%", height: "70%" }}
              ref={cropperRef}
              src={file}
              // aspectRatio={4 / 3}
              cropend={() => handleCropChange()}
              zoomable={false}
            />
          )}
        </div>
      </div>

      <div className="flex w-full">
        <Button
          label={t("buttons:save")}
          colorType={"primary-product"}
          size={"lg"}
          onClick={() => {
            setIsCrop(false);
          }}
        />
      </div>
    </div>
  );
};

export default Preview;
