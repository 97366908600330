export const KwIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M19.6661 2.66666H11.992C11.7527 2.66666 11.633 2.66666 11.5274 2.7031C11.4339 2.73532 11.3489 2.78791 11.2783 2.85705C11.1984 2.93524 11.1449 3.04226 11.0379 3.2563L5.4379 14.4563C5.18233 14.9674 5.05454 15.223 5.08524 15.4308C5.11204 15.6122 5.21238 15.7745 5.36265 15.8796C5.53474 16 5.82048 16 6.39196 16H14.6661L10.6661 29.3333L26.9235 12.4737C27.472 11.9049 27.7463 11.6205 27.7623 11.3772C27.7762 11.1659 27.689 10.9606 27.5273 10.824C27.341 10.6667 26.9459 10.6667 26.1557 10.6667H16.6661L19.6661 2.66666Z"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LossTimeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 30 30"
      fill="none"
    >
      <mask
        id="mask0_1090_737"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <path
          d="M0.333313 0.333332H29.6666V29.6667H0.333313V0.333332Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1090_737)">
        <path
          d="M7.28033 1.19266V4.51071C7.28033 11.2899 14.9999 15 14.9999 15C14.9999 15 22.7195 11.2899 22.7195 4.51071V1.19266H7.28033Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9999 15C14.9999 15 7.28033 18.71 7.28033 25.4892V28.8072H22.7195V25.4892C22.7195 18.71 14.9999 15 14.9999 15Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.2803 1.19266H5.20233"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7194 1.19266H24.7974"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.28036 28.8072H5.20239"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7194 28.8072H24.7974"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.12799 8.30318H21.7825"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7591 25.1001L7.28033 28.8072H22.6302L19.1514 25.1001C16.8783 22.6779 13.0322 22.6779 10.7591 25.1001Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const CostTIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
    >
      <path
        d="M32.0625 22.6875C32.0625 27.8652 27.8652 32.0625 22.6875 32.0625C17.5098 32.0625 13.3125 27.8652 13.3125 22.6875C13.3125 17.5098 17.5098 13.3125 22.6875 13.3125C27.8652 13.3125 32.0625 17.5098 32.0625 22.6875Z"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8125 6.625C20.8125 9.21381 16.6152 11.3125 11.4375 11.3125C6.25981 11.3125 1.9375 9.21381 1.9375 6.625C1.9375 4.03619 6.25981 1.9375 11.4375 1.9375C16.6152 1.9375 20.8125 4.03619 20.8125 6.625Z"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.5719 16.5832C14.3247 16.8901 12.9214 17.0625 11.4375 17.0625C6.25981 17.0625 1.9375 14.9639 1.9375 12.375"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.9375 6.625V23.625C1.9375 26.2138 6.25981 28.3125 11.4375 28.3125C12.6772 28.3125 13.8607 28.1922 14.9439 27.9736"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8125 6.62494V13.5"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.313 22.5937C12.707 22.6552 12.0798 22.6875 11.4375 22.6875C6.25981 22.6875 1.9375 20.5888 1.9375 18"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.4667 19.1333H24.8589"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.672 26.1607L22.672 19.1333"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const Cost3TIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
    >
      <path
        d="M32.0625 22.6875C32.0625 27.8652 27.8652 32.0625 22.6875 32.0625C17.5098 32.0625 13.3125 27.8652 13.3125 22.6875C13.3125 17.5098 17.5098 13.3125 22.6875 13.3125C27.8652 13.3125 32.0625 17.5098 32.0625 22.6875Z"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8125 6.625C20.8125 9.21381 16.6152 11.3125 11.4375 11.3125C6.25981 11.3125 1.9375 9.21381 1.9375 6.625C1.9375 4.03619 6.25981 1.9375 11.4375 1.9375C16.6152 1.9375 20.8125 4.03619 20.8125 6.625Z"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.5719 16.5832C14.3248 16.8901 12.9214 17.0625 11.4375 17.0625C6.25984 17.0625 1.93753 14.9639 1.93753 12.375"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.9375 6.625V23.625C1.9375 26.2138 6.25981 28.3125 11.4375 28.3125C12.6772 28.3125 13.8607 28.1922 14.9439 27.9736"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8125 6.62494V13.5"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.313 22.5937C12.707 22.6552 12.0798 22.6875 11.4375 22.6875C6.25981 22.6875 1.9375 20.5888 1.9375 18"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.9412 19.1334H28.3333"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.1465 26.1608L26.1465 19.1333"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.3663 20.5496C18.5249 19.7423 19.2364 19.1333 20.0902 19.1333C21.0605 19.1333 21.8471 19.9199 21.8471 20.8902C21.8471 21.8604 21.0605 22.647 20.0902 22.647"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0902 22.647C21.0604 22.647 21.847 23.4336 21.847 24.4039C21.847 25.3742 21.0604 26.1608 20.0902 26.1608C19.203 26.1608 18.4695 25.5032 18.3503 24.649C18.3391 24.5689 18.3333 24.487 18.3333 24.4039"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CostLossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask
        id="mask0_1090_209"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path d="M0 1.90735e-06H32V32H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1090_209)">
        <path
          d="M12.0625 17.6875V22.8682H7.625L16 31.0557L24.375 22.8682H19.9375V17.75"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="22.926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 18.4307C20.8156 18.4307 24.7432 14.5031 24.7432 9.68747C24.7432 4.87197 20.8156 0.944349 16 0.944349C11.1845 0.944349 7.25684 4.87197 7.25684 9.68747C7.25684 14.5031 11.1845 18.4307 16 18.4307Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="22.926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.624 9.54096C16.8173 9.96377 18.1692 10.2896 18.1566 11.6955C18.1341 14.2077 13.9115 13.9276 13.9115 11.7033"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="22.926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.7466 9.58723C14.7535 9.23648 13.8331 8.81205 13.8435 7.65098C13.8659 5.13867 18.0886 5.41886 18.0886 7.64311"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="22.926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0583 13.5341V14.992"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="22.926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9417 4.38294V5.84082"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="22.926"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const CCountIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask
        id="mask0_1090_776"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path d="M0 1.90735e-06H32V32H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1090_776)">
        <path
          d="M19.4583 25.797C19.569 26.1722 19.6428 26.5549 19.6603 26.9488C19.7662 29.318 17.984 31.2147 15.5138 31.0528C14.0552 30.9572 12.475 30.3942 11.4361 29.3645C9.15895 27.1071 8.98277 22.6967 8.85208 19.6881C8.67027 15.5024 9.0372 11.6902 11.6047 9.63754C14.5391 7.2916 19.2607 6.55054 22.5051 8.20573C23.8731 8.90373 24.5667 10.3522 24.2271 11.8446C23.7304 14.0275 21.6783 15.28 19.9491 16.4912C18.4961 17.5089 17.2536 18.85 17.448 20.7094C17.6405 22.5506 18.943 24.0515 19.4583 25.797Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.375 3.5625C27.375 4.94325 26.2557 6.0625 24.875 6.0625C23.4942 6.0625 22.375 4.94325 22.375 3.5625C22.375 2.18175 23.4942 1.0625 24.875 1.0625C26.2557 1.0625 27.375 2.18175 27.375 3.5625Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.6875 2.8125C19.6875 3.848 18.848 4.6875 17.8125 4.6875C16.777 4.6875 15.9375 3.848 15.9375 2.8125C15.9375 1.777 16.777 0.937496 17.8125 0.937496C18.848 0.937496 19.6875 1.777 19.6875 2.8125Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.375 4.375C13.375 5.4105 12.5355 6.25 11.5 6.25C10.4645 6.25 9.625 5.4105 9.625 4.375C9.625 3.3395 10.4645 2.5 11.5 2.5C12.5355 2.5 13.375 3.3395 13.375 4.375Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.375 8.4375C8.375 9.473 7.5355 10.3125 6.5 10.3125C5.4645 10.3125 4.625 9.473 4.625 8.4375C4.625 7.402 5.4645 6.5625 6.5 6.5625C7.5355 6.5625 8.375 7.402 8.375 8.4375Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const KwhIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 29"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64456 0.33386L8.59411 0.333626C8.41801 0.332446 8.12772 0.330502 7.85389 0.424951C7.62035 0.505505 7.40764 0.636969 7.23115 0.809829C7.02421 1.01251 6.89613 1.27302 6.81843 1.43106L6.79608 1.47628L1.19608 12.6763L1.18002 12.7084L1.18002 12.7084C1.06622 12.9359 0.952382 13.1635 0.875356 13.3607C0.795231 13.5658 0.694756 13.8798 0.748579 14.2441C0.815584 14.6976 1.06644 15.1035 1.44211 15.3663C1.74387 15.5773 2.06968 15.6279 2.28899 15.648C2.49982 15.6673 2.75429 15.6672 3.00866 15.6672L3.04456 15.6672H9.97463L6.36083 27.7132C6.22822 28.1552 6.41534 28.6306 6.8137 28.8637C7.21205 29.0967 7.71815 29.0269 8.0385 28.6947L11.4587 25.1478C11.2048 24.3686 11.0673 23.5368 11.0667 22.673L9.46156 24.3376L12.2765 14.9545C12.3673 14.6518 12.3096 14.3239 12.1209 14.0703C11.9323 13.8167 11.6348 13.6672 11.3187 13.6672H3.04456L2.93675 13.6672L2.98493 13.5707L8.58493 2.37071L8.60336 2.33387L8.64456 2.33386H14.8757L12.3823 8.98274C12.2672 9.28983 12.3099 9.63388 12.4967 9.90345C12.6835 10.173 12.9907 10.3339 13.3187 10.3339H22.8083L22.965 10.3339L22.8563 10.4468L18.7818 14.6722C18.8764 14.6689 18.9713 14.6672 19.0667 14.6672C19.8305 14.6672 20.5693 14.7742 21.269 14.9742L24.296 11.8351L24.3243 11.8057C24.574 11.5467 24.8063 11.306 24.9738 11.0981C25.1286 10.906 25.3828 10.5639 25.4127 10.1101C25.4475 9.58205 25.2295 9.06881 24.8252 8.7273C24.4777 8.43382 24.055 8.37936 23.8093 8.35748C23.5434 8.33379 23.2089 8.33382 22.8492 8.33386H22.8491L22.8083 8.33386H14.7617L17.255 1.68498C17.3701 1.37789 17.3274 1.03384 17.1406 0.764265C16.9538 0.49469 16.6466 0.33386 16.3187 0.33386H8.64456ZM2.4238 13.6501C2.42387 13.65 2.42575 13.6504 2.42923 13.6512C2.42546 13.6505 2.42372 13.6501 2.4238 13.6501ZM2.72406 14.1282C2.72324 14.1317 2.72269 14.1335 2.72262 14.1336C2.72254 14.1336 2.72296 14.1319 2.72406 14.1282Z"
        fill="#475467"
      />
      <path
        d="M19.0667 20.6672V22.6672L21.2001 23.7338M24.4001 22.6672C24.4001 25.6127 22.0123 28.0005 19.0667 28.0005C16.1212 28.0005 13.7334 25.6127 13.7334 22.6672C13.7334 19.7217 16.1212 17.3338 19.0667 17.3338C22.0123 17.3338 24.4001 19.7217 24.4001 22.6672Z"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TableArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16663 9.99984H15.8333M15.8333 9.99984L9.99996 4.1665M15.8333 9.99984L9.99996 15.8332"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LossFactoryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g mask="url(#mask0_7302_1687)">
        <path
          d="M31.0625 28.25H0.9375V17.5L15.0625 11.875V17.5L31.0625 11.875V28.25Z"
          stroke="#475467"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M14.125 28.25V24.5H17.875V28.25"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.625 28.25V24.5H10.375V28.25"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.625 28.25V24.5H25.375V28.25"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.6875 15.6084V3.75002H11.3125V13.3683"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6875 15.5225V3.75002H26.3125V13.5449"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.6875 7.5H11.3125"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6875 7.5H26.3125"
        stroke="#475467"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
