import { useQuery } from "@tanstack/react-query";
import React, { memo, useEffect, useRef, useState } from "react";
import { chatService } from "../../../services/chat.service";
import TaskCard from "./TaskCard";
import TaskFilterBar from "./TaskFilterBar";
import { Button, CustomModal } from "../../../components";
import { PlusIcon, SearchIcon } from "../../../assets/icons/stepsIcons";
import TaskDropDownButton from "./TaskDropDownButton";
import AddTaskCard from "./AddTaskCard";
import Skeleton from "react-loading-skeleton";
import MyTaskList from "./MyTaskList";
import { authStore } from "../../../stores/auth.store";
import { CloseIcon } from "../../../assets/icons/departmentIcon";
import SearchInput from "../../../components/inputs/SearchInput";
import { useTranslation } from "react-i18next";
import TaskSortButton from "./TaskSortButton";
import moment from "moment";
import { useStore } from "../../../hooks/useStores";
import { InfoIcon } from "../../../assets/icons/PersonIcons";
import diacritics from "diacritics";

const TaskList = ({ groupId, selectedUser, setSelectedUser }) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [value, setValue] = useState("all");
  const [addTask, setAddTask] = useState(false);
  const [isMyTasks, setIsMyTasks] = useState("all");
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [labelDepartment, setLabelDepartment] = useState("");
  const [sortValue, setSortValue] = useState(null);
  const { data, refetch, isLoading, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getTaskList"],
    queryFn: async () => await chatService.getTaskGroupDetails(groupId),
  });

  const containerRef = useRef(null);

  const handleLabels = async (id) => {
    const datas = await chatService.getLabelList(id);
    setLabelDepartment(datas);
  };

  useEffect(() => {
    if (!isLoading) {
      handleLabels(data?.departmentId);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [groupId]);

  useEffect(() => {
    if (data && data?.tasks) {
      const filtered = data.tasks.filter((task) =>
        diacritics
          .remove(task?.name.toLowerCase())
          .includes(diacritics.remove(searchTerm.toLowerCase()))
      );
      setFilteredTasks(filtered);
    }
  }, [data, searchTerm]);

  useEffect(() => {
    if (addTask && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [addTask]);

  return (
    <>
      <div className="grow w-full px-5 pt-5 border-l border-secondary-200 flex flex-col overflow-hidden">
        <div className="w-full flex items-center">
          <p className="text-lg font-semibold text-secondary-900">
            {t("tasks:tasks")}
          </p>
          <div className="ml-auto">
            {searchOpen === false ? (
              <Button
                colorType={"tertiary-gray"}
                size={"sm"}
                onClick={
                  searchOpen === true
                    ? () => setSearchOpen(false)
                    : () => setSearchOpen(true)
                }
                iconLeft={<SearchIcon />}
              />
            ) : (
              <Button
                colorType={"tertiary-gray"}
                size={"sm"}
                onClick={
                  searchOpen === true
                    ? () => setSearchOpen(false)
                    : () => setSearchOpen(true)
                }
                iconLeft={<CloseIcon />}
              />
            )}
          </div>
        </div>

        <div className="my-5 w-full">
          {isLoading || isFetching ? (
            <Skeleton height={44} />
          ) : (
            <TaskFilterBar
              value={value}
              setValue={setValue}
              done={data?.doneLength}
              todo={data?.todoLength}
              total={data?.totalLength}
              expired={data?.expiredLength}
              approved={data?.approvedLength}
            />
          )}
        </div>
        {searchOpen && (
          <div className="w-full h-10 mb-5">
            <SearchInput
              setSearchVal={setSearchTerm}
              placeholder={t("chat:search")}
            />
          </div>
        )}
        <div className="flex items-center justify-between gap-3 mb-5 w-full">
          {isLoading || isFetching ? (
            <Skeleton containerClassName="w-full" height={34} />
          ) : (
            <>
              <Button
                colorType={"secondary-gray"}
                label={t("person:createNewTask")}
                iconLeft={<PlusIcon />}
                size={"sm"}
                onClick={() => setAddTask(!addTask)}
              />
              <div className="min-w-[142px]">
                <TaskDropDownButton
                  value={isMyTasks}
                  setValue={setIsMyTasks}
                  page={"taskList"}
                  taskPage={true}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              </div>
              <TaskSortButton
                data={data}
                setFilteredTasks={setFilteredTasks}
                setSortValue={setSortValue}
                sortValue={sortValue}
              />
            </>
          )}
        </div>

        <div
          className="flex-1 w-full flex flex-col overflow-x-visible overflow-y-scroll scrollbar-hide gap-y-3 pb-5"
          ref={containerRef}
        >
          <>
            {addTask && (
              <div className="mb-5">
                <div className="flex items-center justify-between pb-2 w-full">
                  <div className="flex items-center justify-center gap-x-1 ">
                    <InfoIcon />
                    <p className="text-sm text-secondary-500 font-normal ">
                      {t("person:totalTaskLimit")}
                    </p>
                  </div>
                  <div className="text-sm text-secondary-500 font-normal">
                    {data?.totalTaskLengthByCompany +
                      "/" +
                      auth?.user?.company?.limits?.task}
                  </div>
                </div>
                <AddTaskCard
                  refetch={refetch}
                  groupId={data?.id}
                  setAddTask={setAddTask}
                  // departmentLabels={data.labels}
                  departmentId={data?.departmentId}
                  groupParticipants={data?.participants}
                />
              </div>
            )}
            {isLoading || isFetching
              ? [...new Array(8)].map((_) => (
                  <Skeleton
                    height={176}
                    containerClassName="w-full"
                    className="rounded-lg"
                  />
                ))
              : filteredTasks
                  .sort((a, b) => b?.isStar - a?.isStar)
                  .map((item, index) => {
                    if (isMyTasks !== "all") {
                      if (
                        item.participants
                          .filter((f) => {
                            return f?.id;
                          })
                          .find((d) => d)?.id === authStore?.user?.id
                      ) {
                        if (value === item.status) {
                          return (
                            <TaskCard
                              key={index}
                              index={index}
                              status={item?.status}
                              point={item?.upuPoint}
                              taskId={item?._id}
                              date={item?.endDate}
                              creator={item?.creator}
                              isStar={item?.isStar}
                              task={item?.name}
                              number={item?.rowNumber}
                              attachment={item?.attachment}
                              participants={item?.participants}
                              comments={item?.comments}
                              setSortValue={setSortValue}
                              groupDataId={data?.id}
                              departmentLabels={labelDepartment?.data}
                              departmentId={data?.departmentId}
                              labels={item?.label}
                              groupConversationId={groupId}
                              refetch={refetch}
                              groupParticipants={data?.participants}
                            />
                          );
                        } else if (value === "all") {
                          return (
                            <TaskCard
                              key={index}
                              index={index}
                              status={item?.status}
                              point={item?.upuPoint}
                              taskId={item?._id}
                              date={item?.endDate}
                              creator={item?.creator}
                              isStar={item?.isStar}
                              task={item?.name}
                              number={item?.rowNumber}
                              attachment={item?.attachment}
                              departmentId={data?.departmentId}
                              participants={item?.participants}
                              comments={item?.comments}
                              departmentLabels={labelDepartment?.data}
                              labels={item?.label}
                              groupDataId={data?.id}
                              setSortValue={setSortValue}
                              groupConversationId={groupId}
                              refetch={refetch}
                              groupParticipants={data?.participants}
                            />
                          );
                        }
                      }
                    } else {
                      if (value === item.status) {
                        return (
                          <TaskCard
                            key={index}
                            index={index}
                            status={item?.status}
                            point={item?.upuPoint}
                            taskId={item?._id}
                            date={item?.endDate}
                            creator={item?.creator}
                            isStar={item?.isStar}
                            task={item?.name}
                            groupDataId={data?.id}
                            number={item?.rowNumber}
                            attachment={item?.attachment}
                            participants={item?.participants}
                            comments={item?.comments}
                            departmentId={data?.departmentId}
                            setSortValue={setSortValue}
                            departmentLabels={labelDepartment?.data}
                            labels={item?.label}
                            groupConversationId={groupId}
                            refetch={refetch}
                            groupParticipants={data?.participants}
                          />
                        );
                      } else if (value === "all") {
                        return (
                          <TaskCard
                            key={index}
                            index={index}
                            status={item?.status}
                            point={item?.upuPoint}
                            taskId={item?._id}
                            date={item?.endDate}
                            creator={item?.creator}
                            isStar={item?.isStar}
                            task={item?.name}
                            number={item?.rowNumber}
                            attachment={item?.attachment}
                            groupDataId={data?.id}
                            departmentId={data?.departmentId}
                            participants={item?.participants}
                            setSortValue={setSortValue}
                            comments={item?.comments}
                            departmentLabels={labelDepartment?.data}
                            labels={item?.label}
                            groupConversationId={groupId}
                            refetch={refetch}
                            groupParticipants={data?.participants}
                          />
                        );
                      }
                    }
                  })}
          </>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default memo(TaskList);
