import moment from "moment";
import React from "react";
import { StockIcon } from "../../../../assets/icons/leftMenuIcons";
import {
  ArrowSquareRightTable,
  ClockIconTable,
  ClockStartIconTable,
  FileTypeIconTable,
  OeeIconTable,
  OrderIconTable,
  PointIconTable,
  QuantityAcceptedIcon,
  QuantityIconTable,
  RejectedIconTable,
} from "../../../../assets/icons/equipmentIcon";
import generateFileUrl from "../../../../utils/generateFileUrl";
import { Button } from "../../../../components";
import { DownloadIcon } from "../../../../assets/icons/buttonIcons";
import { legacyFormatSeconds } from "../../../../utils/secondToHoursAndMinute";
import { useTranslation } from "react-i18next";

export default function SelectJunctionTable({
  selectedJunctionData,
  selectedJunction,
  user,
}) {
  const { t } = useTranslation();
  const table = [
    [
      <TableContent
        title={t("personals:personal")}
        content={
          selectedJunction?.user?.name + " " + selectedJunction?.user?.lastName
        }
        userImage={selectedJunction?.user?.avatar}
      />,
      <TableContent
        title={t("product:stockNo")}
        content={selectedJunction?.job?.product?.stockCode}
        icon={<StockIcon />}
      />,
      <TableContent
        title={t("personals:startEndTime")}
        content={
          moment(selectedJunction?.startDate).format("HH:mm") +
          " - " +
          moment(selectedJunction?.endDate).format("HH:mm")
        }
        icon={<ArrowSquareRightTable />}
      />,
      <TableContent
        title={t("product:estimatedTimes")}
        content={selectedJunction?.metadata?.estimatedTime || "--" + " sn"}
        icon={<ClockIconTable />}
      />,
      <TableContent
        title={t("product:earnedPoints")}
        content={selectedJunction?.metadata?.upuPoint?.toFixed(2) || "--"}
        icon={<PointIconTable />}
      />,
    ],
    [
      <TableContent
        title={t("product:productName")}
        content={selectedJunction?.job?.product?.name}
        productImage={"45e38e0e-151b-4020-a24c-4248eead77cb"}
      />,
      <TableContent
        title={t("product:orderNo")}
        content={selectedJunction?.job?.orderNo}
        icon={<OrderIconTable />}
      />,
      <TableContent
        title={t("product:totalWorkTime")}
        content={
          moment(selectedJunction?.endDate).diff(
            moment(selectedJunction?.startDate),
            "minutes"
          ) + " dk"
        }
        icon={<QuantityIconTable />}
      />,
      <TableContent
        title={t("product:averageActual")}
        content={
          legacyFormatSeconds(
            Math.floor(selectedJunction?.metadata?.avgActualTime)
          ) + " dk"
        }
        icon={<ClockStartIconTable />}
      />,
      <TableContent
        title={"Oee"}
        content={selectedJunction?.metadata?.oeePercent || "--"}
        icon={<OeeIconTable />}
      />,
    ],
    [
      <TableContent
        title={t("product:quantityMade")}
        content={selectedJunction?.metadata?.madeWorkCount || "--"}
        icon={<QuantityAcceptedIcon />}
      />,
      <TableContent
        title={t("product:approvedQuantity")}
        content={selectedJunction?.metadata?.acceptedWorkCount || "--"}
        icon={<QuantityAcceptedIcon />}
      />,
      <TableContent
        title={t("product:rejectedQuantitys")}
        content={selectedJunction?.metadata?.rejectedWorkCount || "--"}
        icon={<RejectedIconTable />}
      />,
      <TableContent
        title={t("product:qualityControlResponsible")}
        content={
          selectedJunction?.responsibleUser?.name +
          " " +
          selectedJunction?.responsibleUser?.lastName
        }
        userImage={selectedJunction?.responsibleUser?.avatar}
      />,
      <TableContent
        title={t("product:qualityControlAttachment")}
        content={"????"}
        icon={<FileTypeIconTable />}
        download={true}
      />,
    ],
  ];
  return (
    <div
      style={{
        width: "96%",
      }}
      className="flex h-[170px] min-h-[170px] max-h-[170px] px-10 mt-4 ml-12"
    >
      {selectedJunction && (
        <div className="flex h-full w-full shadow-ring-machine rounded-lg">
          <table className="w-full shadow-sm h-full">
            <tbody>
              {table.map((rowData, rowIndex) => (
                <tr className="justify-center" key={rowIndex}>
                  {rowData.map((cellData, colIndex) => (
                    <td
                      className="border items-center justify-center border-[#D0D5DD]"
                      key={colIndex}
                    >
                      {cellData}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const TableContent = ({
  title,
  content,
  icon,
  userImage,
  productImage,
  download,
}) => {
  return (
    <div
      className={`flex items-center justify-start w-full h-full py-2 ${
        userImage ? "px-[20px]" : ""
      } ${productImage ? "px-[12px]" : ""} ${
        icon ? "gap-x-3 px-[24px]" : "gap-x-2"
      }`}
    >
      {icon && (
        <div className="flex items-center justify-center max-w-[24px] min-w-[24px] min-h-[24px] max-h-[24px]">
          {icon}
        </div>
      )}
      {userImage && (
        <img
          className="border-[0.5px] border-[#0000001A] rounded-full w-8 h-8 max-w-[32px] min-w-[32px] min-h-[32px] max-h-[32px]"
          src={generateFileUrl(userImage)}
        />
      )}
      {productImage && (
        <img
          className="border-[0.5px] w-10 h-[30px] min-w-[40px] max-w-[40px] min-h-[30px] max-h-[30px] rounded"
          src={generateFileUrl(productImage)}
        />
      )}
      <div className="flex flex-col items-start justify-center">
        <span className="text-xs text-[#475467] font-normal">{title}</span>
        <span className="text-sm text-[#344054] font-medium">{content}</span>
      </div>
      {download && (
        <div className="flex ml-auto">
          <Button
            colorType={"secondary-gray"}
            size={"sm"}
            iconLeft={<DownloadIcon />}
          />
        </div>
      )}
    </div>
  );
};
