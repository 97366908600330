import React from "react";
import { useTranslation } from "react-i18next";
import {
  ClockIcon,
  ClockIconStart,
  PlaySquareIcon,
  QuantityAcceptedIcon,
  QuantityDeclineIcon,
} from "../../../assets/icons/productIcons";
import { legacyFormatSeconds } from "../../../utils/secondToHoursAndMinute";

function AssemblyCard({ name, hours, hoursJunctionTime, operations }) {
  // const statusColor = () => {
  //     switch (status) {
  //       case "finished":
  //         return "#12B76A";
  //       case "started":
  //         return "#FDE172";
  //       case "rejected":
  //         return "#D92D20";
  //       default:
  //         return "#D0D5DD";
  //     }
  //   };

  //   const qualityStatus = () => {
  //     if (operations?.hasQualityControl) {
  //       return "#17B26A";
  //     } else {
  //       return "#98A2B3";
  //     }
  //   };
  const { t } = useTranslation();

  return (
    <div className="bg-white  flex w-full flex-col h-full cursor-pointer">
      <div
        style={{
          //   backgroundColor: statusColor(),
          backgroundColor: "#12B76A",
        }}
        className={`h-1 max-h-1 min-h-[4px]  rounded-t`}
      ></div>

      <div className="flex w-full h-full border-x justify-between">
        <div className="flex min-w-[12px] max-w-[12px] min-h-full bg-[#DC6803] flex-col gap-y-[10px] items-center">
          <span className="flex min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]">
            <PuzzleIcon />
          </span>
          <span className="flex min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]">
            <PuzzleIcon />
          </span>
          <span className="flex min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]">
            <PuzzleIcon />
          </span>
        </div>
        <div className="flex mt-1 mb-2 mr-1">
          <div className="flex flex-col w-[184px] h-full">
            <div className="w-full flex items-center overflow-hidden">
              <div className="w-6 h-6 min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px] p-[0.5px] flex items-center justify-center bg-[#DC6803] rounded">
                <PuzzleIcon />
              </div>
              <p className="text-sm text-secondary-700 font-semibold truncate ml-2">
                {name} Montage
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex border-b">
                <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">
                  {t("product:previousIncoming")}
                </div>
                <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {hours}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:hours")}
                  </span>
                </div>
                <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {operations?.previousPiece}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="min-w-[67px] border-r pr-[6px] py-1 text-xs font-normal text-[#475467]">
                  {t("product:applied")}
                </div>
                <div className="w-[48px] border-r px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {hoursJunctionTime}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:hours")}
                  </span>
                </div>
                <div className="w-[76px] px-[6px] py-1 flex gap-x-[2px] items-end">
                  <span className="font-medium text-xs text-[#475467]">
                    {operations?.qualityAcceptedPiece}
                  </span>
                  <span className="text-[10px] leading-[18px] font-normal text-[#667085]">
                    {t("product:pcs")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col border-[0.5px] w-[82px] h-[68px] px-[6px] py-[2px] gap-y-2 rounded">
            <div className="flex gap-x-1 items-center justify-center">
              <span
                className={`w-2 h-2 min-w-[8px] max-w-[8px] min-h-[8px] max-h-[8px] bg-[#FDE172] rounded-[2px]`}
              ></span>
              <p className="text-[#475467] font-normal text-xs">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col gap-y-[2px]">
              <div className="flex gap-x-[2px] items-center">
                <QuantityAcceptedIcon />
                <span className="text-xs text-[#079455]">
                  {operations?.hasQualityControl
                    ? operations?.qualityAcceptedPiece
                    : "--"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex gap-x-[2px] items-center">
                <QuantityDeclineIcon />
                <span className="text-xs text-[#D92D20]">
                  {operations?.hasQualityControl
                    ? operations?.qualityRejectedPiece
                    : "--"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div>

          {/* kalite yeni ui */}

          {/* <div className="flex flex-col border border-secondary-200 bg-white h-[72px]  w-[84px] min-w-[84px]  ml-auto rounded">
            <div className="flex bg-[#F9FAFB] rounded-t px-[6px] py-[2px]  border-b border-b-secondary-200 gap-x-1 items-center justify-center">
              <QualityIcon />
              <p className="text-secondary-700 font-semibold text-[10px]">
                {t("reports:quality")}
              </p>
            </div>
            <div className="flex flex-col  items-center justify-center">
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <QuantityAcceptedIcon />
                </div>
                <span className="text-xs font-medium text-[#079455]">
                  {operations?.hasQualityControl
                    ? operations?.qualityAcceptedPiece
                    : "--"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
              <div className="flex gap-x-[2px] p-1 items-center">
                <div className="w-4 h-4 min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]">
                  <QuantityDeclineIcon />
                </div>
                <span className="text-xs font-medium text-[#D92D20]">
                  {operations?.hasQualityControl
                    ? operations?.qualityRejectedPiece
                    : "--"}
                </span>
                <span className="text-[10px] leading-[18px] text-[#667085]">
                  {t("product:pcs")}
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="border min-h-[26px] max-h-[26px] rounded-b-lg bg-[#F9FAFB] pl-3 pr-2 py-1 flex justify-between items-center">
        <div className="flex h-full items-center gap-x-[2px]">
          <ClockIcon />
          <span className="text-xs text-[#344054] font-medium">
            {legacyFormatSeconds(operations?.internalOperation?.estimatedTime)}
          </span>
          <span className="text-[10px] leading-[18px] text-[#475467]">
            {t("product:targetMin")}
          </span>
        </div>
        <div
          className={`flex h-full items-center gap-x-[2px] ${
            operations?.newAvgActualCalculate == null ||
            operations?.newAvgActualCalculate === 0
              ? "text-[#7F7F7F]"
              : operations?.internalOperation?.estimatedTime >
                operations?.newAvgActualCalculate
              ? "text-[#079455]"
              : "text-[#D92D20]"
          }`}
        >
          <ClockIconStart />
          <span className="text-xs font-medium">
            {legacyFormatSeconds(operations?.newAvgActualCalculate)}
          </span>
          <span className="text-[10px] leading-[18px] text-[#475467]">
            {t("product:minPieceAvg")}
          </span>
        </div>
        <div className="flex h-full items-center gap-x-[2px]">
          <PlaySquareIcon />
          <span className="text-xs text-[#344054] font-medium">
            {operations?.totalJunctionLength}
          </span>
          <span className="text-[10px] leading-[18px] text-[#475467]">
            {t("product:works")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default AssemblyCard;

const QualityIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g mask="url(#mask0_13331_70317)">
        <path
          d="M11.1016 7C11.1016 9.26524 9.26524 11.1016 7 11.1016C4.73476 11.1016 2.89844 9.26524 2.89844 7C2.89844 4.73476 4.73476 2.89844 7 2.89844C9.26524 2.89844 11.1016 4.73476 11.1016 7Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.05078 5.76953L6.58984 8.23047L4.94922 6.58984"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.46805 0.606006C6.77463 0.344873 7.22537 0.344873 7.53194 0.606006L8.59789 1.54677L10.4017 1.40718C10.8032 1.37612 11.1679 1.64105 11.2624 2.0325L11.6241 3.53028L13.2001 4.4737C13.5432 4.68457 13.6825 5.11327 13.5289 5.4855L12.9062 6.99424L13.5289 8.50299C13.6825 8.87525 13.5432 9.30394 13.2001 9.51482L11.6241 10.4582L11.2624 11.956C11.1679 12.3474 10.8032 12.6124 10.4017 12.5813L8.61552 12.4431L7.53194 13.394C7.22537 13.6551 6.77463 13.6551 6.46805 13.394L5.38448 12.4431L3.59827 12.5813C3.19676 12.6124 2.83207 12.3474 2.73757 11.956L2.37448 10.4523L0.801062 9.51482C0.457953 9.30394 0.318664 8.87525 0.472308 8.50299L1.09493 6.99424L0.472308 5.4855C0.318664 5.11327 0.457953 4.68457 0.801062 4.4737L2.23962 3.61907L2.73757 2.0325C2.83207 1.64105 3.19676 1.37612 3.59827 1.40718L5.40214 1.54677L6.46805 0.606006Z"
          stroke="#475467"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

const PuzzleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.8963 12.8945L10.6951 10.6934C10.7272 11.2076 10.4939 11.7356 9.99496 12.0539C9.5165 12.3598 8.86887 12.3524 8.39562 12.0382C7.5803 11.4972 7.49944 10.3867 8.15341 9.73275C8.46829 9.41787 8.88899 9.27329 9.30075 9.299L7.09961 7.09786L9.67599 4.52111C9.63463 5.18775 10.0326 5.59169 10.1097 5.66845C10.7637 6.32242 11.8741 6.24193 12.4152 5.42624C12.7293 4.95337 12.7368 4.30573 12.4312 3.8269C12.1126 3.32832 11.5846 3.09505 11.0704 3.1271L13.1497 1.04781C13.4407 0.756781 13.9124 0.756781 14.2035 1.04781L15.8777 2.72204C15.8456 2.20781 16.0789 1.67979 16.5779 1.36119C17.0563 1.05563 17.704 1.06309 18.1772 1.37722C18.9925 1.91828 19.0734 3.02872 18.4194 3.68269C18.1046 3.99756 17.6842 4.14215 17.2721 4.11643L18.9463 5.79067C19.2374 6.0817 19.2374 6.55345 18.9463 6.84447L16.867 8.92376C17.3817 8.89172 17.9093 9.12498 18.2279 9.62394C18.5334 10.1024 18.526 10.75 18.2122 11.2233C17.6708 12.0386 16.5604 12.1195 15.9064 11.4655C15.5919 11.1506 15.4473 10.7299 15.473 10.3182L12.8963 12.8945Z"
        stroke="white"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.10036 7.09907L4.52361 9.67545C4.55565 9.16122 4.32238 8.63357 3.8238 8.31497C3.34534 8.00904 2.69733 8.01649 2.22446 8.33062C1.40915 8.87168 1.32828 9.98212 1.98225 10.6361C2.46295 11.1172 3.045 11.0751 3.12959 11.0698L1.0503 13.1491C0.759275 13.4402 0.759275 13.9119 1.0503 14.2029L2.72454 15.8772C2.69249 15.3629 2.92576 14.8353 3.42434 14.5167C3.90317 14.2108 4.55081 14.2182 5.02405 14.5323C5.83937 15.0738 5.91986 16.1838 5.26589 16.8378C4.95139 17.1527 4.53069 17.2973 4.11893 17.2716L5.79316 18.9458C6.08419 19.2368 6.55594 19.2368 6.84697 18.9458L8.92625 16.8665C8.89421 17.3811 9.12748 17.9088 9.62643 18.2274C10.1049 18.5329 10.7525 18.5255 11.2257 18.2117C12.0411 17.6703 12.1219 16.5598 11.4679 15.9059C11.1531 15.5914 10.7324 15.4468 10.3206 15.4725L12.897 12.8957"
        stroke="white"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
