import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomModal, Loading } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import { junctionService } from "../../../services/junction.service";
import { ScrollSyncPane } from "react-scroll-sync";
import JunctionCardType from "../../../components/junction/JunctionCardType";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import DroppedExternalJunctionPlan from "./DroppedExternalJunctionPlan";
import SelectUserModal from "../../../components/modal/SelectUserModal";
import FinishJunctionModal from "../../../components/modal/FinishJunctionModal";
import { ScrollChevronIcon } from "../../../assets/icons/productIcons";
import PlanPurchaseJunctionModal from "../modals/PlanPurchaseJunctionModal";
import { useStore } from "../../../hooks/useStores";
import SearchInput from "../../../components/inputs/SearchInput";
import Badgets from "../../../components/buttons/Badgets";
import { Toast } from "../../../utils/toastify/toast";
import { manipulate } from "../../../utils/manipulate";
const MaterialSupplyPlanView = ({
  data,
  isLoading,
  refetch,
  selectedOperation,
  setFinishPurhcaseModal,
  setFinishPurhcaseModalData,
}) => {
  const { t } = useTranslation();
  const { auth } = useStore();
  const [arr, setArr] = useState([]);
  const [isOpenPlanningModal, setIsOpenPlanningModal] = useState(false);
  const [isOpenSelectUserModal, setIsOpenSelectUserModal] = useState(false);
  const [isOpenFinishJunction, setIsOpenFinishJunction] = useState(false);
  const [selectedJunction, setSelectedJunction] = useState(null);
  const [draggedProduct, setDraggedProduct] = useState(null);
  const [supplierData, setSupplierData] = useState(null);
  const [tableHeadItemsLength, settableHeadItemsLength] = useState(0);
  const [rowLoading, setRowLoading] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const handleHeaderLength = () => {
    const head = data?.suppliers?.map((d) => {
      return d?.plannedJunction?.concat(d?.activeJunction)?.length;
    });
    settableHeadItemsLength(Math?.max(...head));
  };
  const handleDrop = (node) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  const tableHeadItems = [];
  for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i + 1}`, id: i });
  }
  const onDragEnd = async (result) => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  useEffect(() => {
    if (data) {
      setArr(data?.suppliers);
      handleHeaderLength();
    }
  }, [data]);
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd =
      container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const [isLeftArrowHidden2, setIsLeftArrowHidden2] = useState(true);
  const [isRightArrowHidden2, setIsRightArrowHidden2] = useState(false);
  const handleScroll2 = () => {
    const container = document.getElementById("content-wrapper2");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd =
      container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden2(isAtBeginning);
    setIsRightArrowHidden2(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper2");
    container?.addEventListener("scroll", handleScroll2);
    return () => {
      container?.removeEventListener("scroll", handleScroll2);
    };
  }, [handleScroll2]);
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full items-center justify-center flex">
          <Loading size={44} primary={"#B54708"} secondary={"#fff"} />
        </div>
      ) : (
        <>
          <div className="flex border flex-col rounded-xl">
            <div className="flex w-full px-6 py-3 items-center border-b mb-2">
              <div className="flex w-full min-h-[28px] max-h-[28px] gap-x-2 items-center">
                <p className="flex text-[#101828] font-semibold text-lg">
                  {/* {selectedOperation?.name?.slice(0, 3) + " ***"}{" "} */}
                  {manipulate(selectedOperation?.name)}
                  {t("product:products")}
                </p>
                <Badgets
                  colorType={"outline-warning"}
                  label={data?.orderList?.length || 0}
                />
                <p className="ml-8 text-sm font-normal text-secondary-600">
                  {t("product:planAndWork")}
                </p>
              </div>
              <div className="flex max-w-[320px] w-full justify-end">
                <SearchInput
                  expandable={true}
                  theme={"product"}
                  setSearchVal={setSearchQuery}
                />
              </div>
            </div>
            <div className="w-full flex relative ml-4 items-center">
              <div
                className={`w-11 h-full ${
                  isRightArrowHidden2 ? "hidden duration-1000" : ""
                } z-50 right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
                onClick={() => {
                  sideScroll(
                    document.getElementById("content-wrapper2"),
                    25,
                    100,
                    20
                  );
                }}
              >
                <ScrollChevronIcon />
              </div>
              <div
                className={`w-11 h-full ${
                  isLeftArrowHidden2 ? "hidden duration-1000" : ""
                } z-50 left-0 absolute cursor-pointer flex bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
                onClick={() => {
                  sideScroll(
                    document.getElementById("content-wrapper2"),
                    25,
                    100,
                    -20
                  );
                }}
              >
                <ScrollChevronIcon />
              </div>
              <div
                id="content-wrapper2"
                className="w-[100%] min-w-[100%] max-w-[100%]  overflow-x-auto flex"
              >
                {data?.orderList?.length > 0 && (
                  <div className="w-full pb-4 pt-2 flex ">
                    {data?.orderList?.map((item, index) => {
                      const statusColor = () => {
                        switch (item?.status) {
                          case "finished":
                            return "#12B76A";
                          case "started":
                            return "#FDE172";
                          case "rejected":
                            return "#D92D20";
                          default:
                            return "#D0D5DD";
                        }
                      };
                      return (
                        <div
                          key={item?.id}
                          draggable
                          onClick={() => {
                            navigate(
                              `/app/product/supply/material/details/${
                                item?.type + "+" + item?.id
                              }`
                            );
                          }}
                          onDragStart={() => setDraggedProduct(item)}
                          style={{ borderTopColor: statusColor() }}
                          className="w-[300px] min-w-[300px] mr-3 h-[90px] min-h-[90px] flex-col rounded-lg  border border-l-gray-300 border-b-gray-300 border-r-gray-300 border-t-4 shadow-sm p-2 flex items-start bg-white cursor-grab"
                        >
                          <div className="flex flex-row items-center gap-x-2">
                            {item?.rawMaterial?.image ? (
                              <img
                                src={generateFileUrl(item?.rawMaterial?.image)}
                                className="w-[64px] blur-sm h-[48px] rounded border object-cover"
                              />
                            ) : (
                              <div className="w-[64px] h-[48px] blur-sm rounded border text-white text-xl select-none flex items-center justify-center bg-[#DC6803] border-[#DC6803]">
                                {item?.rawMaterial?.name
                                  ?.charAt(0)
                                  .toUpperCase()}
                              </div>
                            )}
                            <div className="flex flex-col flex-1 overflow-hidden">
                              <p
                                title={item?.rawMaterial?.name}
                                className="text-sm font-semibold text-secondary-900 truncate"
                              >
                                {item?.rawMaterial?.name?.slice(0, 2) +
                                  " ***" || "Product Name"}
                              </p>
                              <p
                                title={item?.orderData?.orderNo}
                                className="text-xs text-gray-600 truncate"
                              >
                                {t("product:stockCode")}:{" "}
                                {item?.rawMaterial?.stockCode?.slice(0, 2) +
                                  " ***"}
                              </p>
                            </div>
                          </div>

                          <div className="w-full mt-1 overflow-hidden flex items-center flex-row gap-x-4">
                            <div
                              title={item?.orderedUnit ? item?.orderedUnit : 0}
                              className="flex items-center gap-0.5 w-[35%]  overflow-hidden"
                            >
                              <QtyLight />
                              <p className="text-xs text-gray-600 truncate">
                                {item?.orderedUnit ? item?.orderedUnit : 0}
                              </p>
                            </div>
                            <div
                              title={moment(
                                item?.orderData?.deliveryDate
                              ).format("DD.MM.YY")}
                              className="flex items-center gap-0.5 overflow-hidden"
                            >
                              <CalendarIcon />
                              <p className="text-xs text-gray-600 truncate">
                                {moment(item?.estimatedDeliveryDate).format(
                                  "DD.MM.YY"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 w-full mt-4 border rounded-xl border-gray-300 flex flex-col overflow-hidden relative">
            <div
              className={`w-11 h-full ${
                isRightArrowHidden ? "hidden duration-1000" : ""
              } z-50 right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
              onClick={() => {
                sideScroll(
                  document.getElementById("content-wrapper"),
                  25,
                  100,
                  20
                );
              }}
            >
              <ScrollChevronIcon />
            </div>

            <div
              className={`w-11 h-full ${
                isLeftArrowHidden ? "hidden duration-1000" : ""
              } z-50 left-0 absolute cursor-pointer flex ml-[335px] bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
              onClick={() => {
                sideScroll(
                  document.getElementById("content-wrapper"),
                  25,
                  100,
                  -20
                );
              }}
            >
              <ScrollChevronIcon />
            </div>
            <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
              <div className="flex items-center justify-center max-w-[335px] min-w-[335px] h-full border-r border-[#EAECF0] text-sm font-semibold text-[#344054]">
                {t("product:suppliers")}
              </div>
              <ScrollSyncPane>
                <div
                  id="content-wrapper"
                  className="flex items-center w-full h-full  overflow-x-auto scrollbar-hide"
                >
                  {tableHeadItems.map((item, index) => (
                    <div
                      key={index}
                      className="min-w-[312px] min-h-[44px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200"
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              </ScrollSyncPane>
            </div>
            <div className="flex-1 w-full flex flex-col overflow-y-auto scrollbar-hide bg-white">
              {arr?.map((a, b) => {
                let filledData = [
                  ...(a?.activeJunction || []),
                  ...(a.plannedJunction?.sort(
                    (a, b) => a?.planningOrder - b?.planningOrder
                  ) || []),
                  ...Array.from(
                    {
                      length: Math.max(
                        tableHeadItemsLength -
                          a?.plannedJunction?.concat(a?.activeJunction)?.length,
                        0
                      ),
                    },
                    () => null
                  ),
                ];

                return (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`${b}`} direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => handleDrop(a)}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="flex w-full min-h-[100px]"
                        >
                          <div className="max-w-[335px] min-w-[335px] h-full px-4 py-2 border-r border-b flex items-start">
                            <div className="h-[64px] w-full flex flex-row items-start gap-4">
                              {a?.image ? (
                                <img
                                  src={generateFileUrl(a?.image)}
                                  className="w-[72px] min-w-[72px] blur-sm min-h-[54px] h-[54px] rounded border object-cover"
                                />
                              ) : (
                                <div className="w-[72px] h-[54px] blur-sm min-w-[72px] min-h-[54px] rounded border text-white text-xl select-none flex items-center justify-center bg-[#DC6803] border-[#DC6803]">
                                  {a?.name?.charAt(0).toUpperCase()}
                                </div>
                              )}
                              <div className="flex flex-col w-full items-start">
                                <p
                                  title={a?.name?.slice(0, 3) + " ***"}
                                  className="text-secondary-900 w-[200px] font-semibold truncate"
                                >
                                  {a?.name?.slice(0, 3) + " ***"}
                                </p>
                                <div className="w-full py-1 flex items-center justify-between border-b border-gray-200">
                                  <p className="text-xs text-secondary-500">
                                    {t("product:actives")}
                                  </p>
                                  <Badgets
                                    colorType={"fill-success"}
                                    label={a?.activeJunction?.length || 0}
                                    size={"sm"}
                                  />
                                </div>
                                <div className="w-full py-1 flex items-center justify-between">
                                  <p className="text-xs text-secondary-500">
                                    {t("product:newPlanned")}
                                  </p>
                                  <Badgets
                                    colorType={"fill-gray"}
                                    label={a?.plannedJunction?.length || 0}
                                    size={"sm"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <ScrollSyncPane>
                            <div className="w-full overflow-x-auto scrollbar-hide flex border-b border-gray-200 max-h-[106px] m-0 p-0">
                              {filledData?.map((item, index) => {
                                if (item) {
                                  if (item?.status === "active") {
                                    return (
                                      <div className="min-w-[312px] max-w-[312px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                        <JunctionCardType
                                          step={item?.header?.step}
                                          qualityPercent={
                                            item?.metadata?.qualityPercent
                                          }
                                          allData={item}
                                          externalOperation={
                                            item?.externalOperation
                                          }
                                          supplier={item?.supplier}
                                          junctionId={item?.id}
                                          key={index}
                                          props={{
                                            ring: "#17B26A",
                                            leftImage:
                                              item?.purchaseRequest?.rawMaterial
                                                ?.image,
                                            userImage:
                                              item?.responsibleUser?.avatar,
                                            rightImage:
                                              item?.responsibleUser?.avatar,
                                            onPressCard: () => {
                                              setFinishPurhcaseModal(true);
                                              setFinishPurhcaseModalData(item);
                                            },
                                            user: {
                                              name: item?.responsibleUser?.name,
                                              lastName:
                                                item?.responsibleUser?.lastName,
                                              avatar:
                                                item?.responsibleUser?.avatar,
                                            },
                                            texts: {
                                              first:
                                                item?.purchaseRequest
                                                  ?.rawMaterial?.name,
                                              second: "ss",
                                              a: item?.orderedUnit,
                                              b:
                                                moment().diff(
                                                  moment(item?.startDate),
                                                  "hours"
                                                ) + " h",
                                            },
                                            onPressProduct: null,
                                          }}
                                        />
                                      </div>
                                    );
                                  } else {
                                    if (b === rowLoading) {
                                      return (
                                        <div className="min-w-[312px] max-w-[312px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                          <div className="w-full h-full rounded-lg bg-gray-200 animate-pulse" />
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <Draggable
                                          key={item?.id}
                                          draggableId={item?.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className=""
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div className="min-w-[312px] max-w-[312px] flex items-center justify-center py-2 border-r border-gray-200 px-3 bg-white">
                                                {item?.type === "planned" ? (
                                                  <div className="relative w-full group">
                                                    <JunctionCardType
                                                      step={item?.header?.step}
                                                      props={{
                                                        rightButton: () => {
                                                          
                                                        },
                                                        leftImage:
                                                          item?.purchaseRequest
                                                            ?.rawMaterial
                                                            ?.image,
                                                        texts: {
                                                          first:
                                                            item
                                                              ?.purchaseRequest
                                                              ?.rawMaterial
                                                              ?.name,
                                                          second:
                                                            item
                                                              ?.purchaseRequest
                                                              ?.rawMaterial
                                                              ?.stockCode,
                                                          a: item?.orderedUnit,
                                                          b: String(
                                                            moment(
                                                              item?.estimatedDeliveryDate
                                                            ).diff(
                                                              moment(),
                                                              "days"
                                                            ) + " days"
                                                          ),
                                                        },
                                                      }}
                                                    />
                                                    {/* <button
                                                      onClick={async () => {
                                                        await junctionService
                                                          .deleteSupplierForJunction(
                                                            item?.id
                                                          )
                                                          .then((res) => {
                                                            if (
                                                              res.data.code == 0
                                                            ) {
                                                              toast.success(
                                                                "Junction deleted"
                                                              );
                                                              refetch();
                                                            } else {
                                                              toast.error(
                                                                "Junction not deleted"
                                                              );
                                                            }
                                                          });
                                                      }}
                                                      type="button"
                                                      className="w-9 h-9 rounded-lg bg-white border border-[#FDA29B] hidden group-hover:flex items-center justify-center absolute -top-2 -right-2"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
                                                          stroke="#B42318"
                                                          strokeWidth="1.66667"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    </button> */}
                                                  </div>
                                                ) : (
                                                  <JunctionCardType
                                                    step={item?.header?.step}
                                                    props={{
                                                      leftImage:
                                                        item?.job?.product
                                                          ?.image,
                                                      texts: {
                                                        first:
                                                          item?.job?.orderNo,
                                                        second:
                                                          item?.job?.product
                                                            ?.name,
                                                        a: item?.header?.piece,
                                                        b:
                                                          String(
                                                            Math.floor(
                                                              item?.header
                                                                ?.totalEstimatedTime /
                                                                3600
                                                            )
                                                          ) + " h",
                                                      },
                                                      user: {
                                                        name: item?.user?.name,
                                                        lastName:
                                                          item?.user?.lastName,
                                                        avatar:
                                                          item?.user?.avatar ||
                                                          "empty",
                                                      },
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                  }
                                } else {
                                  return (
                                    <div className="min-w-[312px] max-w-[312px]" />
                                  );
                                }
                              })}
                            </div>
                          </ScrollSyncPane>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                );
              })}
            </div>
          </div>
        </>
      )}

      {/* <DroppedExternalJunctionPlan
        supplier={supplierData}
        orderData={draggedProduct}
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        refetch={refetch}
        processId={data?.id}
      /> */}
      <CustomModal
        isOpen={isOpenPlanningModal}
        setIsOpen={setIsOpenPlanningModal}
        onClose={() => {
          setIsOpenPlanningModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <PlanPurchaseJunctionModal
            setIsOpen={setIsOpenPlanningModal}
            processId={data?.id}
            refetch={refetch}
            selectedJunction={draggedProduct}
            supplierId={supplierData?.id}
            companyId={auth.user.company.id}
          />
        }
      />
      <CustomModal
        isOpen={isOpenSelectUserModal}
        setIsOpen={setIsOpenSelectUserModal}
        onClose={() => {
          setIsOpenSelectUserModal(false);
        }}
        modalTitle={t("chat:startJob")}
        width="fit-content"
        children={
          <SelectUserModal
            t={t}
            setIsOpen={setIsOpenSelectUserModal}
            isOpen={isOpenSelectUserModal}
            selectedJunction={selectedJunction}
            handleData={refetch}
            purchase={true}
          />
        }
      />
      <CustomModal
        isOpen={isOpenFinishJunction}
        setIsOpen={setIsOpenFinishJunction}
        modalTitle={t("product:finishJuction")}
        children={
          <FinishJunctionModal
            handleData={refetch}
            modalTheme={"machine"}
            junctionId={selectedJunction?.id}
            closeModal={() => setIsOpenFinishJunction(false)}
            type={selectedJunction?.internalOperation ? "internal" : "external"}
          />
        }
      />
    </>
  );
};

export default MaterialSupplyPlanView;

const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.33333 5.97689C6.33333 5.6587 6.33333 5.4996 6.39983 5.41078C6.45777 5.33338 6.54648 5.28496 6.64292 5.27807C6.75359 5.27016 6.88742 5.3562 7.15507 5.52826L10.3021 7.55137C10.5344 7.70068 10.6505 7.77533 10.6906 7.87026C10.7257 7.9532 10.7257 8.0468 10.6906 8.12974C10.6505 8.22467 10.5344 8.29932 10.3021 8.44863L7.15507 10.4717C6.88742 10.6438 6.75359 10.7298 6.64292 10.7219C6.54648 10.715 6.45777 10.6666 6.39983 10.5892C6.33333 10.5004 6.33333 10.3413 6.33333 10.0231V5.97689Z"
        stroke="#475467"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5.2C2 4.07989 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.07989 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.07989 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2Z"
        stroke="#475467"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const QtySmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_9687_29648)">
        <path
          d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9687_29648">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const QtyLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75 2.3335H3.71429C2.76751 2.3335 2 3.10101 2 4.04778V11.8692C2 12.816 2.76751 13.5835 3.71429 13.5835H12.2857C13.2325 13.5835 14 12.816 14 11.8692V4.04778C14 3.10101 13.2325 2.3335 12.2857 2.3335H10.25M5.75 2.3335V6.69481C5.75 6.75853 5.81706 6.79997 5.87405 6.77147L8 5.7085L10.126 6.77147C10.1829 6.79997 10.25 6.75853 10.25 6.69481V2.3335M5.75 2.3335H10.25"
        stroke="#475467"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
