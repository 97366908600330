import React, { useState, useEffect } from "react";
import { Button, CustomModal } from "../../components";
import { ArrowLeftIcon } from "../../assets/icons/productIcons";

import { useTranslation } from "react-i18next";

import NewPlannedJunctionModal from "./modals/NewPlannedJunctionModal";
import FinishOperationSelectNode from "../../components/modal/FinishOperationSelectNode";
import NewPlannedJunctionExternalModal from "./modals/NewPlannedJunctionExternalModal";
import { Toast } from "../../utils/toastify/toast";
import FinishOperationDropDown from "./components/FinishOperationDropDown";
import AddJunctionManual from "../../components/modal/AddJunctionManual";

const ProductOperationDetailHeader = ({
  navigate,
  refetch,
  operation,
  headerId,
  currentOperation,
  operationId,
  orderId,
  disabledMove,
  isLoading,
  isFetching,
}) => {
  const [finishOperationAlert, setFinishOperationAlert] = useState(false);
  const [newPlannedJunctionModal, setNewPlannedJunctionModal] = useState(false);
  const [newPlannedJunctionExternalModal, setNewPlannedJunctionExternalModal] =
    useState(false);
  const [isOpenFinishOperation, setIsOpenFinishOperation] = useState(false);
  const [isOpenJunction, setIsOpenJunction] = useState(false);

  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const operationData = {
    product: {
      id: operation?.operationId,
      name: operation?.name,
      image: operation?.image,
    },
    orderNo: operation?.orderNo,
    orderId,
  };

  useEffect(() => {
    setStatus(operation?.operation?.status);
  }, [status]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = windowSize <= 767;

  return (
    <>
      <div className="w-full h-[78px] border-b flex items-center sticky top-0 z-50 bg-white">
        <Button
          size={"md"}
          iconLeft={<ArrowLeftIcon />}
          colorType={"tertiary-gray"}
          onClick={() => navigate(-1)}
        />

        <div className="flex flex-col ml-3">
          <h1 className="text-xl font-semibold text-gray-900">
            {t("product:operationDetail")}
          </h1>
        </div>
        <div className="flex items-center ml-auto gap-3 mr-1">
          <FinishOperationDropDown
            setIsOpenFinishOperation={setIsOpenFinishOperation}
            setIsOpenJunction={setIsOpenJunction}
            disabled={status != "finished" ? false : true}
          />
          {/* <Button
            colorType={"primary-error"}
            size={"md"}
            label={
              isSmallScreen
                ? t("buttons:finish")
                : t("product:finishThisOperation")
            }
            onClick={() => {
              setIsOpenFinishOperation(true);
            }}
            disabled={status != "finished" ? false : true}
          /> */}
          <Button
            colorType={"secondary-product"}
            iconLeft={<MoveIcon />}
            label={t("product:moveJunction")}
            size={"md"}
            // disabled={disabledMove}
            // onClick={() => {
            //   navigate(
            //     `/app/product/order/detail/${operationId}/move-junction`
            //   );
            // }}
            // onClick={() => Toast("warning", t("alert:warning"))}
          />
          <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:planWork")}
            iconLeft={<PlusIconNew />}
            // onClick={() => {
            //   if (operation?.operation?.status == "finished") {
            //     Toast("error", t("product:noPlannedJunction"));
            //   } else {
            //     if (currentOperation?.internalOperation == null) {
            //       setNewPlannedJunctionExternalModal(true);
            //     } else {
            //       setNewPlannedJunctionModal(true);
            //     }
            //   }
            // }}
            onClick={() => Toast("warning", t("alert:warning"))}
            // disabled={orders?.data?.activeOrder?.length > 0}
          />
        </div>
      </div>

      <CustomModal
        // isOpen={isOpenFinishOperation}
        // isOpen={Toast("warning", t("alert:warning"))}
        // setIsOpen={setIsOpenFinishOperation}
        onClose={() => {
          setIsOpenFinishOperation(false);
        }}
        modalTitle={t("product:finishThisOperation")}
        width="fit-content"
        children={
          <FinishOperationSelectNode
            refetch={refetch}
            orderData={operationData}
            isLoading={isLoading}
            isFetching={isFetching}
            currentOperation={currentOperation}
            processId={
              operation?.operation?.internalOperation
                ? operation?.processId
                : operation?.operation?.externalOperation?.process?.id
            }
            onClose={() => {
              setIsOpenFinishOperation(false);
            }}
          />
        }
      />
      <CustomModal
        // isOpen={isOpenJunction}
        // setIsOpen={setIsOpenJunction}
        onClose={() => {
          setIsOpenJunction(false);
        }}
        modalTitle={t("product:junctionManually")}
        width="fit-content"
        children={
          <AddJunctionManual
            refetch={refetch}
            operation={operation}
            orderData={operationData}
            // currentOperation={currentOperation}
            // processId={
            //   operation?.operation?.internalOperation
            //     ? operation?.processId
            //     : operation?.operation?.externalOperation?.process?.id
            // }
            setIsOpenJunction={setIsOpenJunction}
          />
        }
      />
      <CustomModal
        modalTitle={t("product:createNewWork")}
        setIsOpen={setNewPlannedJunctionModal}
        isOpen={newPlannedJunctionModal}
        // asdas
        children={
          <NewPlannedJunctionModal
            operationData={operationData}
            headerId={headerId}
            getJunctions={refetch}
            closeModal={() => setNewPlannedJunctionModal(false)}
            processId={operation?.processId}
          />
        }
      />
      <CustomModal
        modalTitle={t("product:createNewWork")}
        setIsOpen={setNewPlannedJunctionExternalModal}
        isOpen={newPlannedJunctionExternalModal}
        children={
          <NewPlannedJunctionExternalModal
            operationData={operationData}
            headerId={headerId}
            getJunctions={refetch}
            closeModal={() => setNewPlannedJunctionExternalModal(false)}
            processId={currentOperation?.externalOperation?.process?.id}
          />
        }
      />
    </>
  );
};

export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 4.1665V15.8332M4.16699 9.99984H15.8337"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlusIconNew = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 4.1665V15.8332M4.16699 9.99984H15.8337"
      stroke="#FFFFFF"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const MoveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_10850_10822)">
        <path
          d="M2.7819 5.83366C4.22277 3.34282 6.91587 1.66699 10.0004 1.66699C14.6028 1.66699 18.3337 5.39795 18.3337 10.0003C18.3337 14.6027 14.6028 18.3337 10.0004 18.3337C6.91587 18.3337 4.22277 16.6578 2.7819 14.167M10.0003 13.3337L13.3337 10.0003M13.3337 10.0003L10.0003 6.66699M13.3337 10.0003H1.66699"
          stroke="#B54708"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10850_10822">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProductOperationDetailHeader;
