import React, { useEffect } from "react";
import { Button } from "../../components";
import { NotFound404 } from "../../assets/illustrations";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (pathname == "/") {
      navigate("/auth/login");
    } else {
    }
  }, []);

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center sm:hidden xs:hidden">
        <div className="w-[40%] lg:w-[45%] flex justify-center flex-col max-w-[560px]">
          <h1 className="text-[60px] lg:text-[52px] md:text-4xl font-semibold text-secondary-900">
            {t("global:pageNotFound")}
          </h1>
          <p className="text-xl lg:text-lg md:text-base text-secondary-500 mt-4">
            {t("global:sorryMsg")}
          </p>
          <ul className="flex items-center gap-x-7 mt-3">
            <li className="my-0.5">
              <NavLink
                to={"/app/machine/digital-twin"}
                className={
                  "text-blue-700 font-medium text-base hover:underline"
                }
              >
                Digital Twin
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/app/product/order"}
                className={
                  "text-blue-700 font-medium text-base hover:underline"
                }
              >
                Product
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/app/person/message"}
                className={
                  "text-blue-700 font-medium text-base hover:underline"
                }
              >
                Message
              </NavLink>
            </li>
          </ul>
          <div className="flex items-center mt-8 gap-4">
            <Button
              width={152}
              colorType={"primary-machine"}
              label={t("global:toHome")}
              size={"sm"}
              onClick={() => navigate("/app/machine/digital-twin")}
            />
          </div>
        </div>
        <div className="w-[40%] flex items-center justify-center ml-8">
          <div className="relative flex-col items-center flex">
            <NotFound404 className="md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px] animate-pulse" />
          </div>
        </div>
      </div>
      <div className="hidden xs:flex sm:flex flex-col p-6 items-center w-screen h-screen justify-center">
        <h1 className="text-3xl font-semibold text-secondary-900">
          {t("global:pageNotFound")}
        </h1>
        <p className="text-base text-secondary-500 mt-4 text-center px-5">
          {t("global:sorryMsg")}
        </p>
        <ul className="flex items-center gap-x-10 mt-3">
          <li className="my-0.5">
            <NavLink
              to={"/app/machine/digital-twin"}
              className={"text-blue-700 font-medium text-base hover:underline"}
            >
              Digital Twin
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/app/product/order"}
              className={"text-blue-700 font-medium text-base hover:underline"}
            >
              Product
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/app/person/message"}
              className={"text-blue-700 font-medium text-base hover:underline"}
            >
              Message
            </NavLink>
          </li>
        </ul>
        <div className="relative flex-col items-center flex">
          <NotFound404 className="w-[230px] h-[230px] animate-pulse" />
        </div>
        <div className="flex w-full items-center mt-7 gap-4">
          <Button
            width={"100%"}
            colorType={"primary-machine"}
            label={t("global:toHome")}
            size={"sm"}
            onClick={() => navigate("/app/machine/digital-twin")}
          />
        </div>
      </div>
    </>
  );
};

export default NotFound;
