import React, { useCallback, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Button, CustomModal } from "../../components";
import { AddPersonnelIcon, PlusIcon } from "../../assets/icons/PersonIcons";
import NewGroup from "./modals/NewGroup";
import NewNotifyModal from "./modals/NewNotifyModal";
import { useTranslation } from "react-i18next";
import AddUserModal from "../../steps/users/modals/AddUserModal";
import ConversationModal from "./modals/ConversationModal";
import NewPollModal from "./modals/NewPollModal";
import { useStore } from "../../hooks/useStores";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { Toast } from "../../utils/toastify/toast";

const PersonLayout = () => {
  const { settings } = useStore();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);
  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false);
  const [isNewPersonModalOpen, setIsNewPersonModalOpen] = useState(false);
  const [isNewNotifyModalOpen, setIsNewNotifyModalOpen] = useState(false);
  const [isNewPollModalOpen, setIsNewPollModalOpen] = useState(false);

  const onClickFunction = () => {
    if (pathname.includes("message")) {
      setIsConversationModalOpen(true);
    }
  };

  const findPageLabel = useCallback(() => {
    if (pathname.includes("message")) {
      return "message";
    } else if (pathname.includes("task")) {
      return "task";
    } else if (pathname.includes("poll")) {
      return "poll";
    } else if (pathname.includes("notify")) {
      return "notify";
    } else if (pathname.includes("attend")) {
      return "attend";
    } else if (pathname.includes("personnel")) {
      return "person";
    } else if (pathname.includes("point")) {
      return "point";
    } else {
      return "person";
    }
  }, [pathname]);

  return (
    <>
      <div className="flex w-full h-full flex-col overflow-hidden ">
        {width < 575 ? (
          <>
            <div
              className={`w-full  flex flex-col mb-4 ${
                pathname.includes("personnel") ||
                pathname.includes("notify") ||
                pathname.includes("point") ||
                pathname.includes("poll")
                  ? "hidden"
                  : "flex"
              }`}
            >
              <div className="flex items-center">
                <span className="mr-3 md:flex sm:flex xs:flex hidden">
                  <Button
                    colorType={"tertiary-gray"}
                    iconLeft={<HumburgerIcon />}
                    size={"md"}
                    onClick={() => settings.setIsActive(true)}
                  />
                </span>
                <span className="font-codecMedium text-2xl text-black">
                  upu.
                </span>
                <p className="text-2xl font-codecMedium text-teal-700">
                  {findPageLabel()}
                </p>
              </div>
              {pathname.includes("notify") ? (
                <div className="flex items-center">
                  <Button
                    label={t("buttons:newNotification")}
                    iconLeft={<PlusIcon />}
                    colorType={"primary-person"}
                    size={"md"}
                    onClick={() => setIsNewNotifyModalOpen(true)}
                  />
                </div>
              ) : pathname.includes("poll") ? (
                <div className="flex items-center">
                  <Button
                    label={t("person:newPoll")}
                    iconLeft={<PlusIcon />}
                    colorType={"primary-person"}
                    size={"md"}
                    onClick={() => setIsNewPollModalOpen(true)}
                  />
                </div>
              ) : pathname.includes("task") ? (
                <div className="flex items-center gap-x-4">
                  <Button
                    label={t("person:newGroup")}
                    iconLeft={<AddPersonnelIcon />}
                    colorType={"secondary-gray"}
                    size={"md"}
                    onClick={() => setIsNewGroupModalOpen(true)}
                  />
                </div>
              ) : pathname.includes("attend") ? (
                <div className="flex items-center"></div>
              ) : pathname.includes("point") ? (
                <div className="flex items-center"></div>
              ) : (
                <div className="flex items-center gap-x-4 mt-4">
                  <Button
                    label={t("person:newGroup")}
                    iconLeft={<AddPersonnelIcon />}
                    colorType={"secondary-gray"}
                    size={"md"}
                    onClick={() => setIsNewGroupModalOpen(true)}
                  />
                  <Button
                    label={t("person:newMessage")}
                    iconLeft={<PlusIcon />}
                    colorType={"primary-person"}
                    size={"md"}
                    onClick={onClickFunction}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div
            className={`w-full h-[60px] min-h-[60px] items-center  justify-between mb-4 border-b border-secondary-200 ${
              pathname.includes("personnel") ||
              pathname.includes("notify") ||
              pathname.includes("point") ||
              pathname.includes("poll")
                ? "hidden"
                : "flex"
            }`}
          >
            <div className="flex items-center">
              <span className="mr-3 md:flex sm:flex xs:flex hidden">
                <Button
                  colorType={"tertiary-gray"}
                  iconLeft={<HumburgerIcon />}
                  size={"md"}
                  onClick={() => settings.setIsActive(true)}
                />
              </span>
              <span className="font-codecMedium text-2xl text-black">upu.</span>
              <p className="text-2xl font-codecMedium text-teal-700">
                {findPageLabel()}
              </p>
            </div>
            {pathname.includes("notify") ? (
              <div className="flex items-center">
                <Button
                  label={t("buttons:newNotification")}
                  iconLeft={<PlusIcon />}
                  colorType={"primary-person"}
                  size={"md"}
                  // onClick={() => setIsNewNotifyModalOpen(true)}
                  onClick={() => Toast("warning", t("alert:warning"))}
                />
              </div>
            ) : pathname.includes("poll") ? (
              <div className="flex items-center">
                <Button
                  label={t("person:newPoll")}
                  iconLeft={<PlusIcon />}
                  colorType={"primary-person"}
                  size={"md"}
                  // onClick={() => setIsNewPollModalOpen(true)}
                  onClick={() => Toast("warning", t("alert:warning"))}
                />
              </div>
            ) : pathname.includes("task") ? (
              <div className="flex items-center gap-x-4">
                <Button
                  label={t("person:newGroup")}
                  iconLeft={<AddPersonnelIcon />}
                  colorType={"secondary-gray"}
                  size={"md"}
                  // onClick={() => setIsNewGroupModalOpen(true)}
                  onClick={() => Toast("warning", t("alert:warning"))}
                />
              </div>
            ) : pathname.includes("attend") ? (
              <div className="flex items-center"></div>
            ) : pathname.includes("point") ? (
              <div className="flex items-center"></div>
            ) : (
              <div className="flex items-center gap-x-4">
                <Button
                  label={t("person:newGroup")}
                  iconLeft={<AddPersonnelIcon />}
                  colorType={"secondary-gray"}
                  size={"md"}
                  // onClick={() => setIsNewGroupModalOpen(true)}
                  onClick={() => Toast("warning", t("alert:warning"))}
                />
                <Button
                  label={t("person:newMessage")}
                  iconLeft={<PlusIcon />}
                  colorType={"primary-person"}
                  size={"md"}
                  // onClick={onClickFunction}
                  onClick={() => Toast("warning", t("alert:warning"))}
                />
              </div>
            )}
          </div>
        )}

        <div className="flex-1 overflow-hidden p-1 xs:p-0">
          <Outlet
            context={[
              isNewNotifyModalOpen,
              setIsNewNotifyModalOpen,
              isConversationModalOpen,
              setIsConversationModalOpen,
              isNewGroupModalOpen,
              setIsNewGroupModalOpen,
              isNewPersonModalOpen,
              setIsNewPersonModalOpen,
              isNewPollModalOpen,
              setIsNewPollModalOpen,
            ]}
          />
        </div>
      </div>
      <CustomModal
        isOpen={isNewGroupModalOpen}
        modalTitle={t("person:createTaskGroup")}
        setIsOpen={setIsNewGroupModalOpen}
        children={<NewGroup setIsNewGroupModalOpen={setIsNewGroupModalOpen} />}
      />
      <CustomModal
        isOpen={isNewPersonModalOpen}
        modalTitle={t("addPersonal:modalTitle")}
        setIsOpen={setIsNewPersonModalOpen}
        children={<AddUserModal />}
      />
      <CustomModal
        isOpen={isConversationModalOpen}
        setIsOpen={setIsConversationModalOpen}
        modalTitle={t("person:startConversation")}
        children={<ConversationModal setIsOpen={setIsConversationModalOpen} />}
      />
      <CustomModal
        isOpen={isNewPollModalOpen}
        setIsOpen={setIsNewPollModalOpen}
        modalTitle={t("person:createNewPoll")}
        children={
          <NewPollModal setIsNewPollModalOpen={setIsNewPollModalOpen} />
        }
      />
    </>
  );
};

export default PersonLayout;
