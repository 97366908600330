import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CustomModal, Loading, PaginationComponent, TableOptions } from "../../components";
import { PlusIcon, QuantityIcon } from "../../assets/icons/stepsIcons";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { useQuery } from "@tanstack/react-query";
import { productService } from "../../services/product.service";
import NewProduct from "./modals/NewProduct";
import { ScrollChevronIcon, Filter, QuantityAcceptedIcon, QuantityDeclineIcon } from "../../assets/icons/productIcons";
import NoData from "../../steps/step-navigation/components/NoData";
import { HumburgerIcon } from "../../assets/icons/leftMenuIcons";
import { useStore } from "../../hooks/useStores";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimension";
import NewStockCard from "./cards/NewStockCard";
import StockPurchaseCard from "./cards/StockPurchaseCard";
import StockInternalOperationCard from "./cards/StockInternalOperationCard";
import StockExternalOperationCard from "./cards/StockExternalOperationCard";
import SearchInput from "../../components/inputs/SearchInput";
import { SearchIcon } from "../../assets/icons/PersonIcons";
import OrderSort from "../product/components/OrderSort";
import { DeadlineIconSort } from "../../assets/icons/equipmentIcon";
// import { StockTour } from "../../assets/TourSteps";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { Toast } from "../../utils/toastify/toast";
const Stock = () => {
  const navigate = useNavigate();
  const { pageNo } = useParams();
  const { settings, auth } = useStore();
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const [newProduct, setNewProduct] = useState([]);
  const [tableHeadItemsLength, settableHeadItemsLength] = useState(0);
  const [isNewProductModal, setIsNewProductModal] = useState(false);
  const [responsiveSearch, setResponsiveSearch] = useState(false);
  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  // Pagination States
  const [searchVal, setSearchVal] = useState();
  const [pageCount, setPageCount] = useState(0);
  // const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortValue, setSortValue] = useState(null);
  const [ascDesc, setAscDesc] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const [runState, setRunState] = useState(false);
  const [stepIndexState, setStepIndexState] = useState(0);
  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["listProduct"],
    queryFn: useMemo(
      () => async () => {
        try {
          const res = await productService.listProductPaginated(Number(pageNo) ? Number(pageNo) : 1, searchVal, sortValue, ascDesc ? "ASC" : "DESC", pageLimit);
          setTotalCount(res.data.meta.itemCount);
          setPageCount(res.data.meta.pageCount);
          setPageData(res.data.data);
          if (res.data.meta.pageCount < pageNo) {
            navigate(`/app/product/stock/1`);
          }
          return res;
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error;
        }
      },
      [pageNo, searchVal, sortValue, ascDesc, pageLimit]
    ),
    retry: 0,
  });
  useEffect(() => {
    refetch();
  }, [pageNo, searchVal, sortValue, ascDesc, pageLimit]);
  const dataManipulate = () => {
    if (pageData?.length > 0) {
      const updatedProduct = pageData?.map((e) => {
        return {
          ...e,
          operations: e.internalOperations.concat(e?.externalOperations),
        };
      });
      setNewProduct(updatedProduct);
      const head = updatedProduct.map((d) => {
        return d?.operations?.length;
      });
      settableHeadItemsLength(Math?.max(...head));
    }
  };
  const tableHeadItems = [];
  for (let i = 0; i < Math.max(1, tableHeadItemsLength); i++) {
    tableHeadItems.push({ title: `${i + 1}.${t("product:operation")}`, id: i });
  }
  useEffect(() => {
    dataManipulate();
  }, [pageData]);
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325") {
    } else if (auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4") {
    } else if (auth?.user?.company?.id === "63ce88f9-b045-4a22-b077-58ae63b1ea51") {
    } else if (auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592") {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd = container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = windowSize <= 1023;
  function handleJoyrideCallback(data) {
    const { action, index, type, status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunState(false);
      setStepIndexState(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // if the current action is 'previous', set the stepIndex back one, otherwise progress +1:
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndexState(stepIndex);
    }
  }
  const changePage = (newPageNo) => {
    navigate(`/app/product/stock/${newPageNo}`);
  };
  return (
    <>
      <>
        <ScrollSync>
          <div className="flex flex-col w-full h-full overflow-hidden">
            <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
              <span className="mr-3 md:flex sm:flex xs:flex hidden">
                <Button colorType={"tertiary-gray"} iconLeft={<HumburgerIcon />} size={"md"} onClick={() => settings.setIsActive(true)} />
              </span>
              <div className="flex items-center">
                <span className="font-codecMedium text-2xl text-black">upu.</span>
                <p className="text-2xl font-codecMedium text-[#B54708]">stock</p>
              </div>
              {/* <div>
                <Button
                  colorType={"secondary-product"}
                  size={"sm"}
                  label={"start"}
                  onClick={() => {
                    setRunState(true);
                  }}
                />
              </div> */}
              <div className="ml-auto flex flex-row items-center gap-x-3">
                <div className="w-[320px] max-w-[320px] min-w-[320px] sm:hidden md:hidden">
                  <SearchInput setSearchVal={setSearchVal} theme={"product"} placeholder={t("chat:search")} />
                </div>
                <div className="hidden sm:flex md:flex">
                  <Button size="md" iconLeft={<SearchIcon />} colorType={"secondary-gray"} />
                </div>
                <OrderSort
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  ascDesc={ascDesc}
                  setAscDesc={setAscDesc}
                  options={[
                    {
                      label: t("product:productName"),
                      value: "name",
                      icon: <QuantityIcon />,
                    },
                    {
                      label: t("product:stockCode"),
                      value: "stockCode",
                      icon: <QuantityIcon />,
                    },
                    {
                      label: t("product:qualityQuantitity"),
                      value: "qualityQuantity",
                      icon: <QuantityAcceptedIcon />,
                    },
                    {
                      label: t("product:scrapQuantity"),
                      value: "scrapQuantity",
                      icon: <QuantityDeclineIcon />,
                    },
                    {
                      label: t("report:creationDate"),
                      value: "createdDate",
                      icon: <DeadlineIconSort />,
                    },
                  ]}
                />
                <TableOptions pageLimit={pageLimit} setPageLimit={setPageLimit} />
                {/* <Button
                  label={isSmallScreen ? "" : "Filter"}
                  size="md"
                  iconLeft={<Filter color="#344054" />}
                  colorType={"secondary-gray"}
                /> */}
                <Button
                  colorType={"primary-product"}
                  size={"md"}
                  label={isSmallScreen ? t("product:newProduct") : t("product:createNewProduct")}
                  iconLeft={<PlusIcon />}
                  onClick={() => {
                    // setIsNewProductModal(true);
                    Toast("warning", t("alert:warning"))
                  }}
                />
                {/* <Button
                  colorType={"primary-product"}
                  size={"md"}
                  label={t("product:createRawMaterial")}
                  iconLeft={<PlusIcon />}
                  onClick={() => navigate("create-raw")}
                /> */}
              </div>
            </div>
            {responsiveSearch && (
              <div className="w-full hidden sm:flex md:flex pb-4 px-1">
                <SearchInput setSearchVal={setSearchVal} />
              </div>
            )}
            <div className="w-full min-h-[91%] max-h-[91%] border shadow-md rounded-xl border-[#D0D5DD]">
              {isLoading || isFetching ? (
                <div className="w-full flex h-full items-center justify-center">
                  <Loading primary={"#B54708"} size={44} secondary={"#fff"} />
                </div>
              ) : (
                <>
                  {pageData?.length <= 0 ? (
                    <div className="flex w-full h-full  items-center justify-center ">
                      <NoData header={t("label:noData")} button={false} />
                    </div>
                  ) : (
                    <div className="w-full h-full flex flex-col relative">
                      <>
                        <div
                          className={`w-11 h-full ${
                            isRightArrowHidden ? "hidden duration-1000" : ""
                          } z-50 right-0 backdrop-blur-md cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-[#FFFFFF33]`}
                          onClick={() => {
                            sideScroll(document.getElementById("content-wrapper"), 55, 300, 50);
                          }}
                        >
                          <ScrollChevronIcon />
                        </div>
                        <div
                          className={`w-11 h-full ${
                            isLeftArrowHidden ? "hidden duration-1000" : ""
                          } z-50 left-0 absolute cursor-pointer flex ml-[356px] bg-[#FFFFFF33] backdrop-blur-md rotate-180 items-center justify-center focus:bg-secondary-500 group`}
                          onClick={() => {
                            sideScroll(document.getElementById("content-wrapper"), 55, 300, -20);
                          }}
                        >
                          <ScrollChevronIcon />
                        </div>
                        {/* HEADER SATIRI */}
                        <div className="flex flex-row w-full h-11 min-h-[44px] bg-[#F9FAFB] border-[#EAECF0] rounded-tl-xl rounded-tr-xl border-b">
                          <div className="flex items-center justify-center max-w-[356px] min-w-[356px] h-full border-r border-[#EAECF0] text-sm font-semibold text-[#344054]">
                            {t("routes:Product")}
                          </div>
                          <ScrollSyncPane>
                            <div className="flex items-center w-full h-full overflow-x-auto scrollbar-hide overflow-y-hidden">
                              <div className="min-w-[326px] min-h-[326px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
                                {t("product:purchasing")}
                              </div>
                              {tableHeadItems.map((item, index) => (
                                <div className="min-w-[326px] max-w-[326px] flex items-center justify-center text-sm font-semibold text-[#344054] border-r border-secondary-200">
                                  {item.title}
                                </div>
                              ))}
                            </div>
                          </ScrollSyncPane>
                        </div>
                        {/* PRODUCT SATIRI */}
                        <div className={`flex flex-col w-full ${height < 769 ? "h-[76vh]" : "h-[79vh]"}  overflow-y-auto scrollbar-hide`}>
                          {newProduct?.map((product, index) => {
                            const rawMaterialData = {
                              name: t("product:purchasing"),
                              id: product?.rawMaterial?.id,
                              source: product?.rawMaterial?.source,
                              materials: product?.rawMaterial?.type,
                              status: product?.rawMaterial?.status,
                            };
                            const filledOperations = [
                              rawMaterialData,
                              ...(product.operations || []),
                              ...Array.from(
                                {
                                  length: Math.max(tableHeadItemsLength - (product.operations || []).length, 0),
                                },
                                () => null
                              ),
                            ];
                            return (
                              <div key={index} className="flex w-full h-[112px]">
                                <div className="max-w-[356px] min-w-[356px] h-full px-4 py-2 border-r">
                                  <NewStockCard
                                    onClick={() => {
                                      navigate(`detail/${product?.id}`);
                                    }}
                                    key={index}
                                    product={product}
                                    image={product?.image}
                                    productName={
                                      product?.revisionNo
                                        ? product?.revisionNo != 0
                                          ? `${product?.name} (Rev-${product?.revisionNo})`
                                          : product?.name
                                        : product?.name
                                    }
                                    allData={product?.id}
                                    stockCode={
                                      product?.revisionNo
                                        ? product?.revisionNo != 0
                                          ? `REV-${product?.revisionNo}/${product?.stockCode} `
                                          : product?.stockCode
                                        : product?.stockCode
                                    }
                                    scrapQuantity={product?.scrapQuantity}
                                    qualityQuantity={product?.qualityQuantity}
                                  />
                                </div>
                                <ScrollSyncPane>
                                  <div id="content-wrapper" className="w-full overflow-x-auto scrollbar-hide flex ">
                                    {filledOperations
                                      ?.sort((a, b) => a?.step - b?.step)
                                      ?.map((operations, index) => {
                                        const operationName = operations?.process?.name;
                                        const occurrences =
                                          filledOperations.slice(0, index).filter((op) => op && op?.process?.name === operationName).length + 1;
                                        return (
                                          <div key={index} className={`min-w-[326px] max-w-[326px] px-4 py-2 border-r border-b`}>
                                            {index === 0 ? (
                                              <>
                                                <StockPurchaseCard
                                                  rawMaterialName={product?.rawMaterial?.name}
                                                  allData={product?.id}
                                                  materialStockPiece={product?.rawMaterialCount?.total}
                                                  acceptedPiece={product?.rawMaterialCount?.acceptedQuantity}
                                                  rejectedPiece={product?.rawMaterialCount?.rejectedQuantity}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                {operations ? (
                                                  <>
                                                    {operations?.type === "internal" ? (
                                                      <StockInternalOperationCard
                                                        operations={operations}
                                                        product={product}
                                                        name={occurrences > 1 ? `${operationName} ${occurrences}` : operationName}
                                                        acceptedPiece={operations?.activePiece || 0}
                                                        rejectedPiece={operations?.rejectedPiece || 0}
                                                        targetMinute={operations?.estimatedTime}
                                                        averageMinute={operations?.avgActualTime}
                                                      />
                                                    ) : (
                                                      <StockExternalOperationCard
                                                        operations={operations}
                                                        product={product}
                                                        name={occurrences > 1 ? `${operationName} ${occurrences}` : operationName}
                                                        allData={product?.id}
                                                        acceptedPiece={operations?.activePiece || 0}
                                                        rejectedPiece={operations?.rejectedPiece || 0}
                                                        estimatedTime={operations?.estimatedTime}
                                                        type={operations?.madeTransaction}
                                                      />
                                                    )}
                                                  </>
                                                ) : null}
                                              </>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </ScrollSyncPane>
                              </div>
                            );
                          })}
                        </div>
                      </>
                      <div className="z-50 border-t border-[#D0D5DD] bg-white rounded-b-xl">
                        <PaginationComponent pageNo={Number(pageNo) ? Number(pageNo) : 1} setPageNo={changePage} pageCount={pageCount} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </ScrollSync>
        <CustomModal
          isOpen={isNewProductModal}
          setIsOpen={setIsNewProductModal}
          onClose={() => {
            setIsNewProductModal(false);
          }}
          width="fit-content"
          modalTitle={t("product:createNewProduct")}
          isBadges={true}
          badgesValue={totalCount + "/" + auth?.user?.company?.limits?.stockCard}
          badgesColor={"fill-warning"}
          badgesSize={"md"}
          children={<NewProduct t={t} setIsOpen={setIsNewProductModal} />}
        />
      </>
      {/* <ReactJoyride
        steps={StockTour?.steps}
        continuous={true}
        disableOverlayClose={true}
        scrollToFirstStep={true}
        spotlightClicks={true}
        showProgress={true}
        // stepIndex={stepIndexState}
        showSkipButton={true}
        hideCloseButton={true}
        run={runState}
        callback={handleJoyrideCallback}
        locale={{
          next: t("buttons:next"),
          back: t("buttons:back"),
          skip: t("buttons:skip"),
          last: t("buttons:last"),
          close: t("buttons:close"),
        }}
        styles={StockTour?.styles}
      /> */}
    </>
  );
};
export default React.memo(Stock);
