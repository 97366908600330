import React from "react";
import DetailGaugeChart from "./chart/DetailGaugeChart";

const PersonelDetailCharts = ({ gaugeData }) => {

  console.log('gaugeData', gaugeData)
  return (
    <div>
      <div className="flex items-center justify-between border rounded-lg border-secondary-200 shadow mb-3 ">
        {gaugeData?.map((item, index) => {
          return (
            <DetailGaugeChart
              step={index + 1}
              gaugeData={gaugeData}
              key={index}
              index={index}
              name={item?.name}
              value={item?.actual ? item?.actual : 0}
              timeMass={item?.timeMass || 0}
              kpi1={item?.average?.toFixed(2) || 0}
              kpi2={item?.kpi || 0}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PersonelDetailCharts;
