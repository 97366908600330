import React, { useState } from "react";
import Linkify from "react-linkify";
import { ShineIcon, DeleteIcon } from "../../../assets/icons/taskCardIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";

const CommentLine = ({
  index,
  title,
  avatar,
  name,
  lastName,
  system,
  editMode,
  onClick,
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const number = editMode ? 100 : 100;
  const handleToggleText = () => {
    setShowFullText(!showFullText);
  };
  const renderText = () => {
    if (showFullText) {
      return (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target="blank"
              href={decoratedHref}
              key={key}
              style={{ textDecorationLine: "underline" }}
              className="hover:text-[#1570EF] inline"
            >
              {decoratedText}
            </a>
          )}
        >
          {title}
        </Linkify>
      );
    }
    if (title.length > number) {
      return title.slice(0, number) + "...";
    }
    return title;
  };
  const renderButton = () => {
    if (title.length > number) {
      return (
        <button
          className="text-blue-500 text-xs inline ml-0.5 hover:underline select-none dark:text-[#15B79E]"
          onClick={handleToggleText}
        >
          {!showFullText ? `Show more` : `Less`}
        </button>
      );
    }
    return null;
  };
  return (
    <div
      style={{ backgroundColor: index % 2 === 0 ? "#F9FAFB" : "#fff" }}
      className={`w-full flex mb-0.5 px-3 min-h-[44px] py-[6px] h-fit dark:bg-[#292929] overflow-hidden rounded-md items-center ${index === 0 ? "mt-2" : ""}`}
    >
      {system ? (
        <div className="min-w-[32px] h-8 flex items-center justify-center">
          <ShineIcon />
        </div>
      ) : avatar ? (
        <img
          className="w-8 h-8 min-w-[32px] max-w-[32px] blur-sm max-h-[32px] min-h-[32px] rounded-full dark:border dark:border-[#424242]"
          src={generateFileUrl(avatar)}
        />
      ) : (
        <NoAvatar
          name={name}
          lastName={lastName}
          size={32}
          fontSize={14}
          color={"#15B79E"}
        />
      )}

      <p
        className={`text-sm font-normal text-[#475467] ml-2`}
      >
        {renderText()} {renderButton()}
      </p>

      {editMode && (
        <button
          onClick={onClick}
          className="min-w-[20px] h-full  flex items-center justify-center ml-auto"
        >
          <DeleteIcon />
        </button>
      )}
    </div>
  );
};

export default CommentLine;
