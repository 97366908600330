import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { PlusIcon } from "../../pages/product/ProductOperationDetailHeader";
import { useTranslation } from "react-i18next";
import AlertModal from "../alert-Modal/Alert-Modal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import {
  EquipmentDetailIcon,
  NoEquIcon,
} from "../../assets/icons/machineIcons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Badgets from "../buttons/Badgets";

const ActiveAndPlannedCard = ({
  id,
  image,
  name,
  estimatedTime,
  piece,
  user,
  rightButton,
  status,
  type,
  pages,
  openCard,
  startDate,
  onClick,
  refetch,
  junctionData,
  deletePlannedInternal,
  process,
  step,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const navigate = useNavigate();

  const [isAlert, setIsAlert] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const menuOptions = [
    // { label: t("Move Junction"), value: "details", icon: <ArrowRight /> },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const menuOptionsPlanned = [
    // { label: t("Move Junction"), value: "details", icon: <ArrowRight /> },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];
  const menuOptionsEquipment = [
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const onDeleted = async () => {
    if (deletePlannedInternal) {
      await junctionService.deletePlannedJunction(id).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    } else {
      await junctionService.deleteJunction(id, deleteId).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    }
  };

  return (
    <>
      <div
        className={`flex w-full h-[80px]  items-center rounded border border-t-[4px] group relative ${
          type == "work"
            ? "border-success-500"
            : type === "setup"
            ? "border-[#EAAA08]"
            : "border-[#98A2B3]"
        } p-2 shadow-xs max-h-[80px] min-h-[80px] bg-white`}
      >
        {image ? (
          <img
            data-tooltip-id={`${id}`}
            data-tooltip-place={"right"}
            data-tooltip-content={`${t("addProcess:equipmentName")}: ${name.slice(0,3)+"***"}`}
            onClick={openCard}
            src={generateFileUrl(image)}
            className="w-[72px] min-w-[72px] cursor-pointer h-[54px] rounded border border-secondary-300 "
          />
        ) : (
          <div className="w-[72px] min-w-[72px] h-[54px] rounded border border-secondary-300 bg-secondary-50 shadow-sm flex items-center justify-center">
            <NoEquIcon />
          </div>
        )}

        <div
          onClick={onClick}
          className="flex-1 flex cursor-pointer ml-2 flex-col gap-0.5 overflow-hidden"
        >
          <div className="w-full overflow-hidden">
            <p className="truncate text-sm text-secondary-700 font-semibold">
              {name || "---"}
            </p>
          </div>
          <div className="flex flex-row gap-2 items-start">
            <div className="w-full flex flex-col items-start gap-0.5 overflow-hidden">
              <div className="flex flex-row gap-1">
                <ClockIcon />
                <p className="text-xs font-normal text-secondary-600">
                  {estimatedTime || "---"}{" "}
                  <span className={`${status == "active" ? "" : "hidden"}`}>
                    {t("product:timeStarted")}
                  </span>
                </p>
              </div>

              <div className="flex flex-row gap-1">
                <PieceIcon />
                <p className="text-xs font-normal text-secondary-600">
                  {piece || "---"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[3px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute ${
              pages === "equipmentDetail"
                ? "top-[2px] right-[32px]"
                : "top-[32px] right-[3px]"
            } min-w-[200px] max-w-[200px] h-fit bg-white border   z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {pages === "persennelConsole" ? (
              <>
                {menuOptionsPlanned?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }

                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : pages === "equipmentDetail" ? (
              <>
                {menuOptionsEquipment?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              user?.id
                            }/${moment(startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {status === "active" ? (
                  <>
                    {menuOptions?.map((item, index) => {
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.value === "delete") {
                              setIsAlert(true);
                              setDeleteId(id);
                            }
                            if (item.value === "personnel") {
                              navigate(
                                `/app/machine/digital-twin/user-detail/${
                                  user?.id
                                }/${moment(startDate).format("YYYY-MM-DD")}`
                              );
                            }
                            if (item.value === "equipmentDetail") {
                              openCard();
                            }
                            setShowDropDown(false);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            item?.value === "delete"
                              ? "hover:bg-error-50"
                              : "hover:bg-secondary-100"
                          }`}
                        >
                          {item?.icon}
                          <p
                            className={`font-medium text-sm  ${
                              item?.value === "delete"
                                ? "text-[#D92D20]"
                                : "text-secondary-700"
                            }`}
                          >
                            {item.label}
                          </p>
                        </button>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {menuOptionsPlanned?.map((item, index) => {
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.value === "delete") {
                              setIsAlert(true);
                              setDeleteId(id);
                            }

                            setShowDropDown(false);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            item?.value === "delete"
                              ? "hover:bg-error-50"
                              : "hover:bg-secondary-100"
                          }`}
                        >
                          {item?.icon}
                          <p
                            className={`font-medium text-sm  ${
                              item?.value === "delete"
                                ? "text-[#D92D20]"
                                : "text-secondary-700"
                            }`}
                          >
                            {item.label}
                          </p>
                        </button>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {user ? (
          user?.avatar ? (
            <div
              onClick={() => {
                navigate(
                  `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                    startDate
                  ).format("YYYY-MM-DD")}`
                );
              }}
              // onClick={rightButton}
              data-tooltip-id={`${id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${user?.name.slice(0,3)+"***" + " " + user?.lastName.slice(0,3)+"***"} `}
              className="ml-auto relative"
            >
              <img
                className="w-[54px] min-w-[54px] cursor-pointer h-[54px] -mt-2 rounded-full border border-gray-300"
                src={generateFileUrl(user?.avatar)}
              />
            </div>
          ) : (
            <div
              className="cursor-pointer ml-auto relative"
              onClick={() => {
                navigate(
                  `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                    startDate
                  ).format("YYYY-MM-DD")}`
                );
              }}
              data-tooltip-id={`${id}`}
              data-tooltip-place={"left"}
              data-tooltip-content={`${user?.name.slice(0,3)+"***" + " " + user?.lastName.slice(0,3)+"***"} `}
            >
              <NoAvatar
                size={54}
                fontSize={22}
                name={user?.name}
                lastName={user?.lastName}
                color={"#475467"}
              />
            </div>
          )
        ) : (
          <button
            onClick={''}
            type="button"
            className="min-w-[50px] h-[50px] rounded-full border-dashed border border-gray-300 flex items-center justify-center mt-1"
          >
            <PlusIcon />
          </button>
        )}
        {step == undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets colorType={"fill-gray"} label={step + ".op"} size={"sm"} />
          </span>
        )}

        <Tooltip className="z-[100]" id={`${id}`} />
      </div>
      {/* <Tooltip id={`${id}`} /> */}
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
        }}
      />
    </>
  );
};

export const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.33268 4.00016V8.00016L10.9993 9.3335M14.9993 8.00016C14.9993 11.6821 12.0146 14.6668 8.33268 14.6668C4.65078 14.6668 1.66602 11.6821 1.66602 8.00016C1.66602 4.31826 4.65078 1.3335 8.33268 1.3335C12.0146 1.3335 14.9993 4.31826 14.9993 8.00016Z"
        stroke="#475467"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PieceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M6.41699 2.3335H4.38128C3.4345 2.3335 2.66699 3.10101 2.66699 4.04778V11.8692C2.66699 12.816 3.4345 13.5835 4.38128 13.5835H12.9527C13.8995 13.5835 14.667 12.816 14.667 11.8692V4.04778C14.667 3.10101 13.8995 2.3335 12.9527 2.3335H10.917M6.41699 2.3335V6.69481C6.41699 6.75853 6.48405 6.79997 6.54104 6.77147L8.66699 5.7085L10.7929 6.77147C10.8499 6.79997 10.917 6.75853 10.917 6.69481V2.3335M6.41699 2.3335H10.917"
        stroke="#475467"
      />
    </svg>
  );
};
export const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_2331_179)">
        <path
          d="M2.22518 4.66634C3.37788 2.67367 5.53236 1.33301 7.99997 1.33301C11.6819 1.33301 14.6666 4.31778 14.6666 7.99967C14.6666 11.6816 11.6819 14.6663 7.99997 14.6663C5.53236 14.6663 3.37788 13.3257 2.22518 11.333M7.99992 10.6663L10.6666 7.99967M10.6666 7.99967L7.99992 5.33301M10.6666 7.99967H1.33325"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2331_179">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.6667 3.99967V3.46634C10.6667 2.7196 10.6667 2.34624 10.5213 2.06102C10.3935 1.81014 10.1895 1.60616 9.93865 1.47833C9.65344 1.33301 9.28007 1.33301 8.53333 1.33301H7.46667C6.71993 1.33301 6.34656 1.33301 6.06135 1.47833C5.81046 1.60616 5.60649 1.81014 5.47866 2.06102C5.33333 2.34624 5.33333 2.7196 5.33333 3.46634V3.99967M6.66667 7.66634V10.9997M9.33333 7.66634V10.9997M2 3.99967H14M12.6667 3.99967V11.4663C12.6667 12.5864 12.6667 13.1465 12.4487 13.5743C12.2569 13.9506 11.951 14.2566 11.5746 14.4484C11.1468 14.6663 10.5868 14.6663 9.46667 14.6663H6.53333C5.41323 14.6663 4.85318 14.6663 4.42535 14.4484C4.04903 14.2566 3.74307 13.9506 3.55132 13.5743C3.33333 13.1465 3.33333 12.5864 3.33333 11.4663V3.99967"
        stroke="#D92D20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const DotsVerticalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0001 10.833C10.4603 10.833 10.8334 10.4599 10.8334 9.99967C10.8334 9.53944 10.4603 9.16634 10.0001 9.16634C9.53984 9.16634 9.16675 9.53944 9.16675 9.99967C9.16675 10.4599 9.53984 10.833 10.0001 10.833Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 4.99967C10.4603 4.99967 10.8334 4.62658 10.8334 4.16634C10.8334 3.7061 10.4603 3.33301 10.0001 3.33301C9.53984 3.33301 9.16675 3.7061 9.16675 4.16634C9.16675 4.62658 9.53984 4.99967 10.0001 4.99967Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 16.6663C10.4603 16.6663 10.8334 16.2932 10.8334 15.833C10.8334 15.3728 10.4603 14.9997 10.0001 14.9997C9.53984 14.9997 9.16675 15.3728 9.16675 15.833C9.16675 16.2932 9.53984 16.6663 10.0001 16.6663Z"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActiveAndPlannedCard;
