import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as PauseIcon } from "./svg/PauseIcon.svg";
import { ReactComponent as PlayIcon } from "./svg/PlayIcon.svg";
import { useTimer } from "react-timer-hook";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { embeddedServerService } from "../../../services/embedded-server.service";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";

const AnalogSensorChart = ({
  standBy,
  closeBy,
  phase,
  setStep,
  deviceId,
  maxValue,
  minValue,
  getChartData,
  currentValues,
  activeKva,
}) => {
  const { t } = useTranslation();
  const chartRef = useRef();
  const [dates, setDates] = useState([]);
  const [datas, setDatas] = useState([]);
  const [filterData, setFilterDatas] = useState([]);
  const channel = useChannel(deviceId);
  useEvent(channel, "analog-real-time-chart-data", (socketEvent) => {
    calculateDatas(socketEvent);
    currentValues(socketEvent);
  });
  const { seconds, minutes, pause, isRunning, start, restart } = useTimer({
    onExpire: () => {
      getChartData(0);
      setStep(0);
    },
  });
  const calculateDatas = (data) => {
    setDatas([...datas, ...data.rawIntegralValue]);
    setFilterDatas([...filterData, ...data.filterIntegralValue]);
    const now = new Date();
    setDates([
      ...dates,
      ...[
        `${now.getHours()}:${now.getMinutes()} - ${now.getMilliseconds() + 0} `,
        `${now.getHours()}:${now.getMinutes()} - ${
          now.getMilliseconds() + 50
        } `,
        `${now.getHours()}:${now.getMinutes()} - ${
          now.getMilliseconds() + 100
        } `,
        `${now.getHours()}:${now.getMinutes()} - ${
          now.getMilliseconds() + 150
        } `,
        `${now.getHours()}:${now.getMinutes()} - ${
          now.getMilliseconds() + 200
        } `,
      ],
    ]);
  };

  const option = {
    renderer: "svg",
    ssr: true,
    animationDuration: 0,
    animationThreshold: 0,
    animation: false,
    tooltip: {
      trigger: "axis",
    },
    legend: {},
    grid: {
      left: "5%",
      right: "4%",
      bottom: "12%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: dates,
      axisLine: { onZero: false },
    },
    yAxis: {
      type: "value",
      min: Number(minValue),
      max: phase === 3 ? Number(maxValue) * 3 : Number(maxValue),
      animationThreshold: 0,
      large: true,
    },
    dataZoom: [
      {
        type: "slider",
        xAxisIndex: 0,
        bottom: 8,
        height: 20,
        filterMode: "none",
      },
      {
        type: "slider",
        yAxisIndex: 0,
        width: 20,
        filterMode: "none",
      },
    ],
    series: [
      {
        name: "Raw Current",
        type: "line",
        stack: "es",
        data: datas,
        animation: false,
        markLine: {
          data: [
            {
              name: "Stand By",
              lineStyle: {
                normal: {
                  type: "solid",
                  color: "green",
                  formatter: "{b}:{c}",
                },
              },
              label: {
                show: true,
                position: "middle",
                formatter: function (d) {
                  return d.name + "   " + d.value;
                },
              },
              yAxis: Number(standBy),
            },
            {
              name: "Close By",
              lineStyle: {
                normal: {
                  type: "solid",
                  color: "red",
                  formatter: "{b}:{c}",
                },
              },
              label: {
                show: true,
                position: "middle",
                formatter: function (d) {
                  return d.name + "   " + d.value;
                },
              },
              yAxis: Number(closeBy),
            },
          ],
        },
      },
      {
        name: "FilterCurrent",
        type: "line",
        data: filterData,
      },
    ],
  };

  useEffect(() => {
    getChartData(1);
  }, []);

  useEffect(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 180);
    restart(time);
  }, []);

  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex items-center mb-3">
        <div
          className="w-8 h-8 flex items-center justify-center cursor-pointer"
          onClick={() => {
            if (isRunning) {
              pause();
              getChartData(0);
            } else {
              start();
              getChartData(1);
            }
          }}
        >
          {!isRunning ? <PlayIcon /> : <PauseIcon />}
        </div>

        <div className="flex items-center w-[190px]">
          <p className="text-secondary-700 text-base mr-2">
            {t("embedded:timeRemaining")}
          </p>
          <p className="font-semibold text-base text-secondary-600">
            {minutes}:{seconds}
          </p>
        </div>
      </div>
      <div
        className={`${
          activeKva ? "h-[286px] min-h-[286px]" : "min-h-[326px]"
        } w-full  border border-gray-300 rounded-2xl bg-white`}
      >
        <div
          className="min-w-full flex items-start justify-start"
          style={{
            height: "100%",
            minWidth: "100%",
          }}
        >
          <ReactECharts
            ref={chartRef}
            option={option}
            lazyUpdate={false}
            renderer="canvas"
            waveAnimation={false}
            style={{
              height: activeKva ? "266px" : "306px",
              width: "854px",
              marginLeft: "-24px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(AnalogSensorChart);
