import React, { useEffect, useRef } from "react";
import EChartsReact from "echarts-for-react";
import {
  ArrowRight,
  AverageLeftIcon,
  AverageRightIcon,
  TableArrowLeft,
} from "../../../../assets/icons/machineIcons";
import {
  Arrow,
  ArrowDownIcon,
  ArrowUpRight,
} from "../../../../assets/icons/stepsIcons";
const GradeChart = ({
  width,
  height,
  radius,
  value,
  kpi1,
  kpi2,
  isIncreaseStatus,
  timeMass,
}) => {
  const chartRef = useRef(null);

  //adjust arch in center
  const xAxis = width / 2;
  const yAxis = height / 1.3;

  const getOption = {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        center: [xAxis, yAxis],
        radius: radius, //used to adjust arch size
        min: 0,
        max: 100,
        splitNumber: !value ? 4 : 4,
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 5,
            fontWeight: "bold",
            color:
              !value && !kpi1 && !kpi2
                ? [
                    [
                      1,
                      localStorage.color_theme === "dark"
                        ? "#525252"
                        : "#D0D5DD",
                    ],
                  ]
                : [
                    [kpi1, "#F22032"],
                    [kpi2, "#F79919"],
                    [1, "#02C851"],
                  ],
          },
        },

        pointer: {
          // icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          // length: "13%",
          // width: 3,
          // offsetCenter: [0, "-62%"],
          // itemStyle: {
          //   color: "auto",
          // },
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z'",
          length: "16%",
          width: 4,
          offsetCenter: [0, "-60%"],
          itemStyle: {
            color: "auto",
          },
        },
        axisTick: {
          length: 2,
          distance: 2,
          lineStyle: {
            color: "auto",
            width: 0.5,
          },
        },
        splitLine: {
          length: 3,
          distance: 3,
          lineStyle: {
            color: "auto",
            width: 0.8,
          },
        },
        axisLabel: {
          color: "#464646",
          fontSize: 20,
          distance: -60,
          rotate: "tangential",
          formatter: function (value) {
            if (value === 0.875) {
              return "Grade A";
            } else if (value === 0.625) {
              return "Grade B";
            } else if (value === 0.375) {
              return "Grade C";
            } else if (value === 0.125) {
              return "Grade D";
            }
            return "";
          },
        },
        title: {
          offsetCenter: [0, "-25%"],
          fontSize: 9,
          fontWeight: 400,
          color: "#455467",
        },
        detail: {
          fontSize: width <= 111 ? 8 : 16,
          fontWeight: 600,

          offsetCenter: [value > 0 ? 4 : 5, "15%"],
          valueAnimation: true,
          formatter: function (value) {
            return "%" + Math.round(value);
          },
          color: "auto",
        },
        data: [
          {
            value: value * 100 || 0,
            name: timeMass + " h",
            fontSize: 5,
            fontWeight: "bold",
          },
        ],
      },
    ],
  };

  useEffect(() => {}, [chartRef]);

  return (
    <div
      className="w-full flex flex-col relative h-full items-center"
      id="main"
      style={{
        width: width,
        height: height,
      }}
    >
      <EChartsReact
        style={{
          width: width,
          height: height,
        }}
        option={getOption}
      />
      <div className="absolute text-[#667085] text-[8px] left-[19px] top-[78px] font-medium dark:text-[#E5E5E5]">
        %0
      </div>
      <div className="absolute text-[#667085] text-[8px] right-[24px] top-[78px] font-medium dark:text-[#E5E5E5]">
        %100
      </div>
      <div className="flex w-full mt-auto  justify-center bg-[#F9FAFB] gap-x-6 pb-[8px] h-6 py-1">
        <div className="flex items-center gap-x-1">
          <AverageLeftIcon />
          <div className="flex items-center gap-x-[2px]">
            <p className="text-secondary-700 font-medium text-xxs">
              %{(kpi1 * 100).toFixed(0)}
            </p>
            <p className="text-secondary-600 font-normal text-xxs">avg</p>
          </div>
        </div>
        <div className="flex items-center gap-x-1">
          <div className="flex items-center gap-x-[2px]">
            <p className="text-secondary-600 font-normal text-xxs">max</p>
            <p className="text-secondary-700 font-medium text-xxs">
              %{parseInt(kpi2 * 100) > 100 ? 100 : parseInt(kpi2 * 100)}
            </p>
          </div>
          <AverageRightIcon />
        </div>
      </div>

      <div className={`absolute left-[50px] gap-auto top-[75px] `}>
        {isIncreaseStatus === true ? (
          <div className={` text-[#02C851] dark:text-[#12B76A] rotate-90`}>
            {" "}
            <TableArrowLeft />
          </div>
        ) : (
          <div
            className={`${
              !value && !kpi1 && !kpi2 ? "text-[#D0D5DD]" : "text-[#F22032]"
            }  dark:text-[#F04438] -rotate-90 `}
          >
            <TableArrowLeft />
          </div>
        )}
      </div>
    </div>
  );
};

export default GradeChart;
