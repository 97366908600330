import React, { memo } from "react";

const TaskStatusBar = ({ data }) => {
  let totalTask = 0;
  let totalTodoLenght = 0;
  let totalDoneLength = 0;
  let totalApprovedLength = 0;
  let totalExpiredTask = 0;

  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.hasOwnProperty("totalLength")) {
      totalTask += data[i]?.totalLength;
    }
  }
  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.hasOwnProperty("todoLength")) {
      totalTodoLenght += data[i]?.todoLength;
    }
  }
  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.hasOwnProperty("doneLength")) {
      totalDoneLength += data[i]?.doneLength;
    }
  }
  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.hasOwnProperty("approvedLength")) {
      totalApprovedLength += data[i]?.approvedLength;
    }
  }
  for (let i = 0; i < data?.length; i++) {
    if (data[i]?.hasOwnProperty("expiredLength")) {
      totalExpiredTask += data[i]?.expiredLength;
    }
  }
  const statusBar = [
    {
      label: "Total",
      length: totalTask,
      color: "#6941C6",
    },
    {
      label: "To Do",
      length: totalTodoLenght,
      color: "#475467",
    },
    {
      label: "Done",
      length: totalDoneLength,
      color: "#CA8504",
    },
    {
      label: "Appro.",
      length: totalApprovedLength,
      color: "#039855",
    },
    {
      label: "Expired",
      length: totalExpiredTask,
      color: "#D92D20",
    },
  ];
  return (
    <div className="flex w-full h-9 flex-row items-center justify-between">
      {statusBar.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="w-11 h-9 items-center  justify-between "
            >
              <p
                style={{
                  color: item?.color,
                }}
                className="font-semibold text-sm text-center"
              >
                {item?.length}
              </p>
              <div className="w-full h-[1px] bg-secondary-200" />
              <p
                style={{
                  color: item?.color,
                }}
                className="text-xs font-semibold text-center"
              >
                {item?.label}
              </p>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default memo(TaskStatusBar);
