import React, { memo, useState } from "react";
import { PersonIcon } from "../assets/icons";
import { NavLink } from "react-router-dom";
import {
  AttendIcon,
  MessageIcon,
  NotifyIcon,
  PollIcon,
  TaskIcon,
  UpuPersonLightText,
} from "../assets/icons/PersonIcons";
import { personalService } from "../services/personal.service";
import { useQuery } from "@tanstack/react-query";
import NoAvatar from "./avatar/NoAvatar";
import { useTranslation } from "react-i18next";
import generateFileUrl from "../utils/generateFileUrl";
// import { Tooltip } from "react-tooltip";

const RightMenu = () => {
  const { t } = useTranslation();
  const [isLarge, setIsLarge] = useState(false);
  const menuItems = [
    {
      name: "message",
      icon: MessageIcon,
      href: "person/message",
    },
    { name: "task", icon: TaskIcon, href: "person/task" },
    { name: "notify", icon: NotifyIcon, href: "person/notify" },
    { name: "poll", icon: PollIcon, href: "person/poll" },
    { name: "attend", icon: AttendIcon, href: "person/attend" },
  ];

  const { isLoading, data, refetch } = useQuery({
    queryKey: ["activeRightMenuList"],
    queryFn: async () => await personalService.activeRightMenuList(),
    retry: 0,
  });

  return (
    <>
      <div
        className={`fixed flex flex-col transition-[400ms] duration-200 overflow-hidden items-center z-[100] right-0 top-9 h-[93.8vh] bg-white rounded-l-lg border border-secondary-200 md:hidden sm:hidden xs:hidden ${
          !isLarge ? "w-[80px] shadow-md" : "w-[248px] shadow-2xl px-4"
        }`}
      >
        {isLarge ? (
          <>
            <div className="flex items-center h-16">
              <PersonIcon />
              <div className="mt-1.5 ml-3">
                <UpuPersonLightText />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-16 flex items-center justify-center">
              <PersonIcon />
            </div>
          </>
        )}
        {menuItems.map((item, index) => {
          return (
            <NavLink
              to={item.href}
              key={index}
              className={({ isActive }) =>
                `mb-2 flex items-center rounded-lg hover:bg-teal-50 group ${
                  isLarge ? "w-full h-10 px-1" : "justify-center h-10 w-10"
                } ${isActive ? "bg-teal-50" : "bg-white"}`
              }
            >
              {!isLarge
                ? ({ isActive }) => (
                    <div
                      className={`group-hover:text-teal-700  ${
                        isActive ? "text-teal-700" : "text-gray-500"
                      }`}
                    >
                      <item.icon color={isActive ? "#107569" : "#475467"} />
                    </div>
                  )
                : ({ isActive }) => (
                    <div className="flex items-center">
                      <div
                        className={`w-10 -translate-x-1 flex items-center justify-center ${
                          isActive ? "text-teal-700" : "text-gray-500"
                        } group-hover:text-teal-700 `}
                      >
                        <item.icon color={isActive ? "#107569" : "#475467"} />
                      </div>
                      <p
                        className={`font-codec group-hover:text-teal-700 ${
                          isActive ? "text-teal-700" : "text-secondary-600"
                        }`}
                      >
                        {item.name}
                      </p>
                    </div>
                  )}
            </NavLink>
          );
        })}
        <div className="flex w-full flex-col items-center overflow-y-auto scrollbar-hide flex-1 mb-2  dark:border-b-[#292929] border-secondary-200">
          <>
            <span className="text-[10px] text-secondary-700 font-semibold mb-2 py-1 w-full bg-secondary-100 text-center dark:text-[#D6D6D6] dark:bg-[#292929]">
              {t("chat:admin")}
            </span>
            {data?.data?.map((item, i) => {
              return (
                item?.rolesName === "ADMIN" && (
                  <span
                    className="flex items-center mb-2 justify-center "
                    key={i}
                    // data-tooltip-id={`${item?.id}`}
                    // data-tooltip-float="left"
                    // data-tooltip-class-name="text-[9px]"
                    // data-tooltip-content={`${item?.name}`}
                  >
                    {!item?.avatar ? (
                      <NoAvatar
                        name={item.name}
                        lastName={item?.lastName}
                        theme={"green"}
                        size={40}
                        fontSize={18}
                      />
                    ) : (
                      <img
                        className="h-10 w-10 min-h-8 min-w-8 rounded-full"
                        src={generateFileUrl(item?.avatar)}
                        width={80}
                        height={80}
                        alt={
                          item.name?.charAt(0).toUpperCase() +
                          item.lastName?.charAt(0)
                        }
                      />
                    )}
                    {/* <Tooltip id={`${item?.id}`} /> */}
                  </span>
                )
              );
            })}

            <span className="text-[10px] text-secondary-700 font-medium mb-2 py-1 w-full bg-secondary-100 text-center dark:text-[#D6D6D6] dark:bg-[#292929]">
              {t("routes:processResponsible")}
            </span>
            {data?.data?.map((item, i) => {
              return (
                item?.rolesName === "OPERATION_RESPONSIBLE" && (
                  <span
                    className="flex items-center mb-2 justify-center "
                    key={i}
                  >
                    {!item?.avatar ? (
                      <NoAvatar
                        name={item.name}
                        lastName={item?.lastName}
                        theme={"green"}
                        size={40}
                        fontSize={18}
                      />
                    ) : (
                      <img
                        className="h-10 w-10 min-h-8 min-w-8 rounded-full"
                        src={generateFileUrl(item?.avatar)}
                        width={80}
                        height={80}
                        alt={
                          item.name?.charAt(0).toUpperCase() +
                          item.lastName?.charAt(0)
                        }
                      />
                    )}
                  </span>
                )
              );
            })}

            <span className="text-[10px] text-secondary-700 font-medium mb-2 py-1 w-full bg-secondary-100 text-center dark:text-[#D6D6D6] dark:bg-[#292929]">
              {t("addShift:supervisor")}
            </span>
            {data?.data?.map((item, i) => {
              return (
                item?.rolesName === "SHIFT_RESPONSIBLE" && (
                  <span
                    className="flex items-center mb-2 justify-center "
                    key={i}
                  >
                    {!item?.avatar ? (
                      <NoAvatar
                        name={item.name}
                        lastName={item?.lastName}
                        theme={"green"}
                        size={40}
                        fontSize={18}
                      />
                    ) : (
                      <img
                        className="h-10 w-10 min-h-8 min-w-8 rounded-full"
                        src={generateFileUrl(item?.avatar)}
                        width={80}
                        height={80}
                        alt={
                          item.name?.charAt(0).toUpperCase() +
                          item.lastName?.charAt(0)
                        }
                      />
                    )}
                  </span>
                )
              );
            })}

            <span className="text-[10px] text-secondary-700 font-medium mb-2 py-1 w-full bg-secondary-100 text-center dark:text-[#D6D6D6] dark:bg-[#292929]">
              {t("chat:qualityControlResponsible")}
            </span>
            {data?.data?.map((item, i) => {
              return (
                item?.rolesName === "QUALITY_CONTROL_RESPONSIBLE" && (
                  <span
                    className="flex items-center mb-2 justify-center "
                    key={i}
                  >
                    {!item?.avatar ? (
                      <NoAvatar
                        name={item.name}
                        lastName={item?.lastName}
                        theme={"green"}
                        size={40}
                        fontSize={18}
                      />
                    ) : (
                      <img
                        className="h-10 w-10 min-h-8 min-w-8 rounded-full"
                        src={generateFileUrl(item?.avatar)}
                        width={80}
                        height={80}
                        alt={
                          item.name?.charAt(0).toUpperCase() +
                          item.lastName?.charAt(0)
                        }
                      />
                    )}
                  </span>
                )
              );
            })}

            <span className="text-[10px] text-secondary-700 font-medium mb-2 py-1 w-full bg-secondary-100 text-center dark:text-[#D6D6D6] dark:bg-[#292929]">
              {t("chat:operators")}
            </span>
            {data?.data?.map((item, i) => {
              return (
                item?.rolesName === "OPERATOR" && (
                  <span
                    className="flex items-center mb-2 justify-center "
                    key={i}
                  >
                    {!item?.avatar ? (
                      <NoAvatar
                        name={item.name}
                        lastName={item?.lastName}
                        theme={"green"}
                        size={40}
                        fontSize={18}
                      />
                    ) : (
                      <img
                        className="h-10 w-10 min-h-8 min-w-8 rounded-full"
                        src={generateFileUrl(item?.avatar)}
                        width={80}
                        height={80}
                        alt={
                          item.name?.charAt(0).toUpperCase() +
                          item.lastName?.charAt(0)
                        }
                      />
                    )}
                  </span>
                )
              );
            })}
          </>
        </div>
      </div>
      {/* <div
        onClick={() => {
          setIsLarge(false);
        }}
        style={{ display: !isLarge ? "none" : "block" }}
        className="fixed z-[950] top-0 left-0 right-0 bottom-0 w-screen h-screen"
      /> */}
    </>
  );
};

export default memo(RightMenu);
