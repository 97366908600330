import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { stepData } from "./StepHeaderTab";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import { observer } from "mobx-react-lite";

const StepHeader = observer(() => {
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScreen = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("screen", handleScreen);
    return () => {
      window.removeEventListener("screen", handleScreen);
    };
  }, []);

  const size = screenWidth > 767 ? "md" : "sm";

  //active index
  const activeStepIndex = stepData?.findIndex((step) =>
    pathname?.includes(step?.path)
  );

  //inactive Steps
  const previousStep = activeStepIndex > 0 ? activeStepIndex - 1 : null;
  const nextStep =
    activeStepIndex < stepData?.length - 1 ? activeStepIndex + 1 : null;

  return (
    <div className="flex min-w-full items-center justify-center py-2">
      <div
        className={`flex items-center justify-center px-8 py-3 gap-2 w-[86%] xs:hidden `}
      >
        {stepData?.map((header, index) => {
          // for sm screen
          if (window.innerWidth < 768) {
            if (
              index === previousStep ||
              index === nextStep ||
              index === activeStepIndex
            ) {
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      display:
                        header?.visible == false
                          ? "none"
                          : index === previousStep
                          ? "flex"
                          : index === nextStep
                          ? "flex"
                          : index === activeStepIndex
                          ? "flex"
                          : "none",
                    }}
                    className={` flex-col justify-center items-center gap-1 `}
                  >
                    <div
                      className={`flex flex-row items-center justify-center px-8 py-4`}
                    >
                      <NavLink to={header?.path} key={index}>
                        {({ isActive }) => {
                          return (
                            <div
                              className={`flex items-center justify-center w-full`}
                            >
                              <div
                                className={`flex flex-col items-center gap-2`}
                              >
                                <Button
                                  size={"2xl"}
                                  colorType={
                                    isActive
                                      ? "primary-machine"
                                      : "secondary-machine"
                                  }
                                  iconLeft={
                                    isActive ? (
                                      <header.activeIcon />
                                    ) : (
                                      <header.icon />
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        }}
                      </NavLink>
                      <div
                        style={
                          pathname.includes(header.path)
                            ? { marginLeft: 16 }
                            : {}
                        }
                        className={`flex flex-col items-start`}
                      >
                        {pathname.includes(header.path) && (
                          <>
                            <p
                              className={`text-[14px] font-normal leading-5 text-[#667085] dark:text-[#D6D6D6]`}
                            >
                              Step {index + 1}/{stepData.length - 1}
                            </p>

                            <div>
                              <p
                                className={`font-semibold text-center text-[#344054] text-[18px] leading-7 whitespace-nowrap dark:text-[#D6D6D6]`}
                              >
                                {t(`${header.name}`)}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={``}>
                      {pathname.includes(header.path) ? (
                        <div className="min-w-[236px] max-w-[236px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
                      ) : index <
                        stepData.findIndex((d) => pathname.includes(d.path)) ? (
                        <div className="min-w-[120px] max-w-[120px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
                      ) : (
                        <div className="min-w-[120px] max-w-[120px] h-1 bg-[#EAECF0] dark:bg-[#292929] rounded" />
                      )}
                    </div>
                  </div>
                  <div>
                    {index < stepData.length - 2 && (
                      <div className=" h-[88px] xs:h-[60px] sm:h-[70px] md:h-[70px] top-0 w-px bg-secondary-200 dark:bg-[#292929] -right-1 xs:hidden" />
                    )}
                  </div>
                </React.Fragment>
              );
            }
            //for md screen size
          } else if (window.innerWidth > 767 && window.innerWidth < 1024) {
            if (
              index === previousStep ||
              index === previousStep - 1 ||
              index === nextStep ||
              index === nextStep + 1 ||
              index === activeStepIndex
            ) {
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      display: header?.visible == false ? "none" : "flex",
                    }}
                    className={` flex-col justify-center items-center gap-1`}
                  >
                    <div
                      className={`flex flex-row items-center justify-center px-8 py-4`}
                    >
                      <NavLink to={header?.path} key={index}>
                        {({ isActive }) => {
                          return (
                            <div
                              className={`flex items-center justify-center w-full`}
                            >
                              <div
                                className={`flex flex-col items-center gap-2`}
                              >
                                <Button
                                  size={"2xl"}
                                  colorType={
                                    isActive
                                      ? "primary-machine"
                                      : "secondary-machine"
                                  }
                                  iconLeft={
                                    isActive ? (
                                      <header.activeIcon />
                                    ) : (
                                      <header.icon />
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        }}
                      </NavLink>
                      <div
                        style={
                          pathname.includes(header.path)
                            ? { marginLeft: 16 }
                            : {}
                        }
                        className={`flex flex-col items-start`}
                      >
                        {pathname.includes(header.path) && (
                          <>
                            <p
                              className={`text-[14px] font-normal leading-5 text-[#667085] dark:text-[#D6D6D6]`}
                            >
                              Step {index + 1}/{stepData.length - 1}
                            </p>

                            <div>
                              <p
                                className={`font-semibold text-center text-[#344054] text-[18px] leading-7 whitespace-nowrap dark:text-[#D6D6D6]`}
                              >
                                {t(`${header.name}`)}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={``}>
                      {pathname.includes(header.path) ? (
                        <div className="min-w-[236px] max-w-[236px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
                      ) : index <
                        stepData.findIndex((d) => pathname.includes(d.path)) ? (
                        <div className="min-w-[120px] max-w-[120px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
                      ) : (
                        <div className="min-w-[120px] max-w-[120px] h-1 bg-[#EAECF0] dark:bg-[#292929] rounded" />
                      )}
                    </div>
                  </div>
                  <div>
                    {index < stepData.length - 2 && (
                      <div className=" h-[88px] xs:h-[60px] sm:h-[70px] md:h-[70px] top-0 w-px bg-secondary-200 dark:bg-[#292929] -right-1 xs:hidden" />
                    )}
                  </div>
                </React.Fragment>
              );
            }
          } else {
            return (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: header?.visible == false ? "none" : "flex",
                  }}
                  className={` flex-col justify-center items-center gap-1`}
                >
                  <div
                    className={`flex flex-row items-center justify-center px-8 py-4`}
                  >
                    <NavLink to={header?.path} key={index}>
                      {({ isActive }) => {
                        return (
                          <div
                            className={`flex items-center justify-center w-full`}
                          >
                            <div className={`flex flex-col items-center gap-2`}>
                              <Button
                                size={"2xl"}
                                colorType={
                                  isActive
                                    ? "primary-machine"
                                    : "secondary-machine"
                                }
                                iconLeft={
                                  isActive ? (
                                    <header.activeIcon />
                                  ) : (
                                    <header.icon />
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      }}
                    </NavLink>
                    <div
                      style={
                        pathname.includes(header.path) ? { marginLeft: 16 } : {}
                      }
                      className={`flex flex-col items-start`}
                    >
                      {pathname.includes(header.path) && (
                        <>
                          <p
                            className={`text-[14px] font-normal leading-5 text-[#667085] dark:text-[#D6D6D6]`}
                          >
                            Step {index + 1}/{stepData.length - 1}
                          </p>

                          <div>
                            <p
                              className={`font-semibold text-center text-[#344054] text-[18px] leading-7 whitespace-nowrap dark:text-[#D6D6D6]`}
                            >
                              {t(`${header.name}`)}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={``}>
                    {pathname.includes(header.path) ? (
                      <div className="min-w-[236px] max-w-[236px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
                    ) : index <
                      stepData.findIndex((d) => pathname.includes(d.path)) ? (
                      <div className="min-w-[120px] max-w-[120px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
                    ) : (
                      <div className="min-w-[120px] max-w-[120px] h-1 bg-[#EAECF0] dark:bg-[#292929] rounded" />
                    )}
                  </div>
                </div>
                <div>
                  {index < stepData.length - 2 && (
                    <div className="h-[88px] xs:h-[60px] sm:h-[70px] md:h-[70px] top-0 w-px bg-secondary-200 dark:bg-[#292929] -right-1 xs:hidden" />
                  )}
                </div>
              </React.Fragment>
            );
          }
        })}
      </div>

      {/*for xs sized screen only*/}
      <div className="hidden xs:flex w-full items-center justify-center">
        <React.Fragment>
          <div className={`flex flex-col justify-center items-center gap-1`}>
            <div
              className={`flex flex-row items-center justify-center px-8 py-4  ${
                activeStepIndex ? "block" : ""
              }`}
            >
              <NavLink to={stepData[activeStepIndex]?.path}>
                {({ isActive }) => {
                  const ActiveIcon = stepData[activeStepIndex]?.activeIcon;
                  const Icon = stepData[activeStepIndex]?.icon;
                  return (
                    <div className={`flex items-center justify-center w-full`}>
                      <div className={`flex flex-col items-center gap-2`}>
                        <Button
                          size={"2xl"}
                          colorType={
                            isActive ? "primary-machine" : "secondary-machine"
                          }
                          iconLeft={isActive ? <ActiveIcon /> : <Icon />}
                        />
                      </div>
                    </div>
                  );
                }}
              </NavLink>
              <div
                style={
                  pathname?.includes(stepData[activeStepIndex]?.path)
                    ? { marginLeft: 16 }
                    : {}
                }
                className={`flex flex-col items-start`}
              >
                {pathname?.includes(stepData[activeStepIndex]?.path) && (
                  <>
                    <p
                      className={`text-[14px] font-normal leading-5 text-[#667085] dark:text-[#D6D6D6]`}
                    >
                      Step {activeStepIndex + 1}
                    </p>

                    <div>
                      <p
                        className={`font-semibold text-center text-[#344054] text-[18px] leading-7 whitespace-nowrap dark:text-[#D6D6D6]`}
                      >
                        {t(`${stepData[activeStepIndex].name}`)}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={``}>
              {pathname.includes(stepData[activeStepIndex]?.path) ? (
                <div className="min-w-[236px] max-w-[236px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
              ) : activeStepIndex <
                stepData?.findIndex((d) => pathname.includes(d?.path)) ? (
                <div className="min-w-[120px] max-w-[120px] h-1 bg-[#7F56D9] rounded dark:bg-[#B692F6]" />
              ) : (
                <div className="min-w-[120px] max-w-[120px] h-1 bg-[#EAECF0] dark:bg-[#292929] rounded" />
              )}
            </div>
          </div>
          <div></div>
        </React.Fragment>
      </div>
    </div>
  );
});

export default StepHeader;
