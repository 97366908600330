import React, { memo, useState } from "react";
import generateFileUrl from "../../../utils/generateFileUrl";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import {
  AcceptedIcon,
  PieceIcon,
  RejectedIcon,
} from "../../../assets/icons/productIcons";
import { MenuIcon } from "../../../assets/icons/PersonIcons";
import { CustomModal } from "../../../components";
import PurchaseRequestForm from "../modals/PurchaseRequestForm";
import NewPurchasedRequestForm from "../modals/NewPurchasedRequestForm";
import SupplyFormModal from "../modals/SupplyFormModal";

const NewStockCard = ({
  image,
  productName = "08 Driving Shaft",
  stockCode = "12452334",
  stokPiece = "50.000",
  deliveryDate = "92.999",
  scrapQuantity,
  qualityQuantity,
  product,
  onClick,
  allData,
  showMenuOptions = true,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [supplyForm, setSupplyForm] = useState(false);
  const [requestForm, setRequestForm] = useState(false);

  const menuOptions = [
    { label: t("product:details"), value: "details", icon: <DetailIcon /> },
    {
      label: t("product:purchaseRequestCreation"),
      value: "form",
      icon: <PurchaseIcon />,
    },
    // {
    //   label: t("product:purchaseRequestForm"),
    //   value: "purchase",
    //   icon: <PurchaseIcon />,
    // },
    // {
    //   label: t("product:supplyForm"),
    //   value: "supplyForm",
    //   icon: <PurchaseIcon />,
    // },
  ];

  return (
    <>
      <div
        data-tooltip-id={`${allData}`}
        data-tooltip-content={`
    ${t("product:productName")} : ${productName.slice(0,3)+"***"} 
     -
    ${t("product:stockId")} : ${stockCode.slice(0,3)+"***"}
    `}
        data-tooltip-place="right"
        // onClick={onClick}
        style={{ zIndex: 60 }}
        className="w-[324px] border bg-white border-secondary-300 px-3 py-2 rounded-lg shadow-sm flex flex-col group relative cursor-pointer"
      >
        <div onClick={onClick} className="flex items-center flex-row">
          <img
            className="w-16 h-12 border blur-sm border-secondary-300 rounded bg-white"
            src={generateFileUrl(image)}
          />
          <div className="flex flex-col ml-3 gap-y-1 w-[75%]">
            <p className="text-md font-semibold text-secondary-900 truncate">
              {productName?.slice(0, 3)+ " ***" || "---"}
            </p>
            <div className="flex flex-row items-center">
              <p className="text-sm font-normal text-secondary-600">
                {t("product:stockId")}:
              </p>
              <p className="text-sm font-normal text-secondary-600 truncate w-[150px]">
                {stockCode?.slice(0, 2)+ " ***" || ""}
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={onClick}
          className="flex flex-row items-center gap-x-4 mt-1"
        >
          <div className="flex flex-row items-center gap-x-1 mt-1 ">
            <PieceIcon />
            <p className="text-xs text-secondary-600 font-medium">
              {qualityQuantity === null ? 0 : qualityQuantity}
            </p>
            <p className="text-[10px] font-normal text-secondary-500">
              {t("product:totalStock")}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1 mt-1">
            <RejectedIcon />
            <p className="text-xs text-error-600 font-medium">
              {scrapQuantity === null ? 0 : scrapQuantity}
            </p>
            <p className="text-[10px] font-normal text-secondary-500">
              {t("product:totalRejectedPiece")}
            </p>
          </div>
        </div>

        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{
            zIndex: 50,
          }}
          className={`min-w-[20px] absolute bottom-1 right-3 h-[20px] cursor-pointer hidden  ${
            showMenuOptions ? "hidden" : "hidden"
          }`}
        >
          <MenuIcon />
        </div>
        {isOpen && (
          <div className="hidden flex-col absolute min-w-[225px] max-w-[225px] h-fit bg-white border top-[8px] right-[32px] z-[110] rounded-lg px-[6px] py-1">
            {menuOptions?.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    if (item.value === "details") {
                      onClick();
                    }
                    if (item.value === "form") {
                      setIsPurchased(true);
                    }

                    // if (item.value === "purchase") {
                    //   setRequestForm(true);
                    // }
                    // if (item.value === "supplyForm") {
                    //   setSupplyForm(true);
                    // }
                    setIsOpen(false);
                  }}
                  key={index}
                  type="button"
                  className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                    item?.value === "delete"
                      ? "hover:bg-[#FECDCA]"
                      : "hover:bg-secondary-100"
                  }`}
                >
                  {item?.icon}
                  <p
                    className={`font-medium text-sm  ${
                      item?.value === "delete"
                        ? "text-[#D92D20]"
                        : "text-secondary-700"
                    }`}
                  >
                    {item?.label}
                  </p>
                </button>
              );
            })}
          </div>
        )}

        {/* <div className="w-[84px] border border-secondary-200 px-[6px] pt-[2px] pb-1 flex flex-col rounded-md items-center gap-y-2 ml-auto">
          <div className="flex flex-row items-center gap-x-1">
            <span className="w-2 h-2 rounded-[2px] bg-[#17B26A]"></span>
            <p className="text-xs font-normal text-secondary-500">
              {t("reports:quality")}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <AcceptedIcon />
            <p className="text-xs font-normal text-success-600">
              {acceptedPiece || "---"}
            </p>
            <p className="text-[10px] font-normal text-secondary-500">ad.</p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <RejectedIcon />
            <p className="text-xs font-normal text-error-600">
              {rejectedPiece || "---"}
            </p>
            <p className="text-[10px] font-normal text-secondary-500">ad.</p>
          </div>
        </div> */}
        <Tooltip id={`${allData}`} />
      </div>

      <CustomModal
        isOpen={isPurchased}
        setIsOpen={setIsPurchased}
        // onClose={() => {
        //   setIsPurchased(false);
        // }}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <NewPurchasedRequestForm
            setIsOpen={isPurchased}
            closeModal={() => {
              setIsPurchased(false);
            }}
            purchaseData={product}
          />
        }
      />

      <CustomModal
        isOpen={supplyForm}
        setIsOpen={setSupplyForm}
        modalTitle={t("product:supplyForm")}
        children={
          <SupplyFormModal
            setIsOpen={setSupplyForm}
            closeModal={() => {
              setSupplyForm(false);
            }}
            supplierData={product}
          />
        }
      />
      <CustomModal
        isOpen={requestForm}
        setIsOpen={setRequestForm}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <PurchaseRequestForm
            setIsOpen={setRequestForm}
            closeModal={() => {
              setRequestForm(false);
            }}
            supplierData={product}
          />
        }
      />
    </>
  );
};

export default memo(NewStockCard);

export const PurchaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_12446_70067)">
        <path
          d="M1.3335 1.33325H2.20427C2.36828 1.33325 2.45029 1.33325 2.51628 1.36341C2.57444 1.38999 2.62373 1.43274 2.65826 1.48655C2.69745 1.54761 2.70905 1.6288 2.73225 1.79116L3.04778 3.99992M3.04778 3.99992L3.74904 9.15419C3.83803 9.80827 3.88253 10.1353 4.0389 10.3815C4.17668 10.5984 4.37422 10.7709 4.60773 10.8782C4.87274 10.9999 5.20279 10.9999 5.8629 10.9999H11.5682C12.1965 10.9999 12.5107 10.9999 12.7675 10.8869C12.9939 10.7872 13.1881 10.6265 13.3283 10.4227C13.4875 10.1917 13.5462 9.88303 13.6638 9.26576L14.5462 4.63305C14.5876 4.41579 14.6083 4.30716 14.5783 4.22225C14.552 4.14777 14.5001 4.08504 14.4319 4.04526C14.3541 3.99992 14.2435 3.99992 14.0223 3.99992H3.04778ZM6.66683 13.9999C6.66683 14.3681 6.36835 14.6666 6.00016 14.6666C5.63197 14.6666 5.3335 14.3681 5.3335 13.9999C5.3335 13.6317 5.63197 13.3333 6.00016 13.3333C6.36835 13.3333 6.66683 13.6317 6.66683 13.9999ZM12.0002 13.9999C12.0002 14.3681 11.7017 14.6666 11.3335 14.6666C10.9653 14.6666 10.6668 14.3681 10.6668 13.9999C10.6668 13.6317 10.9653 13.3333 11.3335 13.3333C11.7017 13.3333 12.0002 13.6317 12.0002 13.9999Z"
          stroke="#667085"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12446_70067">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 7.99992L6 7.99992M14 3.99992L6 3.99992M14 11.9999L6 11.9999M3.33333 7.99992C3.33333 8.36811 3.03486 8.66659 2.66667 8.66659C2.29848 8.66659 2 8.36811 2 7.99992C2 7.63173 2.29848 7.33325 2.66667 7.33325C3.03486 7.33325 3.33333 7.63173 3.33333 7.99992ZM3.33333 3.99992C3.33333 4.36811 3.03486 4.66659 2.66667 4.66659C2.29848 4.66659 2 4.36811 2 3.99992C2 3.63173 2.29848 3.33325 2.66667 3.33325C3.03486 3.33325 3.33333 3.63173 3.33333 3.99992ZM3.33333 11.9999C3.33333 12.3681 3.03486 12.6666 2.66667 12.6666C2.29848 12.6666 2 12.3681 2 11.9999C2 11.6317 2.29848 11.3333 2.66667 11.3333C3.03486 11.3333 3.33333 11.6317 3.33333 11.9999Z"
        stroke="#667085"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
