import React, { useState, useEffect } from "react";
import moment from "moment";
import EventRect from "./EventRect";
import StatusTooltip from "./StatusTooltip";
import MultipleEventRect from "./MultipleEventRect";
import SingleEventRect from "./SingleEventRect";

const UpuTimelineChart = ({
  data,
  totalWidth,
  selectedStart,
  selectedEnd,
  totalTime,
  setSelectedStart,
  setSelectedEnd,
  setTotalTime,

  //toltiop
  setShowTooltip,
  showTooltip,

  //multipes
  multipleEvent,
}) => {
  const handleMouseOver = (index, xPos) => {
    setShowTooltip({ index, xPos });
  };

  const handleMouseOut = () => {
    if (showTooltip) {
      setShowTooltip(null);
    }
  };

  console.log("totaltime", totalTime);

  const totals = totalWidth - 200;

  return (
    <div
      style={{
        width: totalWidth,
        height: 120,
        overflow: "hidden",
        position: "relative",
        marginTop: "12px",
      }}
    >
      <svg width={totalWidth} height="110">
        {data?.map((event, index) => {
          console.log("DATAAAA", data);

          const endTimes = moment(event.ends).unix();
          const startTime = moment(event.starts).unix();

          const finishAction = endTimes - selectedStart;
          const startAction = startTime - selectedStart;

          const widthS = ((event.timeDiff / totalTime) * totals).toFixed(2);

          const endPos = ((finishAction % totalTime) / totalTime) * totals;
          const startPos = ((startAction % totalTime) / totalTime) * totals;

          const widthh = endPos - startPos;

          if (multipleEvent == 2) {
            return (
              <MultipleEventRect
                key={index}
                index={index}
                event={event}
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
                //kontrols
                startPos={startPos}
                width={widthh}
                widthS={widthS}
              />
            );
          } else if (multipleEvent == 1) {
            return (
              <>
                <SingleEventRect
                  key={index}
                  index={index}
                  event={event}
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                  //kontrols
                  startPos={startPos}
                  width={widthh}
                  widthS={widthS}
                  totalWidth={totalWidth - 200}
                  selectedStart={selectedStart}
                  selectedEnd={selectedEnd}
                />
              </>
            );
          } else {
            return (
              <>
                <EventRect
                  key={index}
                  index={index}
                  event={event}
                  handleMouseOver={handleMouseOver}
                  handleMouseOut={handleMouseOut}
                  //kontrols
                  startPos={startPos}
                  width={widthh}
                  widthS={widthS}
                />
              </>
            );
          }
        })}
      </svg>
    </div>
  );
};

export default UpuTimelineChart;
