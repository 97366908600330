import { makeAutoObservable } from "mobx";
import { consoleStore } from "./console.store";

class ProductStore {
  constructor() {
    makeAutoObservable(this);
  }

  setLastIndexx(status) {
    this.lastIndex = status;
  }

  setId(status) {
    this.id = status;
  }

  setSelectedProduct(product) {
    this.selectedProduct = product;
  }
}

const productStore = new ProductStore();
export { productStore };
