import React, { useState } from "react";
import generateFileUrl from "../../utils/generateFileUrl";
import NoAvatar from "../avatar/NoAvatar";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import AlertModal from "../alert-Modal/Alert-Modal";
import { DeleteIcon, DotsVerticalIcon } from "./ActiveAndPlannedCard";
import { EditIcon } from "../../assets/icons/departmentIcon";
import JunctionUpdate from "../modal/JunctionUpdate";
import CustomModal from "../modal/CustomModal";
import { junctionService } from "../../services/junction.service";
import { Toast } from "../../utils/toastify/toast";
import QualityControlForm from "../modal/QualityControlForm";
import { useNavigate } from "react-router-dom";
import {
  EquipmentDetailIcon,
  EquipmentEditIcon,
  NoEquIcon,
  ProductDetailIcon,
} from "../../assets/icons/machineIcons";
import moment from "moment";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import Badgets from "../buttons/Badgets";

const NewActiveAndPlannedCard = ({
  id,
  image,
  name,
  estimatedTime,
  piece,
  user,
  rightButton,
  status,
  type,
  pages,
  openCard,
  startDate,
  targetMin,
  onClick,
  deletePlannedInternal,
  step,
  refetch,
  externalOperation,
  oneOrderTime,
}) => {
  const { t } = useTranslation();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isJunction, setIsJunction] = useState(false);
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState("");

  const menuOptions = [
    // { label: t("Move Junction"), value: "details", icon: <ArrowRight /> },
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const menuOptionsExternal = [
    {
      label: t("product:finishJuction"),
      value: "finish",
      icon: <ProductDetailIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const menuOptionsPlanned = [
    // { label: t("Move Junction"), value: "details", icon: <ArrowRight /> },
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const menuOptionsPersonnel = [
    {
      label: t("product:equipmentDetails"),
      value: "equipmentDetail",
      icon: <EquipmentDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "editJunction",
      icon: <EquipmentEditIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const menuOptionsEquipment = [
    {
      label: t("product:personnelDetail"),
      value: "personnel",
      icon: <PersonnelDetailIcon />,
    },
    {
      label: t("product:editJunction"),
      value: "edit",
      icon: <EquipmentEditIcon />,
    },
    { label: t("buttons:delete"), value: "delete", icon: <DeleteIcon /> },
  ];

  const onDeleted = async () => {
    if (deletePlannedInternal) {
      await junctionService.deletePlannedJunction(id).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    } else {
      await junctionService.deleteJunction(id, deleteId).then((res) => {
        if (res.data.code === 0) {
          Toast("success", res.data.message);
          refetch();
          setIsAlert(false);
          setDeleteId(id);
        } else {
          Toast("error", res.data.message);
          setIsAlert(false);
          setDeleteId("");
        }
      });
    }
  };

  return (
    <>
      <div
        onClick={onClick}
        className={`flex w-full h-[92px] cursor-pointer flex-col  gap-x-2 items-start rounded-t-[4px] rounded-b-lg border  border-[#D0D5DD]  shadow-xs max-h-[92px] min-h-[92px] bg-white group relative`}
      >
        <div
          className={`w-full border-t-4 rounded-t-[3px] ${
            type == "work"
              ? "border-t-success-500 "
              : type === "setup"
              ? "border-t-[#EAAA08]"
              : "border-t-[#98A2B3]"
          }`}
        ></div>
        <div className="flex flex-row items-start gap-x-[6px] pt-[6px] px-2 pb-[6px] w-full">
          <div className="w-[80%] flex flex-row items-center gap-x-[6px]">
            {image ? (
              <img
                data-tooltip-id={`${id}`}
                data-tooltip-place={"right"}
                data-tooltip-content={`${name?.slice(0,3)+"***"} `}
                onClick={openCard}
                src={generateFileUrl(image || "")}
                className="w-[64px] min-w-[64px] blur-sm h-[48px] cursor-pointer rounded-[2px] border border-secondary-300 "
              />
            ) : (
              <div
                onClick={openCard}
                className="w-[64px] blur-sm min-w-[64px] h-[48px] rounded border border-secondary-300 shadow-sm bg-secondar-50 flex items-center justify-center"
              >
                <NoEquIcon />
              </div>
            )}
            <div className="flex flex-col w-[90%] items-start gap-y-[6px]">
              <p className="truncate w-[80%] text-sm text-secondary-700 font-semibold">
                {name ? `${name.slice(0, 2)} ***` : "****"}
              </p>
              <div className="flex flex-row items-center gap-x-3 w-full">
                <div className="flex flex-row items-center">
                  <ClockIcon />
                  <p className="text-xs text-secondary-600 ml-1 font-normal">
                    {estimatedTime || "---"}
                  </p>
                  <p
                    className={`text-xxs ${
                      status == "active" ? "" : "hidden"
                    } text-secondary-600 ml-0.5 font-normal`}
                  >
                    {t("product:timeStarted")}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <PieceIcon />
                  <p className="text-xs text-secondary-600 ml-1 font-normal">
                    {piece ? `${piece.slice(0, 2)} **` : "****"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {user ? (
            user?.avatar ? (
              <div
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
                // onClick={rightButton}
                data-tooltip-id={`${id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`${user?.name + " " + user?.lastName} `}
                className="ml-auto relative"
              >
                <img
                  className="w-[48px] min-w-[48px] blur-sm cursor-pointer h-[48px] absolute right-[4px] rounded-full border border-gray-300"
                  src={generateFileUrl(user?.avatar)}
                />
              </div>
            ) : (
              <div
                className="cursor-pointer ml-auto relative"
                onClick={() => {
                  navigate(
                    `/app/machine/digital-twin/user-detail/${user?.id}/${moment(
                      startDate
                    ).format("YYYY-MM-DD")}`
                  );
                }}
                data-tooltip-id={`${id}`}
                data-tooltip-place={"left"}
                data-tooltip-content={`${user?.name + " " + user?.lastName} `}
              >
                <NoAvatar
                  size={48}
                  fontSize={22}
                  name={user?.name}
                  lastName={user?.lastName}
                  color={"#475467"}
                />
              </div>
            )
          ) : (
            <button
              onClick={rightButton}
              type="button"
              className="min-w-[48px] blur-sm h-[48px] rounded-full ml-auto border-dashed border border-gray-300 flex items-center justify-center"
            >
              <NewPlusIcon />
            </button>
          )}
        </div>
        <div className="flex flex-row items-center w-full h-[26px] bg-[#F9FAFB] gap-x-6 py-1 px-[6px] rounded-b-[8px]  border-t-[0.5px] border-t-[#D0D5DD]">
          <div className="flex flex-row gap-1 items-center ">
            <TargetIcon />
            <p className="text-xs font-medium text-secondary-600">
              {targetMin || "---"}
            </p>
            <p className="text-[10px] font-normal text-secondary-600">
              {t("product:targetMin")}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-1">
            <SinglePiece />
            <div className="flex flex-row items-center">
              <p className={`text-xs font-medium text-secondary-600 `}>
                {oneOrderTime || "---"}
              </p>
              <p className="text-[10px] ml-0.5 font-normal text-secondary-600">
                {t("product:pcs")}
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            // e.stopPropagation();
            // setShowDropDown(!setShowDropDown);
            setShowDropDown(false);
            Toast(
              "warning",
              "Demo users are not authorized to access contact administration."
            );
          }}
          className="hidden absolute min-h-[24px] max-h-[24px] min-w-[24px] max-w-[24px] p-[2px] border rounded  bg-white group-hover:flex right-[3px] top-[8px] z-[50] items-center justify-center cursor-pointer"
        >
          <DotsVerticalIcon />
        </div>
        {showDropDown && (
          <div
            className={`hidden flex-col absolute ${
              pages === "equipmentDetail"
                ? "top-[2px] right-[32px]"
                : "top-[36px] right-[3px]"
            } min-w-[200px] max-w-[200px] h-fit bg-white border   z-[99] rounded-lg px-[6px] py-1 group-hover:flex`}
          >
            {pages === "persennelConsole" ? (
              <>
                {menuOptionsPersonnel?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "editJunction") {
                          setIsJunction(true);
                        }
                        if (item.value === "equipmentDetail") {
                          openCard();
                        }

                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : pages === "equipmentDetail" ? (
              <>
                {menuOptionsEquipment?.map((item, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.value === "delete") {
                          setIsAlert(true);
                          setDeleteId(id);
                        }
                        if (item.value === "personnel") {
                          navigate(
                            `/app/machine/digital-twin/user-detail/${
                              user?.id
                            }/${moment(startDate).format("YYYY-MM-DD")}`
                          );
                        }
                        if (item.value === "edit") {
                          setIsJunction(true);
                        }
                        setShowDropDown(false);
                      }}
                      key={index}
                      type="button"
                      className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                        item?.value === "delete"
                          ? "hover:bg-error-50"
                          : "hover:bg-secondary-100"
                      }`}
                    >
                      {item?.icon}
                      <p
                        className={`font-medium text-sm  ${
                          item?.value === "delete"
                            ? "text-[#D92D20]"
                            : "text-secondary-700"
                        }`}
                      >
                        {item.label}
                      </p>
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {status === "active" ? (
                  <>
                    {externalOperation ? (
                      <>
                        {menuOptionsExternal?.map((item, index) => {
                          return (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                if (item.value === "delete") {
                                  setIsAlert(true);
                                  setDeleteId(id);
                                }

                                if (item.value === "finish") {
                                  onClick();
                                }

                                setShowDropDown(false);
                              }}
                              key={index}
                              type="button"
                              className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                                item?.value === "delete"
                                  ? "hover:bg-error-50"
                                  : "hover:bg-secondary-100"
                              }`}
                            >
                              {item?.icon}
                              <p
                                className={`font-medium text-sm  ${
                                  item?.value === "delete"
                                    ? "text-[#D92D20]"
                                    : "text-secondary-700"
                                }`}
                              >
                                {item.label}
                              </p>
                            </button>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {menuOptions?.map((item, index) => {
                          return (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                if (item.value === "delete") {
                                  setIsAlert(true);
                                  setDeleteId(id);
                                }
                                if (item.value === "personnel") {
                                  navigate(
                                    `/app/machine/digital-twin/user-detail/${
                                      user?.id
                                    }/${moment(startDate).format("YYYY-MM-DD")}`
                                  );
                                }
                                if (item.value === "equipmentDetail") {
                                  openCard();
                                }
                                if (item.value === "edit") {
                                  setIsJunction(true);
                                }
                                setShowDropDown(false);
                              }}
                              key={index}
                              type="button"
                              className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                                item?.value === "delete"
                                  ? "hover:bg-error-50"
                                  : "hover:bg-secondary-100"
                              }`}
                            >
                              {item?.icon}
                              <p
                                className={`font-medium text-sm  ${
                                  item?.value === "delete"
                                    ? "text-[#D92D20]"
                                    : "text-secondary-700"
                                }`}
                              >
                                {item.label}
                              </p>
                            </button>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {menuOptionsPlanned?.map((item, index) => {
                      return (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.value === "delete") {
                              setIsAlert(true);
                              setDeleteId(id);
                            }
                            if (item.value === "editJunction") {
                              setIsJunction(true);
                            }
                            if (item.value === "equipmentDetail") {
                              openCard();
                            }

                            setShowDropDown(false);
                          }}
                          key={index}
                          type="button"
                          className={`w-full h-[38px] flex items-center px-2.5  rounded-md gap-x-2 ${
                            item?.value === "delete"
                              ? "hover:bg-error-50"
                              : "hover:bg-secondary-100"
                          }`}
                        >
                          {item?.icon}
                          <p
                            className={`font-medium text-sm  ${
                              item?.value === "delete"
                                ? "text-[#D92D20]"
                                : "text-secondary-700"
                            }`}
                          >
                            {item.label}
                          </p>
                        </button>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        )}
        {step == undefined ? null : (
          <span className="absolute left-1 top-1">
            <Badgets colorType={"fill-gray"} label={step + ".op"} size={"sm"} />
          </span>
        )}
      </div>
      <Tooltip id={`${id}`} />
      <AlertModal
        title={t("product:deleteOrder")}
        subTitle={t("equipments:deleteAlert")}
        isOpen={isAlert}
        setIsOpen={setIsAlert}
        applyButton={() => {
          // onDeleted(deleteId);
        }}
      />
      <CustomModal
        isOpen={isJunction}
        setIsOpen={setIsJunction}
        onClose={() => {
          setIsJunction(false);
        }}
        modalTitle={t("product:editJunction")}
        children={
          <JunctionUpdate
            junctionId={id}
            pages={"activeAndPlanned"}
            onClose={() => {
              setIsJunction(false);
            }}
            refetch={refetch}
          />
        }
      />
    </>
  );
};

export default NewActiveAndPlannedCard;

export const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00008 3.49996V6.99996L9.33342 8.16663M12.8334 6.99996C12.8334 10.2216 10.2217 12.8333 7.00008 12.8333C3.77842 12.8333 1.16675 10.2216 1.16675 6.99996C1.16675 3.7783 3.77842 1.16663 7.00008 1.16663C10.2217 1.16663 12.8334 3.7783 12.8334 6.99996Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PersonnelDetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M8.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5Z"
        stroke="#475467"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PieceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.07812H3.25C2.42157 2.07812 1.75 2.7497 1.75 3.57813V10.4219C1.75 11.2503 2.42157 11.9219 3.25 11.9219H10.75C11.5784 11.9219 12.25 11.2503 12.25 10.4219V3.57813C12.25 2.7497 11.5784 2.07812 10.75 2.07812H8.96875M5.03125 2.07812V5.89427C5.03125 5.95003 5.08992 5.98629 5.13979 5.96135L7 5.03125L8.86021 5.96135C8.91008 5.98629 8.96875 5.95003 8.96875 5.89427V2.07812M5.03125 2.07812H8.96875"
        stroke="#475467"
      />
    </svg>
  );
};

export const AcceptedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04163H3.25C2.42157 2.04163 1.75 2.7132 1.75 3.54163V10.3854C1.75 11.2138 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2138 12.25 10.3854V3.54163C12.25 2.7132 11.5784 2.04163 10.75 2.04163H8.96875M5.03125 2.04163V4.69111C5.03125 4.74686 5.08992 4.78312 5.13979 4.75819L7 3.82808L8.86021 4.75819C8.91008 4.78312 8.96875 4.74686 8.96875 4.69111V2.04163M5.03125 2.04163H8.96875"
        stroke="#079455"
      />
      <path
        d="M5.25 8.27889L6.50641 9.5353L9.33333 6.70837"
        stroke="#079455"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const NewPlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 1V15M1 8H15"
        stroke="#98A2B3"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const RejectedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.03125 2.04163H3.25C2.42157 2.04163 1.75 2.7132 1.75 3.54163V10.3854C1.75 11.2138 2.42157 11.8854 3.25 11.8854H10.75C11.5784 11.8854 12.25 11.2138 12.25 10.3854V3.54163C12.25 2.7132 11.5784 2.04163 10.75 2.04163H8.96875M5.03125 2.04163V4.69111C5.03125 4.74686 5.08992 4.78312 5.13979 4.75819L7 3.82808L8.86021 4.75819C8.91008 4.78312 8.96875 4.74686 8.96875 4.69111V2.04163M5.03125 2.04163H8.96875"
        stroke="#D92D20"
      />
      <path
        d="M8.45841 6.70837L5.54175 9.62504M5.54175 6.70837L8.45841 9.62504"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const TargetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8334 7.00002H10.5001M3.50008 7.00002H1.16675M7.00008 3.50002V1.16669M7.00008 12.8334V10.5M11.6667 7.00002C11.6667 9.57735 9.57741 11.6667 7.00008 11.6667C4.42275 11.6667 2.33341 9.57735 2.33341 7.00002C2.33341 4.42269 4.42275 2.33335 7.00008 2.33335C9.57741 2.33335 11.6667 4.42269 11.6667 7.00002Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SinglePiece = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M3.66667 6.58333V8.91667M8.33333 5.41667V8.91667M6 3.08333V8.91667M3.55 11.25H8.45C9.43009 11.25 9.92014 11.25 10.2945 11.0593C10.6238 10.8915 10.8915 10.6238 11.0593 10.2945C11.25 9.92014 11.25 9.43009 11.25 8.45V3.55C11.25 2.56991 11.25 2.07986 11.0593 1.70552C10.8915 1.37623 10.6238 1.10852 10.2945 0.940739C9.92014 0.75 9.43009 0.75 8.45 0.75H3.55C2.56991 0.75 2.07986 0.75 1.70552 0.940739C1.37623 1.10852 1.10852 1.37623 0.940739 1.70552C0.75 2.07986 0.75 2.56991 0.75 3.55V8.45C0.75 9.43009 0.75 9.92014 0.940739 10.2945C1.10852 10.6238 1.37623 10.8915 1.70552 11.0593C2.07986 11.25 2.56991 11.25 3.55 11.25Z"
        stroke="#475467"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SinglePiece2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.66667 7.58333V9.91667M9.33333 6.41667V9.91667M7 4.08333V9.91667M4.55 12.25H9.45C10.4301 12.25 10.9201 12.25 11.2945 12.0593C11.6238 11.8915 11.8915 11.6238 12.0593 11.2945C12.25 10.9201 12.25 10.4301 12.25 9.45V4.55C12.25 3.56991 12.25 3.07986 12.0593 2.70552C11.8915 2.37623 11.6238 2.10852 11.2945 1.94074C10.9201 1.75 10.4301 1.75 9.45 1.75H4.55C3.56991 1.75 3.07986 1.75 2.70552 1.94074C2.37623 2.10852 2.10852 2.37623 1.94074 2.70552C1.75 3.07986 1.75 3.56991 1.75 4.55V9.45C1.75 10.4301 1.75 10.9201 1.94074 11.2945C2.10852 11.6238 2.37623 11.8915 2.70552 12.0593C3.07986 12.25 3.56991 12.25 4.55 12.25Z"
        stroke="#D92D20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
