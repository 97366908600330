import React, { memo, useEffect, useState } from "react";
import TaskDropDownButton from "../components/TaskDropDownButton";
import GroupList from "../components/GroupList";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import GroupChatDetails from "./GroupChatDetails";
import { useStore } from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";
import TaskList from "../components/TaskList";
import MyTaskList from "../components/MyTaskList";
import { chatService } from "../../../services/chat.service";
import { usePubNub } from "pubnub-react";
import { useQuery } from "@tanstack/react-query";
import NoData from "../../../steps/step-navigation/components/NoData";
import { useTranslation } from "react-i18next";
import OtherList from "../components/OtherList";
import { Button } from "../../../components";
import { SearchIcon } from "../../../assets/icons/stepsIcons";
import useWindowDimensions from "../../../hooks/useWindowDimension";

const Task = observer(() => {
  const { t } = useTranslation();
  const { chat, auth } = useStore();
  const pubnub = usePubNub();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { conversationId } = useParams();
  const [value, setValue] = useState("all");
  const [selectedUser, setSelectedUser] = useState(auth.user);
  const [searchView, setSearchView] = useState(false);
  const { data, isLoading, refetch, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getConversationChannelsByTask"],
    queryFn: async () => await chatService.getConversationChannels(),
  });
  const handleMessageList = () => {
    const channels = data?.map((d) => d?.conversationId);
    pubnub.fetchMessages(
      {
        channels: channels,
        count: 1,
      },
      function (status, response) {
        if (!status.error) {
          chat.setMessageList(filteredConversations(response.channels));
        } else {
          chat.setMessageList(
            data?.map((d) => {
              return {
                ...d,
                lastMessage: "",
                unreadCount: d.unreadCount || 0,
              };
            })
          );
        }
      }
    );

    const filteredConversations = (channels) => {
      const mapper = data?.map((item) => {
        if (Object.keys(channels).includes(item.conversationId)) {
          return {
            ...item,
            lastMessage: channels[item.conversationId][0],
          };
        } else {
          return {
            ...item,
            lastMessage: {
              message: "Click to send message.",
            },
          };
        }
      });
      return mapper;
    };
  };

  useEffect(() => {
    if (data) {
      handleMessageList();
    }
  }, [data]);

  // useEffect(() => {
  //   if (chat.messageList?.length > 0) {
  //     const newList = chat.messageList?.filter((a) => a.type === "group-task");
  //     navigate(newList?.[0]?.conversationId);
  //   }
  // }, []);

  useEffect(() => {
    refetch();
  }, [conversationId]);

  return (
    <div className="w-full h-full rounded-xl border border-secondary-200 shadow-md flex">
      {/* {value === "all" ? ( */}
      <>
        {conversationId ? (
          <div className="flex w-[75%] min-w-[75%] max-w-[75%]">
            {chat.currentGroupChatInfoId ? (
              <GroupChatDetails
                groupId={chat.currentGroupChatInfoId}
                onClose={() => chat.setCurrentGroupChatId(null)}
              />
            ) : (
              <Outlet />
            )}
            <TaskList
              groupId={conversationId}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </div>
        ) : (
          <div className="flex-1 h-full flex items-center justify-center">
            <NoData
              button={false}
              theme={"person"}
              header={t("chat:task")}
              text={t("person:selectItem")}
            />
          </div>
        )}
      </>
      <div
        className={`w-[25%] min-w-[25%] max-w-[25%] ${
          width >= 1360 && width < 1550 ? "px-3" : "px-5"
        } h-full border-l border-secondary-200 py-4    flex flex-col`}
      >
        <div className="w-full h-9 flex justify-between">
          <p className="text-lg font-semibold text-secondary-700">
            {value === "all"
              ? t("person:groups")
              : value === "other"
              ? t("person:groups")
              : t("tasks:myTasks")}
          </p>
          <div
            onClick={() => {
              setSearchView(!searchView);
            }}
            className={`max-w-[36px] ml-auto mr-3 cursor-pointer min-w-[36px] max-h-[36px] min-h-[36px] justify-center items-center flex p-2 rounded-lg border-[#D0D5DD] hover:bg-[#F9FAFB] active:shadow-ring-gray ${
              value === "all" ? "flex" : "hidden"
            } ${searchView ? "bg-[#F9FAFB]" : ""}`}
          >
            <SearchIcon />
          </div>
          <TaskDropDownButton
            value={value}
            setValue={setValue}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
          />
        </div>

        <div className="flex-1 w-full flex mt-5 overflow-hidden">
          {value === "all" ? (
            <GroupList
              conversationId={conversationId}
              searchView={searchView}
            />
          ) : value === "other" ? (
            <OtherList
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          ) : (
            <MyTaskList />
          )}
        </div>
      </div>
    </div>
  );
});

export default memo(Task);
