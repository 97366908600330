import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "../assets/icons/PersonIcons";
const PaginationComponent = ({ pageNo = 9, setPageNo, pageCount = 12 }) => {
  const { t } = useTranslation();
  return (
    <nav className="flex items-center justify-between border-gray-200 px-4 my-2.5 sm:px-0 z-50 bg-white">
      <div className="-mt-px flex w-0 flex-1 cursor-pointer">
        <a
          onClick={() => {
            if (pageNo != 1) {
              setPageNo(pageNo - 1);
            }
          }}
          className="border rounded-lg border-gray-300 inline-flex items-center p-2 text-sm font-medium text-gray-500  hover:text-gray-700"
        >
          <span className="mr-1.5">
            <ArrowLeftIcon />
          </span>

          {t("buttons:prev")}
        </a>
      </div>
      {pageCount <= 11 ? (
        <div className="md:-mt-px flex max-w-[800px]">
          <div className="flex gap-x-2">
            {[...Array(pageCount)].map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    setPageNo(i + 1);
                  }}
                >
                  <a
                    className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg cursor-pointer ${
                      pageNo == i + 1
                        ? "border-transparent bg-secondary-100"
                        : "border-transparent text-gray-500 hover:text-gray-700 "
                    }`}
                  >
                    {i + 1}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="md:-mt-px flex max-w-[800px]">
          {pageNo - 3 > 1 && pageNo + 3 < pageCount && (
            <div className="flex gap-x-2">
              <div
                onClick={() => {
                  setPageNo(1);
                }}
              >
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg border-transparent text-gray-500 hover:text-gray-700 cursor-pointer 
                `}
                >
                  1
                </a>
              </div>
              <div>
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700
                `}
                >
                  ...
                </a>
              </div>
              {[...Array(pageCount)].map((x, i) => {
                if (
                  i + 1 < pageNo
                    ? Math.abs(pageNo - i) <= 3
                    : Math.abs(pageNo - i) <= 1
                ) {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setPageNo(i + 1);
                      }}
                    >
                      <a
                        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg cursor-pointer ${
                          pageNo == i + 1
                            ? "border-transparent bg-secondary-100"
                            : "border-transparent text-gray-500 hover:text-gray-700 "
                        }`}
                      >
                        {i + 1}
                      </a>
                    </div>
                  );
                }
              })}
              <div>
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 
                `}
                >
                  ...
                </a>
              </div>
              <div
                key={pageCount}
                onClick={() => {
                  setPageNo(pageCount);
                }}
              >
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg border-transparent text-gray-500 hover:text-gray-700 cursor-pointer
                `}
                >
                  {pageCount}
                </a>
              </div>
            </div>
          )}
          {pageNo - 3 <= 1 && (
            <div className="flex gap-x-2">
              {[...Array(pageCount)].map((x, i) => {
                if (i - 1 <= pageNo) {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setPageNo(i + 1);
                      }}
                    >
                      <a
                        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg cursor-pointer ${
                          pageNo == i + 1
                            ? "border-transparent bg-secondary-100"
                            : "border-transparent text-gray-500 hover:text-gray-700 "
                        }`}
                      >
                        {i + 1}
                      </a>
                    </div>
                  );
                }
              })}
              <div>
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 
                `}
                >
                  ...
                </a>
              </div>
              <div
                key={pageCount}
                onClick={() => {
                  setPageNo(pageCount);
                }}
              >
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg border-transparent text-gray-500 hover:text-gray-700 cursor-pointer
                `}
                >
                  {pageCount}
                </a>
              </div>
            </div>
          )}
          {pageNo + 3 >= pageCount && (
            <div className="flex gap-x-2">
              <div
                key={pageCount}
                onClick={() => {
                  setPageNo(1);
                }}
              >
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg border-transparent text-gray-500 hover:text-gray-700 cursor-pointer
                `}
                >
                  1
                </a>
              </div>
              <div>
                <a
                  className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 
                `}
                >
                  ...
                </a>
              </div>
              {[...Array(pageCount)].map((x, i) => {
                if (i + 7 > pageCount) {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setPageNo(i + 1);
                      }}
                    >
                      <a
                        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium border rounded-lg cursor-pointer ${
                          pageNo == i + 1
                            ? "border-transparent bg-secondary-100"
                            : "border-transparent text-gray-500 hover:text-gray-700 "
                        }`}
                      >
                        {i + 1}
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      )}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          onClick={() => {
            if (pageNo != pageCount) {
              setPageNo(pageNo + 1);
            }
          }}
          className="border rounded-lg border-gray-300 inline-flex items-center p-2 text-sm font-medium text-gray-500  hover:text-gray-700 cursor-pointer"
        >
          {t("buttons:next")}
          <span className="rotate-180 ml-1.5">
            <ArrowLeftIcon />
          </span>
        </a>
      </div>
    </nav>
  );
};
export default memo(PaginationComponent);
