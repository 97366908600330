import React, { useEffect, useState } from "react";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { useQuery } from "@tanstack/react-query";
import Selector from "../../../components/inputs/Selector";
import CustomToggle from "./CustomToggle";
import CustomSwich from "./CustomSwich";
import { TickIcon } from "./svg";
import Button from "../../../components/buttons/Button";
import { Loading, RefreshIcon, SaveIcon } from "./svg/EmbeddedServerIcons";
import { Toast } from "../../../utils/toastify/toast";
import AlertModal from "../../../components/alert-Modal/Alert-Modal";
import { useTranslation } from "react-i18next";
import { Input } from "../../../components";

export const digital_sensor_statuses = [
  { label: "Empty", value: "empty" },
  { label: "Fault", value: "fault" },
  { label: "Close", value: "close" },
  { label: "Working", value: "running" },
  { label: "Standby", value: "standBy" },
  { label: "Predictive Maintinance Alert", value: "predictive_maintenance_alert" },
  { label: "Maintinance", value: "maintenance" },
];

const DigitalSensorSettings = ({ onClose, deviceId, metadataId, whichSensor, registeredSensor, getRegisteredSensors }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const [timeDelay, setTimeDelay] = useState(0.25);
  const [valueType, setValueType] = useState(null);
  const [sensorType, setSensorType] = useState(null);
  const [resetAlert, setResetAlert] = useState(false);
  const [activeOutput, setActiveOutput] = useState(false);
  const [activeTimeDelay, setActiveTimeDelay] = useState(false);
  const [functionLoading, setFunctionLoading] = useState(false);
  const [isTotalTimeValue, setIsTotalTimeValue] = useState(false);
  const [integralFrequence, setIntegralFrequence] = useState(false);
  const [isTotalCountValue, setIsTotalCountValue] = useState(false);
  const { data, isLoading } = useQuery({
    retry: 0,
    queryKey: "sensor-types-digital",
    queryFn: async () => await embeddedServerService.getSensorTypes(),
  });

  const sensorTypes = data?.digital?.map((item) => {
    return { label: item?.name, value: item?.id };
  });

  const resetSensor = async () => {
    const values = {
      deviceId,
      whichSensor,
      status: "reset",
      metadata: metadataId,
    };
    await embeddedServerService.resetDigital(values).then((res) => {
      Toast("success", t("embedded:registerDeleted"));
      setResetAlert(false);
      getRegisteredSensors();
    });
  };

  const saveSensorData = async () => {
    setFunctionLoading(true);
    const data = [
      {
        status,
        deviceId,
        closeBy: 0,
        standBy: 0,
        topCrop: 0,
        whichSensor,
        activeOutput,
        filter: null,
        bottomCrop: 0,
        type: sensorType,
        isTotalTimeValue,
        isTotalCountValue,
        samplingNumber: 0,
        sensorStatus: true,
        metadata: metadataId,
        timeDelay: Number(timeDelay),
        valueOrFreq: !valueType ? "value" : "frequency",
        isIntegralFreq: integralFrequence,
      },
    ];
    await embeddedServerService.saveDigital(data).then((res) => {
      getRegisteredSensors();
      setFunctionLoading(false);
      onClose();
    });
  };

  useEffect(() => {
    if (registeredSensor) {
      setStatus(registeredSensor?.status);
      setTimeDelay(registeredSensor?.timeDelay);
      setSensorType(registeredSensor?.type?.id);
      setActiveOutput(registeredSensor?.activeOutput);
      setIntegralFrequence(registeredSensor?.isIntegralFreq);
      setIsTotalTimeValue(registeredSensor?.isTotalTimeValue);
      setIsTotalCountValue(registeredSensor?.isTotalCountValue);
      setValueType(registeredSensor?.valueOrFreq === "value" ? false : true);
    }
  }, [registeredSensor]);

  return (
    <>
      <div className="w-[639px] flex flex-col mt-5">
        <div className="w-full flex items-center justify-between gap-x-4">
          <Selector isLoading={isLoading} label={t("embedded:sensorType")} items={sensorTypes} value={sensorType} onChange={setSensorType} />
          <Selector label={t("embedded:status")} items={digital_sensor_statuses} value={status} onChange={setStatus} />
        </div>
        <div className="w-full flex items-center justify-between gap-x-4 mt-6">
          <div className="w-1/2">
            <CustomToggle
              label={t("embedded:valueType")}
              firstText={"Value"} // value
              secondText={"Frequence"} // frequency
              enable={valueType}
              setEnable={setValueType}
            />
          </div>

          <div className="w-1/2 flex flex-col pl-3 pb-2.5">
            <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 dark:text-darkTrue-200 font-medium">{t("embedded:activeOutput")}</span>
            <div className="flex items-center gap-x-3">
              <p className="font-medium text-secondary-700">0</p>
              <CustomSwich isActive={activeOutput} setIsActive={setActiveOutput} />
              <p className="font-medium text-secondary-700">1</p>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-x-4">
          <div className="w-1/2">
            <div
              onClick={() => {
                setIntegralFrequence(!integralFrequence);
              }}
              className="items-center flex select-none cursor-pointer group mt-6"
            >
              <div className={`${integralFrequence ? "border-[#3E4784]" : "border-gray-300"} flex items-center justify-center w-5 h-5 rounded border`}>
                {integralFrequence && <TickIcon width={12} height={12} className="text-[#3E4784]" />}
              </div>
              <p className="text-sm font-medium text-gray-700 ml-2 select-none group-hover:underline">{t("embedded:digitalIntegral")}</p>
            </div>
            <div
              onClick={() => {
                setIsTotalTimeValue(!isTotalTimeValue);
              }}
              className="items-center flex select-none cursor-pointer group mt-3"
            >
              <div className={`${isTotalTimeValue ? "border-[#3E4784]" : "border-gray-300"} flex items-center justify-center w-5 h-5 rounded border`}>
                {isTotalTimeValue && <TickIcon width={12} height={12} className="text-[#3E4784]" />}
              </div>
              <p className="text-sm font-medium text-gray-700 ml-2 select-none group-hover:underline">{t("embedded:digitalTotalTime")}</p>
            </div>
            <div
              onClick={() => {
                setIsTotalCountValue(!isTotalCountValue);
              }}
              className="items-center flex select-none cursor-pointer group mt-3"
            >
              <div className={`${isTotalCountValue ? "border-[#3E4784]" : "border-gray-300"} flex items-center justify-center w-5 h-5 rounded border`}>
                {isTotalCountValue && <TickIcon width={12} height={12} className="text-[#3E4784]" />}
              </div>
              <p className="text-sm font-medium text-gray-700 ml-2 select-none group-hover:underline">{t("embedded:digitalTotalCount")}</p>
            </div>
          </div>
          <div className="flex flex-col w-1/2 pl-2 gap-3">
            <div
              onClick={() => {
                setActiveTimeDelay(!activeTimeDelay);
              }}
              className="items-center flex select-none cursor-pointer group mt-6"
            >
              <div className={`${activeTimeDelay ? "border-[#3E4784]" : "border-gray-300"} flex items-center justify-center w-5 h-5 rounded border`}>
                {activeTimeDelay && <TickIcon width={12} height={12} className="text-[#3E4784]" />}
              </div>
              <p className="text-sm font-medium text-gray-700 ml-2 select-none group-hover:underline">Activate Time Delay</p>
            </div>

            <Input
              disabled={!activeTimeDelay}
              label={"Time Delay (sn)"}
              value={timeDelay}
              onChange={({ target }) => {
                setTimeDelay(target?.value);
              }}
            />
          </div>
        </div>
        <div className="flex mt-6 items-center w-full">
          <Button
            width={205}
            size={"md"}
            iconLeft={<RefreshIcon />}
            label={t("buttons:resetSensorSettings")}
            colorType={"secondary-error"}
            onClick={() => setResetAlert(true)}
          />
          <div className="flex ml-auto gap-x-4">
            <Button
              size={"md"}
              width={177}
              label={t("buttons:stop")}
              colorType={"secondary-gray"}
              onClick={() => {
                onClose();
              }}
            />
            <Button
              size={"md"}
              width={177}
              label={functionLoading ? <Loading color={"#fff"} size={22} secondary={"#3E4784"} /> : <p>{t("buttons:save")}</p>}
              iconLeft={!functionLoading && <SaveIcon />}
              colorType={"primary-embedded"}
              onClick={() => {
                saveSensorData();
              }}
            />
          </div>
        </div>
      </div>
      <AlertModal
        isOpen={resetAlert}
        setIsOpen={setResetAlert}
        title={t("embedded:resetSensor")}
        subTitle={t("embedded:willBeReset")}
        label={t("buttons:reset")}
        applyButton={resetSensor}
      />
    </>
  );
};

export default DigitalSensorSettings;
