import React, { memo, useEffect, useState } from "react";
import { chatService } from "../../../services/chat.service";
import { useQuery } from "@tanstack/react-query";
import generateFileUrl from "../../../utils/generateFileUrl";
import NoAvatar from "../../../components/avatar/NoAvatar";
import CloseButton from "../../../components/buttons/CloseButton";
import Button from "../../../components/buttons/Button";
import {
  CalenderCheck,
  ChevronDown,
  DeadlineIcon,
  EditIcon,
  UsersIcon,
} from "../../../assets/icons/PersonIcons";
import moment from "moment";
import Badgets from "../../../components/buttons/Badgets";
import { CustomModal, Loading } from "../../../components";
import NewGroup from "../modals/NewGroup";
import { useTranslation } from "react-i18next";

const GroupChatDetails = ({ groupId, onClose, newRefetch }) => {
  const { t } = useTranslation();
  const [firstExpand, setFirstExpand] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [secondtExpand, setSecondtExpand] = useState(true);
  const { data, refetch, isLoading, isFetching } = useQuery({
    retry: 0,
    queryKey: ["getGropChatDetails"],
    queryFn: async () => await chatService.getTaskGroupDetails(groupId),
  });
  useEffect(() => {
    refetch();
  }, [groupId]);

  return (
    <>
      {/* normal sayfa */}
      <div className="w-[375px] min-w-[375px] px-5 pt-5 border-r border-secondary-200 flex flex-col md:hidden sm:hidden xs:hidden">
        {isLoading || isFetching ? (
          <div className="w-full h-full items-center justify-center flex">
            <Loading size={32} secondary={"#fff"} primary={"#0E9384"} />
          </div>
        ) : (
          <>
            <div className="w-full flex items-start">
              <div className="flex w-full items-center">
                {data?.image ? (
                  <img
                    src={generateFileUrl(data?.image)}
                    className="w-16 h-16 rounded-full border border-secondary-200"
                  />
                ) : (
                  <NoAvatar size={64} name={data?.title} fontSize={32} />
                )}
              </div>

              <div className="h-[40px] ml-auto">
                <CloseButton onClick={onClose} size={"md"} />
              </div>
            </div>
            <div className="flex flex-col mt-3">
              <div className="flex items-center justify-between">
                <p className="text-lg font-semibold text-secondary-700">
                  {data?.title}
                </p>

                <Button
                  size={"sm"}
                  iconLeft={<EditIcon />}
                  colorType={"tertiary-gray"}
                  onClick={() => {
                    setSelectedGroup(data);
                    setIsOpen(true);
                  }}
                />
              </div>

              <div className="flex  mt-6">
                <CalenderCheck />
                <div className="flex flex-col ml-2">
                  <p className="text-sm text-secondary-500">
                    {t("report:creationDate")}
                  </p>
                  <p className="font-medium text-secondary-700">
                    {moment(data?.createdAt).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
              <div className="border-b border-secondary-200 my-2.5" />

              <div className="flex">
                <DeadlineIcon />
                <div className="flex flex-col ml-2">
                  <p className="text-sm text-secondary-500">
                    {t("chat:deadLine")}
                  </p>
                  <p className="font-medium text-secondary-700">
                    {moment(data?.endDate).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
              <div className="border-b border-secondary-200 my-2.5" />
              <div className="flex">
                <UsersIcon />
                <div className="flex flex-col ml-2">
                  <p className="text-sm text-secondary-500">
                    {t("settingsKpi:personels")}
                  </p>
                  <p className="font-medium text-secondary-700">
                    {data?.participants?.length}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-6 flex-1 overflow-y-scroll scrollbar-hide">
              <div
                onClick={() => setFirstExpand(!firstExpand)}
                className="flex items-center w-full justify-between border-b border-secondary-200 pb-1"
              >
                <p className="text-base font-semibold text-secondary-700 flex gap-2">
                  {t("person:admins")}
                  <Badgets
                    label={data?.authorizedParticipants?.length}
                    size={"sm"}
                    colorType={"fill-gray"}
                  />
                </p>
                <div className={`w-9 ${firstExpand && "rotate-180"}`}>
                  <Button
                    colorType={"tertiary-gray"}
                    size={"sm"}
                    iconLeft={<ChevronDown />}
                  />
                </div>
              </div>
              {firstExpand && (
                <>
                  {data?.authorizedParticipants?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full  h-14 min-h-[56px] flex items-center"
                      >
                        {item?.avatar ? (
                          <img
                            src={generateFileUrl(item?.avatar)}
                            className="w-10 h-10 rounded-full"
                          />
                        ) : (
                          <NoAvatar
                            size={40}
                            name={item?.name}
                            lastName={item?.lastName}
                            fontSize={20}
                          />
                        )}
                        <p className="text-sm font-semibold text-secondary-700 ml-3">
                          {item?.name + " " + item?.lastName}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
              <div
                onClick={() => setSecondtExpand(!secondtExpand)}
                className="flex items-center w-full justify-between border-b border-secondary-200 pb-1 mt-2"
              >
                <p className="text-base font-semibold text-secondary-700 flex gap-2">
                  {t("chat:otherUser")}
                  <Badgets
                    label={
                      data?.participants?.filter(
                        (a) =>
                          data?.authorizedParticipants?.find(
                            (b) => b?.id == a?.id
                          )?.id !== a.id
                      )?.length
                    }
                    size={"sm"}
                    colorType={"fill-gray"}
                  />
                </p>
                <div className={`w-9 ${secondtExpand && "rotate-180"}`}>
                  <Button
                    colorType={"tertiary-gray"}
                    size={"sm"}
                    iconLeft={<ChevronDown />}
                  />
                </div>
              </div>
              {secondtExpand && (
                <>
                  {data?.participants?.map((item, index) => {
                    if (
                      data?.authorizedParticipants?.find(
                        (a) => a?.id == item?.id
                      )?.id !== item?.id
                    )
                      return (
                        <div
                          key={index}
                          className="w-full  h-14 min-h-[56px] flex items-center"
                        >
                          {item?.avatar ? (
                            <img
                              src={generateFileUrl(item?.avatar)}
                              className="w-10 h-10 rounded-full"
                            />
                          ) : (
                            <NoAvatar
                              size={40}
                              name={item?.name}
                              lastName={item?.lastName}
                              fontSize={20}
                            />
                          )}
                          <p className="text-sm font-semibold text-secondary-700 ml-3">
                            {item?.name + " " + item?.lastName}
                          </p>
                        </div>
                      );
                  })}
                </>
              )}
            </div>
          </>
        )}
      </div>
      {/*  tablet view */}
      <div className="md:flex sm:flex xs:flex hidden px-5 pt-5 flex-1 h-full md:flex-col  justify-between p-5 min-w-[375px] sm:w-full sm:min-w-full sm:flex-col xs:w-full xs:flex-col xs:min-w-full border-secondary-200 ">
        {isLoading || isFetching ? (
          <div className="w-full h-full items-center justify-center flex">
            <Loading size={32} secondary={"#fff"} primary={"#0E9384"} />
          </div>
        ) : (
          <>
            <div className="w-full flex items-start">
              <div className="flex w-full items-center">
                {data?.image ? (
                  <img
                    src={generateFileUrl(data?.image)}
                    className="w-16 h-16 rounded-full border border-secondary-200"
                  />
                ) : (
                  <NoAvatar size={64} name={data?.title} fontSize={32} />
                )}
              </div>

              <div className="h-[40px] ml-auto">
                <CloseButton onClick={onClose} size={"md"} />
              </div>
            </div>
            <div className="flex flex-col mt-3">
              <div className="flex items-center justify-between">
                <p className="text-lg font-semibold text-secondary-700">
                  {data?.title}
                </p>

                <Button
                  size={"sm"}
                  iconLeft={<EditIcon />}
                  colorType={"tertiary-gray"}
                  onClick={() => {
                    setSelectedGroup(data);
                    setIsOpen(true);
                  }}
                />
              </div>

              <div className="flex  mt-6">
                <CalenderCheck />
                <div className="flex flex-col ml-2">
                  <p className="text-sm text-secondary-500">
                    {t("report:creationDate")}
                  </p>
                  <p className="font-medium text-secondary-700">
                    {moment(data?.createdAt).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
              <div className="border-b border-secondary-200 my-2.5" />

              <div className="flex">
                <DeadlineIcon />
                <div className="flex flex-col ml-2">
                  <p className="text-sm text-secondary-500">
                    {t("chat:deadLine")}
                  </p>
                  <p className="font-medium text-secondary-700">
                    {moment(data?.endDate).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
              <div className="border-b border-secondary-200 my-2.5" />
              <div className="flex">
                <UsersIcon />
                <div className="flex flex-col ml-2">
                  <p className="text-sm text-secondary-500">
                    {t("settingsKpi:personels")}
                  </p>
                  <p className="font-medium text-secondary-700">
                    {data?.participants?.length}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-6 flex-1 overflow-y-scroll scrollbar-hide">
              <div
                onClick={() => setFirstExpand(!firstExpand)}
                className="flex items-center w-full justify-between border-b border-secondary-200 pb-1"
              >
                <p className="text-base font-semibold text-secondary-700 flex gap-2">
                  {t("person:admins")}
                  <Badgets
                    label={data?.authorizedParticipants?.length}
                    size={"sm"}
                    colorType={"fill-gray"}
                  />
                </p>
                <div className={`w-9 ${firstExpand && "rotate-180"}`}>
                  <Button
                    colorType={"tertiary-gray"}
                    size={"sm"}
                    iconLeft={<ChevronDown />}
                  />
                </div>
              </div>
              {firstExpand && (
                <>
                  {data?.authorizedParticipants?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full  h-14 min-h-[56px] flex items-center"
                      >
                        {item?.avatar ? (
                          <img
                            src={generateFileUrl(item?.avatar)}
                            className="w-10 h-10 rounded-full"
                          />
                        ) : (
                          <NoAvatar
                            size={40}
                            name={item?.name}
                            lastName={item?.lastName}
                            fontSize={20}
                          />
                        )}
                        <p className="text-sm font-semibold text-secondary-700 ml-3">
                          {item?.name + " " + item?.lastName}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
              <div
                onClick={() => setSecondtExpand(!secondtExpand)}
                className="flex items-center w-full justify-between border-b border-secondary-200 pb-1 mt-2"
              >
                <p className="text-base font-semibold text-secondary-700 flex gap-2">
                  {t("chat:otherUser")}
                  <Badgets
                    label={
                      data?.participants?.filter(
                        (a) =>
                          data?.authorizedParticipants?.find(
                            (b) => b?.id == a?.id
                          )?.id !== a.id
                      )?.length
                    }
                    size={"sm"}
                    colorType={"fill-gray"}
                  />
                </p>
                <div className={`w-9 ${secondtExpand && "rotate-180"}`}>
                  <Button
                    colorType={"tertiary-gray"}
                    size={"sm"}
                    iconLeft={<ChevronDown />}
                  />
                </div>
              </div>
              {secondtExpand && (
                <>
                  {data?.participants?.map((item, index) => {
                    if (
                      data?.authorizedParticipants?.find(
                        (a) => a?.id == item?.id
                      )?.id !== item?.id
                    )
                      return (
                        <div
                          key={index}
                          className="w-full  h-14 min-h-[56px] flex items-center"
                        >
                          {item?.avatar ? (
                            <img
                              src={generateFileUrl(item?.avatar)}
                              className="w-10 h-10 rounded-full"
                            />
                          ) : (
                            <NoAvatar
                              size={40}
                              name={item?.name}
                              lastName={item?.lastName}
                              fontSize={20}
                            />
                          )}
                          <p className="text-sm font-semibold text-secondary-700 ml-3">
                            {item?.name + " " + item?.lastName}
                          </p>
                        </div>
                      );
                  })}
                </>
              )}
            </div>
          </>
        )}
      </div>

      <CustomModal
        isOpen={isOpen}
        modalTitle={t("person:editGroup")}
        setIsOpen={setIsOpen}
        children={
          <NewGroup
            groupId={groupId}
            refetch={refetch}
            newRefetch={newRefetch}
            setIsOpen={setIsOpen}
            selectedGroup={selectedGroup}
          />
        }
      />
    </>
  );
};

export default memo(GroupChatDetails);
