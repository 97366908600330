import React from "react";
import generateFileUrl from "../../../utils/generateFileUrl";

const PollResult = ({ data }) => {
  return (
    <div className="flex flex-col mt-5 w-full gap-4 h-full">
      <div className="flex flex-col gap-4 h-[67vh] overflow-y-scroll scrollbar-hide  w-full border-secondary-200">
        {data?.question?.map((item, i) => (
          <div key={i} className="w-full flex flex-col gap-4">
            <span className="text-md font-semibold text-secondary-700 dark:text-[#FAFAFA]">
              {i +
                1 +
                ". " +
                (item?.name ? item?.name?.slice(0, 3) + " ***" : "---")}
            </span>
            {item?.options?.map((d, i) => (
              <>
                <div
                  key={i}
                  className="w-full h-8 flex rounded-lg border border-secondary-300 relative  items-center justify-between pl-4 pr-2 dark:bg-[#292929] dark:border-[#424242]"
                >
                  <div
                    style={{ width: d.percent * 100 * (100 / 100) + "%" }}
                    className="h-full left-0 right-0 absolute bg-[#CCFBEF] rounded-lg dark:bg-[#525252] dark:border-[#424242]  "
                  ></div>
                  <span className="text-md text-secondary-600 font-normal z-20 dark:text-[#FAFAFA]">
                    {d?.option}
                  </span>
                  <span className="text-md text-secondary-600 font-normal z-20 dark:text-[#F5F5F5]">
                    {"%" + " " + (d.percent * 100).toFixed(0)}
                  </span>
                </div>
                {d.image ? (
                  <>
                    <div className="flex items-center justify-center w-[144px] min-w-[144px] min-h-[144px] h-[144px] border  rounded-lg">
                      <img
                        src={generateFileUrl(d?.image)}
                        style={{
                          height: 144,
                          width: 144,
                          minWidth: 144,
                          minHeight: 144,
                          filter: "blur(4px)",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PollResult;
