import React, { useEffect, useState } from "react";
import { chatService } from "../../../services/chat.service";
import { Toast } from "../../../utils/toastify/toast";
import { Loader } from "../../../assets/icons/stepsIcons";
import NoAvatar from "../../../components/avatar/NoAvatar";
import { ArrowRightIcon } from "../../../assets/icons/PersonIcons";
import generateFileUrl from "../../../utils/generateFileUrl";
import { Button } from "../../../components";
import Badgets from "../../../components/buttons/Badgets";

const MoveTaskCardModal = ({ selectedItem, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [groupConversations, setGroupConversations] = useState([]);

  const getGroupConversations = async () => {
    setLoading(true);
    await chatService.getGroupConversations().then((res) => {
      setGroupConversations(
        res?.data.filter(
          (item) =>
            item?.tasksId != "undefined" &&
            item?.tasksId != selectedItem?.groupDataId
        )
      );
    });
    setLoading(false);
  };

  const moveToTask = async (id) => {
    const datas = {
      taskId: selectedItem?.taskId,
      newTaskId: id,
    };
    await chatService
      .taskMoveToGroup(selectedItem?.groupDataId, datas)
      .then((res) => {
        if (res?.data?.code == 0) {
          Toast("success", res?.data?.message);
          onClose();
        } else {
        }
      });
  };

  useEffect(() => {
    getGroupConversations();
  }, []);

  return (
    <div className="w-[375px] min-w-[375px] h-[575px] min-h-[575px] flex flex-col items-center">
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="#107569" currentFill="currentFill" />
        </div>
      ) : (
        <div className="h-full w-full mt-6 flex flex-col gap-3 scrollbar-hide overflow-y-scroll">
          {groupConversations?.map((item) => {
            return (
              <div className="flex flex-col">
                <div className="flex pb-2 border-b border-b-secondary-200 items-center justify-between">
                  <p className="text-base font-semibold text-[#101828]">
                    {item?.departmentName}
                  </p>
                  {item?.taskGroupCount > 0 && (
                    <Badgets
                      colorType={"fill-success"}
                      label={item.taskGroupCount || 0}
                    />
                  )}
                </div>
                {item?.taskGroup?.map((item) => {
                  if (item?.tasksId !== selectedItem?.groupDataId)
                    return (
                      <div
                        type="button"
                        key={item?.tasksId}
                        className="w-full h-[64px] pt-3 gap-3 flex flex-row items-center  dark:border-b-[#292929]"
                      >
                        <div className="w-[291px] h-12 flex items-center gap-3">
                          {item?.image ? (
                            <img
                              src={generateFileUrl(item?.image)}
                              className="w-12 h-12 rounded-full border border-solid border-[#EAECF0] dark:border-[#525252]"
                            />
                          ) : (
                            <NoAvatar name={item?.name} size={48} />
                          )}
                          <span className="w-[231px] h-6 text-sm font-semibold text-[#344054] flex flex-col justify-center items-start gap-2 truncate dark:text-[#FAFAFA]">
                            {item?.name}
                          </span>
                        </div>
                        <div className="ml-auto">
                          <Button
                            iconLeft={<ArrowRightIcon />}
                            colorType={"secondary-gray"}
                            size={"sm"}
                            onClick={() => {
                              moveToTask(item?.tasksId);
                            }}
                          />
                        </div>
                      </div>
                    );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MoveTaskCardModal;
