import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../components";
import {
  SortDeadlineIcon,
  SortTaskNumberIcon,
  SortUpuPointIcon,
  TaskSortIcon,
} from "../../../assets/icons/PersonIcons";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TaskSortButton = ({
  data,
  setFilteredTasks,
  setSortValue,
  sortValue,
}) => {
  const menuRef = useRef();
  const {t} = useTranslation();
  const [sortOpen, setSortOpen] = useState(false);
  const sortValueOptions = [
    {
      label: t("chat:deadLine"),
      value: "endDate",
      icon: <SortDeadlineIcon />,
    },
    {
      label: t("chat:taskNumber"),
      value: "rowNumber",
      icon: <SortTaskNumberIcon />,
    },
    {
      label: "upu.point",
      value: "upuPoint",
      icon: <SortUpuPointIcon />,
    },
  ];

  useEffect(() => {
    if (data?.tasks?.length > 0) {
      if (sortValue === "endDate") {
        setFilteredTasks(
          [...data?.tasks].sort(
            (a, b) => moment(a?.endDate).unix() - moment(b?.endDate).unix()
          )
        );
      } else {
        setFilteredTasks(
          [...data?.tasks].sort((a, b) => b[sortValue] - a[sortValue])
        );
      }
    }
  }, [sortValue, data?.tasks]);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setSortOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={menuRef} className="relative">
      <Button
        colorType={"secondary-gray"}
        size={"sm"}
        iconLeft={<TaskSortIcon />}
        onClick={() => {
          setSortOpen(!sortOpen);
        }}
      />
      {sortOpen && (
        <div className="absolute w-[190px] p-1 bg-white right-0 top-10 border border-secondary-200 rounded-lg shadow-sm flex flex-col gap-y-1 z-50">
          {sortValueOptions?.map((item, index) => (
            <button
              onClick={() => {
                setSortValue(item?.value);
                setSortOpen(false);
              }}
              key={index}
              type="button"
              className={`w-full h-[38px] flex items-center px-2.5 hover:bg-secondary-100 rounded ${
                sortValue == item?.value ? "bg-secondary-200" : ""
              }`}
            >
              {item?.icon}
              <span className="font-medium text-sm text-secondary-700 ml-2">
                {item?.label}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaskSortButton;
