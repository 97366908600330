import React, { useState } from "react";
import {
  PointIcon,
  StarIcon,
  EditIcon,
  CloseIcon,
} from "../../../assets/icons/taskCardIcons";
import moment from "moment";
import NoAvatar from "../../../components/avatar/NoAvatar";
import generateFileUrl from "../../../utils/generateFileUrl";
import EndDatePicker from "./EndDatePicker";
import { CalenderIcon, GoldStarIcon } from "../../../assets/icons/PersonIcons";
import { Toast } from "../../../utils/toastify/toast";
import { useTranslation } from "react-i18next";

const TaskCardHeader = ({
  isEdit,
  activeColor,
  number,
  listNumber,
  upuPoint,
  value,
  setUpuPoint,
  endDate,
  setEndDate,
  changeStarStatus,
  creator,
  isStar,
  setIsEdit,
  setIsOpenMenu,
  setSelectedTab,
  isOpenMenu,
}) => {
  const {t} = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  return (
    <div className="flex w-full justify-between">
      <div
        className={`flex items-center ${
          isEdit ? "h-8 w-[60%]" : "h-9 w-[70%]"
        }`}
      >
        <div
          onClick={() => {
            if (!isEdit) {
              setIsOpenMenu(true);
            } else {
              setIsEdit(false);
              setSelectedTab(null);
            }
          }}
          style={{ backgroundColor: isEdit ? "#fff" : activeColor }}
          className={`${
            isEdit
              ? "w-9 h-9 min-w-[36px] min-h-[36px] border rounded-lg border-[#D0D5DD] hover:bg-[#F9FAFB] active:bg-white active:shadow-ring-gray"
              : "w-9 h-9 min-w-[36px] min-h-[36px] rounded-lg"
          } rounded flex items-center justify-center cursor-pointer group`}
        >
          <p
            className={`text-[11px] font-semibold text-white ${
              isEdit ? "hidden" : `block group-hover:hidden`
            }`}
          >
            {number ? number + 1 : number == 0 ? 1 : ".."}
          </p>

          {isEdit ? (
            <CloseIcon color={"#475467"} />
          ) : (
            <div className="hidden group-hover:block">
              <EditIcon />
            </div>
          )}
        </div>

        {isEdit ? (
          <div
            className={`w-[78px] min-w-[78px] ml-3 overflow-hidden h-9 flex items-center justify-center border border-[#D0D5DD] rounded-lg bg-white px-3`}
          >
            <span className="pb-1">
              <PointIcon />
            </span>
            <input
              className="h-full w-[60%] p-0 grow ml-1 border-transparent focus:border-transparent focus:outline-0 focus:ring-0 border-none text-[#344054] font-semibold text-sm dark:bg-[#292929] dark:text-[#D6D6D6]"
              type="number"
              value={upuPoint}
              onChange={({ target }) => {
                const inputValue = target.value;
                if (inputValue.length > 3) {
                  target.value = inputValue?.slice(0, 3);
                }
                setUpuPoint(target.value);
              }}
            />
          </div>
        ) : (
          <div className="flex items-center gap-0.5 ml-5">
            <div className="min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px]">
              <PointIcon />
            </div>
            {value == undefined ? (
              <p className="truncate w-[90%] text-sm font-medium text-gray-700 dark:text-[#D6D6D6]">
                {upuPoint}
              </p>
            ) : (
              <p className="text-sm font-medium text-gray-700 dark:text-[#D6D6D6]">
                {upuPoint}
              </p>
            )}
          </div>
        )}
        {value == undefined ? (
          <span
            style={{ display: isEdit ? "none" : "flex" }}
            className="h-full w-px bg-[#D0D5DD] mx-5"
          ></span>
        ) : (
          <span
            style={{ display: isEdit ? "none" : "flex" }}
            className="h-full w-px bg-[#D0D5DD] ml-2"
          ></span>
        )}

        {isEdit ? (
          <EndDatePicker
            date={endDate}
            setDate={setEndDate}
            visible={showDatePicker}
            setVisible={setShowDatePicker}
          >
            <div
              onClick={() => setShowDatePicker(!showDatePicker)}
              className="w-[128px] ml-3 h-9 border rounded-lg flex gap-x-1 items-center border-gray-300 dark:border-[#525252] cursor-pointer px-3 py-2"
            >
              <CalenderIcon />
              <p className="text-sm text-[#344054] font-semibold">
                {moment(endDate).format("DD.MM.YYYY")}
              </p>
            </div>
          </EndDatePicker>
        ) : (
          <div className="flex items-center gap-1">
            <CalenderIcon />
            <p className="text-sm font-medium text-gray-700 dark:text-[#D6D6D6]">
              {moment(endDate).format("DD/MM/YYYY")}
            </p>
          </div>
        )}
        {value == undefined ? (
          <span
            style={{ display: isEdit ? "none" : "flex" }}
            className="h-full w-px bg-[#D0D5DD] mx-5"
          ></span>
        ) : (
          <span
            style={{ display: isEdit ? "none" : "flex" }}
            className="h-full w-px bg-[#D0D5DD] ml-2"
          ></span>
        )}

        <p
          style={{ display: isEdit ? "none" : "flex" }}
          className="text-sm font-medium text-gray-700 dark:text-[#D6D6D6]"
        >
          {"#" + listNumber}
        </p>

        {value == undefined ? (
          <span
            style={{ display: isEdit ? "none" : "flex" }}
            className="h-full w-px bg-[#D0D5DD] mx-5"
          ></span>
        ) : (
          <span
            style={{ display: isEdit ? "none" : "flex" }}
            className="h-full w-px bg-[#D0D5DD] ml-2"
          ></span>
        )}

        {value == undefined ? (
          <div
            // onClick={() => {
            //   changeStarStatus();
            // }}
            onClick={() => Toast("warning", t("alert:warning"))}
            className={`items-center justify-center cursor-pointer ${
              isEdit ? "hidden" : "flex"
            }`}
          >
            {isStar ? <GoldStarIcon /> : <StarIcon />}
          </div>
        ) : (
          <></>
        )}
      </div>

      <div
        className={`${
          isEdit ? "h-8 w-[40%] ml-5" : "h-9 w-[40%] "
        }  flex items-center justify-end gap-2`}
      >
        {creator?.avatar ? (
          <span className="flex items-center">
            {value == undefined ? (
              <div className="text-sm font-semibold mr-2 text-[#475467] whitespace-nowrap flex items-end truncate">
                {creator?.name} {creator?.lastName}
              </div>
            ) : (
              <></>
            )}
            <img
              src={generateFileUrl(creator?.avatar)}
              className={`${
                isEdit ? "w-9 min-w-[36px] h-9 " : "w-9 min-w-[36px] h-9"
              } rounded-full border blur-sm ${
                value == undefined ? "ml-0" : "ml-auto"
              } border-secondary-300 dark:border-[#525252]`}
            />
          </span>
        ) : (
          <span className="flex items-center">
            {value == undefined ? (
              <div className="text-sm font-semibold mr-2 text-[#475467] whitespace-nowrap flex items-end truncate">
                {creator?.name} {creator?.lastName}
              </div>
            ) : null}
            <NoAvatar
              size={isEdit ? 32 : 24}
              fontSize={isEdit ? 13 : 10}
              color={activeColor}
              name={creator?.name}
              lastName={creator?.lastName}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default TaskCardHeader;
