import React, { useEffect, useState } from "react";
import { QualityHeader } from "../components";
import moment from "moment";
import QualityMain from "./QualityMain";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../hooks/useStores";

const QualityDashboard = () => {
  const { auth } = useStore();
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const navigate = useNavigate();
  useEffect(() => {
    if (auth?.user?.company?.id == "e5c06127-3c5d-44b9-8403-74c160fc7f59") {
    } else if (
      auth?.user?.company?.id == "ab77a50a-7b68-42a2-ba5c-0ebfb5c13325"
    ) {
    } else if (
      auth?.user?.company?.id == "71b0edec-e5d4-4929-bfdb-0e5d5e149592"
    ) {
    } else if (
      auth?.user?.company?.id == "dd1892b9-d9a3-44ed-9131-17c88a74a1b4"
    ) {
    } else if (
      auth?.user?.company?.id == "f52fd7a4-c850-4c68-9244-fda3f16da706"
    ) {
    } else if (
      auth?.user?.company?.id == "49ee8491-35d7-4eb2-a0e9-bfc95541180f"
    ) {
    } else if (
      auth?.user?.company?.id == "65844198-a9e8-45fa-bc99-7d012a541b35"
    ) {
    } else {
      navigate("/app/product/verysoon");
    }
  }, []);
  return (
    <div className="flex w-full h-full  flex-col">
      <QualityHeader date={date} setDate={setDate} />
      <QualityMain date={date} />
    </div>
  );
};

export default QualityDashboard;
