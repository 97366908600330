import React, { useEffect, useState } from "react";
import { Button, CustomModal } from "../../components";
import { useStore } from "../../hooks/useStores";
import { HumburgerIcon, PurchaseIcon } from "../../assets/icons/leftMenuIcons";
import { PlusIcon } from "../../assets/icons/stepsIcons";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon } from "../../assets/icons/departmentIcon";
import NewRawMaterialsModal from "./modals/NewRawMaterialsModal";
import { useQuery } from "@tanstack/react-query";
import { rawMaterialService } from "../../services/raw-material.service";
import { useNavigate } from "react-router-dom";
import { TableArrowLeft } from "../../assets/icons/lossIcon";
import SearchInput from "../../components/inputs/SearchInput";
import diacritics from "diacritics";
import { SearchIcon } from "../../assets/icons/PersonIcons";
import { ScrollSyncPane } from "react-scroll-sync";
import { ScrollChevronIcon } from "../../assets/icons/productIcons";
import PurchaseRequestForm from "./modals/PurchaseRequestForm";
import TypeRawPurchaseRequestForm from "./modals/TypeRawPurchaseRequestForm";
import { DotsVerticalIcon } from "../../components/junction/ActiveAndPlannedCard";
import NewRawMaterialCard from "../../components/junction/NewRawMaterialCard";
import { Toast } from "../../utils/toastify/toast";

const RawMaterialList = ({}) => {
  const { settings } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [onEdit, setOnEdit] = useState(null);
  const [isNewRawMaterialModal, setIsNewRawMaterialModal] = useState(false);
  const [responsiveSearch, setResponsiveSearch] = useState(false);
  const [purchaseForm, setPurchaseForm] = useState(false);
  const [purchase, setPurchase] = useState(null);

  const [windowSize, setWindowSize] = useState(window?.innerWidth);
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["raw-materials"],
    queryFn: async () => await rawMaterialService.getRawMaterials(),
    retry: 0,
  });
  const tableHeadItems = [
    { name: t("product:rawMaterials"), value: "image", type: "" },
    { name: t("product:rawMaterialType"), value: "name", type: "string" },
    { name: t("product:affiliatedSuppliers"), value: "lastName", type: "string" },
    { name: t("product:costPerUnit"), value: "rolesKey", type: "string" },
    { name: t("product:explanation"), value: "talents", type: "number" },
    { name: "", value: "phoneNumber", type: "number" },
  ];

  const handleSearch = () => {
    setResponsiveSearch(!responsiveSearch);
  };

  const filteredData = data?.filter(
    (raw) =>
      diacritics
        .remove(`${raw?.name}`)
        .toLowerCase()
        .includes(diacritics.remove(searchQuery.toLowerCase())) ||
      diacritics
        .remove(`${raw?.type}`)
        .toLowerCase()
        .includes(diacritics.remove(searchQuery.toLowerCase()))
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isSmallScreen = windowSize <= 1023;

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true);
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const handleScroll = () => {
    const container = document.getElementById("content-wrapper");
    const isAtBeginning = container.scrollLeft === 0;
    const isAtEnd =
      container.scrollLeft >= container.scrollWidth - container.clientWidth;
    setIsLeftArrowHidden(isAtBeginning);
    setIsRightArrowHidden(isAtEnd);
  };
  useEffect(() => {
    const container = document.getElementById("content-wrapper");
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="flex w-full border-b h-14 min-h-[58px] mb-4 items-center">
        <Button
          colorType={"tertiary-gray"}
          onClick={() => {
            navigate(-1);
          }}
          size={"sm"}
          iconLeft={
            <div className="rotate-180">
              <TableArrowLeft />
            </div>
          }
        />

        <div className="flex items-center">
          <span className="text-2xl text-secondary-900 font-semibold ml-2">
            {t("product:rawMaterialList")}
          </span>
        </div>
        <div className="ml-auto flex flex-row items-center gap-x-3">
          <div className="w-[320px] max-w-[320px] min-w-[320px] sm:hidden md:hidden">
            <SearchInput setSearchVal={setSearchQuery} theme={"product"} />
          </div>
          <div className="hidden sm:flex md:flex">
            <Button
              onClick={handleSearch}
              size="md"
              iconLeft={<SearchIcon />}
              colorType={"secondary-gray"}
            />
          </div>
          {/* <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:createRawMaterial")}
            iconLeft={<PlusIcon />}
            onClick={() => {
              setOnEdit(null);
              setIsNewRawMaterialModal(true);
            }}
          /> */}

          <Button
            colorType={"primary-product"}
            size={"md"}
            label={t("product:createRawMaterial")}
            iconLeft={<PlusIcon />}
            // onClick={() => navigate("/app/product/stock/raw-material/create")}
            onClick={() => Toast("warning", t("alert:warning"))}
          />
        </div>
      </div>
      {responsiveSearch && (
        <div className="w-full hidden sm:flex md:flex pb-4 px-1">
          <SearchInput setSearchVal={setSearchQuery} />
        </div>
      )}

      <div className="flex-1 w-full mt-2 border rounded-xl flex flex-col overflow-hidden relative sm:px-11">
        <div
          className={`w-11 h-full hidden sm:flex ${
            isRightArrowHidden ? "hidden duration-1000" : ""
          } z-[999] right-0 cursor-pointer flex absolute rounded-r-xl items-center justify-center group bg-gray-50`}
          onClick={() => {
            sideScroll(document.getElementById("content-wrapper"), 25, 100, 20);
          }}
        >
          <ScrollChevronIcon />
        </div>

        <div
          className={`w-11 h-full hidden sm:flex ${
            isLeftArrowHidden ? "hidden duration-1000" : ""
          } z-[999] left-0 absolute cursor-pointer flex rotate-180 items-center justify-center bg-gray-50 group`}
          onClick={() => {
            sideScroll(
              document.getElementById("content-wrapper"),
              25,
              100,
              -20
            );
          }}
        >
          <ScrollChevronIcon />
        </div>

        <ScrollSyncPane>
          <div
            id="content-wrapper"
            className="min-w-full max-w-full w-full h-[86vh] rounded-xl overflow-x-auto scrollbar-hide flex flex-col"
          >
            <div className="flex max-h-[44px] min-h-[44px] max-w-full min-w-full">
              {tableHeadItems.map((item, i) => {
                return (
                  <div
                    key={i}
                    scope="col"
                    className={` px-4 py-3 ${
                      i === 0
                        ? "w-[27%] "
                        : i === 1
                        ? "w-[11%] "
                        : i === 2
                        ? "w-[15%] "
                        : i === 3
                        ? "w-[11%] "
                        : i === 4
                        ? "w-[30%] "
                        : i === 5
                        ? "w-[6%] border-r-0"
                        : ""
                    } items-center text-center bg-secondary-50 border-gray-200 border-b border-r`}
                  >
                    <span
                      className={`text-center rounded flex text-xs font-medium justify-center items-center whitespace-nowrap`}
                    >
                      {item?.name}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full bg-[#FFF] flex-1 flex flex-col">
              {filteredData?.map((item, key) => (
                <div
                  key={key}
                  className={`flex w-full items-center border-b h-[108px] min-h-[108px] `}
                >
                  <div className=" border-r h-full px-3 py-2 flex items-center w-[27%] ">
                    <NewRawMaterialCard item={item} />
                  </div>
                  <div className=" border-r h-full p-4 flex items-center justify-center w-[11%] ">
                    <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium text-center">
                      {item?.type === "lama"
                        ? "Lama".slice(0, 2) +" ***"
                        : item?.type === "stick"
                        ? "Çubuk".slice(0, 2) +" ***"
                        : item?.type === "pipe"
                        ? "Boru".slice(0, 2) +" ***"
                        : "---"}
                    </p>
                  </div>
                  <div className="border-r h-full p-4 flex items-center justify-center w-[15%]">
                    <p className="w-[90%] line-clamp-2 text-sm text-[#344054] dark:text-[#F5F5F5] font-medium text-center">
                      {item?.supplierGroup?.name ? item?.supplierGroup?.name?.slice(0, 3)+" ***" : "---"}
                    </p>
                  </div>
                  <div className="border-r h-full p-4 flex items-center w-[11%] flex-col justify-center text-center">
                    <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-sm text-[#344054] dark:text-[#F5F5F5] font-medium">
                      £ {(item?.price?.toString().slice(0, 2) || "") + " ***"}
                    </p>
                    <p className="w-[90%] whitespace-nowrap overflow-hidden text-ellipsis text-xs text-[#667085] dark:text-[#F5F5F5] font-normal">
                      {item?.unit}
                    </p>
                  </div>
                  <div className="h-full border-r justify-center flex text-start items-center w-[30%] p-3">
                    <p className="w-[95%] line-clamp-4 text-sm text-[#475467] dark:text-[#F5F5F5] font-normal">
                      {item?.description?.slice(0, 3)+" ***"}
                    </p>
                  </div>
                  <div className="h-full items-center flex justify-center w-[6%] ">
                    <Button
                      // onClick={() => {
                      //   setOnEdit(item);
                      //   setIsNewRawMaterialModal(true);
                      // }}
                      onClick={() => {
                        // navigate(
                        //   `/app/product/stock/raw-material/edit/${item.id}`
                        // );
                        Toast("warning", t("alert:warning"))
                      }}
                      colorType={"tertiary-gray"}
                      iconLeft={<DotsVerticalIcon />}
                      size={"sm"}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ScrollSyncPane>
      </div>

      <CustomModal
        isOpen={isNewRawMaterialModal}
        setIsOpen={setIsNewRawMaterialModal}
        modalTitle={t("product:createNewRawMaterial")}
        width="fit-content"
        children={
          <NewRawMaterialsModal
            t={t}
            refetch={refetch}
            update={onEdit}
            isOpen={isNewRawMaterialModal}
            setIsOpen={setIsNewRawMaterialModal}
          />
        }
      />
      <CustomModal
        isOpen={purchaseForm}
        setIsOpen={setPurchaseForm}
        onClose={() => {
          setPurchaseForm(false);
        }}
        modalTitle={t("product:purchaseRequestForm")}
        children={
          <TypeRawPurchaseRequestForm
            setIsOpen={purchaseForm}
            closeModal={() => {
              setPurchaseForm(false);
            }}
            purchaseData={purchase}
          />
        }
      />
    </div>
  );
};

export default RawMaterialList;
