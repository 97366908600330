import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, UploadProfile } from "../../components";
import { Toast } from "../../utils/toastify/toast";
import { authService } from "../../services/auth.service";
import { EyeIcon } from "../../assets/icons/PersonIcons";
import { Loader } from "../../assets/icons/stepsIcons";
import { useNavigate } from "react-router-dom";
import { instance } from "../../libs/client";
import { useStore } from "../../hooks/useStores";
import { personalService } from "../../services/personal.service";

const SettingProfile = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [user, setUser] = useState({});
  const [passError, setPassError] = useState(false);
  const [passRepeatError, setPassRepeatError] = useState(false);
  const { auth } = useStore();
  const navigate = useNavigate();

  async function logOut() {
    setLoading(true);
    await instance.post(`auth/logout`, { token: auth?.token?.access_token });
    auth?.logOut();
    navigate("/auth/login");
    setLoading(false);
  }

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("AuthStore"));
    setUser(authData?.user);
    if (authData && authData?.user && authData?.user?.avatar) {
      setAvatar(authData?.user?.avatar);
    }
  }, []);
  const changePassword = async (
    oldPassword,
    newPassword,
    newPasswordRepeat
  ) => {
    if (!oldPassword || !newPassword || !newPasswordRepeat) {
      Toast("error", t("password:passwordRequired"));
      return;
    } else if (!(newPasswordRepeat === newPassword)) {
      Toast("error", t("password:passwordNotMatch"));
      return;
    } else if (oldPassword === newPassword && newPasswordRepeat) {
      Toast("error", t("errors:oldAndNewPassword"));
      return;
    } else {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/.test(
          newPassword
        );
      if (regex) {
        setLoading(true);
        authService
          .changePassword({
            oldPassword,
            newPassword,
            newPasswordRepeat,
          })
          .then((res) => {
            if (res.data.code === 0) {
              Toast("success", t("success:passwordChanged"));
              setLoading(false);
              logOut();
            } else if (res.data.code === 2) {
              Toast("error", t("password:currentPasswordNotTrue"));
              setLoading(false);
            } else {
              Toast("error", t("password:passwordNotMatch"));
              setLoading(false);
            }
          })
          .catch((error) => {
            Toast("error", t("errors:apiError"));
            setLoading(false);
          });
      } else {
        Toast("error", t("password:passwordNotValid"));
        return;
      }
    }
  };
  const onSubmit = async () => {
    Toast(
      "warning",
      "Demo users are not authorized to access contact administration."
    );
  };
  useEffect(() => {
    if (newPassword == "") {
      setPassError(false);
    }
    if (newPasswordRepeat == "") {
      setPassRepeatError(false);
    }
    if (newPassword != "") {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/.test(
          newPassword
        );
      setPassError(!regex);
    }
    if (newPassword != newPasswordRepeat && newPasswordRepeat != "") {
      setPassRepeatError(true);
    }
    if (newPassword == newPasswordRepeat) {
      setPassRepeatError(false);
    }
  }, [newPassword, newPasswordRepeat]);

  // const changePassword = async (
  //   oldPassword,
  //   newPassword,
  //   newPasswordRepeat
  // ) => {
  //   if (!oldPassword || !newPassword || !newPasswordRepeat) {
  //     Toast("error", t("errors:passwordRequired"));
  //     return;
  //   }

  //   try {
  //     const isCurrentPasswordValid =
  //       await authService?.checkCurrentPassword(oldPassword);

  //     if (!isCurrentPasswordValid) {
  //       Toast("error", t("errors:incorrectPassword"));
  //       return;
  //     }

  //     if (oldPassword === newPassword && newPasswordRepeat) {
  //       Toast("error", t("errors:oldAndNewPassword"));
  //       return;
  //     }

  //     if (newPassword !== newPasswordRepeat) {
  //       Toast("error", t("errors:passwordNotMatch"));
  //       return;
  //     }

  //     setLoading(true);
  //     authService
  //       .changePassword({
  //         oldPassword,
  //         newPassword,
  //         newPasswordRepeat,
  //       })
  //       .then((res) => {
  //         if (res.data.code === 0) {
  //           Toast("success", t("success:passwordChanged"));
  //           setLoading(false);
  //           logOut();
  //         } else {
  //           Toast("error", t("errors:passwordNotMatch"));
  //           setLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         Toast("error", t("errors:apiError"));
  //         setLoading(false);
  //       });
  //   } catch (error) {
  //     Toast("error", t("errors:apiError"));
  //   }
  // };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Loader currentColor="currentColor" currentFill="currentFill" />
        </div>
      ) : (
        <>
          <div className="w-full h-full xs:h-[72vh] xs:overflow-y-auto xs:scrollbar-hide flex flex-col gap-5">
            <div className="flex justify-between items-center h-[74px] border-b w-full border-secondary-200">
              <div className="flex flex-col w-1/2 xs:w-[100%] xs:min-w-[100%] xs:pb-2">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary-900  text-lg font-medium dark:text-[#FAFAFA]">
                    {t("routes:profile")}
                  </p>
                </div>

                <p className="text-sm text-secondary-500 font-normal dark:text-[#D6D6D6]">
                  {t("settingTab:manageYourProfile")}
                </p>
              </div>
              {/* <div className="w-1/2 flex flex-row items-center gap-3 justify-end ">
                <div className="w-[30%] xs:w-full sm:w-[65%] md:w-1/2 lg:w-1/2 xs:hidden">
                  <Button
                    size={"lg"}
                    type={"button"}
                    colorType={"primary-machine"}
                    label={t("buttons:saveChanges")}
                    onClick={() =>
                      changePassword(
                        oldPassword,
                        newPassword,
                        newPasswordRepeat
                      )
                    }
                  />
                </div>
              </div> */}
            </div>

            <div className="flex flex-row xs:flex-col items-start w-full min-h-[72px] gap-3  dark:border-b-[#292929]">
              <p className="w-[28%] dark:text-[#E5E5E5] xs:w-full xs:hidden">
                {t("settingTab:profilePhoto")}
              </p>
              <div className="w-[25%] sm:w-[40%] xs:[50%] xs:w-full">
                <UploadProfile
                  title={t("fileInput:uploadAvatar")}
                  subTitle={t("fileInput:removePicture")}
                  type={"profile"}
                  rounded={100}
                  onChange={(image) => {
                    setAvatar(image);
                  }}
                  value={avatar}
                  setAvatar={setAvatar}
                />
              </div>
            </div>

            <div className="flex items-center h-[72px] border-b w-full border-secondary-200">
              <div className="flex flex-col w-full  xs:pb-2">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-secondary-900 text-lg font-medium dark:text-[#FAFAFA]">
                    {t("personals:password")}
                  </p>
                </div>
                <p className="text-sm text-secondary-500 font-normal dark:text-[#D6D6D6]">
                  {t("password:passwordConditions")}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start w-full ">
              <div className="flex flex-row xs:flex-col items-start w-full  gap-3 dark:border-b-[#292929]">
                <p className="w-[28%] dark:text-[#E5E5E5] xs:w-full">
                  {t("settingTab:currentPasswords")}
                </p>
                <div className="w-[28%] sm:w-[40%] xs:w-full blur-[2px]">
                  <Input
                    type={"password"}
                    iconPosition={"right"}
                    icon={<EyeIcon currentColor={"#98A2B3"} />}
                    onChange={(e) => setOldPassword(e?.target?.value)}
                  />
                </div>
              </div>
              <hr className="w-full h-[1px] bg-secondary-200 mt-5" />

              <div className="flex flex-row xs:flex-col items-start w-full mt-5 gap-3 dark:border-b-[#292929]">
                <p className="w-[28%] dark:text-[#E5E5E5] xs:w-full">
                  {t("personals:newPassword")}
                </p>
                <div className="w-[28%] sm:w-[40%] xs:w-full blur-[2px]">
                  <Input
                    iconPosition={"right"}
                    icon={<EyeIcon currentColor={"#98A2B3"} />}
                    type={"password"}
                    onChange={(e) => setNewPassword(e?.target?.value)}
                    validate={passError ? "error" : ""}
                    errorMessage={t("password:passwordNotValid")}
                  />
                </div>
              </div>
              <hr className="w-full h-[1px] bg-secondary-200 mt-5" />
              <div className="flex flex-row xs:flex-col items-start w-full mt-5 gap-3 dark:border-b-[#292929]">
                <p className="w-[28%] dark:text-[#E5E5E5] xs:w-full">
                  {t("settingTab:consfirmNewPassword")}
                </p>
                <div className="w-[28%] sm:w-[40%] xs:w-full blur-[2px]">
                  <Input
                    iconPosition={"right"}
                    icon={<EyeIcon currentColor={"#98A2B3"} />}
                    onChange={(e) => setNewPasswordRepeat(e?.target?.value)}
                    type={"password"}
                    errorMessage={t("password:passwordNotMatch")}
                    validate={passRepeatError ? "error" : ""}
                  />
                </div>
              </div>
              <hr className="w-full h-[1px] bg-secondary-200 mt-5" />
            </div>

            <div className="w-full flex flex-row items-center gap-3 justify-end ">
              <div className="w-[20%] sm:w-[30%] xs:w-full">
                <Button
                  size={"lg"}
                  type={"button"}
                  colorType={"primary-machine"}
                  label={t("buttons:saveChanges")}
                  disabled={true}
                  onClick={() => onSubmit()}
                />
              </div>
            </div>
          </div>
          <div className="xs:w-full xs:flex hidden mt-4">
            <Button
              size={"lg"}
              type={"button"}
              colorType={"primary-machine"}
              label={t("buttons:saveChanges")}
              onClick={() => onSubmit()}
              disabled={true}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SettingProfile;
