import React from "react";

const Loading = ({ size, primary, secondary }) => {
  return (
    <React.Fragment>
      <svg
        aria-hidden="true"
        width={size}
        height={size}
        className="animate-spin"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill={primary}
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill={secondary}
        />
      </svg>
    </React.Fragment>
    // <div className="w-full h-full flex items-center justify-center">
    //   {/* <img src={LoadingUpuIcon} className="animate-spin" /> */}
    // </div>
  );
};

export default Loading;

export const LoadingUpuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="41"
      viewBox="0 0 42 41"
      fill="none"
    >
      <path
        d="M30.0688 25.6099C30.0688 27.5122 29.6941 29.396 28.966 31.1534C28.238 32.9109 27.1709 34.5077 25.8258 35.8526C24.4806 37.1975 22.8837 38.2641 21.1263 38.9916C19.3689 39.7191 17.4854 40.0931 15.5834 40.0923V33.966C17.0885 33.9807 18.572 33.6085 19.8919 32.885C21.1656 32.1883 22.2233 31.1549 22.9495 29.8976C23.677 28.6006 24.0591 27.1383 24.0591 25.651C24.0591 24.1638 23.677 22.7015 22.9495 21.4044C22.2243 20.1483 21.1662 19.1169 19.8919 18.4242C18.552 17.719 17.0607 17.3505 15.5466 17.3505C14.0325 17.3505 12.5412 17.719 11.2013 18.4242C9.93149 19.113 8.87719 20.14 8.15533 21.3915C7.64596 22.2706 7.30764 23.2382 7.15827 24.2432C4.9625 22.8318 3.15636 20.8913 1.90563 18.5999C0.654895 16.3085 -0.000365425 13.7396 1.52886e-07 11.1289H15.5834C17.4853 11.128 19.3688 11.5018 21.1262 12.2291C22.8836 12.9564 24.4805 14.023 25.8257 15.3677C27.1709 16.7125 28.238 18.3092 28.966 20.0666C29.6941 21.824 30.0688 23.7076 30.0688 25.6099Z"
        fill="white"
      />
      <path
        d="M30.0615 13.3325V6.69141H41.1443L30.0615 13.3325Z"
        fill="white"
      />
      <path
        d="M30.0608 3.34593V6.69173H18.9191C18.4799 6.6923 18.0449 6.60619 17.639 6.43832C17.233 6.27045 16.8641 6.02411 16.5533 5.71339C16.2425 5.40266 15.9959 5.03364 15.8275 4.62742C15.6592 4.22119 15.5725 3.78574 15.5723 3.34593V0.000122383H26.714C27.1529 -6.74658e-05 27.5875 0.0863328 27.993 0.25439C28.3985 0.422448 28.767 0.668865 29.0774 0.979571C29.3878 1.29028 29.634 1.65918 29.802 2.06521C29.97 2.47123 30.0565 2.90643 30.0565 3.34593"
        fill="white"
      />
      <path
        d="M18.4165 28.7545C17.6604 29.545 16.697 29.9436 15.5265 29.9503H15.4972C14.3127 29.9503 13.3396 29.5517 12.5778 28.7545C11.9141 28.0525 11.5176 27.129 11.4604 26.1519C11.4464 25.9925 11.4395 25.8303 11.4395 25.6624C11.4395 24.3991 11.8203 23.3689 12.582 22.5717C13.3438 21.7745 14.3169 21.3755 15.5014 21.3745C16.6859 21.3745 17.659 21.7736 18.4207 22.5717C19.1824 23.3699 19.5633 24.4001 19.5633 25.6624C19.5605 26.9285 19.1782 27.9592 18.4165 28.7545Z"
        fill="#9E77ED"
      />
    </svg>
  );
};
